
import Vue from "vue";
import router from "@/router/index";
import AuthClient from "@/api/AuthClient";
import LocalDataService from "@/store/LocalDataService";
import { Constant } from "@/store/constant";
import Auth from "@aws-amplify/auth";
import { User } from "@/store/model";
import S3Image from "@/components/aws/S3Image.vue";
import { CompanyPlan } from "@/api/entities";
import { Gtag } from "@/store/gtag";
import store from "@/store";
import mixin from "@/mixin/mixin";
export interface DataType {
  isMini: boolean;
  isMenuOpen: boolean;
  isAccountMenuOpen: boolean;
}
export default Vue.extend({
  name: "NavigationBar",
  mixins: [mixin],
  components: {
    S3Image,
  },
  props: {},
  data(): DataType {
    return {
      isMini: true,
      isMenuOpen: false,
      isAccountMenuOpen: false,
    };
  },
  watch: {
    isMenuOpen() {
      if (!this.isMini && !this.isMenuOpen) {
        this.isMini = true;
      }
    },
    isAccountMenuOpen() {
      if (!this.isMini && !this.isAccountMenuOpen) {
        this.isMini = true;
      }
    },
  },
  computed: {
    userIcon(): string | null {
      const user = store.state.user;
      if (user) {
        if (this.isAdmin) {
          if (user.sub === user.companyId) {
            return store.state.company?.icon ?? null;
          } else {
            return store.state.adminUser?.icon ?? null;
          }
        } else {
          return store.state.store?.icon ?? null;
        }
      }
      return null;
    },
    plan(): CompanyPlan | null {
      // companyがnullの場合は何も表示しない
      return store.state.company?.plan ?? null;
    },
    user(): User | null {
      return store.state.user ?? null;
    },
  },
  methods: {
    logoDidClick() {
      router.push({ name: this.isAdmin ? "AdminRoot" : "Root" });
    },
    dashboardDidClick() {
      router.push({ name: this.isAdmin ? "AdminDashboard" : "Dashboard" });
    },
    productDidClick() {
      router.push({ name: this.isAdmin ? "AdminProductList" : "ProductList" });
    },
    skuDidClick() {
      router.push({ name: this.isAdmin ? "AdminSkuList" : "SkuList" });
    },
    actualResultDownloadDidClick() {
      router.push({ name: this.isAdmin ? "AdminActualResultDownload" : "ActualResultDownload" });
    },
    groupListDidClick() {
      router.push({ name: this.isAdmin ? "AdminGroupList" : "GroupList" });
    },
    reportDidClick() {
      router.push({ name: this.isAdmin ? "AdminReport" : "Report" });
    },
    seasonsDidClick() {
      router.push({ name: this.isAdmin ? "AdminSeasons" : "" });
    },
    ordersDidClick() {
      router.push({ name: this.isAdmin ? "AdminOrders" : "" });
    },
    storePlanDidClick() {
      router.push({ name: this.isAdmin ? "AdminStorePlan" : "" });
    },
    deliveryDidClick() {
      router.push({ name: this.isAdmin ? "AdminDelivery" : "Delivery" });
    },
    actionListDidClick() {
      router.push({ name: this.isAdmin ? "AdminActionList" : "" });
    },
    actionRequestDidClick() {
      router.push({ name: this.isAdmin ? "AdminActionRequest" : "" });
    },
    CTPDidClick() {
      router.push({ name: this.isAdmin ? "AdminCustomerTouchPoint" : "Members" });
    },
    warehouseDidClick() {
      router.push({ name: "AdminWarehouses" });
    },
    factoryDidClick() {
      router.push({ name: "AdminFactories" });
    },
    integrationDidClick() {
      router.push({ name: "AdminIntegration" });
    },
    masterDidClick() {
      router.push({ name: "MasterList" });
    },
    settingDidClick() {
      router.push({ name: this.isAdmin ? "AdminAccountSetting" : "AccountSetting" });
    },
    adminSettingDidClick() {
      router.push({ name: "AdminSetting" });
    },
    csvItemSkuUpload() {
      router.push({ name: "AdminCsvItemSkuUpload" });
    },
    csvSaleStockUpload() {
      router.push({ name: "AdminCsvSaleStockUpload" });
    },
    imageDataUpload() {
      router.push({ name: "AdminImageDataUpload" });
    },
    alertSetting() {
      router.push({ name: "AlertSetting" });
    },
    customSetting() {
      router.push({ name: "CustomSetting" });
    },
    weekdayBudgetDidClick() {
      router.push({ name: "AdminWeekdayBudget" });
    },
    dailyStoreBudgettDidClick() {
      router.push({ name: this.isAdmin ? "AdminDailyStoreBudget" : "DailyStoreBudget" });
    },
    faqDidClick() {
      window.open("https://wheat-arithmetic-b8a.notion.site/radial-9865394931de4eb9a74db076bfa873ec", "_blank");
    },
    async logoutDidClick() {
      await LocalDataService.clear();
      Auth.signOut().then(() => {
        router.push({ name: this.isAdmin ? "AdminLogin" : "Login" });
      });
    },
  },
  async mounted() {
    if (await AuthClient.checkAuth(this.isAdmin)) {
      Gtag.init();
    } else {
      Auth.signOut().then(() => {
        router.push({ name: this.isAdmin ? "AdminLogin" : "Login" });
      });
    }
    const localVersion = await LocalDataService.getVersion();
    if (localVersion) {
      if (localVersion !== Constant.version) {
        await LocalDataService.clear();
        Auth.signOut().then(() => {
          router.push({ name: this.isAdmin ? "AdminLogin" : "Login" });
        });
      }
    } else {
      await LocalDataService.clear();
    }
  },
});
