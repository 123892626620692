
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { JanCodeLogic } from "@/api/entities";
import { UpdateJanCodeLogicDto } from "@/api/dto";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { User } from "@/store/model";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  isLoading: boolean;
  janCodeLogic: JanCodeLogic[];
  prefixNumbers: number[];
  newPrefix: number | null;
  currentPrefix: string | null;
  buttonDisabled: boolean;
}

export default Vue.extend({
  name: "JanCode",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Loading,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      isLoading: false,
      janCodeLogic: [],
      prefixNumbers: Constant.janCodePrefixList,
      newPrefix: null,
      currentPrefix: null,
      buttonDisabled: true,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {},
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    cancelEditJancodeLogic() {
      this.buttonDisabled = true;
      this.newPrefix = Number(this.currentPrefix);
    },
    async editJancodeLogic() {
      if (this.newPrefix && this.janCodeLogic) {
        const dto: UpdateJanCodeLogicDto = {
          prefix: this.newPrefix,
        };
        await RadialApiClient.updateJancodeLogic(this.janCodeLogic[0].id, dto);
        this.newPrefix = null;
        this.janCodeLogic = (await RadialApiClient.listJanCodeLogic()) ?? [];
        if (this.janCodeLogic.length > 0) {
          this.currentPrefix = String(this.janCodeLogic[0].prefix);
        }
      }
      this.buttonDisabled = true;
      this.showAlert("無事に更新が完了しました！", "success");
    },
  },
  async mounted() {
    this.isLoading = true;
    this.janCodeLogic = (await RadialApiClient.listJanCodeLogic()) ?? [];
    if (this.janCodeLogic.length > 0) {
      this.currentPrefix = String(this.janCodeLogic[0].prefix);
    }
    this.isLoading = false;
  },
});
