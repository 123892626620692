
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import dayjs from "dayjs";
import {
  Season,
  SeasonTotalMonthEntity,
  Store,
  StoreMonthEntity,
  StoreSeasonPlanSeasonTotalEntity,
  StoreSeasonPlanStoreEntity,
  StoreSeasonPlanTotalEntity,
  TotalTotalMonthEntity,
} from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { FilteredStore, User } from "@/store/model";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import AuthClient from "@/api/AuthClient";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

dayjs.extend(isSameOrBefore);

export interface SeasonInfo {
  // シーズン店舗のための型
  seasonId: number;
  seasonName: string;
  seasonYearMonth: string;
}

export interface FilteredStorePlans {
  totalStorePlans: StoreSeasonPlanSeasonTotalEntity[];
  seasonStorePlans: StoreSeasonPlanStoreEntity[];
}

export interface DataType {
  user: User | null;
  isLoading: boolean;
  isCanceled: boolean;
  cancelDates: string[];
  stores: Store[];
  seasons: Season[];
  rangeMonths: string[];
  rangeMonthsMenu: boolean;
  storeFilters: FilteredStore[];
  editMode: boolean;
  tableMonths: string[];
  // 初期描画時の全シーズン及び各シーズンの月別データ
  allStorePlans: StoreSeasonPlanTotalEntity | null;
  // 全シーズン内における各店舗ごとの月別データ
  totalStorePlans: StoreSeasonPlanSeasonTotalEntity[];
  // 特定シーズンにおける各店舗ごとの月別データ
  seasonStorePlans: StoreSeasonPlanStoreEntity[];
  defaultSeasonStorePlans: StoreSeasonPlanStoreEntity[];
  filteredStorePlans: FilteredStorePlans;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  operatingStores: FilteredStore[];
  selectedSeasonId: number | null;
  startEndMonths: string[];
  totalStorePlansEditing: boolean;
}

export default Vue.extend({
  name: "StoreSeasonPlan",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    FilterChipButton,
    ElementRangeDatePicker,
    anum,
    ConfirmationDialog,
    Loading,
  },
  data(): DataType {
    return {
      user: null,
      isLoading: false,
      isCanceled: false,
      cancelDates: [],
      stores: [],
      seasons: [],
      rangeMonths: [dayjs().subtract(11, "month").format("YYYY-MM"), dayjs().format("YYYY-MM")],
      rangeMonthsMenu: false,
      storeFilters: [],
      editMode: false,
      tableMonths: [],
      allStorePlans: null,
      totalStorePlans: [],
      seasonStorePlans: [],
      defaultSeasonStorePlans: [],
      filteredStorePlans: {
        totalStorePlans: [],
        seasonStorePlans: [],
      },
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      operatingStores: [],
      selectedSeasonId: null,
      startEndMonths: [],
      totalStorePlansEditing: false,
    };
  },
  computed: {
    minYearMonth(): string {
      const startYearMonths = this.seasons.map((season) => season.startYearMonth);
      startYearMonths.sort((a, b) => {
        if (a > b) {
          return 1;
        } else {
          return -1;
        }
      });
      return dayjs(startYearMonths[0]).format("YYYY-MM");
    },
    maxYearMonth(): string {
      const endYearMonths = this.seasons.map((season) => season.endYearMonth);
      endYearMonths.sort((a, b) => {
        if (a > b) {
          return 1;
        } else {
          return -1;
        }
      });
      return dayjs(endYearMonths[endYearMonths.length - 1]).format("YYYY-MM");
    },
  },
  methods: {
    inputNumber(number: number, index1: number, index2: number, caseNum: number) {
      const validNumber = Number(
        number.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
      );
      switch (caseNum) {
        case 0:
          // index1はstoreIndex, index2はmonthIndex
          this.filteredStorePlans.totalStorePlans[index1].storeMonths[index2].saleBudget = validNumber;
          this.editStoreSaleBudget(index2);
          return;
        case 1:
          // index1はseasonIndex, index2はmonthIndex
          if (this.allStorePlans) {
            this.allStorePlans.seasons[index1].totalMonths[index2].rate = validNumber;
          }
          this.editSeasonRate(validNumber, index1, index2);
          return;
        default:
          return;
      }
    },
    // 選択されたシーズンものが上に来るようにスクロール対象のclassを入れる
    isScrollTarget(seasonId: number): boolean {
      if (this.seasonStorePlans) {
        return seasonId === this.selectedSeasonId ? true : false;
      }
      return false;
    },
    seasonWidthStyle(seasonId: number): string {
      const season = this.seasons.find((season) => season.id === seasonId);
      if (
        dayjs(season?.startYearMonth).isBefore(dayjs(this.startEndMonths[0])) &&
        dayjs(season?.endYearMonth).isAfter(dayjs(this.startEndMonths[1]))
      ) {
        // 検索条件よりシーズンの開始月及び終了月のいずれもはみ出る場合
        const seasonRangeWidth = (dayjs(this.startEndMonths[1]).diff(this.startEndMonths[0], "month") + 1) * 80;
        return `width: ${seasonRangeWidth}px;`;
      } else if (dayjs(season?.startYearMonth).isBefore(dayjs(this.startEndMonths[0]))) {
        // 検索条件の開始月よりもシーズン開始月の方が過去の場合
        const seasonRangeWidth =
          (dayjs(this.startEndMonths[0]).diff(dayjs(season?.endYearMonth).format("YYYY-MM"), "month") - 1) * -80;
        return `width: ${seasonRangeWidth}px;`;
      } else if (dayjs(season?.endYearMonth).isAfter(dayjs(this.startEndMonths[1]))) {
        // 検索条件の終了月よりもシーズン終了月の方が未来の場合
        const seasonRangeWidth =
          (dayjs(this.startEndMonths[1]).diff(dayjs(season?.startYearMonth).format("YYYY-MM"), "month") + 1) * 80;
        return `width: ${seasonRangeWidth}px;`;
      } else {
        // 検索条件にシーズン期間が完全に含まれる場合
        const seasonRangeWidth = (dayjs(season?.endYearMonth).diff(season?.startYearMonth, "month") + 1) * 80;
        return `width: ${seasonRangeWidth}px;`;
      }
    },
    seasonMarginStyle(seasonId: number): string {
      const season = this.seasons.find((season) => season.id === seasonId);
      if (
        (dayjs(season?.startYearMonth).isBefore(dayjs(this.startEndMonths[0])) &&
          dayjs(season?.endYearMonth).isAfter(dayjs(this.startEndMonths[1]))) ||
        dayjs(season?.startYearMonth).isBefore(dayjs(this.startEndMonths[0]))
      ) {
        // 検索条件よりシーズンの開始月及び終了月のいずれもはみ出る場合もしくは検索条件の開始月よりもシーズン開始月の方が過去の場合
        return `margin-left: 0px; width;`;
      } else {
        // 検索条件の終了月よりもシーズン終了月の方が未来の場合もしくは検索条件にシーズン期間が完全に含まれる場合
        const seasonRangeMargin = dayjs(season?.startYearMonth).diff(this.startEndMonths[0], "month") * 80;
        return `margin-left: ${seasonRangeMargin}px; width:`;
      }
    },
    max<T>(v1: T, v2: T): T {
      return v1 < v2 ? v2 : v1;
    },
    min<T>(v1: T, v2: T): T {
      return v1 > v2 ? v2 : v1;
    },
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option;
      this.showingAlert = true;
    },
    async clearFilterKey() {
      this.isLoading = true;
      setTimeout(() => {
        this.storeFilters = [];
        this.filterSearch();
      }, 10);
    },
    async search() {
      this.isLoading = true;
      this.tableMonths = [];

      // テーブルヘッダーの取得
      let startTableMonth = dayjs(this.rangeMonths[0]).isSameOrBefore(dayjs(this.rangeMonths[1]))
        ? dayjs(this.rangeMonths[0])
        : dayjs(this.rangeMonths[1]);
      const endTableMonth = startTableMonth.isSame(dayjs(this.rangeMonths[0]))
        ? dayjs(this.rangeMonths[1])
        : dayjs(this.rangeMonths[0]);
      this.startEndMonths = [dayjs(startTableMonth).format("YYYY-MM"), dayjs(endTableMonth).format("YYYY-MM")];
      while (startTableMonth.isSameOrBefore(endTableMonth)) {
        this.tableMonths.push(startTableMonth.format("YY/M"));
        startTableMonth = startTableMonth.add(1, "M");
      }

      // テーブルデータの取得
      const start = this.startEndMonths[0];
      const end = this.startEndMonths[1];
      const allStorePlans = await RadialApiClient.getStoreSeasonPlanTotalEntity(start, end).catch((error) => {
        if (error.response.data.statusCode === 600) {
          this.showAlert(error.response.data.message, "error", -1);
        }
      });
      if (allStorePlans) {
        this.allStorePlans = allStorePlans;
        // 合計の売上予算をk単位に変換
        this.allStorePlans.total.forEach((allStoreMonthlyPlan) => {
          allStoreMonthlyPlan.saleBudget /= 1000;
        });
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
      }
      // 編集モードでない状態の場合は全シーズンの各店舗ごとの情報を再検索する必要がないため空としデータ取得を行わない。
      this.totalStorePlans = this.totalStorePlansEditing ? this.totalStorePlans : [];
      if (this.totalStorePlans.length > 0 && this.selectedSeasonId) {
        // 全シーズン及び該当のシーズンのアコーディオン両方とも開いている場合は再検索
        await this.searchTotalStorePlans(start, end);
        await this.searchSeasonStorePlans(this.selectedSeasonId, start, end);
      } else if (this.totalStorePlans.length > 0) {
        // 全シーズンの各店舗ごとのデータのみを描画している場合は再検索
        this.searchTotalStorePlans(start, end);
      } else if (this.selectedSeasonId) {
        // 該当シーズンの各店舗ごとのデータのみを描画している場合は再検索
        this.searchSeasonStorePlans(this.selectedSeasonId, start, end);
      } else {
        this.filterSearch();
      }
      this.isLoading = false;
    },
    filterSearch() {
      this.isLoading = true;
      const filters =
        this.storeFilters.length > 0
          ? this.storeFilters
          : this.stores.map((store) => {
              return {
                id: store.id,
                name: store.name,
              };
            });
      if (this.totalStorePlans) {
        // 全シーズンのフィルター
        this.totalStorePlans.sort((a, b) => {
          const aName = this.getStoreName(a.storeId);
          const bName = this.getStoreName(b.storeId);
          if (aName > bName) {
            return 1;
          } else {
            return -1;
          }
        });
        this.filteredStorePlans.totalStorePlans = this.totalStorePlans.filter((totalStorePlan) => {
          if (filters.some((filteredStore) => filteredStore.id === "nonOperatingStores")) {
            // フィルター適用されている集計対象外及び、フィルター適用されている稼働店舗を描画
            return (
              this.stores
                .filter((store) => store.operating === false)
                .some((nonOperatingStore) => nonOperatingStore.id === totalStorePlan.storeId) ||
              filters.some((filteredStore) => filteredStore.id === totalStorePlan.storeId)
            );
          } else {
            // フィルター適用されている稼働店舗のみ描画
            return filters.some((filteredStore) => filteredStore.id === totalStorePlan.storeId);
          }
        });
      }
      // 各シーズンごとのフィルター
      if (this.seasonStorePlans) {
        this.seasonStorePlans.sort((a, b) => {
          const aName = this.getStoreName(a.storeId);
          const bName = this.getStoreName(b.storeId);
          if (aName > bName) {
            return 1;
          } else {
            return -1;
          }
        });
        this.filteredStorePlans.seasonStorePlans = this.seasonStorePlans.filter((totalStorePlan) => {
          if (filters.some((filteredStore) => filteredStore.id === "nonOperatingStores")) {
            // フィルター適用されている集計対象外及び、フィルター適用されている稼働店舗を描画
            return (
              this.stores
                .filter((store) => store.operating === false)
                .some((nonOperatingStore) => nonOperatingStore.id === totalStorePlan.storeId) ||
              filters.some((filteredStore) => filteredStore.id === totalStorePlan.storeId)
            );
          } else {
            // フィルター適用されている稼働店舗のみ描画
            return filters.some((filteredStore) => filteredStore.id === totalStorePlan.storeId);
          }
        });
      }
      this.isLoading = false;
    },
    async storeToggle(seasonId: number) {
      this.isLoading = true;
      if (seasonId) {
        // シーズンのアコーディオン変更時
        if (this.selectedSeasonId === seasonId) {
          // シーズンのアコーディオンを閉じる場合
          this.selectedSeasonId = null;
          this.seasonStorePlans = [];
          this.filteredStorePlans.seasonStorePlans = [];
          this.isLoading = false;
        } else if (this.seasonStorePlans.length > 0 && this.selectedSeasonId !== seasonId) {
          // 他のシーズンのアコーディオンを既に開いており、別のシーズンのアコーディオンを開く場合
          this.selectedSeasonId = seasonId;
          this.searchSeasonStorePlans(seasonId, this.startEndMonths[0], this.startEndMonths[1]);
        } else {
          // 新しくシーズンのアコーディオンを開く場合
          this.selectedSeasonId = seasonId;
          this.searchSeasonStorePlans(seasonId, this.startEndMonths[0], this.startEndMonths[1]);
        }
        setTimeout(() => {
          const element = document.getElementById("scroll-target");
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 500);
      } else {
        // 全シーズンのアコーディオン変更時
        if (this.totalStorePlansEditing) {
          this.totalStorePlansEditing = false;
          this.isLoading = false;
        } else {
          this.totalStorePlansEditing = true;
          if (this.totalStorePlans.length === 0) {
            this.searchTotalStorePlans(this.startEndMonths[0], this.startEndMonths[1]);
          } else {
            this.isLoading = false;
          }
        }
      }
    },
    // 全シーズンの各店舗ごとの情報取得処理
    async searchTotalStorePlans(start: string, end: string) {
      const totalStorePlans = await RadialApiClient.getStoreSeasonPlanSeasonTotalEntity(start, end).catch((error) => {
        if (error.response.data.statusCode === 600) {
          this.showAlert(error.response.data.message, "error", -1);
        }
      });
      if (totalStorePlans) {
        this.totalStorePlans = totalStorePlans;
        // 売上予算をk単位に変換
        this.totalStorePlans.forEach((store) => {
          store.storeMonths.forEach((storeMonthlyPlan) => {
            storeMonthlyPlan.saleBudget /= 1000;
          });
        });
        this.filterSearch();
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
      }
      this.isLoading = false;
    },
    // 各シーズンの各店舗ごとの情報取得処理
    async searchSeasonStorePlans(seasonId: number, start: string, end: string) {
      const seasonStorePlans = await RadialApiClient.getStoreSeasonPlanStoreEntity(seasonId, start, end, {
        storeSaleBudgets: this.totalStorePlans.map((storePlan) => {
          return {
            storeId: storePlan.storeId,
            saleBudgets: storePlan.storeMonths.map((storeMonthlyPlan) => {
              return {
                yearMonth: dayjs(storeMonthlyPlan.yearMonth, "YY/MM").format("YYYY-MM"),
                saleBudget: storeMonthlyPlan.saleBudget,
              };
            }),
          };
        }),
        rates:
          this.allStorePlans?.seasons
            .find((season) => season.seasonId === seasonId)
            ?.totalMonths.map((seasonMonthlyPlan) => {
              return {
                yearMonth: dayjs(seasonMonthlyPlan.yearMonth, "YY/MM").format("YYYY-MM"),
                rate: seasonMonthlyPlan.rate,
              };
            }) ?? [],
      }).catch((error) => {
        if (error.response.data.statusCode === 600) {
          this.showAlert(error.response.data.message, "error", -1);
        }
      });
      if (seasonStorePlans) {
        this.defaultSeasonStorePlans = JSON.parse(JSON.stringify(seasonStorePlans));
        if (this.editMode && this.totalStorePlans.length > 0) {
          // 既に全シーズンの各店舗ごとの予算情報を取得している場合（編集していた場合はDBからデータ取得すると整合性が会わなくなるため別途処理とする）
          const selectedSeasonRates: number[] =
            this.allStorePlans?.seasons
              .find((season) => season.seasonId === this.selectedSeasonId)
              ?.totalMonths.map((totalMonth) => totalMonth.rate) ?? [];
          this.seasonStorePlans = seasonStorePlans.map((storePlan) => {
            return {
              storeId: storePlan.storeId,
              storeMonths: storePlan.storeMonths.map((storeMonth, monthIndex) => {
                const totalStoreSaleBudget = this.totalStorePlans.find(
                  (totalStorePlan) => totalStorePlan.storeId === storePlan.storeId
                )?.storeMonths[monthIndex].saleBudget;
                return {
                  // 全シーズンの該当の店舗及び月の合計売上予算とシーズン比を掛け合わせる
                  saleBudget: totalStoreSaleBudget
                    ? (1000 * totalStoreSaleBudget * selectedSeasonRates[monthIndex]) / 100
                    : 0,
                  saleResult: storeMonth.saleResult,
                  refSeasonSaleResult: storeMonth.refSeasonSaleResult,
                  yearMonth: storeMonth.yearMonth,
                };
              }),
            };
          });
        } else {
          this.seasonStorePlans = seasonStorePlans;
        }
        // 売上予算をk単位に変換
        this.seasonStorePlans.forEach((store) => {
          store.storeMonths.forEach((storeMonthlyPlan) => {
            storeMonthlyPlan.saleBudget /= 1000;
          });
        });
        this.filterSearch();
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
      }
      this.isLoading = false;
    },
    async changeYearMonthRange() {
      this.isCanceled = false;
      this.rangeMonthsMenu = false;
      await this.search();
    },
    sortList(index: number) {
      switch (index) {
        case 0:
          this.seasons.sort((a, b) => {
            if (a.startYearMonth > b.startYearMonth) {
              return 1;
            } else {
              return -1;
            }
          });
          break;
        case 1:
          this.stores.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else {
              return -1;
            }
          });
          break;
        default:
          return "";
      }
    },
    getSeasonDetail(seasonId: number): SeasonInfo {
      const season = this.seasons.find((item) => item.id === seasonId);
      return {
        seasonId: seasonId,
        seasonName: season?.name ?? "参照シーズン",
        seasonYearMonth: `${dayjs(season?.startYearMonth).format("YY/MM")} 〜 ${dayjs(season?.endYearMonth).format(
          "YY/MM"
        )}`,
      };
    },
    getStoreName(storeId: string): string {
      return this.stores.find((item) => item.id === storeId)?.name ?? "";
    },
    showSeasonDetailInNewTab(seasonId: string, page: string) {
      const routeData = this.$router.resolve({
        name: page === "season-plan" ? "AdminSeasonPlan" : "AdminMdMap",
        params: { seasonId },
      });
      window.open(routeData.href, "_blank");
    },
    async deselectStoreSeasonBudget() {
      this.editMode = false;
      await this.search();
    },
    async submitStoreSeasonBudget() {
      this.isLoading = true;
      await RadialApiClient.updateStoreSeasonPlan({
        // シーズンの月別売り上げ構成比一覧
        budgetSeasonRateList:
          this.allStorePlans?.seasons.map((season) => {
            return {
              seasonId: season.seasonId,
              monthBudgetSeasonRate: season.totalMonths
                .filter((item) => item.rate !== null)
                .map((seasonMonth) => {
                  return {
                    yearMonth: seasonMonth.yearMonth,
                    rate: seasonMonth.rate,
                  };
                }),
            };
          }) ?? [],
        // 店舗の月別予算一覧
        storeBudget:
          this.totalStorePlans.map((storePlan) => {
            return {
              storeId: storePlan.storeId,
              monthStoreBudget: storePlan.storeMonths.map((storeMonth) => {
                return {
                  yearMonth: storeMonth.yearMonth,
                  saleBudget: storeMonth.saleBudget * 1000,
                };
              }),
            };
          }) ?? [],
      })
        .then(() => {
          this.showAlert(
            `無事に更新が完了しました！\nダッシュボード画面には24時間以内に反映されます。\n以下のページでは数分以内に最新の予算データを確認することができます。\n\n・店舗-StoreActuals\n・店舗-日報\n・MD計画-売上仕入計画\n・店舗日別予算設定`,
            "success",
            -1
          );
        })
        .catch(() => {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error",
            -1
          );
        });
      this.editMode = false;
      this.isLoading = false;
    },
    sumTotal(index: number): number {
      switch (index) {
        // 合計の売上予算
        case 0: {
          return (
            this.allStorePlans?.total.reduce((sumResult, item) => {
              return sumResult + item.saleBudget;
            }, 0) ?? 0
          );
        }
        // 合計の売上実績
        case 1: {
          return (
            this.allStorePlans?.total.reduce((sumResult, item) => {
              return sumResult + item.saleResult;
            }, 0) ?? 0
          );
        }
        // 合計の昨年売上実績
        case 2: {
          return (
            this.allStorePlans?.total.reduce((sumResult, item) => {
              return sumResult + item.lastYearSaleResult;
            }, 0) ?? 0
          );
        }
        // 合計の昨年比
        case 3: {
          const lastYearTotal =
            this.allStorePlans?.total.reduce((sumResult, item) => {
              return sumResult + item.lastYearSaleResult;
            }, 0) ?? 0;
          const thisYearTotal =
            this.allStorePlans?.total.reduce((sumResult, item) => {
              return sumResult + item.saleResult;
            }, 0) ?? 0;
          return (100 * thisYearTotal) / lastYearTotal;
        }
        default:
          return 0;
      }
    },
    sumStoreTotal(totalMonths: TotalTotalMonthEntity[], index: number): number {
      switch (index) {
        case 0: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleBudget;
          }, 0);
        }
        case 1: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleResult;
          }, 0);
        }
        case 2: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.lastYearSaleResult;
          }, 0);
        }
        case 3: {
          const lastYearTotal =
            totalMonths.reduce((sumResult, item) => {
              return sumResult + item.lastYearSaleResult;
            }, 0) ?? 0;
          const thisYearTotal =
            totalMonths.reduce((sumResult, item) => {
              return sumResult + item.saleResult;
            }, 0) ?? 0;
          return lastYearTotal === 0 ? 0 : (100 * thisYearTotal) / lastYearTotal;
        }
        default:
          return 0;
      }
    },
    sumSeasonTotal(totalMonths: SeasonTotalMonthEntity[], index: number): number {
      switch (index) {
        // 合計のシーズン売上予算
        case 0: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleBudget;
          }, 0);
        }
        // 合計のシーズン売上実績
        case 1: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleResult;
          }, 0);
        }
        // 合計の参照シーズン売上実績
        case 2: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.refSeasonSaleResult;
          }, 0);
        }
        // 合計のシーズン比
        case 3: {
          const refSeasonTotal = totalMonths.reduce((sumResult, item) => {
            return sumResult + item.refSeasonSaleResult;
          }, 0);
          const thisSeasonTotal = totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleResult;
          }, 0);
          return refSeasonTotal === 0 ? 0 : (100 * thisSeasonTotal) / refSeasonTotal;
        }
        // シーズンの合計月別売上構成比予算（全体の売上予算に占めるシーズンの売上予算割合）
        case 4: {
          const totalSaleBudget =
            this.allStorePlans?.total.reduce((sumResult, item) => {
              return sumResult + item.saleBudget;
            }, 0) ?? 0;
          const seasonTotalSaleBudget = totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleBudget;
          }, 0);
          return totalSaleBudget === 0 ? 0 : seasonTotalSaleBudget / totalSaleBudget / 10;
        }
        default:
          return 0;
      }
    },
    sumSeasonStoreTotal(totalMonths: StoreMonthEntity[], index: number): number {
      switch (index) {
        case 0: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleBudget;
          }, 0);
        }
        case 1: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleResult;
          }, 0);
        }
        case 2: {
          return totalMonths.reduce((sumResult, item) => {
            return sumResult + item.refSeasonSaleResult;
          }, 0);
        }
        case 3: {
          const thisSeasonTotal = totalMonths.reduce((sumResult, item) => {
            return sumResult + item.saleResult;
          }, 0);
          const refSeasonTotal = totalMonths.reduce((sumResult, item) => {
            return sumResult + item.refSeasonSaleResult;
          }, 0);
          return refSeasonTotal === 0 ? 0 : (100 * thisSeasonTotal) / refSeasonTotal;
        }
        default:
          return 0;
      }
    },
    editStoreSaleBudget(monthIndex: number) {
      if (this.allStorePlans) {
        // 合計売上予算の更新
        const totalBudget = (this.allStorePlans.total[monthIndex].saleBudget = this.totalStorePlans.reduce(
          (sumResult, item) => {
            return sumResult + item.storeMonths[monthIndex].saleBudget;
          },
          0
        ));
        // シーズン合計の売上予算の更新
        this.allStorePlans.seasons.forEach((season) => {
          season.totalMonths[monthIndex].saleBudget = (1000 * totalBudget * season.totalMonths[monthIndex].rate) / 100;
        });
        // 各シーズン及び各店舗の売上予算の更新
        if (this.selectedSeasonId && this.seasonStorePlans.length > 0) {
          this.seasonStorePlans.forEach((store) => {
            const storeTotalSaleBudget =
              this.totalStorePlans.find((totalStorePlan) => totalStorePlan.storeId === store.storeId)?.storeMonths[
                monthIndex
              ].saleBudget ?? 0;
            const rate =
              this.allStorePlans?.seasons.find((season) => season.seasonId === this.selectedSeasonId)?.totalMonths[
                monthIndex
              ].rate ?? 0;
            store.storeMonths[monthIndex].saleBudget = (storeTotalSaleBudget * rate) / 100;
          });
        }
      }
    },
    editSeasonRate(rate: number, seasonIndex: number, monthIndex: number) {
      if (this.allStorePlans) {
        // 更新前の該当のシーズン月の売上予算
        const seasonMonthTotalBudget = this.defaultSeasonStorePlans.reduce((sumResult, info) => {
          return sumResult + info.storeMonths[monthIndex].saleBudget;
        }, 0);
        // 全シーズンの該当月の売上予算
        const totalSaleBudget = this.allStorePlans?.total[monthIndex].saleBudget ?? 0;
        // 該当のシーズン月の売上予算の更新
        this.allStorePlans.seasons[seasonIndex].totalMonths[monthIndex].saleBudget =
          (totalSaleBudget * 1000 * rate) / 100;
        // 該当のシーズンにおける各店舗ごとの売上予算の更新
        if (this.totalStorePlans.length === 0 && this.selectedSeasonId) {
          // 全シーズンのアコーディオンが一度も開かれていない && シーズンのアコーディオンが開いている場合（全シーズンの店舗予算がわからないが各店舗シーズンの売上予算を計算する必要がある場合）
          // 該当のシーズン,月における各店舗の売上予算 = 該当のシーズン, 月の売上予算 * シーズンに占める店舗予算比率
          this.seasonStorePlans.forEach((item) => {
            const defaultBudget = this.defaultSeasonStorePlans.find((storePlan) => storePlan.storeId === item.storeId)
              ?.storeMonths[monthIndex].saleBudget;
            item.storeMonths[monthIndex].saleBudget = defaultBudget
              ? ((totalSaleBudget * rate) / 100) * (defaultBudget / seasonMonthTotalBudget)
              : 0;
          });
        } else {
          this.seasonStorePlans.forEach((item) => {
            const storeTotalBudget =
              this.totalStorePlans.find((store) => store.storeId === item.storeId)?.storeMonths[monthIndex]
                .saleBudget ?? 0;
            item.storeMonths[monthIndex].saleBudget = (storeTotalBudget * rate) / 100;
          });
        }
      }
    },
  },
  watch: {
    rangeMonthsMenu: {
      async handler() {
        if (this.rangeMonthsMenu) {
          this.cancelDates = this.rangeMonths;
          this.isCanceled = true;
        } else if (!this.rangeMonthsMenu && this.isCanceled) {
          this.rangeMonths = this.cancelDates;
        }
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.isAdmin) {
      this.user = await AuthClient.getUserInfo();
      if (this.user) {
        this.seasons = (await RadialApiClient.listSeasons()) ?? [];
        this.sortList(0);
        this.stores = (await RadialApiClient.listStores()) ?? [];
        this.sortList(1);
        this.operatingStores = this.stores
          .filter((store) => store.operating === true)
          .map((filteredStore) => {
            return {
              id: filteredStore.id,
              name: filteredStore.name,
            };
          });
        if (this.stores.some((store) => store.operating === false)) {
          this.operatingStores.push({
            id: "nonOperatingStores",
            name: "集計対象外",
          });
        }
        await this.search();
      }
    }

    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/store-season-plan",
        page_title: "Admin店舗シーズン売上計画画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/season",
        page_title: "Staff店舗シーズン売上計画画面",
      });
    }
    this.isLoading = false;
  },
});
