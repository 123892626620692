
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { Factory } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import { UpdateFactoryDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface NewFactory {
  name: string;
  code: string;
  address: string;
  phoneNumber: string;
}

export interface DataType {
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  showingAlert: boolean;
  user: User | null;
  activeItem: Factory | null;
  factories: Factory[];
  selectedFactory: Factory | null;
  dialogEdit: boolean;
  dialogAdd: boolean;
  dialogDelete: boolean;
  isLoading: boolean;
  newFactory: NewFactory;
  page: number;
  pageSize: number;
  list: Factory[];
  listCount: number;
}

export default Vue.extend({
  name: "Factories",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    Pagination,
    Loading,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      showingAlert: false,
      user: null,
      activeItem: null,
      factories: [],
      selectedFactory: null,
      dialogEdit: false,
      dialogAdd: false,
      dialogDelete: false,
      isLoading: false,
      newFactory: {
        name: "",
        code: "",
        address: "",
        phoneNumber: "",
      },
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  computed: {
    editFactoryDisabled(): boolean {
      if (this.selectedFactory && Object.values(this.selectedFactory).includes("")) {
        return true;
      } else if (
        this.factories
          .filter((factory) => factory.id !== this.selectedFactory?.id)
          .some((filteredFactory) => filteredFactory.code === this.selectedFactory?.code)
      ) {
        // 既存の仕入れ先とコードが一致する場合
        return true;
      } else {
        return false;
      }
    },
    addFactoryDisabled(): boolean {
      if (Object.values(this.newFactory).includes("")) {
        return true;
      } else if (this.factories.some((factory) => factory.code === this.newFactory.code)) {
        // 既存の仕入れ先とコードが一致する場合
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    listActive(item: Factory) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    sortList() {
      this.list.sort((a, b) => {
        if (a.code > b.code) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    selectFactory(factory: Factory, index: number) {
      this.selectedFactory = { ...factory };
      if (index === 0) {
        this.dialogEdit = true;
      } else {
        this.dialogDelete = true;
      }
    },
    deselectFactory(index: number) {
      this.selectedFactory = null;
      if (index === 0) {
        this.dialogEdit = false;
      } else {
        this.dialogDelete = false;
      }
    },
    async deleteFactory() {
      this.isLoading = true;
      if (this.selectedFactory) {
        await RadialApiClient.deleteFactory(this.selectedFactory.id);
        const list = await RadialApiClient.listFactories();
        if (list) {
          this.list = list;
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.isLoading = false;
      this.selectedFactory = null;
      this.dialogDelete = false;
      this.showAlert("無事に削除が完了しました！", "success", -1);
    },
    async editFactory() {
      this.isLoading = true;
      if (this.selectedFactory) {
        await RadialApiClient.updateFactory(this.selectedFactory.id, {
          name: this.selectedFactory.name,
          code: this.selectedFactory.code,
          address: this.selectedFactory.address,
          phoneNumber: this.selectedFactory.phoneNumber,
        } as UpdateFactoryDto);
        const list = await RadialApiClient.listFactories();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.isLoading = false;
      this.selectedFactory = null;
      this.dialogEdit = false;
    },
    async addFactory() {
      this.isLoading = true;
      const createdFactory = await RadialApiClient.createFactory({
        company: {
          connect: {
            id: this.user?.companyId,
          },
        },
        name: this.newFactory.name,
        code: this.newFactory.code,
        address: this.newFactory.address,
        phoneNumber: this.newFactory.phoneNumber,
      });
      if (createdFactory) {
        this.list.push(createdFactory);
        this.listCount = this.list.length;
        this.sortList();
        this.updatePage(this.page);
        this.showAlert("新しく仕入れ先が作成されました！", "success", -1);
      }
      this.newFactory = {
        name: "",
        code: "",
        address: "",
        phoneNumber: "",
      };
      this.isLoading = false;
      this.dialogAdd = false;
    },
    cancelAddFactory() {
      this.dialogAdd = false;
      this.newFactory = {
        name: "",
        code: "",
        address: "",
        phoneNumber: "",
      };
    },
    getAlertMessage(): string {
      return `本当に${this.selectedFactory?.name ?? ""}を削除してもよろしいですか？`;
    },
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.factories = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.isLoading = true;
    const user = await AuthClient.getUserInfo();
    if (user) {
      this.user = user;
      const list = await RadialApiClient.listFactories();
      if (list) {
        this.list = list;
        this.sortList();
        this.listCount = this.list.length;
        if (this.listCount < this.pageSize) {
          this.factories = this.list;
        } else {
          this.factories = this.list.slice(0, this.pageSize);
        }
      }
    }
    this.isLoading = false;
  },
});
