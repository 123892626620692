
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import DashboardChart from "@/views/dashboard/DashboardChart.vue";
import DashboardSaleRanking from "@/views/dashboard/DashboardSaleRanking.vue";
import DashboardSeason from "@/views/dashboard/DashboardSeason.vue";
import { Constant } from "@/store/constant";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  isChartLoading: boolean;
  isRankingLoading: boolean;
  isSeasonLoading: boolean;
  isTablet: boolean;
  isLandScape: boolean;
}

export default Vue.extend({
  name: "Dashboard",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    DashboardChart,
    DashboardSaleRanking,
    DashboardSeason,
    Loading,
  },
  data(): DataType {
    return {
      isChartLoading: true,
      isRankingLoading: true,
      isSeasonLoading: true,
      isTablet: false,
      isLandScape: false,
    };
  },
  methods: {
    changeSeasonLoadingStatus(status: boolean) {
      this.isSeasonLoading = status;
    },
    changeChartLoadingStatus(status: boolean) {
      this.isChartLoading = status;
    },
    changeRankingLoadingStatus(status: boolean) {
      this.isRankingLoading = status;
    },
    changeRotation() {
      if (screen.orientation.type.includes("landscape")) {
        this.isLandScape = true;
      } else {
        this.isLandScape = false;
      }
    },
  },
  computed: {
    isLoading(): boolean {
      if (this.isAdmin) {
        return this.isChartLoading || this.isRankingLoading || this.isSeasonLoading;
      } else {
        return this.isChartLoading || this.isRankingLoading;
      }
    },
  },
  mounted() {
    // タブレットを使っているのか判断
    this.isTablet = Constant.isUsingTablet();
    if (this.isTablet) {
      // タブレットが横向きかどうか判断
      this.isLandScape = screen.orientation.type.includes("landscape") ? true : false;
      // タブレットが回転した場合のコールバック登録
      window.addEventListener("orientationchange", this.changeRotation);
    }
    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/dashboard",
        page_title: "Adminダッシュボード画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/dashboard",
        page_title: "Staffダッシュボード画面",
      });
    }
  },
});
