
/*
  [ConfirmationDialog]
    <必須>
    - v-model = dialogを表示するかを決める(boolean)
    - :buttonName = ボタンに表示する文言(undefinedか空文字列の場合はボタンを表示させない)
    - :sentence = dialogに表示する文言
    - :type = alertのtype(success、info、warning、errorのいずれかを指定)

    <任意>
    - @clickOutside = dialogの外側をクリックした時に呼ばれるイベント
    - @cancel = キャンセルボタンを押した時に呼ばれるイベント
    - @clickButton = ボタンを押した時に呼ばれるイベント
*/
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    buttonName: {
      type: String,
      default: "",
    },
    sentence: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    showingAlert: {
      get() {
        return (this as any).value;
      },
      set(newValue) {
        (this as any).$emit("input", newValue);
      },
    },
  },
  methods: {
    getMaterialDesignIcon(type: string): string {
      switch (type) {
        case "success":
          return "mdi-checkbox-marked-circle";
        case "info":
          return "mdi-help-circle";
        case "warning":
          return "mdi-alert";
        case "error":
          return "mdi-alert";
        case "question":
          return "mdi-mushroom";
        default:
          return "";
      }
    },
    getColor(type: string): string {
      switch (type) {
        case "success":
          return "radialPrimaryGreen70";
        case "info":
          return "radialInfo70";
        case "warning":
          return "radialWarning";
        case "error":
          return "radialDanger70";
        case "question":
          return "radialSecondaryGray70";
        default:
          // "error"
          return "radialDanger70";
      }
    },
    getClass(type: string): string {
      switch (type) {
        case "success":
          return "component-success-font-button";
        case "info":
          return "component-info-font-button";
        default:
          // "warning" or "error" or "question"
          return "component-danger-font-button";
      }
    },
    isDisplayButton(): boolean {
      if (!this.buttonName) {
        return false;
      } else {
        return true;
      }
    },
  },
});
