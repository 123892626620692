import AuthClient from "@/api/AuthClient";
import { Company, AdminUser, Store } from "@/api/entities";
import S3Client from "@/api/S3Client";
import Vue from "vue";
import Vuex from "vuex";
import { CommentFilter, SearchEntries, User } from "./model";
import { AdminUserManager } from "./AdminUserManager";
import createPersistedState from "vuex-persistedstate";
import { DigestRateBase } from "./constant";

Vue.use(Vuex);

export interface State {
  company: Company | null;
  user: User | null;
  adminUser: AdminUser | null;
  store: Store | null;
  imageKeys: string[];
  searchEntries: SearchEntries | null;
  version: string | null;
  commentFilter: CommentFilter | null;
  digestRateBase: DigestRateBase;
}

export default new Vuex.Store<State>({
  plugins: [createPersistedState()],
  state: {
    company: null,
    user: null,
    adminUser: null,
    store: null,
    imageKeys: [],
    searchEntries: null,
    version: null,
    commentFilter: null,
    digestRateBase: "quantity",
  },
  mutations: {
    setCompany(state, payload: { company: Company }) {
      state.company = payload.company;
    },
    setUser(state, payload: { user: User }) {
      state.user = payload.user;
    },
    setAdminUser(state, payload: { adminUser: AdminUser }) {
      state.adminUser = payload.adminUser;
    },
    setImageKeys(state, payload: { imageKeys: string[] }) {
      state.imageKeys = payload.imageKeys;
    },
    setSearchEntries(state, payload: { searchEntries: SearchEntries }) {
      state.searchEntries = payload.searchEntries;
    },
    setVersion(state, payload: { version: string }) {
      state.version = payload.version;
    },
    setCommentFilter(state, payload: { commentFilter: CommentFilter }) {
      state.commentFilter = payload.commentFilter;
    },
    setStore(state, payload: { store: Store }) {
      state.store = payload.store;
    },
    setDigestRateBase(state, payload: { digestRateBase: DigestRateBase }) {
      state.digestRateBase = payload.digestRateBase;
    },
  },
  actions: {
    async updateUser(context) {
      const user = await AuthClient.getUserInfo();
      context.commit("setUser", { user });
    },
    async updateImageKeys(context, payload: { companyId: string }) {
      const imageKeys = await S3Client.list(payload.companyId);
      context.commit("setImageKeys", { imageKeys });
    },
  },
  modules: {},
});

// export default store;
