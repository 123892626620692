
/* [CustomSelector]
  <必須>
  - v-model = セレクトされたもの
  - items = セレクト一覧で表示するもの
  
  <任意>
  - label = セレクタのタイトル
  - itemText = itemsの中でセレクト一覧で表示されるキー
  - itemValue = itemsの中でv-modelに格納されるキー
  - @change = セレクトしたときに呼ばれるイベント
s*/
import Vue from "vue";
export default Vue.extend({
  name: "CustomSelector",
  props: {
    value: {
      type: [String, Number, Object],
      required: true,
    },
    items: {
      type: Array as () => any[],
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    itemText: {
      type: String,
      required: false,
    },
    itemValue: {
      type: String,
      required: false,
    },
  },
  computed: {
    model: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      },
    },
    itemAttributes(): Record<string, string> {
      let attrs: Record<string, string> = {};
      if (this.itemText) {
        attrs["item-text"] = this.itemText;
      }
      if (this.itemValue) {
        attrs["item-value"] = this.itemValue;
      }
      if (this.label) {
        attrs["label"] = this.label;
      }
      return attrs;
    },
    mergedAttributes(): Record<string, any> {
      return {
        ...this.$attrs,
        ...this.itemAttributes,
        items: this.items,
      };
    },
  },
});
