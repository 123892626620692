
import Vue from "vue";
import S3Image from "@/components/aws/S3Image.vue";
import {
  Comment,
  SkuWithColorSize,
  ProductDetailBasicInfoEntity,
  Factory,
  ProductCustomValue,
  Company,
  CustomValueType,
} from "@/api/entities";
import {
  UpdateProductAttribute1RelationInputDto,
  UpdateProductBasicInfoDto,
  CreateProductCustomValueDto,
  UpdateProductCustomValueDto,
} from "@/api/dto";
import RadialApiClient from "@/api/RadialApiClient";
import { Constant } from "@/store/constant";
import { Attribute, Season, Brand, Category, GetDetailImagesEntity } from "@/api/entities";
import Loading from "@/components/vuetify/Loading.vue";
import * as lodash from "lodash";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import CustomSelector from "@/components/vuetify/Selector.vue";
import CustomTextField from "@/components/vuetify/TextField.vue";
import mixin from "@/mixin/mixin";
import store from "@/store";

export interface DataType {
  product: ProductDetailBasicInfoEntity | null;
  temporaryProduct: ProductDetailBasicInfoEntity | null;
  dialogInfo: boolean;
  dialogSku: boolean;
  productSalesStartedAtMenu: boolean;
  productsalesEndedAtMenu: boolean;
  productPriceUpdatedAtMenu: boolean;
  dialogDescription: boolean;
  dialogFreeComment: boolean;
  dialogComposition: boolean;
  images: string[];
  isVisible: number;
  attributes: Attribute[];
  attributesName: string[];
  factories: Factory[];
  seasons: Season[];
  brands: Brand[];
  categories: Category[];
  seasonId: number | null;
  brandId: number | null;
  categoryId: number | null;
  factoryId: string | null;
  productDetailImages: GetDetailImagesEntity;
  isLoading: boolean;
  editingSkus: { [color: string]: SkuWithColorSize[] };
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
}
export default Vue.extend({
  mixins: [mixin],
  components: {
    CustomSelector,
    CustomTextField,
    S3Image,
    Loading,
    ConfirmationDialog,
  },
  props: {
    propProduct: {
      type: Object as () => ProductDetailBasicInfoEntity,
      default: null,
    },
    propImages: {
      type: Array as () => string[],
      required: true,
    },
    isTablet: {
      type: Boolean,
      required: true,
    },
  },
  data(): DataType {
    return {
      product: null,
      temporaryProduct: null,
      dialogInfo: false,
      dialogSku: false,
      productSalesStartedAtMenu: false,
      productsalesEndedAtMenu: false,
      productPriceUpdatedAtMenu: false,
      dialogDescription: false,
      dialogFreeComment: false,
      dialogComposition: false,
      images: [],
      isVisible: 0,
      attributes: [],
      attributesName: ["", "", ""],
      factories: [],
      seasons: [],
      brands: [],
      categories: [],
      seasonId: null,
      brandId: null,
      categoryId: null,
      factoryId: null,
      productDetailImages: { s3KeyMain: "", s3KeySkus: [] },
      isLoading: false,
      editingSkus: {},
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
    };
  },
  computed: {
    genderOptions(): string[] {
      return Constant.genderOptions;
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
    percentOff(): string {
      if (this.propProduct) {
        if (
          this.propProduct.salesPrice &&
          this.propProduct.price &&
          this.propProduct.salesPrice < this.propProduct.price
        ) {
          const off = 100 - (this.propProduct.salesPrice / this.propProduct.price) * 100;
          return `(${Math.floor(off)}% OFF)`;
        }
      }
      return "";
    },
    skus(): { [color: string]: SkuWithColorSize[] } {
      const dict: { [color: string]: SkuWithColorSize[] } = {};
      if (this.product?.skus) {
        for (const sku of this.product.skus) {
          if (sku.color.name) {
            if (dict[sku.color.name]) {
              dict[sku.color.name].push(sku);
            } else {
              dict[sku.color.name] = [sku];
            }
          }
        }
      }
      return dict;
    },
    attribute1Name(): string {
      return this.product?.attribute1?.name ?? "";
    },
    attribute2Name(): string {
      return this.product?.attribute2?.name ?? "";
    },
    attribute3Name(): string {
      return this.product?.attribute3?.name ?? "";
    },
  },
  methods: {
    productCustomValueLabelType(customValueType: CustomValueType): string {
      switch (customValueType) {
        case "STRING":
          return "文字列";
        case "NUMBER":
          return "数値";
        case "DATE":
          return "日付";
        default:
          return "";
      }
    },
    isSkuEmputy(): boolean {
      if (Object.keys(this.skus).length === 0) {
        return true;
      } else {
        return false;
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    replaceBr(str: string | null): string | null {
      if (str) {
        return str.replaceAll(/< *\/? *br *\/? *>/g, "\n");
      } else {
        return null;
      }
    },
    isNumber(event: KeyboardEvent) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    async showDialogInfo() {
      this.isLoading = true;
      this.attributes = (await RadialApiClient.listAttributes()) ?? [];
      this.factories = (await RadialApiClient.listFactories()) ?? [];
      this.dialogInfo = true;
      this.temporaryProduct = lodash.cloneDeep(this.product);
      this.isLoading = false;
    },
    async showDialogSku() {
      this.editingSkus = lodash.cloneDeep(this.skus);
      this.dialogSku = true;
    },
    getAttributeId(index: number): UpdateProductAttribute1RelationInputDto | null {
      return this.attributes
        .filter((attribute) => attribute.number === index + 1)
        .find((attribute) => attribute.name === this.attributesName[index])?.id
        ? {
            connect: {
              id:
                this.attributes
                  .filter((attribute) => attribute.number === index + 1)
                  .find((attribute) => attribute.name === this.attributesName[index])?.id ?? "",
            },
          }
        : null;
    },
    updateProduct() {
      const attribute1 = this.getAttributeId(0);
      const attribute2 = this.getAttributeId(1);
      const attribute3 = this.getAttributeId(2);
      if (this.isAdmin && this.product) {
        const dto: UpdateProductBasicInfoDto = {
          name: this.product.name ?? undefined,
          itemNumber: this.product.itemNumber ?? undefined,
          gender: this.product.gender ?? undefined,
          store: this.product.store ?? undefined,
          attribute1: attribute1 ?? undefined,
          attribute2: attribute2 ?? undefined,
          attribute3: attribute3 ?? undefined,
          price: this.product.price ?? undefined,
          salesPrice: this.product.salesPrice ?? undefined,
          salesStartedAt: this.product.salesStartedAt ?? undefined,
          salesEndedAt: this.product.salesEndedAt ?? undefined,
          salesPriceUpdatedAt: this.product.salesPriceUpdatedAt ?? undefined,
          description: this.product.description ?? undefined,
          composition: this.product.composition ?? undefined,
          freeComment: this.product.freeComment ?? undefined,
          season: this.seasonId ? { connect: { id: this.seasonId } } : undefined,
          brand: this.brandId ? { connect: { id: this.brandId } } : undefined,
          category: this.categoryId ? { connect: { id: this.categoryId } } : undefined,
          factory: this.factoryId ? { connect: { id: this.factoryId } } : undefined,
          leadTime: Number(this.temporaryProduct?.leadTime),
          minimumOrderQuantity: this.temporaryProduct?.minimumOrderQuantity
        };
        this.$emit("product-update", { productId: this.product.id, dto: dto });
      }
    },
    async saveInfo() {
      this.dialogInfo = false;
      await this.updateCustomValue();
      (this.product = this.temporaryProduct), false;
      this.updateProduct();
    },
    async saveSku() {
      let errorMessages: { status: number; color: string; size: string }[] = [];
      for (const color in this.editingSkus) {
        for (const [index, sku] of this.editingSkus[color].entries()) {
          if (sku.janCode !== this.skus[color][index].janCode || sku.cost !== this.skus[color][index].cost) {
            await RadialApiClient.updateSku(sku.id, {
              janCode: sku.janCode,
              cost: sku.cost,
            }).catch((error) => {
              errorMessages.push({ status: error.response?.data.statusCode, color, size: sku.size.name ?? "" });
            });
          }
        }
      }
      if (errorMessages.length === 0) {
        this.showAlert("SKU情報を更新しました！", "success");
      } else {
        this.showAlert(this.getErrorMessage(errorMessages), "error");
      }
      if (this.product) {
        this.$emit("product-get", { productId: this.product.id });
      }
      this.dialogSku = false;
    },
    getErrorMessage(errorMessages: { status: number; color: string; size: string }[]): string {
      const overLappingErrorMessages = errorMessages.filter((message) => message.status === 600);
      const otherErrorMessages = errorMessages.filter((message) => message.status !== 600);
      let message = "";
      if (overLappingErrorMessages.length !== 0) {
        message = message.concat("以下の組み合わせでjanCodeが重複しているため、SKU情報を更新できませんでした。\n");
        for (const errorMessage of overLappingErrorMessages) {
          message = message.concat(`\n・${errorMessage.color} × ${errorMessage.size}`);
        }
        message = message.concat("\n\n");
      }
      if (otherErrorMessages.length !== 0) {
        message = message.concat("以下の組み合わせでエラーが発生したため、SKU情報を更新できませんでした。\n");
        for (const errorMessage of otherErrorMessages) {
          message = message.concat(`\n・${errorMessage.color} × ${errorMessage.size}`);
        }
        message = message.concat("\n\n");
      }
      message = message.concat("入力内容をご確認のうえ、もう一度お試しください。");
      return message;
    },
    showDialogDescription() {
      this.dialogDescription = true;
      this.temporaryProduct = this.product;
    },
    saveDescription() {
      this.dialogDescription = false;
      (this.product = this.temporaryProduct), false;
      this.updateProduct();
    },
    showDialogFreeComment() {
      this.dialogFreeComment = true;
      this.temporaryProduct = this.product;
    },
    saveFreeComment() {
      this.dialogFreeComment = false;
      (this.product = this.temporaryProduct), false;
      this.updateProduct();
    },
    showDialogComposition() {
      this.dialogComposition = true;
      this.temporaryProduct = this.product;
    },
    saveComposition() {
      this.dialogComposition = false;
      (this.product = this.temporaryProduct), false;
      this.updateProduct();
    },
    getTarget(comment: Comment): string {
      if (comment.skuId && comment.skuId !== "null") {
        if (this.product?.skus) {
          const filteredSkus = this.product.skus.filter((sku) => sku.id === comment.skuId);
          if (filteredSkus.length > 0) {
            const sku = filteredSkus[0];
            return `${sku.color.name}-${sku.size.name}`;
          }
        }
      }
      return "品番";
    },
    async hideEditDialog() {
      this.dialogInfo = false;
      this.dialogSku = false;
      this.dialogComposition = false;
      this.dialogDescription = false;
      this.dialogFreeComment = false;
      if (this.product) {
        this.product = await RadialApiClient.getProductDetailBasicInfo(this.product.id);
      }
    },
    getTargetName(target: number): string[] {
      const list: string[] = [];
      if (target & 0b10000) {
        list.push("色味");
      }
      if (target & 0b01000) {
        list.push("サイズ感");
      }
      if (target & 0b00100) {
        list.push("仕様");
      }
      if (target & 0b00010) {
        list.push("素材・質感");
      }
      if (target & 0b00001) {
        list.push("その他");
      }
      return list;
    },
    getPositivityName(positivity: number) {
      if (positivity == 0) {
        return "ポジティブ";
      } else if (positivity == 1) {
        return "ネガティブ";
      } else {
        return "中立";
      }
    },
    getMainImage(colorName: string): string {
      if (this.skus[colorName] && this.product) {
        const skuIds = this.skus[colorName].map((sku) => sku.id);
        for (const skuId of skuIds) {
          return this.productDetailImages.s3KeySkus.find((item) => item.skuId === skuId)?.s3KeyWithId[0]?.s3Key ?? "";
        }
      }
      return "";
    },
    getAttributeNames(n: number): string[] {
      return this.attributes.filter((attribute) => attribute.number === n).map((attribute) => attribute.name);
    },
    inputNumber(color: string, sizeIndex: number) {
      this.editingSkus[color][sizeIndex].cost =
        Number(
          this.editingSkus[color][sizeIndex].cost?.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
        ) ?? 0;
    },
    async updateCustomValue() {
      if (this.product && this.temporaryProduct && store.state.company) {
        for (const temporaryProductCustomValue of this.temporaryProduct.productCustomValues) {
          const productCustomValue = this.product.productCustomValues.find(
            (productCustomValue) =>
              productCustomValue.productCustomValueSettingId === temporaryProductCustomValue.productCustomValueSettingId
          );
          if (
            productCustomValue &&
            productCustomValue.productCustomValueName !== temporaryProductCustomValue.productCustomValueName
          ) {
            // customValueが更新されている場合レコードを更新
            if (productCustomValue.productCustomValueId.length === 0) {
              // もともとレコードがなく新しいレコードを作成場合
              const dto: CreateProductCustomValueDto = {
                value: String(temporaryProductCustomValue.productCustomValueName),
                productCustomValueSetting: { connect: { id: temporaryProductCustomValue.productCustomValueSettingId } },
                product: { connect: { id: this.product.id } },
                company: { connect: { id: store.state.company.id } },
              };
              await RadialApiClient.createProductCustomValue(dto);
            } else if (temporaryProductCustomValue.productCustomValueName.length === 0) {
              // テキストフィールドを空欄にし、レコードを削除する場合
              await RadialApiClient.deleteProductCustomValue(temporaryProductCustomValue.productCustomValueId);
            } else {
              // レコードの値を更新する場合
              const dto: UpdateProductCustomValueDto = {
                value: String(temporaryProductCustomValue.productCustomValueName),
              };
              await RadialApiClient.updateProductCustomValue(temporaryProductCustomValue.productCustomValueId, dto);
            }
          }
        }
      }
    },
    textToNumber(index: number) {
      if (
        this.temporaryProduct?.productCustomValues[index].productCustomValueSettingType === "NUMBER" &&
        this.temporaryProduct
      ) {
        const customValue = Number(this.temporaryProduct.productCustomValues[index].productCustomValueName);
        this.temporaryProduct.productCustomValues[index].productCustomValueName = String(Number.isNaN(customValue) ? 0 : customValue);
      }
    },
    textToNumberInProduct(){
      if(this.temporaryProduct){
      this.temporaryProduct.leadTime = Number(this.temporaryProduct.leadTime);
      this.temporaryProduct.minimumOrderQuantity = Number(this.temporaryProduct.minimumOrderQuantity)
      }
    }
  },
  watch: {
    propProduct: function (currentProduct: ProductDetailBasicInfoEntity) {
      this.product = currentProduct;
      this.temporaryProduct = currentProduct;
    },
    propImages: function (currentImages: string[]) {
      this.images = currentImages;
    },
  },
  async mounted() {
    this.product = this.propProduct;
    this.attributesName = [
      this.product.attribute1?.name ?? "",
      this.product.attribute2?.name ?? "",
      this.product.attribute3?.name ?? "",
    ];
    this.temporaryProduct = this.propProduct;
    this.images = this.propImages;
    this.seasons = (await RadialApiClient.listSeasons()) ?? [];
    this.brands = (await RadialApiClient.listBrands()) ?? [];
    this.categories = (await RadialApiClient.listCategories()) ?? [];
    this.seasonId = this.product.seasonId;
    this.brandId = this.product.brandId;
    this.categoryId = this.product.categoryId;
    this.factoryId = this.product.factoryId;
    // get product detail images
    const productDetailImages = await RadialApiClient.getProductDetailImages(this.product.id);
    if (productDetailImages) {
      this.productDetailImages = productDetailImages;
    }
  },
});
