
import Vue from "vue";
import S3Image from "@/components/aws/S3Image.vue";
import { ProductDetailBasicInfoEntity, IndividualAlertSetting } from "@/api/entities";
import { CreateIndividualAlertSettingDto, UpdateIndividualAlertSettingDto } from "@/api/dto";
import RadialApiClient from "@/api/RadialApiClient";
import { AlertSetting } from "@/api/entities";
import Loading from "@/components/vuetify/Loading.vue";
import * as lodash from "lodash";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import CustomSelector from "@/components/vuetify/Selector.vue";
import CustomTextField from "@/components/vuetify/TextField.vue";
import mixin from "@/mixin/mixin";
import store from "@/store";

export interface DataType {
  product: ProductDetailBasicInfoEntity | null;
  isLoading: boolean;
  alertSettings: IndividualAlertSetting[];
  savedAlertSettings: IndividualAlertSetting[];
  alertSettingDatas: AlertSetting[];
}
export default Vue.extend({
  mixins: [mixin],
  components: {
    CustomSelector,
    CustomTextField,
    S3Image,
    Loading,
    ConfirmationDialog,
  },
  props: {
    propProduct: {
      type: Object as () => ProductDetailBasicInfoEntity,
      default: null,
    },
  },
  data(): DataType {
    return {
      product: null,
      isLoading: false,
      alertSettings: [],
      savedAlertSettings: [],
      alertSettingDatas: [],
    };
  },
  computed: {
    paceSetting(): IndividualAlertSetting | undefined {
      return this.alertSettings.find((setting) => setting.alertMasterId === 2);
    },
    initialSetting(): IndividualAlertSetting | undefined {
      return this.alertSettings.find((setting) => setting.alertMasterId === 8);
    },
    savedPaceSetting(): IndividualAlertSetting | undefined {
      return this.savedAlertSettings.find((setting) => setting.alertMasterId === 2);
    },
    savedInitialSetting(): IndividualAlertSetting | undefined {
      return this.savedAlertSettings.find((setting) => setting.alertMasterId === 8);
    },
  },
  methods: {
    async addAlert() {
      this.isLoading = true;
      if (!this.product || !store.state.company) {
        this.isLoading = false;
        return;
      }
      let dto: CreateIndividualAlertSettingDto | undefined;
      if (!this.alertSettings.some((setting) => setting.alertMasterId === 2)) {
        const numbers = this.alertSettingDatas.find((data) => data.alertMasterId === 2)?.numbers ?? "14,0-0,30,0";
        dto = {
          numbers,
          excludedStoreIds: "",
          alertMaster: { connect: { id: 2 } },
          product: { connect: { id: this.product.id } },
          company: { connect: { id: store.state.company.id } },
        };
      } else if (!this.alertSettings.some((setting) => setting.alertMasterId === 8)) {
        const numbers = this.alertSettingDatas.find((data) => data.alertMasterId === 8)?.numbers ?? "2,25,0";
        dto = {
          numbers,
          excludedStoreIds: "",
          alertMaster: { connect: { id: 8 } },
          product: { connect: { id: this.product.id } },
          company: { connect: { id: store.state.company.id } },
        };
      }
      if (dto) {
        const alertSetting = await RadialApiClient.createIndividualAlertSetting(dto);
        if (!alertSetting) {
          this.isLoading = false;
          return;
        }
        this.alertSettings.push(alertSetting);
        this.savedAlertSettings.push(lodash.cloneDeep(alertSetting));
      }
      this.isLoading = false;
    },
    async deleteAlertSetting(deleteAlertSettingMasterId: number) {
      this.isLoading = true;
      const deleteIndividualAlertSettingId = this.alertSettings.find(
        (setting) => setting.alertMasterId === deleteAlertSettingMasterId
      )?.id;
      if (!deleteIndividualAlertSettingId) {
        this.isLoading = false;
        return;
      }
      await RadialApiClient.deleteIndividualAlertSetting(deleteIndividualAlertSettingId);
      this.alertSettings = this.alertSettings.filter((setting) => setting.id !== deleteIndividualAlertSettingId);
      this.savedAlertSettings = this.savedAlertSettings.filter(
        (setting) => setting.id !== deleteIndividualAlertSettingId
      );
      this.isLoading = false;
    },
    radioChange(newValue, alertNumberIndex: number, alertMasterId: number) {
      this.alertSettings.forEach((setting) => {
        if (setting.alertMasterId === alertMasterId) {
          setting.numbers = [
            ...setting.numbers.split(",").slice(0, alertNumberIndex),
            `${newValue}-0`,
            ...setting.numbers.split(",").slice(alertNumberIndex + 1),
          ].join(",");
        }
      });
    },
    textToNumberWithIndex(newValue, alertNumberIndex: number, alertMasterId: number) {
      this.alertSettings.forEach((setting) => {
        if (setting.alertMasterId === alertMasterId) {
          const newAlertNumber = `${setting.numbers.split(",")[alertNumberIndex].split("-")[0]}-${
            isNaN(Number(newValue)) ? 0 : Number(newValue)
          }`;
          setting.numbers = [
            ...setting.numbers.split(",").slice(0, alertNumberIndex),
            newAlertNumber,
            ...setting.numbers.split(",").slice(alertNumberIndex + 1),
          ].join(",");
        }
      });
    },
    textToNumber(newValue, alertNumberIndex: number, alertMasterId: number) {
      this.alertSettings.forEach((setting) => {
        if (setting.alertMasterId === alertMasterId) {
          setting.numbers = [
            ...setting.numbers.split(",").slice(0, alertNumberIndex),
            newValue,
            ...setting.numbers.split(",").slice(alertNumberIndex + 1),
          ].join(",");
        }
      });
    },
    async updateAlertSetting(alertMasterId: number) {
      this.isLoading = true;
      const updateIndividualAlertSetting = this.alertSettings.find(
        (setting) => setting.alertMasterId === alertMasterId
      );
      if (!updateIndividualAlertSetting || !this.product) {
        this.isLoading = false;
        return;
      }
      const dto: UpdateIndividualAlertSettingDto = {
        numbers: updateIndividualAlertSetting.numbers,
      };
      await RadialApiClient.updateIndividualAlertSetting(updateIndividualAlertSetting.id, dto);
      this.savedAlertSettings.forEach((setting) => {
        if (setting.alertMasterId === alertMasterId) {
          setting.numbers = updateIndividualAlertSetting.numbers;
        }
      });
      this.isLoading = false;
    },
    cancelUpdateAlertSetting(alertMasterId: number) {
      this.alertSettings.forEach((setting) => {
        if (setting.alertMasterId === alertMasterId) {
          const savedNumbers = this.savedAlertSettings.find(
            (setting) => setting.alertMasterId === alertMasterId
          )?.numbers;
          if (savedNumbers) {
            setting.numbers = savedNumbers;
          }
        }
      });
    },
  },
  watch: {
    propProduct: function (currentProduct: ProductDetailBasicInfoEntity) {
      this.product = currentProduct;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.product = this.propProduct;
    this.alertSettings = (await RadialApiClient.getIndividualAlertSettingByProduct(this.product.id)) ?? [];
    this.alertSettingDatas = (await RadialApiClient.listAlertSettings()) ?? [];
    this.savedAlertSettings = lodash.cloneDeep(this.alertSettings);
    this.isLoading = false;
  },
});
