
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { CustomActionMaster } from "@/api/entities";
import { CreateCustomActionMasterDto, UpdateCustomActionMasterDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  isLoading: boolean;
  activeItem: string | null;
  selectedIndex: number;
  actions: CustomActionMaster[];
  newActionName: string;
  dialogAddAction: boolean;
  dialogeditAction: boolean;
  dialogDeleteAction: boolean;
  selectedAction: CustomActionMaster | null;
  page: number;
  pageSize: number;
  list: CustomActionMaster[];
  listCount: number;
}

export default Vue.extend({
  name: "CustomAction",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
    Loading,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      isLoading: false,
      activeItem: null,
      selectedIndex: 0,
      actions: [],
      newActionName: "",
      dialogAddAction: false,
      dialogeditAction: false,
      dialogDeleteAction: false,
      selectedAction: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: false,
    },
  },
  watch: {},
  computed: {
    editActionDisabled(): boolean {
      if (this.selectedAction) {
        if (this.selectedAction.name === "") {
          return true;
        } else {
          return this.actions.some((item) => {
            return this.selectedAction?.name === item.name;
          });
        }
      }
      return false;
    },
    addActionDisabled(): boolean {
      if (this.newActionName === "") {
        return true;
      } else {
        return this.actions.some((item) => {
          return this.newActionName === item.name;
        });
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    selectAction(action: CustomActionMaster, index: number, selectedIndex: number) {
      this.selectedAction = { ...action };
      if (index === 0) {
        this.selectedIndex = selectedIndex;
        this.dialogeditAction = true;
      } else {
        this.dialogDeleteAction = true;
      }
    },
    deselectAction() {
      this.selectedAction = null;
      this.dialogeditAction = false;
      this.dialogDeleteAction = false;
    },
    sortList() {
      this.list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    async addAction() {
      this.isLoading = true;
      if (this.user) {
        const dto: CreateCustomActionMasterDto = {
          company: { connect: { id: this.user.companyId } },
          name: this.newActionName,
        };
        const customActionMaster = await RadialApiClient.createCustomActionMaster(dto);
        if (customActionMaster) {
          this.list.push(customActionMaster);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("新しくアクションが作成されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.newActionName = "";
      this.isLoading = false;
      this.dialogAddAction = false;
    },
    cancelAddaction() {
      this.dialogAddAction = false;
      this.newActionName = "";
    },
    async editAction() {
      if (this.selectedAction) {
        const dto: UpdateCustomActionMasterDto = {
          name: this.selectedAction.name,
        };
        await RadialApiClient.updateCustomActionMaster(this.selectedAction.id, dto);
        const list = await RadialApiClient.listCustomActionMasters();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
        this.selectedAction = null;
        this.dialogeditAction = false;
      }
    },
    async deleteAction() {
      this.dialogDeleteAction = false;
      this.isLoading = true;
      if (this.selectedAction) {
        await RadialApiClient.deleteCustomActionMaster(this.selectedAction.id);
        const list = await RadialApiClient.listCustomActionMasters();
        if (list) {
          this.list = list;
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.selectedAction = null;
      this.isLoading = false;
      this.showAlert("無事に削除が完了しました！", "success");
    },
    getAlertMessage(): string {
      if (this.selectedAction) {
        return `本当に削除してもよろしいですか？\n全ての品番及びSKUから${this.selectedAction.name}アクションが削除されます。`;
      } else {
        return "";
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.actions = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.isLoading = true;
    const list = await RadialApiClient.listCustomActionMasters();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.actions = this.list;
      } else {
        this.actions = this.list.slice(0, this.pageSize);
      }
    }
    this.isLoading = false;
  },
});
