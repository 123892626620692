
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { User } from "@/store/model";
import { Member, Store } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import AuthClient from "@/api/AuthClient";
import mixin from "@/mixin/mixin";
import S3Image from "@/components/aws/S3Image.vue";
import Loading from "@/components/vuetify/Loading.vue";
import { Storage } from "aws-amplify";

export interface DataType {
  isLoading: boolean;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  members: Member[];
  newMemberName: string;
  dialogEdit: boolean;
  dialogAdd: boolean;
  dialogEditStore: boolean;
  user: User | null;
  store: Store | null;
  newStore: Store | null;
  selectedMember: Member | null;
  page: number;
  pageSize: number;
  list: Member[];
  listCount: number;
}

export default Vue.extend({
  name: "Members",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    Pagination,
    S3Image,
    Loading,
  },
  data(): DataType {
    return {
      isLoading: false,
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      members: [],
      newMemberName: "",
      dialogEdit: false,
      dialogAdd: false,
      dialogEditStore: false,
      user: null,
      store: null,
      newStore: null,
      selectedMember: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  watch: {
    dialogEditStore() {
      if (this.dialogEditStore && this.store) {
        this.newStore = { ...this.store };
      }
    },
  },
  methods: {
    selectMember(member: Member, index: number) {
      this.selectedMember = { ...member };
      if (index === 0) {
        this.dialogEdit = true;
      } else if (index === 1) {
        this.showAlert(
          `本当に${this.selectedMember.name}さんを削除してもよろしいですか？\n削除後もコメントデータは削除されず、radial上に保持され続けます。`,
          "error",
          0
        );
      }
    },
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option;
      this.showingAlert = true;
    },
    async editMember() {
      this.dialogEdit = false;
      if (this.user && this.selectedMember) {
        const updatedMember = await RadialApiClient.updateMember(this.selectedMember.id, {
          name: this.selectedMember.name,
        });
        if (updatedMember) {
          const list = await RadialApiClient.listMembers(this.user.sub);
          if (list) {
            this.list = list;
            this.sortList();
            this.updatePage(this.page);
          }
        } else {
          this.showAlert(
            `エラーが発生しております。
            入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error",
            -1
          );
        }
      }
    },
    async deleteMember() {
      this.showingAlert = false;
      if (this.selectedMember && this.user) {
        const deletedMember = await RadialApiClient.deleteMember(this.selectedMember.id);
        if (deletedMember) {
          const list = await RadialApiClient.listMembers(this.user.sub);
          if (list) {
            this.list = list;
            this.listCount = this.list.length;
            this.sortList();
            this.updatePage(this.page);
          }
          this.showAlert("削除が完了しました！", "success", -1);
        } else {
          this.showAlert(
            `エラーが発生しております。
            入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error",
            -1
          );
        }
      }
    },
    async addMember() {
      if (this.user) {
        const member = await RadialApiClient.createMember({
          company: {
            connect: {
              id: this.user.companyId,
            },
          },
          store: {
            connect: {
              id: this.store?.id ?? "",
            },
          },
          name: this.newMemberName,
        });
        if (member) {
          this.list.push(member);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("メンバーを追加しました！", "success", -1);
        } else {
          this.showAlert(
            `エラーが発生しております。
            入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error",
            -1
          );
        }
      }
      this.newMemberName = "";
      this.dialogAdd = false;
    },
    sortList() {
      this.list.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.members = this.list.slice(start, end);
      this.page = event;
    },
    async updateStore() {
      this.isLoading = true;
      if (this.store && this.newStore) {
        try {
          await AuthClient.updateStore(this.store, this.newStore);
          await RadialApiClient.updateStore(this.newStore.id, {
            email: this.newStore.email,
            name: this.newStore.name,
            icon: this.newStore.icon,
          });
          this.store = { ...this.newStore };
          await AuthClient.checkAuth(false);
        } catch {
          this.showAlert("このメールアドレスはすでに登録されております。", "warning", -1);
          this.isLoading = false;
          return;
        }
      }
      this.isLoading = false;
      this.dialogEditStore = false;
      this.showAlert("更新が完了しました！", "success", -1);
    },
    async select(event: Event) {
      this.isLoading = true;
      if (event.target) {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
          const file = target.files[0];
          if (this.newStore) {
            const s3Key = `store_icon/${this.newStore.id}/${file.name}`;
            await Storage.put(s3Key, file, {
              contentType: file.name.split(".").pop(),
            });
            this.newStore.icon = s3Key;
          }
        }
      }
      this.isLoading = false;
    },
    deselect(event: Event) {
      if (event.target) {
        const target = event.target as HTMLInputElement;
        target.value = "";
      }
    },
  },
  async mounted() {
    const user = await AuthClient.getUserInfo();
    if (user) {
      this.user = user;
      const list = await RadialApiClient.listMembers(this.user.sub);
      if (list) {
        this.list = list;
        this.sortList();
        this.listCount = this.list.length;
        if (this.listCount < this.pageSize) {
          this.members = this.list;
        } else {
          this.members = this.list.slice(0, this.pageSize);
        }
      }
      if (this.user.id) {
        this.store = await RadialApiClient.getStore(this.user.sub);
      }
    }
  },
});
