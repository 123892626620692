
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

import {
  Brand,
  Category,
  DeliveryMapEntity,
  DeliveryMapProductEntity,
  Product,
  CustomTagMaster,
  CustomTag,
  GetMainImagesEntity,
  AdminUser,
} from "@/api/entities";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import { DeliveryMapFilters } from "@/store/model";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import { Constant } from "@/store/constant";
import store from "@/store";
import mixin from "@/mixin/mixin";
import Loading from "@/components/vuetify/Loading.vue";

export interface UnderCursorIndex {
  brand: number;
  category: number;
  divide: number;
  sales: number;
}

export interface AtList {
  startedAt: Date;
  endedAt: Date;
}

export interface ColumnAtList {
  week: AtList[];
  half: AtList[];
}

export interface DataType {
  adminUsers: AdminUser[];
  today: Date;
  selectedMonth: string;
  selectedMonthMenu: boolean;
  deliveryMapFilters: DeliveryMapFilters;
  filters: string[];
  filterKeys: string[];
  brandTable: Brand[];
  categoryTable: Category[];
  productsSalesInfo: DeliveryMapEntity;
  underCursorIndex: UnderCursorIndex;
  tableFormat: string;
  columnAtList: ColumnAtList;
  customTagMasters: CustomTagMaster[];
  hover: boolean;
  hoverDivideIndex: number | null;
  hoverSalesIndex: number | null;
  hoverCategoryIndex: number | null;
  hoverBrandIndex: number | null;
  productImages: GetMainImagesEntity;
  isTablet: boolean;
  isLoading: boolean;
}

export default Vue.extend({
  name: "Delivery",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    S3Image,
    FilterChipButton,
    ElementDatePicker,
    Loading,
  },
  data(): DataType {
    return {
      adminUsers: [],
      today: new Date(),
      selectedMonth: dayjs().format("YYYY-MM"),
      selectedMonthMenu: false,
      filters: ["ブランド", "アイテムカテゴリ"],
      filterKeys: ["brand", "category"],
      brandTable: [],
      categoryTable: [],
      productsSalesInfo: { brands: [] },
      deliveryMapFilters: {
        brand: [],
        category: [],
      },
      underCursorIndex: { brand: -1, category: -1, divide: -1, sales: -1 },
      // toggleのdefault値を設定する
      tableFormat: "month",
      columnAtList: {
        week: [],
        half: [],
      },
      customTagMasters: [],
      hover: false,
      hoverDivideIndex: null,
      hoverSalesIndex: null,
      hoverCategoryIndex: null,
      hoverBrandIndex: null,
      productImages: { s3KeyProducts: [] },
      isTablet: false,
      isLoading: false,
    };
  },
  watch: {
    selectedMonth: {
      async handler() {
        // 異なるselectedMonthが選択されたときの処理
        // selectedMonthのDeliveryMapProductEntityをGETする
        this.isLoading = true;
        const productsSalesInfo = await RadialApiClient.getSalesInfo(dayjs(this.selectedMonth).format("YYYY-MM"));
        if (productsSalesInfo) {
          this.productsSalesInfo = productsSalesInfo;
        }
        this.columnAtList = this.getColumnAtList(dayjs(this.selectedMonth).format("YYYY-MM"));
        this.isLoading = false;
      },
    },
  },
  computed: {
    displayEntries(): DeliveryMapFilters {
      return {
        brand: this.deliveryMapFilters.brand.length === 0 ? this.brandTable : this.deliveryMapFilters.brand,
        category: this.deliveryMapFilters.category.length === 0 ? this.categoryTable : this.deliveryMapFilters.category,
      };
    },
    divideNumber(): number {
      switch (this.tableFormat) {
        case "month":
          return 1;
        case "week":
          return 4;
        default:
          // "half"
          return 2;
      }
    },
    displayProductsSalesInfo(): DeliveryMapEntity {
      const salesInfoBuff: DeliveryMapEntity = { brands: [] };
      for (const brandInfo of this.productsSalesInfo.brands) {
        if (this.displayEntries.brand.map((brand) => brand.name).includes(brandInfo.brand.name)) {
          // 選択されているカテゴリをフィルタリング
          const filteredCategoriesInfo: {
            category: Category;
            products: DeliveryMapProductEntity[];
          }[] = brandInfo.categories.filter((element) => {
            return this.displayEntries.category.map((category) => category.name).includes(element.category.name);
          });
          // 日付順にソート
          filteredCategoriesInfo.map((element) => {
            return {
              category: element,
              products: element.products.sort((a, b) => {
                if (
                  a.product.salesStartedAt &&
                  b.product.salesStartedAt &&
                  a.product.salesStartedAt > b.product.salesStartedAt
                ) {
                  return 1;
                } else {
                  return -1;
                }
              }),
            };
          });
          const filteredBrandsInfo: {
            brand: Brand;
            categories: { category: Category; products: DeliveryMapProductEntity[] }[];
          } = {
            brand: brandInfo.brand,
            categories: filteredCategoriesInfo,
          };
          salesInfoBuff.brands = salesInfoBuff.brands.concat(filteredBrandsInfo);
        }
      }
      return salesInfoBuff;
    },
    columNumberInTablet(): number {
      return this.tableFormat === "month" ? 3 : 2; // falseは"half"の時
    },
  },
  methods: {
    filterOptions(index: number): Brand[] | Category[] {
      switch (index) {
        case 0:
          return this.brandTable;
        case 1:
          return this.categoryTable;
        default:
          return [];
      }
    },
    convertDateFormat(date: Date): string {
      return dayjs(date).format("YYYY/MM/DD");
    },
    clearFilterKey(key: keyof DeliveryMapFilters) {
      this.deliveryMapFilters[key] = [];
    },
    hoverCard(brandIndex: number, categoryIndex: number, divideIndex: number, salesIndex: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hover = true;
        this.hoverBrandIndex = brandIndex;
        this.hoverCategoryIndex = categoryIndex;
        this.hoverDivideIndex = divideIndex;
        this.hoverSalesIndex = salesIndex;
      } else if (hoverFlag === 1) {
        this.hover = false;
      }
    },
    showDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    getColumnTitle(n: number): string {
      if (this.tableFormat === "month") {
        return "";
      } else if (this.tableFormat === "week") {
        const atList = this.columnAtList.week[n - 1];
        return `week${n}（${dayjs(atList.startedAt).format("MM/DD")} ～ ${dayjs(atList.endedAt).format("MM/DD")}）`;
      } else {
        // this.tableFormat === "half"
        if (n === 1) {
          return `前半（${dayjs(this.columnAtList.half[0].startedAt).format("MM/DD")} ～ ${dayjs(
            this.columnAtList.half[0].endedAt
          ).format("MM/DD")}）`;
        } else {
          // n === 2
          return `後半（${dayjs(this.columnAtList.half[1].startedAt).format("MM/DD")} ～ ${dayjs(
            this.columnAtList.half[1].endedAt
          ).format("MM/DD")}）`;
        }
      }
    },
    getProductsWithinPeriodInDivideIndex(
      products: DeliveryMapProductEntity[],
      divideIndex: number
    ): DeliveryMapProductEntity[] {
      if (!this.isTablet) {
        // PCの場合
        if (this.tableFormat === "month") {
          return products.filter((_, index) => index % 4 === divideIndex - 1);
        } else if (this.tableFormat === "week") {
          const productsWithinPeriod = [
            ...products.filter(
              (product) =>
                product.product.salesStartedAt &&
                dayjs(product.product.salesStartedAt).isBetween(
                  dayjs(this.columnAtList.week[divideIndex - 1].startedAt),
                  dayjs(this.columnAtList.week[divideIndex - 1].endedAt),
                  null,
                  "[]"
                )
            ),
          ];
          return productsWithinPeriod;
        } else {
          // this.tableFormat === "half"
          const productsWithinPeriod = [
            ...products.filter(
              (product) =>
                product.product.salesStartedAt &&
                dayjs(product.product.salesStartedAt).isBetween(
                  dayjs(this.columnAtList.half[Math.floor((divideIndex - 1) / 2)].startedAt),
                  dayjs(this.columnAtList.half[Math.floor((divideIndex - 1) / 2)].endedAt),
                  null,
                  "[]"
                )
            ),
          ];
          return productsWithinPeriod.filter((_, index) => index % 2 === (divideIndex - 1) % 2);
        }
      } else {
        // タブレットの場合
        if (this.tableFormat === "month") {
          return products.filter((_, index) => index % 3 === divideIndex - 1);
        } else {
          // this.tableFormat === "half"
          const productsWithinPeriod = [
            ...products.filter(
              (product) =>
                product.product.salesStartedAt &&
                dayjs(product.product.salesStartedAt).isBetween(
                  dayjs(this.columnAtList.half[Math.floor((divideIndex * 2 - 1) / 2)].startedAt),
                  dayjs(this.columnAtList.half[Math.floor((divideIndex * 2 - 1) / 2)].endedAt),
                  null,
                  "[]"
                )
            ),
          ];
          return productsWithinPeriod;
        }
      }
    },
    getColumnAtList(selectedMonth: string): ColumnAtList {
      const month = dayjs(selectedMonth);
      const start = month.startOf("month");
      const end = month.endOf("month");
      const firstWeekDays = start.get("day") === 0 ? 1 : 8 - start.get("day"); // 月初の週の日数
      const endWeekDays = end.get("day"); // 月末の週の日数
      const daysNumber = end.get("date"); // 当月の日数

      let weeks: AtList[] = [];
      if (daysNumber - firstWeekDays - endWeekDays === 28) {
        // 月初と月末の週を直近の週にマージ
        weeks = [...Array(4).keys()].map((n) => {
          if (n === 0) {
            // n === 0
            return {
              startedAt: start.startOf("day").toDate(),
              endedAt: start
                .add(firstWeekDays + 7 * n + 6, "day")
                .endOf("day")
                .toDate(),
            };
          } else if (n !== 3) {
            // n === 1 || n === 2
            return {
              startedAt: start
                .add(firstWeekDays + 7 * n, "day")
                .startOf("day")
                .toDate(),
              endedAt: start
                .add(firstWeekDays + 7 * n + 6, "day")
                .endOf("day")
                .toDate(),
            };
          } else {
            // n === 3
            return {
              startedAt: start
                .add(firstWeekDays + 7 * n, "day")
                .startOf("day")
                .toDate(),
              endedAt: start
                .add(firstWeekDays + 7 * n + 6 + endWeekDays, "day")
                .endOf("day")
                .toDate(),
            };
          }
        });
      } else {
        // 月初と月末の週の日数が少ないほうを直近の週にマージ
        if (firstWeekDays > endWeekDays) {
          // 月初の週の方が日数が多い
          weeks = [...Array(4).keys()].map((n) => {
            if (n === 0) {
              // n === 0
              return {
                startedAt: start.startOf("day").toDate(),
                endedAt: start
                  .add(firstWeekDays + 7 * (n - 1) + 6, "day")
                  .endOf("day")
                  .toDate(),
              };
            } else if (n !== 3) {
              // n === 1 || n === 2
              return {
                startedAt: start
                  .add(firstWeekDays + 7 * (n - 1), "day")
                  .startOf("day")
                  .toDate(),
                endedAt: start
                  .add(firstWeekDays + 7 * (n - 1) + 6, "day")
                  .endOf("day")
                  .toDate(),
              };
            } else {
              // n === 3
              return {
                startedAt: start
                  .add(firstWeekDays + 7 * (n - 1), "day")
                  .startOf("day")
                  .toDate(),
                endedAt: start
                  .add(firstWeekDays + 7 * (n - 1) + 6 + endWeekDays, "day")
                  .endOf("day")
                  .toDate(),
              };
            }
          });
        } else {
          // 月末の週の方が日数が多い、または同じ日数
          weeks = [...Array(4).keys()].map((n) => {
            if (n === 0) {
              // n === 0
              return {
                startedAt: start.startOf("day").toDate(),
                endedAt: start
                  .add(firstWeekDays + 7 * n + 6, "day")
                  .endOf("day")
                  .toDate(),
              };
            } else if (n !== 3) {
              // n === 1 || n === 2
              return {
                startedAt: start
                  .add(firstWeekDays + 7 * n, "day")
                  .startOf("day")
                  .toDate(),
                endedAt: start
                  .add(firstWeekDays + 7 * n + 6, "day")
                  .endOf("day")
                  .toDate(),
              };
            } else {
              // n === 3
              return {
                startedAt: start
                  .add(firstWeekDays + 7 * n, "day")
                  .startOf("day")
                  .toDate(),
                endedAt: start
                  .add(firstWeekDays + 7 * (n - 1) + 6 + endWeekDays, "day")
                  .endOf("day")
                  .toDate(),
              };
            }
          });
        }
      }
      const firstHalf: AtList = {
        startedAt: start.startOf("day").toDate(),
        endedAt: start.add(13, "day").endOf("day").toDate(),
      };
      const secondHalf: AtList = {
        startedAt: start.add(14, "day").startOf("day").toDate(),
        endedAt: end.endOf("day").toDate(),
      };
      return { week: weeks, half: [firstHalf, secondHalf] };
    },
    isThereProduct(brandSalesInfo: any): boolean {
      const isThereProduct = brandSalesInfo.categories.some(({ products }: { products: any }) => products.length !== 0);
      return isThereProduct;
    },
    getFkuFrameClassName(colorName: string, stockcount: number): string {
      // 文字列の長さを取得
      const span = document.createElement("span");
      span.style.position = "absolute";
      span.style.top = "-1000px";
      span.style.left = "-1000px";
      span.style.whiteSpace = "nowrap";
      span.innerHTML = `${colorName}: ${stockcount}`;
      span.style.font = "futura-pt";
      span.style.fontSize = "12px";
      span.style.fontWeight = "500";
      document.body.appendChild(span);
      const width = span.clientWidth;
      (span as any).parentElement.removeChild(span);
      // 文字列の長さに合わせてstyleのwidthを変更
      if (width < 70) {
        return "fku-frame-short";
      } else {
        return "fku-frame-long";
      }
    },
    getCustomTagName(customTagId: number): string {
      return this.customTagMasters
        .filter((customTagMaster) => customTagMaster.id === customTagId)
        .map((customTagMaster) => customTagMaster.name)[0];
    },
    getCustomTagNameWidth(customTagName: string): number {
      const span = document.createElement("span");
      span.style.position = "absolute";
      span.style.top = "-1000px";
      span.style.left = "-1000px";
      span.style.whiteSpace = "nowrap";
      span.innerHTML = customTagName;
      span.style.font = "futura-pt";
      span.style.fontSize = "10px";
      span.style.fontWeight = "500";
      span.style.lineHeight = "14px";
      document.body.appendChild(span);
      const width = span.clientWidth;
      (span as any).parentElement.removeChild(span);
      return width;
    },
    getDisplayableCustomTags(customTags: CustomTag[]): CustomTag[] {
      let displayableCustomTags: CustomTag[] = [];
      let width = 0;
      const customTagSpaceWidth = 26;
      for (const [index, customTag] of customTags.entries()) {
        width += this.getCustomTagNameWidth(this.getCustomTagName(customTag.customTagMasterId));
        if (width + customTagSpaceWidth * (index + 1) < 200) {
          displayableCustomTags = displayableCustomTags.concat(customTag);
        } else {
          break;
        }
      }
      return displayableCustomTags;
    },
    isCustomTagLong(customTags: CustomTag[]): boolean {
      const displayableCustomTags: CustomTag[] = this.getDisplayableCustomTags(customTags);
      if (displayableCustomTags.length === customTags.length) {
        return false;
      } else {
        return true;
      }
    },
    getDividerClassName(categories: any, categoryIndex: number): string {
      let lastIndex = 0;
      for (const [index, category] of categories.entries()) {
        if (category.products.length !== 0) {
          lastIndex = index;
        }
      }
      if (categoryIndex !== lastIndex) {
        return "middle-divider";
      } else {
        return "";
      }
    },
    isFirstCategory(categories: any, categoryIndex: number): boolean {
      let firstIndex = 0;
      for (const [index, category] of categories.entries()) {
        if (category.products.length !== 0) {
          firstIndex = index;
          break;
        }
      }
      if (categoryIndex === firstIndex) {
        return true;
      } else {
        return false;
      }
    },
    async selectMonth() {
      this.isLoading = true;
      const productsSalesInfo = await RadialApiClient.getSalesInfo(dayjs(this.selectedMonth).format("YYYY-MM"));
      if (productsSalesInfo) {
        this.productsSalesInfo = productsSalesInfo;
      }
      this.columnAtList = this.getColumnAtList(dayjs(this.selectedMonth).format("YYYY-MM"));
      // get product image s3keys
      const productIds: string[] = this.productsSalesInfo.brands
        .map((brandItem) =>
          brandItem.categories.map((categoryItem) => categoryItem.products.map((item) => item.product.id))
        )
        .flat(2);
      this.brandTable = this.productsSalesInfo.brands.map((brand) => brand.brand);
      this.displayEntries.brand = this.brandTable;
      let overlappedCategoryTable: Category[] = [];
      for (const categories of this.productsSalesInfo.brands.map((categories) =>
        categories.categories.map((category) => category.category)
      )) {
        overlappedCategoryTable = overlappedCategoryTable.concat(...categories);
      }
      this.categoryTable = Array.from(
        new Map(overlappedCategoryTable.map((category) => [category.id, category])).values()
      );
      this.displayEntries.category = this.categoryTable;
      const productImages = await RadialApiClient.getProductMainImages({ productIds });
      if (productIds.length !== 0 && productImages) {
        this.productImages = productImages;
      }
      this.selectedMonthMenu = false;
      this.isLoading = false;
    },
    getS3Key(product: Product): string {
      return this.productImages.s3KeyProducts.find((item) => item.productId === product.id)?.s3Key ?? "";
    },
    getIconS3Key(tag: CustomTag): string {
      if (tag.creatorAdminUserId) {
        return this.adminUsers.find((adminUser) => adminUser.id === tag.creatorAdminUserId)?.icon ?? "";
      } else {
        return store.state.company?.icon ?? "";
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    // タブレットを使っているのか判断
    this.isTablet = Constant.isUsingTablet();
    // 描画に必要な情報を取得
    await Promise.all([
      (async () => {
        this.adminUsers = (await RadialApiClient.listAdminUsers()) ?? [];
      })(),
    ]);
    const productsSalesInfo = await RadialApiClient.getSalesInfo(this.selectedMonth);
    if (productsSalesInfo) {
      this.productsSalesInfo = productsSalesInfo;
    }

    this.brandTable = this.productsSalesInfo.brands.map((brand) => brand.brand);
    this.displayEntries.brand = this.brandTable;
    let overlappedCategoryTable: Category[] = [];
    for (const categories of this.productsSalesInfo.brands.map((categories) =>
      categories.categories.map((category) => category.category)
    )) {
      overlappedCategoryTable = overlappedCategoryTable.concat(...categories);
    }
    this.categoryTable = Array.from(
      new Map(overlappedCategoryTable.map((category) => [category.id, category])).values()
    );
    this.displayEntries.category = this.categoryTable;
    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/delivery",
        page_title: "Adminデリバリー画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/delivery",
        page_title: "Staffデリバリー画面",
      });
    }
    this.columnAtList = this.getColumnAtList(this.selectedMonth);
    this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];

    // get product image s3keys
    const productIds: string[] = this.productsSalesInfo.brands
      .map((brandItem) =>
        brandItem.categories.map((categoryItem) => categoryItem.products.map((item) => item.product.id))
      )
      .flat(2);
    const productImages = await RadialApiClient.getProductMainImages({ productIds });
    if (productIds.length !== 0 && productImages) {
      this.productImages = productImages;
    }
    this.isLoading = false;
  },
});
