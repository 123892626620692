
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import RadialApiClient from "@/api/RadialApiClient";
import {} from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import S3Image from "@/components/aws/S3Image.vue";
import {} from "@/api/entities";
import { OrderInstructionWithTotal } from "@/store/model";
import {} from "@/api/dto";
import AuthClient from "@/api/AuthClient";
import LocalDataService from "@/store/LocalDataService";
import _ from "lodash";
import dayjs from "dayjs";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface OrderInstructionDetailProduct {
  orderProductId: number;
  productId: string;
  itemNumber: string | null;
  name: string | null;
  orderSkusByColors: OrderInstructionDetailSkuColor[];
}

export interface OrderInstructionDetailSkuColor {
  colorId: string;
  orderSkus: OrderInstructionDetailSku[];
}

export interface OrderInstructionDetailSku {
  orderSkuId: number;
  skuId: string;
  sizeId: string | null;
  sizeName: string | null;
  colorId: string | null;
  colorName: string | null;
  orderCost: number;
  orderQuantity: number;
}

export interface DataType {
  sub: string;
  isLoading: boolean;
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  orderInstruction: OrderInstructionWithTotal | null;
  orderInstructionProducts: OrderInstructionDetailProduct[];
  editMode: boolean;
  images: string[];
}
export default Vue.extend({
  name: "OrderInstructionDetail",
  mixins: [mixin],
  components: { ConfirmationDialog, NavigationDrawer, S3Image, Loading },
  data(): DataType {
    return {
      sub: "",
      isLoading: false,
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      orderInstruction: null,
      orderInstructionProducts: [],
      editMode: false,
      images: [],
    };
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    changeEditMode() {
      return "";
    },
    cancelUpdateOrderInstruction() {
      return "";
    },
    updateOrderInstruction() {
      return "";
    },
    getImageFromProduct(itemNumber: string): string {
      const images = this.images.filter((image) => image.startsWith(`${this.sub}/${itemNumber}_`));
      if (images.length > 0) {
        const mainImages = images.filter((image) => image.endsWith("_M"));
        if (mainImages.length > 0) {
          return mainImages[0];
        } else {
          return images[0];
        }
      }
      return "";
    },
    showProductDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: "AdminProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    format(date: Date): string {
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  async mounted() {
    this.isLoading = true;
    const user = await AuthClient.getUserInfo();
    if (user) {
      this.sub = this.isAdmin ? user.companyId : user.sub;
      this.images = (await LocalDataService.getImageKeys(user.companyId)) ?? [];
      const orderInstructionId = this.$route.params.orderInstructionId;
      const orderInstruction = await RadialApiClient.getOrderInstruction(orderInstructionId);
      const factories = (await RadialApiClient.listFactories()) ?? [];
      const warehouses = (await RadialApiClient.listWarehouses()) ?? [];
      if (orderInstruction) {
        const orderInstructionProducts = await RadialApiClient.listOrderInstructionProducts(orderInstructionId);
        if (orderInstructionProducts) {
          const totalCosts = orderInstructionProducts.map((item) => {
            const productCost = item.orderSkus.reduce((sumCountResult, info) => {
              return sumCountResult + info.orderCost;
            }, 0);
            const productQuantity = item.orderSkus.reduce((sumQuantityResult, info) => {
              return sumQuantityResult + info.orderQuantity;
            }, 0);
            return {
              totalCost: productCost,
              totalQuantity: productQuantity,
            };
          });
          this.orderInstruction = {
            id: orderInstruction.id,
            orderNumber: orderInstruction.orderNumber,
            orderedDate: orderInstruction.orderedDate,
            factoryId: orderInstruction.factoryId,
            factory: factories.find((factory) => factory.id === orderInstruction.factoryId),
            stockedDate: orderInstruction.stockedDate,
            warehouseId: orderInstruction.warehouseId,
            warehouse: warehouses.find((warehouse) => warehouse.id === orderInstruction.warehouseId),
            status: orderInstruction.status,
            orderTotalCount: totalCosts.reduce((sumResult, info) => {
              return sumResult + info.totalCost;
            }, 0),
            orderTotalAmount: totalCosts.reduce((sumResult, info) => {
              return sumResult + info.totalQuantity;
            }, 0),
          };
          const products = (await RadialApiClient.listProducts()) ?? [];
          const skus = (await RadialApiClient.listSkus()) ?? [];
          const colors = (await RadialApiClient.listColors()) ?? [];
          const sizes = (await RadialApiClient.listSizes()) ?? [];
          this.orderInstructionProducts = orderInstructionProducts.map((productInstruction) => {
            const product = products.find((product) => product.id === productInstruction.productId);
            const orderInstructionDetailSkus: OrderInstructionDetailSku[] = productInstruction.orderSkus.map(
              (orderSku) => {
                const sku = skus.find((sku) => sku.id === orderSku.skuId);
                const color = colors.find((color) => color.id === sku?.colorId);
                const size = sizes.find((size) => size.id === sku?.sizeId);
                return {
                  orderSkuId: orderSku.orderSkuId,
                  skuId: orderSku.skuId,
                  sizeId: sku?.sizeId ?? null,
                  sizeName: size?.name ?? null,
                  colorId: sku?.colorId ?? null,
                  colorName: color?.name ?? null,
                  orderCost: orderSku.orderCost,
                  orderQuantity: orderSku.orderQuantity,
                };
              }
            );
            const grouped = _.chain(orderInstructionDetailSkus)
              .groupBy("colorId")
              .map((orderSkus, colorId) => ({ colorId, orderSkus }))
              .value();
            return {
              orderProductId: productInstruction.orderProductId,
              productId: productInstruction.productId,
              itemNumber: product?.itemNumber ?? null,
              name: product?.name ?? null,
              orderSkusByColors: grouped,
            };
          });
          this.orderInstructionProducts = [
            {
              orderProductId: 0,
              productId: "",
              itemNumber: "12345678",
              name: "Ultra T-shirt",
              orderSkusByColors: [
                {
                  colorId: "blue",
                  orderSkus: [
                    {
                      orderSkuId: 0,
                      skuId: "",
                      sizeId: "ss",
                      sizeName: "SS",
                      colorId: "blue",
                      colorName: "Blue",
                      orderCost: 1000,
                      orderQuantity: 1000,
                    },
                    {
                      orderSkuId: 0,
                      skuId: "",
                      sizeId: "m",
                      sizeName: "M",
                      colorId: "blue",
                      colorName: "Blue",
                      orderCost: 2000,
                      orderQuantity: 1000,
                    },
                    {
                      orderSkuId: 0,
                      skuId: "",
                      sizeId: "ll",
                      sizeName: "LL",
                      colorId: "blue",
                      colorName: "Blue",
                      orderCost: 3000,
                      orderQuantity: 0,
                    },
                  ],
                },
                {
                  colorId: "blue",
                  orderSkus: [
                    {
                      orderSkuId: 0,
                      skuId: "",
                      sizeId: "ss",
                      sizeName: "SS",
                      colorId: "blue",
                      colorName: "Blue",
                      orderCost: 1000,
                      orderQuantity: 1000,
                    },
                    {
                      orderSkuId: 0,
                      skuId: "",
                      sizeId: "m",
                      sizeName: "M",
                      colorId: "blue",
                      colorName: "Blue",
                      orderCost: 2000,
                      orderQuantity: 1000,
                    },
                    {
                      orderSkuId: 0,
                      skuId: "",
                      sizeId: "ll",
                      sizeName: "LL",
                      colorId: "blue",
                      colorName: "Blue",
                      orderCost: 3000,
                      orderQuantity: 0,
                    },
                  ],
                },
              ],
            },
          ];
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
        }
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    }
    this.isLoading = false;
  },
});
