
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { Storage } from "aws-amplify";
import Encoding from "encoding-japanese";
import LocalDataService from "@/store/LocalDataService";
import RadialApiClient from "@/api/RadialApiClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { Company, CsvCheckResultEntity, AdminUser } from "@/api/entities";
import dayjs from "dayjs";
import { Counts, User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import mixin from "@/mixin/mixin";
import { CsvSaleDto } from "@/api/dto";
import anum from "@/components/animation/AnimatedNumber.vue";

export interface DataType {
  user: User | null;
  file: File | null;
  companyAdminUser: Company | AdminUser | null;
  companyTimer: number;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  isLoading: boolean;
  uploading: boolean;
  csvCheckMessage: CsvCheckResultEntity;
  isCsvChecked: boolean;
  csvS3Key: string;
  sampleCsvUrl: { sale: string; stock: string };
  counts: Counts | null;
  tab: string;
  rangeDates: string[];
  saleCsvNumber: number;
  stockCsvNumber: number;
  showSaleAction: boolean;
  showStockAction: boolean;
  saleLimitedCsvNumber: number;
}

export default Vue.extend({
  name: "CsvSaleStockUpload",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    Loading,
    ElementRangeDatePicker,
    anum,
  },
  data(): DataType {
    return {
      user: null,
      file: null,
      companyAdminUser: null,
      companyTimer: -1,
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      isLoading: true,
      uploading: false,
      csvCheckMessage: { errors: [], status: 0 },
      isCsvChecked: false,
      csvS3Key: "",
      sampleCsvUrl: { sale: "", stock: "" },
      counts: { update: 0, create: 0, incomplete: 0, name: "" },
      tab: "sale-tab",
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      saleCsvNumber: 0,
      stockCsvNumber: 0,
      showSaleAction: true,
      showStockAction: true,
      saleLimitedCsvNumber: 10000,
    };
  },
  computed: {
    saleActions(): string[] {
      if (this.saleCsvNumber > this.saleLimitedCsvNumber) {
        return ["選択期間を調整してください"];
      }
      if (this.saleCsvNumber === 0) {
        return this.canCreate ? ["サンプルデータダウンロード", "削除"] : ["サンプルデータダウンロード"];
      } else {
        return this.canCreate ? ["radial - csvダウンロード", "削除"] : ["radial - csvダウンロード"];
      }
    },
    stockActions(): string[] {
      if (this.stockCsvNumber === 0) {
        return ["サンプルデータダウンロード"];
      } else {
        return ["radial - csvダウンロード"];
      }
    },
    buttonTitle(): string {
      if (this.companyAdminUser) {
        switch (this.companyAdminUser.saleStockProgress) {
          case 100:
            return "ファイルを送信";
          case 0:
            return "ファイルを送信";
          case -1:
            return "アップロード中にエラーが起きています";
          default:
            return "ファイルを送信中...";
        }
      }
      return "";
    },
    isRoot(): boolean {
      return this.user?.sub === this.user?.companyId;
    },
  },
  methods: {
    getTimeStamp(): string {
      return dayjs().format("YYYYMMDDTHHmmss");
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    /*
    downloadRegisteredCsv() {
    },
    */
    select(event: Event) {
      this.uploading = false;
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      const reader = new FileReader();
      if (target.files && target.files.length > 0) {
        this.file = target.files[0];
        this.csvCheckMessage = { errors: [], status: 0 };
        if (this.file.name.split(".").pop() !== "csv") {
          this.showAlert(
            `.csv以外の拡張子のファイルはアップロードを行うことができません。
              再度ファイルをアップロードしてみてください。`,
            "error"
          );
          return;
        }
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            const array = new Uint8Array(reader.result);
            const encoding = Encoding.detect(array);
            if (encoding) {
              this.loadFile();
            } else {
              this.showAlert(
                `アップロードデータが損傷しているようです。
                  再度サンプルデータをダウンロードいただきデータを再作成ください。`,
                "error"
              );
            }
          }
        };
        reader.readAsArrayBuffer(this.file);
      }
      (this.$refs.input as any).value = "";
    },
    async loadFile() {
      this.isCsvChecked = false;
      if (this.file && this.user) {
        // csvをアップロード
        this.isLoading = true;
        const filePath = `sale_stock_csv/${this.user.companyId}/temp/${this.getTimeStamp()}.csv`;
        const res = await Storage.put(filePath, this.file, { contentType: "text/csv" });
        this.csvS3Key = (res as any).key;
        // csvのチェック
        const csvCheckMessage = await RadialApiClient.checkSaleStockCsv(`public/${this.csvS3Key}`);
        if (csvCheckMessage) {
          this.csvCheckMessage = csvCheckMessage;
        }
        this.isLoading = false;
        // csvのチェックのエラーハンドリング
        if (this.csvCheckMessage.status === 0) {
          // csvチェック成功
          if ((this.csvCheckMessage.createCount ?? 0) + (this.csvCheckMessage.updateCount ?? 0) === 0) {
            this.showAlert("データに変更がないため、アップロードできません。 再度ファイルをご確認ください。", "info");
          } else {
            this.isCsvChecked = true;
            this.counts = {
              create: this.csvCheckMessage.createCount ?? 0,
              update: this.csvCheckMessage.updateCount ?? 0,
              incomplete: this.csvCheckMessage.incompleteCount ?? 0,
              name: this.file.name,
            };
          }
        } else if (this.csvCheckMessage.status === 1) {
          // csvチェック入力内容不正
          this.isCsvChecked = true;
          this.counts = {
            create: this.csvCheckMessage.createCount ?? 0,
            update: this.csvCheckMessage.updateCount ?? 0,
            incomplete: this.csvCheckMessage.incompleteCount ?? 0,
            name: this.file.name,
          };
        } else {
          // this.csvCheckStatus === 2 csvチェックフォーマット不正
          this.showAlert(this.csvCheckMessage.errors[0].message, "error");
        }
      }
    },
    async uploadCsv() {
      const message =
        `新規: ${this.counts?.create}件\n` + `更新: ${this.counts?.update}件\n` + `\nデータベースを更新しますか？`;
      if (confirm(message) && this.user && this.counts) {
        await LocalDataService.setSaleStockCounts(this.counts);
        await RadialApiClient.uploadSaleStockCsv(`public/${this.csvS3Key}`);
        this.uploading = true;
      } else {
        this.showAlert("アップロードをキャンセルしました。", "warning");
      }
    },
    createCompanyTimer() {
      this.companyTimer = window.setInterval(async () => {
        if (this.user) {
          if (this.isRoot) {
            this.companyAdminUser = await RadialApiClient.getCompany(this.user.sub);
          } else {
            this.companyAdminUser = await RadialApiClient.getAdminUser(this.user.sub);
          }
        }
        if (this.uploading) {
          if (this.companyAdminUser && this.companyAdminUser.saleStockProgress === 100) {
            if ((this.counts?.create ?? 0) + (this.counts?.update ?? 0) >= 100) {
              // 多くの新規や更新件数がある場合は「大変おつかれさまでした。」でねぎらってあげる
              this.showAlert(
                `csvのアップロードが完了しました!
                大変おつかれさまでした。1時間以内に、商品リストや詳細ページにてご確認いただけます。`,
                "success"
              );
            } else {
              // 新規や更新件数が少ない場合はシンプルにアップロードが成功しましたモーダルを表示する
              this.showAlert(
                `csvのアップロードが完了しました!
                1時間以内に、商品リストや詳細ページにてご確認いただけます。`,
                "success"
              );
            }
            this.file = null;
            this.uploading = false;
            const dto: CsvSaleDto = {
              start: dayjs(this.rangeDates[0]).toDate(),
              end: dayjs(this.rangeDates[1]).toDate(),
            };
            const saleCsvNumber = await RadialApiClient.getSaleCsvNumber(dto);
            const stockCsvNumber = await RadialApiClient.getStockCsvNumber();
            this.saleCsvNumber = saleCsvNumber ? saleCsvNumber.saleNumber : 0;
            this.stockCsvNumber = stockCsvNumber ? stockCsvNumber.stockNumber : 0;
            this.isCsvChecked = false;
          }
        }
      }, 1000);
    },
    getCsvErrorMessage(index: number): string {
      let message = this.csvCheckMessage.errors[index].message;
      for (const rows of this.csvCheckMessage.errors[index].rows) {
        for (const [rowIndex, row] of rows.entries()) {
          if (rowIndex === 0) {
            message = message.concat(`\n・${row}行目`);
          } else {
            message = message.concat(`と${row}行目`);
          }
        }
      }
      return message;
    },
    async saleActionChanged(event: string) {
      this.isLoading = true;
      let dto: CsvSaleDto;
      switch (event) {
        case "サンプルデータダウンロード":
          location.href = this.sampleCsvUrl.sale;
          break;
        case "radial - csvダウンロード":
          dto = {
            start: dayjs(this.rangeDates[0]).toDate(),
            end: dayjs(this.rangeDates[1]).toDate(),
          };
          const response = await RadialApiClient.getSaleCsvS3Key(dto);
          const s3Key = response?.S3Key.replace(/^public\//, "");
          if (s3Key) {
            const url = String(await Storage.get(s3Key));
            location.href = url;
          } else {
            this.showAlert(
              `ダウンロード時にエラーが発生しました。
             解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
              "error"
            );
          }
          break;
        case "削除":
          const message = `${this.saleCsvNumber}件の取引明細を削除しますか？`;
          if (confirm(message)) {
            dto = {
              start: dayjs(this.rangeDates[0]).toDate(),
              end: dayjs(this.rangeDates[1]).toDate(),
            };
            await RadialApiClient.deleteSaleCsv(dto);
            const saleCsvNumber = await RadialApiClient.getSaleCsvNumber(dto);
            const stockCsvNumber = await RadialApiClient.getStockCsvNumber();
            this.saleCsvNumber = saleCsvNumber ? saleCsvNumber.saleNumber : 0;
            this.stockCsvNumber = stockCsvNumber ? stockCsvNumber.stockNumber : 0;
          }
          break;
        default:
          break;
      }
      // v-selectを再描画させる
      this.showSaleAction = false;
      this.$nextTick(() => (this.showSaleAction = true));
      this.isLoading = false;
    },
    async stockActionChanged(event: string) {
      this.isLoading = true;
      switch (event) {
        case "サンプルデータダウンロード":
          location.href = this.sampleCsvUrl.stock;
          break;
        case "radial - csvダウンロード":
          const response = await RadialApiClient.getStockCsvS3Key();
          const s3Key = response?.S3Key.replace(/^public\//, "");
          if (s3Key) {
            const url = String(await Storage.get(s3Key));
            location.href = url;
          } else {
            this.showAlert(
              `ダウンロード時にエラーが発生しました。
             解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
              "error"
            );
          }
          break;
        default:
          break;
      }
      // v-selectを再描画させる
      this.showStockAction = false;
      this.$nextTick(() => (this.showStockAction = true));
      this.isLoading = false;
    },
    async updateSaleDate() {
      this.isLoading = true;
      const dto: CsvSaleDto = { start: dayjs(this.rangeDates[0]).toDate(), end: dayjs(this.rangeDates[1]).toDate() };
      const saleCsvNumber = await RadialApiClient.getSaleCsvNumber(dto);
      this.saleCsvNumber = saleCsvNumber ? saleCsvNumber.saleNumber : 0;
      this.isLoading = false;
    },
    isThereUploadableItem(): boolean {
      return (this.counts?.create ?? 0) + (this.counts?.update ?? 0) !== 0;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      if (this.isRoot) {
        this.companyAdminUser = await RadialApiClient.getCompany(this.user.sub);
      } else {
        this.companyAdminUser = await RadialApiClient.getAdminUser(this.user.sub);
      }
    }
    this.sampleCsvUrl.sale = String(await Storage.get("sale_stock_csv/data_sale.csv"));
    this.sampleCsvUrl.stock = String(await Storage.get("sale_stock_csv/data_stock.csv"));
    this.counts = await LocalDataService.getSaleStockCounts();
    const dto: CsvSaleDto = { start: dayjs(this.rangeDates[0]).toDate(), end: dayjs(this.rangeDates[1]).toDate() };
    const saleCsvNumber = await RadialApiClient.getSaleCsvNumber(dto);
    const stockCsvNumber = await RadialApiClient.getStockCsvNumber();
    this.saleCsvNumber = saleCsvNumber ? saleCsvNumber.saleNumber : 0;
    this.stockCsvNumber = stockCsvNumber ? stockCsvNumber.stockNumber : 0;
    this.createCompanyTimer();
    this.isLoading = false;
  },
  beforeDestroy() {
    clearInterval(this.companyTimer);
  },
});
