
import dayjs, { Dayjs } from "dayjs";
import Vue from "vue";
import S3Image from "@/components/aws/S3Image.vue";
import {
  ProductDetailTrendEntity,
  ProductDetailBasicInfoEntity,
  ProductDetailSalesDigestRateChartEntity,
  AlertMaster,
  SkuDetailTrendEntity,
  Sku,
  Color,
  Store,
  Size,
  ProductDetailSalesDigestRateChartData,
  Warehouse,
  DetailTrendItemEntity,
  GetDetailImagesEntity,
  ProductDetailActionInfoEntity,
  CustomActionMaster,
  Action,
  CustomActionDetail,
  AdminUser,
  StockActionDetail,
  ActionType,
  OrderedActionInfoEntity,
  StockActionInfoEntity,
} from "@/api/entities";
import { AlertDetail, ColorDetail, ProductTrendTableHeader, SizeDetail, User, AdminUserDetail } from "@/store/model";
import { ActionCreateDto, CreateActionDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { Chart } from "highcharts-vue";
import { Options, TooltipOptions } from "highcharts";
import RadialApiClient from "@/api/RadialApiClient";
import * as Highcharts from "highcharts";
import { Constant } from "@/store/constant";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import mixin from "@/mixin/mixin";
import ElementDatePicker from "@/components/element/DatePicker.vue";

export interface ProductAlert {
  id: string;
  alertId: string;
  alertMasterId: number;
  userDeleted: boolean;
}

export interface SkuTrendTableTabs {
  colorId: string;
  sizes: SkuSizeTrendTableData[];
}

export interface SkuSizeTrendTableData {
  skuId: string;
  sizeId: string;
  skuAlerts: ProductAlert[];
}

export type ActionStatus = "REQUEST" | "PENDING" | "COMPLETED";

export interface DataType {
  isLoading: boolean;
  itemNumber: string;
  today: string;
  warehouseNames: string[];
  skus: Sku[];
  alertDatas: AlertMaster[];
  showingAlert: boolean;
  selectedAlert: { alert: ProductAlert; type: string } | null;
  tableFormat: number;
  tableFormatChartList: number;
  monthRangeList: { name: string; id: number }[];
  selectedPeriod: number;
  xLabel: string[];
  yDataColumn: number[];
  yDataLine: number[];
  productDetailSalesDigestRateChartEntity: ProductDetailSalesDigestRateChartEntity;
  skuDetailSalesDigestRateChartData: ProductDetailSalesDigestRateChartData[];
  productAlerts: ProductAlert[];
  skuAlerts: ProductAlert[];
  productTableInfo: ProductDetailTrendEntity | null;
  skuTableTabs: SkuTrendTableTabs[];
  skuTableInfo: SkuDetailTrendEntity | null;
  produtTrendTableHeader: ProductTrendTableHeader[];
  selectedSkuColor: SkuTrendTableTabs | null;
  selectedSkuSize: SkuSizeTrendTableData | null;
  productDetailImages: GetDetailImagesEntity;
  showingAction: boolean;
  actionInfo: ProductDetailActionInfoEntity;
  actionStatus: ActionStatus;
  customActionMasterList: CustomActionMaster[];
  actionList: Action[];
  customActionDetailList: CustomActionDetail[];
  adminUserDetails: AdminUserDetail[];
  stockActionDetailList: StockActionDetail[];
  selectedStockActionDetailIds: string[];
  showingCreateAction: boolean;
  createActionListInfo: { title: string | null; managerId: string | null; executedDate: Date | null };
  alertMessage: string;
  alertType: string;
}

export default Vue.extend({
  mixins: [mixin],
  components: {
    S3Image,
    ConfirmationDialog,
    highcharts: Chart,
    Loading,
    ElementDatePicker,
  },
  props: {
    propImages: {
      type: Array as () => string[],
      required: true,
    },
    user: {
      type: Object as () => User | null,
      required: false,
    },
    product: {
      type: Object as () => ProductDetailBasicInfoEntity,
      required: false,
    },
    stores: {
      type: Array as () => Store[],
      required: true,
    },
    warehouses: {
      type: Array as () => Warehouse[],
      required: true,
    },
    colors: {
      type: Array as () => Color[],
      required: true,
    },
    sizes: {
      type: Array as () => Size[],
      required: true,
    },
    propGraph: {
      type: Object as () => ProductDetailSalesDigestRateChartEntity,
      required: true,
    },
    propPeriod: {
      type: Number,
      required: true,
    },
    isTablet: {
      type: Boolean,
      required: true,
    },
  },
  data(): DataType {
    return {
      isLoading: false,
      itemNumber: "",
      // 本日日付
      today: dayjs().toString(),
      warehouseNames: [],
      skus: [],
      alertDatas: [],
      showingAlert: false,
      selectedAlert: null,
      tableFormat: 0,
      tableFormatChartList: 0,
      monthRangeList: [
        { name: "直近3ヶ月", id: 3 },
        { name: "直近6ヶ月", id: 6 },
        { name: "直近1年間", id: 12 },
        { name: "前年度", id: 24 },
        { name: "前々年度", id: 36 },
      ],
      selectedPeriod: 3,
      xLabel: [],
      yDataColumn: [],
      yDataLine: [],
      productDetailSalesDigestRateChartEntity: { productId: "", productData: null, skuData: [] },
      skuDetailSalesDigestRateChartData: [],
      productAlerts: [],
      skuAlerts: [],
      productTableInfo: null,
      skuTableTabs: [],
      skuTableInfo: null,
      produtTrendTableHeader: Constant.productTrendTableHeader,
      selectedSkuColor: null,
      selectedSkuSize: null,
      productDetailImages: { s3KeyMain: "", s3KeySkus: [] },
      showingAction: false,
      actionInfo: { requests: [], pending: { saleActions: [], customActions: [], stockActions: [] }, completed: [] },
      actionStatus: "REQUEST",
      customActionMasterList: [],
      actionList: [],
      customActionDetailList: [],
      adminUserDetails: [],
      stockActionDetailList: [],
      selectedStockActionDetailIds: [],
      showingCreateAction: false,
      createActionListInfo: { title: null, managerId: null, executedDate: null },
      alertMessage: "",
      alertType: "success",
    };
  },
  computed: {
    // 値引率
    percentOff(): string {
      if (this.product?.salesPrice && this.product.price) {
        if (this.product.salesPrice < this.product.price) {
          const off = 100 - (this.product.salesPrice / this.product.price) * 100;
          return `(${Math.floor(off)}% OFF)`;
        }
      }
      return "";
    },
    // 週間、画面表示フォーマット
    oneWeek() {
      return (minusWeek = 0): { from: string; to: string } => {
        const week = this.oneWeekDayjs(minusWeek);
        return { from: week.from.format("MM.DD"), to: week.to.format("MM.DD") };
      };
    },
    // 日付の差分
    diffDate(): string {
      if (!this.product?.salesStartedAt || !this.product?.salesEndedAt) {
        return "未定";
      }
      return (
        (
          dayjs(this.product?.salesEndedAt.toString()).diff(dayjs(this.product?.salesStartedAt.toString()), "days") + 1
        ).toString() + "日"
      );
    },
    options(): Options {
      const labels: any =
        this.tableFormat === 0
          ? {
              formatter: function () {
                return Highcharts.numberFormat(Number(this.value), 0, ",", ",");
              },
              style: {
                // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            }
          : {
              formatter: function () {
                return `￥${Highcharts.numberFormat(Number(this.value), 0, ",", ",")}`;
              },
              style: {
                // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            };
      const yDataColumnMax = Math.max(...this.yDataColumn);
      const tickInterval = this.selectedPeriod === 3 ? 2 : this.selectedPeriod === 6 ? 3 : 2;
      const tooltip: TooltipOptions =
        this.tableFormat === 0
          ? {
              formatter: function () {
                let s = this.x.toString();
                if (this.points) {
                  s +=
                    "<br/>" +
                    '<b style="color:#76B3E0">' +
                    this.points[0].series.name +
                    "</b>" +
                    ": " +
                    this.points[0].y.toLocaleString();
                  s +=
                    "<br/>" +
                    '<b style="color:#519998">' +
                    this.points[1].series.name +
                    "</b>" +
                    ": " +
                    this.points[1].y +
                    "%";
                }
                return s;
              },
              shared: true,
              style: {
                // ツールチップ内の文字カラーはassistantType70
                color: "#3C6065",
                fontSize: "12px",
              },
            }
          : {
              formatter: function () {
                let s = this.x.toString();
                if (this.points) {
                  s +=
                    "<br/>" +
                    '<b style="color:#76B3E0">' +
                    this.points[0].series.name +
                    "</b>" +
                    ": " +
                    "￥" +
                    this.points[0].y.toLocaleString();
                  s +=
                    "<br/>" +
                    '<b style="color:#519998">' +
                    this.points[1].series.name +
                    "</b>" +
                    ": " +
                    this.points[1].y +
                    "%";
                }
                return s;
              },
              shared: true,
              style: {
                // ツールチップ内の文字カラーはassistantType70
                color: "#3C6065",
                fontSize: "12px",
              },
            };
      return {
        title: undefined,
        chart: {
          width: this.showingAction ? 786 : 998,
          height: 215,
          backgroundColor: "#fbfbfb",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.xLabel,
            tickInterval: tickInterval, // one month
            gridLineWidth: 1,
            labels: {
              style: {
                // X軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            },
          },
        ],
        yAxis: [
          {
            // 1つ目のy軸設定
            title: {
              text: undefined,
            },
            endOnTick: false,
            min: 0,
            max:
              (Math.floor(yDataColumnMax / Math.pow(10, yDataColumnMax.toString().length - 1)) + 1) *
              Math.pow(10, yDataColumnMax.toString().length - 1),
            tickInterval:
              ((Math.floor(yDataColumnMax / Math.pow(10, yDataColumnMax.toString().length - 1)) + 1) *
                Math.pow(10, yDataColumnMax.toString().length - 1)) /
              5, // y軸のgrid幅を揃える
            // labels: {
            //   format: this.tableFormat === 0 ? `` : `￥{value}`
            // }
            labels: labels,
          },
          {
            // 2つ目のy軸設定
            title: {
              text: undefined,
            },
            labels: {
              format: "{value}%",
              style: {
                // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            },
            endOnTick: false,
            min: 0,
            max: 100,
            tickInterval: 20, // y軸のgrid幅を揃える
            opposite: true, // trueの場合グラフの右側にy軸を配置する
          },
        ],
        tooltip: tooltip,
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
              color: "#13365c",
              verticalAlign: "bottom",
              useHTML: true,
            },
          },
          line: {
            dataLabels: {
              enabled: false,
              color: "#006400",
              verticalAlign: "bottom",
              useHTML: true,
            },
          },
        },
        series: [
          {
            // 1つ目のグラフデータ
            name: "売上", // グラフの名前指定
            type: "column", // グラフの形指定
            data: this.yDataColumn, // グラフに使用するデータ指定
            yAxis: 0, // y軸を指定
            color: "#76B3E0",
            pointWidth: 15,
          },
          {
            // 2つ目のグラフデータ
            name: "消化率", // グラフの名前指定
            type: "line", // グラフの形指定
            data: this.yDataLine, // グラフに使用するデータ指定
            yAxis: 1, // y軸を指定
            color: "#519998",
            marker: {
              fillColor: "#FFFFFF",
              radius: 5,
              lineWidth: 2,
              lineColor: undefined, // inherit from series
            },
          },
        ],
      };
    },
    selectedActionType(): ActionType | null {
      if (this.selectedStockActionDetailIds.length === 0) {
        // 選択されているリクエストがない場合
        return null;
      } else {
        const selectedStockActionDetails = this.stockActionDetailList.filter((stockAction) =>
          this.selectedStockActionDetailIds.includes(stockAction.id)
        );
        const actionTypeList = selectedStockActionDetails.map((stockActionDetail) =>
          stockActionDetail.sourceStoreId ? "STOCKMOVING" : "STOCKFOLLOW"
        );
        if (actionTypeList.every((actionType) => actionType === actionTypeList[0])) {
          // 選択されているリクエストで異なるアクションタイプがない
          return actionTypeList[0];
        } else {
          // 選択されているリクエストで異なるアクションタイプがある
          return null;
        }
      }
    },
    requestActionNumber(): number {
      return this.actionInfo.requests.reduce((sum, request) => {
        return (
          sum +
          request.stockActionDetails.reduce((stockNumber, stockActionDetail) => {
            return stockNumber + stockActionDetail.stockFollowInfos.length + stockActionDetail.stockMovingInfos.length;
          }, 0)
        );
      }, 0);
    },
    pendingActionNumber(): number {
      return (
        this.actionInfo.pending.saleActions.length +
        this.actionInfo.pending.customActions.length +
        this.actionInfo.pending.stockActions.length
      );
    },
    completedActionNumber(): number {
      return this.actionInfo.completed.reduce((sum, orderedActionInfo) => {
        return (
          sum +
          orderedActionInfo.saleActions.length +
          orderedActionInfo.customActions.length +
          orderedActionInfo.stockActions.length
        );
      }, 0);
    },
  },
  methods: {
    customSort(items: DetailTrendItemEntity[], index: string[], isDesc: boolean[]) {
      const ascValue = isDesc[0] ? -1 : 1;
      items.sort((a, b) => {
        switch (index[0]) {
          case "storageLife":
            if (a.storageLife === "∞週") return ascValue;
            if (b.storageLife === "∞週") return -ascValue;
            return (parseInt(a.storageLife) - parseInt(b.storageLife)) * ascValue;
          case "digestionRate":
            if (a.digestionRate === "-") return -ascValue;
            if (b.digestionRate === "-") return ascValue;
            return (parseInt(a.digestionRate) - parseInt(b.digestionRate)) * ascValue;
          case "stockCount":
            return (a.stockCount - b.stockCount) * ascValue;
          case "unitPrice":
            if (this.tableFormat === 0) {
              return (a.salesCounts[0] - b.salesCounts[0]) * ascValue;
            } else {
              return (a.salesPrices[0] - b.salesPrices[0]) * ascValue;
            }
          case "0week":
            if (this.tableFormat === 0) {
              return (a.salesCounts[1] - b.salesCounts[1]) * ascValue;
            } else {
              return (a.salesPrices[1] - b.salesPrices[1]) * ascValue;
            }
          case "-1week":
            if (this.tableFormat === 0) {
              return (a.salesCounts[2] - b.salesCounts[2]) * ascValue;
            } else {
              return (a.salesPrices[2] - b.salesPrices[2]) * ascValue;
            }
          case "-2week":
            if (this.tableFormat === 0) {
              return (a.salesCounts[3] - b.salesCounts[3]) * ascValue;
            } else {
              return (a.salesPrices[3] - b.salesPrices[3]) * ascValue;
            }
          case "-3week":
            if (this.tableFormat === 0) {
              return (a.salesCounts[4] - b.salesCounts[4]) * ascValue;
            } else {
              return (a.salesPrices[4] - b.salesPrices[4]) * ascValue;
            }
          case "-4week":
            if (this.tableFormat === 0) {
              return (a.salesCounts[5] - b.salesCounts[5]) * ascValue;
            } else {
              return (a.salesPrices[5] - b.salesPrices[5]) * ascValue;
            }
          default:
            return 0;
        }
      });
      return items;
    },
    getColorDetail(id: string): ColorDetail {
      const colorId = this.skus.find((sku) => sku.id === id)?.colorId;
      const color = this.colors.find((color) => color.id === colorId);
      return {
        id: colorId ?? "",
        name: color?.name ?? "",
      };
    },
    getSkuId(skuColor: SkuTrendTableTabs): string {
      return skuColor.sizes.find((size) => size.sizeId === this.selectedSkuSize?.sizeId)?.skuId ?? "";
    },
    getColorName(colorId: string): string {
      return this.colors.find((color) => color.id === colorId)?.name ?? "";
    },
    getSizeDetail(id: string): SizeDetail {
      const sizeId = this.skus.find((sku) => sku.id === id)?.sizeId;
      const size = this.sizes.find((size) => size.id === sizeId);
      return {
        id: sizeId ?? "",
        name: size?.name ?? "",
      };
    },
    getSizeName(sizeId: string): string {
      return this.sizes.find((size) => size.id === sizeId)?.name ?? "";
    },
    formatDate(date: Date): string {
      if (date !== null) {
        return date.toString().substring(0, 10).replaceAll("-", "/");
      } else {
        return "";
      }
    },
    // １週間日付取得
    oneWeekDayjs(minusWeek = 0): { from: Dayjs; to: Dayjs } {
      let to: Dayjs = dayjs();
      let from: Dayjs = dayjs();
      // 曜日取得
      const day = dayjs(this.today).day();
      // 本日、日曜日の場合先週の月曜日から今日(日曜日)までを範囲とする
      // 月曜日始まりにするため下でaddしている(dayjsの週間始まりは日曜日)のとバランスをとるため
      if (day === 0) {
        minusWeek += 1;
      }
      from = dayjs(this.today).subtract(minusWeek, "week").startOf("weeks").add(1, "day");
      if (minusWeek > 0) {
        to = dayjs(this.today).subtract(minusWeek, "week").endOf("weeks").add(1, "day");
      } else {
        to = dayjs(this.today).endOf("day");
      }
      return { from: from, to: to };
    },
    getAlertDetail(alertMasterId: number): AlertDetail {
      const alert = this.alertDatas.find((item) => {
        return item.id === alertMasterId;
      });
      return {
        number: alert?.id ?? -1,
        name: alert?.name ?? "",
        color: alert?.prominenceColor ?? "",
        storeIntegration: alert?.storeIntegration ?? false,
        description: alert?.description ?? "",
        comment: alert?.comment ?? "",
      };
    },
    selectDeleteAlert(alert: ProductAlert, type: string) {
      this.selectedAlert = { alert, type };
      this.showAlert(
        `アラートを無効化しますか？
           しばらくの間フィルター検索後の結果に表示されなくなり、対応すべきアラートを集中して管理することが可能になります。`,
        "warning"
      );
      this.showingAlert = true;
    },
    async deleteAlert() {
      if (this.selectedAlert) {
        await RadialApiClient.userDeleteAlert(this.selectedAlert.alert.alertId);
        if (this.selectedAlert.type === "product") {
          this.productAlerts.filter((alert) => alert.alertId === this.selectedAlert?.alert.alertId)[0].userDeleted =
            true;
        } else if (this.selectedAlert.type === "sku") {
          this.skuAlerts.filter((alert) => alert.alertId === this.selectedAlert?.alert.alertId)[0].userDeleted = true;
        }
      }
      this.selectedAlert = null;
      this.showingAlert = false;
    },
    getMainImage(skuId: string): string {
      if (this.product) {
        return this.productDetailImages.s3KeySkus.find((item) => item.skuId === skuId)?.s3KeyWithId[0]?.s3Key ?? "";
      }
      return "";
    },
    getImageFromProduct(product: ProductDetailBasicInfoEntity): string {
      const images = this.propImages.filter((image) =>
        image.startsWith(`${this.user?.companyId}/${product.itemNumber}_`)
      );
      if (images.length > 0) {
        const mainImages = images.filter((image) => image.endsWith("_M"));
        if (mainImages.length > 0) {
          return mainImages[0];
        } else {
          return images[0];
        }
      } else {
        return "";
      }
    },
    getTotalSales(type: string): string {
      if (this.productDetailSalesDigestRateChartEntity.productData) {
        switch (type) {
          case "total":
            return this.tableFormat === 0
              ? this.productDetailSalesDigestRateChartEntity.productData.totalSalesCount?.toLocaleString() ?? "0"
              : `¥${this.productDetailSalesDigestRateChartEntity.productData.totalSalesPrice?.toLocaleString() ?? "0"}`;
          case "store":
            return this.tableFormat === 0
              ? this.productDetailSalesDigestRateChartEntity.productData.posSalesCount?.toLocaleString() ?? "0"
              : `¥${this.productDetailSalesDigestRateChartEntity.productData.posSalesPrice?.toLocaleString() ?? "0"}`;
          case "ec":
            return this.tableFormat === 0
              ? this.productDetailSalesDigestRateChartEntity.productData.ecSalesCount?.toLocaleString() ?? "0"
              : `¥${this.productDetailSalesDigestRateChartEntity.productData.ecSalesPrice?.toLocaleString() ?? "0"}`;
          default:
            return "";
        }
      } else {
        return "";
      }
    },
    getTotalStocks(type: string): string {
      if (this.productDetailSalesDigestRateChartEntity.productData) {
        switch (type) {
          case "digestionRate":
            return this.productDetailSalesDigestRateChartEntity.productData.digestionRate.toLocaleString();
          case "storageLife":
            return this.productDetailSalesDigestRateChartEntity.productData.storageLife.replace("週", "");
          case "stockCount":
            // TODO: サーバー側で在庫金額も送ってもらう
            return this.productDetailSalesDigestRateChartEntity.productData.stockCount.toLocaleString();
          case "discountRate":
            return this.productDetailSalesDigestRateChartEntity.productData.discountRate.toLocaleString();
          case "grossProfitMargin":
            return this.productDetailSalesDigestRateChartEntity.productData.grossProfitMargin.toLocaleString();
          default:
            return "";
        }
      } else {
        return "";
      }
    },
    getSkuSales(type: string, sku: ProductDetailSalesDigestRateChartData): string {
      switch (type) {
        case "total":
          return this.tableFormat === 0
            ? sku.totalSalesCount?.toLocaleString() ?? "0"
            : `¥${sku.totalSalesPrice?.toLocaleString() ?? "0"}`;
        case "store":
          return this.tableFormat === 0
            ? sku.posSalesCount?.toLocaleString() ?? "0"
            : `¥${sku.posSalesPrice?.toLocaleString() ?? "0"}`;
        case "ec":
          return this.tableFormat === 0
            ? sku.ecSalesCount?.toLocaleString() ?? "0"
            : `¥${sku.ecSalesPrice?.toLocaleString() ?? "0"}`;
        default:
          return "";
      }
    },
    getSkuStockCount(sku: ProductDetailSalesDigestRateChartData): string {
      // TODO: サーバー側でStockPriceも送ってもらう
      return this.tableFormat === 0 ? sku.stockCount.toLocaleString() : sku.stockCount.toLocaleString();
    },
    getSkuSalesMax(): number {
      let sales: number[] = [];
      for (const sale of this.productDetailSalesDigestRateChartEntity.skuData
        .map((element) => element.data)
        .map((element) =>
          element.map((element) => (this.tableFormat === 0 ? element.salesQuantity : element.salesPrice))
        )) {
        sales = sales.concat(sale);
      }
      return Math.max(...sales);
    },
    getSkuOptions(skuid: string): Options {
      const yDataSkuColumn = this.productDetailSalesDigestRateChartEntity.skuData
        .find((element) => element.id === skuid)
        ?.data.map((element) => (this.tableFormat === 0 ? element.salesQuantity : element.salesPrice ?? 0));
      const yDataSkuLine = this.productDetailSalesDigestRateChartEntity.skuData
        .find((element) => element.id === skuid)
        ?.data.map((element) => Math.ceil(Number(element.digestRate)) ?? 0);
      const labels: any =
        this.tableFormat === 0
          ? {
              formatter: function () {
                return Highcharts.numberFormat(Number(this.value), 0, ",", ",");
              },
              style: {
                // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            }
          : {
              formatter: function () {
                return `￥${Highcharts.numberFormat(Number(this.value), 0, ",", ",")}`;
              },
              style: {
                // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            };
      const yDataSkuColumnMax = this.getSkuSalesMax();
      const tickInterval = this.selectedPeriod === 3 ? 2 : this.selectedPeriod === 6 ? 3 : 2;
      const tooltip: TooltipOptions =
        this.tableFormat === 0
          ? {
              formatter: function () {
                let s = this.x.toString();
                if (this.points) {
                  s +=
                    "<br/>" +
                    '<b style="color:#76B3E0">' +
                    this.points[0].series.name +
                    "</b>" +
                    ": " +
                    this.points[0].y.toLocaleString();
                  s +=
                    "<br/>" +
                    '<b style="color:#519998">' +
                    this.points[1].series.name +
                    "</b>" +
                    ": " +
                    this.points[1].y +
                    "%";
                }
                return s;
              },
              shared: true,
              style: {
                // ツールチップ内の文字カラーはassistantType70
                color: "#3C6065",
                fontSize: "12px",
              },
            }
          : {
              formatter: function () {
                let s = this.x.toString();
                if (this.points) {
                  s +=
                    "<br/>" +
                    '<b style="color:#76B3E0">' +
                    this.points[0].series.name +
                    "</b>" +
                    ": " +
                    "￥" +
                    this.points[0].y.toLocaleString();
                  s +=
                    "<br/>" +
                    '<b style="color:#519998">' +
                    this.points[1].series.name +
                    "</b>" +
                    ": " +
                    this.points[1].y +
                    "%";
                }
                return s;
              },
              shared: true,
              style: {
                // ツールチップ内の文字カラーはassistantType70
                color: "#3C6065",
                fontSize: "12px",
              },
            };
      if (yDataSkuColumn && yDataSkuLine) {
        return {
          title: undefined,
          chart: {
            width: this.isTablet ? 305 : this.showingAction ? 390 : 480,
            height: this.isTablet ? 150 : 160,
            backgroundColor: "#fbfbfb",
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          xAxis: [
            {
              categories: this.xLabel,
              tickInterval: tickInterval, // one month
              gridLineWidth: 1,
              labels: {
                style: {
                  // X軸の各ポイントにおけるカラー（assistantType50で統一）
                  color: "#799498",
                },
              },
            },
          ],
          yAxis: [
            {
              labels: labels,
              // 1つ目のy軸設定
              title: {
                text: undefined,
              },
              endOnTick: false,
              min: 0,
              max:
                (Math.floor(yDataSkuColumnMax / Math.pow(10, yDataSkuColumnMax.toString().length - 1)) + 1) *
                Math.pow(10, yDataSkuColumnMax.toString().length - 1),
              tickInterval:
                ((Math.floor(yDataSkuColumnMax / Math.pow(10, yDataSkuColumnMax.toString().length - 1)) + 1) *
                  Math.pow(10, yDataSkuColumnMax.toString().length - 1)) /
                (this.isTablet ? 4 : 5), // y軸のgrid幅を揃える
            },
            {
              // 2つ目のy軸設定
              title: {
                text: undefined,
              },
              labels: {
                format: "{value}%",
                enabled: true, // y軸のメモリを表示
                style: {
                  // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                  color: "#799498",
                },
              },
              endOnTick: false,
              min: 0,
              max: 100,
              tickInterval: this.isTablet ? 25 : 20, // y軸のgrid幅を揃える
              opposite: true, // trueの場合グラフの右側にy軸を配置する
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: false,
                color: "#13365c",
                verticalAlign: "bottom",
                useHTML: true,
              },
            },
            line: {
              dataLabels: {
                enabled: false,
                color: "#006400",
                verticalAlign: "bottom",
                useHTML: true,
              },
            },
          },
          tooltip: tooltip,
          series: [
            {
              // 1つ目のグラフデータ
              name: "売上", // グラフの名前指定
              type: "column", // グラフの形指定
              data: yDataSkuColumn, // グラフに使用するデータ指定
              yAxis: 0, // y軸を指定
              color: "#76B3E0",
              pointWidth: 7,
            },
            {
              // 2つ目のグラフデータ
              name: "消化率", // グラフの名前指定
              type: "line", // グラフの形指定
              data: yDataSkuLine, // グラフに使用するデータ指定
              yAxis: 1, // y軸を指定
              color: "#519998",
              marker: {
                fillColor: "#FFFFFF",
                radius: 2,
                lineWidth: 1,
                lineColor: undefined, // inherit from series
              },
            },
          ],
        };
      } else {
        return {
          title: undefined,
          chart: {
            width: 305,
            height: 200,
            backgroundColor: "#fbfbfb",
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
        };
      }
    },
    getSkuTrendAlertDatas(skuId: string): ProductAlert[] {
      return this.skuAlerts.filter((alert) => alert.id === skuId);
    },
    async searchAdditionalSkus() {
      this.isLoading = true;
      // もっと見るで追加でSKUのグラフ情報をサーチ
      const productDetailSalesDigestRateChartEntity = await RadialApiClient.getProductDetailTrendChartsValue(
        this.product.id,
        this.productDetailSalesDigestRateChartEntity.skuData.length + 1,
        this.productDetailSalesDigestRateChartEntity.skuData.length + 10,
        this.selectedPeriod
      );
      if (productDetailSalesDigestRateChartEntity) {
        this.productDetailSalesDigestRateChartEntity.skuData =
          this.productDetailSalesDigestRateChartEntity.skuData.concat(productDetailSalesDigestRateChartEntity.skuData);
        this.skuDetailSalesDigestRateChartData = { ...this.productDetailSalesDigestRateChartEntity.skuData };
        // 親コンポーネントのデータ更新
        this.$emit("update-graph", this.productDetailSalesDigestRateChartEntity);
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      this.isLoading = false;
    },
    async selectPeriod() {
      this.isLoading = true;
      const productDetailSalesDigestRateChartEntity = await RadialApiClient.getProductDetailTrendChartsValue(
        this.product.id,
        1,
        this.productDetailSalesDigestRateChartEntity.skuData.length,
        this.selectedPeriod
      );
      if (productDetailSalesDigestRateChartEntity) {
        this.productDetailSalesDigestRateChartEntity = productDetailSalesDigestRateChartEntity;
        // 親コンポーネントのデータ更新
        this.$emit("update-period", this.selectedPeriod);
        this.$emit("update-graph", productDetailSalesDigestRateChartEntity);
      }
      this.skuDetailSalesDigestRateChartData = { ...this.productDetailSalesDigestRateChartEntity.skuData };
      this.xLabel =
        this.productDetailSalesDigestRateChartEntity.productData?.data.map(
          (element) => `${dayjs(element.date).format("MM.DD")}`
        ) ?? [];
      if (this.tableFormat === 0) {
        this.yDataColumn =
          this.productDetailSalesDigestRateChartEntity.productData?.data.map((element) => element.salesQuantity ?? 0) ??
          [];
      } else {
        this.yDataColumn =
          this.productDetailSalesDigestRateChartEntity.productData?.data.map((element) => element.salesPrice ?? 0) ??
          [];
      }
      this.yDataLine =
        this.productDetailSalesDigestRateChartEntity.productData?.data.map(
          (element) => Math.ceil(Number(element.digestRate)) ?? 0
        ) ?? [];
      this.isLoading = false;
    },
    selectTableFormat() {
      if (this.tableFormat === 0) {
        // select quantity
        this.yDataColumn =
          this.productDetailSalesDigestRateChartEntity.productData?.data.map((element) => element.salesQuantity ?? 0) ??
          [];
      } else {
        // this.tableFormat === 1 // select price
        this.yDataColumn =
          this.productDetailSalesDigestRateChartEntity.productData?.data.map((element) => element.salesPrice ?? 0) ??
          [];
      }
    },
    async selectTrendFormat() {
      this.isLoading = true;
      if (this.tableFormatChartList === 1) {
        this.productTableInfo = await RadialApiClient.getProductDetailTrends(this.$route.params.productId);
        this.productTableInfo?.items.sort((a, b) => {
          const aName = this.getChanelName(a.storeId, a.warehouseId);
          const bName = this.getChanelName(b.storeId, b.warehouseId);

          // 店舗側か倉庫側かを判定
          const isAStore = a.storeId !== null;
          const isBStore = b.storeId !== null;

          if (isAStore && !isBStore) {
            return -1; // 店舗を優先
          } else if (!isAStore && isBStore) {
            return 1; // 倉庫は後に
          } else if (isAStore && isBStore) {
            return aName.localeCompare(bName, "ja"); // 店舗同士をあいうえお順に並び替え
          } else {
            return aName.localeCompare(bName, "ja"); // 倉庫同士をあいうえお順に並び替え
          }
        });
        const colorIds: string[] = [];
        this.skus.forEach((sku) => {
          const skuAlerts = this.skuAlerts.filter((skuAlert) => skuAlert.id === sku.id);
          if (colorIds.includes(sku.colorId)) {
            this.skuTableTabs
              .find((colorTab) => colorTab.colorId === sku.colorId)
              ?.sizes.push({
                skuId: sku.id,
                sizeId: sku.sizeId,
                skuAlerts: skuAlerts.map((skuAlert) => {
                  return {
                    id: skuAlert.id,
                    alertId: skuAlert.alertId,
                    alertMasterId: skuAlert.alertMasterId,
                    userDeleted: skuAlert.userDeleted,
                  };
                }),
              });
          } else {
            this.skuTableTabs.push({
              colorId: sku.colorId,
              sizes: [
                {
                  skuId: sku.id,
                  sizeId: sku.sizeId,
                  skuAlerts: skuAlerts.map((skuAlert) => {
                    return {
                      id: skuAlert.id,
                      alertId: skuAlert.alertId,
                      alertMasterId: skuAlert.alertMasterId,
                      userDeleted: skuAlert.userDeleted,
                    };
                  }),
                },
              ],
            });
            colorIds.push(sku.colorId);
          }
        });
        if (this.skuTableTabs[0]) {
          this.skuTableInfo = await RadialApiClient.getSkuDetailTrends(this.skuTableTabs[0].sizes[0].skuId);
          this.skuTableInfo?.items.sort((a, b) => {
            const aName = this.getChanelName(a.storeId, a.warehouseId);
            const bName = this.getChanelName(b.storeId, b.warehouseId);

            // 店舗側か倉庫側かを判定
            const isAStore = a.storeId !== null;
            const isBStore = b.storeId !== null;

            if (isAStore && !isBStore) {
              return -1; // 店舗を優先
            } else if (!isAStore && isBStore) {
              return 1; // 倉庫は後に
            } else if (isAStore && isBStore) {
              return aName.localeCompare(bName, "ja"); // 店舗同士をあいうえお順に並び替え
            } else {
              return aName.localeCompare(bName, "ja"); // 倉庫同士をあいうえお順に並び替え
            }
          });
          this.selectedSkuColor = this.skuTableTabs[0];
          this.selectedSkuSize = this.skuTableTabs[0].sizes[0];
        }
      } else {
        this.skuTableTabs = [];
        this.selectedSkuColor = null;
        this.selectedSkuSize = null;
      }
      this.isLoading = false;
    },
    getServiceLogo(storeId: string, warehouseId: string): string {
      const serviceId =
        warehouseId === null
          ? this.stores.find((store) => store.id === storeId)?.serviceId
          : this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.serviceId;
      switch (serviceId) {
        case 0:
          return require("@/assets/smaregi-logo.svg");
        case 1:
          return require("@/assets/shopify.svg");
        case 100:
          return require("@/assets/futureshop.svg");
        case 101:
          return require("@/assets/shopify.svg");
        case 103:
          return require("@/assets/base.svg");
        case 200:
          return require("@/assets/logizard.png");
        default:
          return require("@/assets/customServiceLogo.svg");
      }
    },
    getChanelName(storeId: string | null, warehouseId: string | null): string {
      const chanelName =
        warehouseId === null
          ? this.stores.find((store) => store.id === storeId)?.name
          : this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name;
      return chanelName ?? "";
    },
    async changeColorTab(skuColor: SkuTrendTableTabs) {
      this.isLoading = true;
      this.selectedSkuColor = skuColor;
      this.selectedSkuSize = this.selectedSkuColor.sizes[0];
      this.skuTableInfo = await RadialApiClient.getSkuDetailTrends(this.selectedSkuSize.skuId);
      this.skuTableInfo?.items.sort((a, b) => {
        const aName = this.getChanelName(a.storeId, a.warehouseId);
        const bName = this.getChanelName(b.storeId, b.warehouseId);

        // 店舗側か倉庫側かを判定
        const isAStore = a.storeId !== null;
        const isBStore = b.storeId !== null;

        if (isAStore && !isBStore) {
          return -1; // 店舗を優先
        } else if (!isAStore && isBStore) {
          return 1; // 倉庫は後に
        } else if (isAStore && isBStore) {
          return aName.localeCompare(bName, "ja"); // 店舗同士をあいうえお順に並び替え
        } else {
          return aName.localeCompare(bName, "ja"); // 倉庫同士をあいうえお順に並び替え
        }
      });
      this.isLoading = false;
    },
    async changeSizeTab(skuSize: SkuSizeTrendTableData) {
      this.isLoading = true;
      this.selectedSkuSize = skuSize;
      this.skuTableInfo = await RadialApiClient.getSkuDetailTrends(this.selectedSkuSize.skuId);
      this.skuTableInfo?.items.sort((a, b) => {
        const aName = this.getChanelName(a.storeId, a.warehouseId);
        const bName = this.getChanelName(b.storeId, b.warehouseId);

        // 店舗側か倉庫側かを判定
        const isAStore = a.storeId !== null;
        const isBStore = b.storeId !== null;

        if (isAStore && !isBStore) {
          return -1; // 店舗を優先
        } else if (!isAStore && isBStore) {
          return 1; // 倉庫は後に
        } else if (isAStore && isBStore) {
          return aName.localeCompare(bName, "ja"); // 店舗同士をあいうえお順に並び替え
        } else {
          return aName.localeCompare(bName, "ja"); // 倉庫同士をあいうえお順に並び替え
        }
      });
      this.isLoading = false;
    },
    async showActions() {
      this.isLoading = true;
      this.showingAction = true;
      this.customActionMasterList = (await RadialApiClient.listCustomActionMasters()) ?? [];
      // actionの一覧を取得(含まれているもののみ取得)
      const actionIds = this.actionInfo.pending.saleActions
        .map((action) => action.actionId)
        .concat(this.actionInfo.pending.customActions.map((action) => action.actionId))
        .concat(
          this.actionInfo.pending.stockActions
            .map((action) => action.stockActionDetails.map((stockAction) => stockAction.actionId))
            .flat()
            .filter((item): item is NonNullable<typeof item> => item !== null)
        )
        .concat(
          this.actionInfo.completed.map((action) => action.saleActions.map((saleAction) => saleAction.actionId)).flat()
        )
        .concat(
          this.actionInfo.completed
            .map((action) => action.customActions.map((customAction) => customAction.actionId))
            .flat()
        )
        .concat(
          this.actionInfo.completed
            .map((action) =>
              action.stockActions
                .map((stockAction) =>
                  stockAction.stockActionDetails.map((stockActionDetail) => stockActionDetail.actionId)
                )
                .flat()
            )
            .flat()
            .filter((item): item is NonNullable<typeof item> => item !== null)
        );
      for (const actionId of actionIds) {
        const action = await RadialApiClient.getAction(actionId);
        if (action) {
          this.actionList.push(action);
        }
      }
      // customActionDetailの一覧を取得(含まれているもののみ取得)
      const customActionDetailIds = this.actionInfo.pending.customActions
        .map((customAction) => customAction.customActionId)
        .concat(
          this.actionInfo.completed
            .map((action) => action.customActions.map((customAction) => customAction.customActionId))
            .flat()
        );
      for (const customActionDetailId of customActionDetailIds) {
        const customActionDetail = await RadialApiClient.getCustomActionDetail(customActionDetailId);
        if (customActionDetail) {
          this.customActionDetailList.push(customActionDetail);
        }
      }
      // stockActionDetailでリクエストの一覧を取得(含まれているもののみ取得)
      const stockActionDetailIds = this.actionInfo.requests
        .map((request) =>
          request.stockActionDetails
            .map((stockActionDetail) =>
              stockActionDetail.stockFollowInfos.map((stockInfo) => stockInfo.stockActionDetailId)
            )
            .flat()
        )
        .concat(
          this.actionInfo.requests.map((request) =>
            request.stockActionDetails
              .map((stockActionDetail) =>
                stockActionDetail.stockMovingInfos.map((stockInfo) => stockInfo.stockActionDetailId)
              )
              .flat()
          )
        )
        .flat();
      for (const stockActionDetailId of stockActionDetailIds) {
        const stockActionDetail = await RadialApiClient.getStockActionDetail(stockActionDetailId);
        if (stockActionDetail) {
          this.stockActionDetailList.push(stockActionDetail);
        }
      }
      this.isLoading = false;
    },
    getCustomActionMasterName(customActionDetailId: string): string {
      const actionId = this.customActionDetailList.find(
        (customActionDetail) => customActionDetail.id === customActionDetailId
      )?.actionId;
      const customActionMasterId = this.actionList.find((action) => action.id === actionId)?.customActionMasterId;
      if (customActionMasterId) {
        return (
          this.customActionMasterList.find((customActionMaster) => customActionMaster.id === customActionMasterId)
            ?.name ?? ""
        );
      } else {
        return "";
      }
    },
    getActionName(actionId: string | null): string {
      if (actionId) {
        return this.actionList.find((action) => action.id === actionId)?.title ?? "";
      } else {
        return "";
      }
    },
    getStoreName(storeId: string): string {
      return this.stores.find((store) => store.id === storeId)?.name ?? "";
    },
    getWarehouseName(warehouseId: string): string {
      return this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name ?? "";
    },
    getIcon(actionId: string | null): string {
      if (actionId) {
        const action = this.actionList.find((action) => action.id === actionId);
        if (action) {
          return (
            this.adminUserDetails.find((adminUserDetail) => {
              if (adminUserDetail !== null && action.managerAdminUserId !== null) {
                return adminUserDetail.id === action.managerAdminUserId;
              } else {
                return false;
              }
            })?.icon ?? ""
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getExecuteDate(date: Date): string {
      return dayjs(date).format("YYYY-MM-DD");
    },
    openActionList(actionId: string | null) {
      if (actionId) {
        const routeData = this.$router.resolve({
          name: "AdminActionList",
          params: { actionId },
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "AdminActionList",
          params: this.createdActionId ? { actionId: this.createdActionId } : undefined,
        });
        window.open(routeData.href, "_blank");
      }
    },
    getIconFromStockActionDetailId(stockActionDetailId: string): string | null {
      const stockActionDetail = this.stockActionDetailList.find(
        (stockActionDetail) => stockActionDetail.id === stockActionDetailId
      );
      if (stockActionDetail && stockActionDetail.creatorStoreId === null) {
        if (stockActionDetail.creatorAdminUserId !== null) {
          // adminUserIdがある場合
          return (
            this.adminUserDetails.find((adminUserDetail) => adminUserDetail.id === stockActionDetail.creatorAdminUserId)
              ?.icon ?? ""
          );
        } else {
          // companyの場合
          return store.state.company?.icon ?? "";
        }
      } else {
        // 店舗の場合
        return null;
      }
    },
    getIconFromAdminUserId(adminUserId: string): string {
      return this.adminUserDetails.find((adminUserDetail) => adminUserDetail.id === adminUserId)?.icon ?? "";
    },
    selectRequest(stockActionDetailId: string) {
      if (this.selectedStockActionDetailIds.includes(stockActionDetailId)) {
        // stockActionDetailIdが含まれていたら削除
        this.selectedStockActionDetailIds = this.selectedStockActionDetailIds.filter(
          (selectedStockActionDetailId) => selectedStockActionDetailId !== stockActionDetailId
        );
      } else {
        // stockActionDetailIdが含まれていなかったら追加
        this.selectedStockActionDetailIds.push(stockActionDetailId);
      }
    },
    async deleteActionRequest(stockActionDetailId: string) {
      this.isLoading = true;
      await RadialApiClient.deleteStockActionDetail(stockActionDetailId);
      // get Action Info
      this.actionInfo = (await RadialApiClient.getProductDetailActionList(this.product.id)) ?? {
        requests: [],
        pending: { saleActions: [], customActions: [], stockActions: [] },
        completed: [],
      };
      this.isLoading = false;
    },
    addActionButtonClicked() {
      // アクションタイプが異なる場合はエラーハンドリング
      if (this.selectedActionType) {
        this.showingCreateAction = true;
      } else {
        this.showAlert(
          `異なるアクションタイプのリクエストが選択されています。同一のアクションタイプのリクエストを選択しアクションを作成してください。`,
          "error"
        );
      }
    },
    cancelCreateAction() {
      // 設定をリセット
      this.showingCreateAction = false;
      this.createActionListInfo.title = null;
      this.createActionListInfo.managerId = null;
      this.createActionListInfo.executedDate = null;
    },
    async createAction() {
      if (this.createActionListInfo.title && this.selectedActionType) {
        // Actionテーブルにレコードを追加
        const actionDto: CreateActionDto = {
          title: this.createActionListInfo.title ?? "",
          type: this.selectedActionType,
          customActionMasterId: null,
          creatorAdminUserId: store.state.user?.sub,
          managerAdminUserId: this.createActionListInfo.managerId,
          status: "PENDING",
          company: { connect: { id: store.state.company?.id } },
          executedDate: this.createActionListInfo.executedDate,
        };
        const action = await RadialApiClient.createAction(actionDto);
        const dto: ActionCreateDto = {
          stockActionDetailIds: this.selectedStockActionDetailIds,
          title: this.createActionListInfo.title,
          actionId: action?.id ?? "",
        };
        await RadialApiClient.createActionFromRequests(dto)
          .then(async () => {
            this.showAlert("アクションを作成しました。", "success");
            // get Action Info
            this.actionInfo = (await RadialApiClient.getProductDetailActionList(this.product.id)) ?? {
              requests: [],
              pending: { saleActions: [], customActions: [], stockActions: [] },
              completed: [],
            };
            if (action) {
              this.actionList.push(action);
            }
          })
          .catch(() => {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          });
      }
      // 設定をリセット
      this.createActionListInfo.title = null;
      this.createActionListInfo.managerId = null;
      this.createActionListInfo.executedDate = null;
      this.selectedStockActionDetailIds = [];
      this.showingCreateAction = false;
    },
    createActionButtonDisable(): boolean {
      return !(this.createActionListInfo.title && this.createActionListInfo.managerId);
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getActionId(orderedActionInfo: OrderedActionInfoEntity): string {
      if (orderedActionInfo.saleActions.length !== 0) {
        // セールアクションがある場合
        return orderedActionInfo.saleActions[0].actionId;
      } else if (orderedActionInfo.customActions.length !== 0) {
        // カスタムアクションがある場合
        return orderedActionInfo.customActions[0].actionId;
      } else {
        // ストックアクションがある場合
        return orderedActionInfo.stockActions[0].stockActionDetails[0].actionId ?? "";
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    // 作成者と担当者のリスト情報を取得
    if (store.state.company) {
      this.adminUserDetails.push({
        id: store.state.company.id,
        icon: store.state.company.icon ?? "",
        name: store.state.company.name,
      });
    }
    const adminUsers: AdminUser[] = (await RadialApiClient.listAdminUsers()) ?? [];
    adminUsers.map((adminUser) => {
      if (adminUser.adminUserMasterId !== 3) {
        this.adminUserDetails.push({ id: adminUser.id, icon: adminUser.icon ?? "", name: adminUser.name });
      }
    });
    await Promise.all([
      (async () => {
        this.alertDatas = (await RadialApiClient.listAlertMasters()) ?? [];
      })(),
      (async () => {
        this.skus = (await RadialApiClient.listSkusByProduct(this.product.id)) ?? [];
        this.skus.sort((a, b) => {
          const aColorCode = this.colors.find((color) => color.id === a.colorId)?.code ?? 0;
          const bColorCode = this.colors.find((color) => color.id === b.colorId)?.code ?? 0;
          const aSizeCode = this.sizes.find((size) => size.id === a.sizeId)?.code ?? 0;
          const bSizeCode = this.sizes.find((size) => size.id === b.sizeId)?.code ?? 0;
          if (aColorCode > bColorCode) return 1;
          if (aColorCode < bColorCode) return -1;
          if (aSizeCode > bSizeCode) return 1;
          if (aSizeCode < bSizeCode) return -1;
          return 0;
        });
      })(),
      (async () => {
        const allProductAlerts = (await RadialApiClient.listAlertsByProduct(this.product.id)) ?? [];
        this.productAlerts = allProductAlerts
          .filter((alert) => alert.skuId === null)
          .map((productAlert) => {
            return {
              id: productAlert.productId,
              alertId: productAlert.id,
              alertMasterId: productAlert.alertMasterId,
              userDeleted: productAlert.userDeleted,
            };
          });
        this.skuAlerts = allProductAlerts
          .filter((alert) => alert.skuId !== null)
          .map((skuAlert) => {
            return {
              id: skuAlert.skuId ?? "",
              alertId: skuAlert.id,
              alertMasterId: skuAlert.alertMasterId,
              userDeleted: skuAlert.userDeleted,
            };
          });
      })(),
    ]);
    // mounted時に都度取得しないよう、親コンポーネントからデータ取得
    this.selectedPeriod = this.propPeriod;
    this.productDetailSalesDigestRateChartEntity = this.propGraph;
    if (this.productDetailSalesDigestRateChartEntity.productData) {
      this.skuDetailSalesDigestRateChartData = { ...this.productDetailSalesDigestRateChartEntity.skuData };
      this.xLabel = this.productDetailSalesDigestRateChartEntity.productData.data.map(
        (element) => `${dayjs(element.date).format("MM.DD")}`
      );
      if (this.tableFormat === 0) {
        this.yDataColumn = this.productDetailSalesDigestRateChartEntity.productData.data.map(
          (element) => element.salesQuantity ?? 0
        );
      } else {
        this.yDataColumn = this.productDetailSalesDigestRateChartEntity.productData.data.map(
          (element) => element.salesPrice ?? 0
        );
      }
      this.yDataLine = this.productDetailSalesDigestRateChartEntity.productData.data.map(
        (element) => Math.ceil(Number(element.digestRate)) ?? 0
      );
    }
    // get product detail images
    const productDetailImages = await RadialApiClient.getProductDetailImages(this.product.id);
    if (productDetailImages) {
      this.productDetailImages = productDetailImages;
    }
    this.isLoading = false;
    // get Action Info
    this.actionInfo = (await RadialApiClient.getProductDetailActionList(this.product.id)) ?? {
      requests: [],
      pending: { saleActions: [], customActions: [], stockActions: [] },
      completed: [],
    };
  },
});
