
import Vue from "vue";
import mixin from "@/mixin/mixin";

export interface DataType {}

export default Vue.extend({
  name: "IntegrationFutureshop",
  mixins: [mixin],
  components: {},
  data(): DataType {
    return {};
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    integrationBargainTypes: {
      type: Object,
      required: true,
    },
    bargainTypes: {
      type: Array as () => Object[],
      required: true,
    },
    newFutureshopIntegration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogFutureshop: {
      get(): boolean {
        return (this as any).value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    integrateFutureshop() {
      this.$emit("integrate", {
        newFutureshopIntegration: this.newFutureshopIntegration,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
});
