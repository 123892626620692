
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { Storage } from "aws-amplify";
import Encoding from "encoding-japanese";
import RadialApiClient from "@/api/RadialApiClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import {
  Company,
  ProductDetailBasicInfoEntity,
  Attribute,
  AdminUser,
  Factory,
  Brand,
  Season,
  SmaregiIntegration,
  Category,
  CsvProductNumbersEntity,
  CsvItemSkuCheckResultEntity,
  CsvProductSku,
  CustomColumnEntity,
} from "@/api/entities";
import dayjs from "dayjs";
import { User, CsvItemSkuUploadFilters } from "@/store/model";
import { Constant } from "@/store/constant";
import AuthClient from "@/api/AuthClient";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import Loading from "@/components/vuetify/Loading.vue";
import { CsvConditionDto } from "@/api/dto";
import mixin from "@/mixin/mixin";
import store from "@/store";

export interface DataType {
  products: ProductDetailBasicInfoEntity[];
  user: User | null;
  file: File | null;
  companyAdminUser: Company | AdminUser | null;
  companyTimer: number;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  isLoading: boolean;
  value: number;
  attributes: Attribute[];
  csvCheckMessage: CsvItemSkuCheckResultEntity;
  uploading: boolean;
  isCsvChecked: boolean;
  csvS3Key: string;
  sampleCsvUrl: { product: string; sku: string };
  factories: Factory[];
  brands: Brand[];
  categories: Category[];
  seasons: Season[];
  csvItemSkuUploadFilters: CsvItemSkuUploadFilters;
  smaregiIntegration: SmaregiIntegration | undefined;
  productSkuNumbers: CsvProductNumbersEntity;
  showProductAction: boolean;
  showSkuAction: boolean;
}

export default Vue.extend({
  name: "CsvItemSkuUpload",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    FilterChipButton,
    anum,
    Loading,
  },
  data(): DataType {
    return {
      products: [],
      user: null,
      file: null,
      companyAdminUser: null,
      companyTimer: -1,
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      isLoading: true,
      value: 0,
      attributes: [],
      csvCheckMessage: { csvType: CsvProductSku.OTHER, errors: [], status: 0 },
      uploading: false,
      isCsvChecked: false,
      csvS3Key: "",
      sampleCsvUrl: { product: "", sku: "" },
      factories: [],
      brands: [],
      categories: [],
      seasons: [],
      csvItemSkuUploadFilters: {
        brand: [],
        season: [],
        category: [],
        attribute1: [],
        attribute2: [],
        attribute3: [],
      },
      smaregiIntegration: undefined,
      productSkuNumbers: {
        productNumber: 0,
        skuNumber: 0,
      },
      showProductAction: true,
      showSkuAction: true,
    };
  },
  computed: {
    productActions(): string[] {
      if (this.productSkuNumbers.productNumber === 0) {
        return this.canCreate ? ["サンプルデータダウンロード", "削除"] : ["サンプルデータダウンロード"];
      } else {
        return this.canCreate ? ["radial - csvダウンロード", "削除"] : ["radial - csvダウンロード"];
      }
    },
    skuActions(): string[] {
      if (this.productSkuNumbers.skuNumber === 0) {
        return this.canCreate ? ["サンプルデータダウンロード", "削除"] : ["サンプルデータダウンロード"];
      } else {
        const tab: string[] = ["radial - csvダウンロード"];
        if (store.state.company?.smaregiIntegration) {
          tab.push("スマレジ - csvダウンロード");
        }
        if (store.state.company?.shopifyIntegration) {
          tab.push("Shopify - csvダウンロード");
        }
        if (this.canCreate) {
          tab.push("削除");
        }
        return tab;
      }
    },
    buttonTitle(): string {
      if (this.companyAdminUser) {
        switch (this.companyAdminUser.saleStockProgress) {
          case 100:
            return "ファイルを送信";
          case 0:
            return "ファイルを送信";
          case -1:
            return "アップロード中にエラーが起きています";
          default:
            return "ファイルを送信中...";
        }
      }
      return "";
    },
    updateCount(): number {
      return this.csvCheckMessage.updateCount ?? 0;
    },
    createCount(): number {
      return this.csvCheckMessage.createCount ?? 0;
    },
    isRoot(): boolean {
      return this.user?.sub === this.user?.companyId;
    },
    attributesLength(): number {
      return (
        this.csvItemSkuUploadFilters.attribute1.length +
        this.csvItemSkuUploadFilters.attribute2.length +
        this.csvItemSkuUploadFilters.attribute3.length
      );
    },
    csvCondition(): CsvConditionDto {
      return {
        seasonIds: this.csvItemSkuUploadFilters.season.map((item) => {
          return item.id;
        }),
        brandIds: this.csvItemSkuUploadFilters.brand.map((item) => {
          return item.id;
        }),
        categoryIds: this.csvItemSkuUploadFilters.category.map((item) => {
          return item.id;
        }),
        attribute1Ids: this.csvItemSkuUploadFilters.attribute1.map((item) => {
          return item.id;
        }),
        attribute2Ids: this.csvItemSkuUploadFilters.attribute2.map((item) => {
          return item.id;
        }),
        attribute3Ids: this.csvItemSkuUploadFilters.attribute3.map((item) => {
          return item.id;
        }),
      };
    },
    csvType(): string {
      switch (this.csvCheckMessage.csvType) {
        case "PRODUCT":
          return "品番";
        case "SKU":
          return "sku";
        case "OTHER":
          return "件";
        default:
          return "件";
      }
    },
  },
  methods: {
    async search() {
      this.isLoading = true;
      const productSkuNumbers = await RadialApiClient.getCsvProductNumbers(this.csvCondition);
      if (productSkuNumbers) {
        this.productSkuNumbers = productSkuNumbers;
      }
      this.isLoading = false;
    },
    convertDoubleQuotationForCsv(string: string | number | null | undefined): string {
      // "があるとcsvでバグの原因となる
      // ""に変換するとcsvでは"として扱われる
      // csvとして出力する文字列に適用する
      if (string) {
        return string.toString().replaceAll('"', '""');
      }
      // 関数内にnullの例外処理を含めておく
      return "";
    },
    convertDoubleQuotationForShopifyCustomCsv(column: CustomColumnEntity | undefined): string {
      if (!column || !column.value || column.value === "null") {
        // columnがundefinedの時、column.valueがnullの時、column.valueが"null"という文字列の時
        return "";
      }
      return column.value.toString();
    },
    async productActionChanged(event: string) {
      this.isLoading = true;
      switch (event) {
        case "サンプルデータダウンロード":
          location.href = this.sampleCsvUrl.product;
          break;
        case "radial - csvダウンロード":
          const csvData = await RadialApiClient.downloadProductCsv(this.csvCondition);
          if (csvData) {
            let csv = "";
            csv += Constant.productCsvHeaders.map((x) => `"${x}"`).join(",");
            if (csvData.customColumnHeaders.length !== 0) {
              csv += ",";
            }
            csv += csvData.customColumnHeaders.map((x) => `"${x}"`).join(",") + "\n";
            for (const product of csvData.entities) {
              let list = [
                this.convertDoubleQuotationForCsv(product.itemNumber),
                product.status,
                this.convertDoubleQuotationForCsv(product.name),
                this.convertDoubleQuotationForCsv(product.brand),
                this.convertDoubleQuotationForCsv(product.season),
                this.convertDoubleQuotationForCsv(product.category),
                product.gender ?? "",
                this.convertDoubleQuotationForCsv(product.factory),
                product.store ?? "",
                this.convertDoubleQuotationForCsv(product.attribute1),
                this.convertDoubleQuotationForCsv(product.attribute2),
                this.convertDoubleQuotationForCsv(product.attribute3),
                this.convertDoubleQuotationForCsv(product.salesStartedAt),
                this.convertDoubleQuotationForCsv(product.salesEndedAt),
                product.price ?? "",
                product.salesPrice ?? "",
                this.convertDoubleQuotationForCsv(product.salesPriceUpdatedAt),
                this.convertDoubleQuotationForCsv(product.composition),
                this.convertDoubleQuotationForCsv(product.description),
                this.convertDoubleQuotationForCsv(product.freeComment),
                this.convertDoubleQuotationForCsv(product.directionMemo),
                product.leadTime ?? "",
                product.minimumOrderQuantity ?? "",
              ];
              list = list.filter((item): item is NonNullable<typeof item> => item != null);
              csv += list.map((item) => `"${item}"`).join(",");
              for (const header of csvData.customColumnHeaders) {
                csv += `,"${this.convertDoubleQuotationForCsv(
                  product.customColumns.find((item) => item.name === header)?.value
                )}"`;
              }
              csv += "\n";
            }
            this.downloadCsv(csv, "data_item.csv");
          } else {
            this.showAlert(
              `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
          break;
        case "削除":
          const message = `${this.productSkuNumbers.productNumber}件の品番を削除しますか？これらの品番に紐付けられたskuも合わせて削除されます。`;
          if (confirm(message)) {
            await RadialApiClient.deleteProductFromCsvPage(this.csvCondition).catch(() => {
              this.showAlert(
                `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
                "error"
              );
            });
            await this.search();
          }
          break;
        default:
          break;
      }
      // v-selectを再描画させる
      this.showProductAction = false;
      this.$nextTick(() => (this.showProductAction = true));
      this.isLoading = false;
    },
    async skuActionChanged(event: string) {
      this.isLoading = true;
      switch (event) {
        case "サンプルデータダウンロード":
          location.href = this.sampleCsvUrl.sku;
          break;
        case "radial - csvダウンロード":
          const csvData = await RadialApiClient.downloadSkuCsv(this.csvCondition);
          if (csvData) {
            let csv = "";
            csv += Constant.skuCsvHeaders.map((x) => `"${x}"`).join(",");
            if (csvData.customColumnHeaders.length !== 0) {
              csv += ",";
            }
            csv += csvData.customColumnHeaders.map((x) => `"${x}"`).join(",") + "\n";
            for (const sku of csvData.entities) {
              let list = [
                this.convertDoubleQuotationForCsv(sku.itemNumber),
                this.convertDoubleQuotationForCsv(sku.janCode),
                this.convertDoubleQuotationForCsv(sku.colorCode),
                this.convertDoubleQuotationForCsv(sku.sizeCode),
                sku.cost ?? "",
              ];
              list = list.filter((item): item is NonNullable<typeof item> => item != null);
              csv += list.map((item) => `"${item}"`).join(",");
              for (const header of csvData.customColumnHeaders) {
                csv += `,"${this.convertDoubleQuotationForCsv(
                  sku.customColumns.find((item) => item.name === header)?.value
                )}"`;
              }
              csv += "\n";
            }
            this.downloadCsv(csv, "data_sku.csv");
          } else {
            this.showAlert(
              `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
          break;
        case "スマレジ - csvダウンロード":
          const csvDataForSmaregi = await RadialApiClient.downloadSmaregiSkuCsv(this.csvCondition);
          if (csvDataForSmaregi) {
            let csvHeaders: string[] = [];
            Constant.skuSmaregiCsvHeaders.map((x) => csvHeaders.push(`${x}`));
            if (csvDataForSmaregi.entities[0].itemNumber !== undefined) {
              csvHeaders.push("品番");
            }
            if (csvDataForSmaregi.entities[0].appStartDate !== undefined) {
              csvHeaders.push("適応開始日");
            }
            if (csvDataForSmaregi.entities[0].description !== undefined) {
              csvHeaders.push("説明");
            }
            if (csvDataForSmaregi.entities[0].color !== undefined) {
              csvHeaders.push("カラー");
            }
            if (csvDataForSmaregi.entities[0].cost !== undefined) {
              csvHeaders.push("原価");
            }
            if (csvDataForSmaregi.entities[0].size !== undefined) {
              csvHeaders.push("サイズ");
            }
            if (csvDataForSmaregi.entities[0].taxDivision !== undefined) {
              csvHeaders.push("税区分");
            }
            if (csvDataForSmaregi.entities[0].groupCode !== undefined) {
              csvHeaders.push("グループコード");
            }
            if (csvDataForSmaregi.entities[0].purchaseCost !== undefined) {
              csvHeaders.push("仕入原価");
            }
            csvDataForSmaregi.customColumnHeaders.map((x) => csvHeaders.push(`${x}`));
            let csv = csvHeaders.map((x) => `"${x}"`).join(",") + "\n";
            for (const sku of csvDataForSmaregi.entities) {
              let list = [
                this.convertDoubleQuotationForCsv(sku.janCode),
                this.convertDoubleQuotationForCsv(sku.smaregiIntegratedCategoryCode),
                this.convertDoubleQuotationForCsv(sku.name),
                this.convertDoubleQuotationForCsv(sku.productNumberId),
                sku.salesPrice,
                sku.itemNumber !== undefined ? this.convertDoubleQuotationForCsv(sku.itemNumber) : null,
                sku.appStartDate !== undefined ? this.convertDoubleQuotationForCsv(sku.appStartDate) : null,
                sku.description !== undefined ? this.convertDoubleQuotationForCsv(sku.description) : null,
                sku.color !== undefined ? this.convertDoubleQuotationForCsv(sku.color) : null,
                sku.cost !== undefined ? sku.cost : null,
                sku.size !== undefined ? this.convertDoubleQuotationForCsv(sku.size) : null,
                sku.taxDivision !== undefined ? this.convertDoubleQuotationForCsv(sku.taxDivision) : null,
                sku.groupCode !== undefined ? this.convertDoubleQuotationForCsv(sku.groupCode) : null,
                sku.purchaseCost !== undefined ? sku.purchaseCost : null,
              ];
              list = list.filter((item): item is NonNullable<typeof item> => item != null);
              csv += list.map((item) => `"${item}"`).join(",");
              for (const header of csvDataForSmaregi.customColumnHeaders) {
                csv += `,"${this.convertDoubleQuotationForCsv(
                  sku.customColumns.find((item) => item.name === header)?.value
                )}"`;
              }
              csv += "\n";
            }
            this.downloadCsv(csv, "data_sku_smaregi.csv");
          } else {
            this.showAlert(
              `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
          break;
        case "Shopify - csvダウンロード":
          const csvDataForShopify = await RadialApiClient.downloadShopifySkuCsv(this.csvCondition);
          if (csvDataForShopify) {
            if (csvDataForShopify.entities.length > 0) {
              let csvHeaders: string[] = [];
              Constant.skuShopifyCsvHeaders.map((x) => csvHeaders.push(`${x}`));
              if (csvDataForShopify.entities[0].handle !== undefined) {
                csvHeaders.push("Handle");
              }
              if (csvDataForShopify.entities[0].bodyHtml !== undefined) {
                csvHeaders.push("Body HTML");
              }
              if (csvDataForShopify.entities[0].vendor !== undefined) {
                csvHeaders.push("Vendor");
              }
              if (csvDataForShopify.entities[0].tags !== undefined) {
                csvHeaders.push("Tags");
              }
              if (csvDataForShopify.entities[0].option1name !== undefined) {
                csvHeaders.push("Option1 Name");
              }
              if (csvDataForShopify.entities[0].option1value !== undefined) {
                csvHeaders.push("Option1 Value");
              }
              if (csvDataForShopify.entities[0].option2name !== undefined) {
                csvHeaders.push("Option2 Name");
              }
              if (csvDataForShopify.entities[0].option2value !== undefined) {
                csvHeaders.push("Option2 Value");
              }
              if (csvDataForShopify.entities[0].option3name !== undefined) {
                csvHeaders.push("Option3 Name");
              }
              if (csvDataForShopify.entities[0].option3value !== undefined) {
                csvHeaders.push("Option3 Value");
              }
              if (csvDataForShopify.entities[0].productType !== undefined) {
                csvHeaders.push("Product Type");
              }
              if (csvDataForShopify.entities[0].variantSku !== undefined) {
                csvHeaders.push("Variant SKU");
              }
              if (csvDataForShopify.entities[0].variantBarcode !== undefined) {
                csvHeaders.push("Variant Barcode");
              }
              if (csvDataForShopify.entities[0].variantInventoryTracker !== undefined) {
                csvHeaders.push("Variant Inventory Tracker");
              }
              if (csvDataForShopify.entities[0].variantPrice !== undefined) {
                csvHeaders.push("Variant Price");
              }
              if (csvDataForShopify.entities[0].variantCompareAtPrice !== undefined) {
                csvHeaders.push("Variant Compare At Price");
              }
              if (csvDataForShopify.entities[0].variantTaxable !== undefined) {
                csvHeaders.push("Variant Taxable");
              }
              if (csvDataForShopify.entities[0].variantRequiresShipping !== undefined) {
                csvHeaders.push("Variant Requires Shipping");
              }
              let csv = csvHeaders.map((x) => `"${x}"`).join(",");
              if (csvDataForShopify.customColumnHeaders.length !== 0) {
                csv += ",";
              }
              csv += csvDataForShopify.customColumnHeaders.map((x) => `"${x}"`).join(",") + "\n";
              for (const sku of csvDataForShopify.entities) {
                let list = [
                  this.convertDoubleQuotationForCsv(sku.title),
                  sku.handle !== undefined ? this.convertDoubleQuotationForCsv(sku.handle) : null,
                  sku.bodyHtml !== undefined ? this.convertDoubleQuotationForCsv(sku.bodyHtml) : null,
                  sku.vendor !== undefined ? this.convertDoubleQuotationForCsv(sku.vendor) : null,
                  sku.tags !== undefined ? this.convertDoubleQuotationForCsv(sku.tags) : null,
                  sku.option1name !== undefined ? this.convertDoubleQuotationForCsv(sku.option1name) : null,
                  sku.option1value !== undefined ? this.convertDoubleQuotationForCsv(sku.option1value) : null,
                  sku.option2name !== undefined ? this.convertDoubleQuotationForCsv(sku.option2name) : null,
                  sku.option2value !== undefined ? this.convertDoubleQuotationForCsv(sku.option2value) : null,
                  sku.option3name !== undefined ? this.convertDoubleQuotationForCsv(sku.option3name) : null,
                  sku.option3value !== undefined ? this.convertDoubleQuotationForCsv(sku.option3value) : null,
                  sku.productType !== undefined ? this.convertDoubleQuotationForCsv(sku.productType) : null,
                  sku.variantSku !== undefined ? this.convertDoubleQuotationForCsv(sku.variantSku) : null,
                  sku.variantBarcode !== undefined ? this.convertDoubleQuotationForCsv(sku.variantBarcode) : null,
                  sku.variantInventoryTracker !== undefined
                    ? this.convertDoubleQuotationForCsv(sku.variantInventoryTracker)
                    : null,
                  sku.variantPrice !== undefined ? this.convertDoubleQuotationForCsv(sku.variantPrice) : null,
                  sku.variantCompareAtPrice !== undefined
                    ? this.convertDoubleQuotationForCsv(sku.variantCompareAtPrice)
                    : null,
                  sku.variantTaxable !== undefined ? this.convertDoubleQuotationForCsv(sku.variantTaxable) : null,
                  sku.variantRequiresShipping !== undefined
                    ? this.convertDoubleQuotationForCsv(sku.variantRequiresShipping)
                    : null,
                ];
                list = list.filter((item): item is NonNullable<typeof item> => item != null);
                csv += list.map((item) => `"${item}"`).join(",");
                for (const header of csvDataForShopify.customColumnHeaders) {
                  csv += `,"${this.convertDoubleQuotationForShopifyCustomCsv(
                    sku.customColumns.find((item) => item.name === header)
                  )}"`;
                }
                csv += "\n";
              }
              this.downloadCsv(csv, "data_sku_shopify.csv");
            } else {
              this.showAlert("登録されているSKUデータがありません。", "error");
            }
          } else {
            this.showAlert(
              `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
          break;
        case "削除":
          const message = `${this.productSkuNumbers.skuNumber}件のskuを削除しますか？`;
          if (confirm(message)) {
            await RadialApiClient.deleteSkuFromCsvPage(this.csvCondition).catch(() => {
              this.showAlert(
                `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
                "error"
              );
            });
            await this.search();
          }
          break;
        default:
          break;
      }
      // v-selectを再描画させる
      this.showSkuAction = false;
      this.$nextTick(() => (this.showSkuAction = true));
      this.isLoading = false;
    },
    getAttributeTitle(): string {
      if (this.attributesLength === 1) {
        // 選択されている属性が１つだけのとき、その名前を表示
        if (this.csvItemSkuUploadFilters.attribute1.length > 0) {
          return this.csvItemSkuUploadFilters.attribute1[0].name;
        }
        if (this.csvItemSkuUploadFilters.attribute2.length > 0) {
          return this.csvItemSkuUploadFilters.attribute2[0].name;
        }
        if (this.csvItemSkuUploadFilters.attribute3.length > 0) {
          return this.csvItemSkuUploadFilters.attribute3[0].name;
        }
      } else {
        return "属性";
      }
      return "";
    },
    clearAttributesFilter() {
      this.csvItemSkuUploadFilters.attribute1 = [];
      this.csvItemSkuUploadFilters.attribute2 = [];
      this.csvItemSkuUploadFilters.attribute3 = [];
    },
    getTimeStamp(): string {
      return dayjs().format("YYYYMMDDTHHmmss");
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    convertCsv(csv: string, from: Encoding.Encoding, to: Encoding.Encoding): Uint8Array {
      const unicodeList: number[] = [];
      for (let i = 0; i < csv.length; i += 1) {
        unicodeList.push(csv.charCodeAt(i));
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, to, from);
      return new Uint8Array(shiftJisCodeList);
    },
    downloadCsv(csv: string, filename: string) {
      const blob = new Blob([this.convertCsv(csv, "UNICODE", "UTF8")], {
        type: "csv/plain",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    parseAmount(amount: string): number {
      return Number(amount.replaceAll(",", ""));
    },
    async select(event: Event) {
      this.uploading = false;
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      const reader = new FileReader();
      if (target.files && target.files.length > 0) {
        this.file = target.files[0];
        this.csvCheckMessage = { csvType: CsvProductSku.OTHER, errors: [], status: 0 };
        if (this.file.name.split(".").pop() !== "csv") {
          this.showAlert(
            ".csv以外の拡張子のファイルはアップロードを行うことができません。再度ファイルをアップロードしてみてください。",
            "error"
          );
          return;
        }
        reader.onload = async () => {
          if (reader.result instanceof ArrayBuffer) {
            const array = new Uint8Array(reader.result);
            const encoding = Encoding.detect(array);
            if (encoding) {
              await this.loadFile();
            } else {
              this.showAlert(
                `アップロードデータが損傷しているようです。
                再度サンプルデータをダウンロードいただきデータを再作成ください。`,
                "error"
              );
            }
          }
        };
        reader.readAsArrayBuffer(this.file);
      }
      (this.$refs.input as any).value = "";
    },
    async loadFile() {
      this.isCsvChecked = false;
      if (this.file && this.companyAdminUser) {
        // csvをアップロード
        this.isLoading = true;
        const filePath = `item_sku_csv/${this.companyAdminUser.id}/temp/${this.getTimeStamp()}.csv`;
        const res = await Storage.put(filePath, this.file, { contentType: "text/csv" });
        this.csvS3Key = (res as any).key;
        // csvのチェック
        const csvCheckMessage = await RadialApiClient.checkItemSkuCsv(`public/${this.csvS3Key}`);
        if (csvCheckMessage) {
          this.csvCheckMessage = csvCheckMessage;
          // csvのチェックのエラーハンドリング
          if (this.csvCheckMessage.status === 0) {
            // csvチェック成功
            if ((this.csvCheckMessage.createCount ?? 0) + (this.csvCheckMessage.updateCount ?? 0) === 0) {
              this.showAlert(
                `csvに変更がないため、アップロードできません。
                  再度ファイルをご確認ください。`,
                "info"
              );
            } else {
              this.isCsvChecked = true;
            }
          } else if (this.csvCheckMessage.status === 2) {
            this.showAlert(this.csvCheckMessage.errors[0].message, "error");
          } else if (this.csvCheckMessage.status === 3) {
            this.isCsvChecked = true;
          }
        } else {
          this.showAlert(
            `エラーが発生しております。
                時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
        this.isLoading = false;
      }
    },
    async uploadItemSkuCsv() {
      const message =
        `新規: ${this.createCount}件\n` + `更新: ${this.updateCount}件\n` + `\nデータベースを更新しますか？`;
      if (confirm(message)) {
        await RadialApiClient.uploadItemSkuCsv(`public/${this.csvS3Key}`);
        this.uploading = true;
      } else {
        this.showAlert("アップロードをキャンセルしました。", "warning");
      }
    },
    async createCompanyTimer() {
      this.companyTimer = window.setInterval(async () => {
        if (this.user) {
          if (this.isRoot) {
            this.companyAdminUser = await RadialApiClient.getCompany(this.user.sub);
          } else {
            this.companyAdminUser = await RadialApiClient.getAdminUser(this.user.sub);
          }
        }
        if (this.uploading) {
          if (this.companyAdminUser?.itemSkuProgress === 100) {
            if (this.createCount + this.updateCount >= 100) {
              // 多くの新規や更新件数がある場合は「大変おつかれさまでした。」でねぎらってあげる
              this.showAlert(
                `csvのアップロードが完了しました!
                大変おつかれさまでした。10分以内に、商品リストや詳細ページにてご確認いただけます。`,
                "success"
              );
              await this.search();
            } else {
              // 新規や更新件数が少ない場合はシンプルにアップロードが成功しましたモーダルを表示する
              this.showAlert(
                `csvのアップロードが完了しました!
                10分以内に、商品リストや詳細ページにてご確認いただけます。`,
                "success"
              );
              await this.search();
            }
            this.file = null;
            this.uploading = false;
            this.isCsvChecked = false;
            this.csvCheckMessage = { csvType: CsvProductSku.OTHER, errors: [], status: 0 };
          }
        }
      }, 1000);
    },
    getCsvErrorMessage(index: number): string {
      let message = this.csvCheckMessage.errors[index].message;
      for (const rows of this.csvCheckMessage.errors[index].rows) {
        for (const [rowIndex, row] of rows.entries()) {
          if (rowIndex === 0) {
            message = message.concat(`\n・${row}行目`);
          } else {
            message = message.concat(`と${row}行目`);
          }
        }
      }
      return message;
    },
    async clearFilters() {
      this.csvItemSkuUploadFilters.season = [];
      this.csvItemSkuUploadFilters.brand = [];
      this.csvItemSkuUploadFilters.category = [];
      this.clearAttributesFilter();
      await this.search();
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      if (this.isRoot) {
        this.companyAdminUser = await RadialApiClient.getCompany(this.user.sub);
      } else {
        this.companyAdminUser = await RadialApiClient.getAdminUser(this.user.sub);
      }
    }
    await Promise.all([
      (async () => {
        this.brands = (await RadialApiClient.listBrands()) ?? [];
      })(),
      (async () => {
        this.categories = (await RadialApiClient.listCategories()) ?? [];
      })(),
      (async () => {
        this.seasons = (await RadialApiClient.listSeasons()) ?? [];
      })(),
      (async () => {
        this.factories = (await RadialApiClient.listFactories()) ?? [];
      })(),
      (async () => {
        this.attributes = (await RadialApiClient.listAttributes()) ?? [];
      })(),
    ]);
    this.seasons.sort((a, b) => {
      if (a.startYearMonth < b.startYearMonth) {
        return 1;
      } else if (a.startYearMonth === b.startYearMonth) {
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code && b.code === "") {
          return -1;
        } else if (a.code === "" && b.code) {
          return 1;
        } else {
          return a.name > b.name ? -1 : 1;
        }
      } else {
        return -1;
      }
    });
    this.brands.sort((a, b) => {
      if (a.code && b.code) {
        if (a.code > b.code) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      }
    });
    this.categories.sort((a, b) => {
      if (a.code && b.code) {
        if (a.code > b.code) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      }
    });
    this.attributes.sort((a, b) => {
      if (a.code && b.code) {
        if (a.code > b.code) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      }
    });
    await this.search();
    if (this.productSkuNumbers.productNumber === 0) {
      this.sampleCsvUrl.product = String(await Storage.get("item_sku_csv/data_item.csv"));
    }
    if (this.productSkuNumbers.skuNumber === 0) {
      this.sampleCsvUrl.sku = String(await Storage.get("item_sku_csv/data_sku.csv"));
    }
    this.isLoading = false;
    this.createCompanyTimer();
  },
  beforeDestroy() {
    clearInterval(this.companyTimer);
  },
});
