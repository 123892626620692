
import Vue from "vue";
import { GroupListGraphItemEntity } from "@/api/entities";
import { GroupListChartFilter, SearchEntries, SearchEntriesInSkuList } from "@/store/model";
import mixin from "@/mixin/mixin";
import { DigestRateBase } from "@/store/constant";

export interface DataType {
  sortOptions: { name: string; key: string }[];
  sortOptionKeyModel: string | null;
  columnDetailDialog: boolean;
  temporaryDigestRateBase: DigestRateBase;
}

export default Vue.extend({
  name: "group-list-ranking",
  mixins: [mixin],
  components: {},
  data(): DataType {
    return {
      sortOptions: [
        { name: "売上個数", key: "saleQuantity" },
        { name: "売上金額", key: "saleResult" },
        { name: "消化率(全体)", key: "digestionRateAll" },
        { name: "消化率(プロパー)", key: "digestionRateProper" },
      ],
      sortOptionKeyModel: null,
      columnDetailDialog: false,
      temporaryDigestRateBase: "quantity",
    };
  },
  props: {
    sortOptionKey: {
      type: String,
      required: false,
    },
    isAsc: {
      type: Boolean,
      required: true,
    },
    graphData: {
      type: Array as () => GroupListGraphItemEntity[],
      required: true,
    },
    groupBys: {
      type: Array as () => { name: string; key: string }[],
      required: true,
    },
    groupBy: {
      type: String,
      required: true,
    },
    chartFilterData: {
      type: Object as () => GroupListChartFilter,
      required: true,
    },
    chartFilter: {
      type: Object as () => GroupListChartFilter,
      required: true,
    },
    rangeDates: {
      type: Array as () => string[],
      required: true,
    },
    hoverItemId: {
      type: [String, Number],
      required: false,
    },
    dataFormat: {
      type: Number,
      required: true,
    },
    digestRateBase: {
      type: String as () => DigestRateBase,
      required: true,
    },
  },
  watch: {
    sortOptionKeyModel() {
      this.$emit("update:sortOptionKey", this.sortOptionKeyModel);
    },
    columnDetailDialog() {
      if (this.columnDetailDialog) {
        this.temporaryDigestRateBase = this.digestRateBase;
      } else {
        this.$emit("update:digestRateBase", this.temporaryDigestRateBase);
      }
    },
  },
  computed: {
    groupByTitle(): string {
      switch (this.groupBy) {
        case "brand":
          return `${this.graphData.length} Brands`;
        case "season":
          return `${this.graphData.length} Seasons`;
        case "category":
          return `${this.graphData.length} Categories`;
        case "attribute1":
          return `${this.graphData.length} Attributes [1]`;
        case "attribute2":
          return `${this.graphData.length} Attributes [2]`;
        case "attribute3":
          return `${this.graphData.length} Attributes [3]`;
        case "customTag":
          return `${this.graphData.length} Custom Tags`;
        case "store":
          return `${this.graphData.length} Stores`;
      }
      return `${this.graphData.length} Items`;
    },
    filteredGroupBys(): { name: string; key: string }[] {
      return this.groupBys.filter((groupBy) => groupBy.key !== this.groupBy);
    },
    graphDataTotal(): { amount: number; quantity: number } {
      return this.graphData.reduce(
        (previous, current) => {
          return {
            amount: previous.amount + current.total.saleResultAmount,
            quantity: previous.quantity + current.total.saleResultQuantity,
          };
        },
        {
          amount: 0,
          quantity: 0,
        }
      );
    },
  },
  methods: {
    // TODO: isAscはVuetifyの仕様的に実装が難しいため対応しない(呼ばれない)
    clickSortOption(sortOption: { name: string; key: string }) {
      if (this.sortOptionKey === sortOption.key) {
        this.$emit("update:isAsc", !this.isAsc);
      } else {
        this.$emit("update:sortOptionKey", sortOption.key);
        this.$emit("update:isAsc", false);
      }
    },
    getSaleRateOfItem(item: GroupListGraphItemEntity) {
      const rate =
        this.dataFormat === 0
          ? (item.total.saleResultQuantity * 100) / this.graphDataTotal.quantity
          : (item.total.saleResultAmount * 100) / this.graphDataTotal.amount;
      return isNaN(rate) ? 0 : Math.round(rate);
    },
    selectGroupBy(key: string, id: string | number) {
      switch (this.groupBy) {
        case "brand":
          this.chartFilter.brand = this.chartFilterData.brand.filter((brand) => brand.id === id);
          break;
        case "season":
          this.chartFilter.season = this.chartFilterData.season.filter((season) => season.id === id);
          break;
        case "category":
          this.chartFilter.category = this.chartFilterData.category.filter((category) => category.id === id);
          break;
        case "attribute1":
          this.chartFilter.attribute1 = this.chartFilterData.attribute1.filter((attribute) => attribute.id === id);
          break;
        case "attribute2":
          this.chartFilter.attribute2 = this.chartFilterData.attribute2.filter((attribute) => attribute.id === id);
          break;
        case "attribute3":
          this.chartFilter.attribute3 = this.chartFilterData.attribute3.filter((attribute) => attribute.id === id);
          break;
        case "customTag":
          this.chartFilter.customTagMaster = this.chartFilterData.customTagMaster.filter(
            (customTagMaster) => customTagMaster.id === id
          );
          break;
        case "store":
          this.chartFilter.store = this.chartFilterData.store.filter((store) => store.id === id);
          break;
        case "color":
          this.chartFilter.color = this.chartFilterData.color.filter((color) => color.id === id);
          break;
        case "size":
          this.chartFilter.size = this.chartFilterData.size.filter((size) => size.id === id);
          break;
      }
      this.$emit("update:groupBy", key);
      this.$emit("update:chartFilter", this.chartFilter);
    },
    showProductList(item: GroupListGraphItemEntity) {
      const searchEntries: SearchEntries = {
        itemNumber: "",
        brands: [],
        seasons: [],
        categories: [],
        attribute1: [],
        attribute2: [],
        attribute3: [],
        salesStatus: [],
        salesPrice: [null, null],
        stockPrice: [null, null],
        salesCount: [null, null],
        stockCount: [null, null],
        salePrice: [null, null],
        alerts: [],
        customTags: [],
        stores: [],
        statuses: [],
      };
      searchEntries.brands = this.chartFilter.brand.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.seasons = this.chartFilter.season.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.categories = this.chartFilter.category.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.attribute1 = this.chartFilter.attribute1.map((data) => data.id);
      searchEntries.attribute2 = this.chartFilter.attribute2.map((data) => data.id);
      searchEntries.attribute3 = this.chartFilter.attribute3.map((data) => data.id);
      searchEntries.customTags = this.chartFilter.customTagMaster.map((data) => data.id);
      searchEntries.stores = this.chartFilter.store.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      const OTHERS = "others";
      const ids = this.graphData.map((data) => data.id);
      switch (this.groupBy) {
        case "brand":
          if (item.id === OTHERS) {
            searchEntries.brands = this.chartFilterData.brand
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.brands = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "season":
          if (item.id === OTHERS) {
            searchEntries.seasons = this.chartFilterData.season
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.seasons = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "category":
          if (item.id === OTHERS) {
            searchEntries.categories = this.chartFilterData.category
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.categories = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "attribute1":
          if (item.id === OTHERS) {
            searchEntries.attribute1 = this.chartFilterData.attribute1
              .filter((data) => !ids.includes(data.id))
              .map((data) => data.id);
          } else {
            searchEntries.attribute1 = [item.id as string];
          }
          break;
        case "attribute2":
          if (item.id === OTHERS) {
            searchEntries.attribute2 = this.chartFilterData.attribute2
              .filter((data) => !ids.includes(data.id))
              .map((data) => data.id);
          } else {
            searchEntries.attribute2 = [item.id as string];
          }
          break;
        case "attribute3":
          if (item.id === OTHERS) {
            searchEntries.attribute3 = this.chartFilterData.attribute3
              .filter((data) => !ids.includes(data.id))
              .map((data) => data.id);
          } else {
            searchEntries.attribute3 = [item.id as string];
          }
          break;
        case "customTag":
          if (item.id === OTHERS) {
            searchEntries.customTags = this.chartFilterData.customTagMaster
              .filter((data) => !ids.includes(data.id))
              .map((data) => data.id);
          } else {
            searchEntries.customTags = [item.id as number];
          }
          break;
        case "store":
          if (item.id === OTHERS) {
            searchEntries.stores = this.chartFilterData.store
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.stores = [{ id: item.id, name: item.name, code: null }];
          }
          break;
      }
      const search = JSON.stringify(searchEntries);
      const rangeDates = JSON.stringify(this.rangeDates);
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductList" : "ProductList",
        query: { search, rangeDates },
      });
      window.open(routeData.href, "_blank");
    },
    showSkuList(item: GroupListGraphItemEntity) {
      const searchEntries: SearchEntriesInSkuList = {
        brands: [],
        seasons: [],
        categories: [],
        attribute1s: [],
        attribute2s: [],
        attribute3s: [],
        stores: [],
        colors: [],
        sizes: [],
        alertMasters: [],
        statuses: [],
      };
      searchEntries.brands = this.chartFilter.brand.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.seasons = this.chartFilter.season.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.categories = this.chartFilter.category.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.attribute1s = this.chartFilter.attribute1.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.attribute2s = this.chartFilter.attribute2.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.attribute3s = this.chartFilter.attribute3.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.stores = this.chartFilter.store.map((data) => {
        return { id: data.id, name: data.name, code: null };
      });
      searchEntries.colors = this.chartFilter.color.map((data) => {
        return { id: data.id, name: data.name };
      });
      searchEntries.sizes = this.chartFilter.size.map((data) => {
        return { id: data.id, name: data.name };
      });
      const OTHERS = "others";
      const ids = this.graphData.map((data) => data.id);
      switch (this.groupBy) {
        case "brand":
          if (item.id === OTHERS) {
            searchEntries.brands = this.chartFilterData.brand
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.brands = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "season":
          if (item.id === OTHERS) {
            searchEntries.seasons = this.chartFilterData.season
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.seasons = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "category":
          if (item.id === OTHERS) {
            searchEntries.categories = this.chartFilterData.category
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.categories = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "attribute1":
          if (item.id === OTHERS) {
            searchEntries.attribute1s = this.chartFilterData.attribute1
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.attribute1s = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "attribute2":
          if (item.id === OTHERS) {
            searchEntries.attribute2s = this.chartFilterData.attribute2
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.attribute2s = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "attribute3":
          if (item.id === OTHERS) {
            searchEntries.attribute3s = this.chartFilterData.attribute3
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.attribute3s = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "store":
          if (item.id === OTHERS) {
            searchEntries.stores = this.chartFilterData.store
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name, code: null };
              });
          } else {
            searchEntries.stores = [{ id: item.id, name: item.name, code: null }];
          }
          break;
        case "color":
          if (item.id === OTHERS) {
            searchEntries.colors = this.chartFilterData.color
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name };
              });
          } else {
            searchEntries.colors = [{ id: item.id, name: item.name }];
          }
          break;
        case "size":
          if (item.id === OTHERS) {
            searchEntries.sizes = this.chartFilterData.size
              .filter((data) => !ids.includes(data.id))
              .map((data) => {
                return { id: data.id, name: data.name };
              });
          } else {
            searchEntries.sizes = [{ id: item.id, name: item.name }];
          }
          break;
      }
      const search = JSON.stringify(searchEntries);
      const rangeDates = JSON.stringify(this.rangeDates);
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminSkuList" : "SkuList",
        query: { search, rangeDates },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.sortOptionKeyModel = this.sortOptionKey;
  },
});
