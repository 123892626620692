import Highcharts from "highcharts";
import brokenAxis from "highcharts/modules/broken-axis";
brokenAxis(Highcharts);

export const pointBreakEvent = function(e) {
  const point = e.point;
  const brk = e.brk;
  const shapeArgs = point.shapeArgs;
  const x = shapeArgs.x;
  const y = this.translate(brk.from, 0, 1, 0, 1);
  const rx = 7;
  const ry = 5;
  const key = ["brk", brk.from, brk.to];
  const offset = -2;
  // ジグザグ線の場合はこれ↓
  // const path = ['M', x, y, 'L', x + w * 0.25, y + 4, 'L', x + w * 0.75, y - 4, 'L', x + w, y];
  // ベジェ曲線をsvgで描いているが，少し歪なので修正が必要かもしれない↓
  const path = [
    ["M", x + offset, y],
    ["Q", x + offset, y + ry, x + rx + offset, y],
    ["T", x + rx * 1.8 + offset, y]
  ];
  if (!point[key]) {
    point[key] = this.chart.renderer
      .path(path)
      .attr({
        "stroke-width": 2,
        stroke: "#B7C9CB"
      })
      .add(point.graphic.parentGroup);
  } else {
    point[key].attr({
      d: path
    });
  }
};
