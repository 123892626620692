
import Vue from "vue";
import mixin from "@/mixin/mixin";
import { EditingNextEngineIntegration } from "./Integration.vue";

export interface DataType {}

export default Vue.extend({
  name: "IntegrationFutureshop",
  mixins: [mixin],
  components: {},
  data(): DataType {
    return {};
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    newNextEngineIntegration: {
      type: Object as () => EditingNextEngineIntegration,
      required: true,
    },
  },
  computed: {
    dialogNextEngine: {
      get(): boolean {
        return (this as any).value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    integrateNextEngine() {
      this.$emit("integrate", {
        newNextEngineIntegration: this.newNextEngineIntegration,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
});
