
import Vue from "vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  smaregiContractId: string;
}

export default Vue.extend({
  name: "IntegrationSmaregi",
  mixins: [mixin],
  components: {},
  data(): DataType {
    return { smaregiContractId: "" };
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    integrationBargainTypes: {
      type: Object,
      required: true,
    },
    bargainTypes: {
      type: Array as () => Object[],
      required: true,
    },
  },
  computed: {
    dialogSmaregi: {
      get(): boolean {
        return (this as any).value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    integrateSmaregi() {
      this.$emit("integrate", {
        smaregiContractId: (this as any).smaregiContractId,
        integrationBargainTypes: this.integrationBargainTypes,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
});
