
import Vue from "vue";
import { Auth } from "aws-amplify";
import router from "@/router";
import RadialApiClient from "@/api/RadialApiClient";
import { CreateCompanyDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import AuthClient from "@/api/AuthClient";
import mixin from "@/mixin/mixin";
import { AdminUser } from "@/api/entities";

export default Vue.extend({
  name: "UpdatePassword",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      code: "",
      temporaryPassword: "",
      password: "",
      passwordConfirm: "",
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      email: "",
    };
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async setPassword() {
      const email = this.$route.query.email as string | null;
      if (!email) {
        this.showAlert("ご入力のメールアドレスは登録されていないようです！", "warning");
      } else if (this.temporaryPassword === "") {
        this.showAlert("認証用メールに記載の仮パスワードを入力してください。", "warning");
      } else if (this.password === "") {
        this.showAlert("英数字6文字以上のパスワードを入力してください。", "warning");
      } else if (this.password !== this.passwordConfirm) {
        this.showAlert("確認パスワードが正しく入力されていないようです。再度お試しください。", "warning");
      } else {
        this.email = email.replaceAll(" ", "+");
        Auth.signIn(this.email, this.temporaryPassword)
          .then((user) => {
            if (user.challengeParam && user.challengeParam.userAttributes) {
              Auth.completeNewPassword(user, this.password, {
                address: "-",
              })
                .then(async () => {
                  const newUser = await AuthClient.getUserInfo();
                  if (this.isAdmin && newUser && newUser.sub === newUser.companyId) {
                    await this.createCompany();
                  } else if (this.isAdmin) {
                    router.push({ name: "AdminDashboard" });
                  } else {
                    router.push({ name: "Dashboard" });
                  }
                })
                .catch((error) => {
                  if (error.code === "InvalidPasswordException") {
                    this.showAlert("英数字6文字以上のパスワードを入力してください。", "warning");
                  } else {
                    this.showAlert(
                      `エラーが発生しております。
                      もしもう一度お試しいただき解決されなければ、
                      cs@proces.co.jp
                      にご連絡くださいませ。`,
                      "error"
                    );
                  }
                });
            }
          })
          .catch((error) => {
            if (error.code === "UserNotFoundException") {
              this.showAlert(
                `指定されたURLは無効のようです。
                メール本文で改行されURLが壊れている場合がありますので、ご確認ください！`,
                "warning"
              );
            } else if (error.code === "NotAuthorizedException") {
              this.showAlert("認証用メールに記載の仮パスワードを入力してください。", "warning");
            } else {
              this.showAlert(
                `エラーが発生しております。
                もしもう一度お試しいただき解決されなければ、
                cs@proces.co.jp
                にご連絡くださいませ。`,
                "error"
              );
            }
          });
      }
    },
    async createCompany() {
      const email = this.email;
      const password = this.password;
      const user = await Auth.signIn(email, password).catch(() => {
        this.showAlert(
          `エラーが発生しております。
          もしもう一度お試しいただき解決されなければ、
          cs@proces.co.jp
          にご連絡くださいませ。`,
          "error"
        );
      });
      if (user) {
        const newUser = await AuthClient.getUserInfo();
        if (newUser) {
          const company = await RadialApiClient.getCompany(newUser.companyId);
          if (company) {
            await AuthClient.updateCompany(newUser.email, company);
            router.push({ name: "AdminCustomerTouchPoint" });
          } else {
            const dto: CreateCompanyDto = {
              id: newUser.sub,
              name: newUser.name,
              email: newUser.email,
              address: newUser.address,
            };
            await AuthClient.updateCompany(newUser.email, dto);
            const company = await RadialApiClient.createCompany(dto);
            if (company) {
              await this.createCsAdminUser(company.id);
            }
            router.push({ name: "AdminCustomerTouchPoint" });
          }
        } else {
          router.push({ name: "AdminDashboard" });
        }
      }
    },
    async createCsAdminUser(companyId: string) {
      const email = `cs+${RadialApiClient.rawEnv}.${companyId}@proces.co.jp`;
      const password = AuthClient.generatePassword();
      const newAdminUser: AdminUser = {
        id: "",
        email,
        name: "CSロール",
        itemSkuProgress: 0,
        saleStockProgress: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        imageProgress: 0,
        adminUserMasterId: 3,
        companyId,
        icon: null,
      };
      const adminUser = await AuthClient.createAdminUser(companyId, newAdminUser, password);
      await AuthClient.sendEmailToCreateAccount(newAdminUser.email, password);
      const sub = adminUser.User?.Attributes?.find((attribute) => attribute.Name === "sub")?.Value;
      if (sub) {
        await RadialApiClient.createAdminUser({
          id: sub,
          email: newAdminUser.email,
          name: newAdminUser.name,
          company: {
            connect: { id: companyId },
          },
          adminUserMaster: newAdminUser.adminUserMasterId
            ? {
                connect: { id: newAdminUser.adminUserMasterId },
              }
            : undefined,
          icon: "",
        });
      }
    },
  },
  computed: {},
});
