
import Vue from "vue";
import { Chart } from "highcharts-vue";
import { Options, PointOptionsObject, SeriesOptionsType, YAxisBreaksOptions } from "highcharts";
import { pointBreakEvent } from "./BreakAxis";

export default Vue.extend({
  name: "ChartSlider",
  model: {
    prop: "range",
    event: "change",
  },
  props: {
    data: {
      type: Array as () => number[],
      required: true,
    },
    range: {
      type: Array as () => number[],
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      bins: 30, // 何等分のヒストグラムを生成するか
      initFlag: true,
    };
  },
  computed: {
    inputRange: {
      get(): number[] {
        const from = Math.floor((this.range[0] - this.min) / this.size);
        const to = Math.floor((this.range[1] - this.min) / this.size);
        return [from, to];
      },
      set(newValue: number[]) {
        const from = this.min + this.size * newValue[0];
        let to = this.min + this.size * newValue[1];
        if (newValue[1] === this.bins - 1) {
          to = this.max;
          this.initFlag = true;
        }
        // スライダーが一番右のときだけ最大値に設定する
        if (to <= this.max) {
          this.$emit("change", [from, to]);
        }
      },
    },
    min(): number {
      return Math.min(...this.data);
    },
    max(): number {
      return Math.max(...this.data);
    },
    size(): number {
      return Math.ceil((this.max - this.min + 1) / this.bins);
    },
    breaks(): YAxisBreaksOptions[] {
      const sortedData = [...this.data].sort((a, b) => a - b);
      const histogram = new Array(this.bins).fill(0);
      for (const item of sortedData) {
        histogram[Math.floor((item - this.min) / this.size)]++;
      }
      if (this.min <= 0) {
        const len = Math.floor((0 - this.min) / this.size);
        if (histogram.every((item) => item <= histogram[len])) {
          histogram.splice(len, 1);
          const histogramMax = Math.max(...histogram) + 1;
          return [
            {
              from: histogramMax - 0.5,
              to: histogramMax - 0.5,
            },
          ];
        }
      }
      return [];
    },
    histogramData(): PointOptionsObject[] {
      const sortedData = [...this.data].sort((a, b) => a - b);
      const histogram = new Array(this.bins).fill(0);
      for (const item of sortedData) {
        histogram[Math.floor((item - this.min) / this.size)]++;
      }
      if (this.min <= 0) {
        const len = Math.floor((0 - this.min) / this.size);
        if (histogram.every((item) => item <= histogram[len])) {
          histogram.splice(len, 1);
          histogram.splice(len, 0, Math.max(...histogram) + 1);
        }
      }
      const type50: string = (this as any).$vuetify.theme.themes.light.radialAssistantType50;
      const type20: string = (this as any).$vuetify.theme.themes.light.radialAssistantType20;
      const inputRange = [...this.inputRange]; // 即時反映するため
      return histogram.map((h, i) => {
        if (i < inputRange[0] || i > inputRange[1]) {
          return {
            x: i,
            y: h,
            color: type20,
          };
        } else {
          return {
            x: i,
            y: h,
            color: type50,
          };
        }
      });
    },
    series(): SeriesOptionsType[] {
      return [
        {
          name: "",
          type: "column",
          data: this.histogramData,
        },
      ];
    },
    options(): Options {
      return {
        title: undefined,
        subtitle: undefined,
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
          marginLeft: 30,
          marginRight: 20,
          marginTop: 0,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          column: {
            pointWidth: 8,
            shadow: false,
          },
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          visible: false,
          breaks: this.breaks,
          events: {
            pointBreak: pointBreakEvent, // ここでaxis break(数値の省略)が発生したときの描画イベントを設定
          },
        },
        legend: {
          enabled: false,
        },
        series: this.series,
      };
    },
  },
  mounted() {
    // グラフ描画のズレを修正するためのコード
    this.$nextTick(() => {
      (this.$refs.chart as any).chart.reflow();
    });
  },
});
