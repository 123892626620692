
/*
  [ElementRangeDatePicker]
    <必須>
    - v-model = 選択した日付(string型)

    <任意>
    - :format = 日付を表示するフォーマット
    - :type = 選択するデータのタイプ（date / month / year）
    - :min = 選択可能な日付の最小値
    - :max = 選択可能な日付の最大値
    - :calendarIcon = カレンダーアイコンを表示するかどうか
    - :innerClass = v-date-pickerに付与するクラス
    - :prefix = 先頭に付ける文言
    - @input = 日付変更時に呼ばれるイベント
*/
import dayjs from "dayjs";
import Vue from "vue";

// The setting to use element-ui
import DatePicker from "element-ui";
import { DatePickerOptions } from "element-ui/types/date-picker";
Vue.use(DatePicker);

export interface DataType {
  showMenu: boolean;
}

export default Vue.extend({
  name: "element-date-picker",
  props: {
    value: {
      type: [String, Date],
      required: false,
      default: null,
    },
    format: {
      type: String,
      required: false,
      default: "yyyy-MM-dd",
    },
    type: {
      type: String,
      required: false,
      default: "date",
    },
    min: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: String,
      required: false,
      default: null,
    },
    calendarIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    innerClass: {
      type: String,
      required: false,
      default: "",
    },
    prefix: {
      type: String,
      required: false,
      default: null,
    },
  },
  data(): DataType {
    return {
      showMenu: false,
    };
  },
  computed: {
    date: {
      get(): string | null {
        if (this.value) {
          return dayjs(this.value).format("YYYY-MM-DD");
        } else {
          return null;
        }
      },
      set(newValue: string | Date | null) {
        if (newValue) {
          if (typeof this.value === "string") {
            this.$emit("input", dayjs(newValue).format("YYYY-MM-DD"));
          } else {
            this.$emit("input", dayjs(newValue).toDate());
          }
        } else {
          this.$emit("input", null);
        }
      },
    },
    inBetweenDatesPickerOptions(): DatePickerOptions {
      return {
        disabledDate: (time) => {
          if (this.min && this.max) {
            return !dayjs(time.getTime()).isBetween(dayjs(this.min).subtract(1, "day"), dayjs(this.max).add(1, "day"));
          } else if (this.min) {
            return dayjs(time).isBefore(dayjs(this.min).subtract(1, "day").add(1, "day").format("YYYY-MM-DD"));
          } else if (this.max) {
            return dayjs(time).isAfter(dayjs(this.max));
          } else {
            return false;
          }
        },
      };
    },
  },
});
