
import Vue from "vue";
import { LogizardJanCodeRecognitionKey } from "@/api/dto";
import mixin from "@/mixin/mixin";

export interface DataType {
  logizardGroupId: string;
  logizardConnectionId: string;
  logizardUserId: string | null;
  logizardPassword: string;
  logizardItemIds: { str: string; key: LogizardJanCodeRecognitionKey }[];
  logizardItemId: LogizardJanCodeRecognitionKey | null;
  logizardKeys: { str: string; key: LogizardJanCodeRecognitionKey[] }[];
  logizardKey: LogizardJanCodeRecognitionKey[];
  logizardKey1: LogizardJanCodeRecognitionKey | null;
  logizardKey2: LogizardJanCodeRecognitionKey | null;
}

export default Vue.extend({
  name: "IntegrationLogizard",
  mixins: [mixin],
  components: {},
  data(): DataType {
    return {
      logizardGroupId: "",
      logizardConnectionId: "",
      logizardUserId: null,
      logizardPassword: "",
      logizardItemIds: [
        { str: "JANコード", key: "JANCODE" },
        { str: "品番", key: "ITEMNUMBER" },
      ],
      logizardItemId: null,
      logizardKeys: [
        { str: "key1:カラーコード,key2:サイズコード", key: ["COLORCODE", "SIZECODE"] },
        { str: "key1:サイズコード,key2カラーコード", key: ["SIZECODE", "COLORCODE"] },
      ],
      logizardKey: [],
      logizardKey1: "COLORCODE",
      logizardKey2: "SIZECODE",
    };
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    integrationBargainTypes: {
      type: Object,
      required: true,
    },
    bargainTypes: {
      type: Array as () => Object[],
      required: true,
    },
  },
  computed: {
    enableKey(): boolean {
      if (this.logizardItemId === "ITEMNUMBER") {
        return true;
      } else {
        return false;
      }
    },
    dialogLogizard: {
      get(): boolean {
        return (this as any).value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    integrateLogizard() {
      this.$emit("integrate", {
        logizardGroupId: this.logizardGroupId,
        logizardConnectionId: this.logizardConnectionId,
        logizardUserId: this.logizardUserId,
        logizardPassword: this.logizardPassword,
        logizardItemId: this.logizardItemId,
        logizardKey: this.logizardKey,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  watch: {
    logizardItemId: {
      async handler() {
        if (this.logizardItemId === "JANCODE") {
          this.logizardKey = [];
        }
      },
    },
  },
  mounted() {},
});
