
import Vue from "vue";
import dayjs from "dayjs";
import RadialApiClient from "@/api/RadialApiClient";
import { DashboardSeasonEntity, Season } from "@/api/entities";
import { Options } from "highcharts";
import { Chart } from "highcharts-vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  seasonItems: DashboardSeasonEntity[] | null;
  seasonTable: Season[];
  hover: boolean;
  hoverIndex: number | null;
  isLoading: boolean;
}

export default Vue.extend({
  name: "dashboard-season",
  mixins: [mixin],
  components: {
    highcharts: Chart,
  },
  props: {
    isTablet: {
      type: Boolean,
      required: true,
    },
  },
  data(): DataType {
    return {
      seasonItems: [],
      seasonTable: [],
      hover: false,
      hoverIndex: null,
      isLoading: true,
    };
  },
  computed: {},
  methods: {
    getSeasonName(seasonId: number): string {
      return this.seasonTable.find((season) => season.id === seasonId)?.name ?? "non registered season";
    },
    getSeasonRange(seasonId: number): string {
      const season = this.seasonTable.find((season) => season.id === seasonId);
      if (season) {
        const startDay = dayjs(season.startYearMonth).format("YYYY/MM");
        const endDay = dayjs(season.endYearMonth).format("YYYY/MM");
        return `${startDay} ～ ${endDay}`;
      } else {
        return "";
      }
    },
    getOptions(item: DashboardSeasonEntity): Options {
      return {
        title: undefined,
        chart: {
          renderTo: "piechart",
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          width: 90,
          height: 90,
          backgroundColor: "rgba(255, 255, 255, 0.0)",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            size: "100%",
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              `<div style="font-size: 8px">` +
              this.point.name +
              "</div>" +
              " " +
              `<div style="font-size: 10px">` +
              this.y +
              "%" +
              "</div>"
            );
          },
        },
        series: [
          {
            //データ設定
            type: "pie",
            name: "",
            data: [
              { name: "企画中", y: item.statusRate[0], color: "#E5F2F9" },
              { name: "1st Sample", y: item.statusRate[1], color: "#76B3E0" },
              { name: "2nd Sample", y: item.statusRate[2], color: "#5EA3DA" },
              { name: "発注済", y: item.statusRate[3], color: "#4487C8" },
              { name: "企画中止", y: item.statusRate[4], color: "#EF8195" },
            ],
          },
        ],
      };
    },
    existSeason(seasonId: number): boolean {
      const season = this.seasonTable.find((season) => season.id === seasonId);
      if (season) {
        return true;
      } else {
        return false;
      }
    },
    hoverCard(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hover = true;
        this.hoverIndex = index;
      } else if (hoverFlag === 1) {
        this.hover = false;
      }
    },
    showSeasonInNewTab(seasonId: string) {
      const routeData = this.$router.resolve({
        name: "AdminMdMap",
        params: { seasonId },
      });
      window.open(routeData.href, "_blank");
    },
    getSeasonDetail(seasonId: number): Season | null {
      return this.seasonTable.find((item) => item.id === seasonId) ?? null;
    },
  },
  async mounted() {
    this.seasonTable = (await RadialApiClient.listSeasons()) ?? [];
    const dashboardSeasons = (await RadialApiClient.listDashboardSeasons()) ?? [];
    const currentMonth = dayjs();
    this.seasonItems = dashboardSeasons.filter((item) => {
      const season = this.getSeasonDetail(item.seasonId);
      const startYearMonth = dayjs(season?.startYearMonth);
      const endYearMonth = dayjs(season?.endYearMonth);
      return currentMonth.isBefore(startYearMonth) || endYearMonth.isAfter(currentMonth);
    });
    this.isLoading = false;
  },
  watch: {
    isLoading() {
      this.$emit("changeLoadingStatus", this.isLoading);
    },
  },
});
