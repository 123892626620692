
/*
  [Checkbox]
    <必須>
    - v-model = チェックされた名前のリスト
    - :name = チェックボックスの右に表示する名前
    - :data = チェックされたときにv-modelに追加するデータ

    <任意>
    - @change = チェック状態が変更されたときに呼ばれるイベント
*/
import Vue from "vue";
export default Vue.extend({
  name: "checkbox",
  props: {
    value: {
      type: Array as () => any[],
      required: false,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    data: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    model: {
      get(): any[] {
        return this.value;
      },
      set(newValue: any[]) {
        this.$emit("input", newValue);
      },
    },
  },
});
