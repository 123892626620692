
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { CustomTagMaster } from "@/api/entities";
import { CreateCustomTagMasterDto, UpdateCustomTagMasterDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  isLoading: boolean;
  activeItem: string | null;
  selectedIndex: number;
  tags: CustomTagMaster[];
  newTagName: string;
  dialogAddTag: boolean;
  dialogEditTag: boolean;
  dialogDeleteTag: boolean;
  selectedTag: CustomTagMaster | null;
  page: number;
  pageSize: number;
  list: CustomTagMaster[];
  listCount: number;
}

export default Vue.extend({
  name: "CustomTag",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
    Loading,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      isLoading: false,
      activeItem: null,
      selectedIndex: 0,
      tags: [],
      newTagName: "",
      dialogAddTag: false,
      dialogEditTag: false,
      dialogDeleteTag: false,
      selectedTag: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: false,
    },
  },
  watch: {},
  computed: {
    editTagDisabled(): boolean {
      if (this.selectedTag) {
        if (this.selectedTag.name === "") {
          return true;
        } else {
          return this.tags.some((item) => {
            return this.selectedTag?.name === item.name;
          });
        }
      }
      return false;
    },
    addTagDisabled(): boolean {
      if (this.newTagName === "") {
        return true;
      } else {
        return this.tags.some((item) => {
          return this.newTagName === item.name;
        });
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    selectTag(tag: CustomTagMaster, index: number, selectedIndex: number) {
      this.selectedTag = { ...tag };
      if (index === 0) {
        this.selectedIndex = selectedIndex;
        this.dialogEditTag = true;
      } else {
        this.dialogDeleteTag = true;
      }
    },
    deselectTag() {
      this.selectedTag = null;
      this.dialogEditTag = false;
      this.dialogDeleteTag = false;
    },
    sortList() {
      this.list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    async addTag() {
      this.isLoading = true;
      if (this.user) {
        const dto: CreateCustomTagMasterDto = {
          company: { connect: { id: this.user.companyId } },
          name: this.newTagName,
        };
        const tagMaster = await RadialApiClient.createCustomTagMaster(dto);
        if (tagMaster) {
          this.list.push(tagMaster);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("新しくタグが作成されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.newTagName = "";
      this.isLoading = false;
      this.dialogAddTag = false;
    },
    cancelAddtag() {
      this.dialogAddTag = false;
      this.newTagName = "";
    },
    async editTag() {
      if (this.selectedTag) {
        const dto: UpdateCustomTagMasterDto = {
          name: this.selectedTag.name,
        };
        await RadialApiClient.updateCustomTagMaster(this.selectedTag.id, dto);
        const list = await RadialApiClient.listCustomTagMasters();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
        this.selectedTag = null;
        this.dialogEditTag = false;
      }
    },
    async deleteTag() {
      this.dialogDeleteTag = false;
      this.isLoading = true;
      if (this.selectedTag) {
        const deleted = await RadialApiClient.deleteCustomTagMaster(this.selectedTag.id);
        if (deleted) {
          const list = await RadialApiClient.listCustomTagMasters();
          if (list) {
            this.list = list;
            this.listCount = this.list.length;
            this.sortList();
            this.updatePage(this.page);
          }
        }
      }
      this.selectedTag = null;
      this.isLoading = false;
      this.showAlert("無事に削除が完了しました！", "success");
    },
    getAlertMessage(): string {
      if (this.selectedTag) {
        return `本当に削除してもよろしいですか？\n全ての品番から${this.selectedTag.name}タグが削除されます。`;
      } else {
        return "";
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.tags = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.isLoading = true;
    const list = await RadialApiClient.listCustomTagMasters();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.tags = this.list;
      } else {
        this.tags = this.list.slice(0, this.pageSize);
      }
    }
    this.isLoading = false;
  },
});
