
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import RadialApiClient from "@/api/RadialApiClient";
import { AdminUser, AdminUserMaster, Company } from "@/api/entities";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import S3Image from "@/components/aws/S3Image.vue";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import { Storage } from "aws-amplify";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  isLoading: boolean;
  showingAlert: boolean;
  alertType: string;
  alertMessage: string;
  company: Company | null;
  newCompany: Company | null;
  rawAdminUsers: AdminUser[];
  rawAdminUserMasters: AdminUserMaster[];
  selectedAdminUser: AdminUser | null;
  newAdminUser: AdminUser | null;
  user: User | null;
  alertDelete: boolean;
  dialogAdd: boolean;
  dialogEdit: boolean;
  dialogEditCompany: boolean;
  activeId: string | null;
}

export default Vue.extend({
  name: "AdminSetting",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    S3Image,
    Loading,
  },
  data(): DataType {
    return {
      isLoading: false,
      showingAlert: false,
      alertType: "",
      alertMessage: "",
      company: null,
      newCompany: null,
      rawAdminUsers: [],
      rawAdminUserMasters: [],
      selectedAdminUser: null,
      newAdminUser: null,
      user: null,
      alertDelete: false,
      dialogAdd: false,
      dialogEdit: false,
      dialogEditCompany: false,
      activeId: null,
    };
  },
  watch: {
    dialogAdd() {
      if (this.dialogAdd) {
        if (this.company && !this.newAdminUser) {
          this.newAdminUser = {
            id: "",
            email: "",
            name: "",
            itemSkuProgress: 0,
            saleStockProgress: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            imageProgress: 0,
            adminUserMasterId: 1,
            companyId: this.company.id,
            icon: null,
          };
        }
      } else {
        this.newAdminUser = null;
      }
    },
    dialogEdit() {
      if (!this.dialogEdit) {
        this.selectedAdminUser = null;
        this.newAdminUser = null;
      }
    },
    dialogEditCompany() {
      if (this.dialogEditCompany) {
        if (this.company) {
          this.newCompany = { ...this.company };
        }
      } else {
        this.newCompany = null;
      }
    },
    alertDelete() {
      if (!this.alertDelete) {
        this.selectedAdminUser = null;
      }
    },
  },
  computed: {
    isRoot(): boolean {
      return this.user?.sub === this.company?.id;
    },
    userAdminUser(): AdminUser | null {
      return this.rawAdminUsers.find((adminUser) => adminUser.id === this.user?.sub) ?? null;
    },
    userAdminUserMasterId(): number {
      return this.isRoot ? 0 : this.userAdminUser?.adminUserMasterId ?? 10;
    },
    adminUserDescriptions(): string[] {
      if (RadialApiClient.env === "localhost") {
        return [
          "radialの全ての機能をご利用いただけます。",
          "radialの全ての機能をご利用いただけます。",
          "マスタ項目や予算に関する作成及び編集権限を持ちません。",
          "連携機能のみ利用できます。(CS)",
        ];
      } else {
        return [
          "radialの全ての機能をご利用いただけます。",
          "radialの全ての機能をご利用いただけます。",
          "マスタ項目や予算に関する作成及び編集権限を持ちません。",
        ];
      }
    },
    shouldShowCsAdminUser(): boolean {
      return RadialApiClient.env === "localhost" || this.userAdminUserMasterId === 3;
    },
    adminUsers(): AdminUser[] {
      return this.rawAdminUsers
        .filter((adminUser) => (this.shouldShowCsAdminUser ? true : adminUser.adminUserMasterId !== 3))
        .sort((a, b) => {
          if (!a.adminUserMasterId || !b.adminUserMasterId) return 1;
          if (a.adminUserMasterId < b.adminUserMasterId) return -1;
          else if (a.adminUserMasterId > b.adminUserMasterId) return 1;
          else if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          else if (a.email < b.email) return -1;
          else if (a.email > b.email) return 1;
          return 1;
        });
    },
    adminUserMasters(): AdminUserMaster[] {
      return this.rawAdminUserMasters.filter((adminUserMaster) =>
        this.shouldShowCsAdminUser ? true : adminUserMaster.id !== 3
      );
    },
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getAdminUserMasterName(adminUserMasterId: number): string {
      return this.adminUserMasters.find((adminUserMaster) => adminUserMaster.id === adminUserMasterId)?.name ?? "";
    },
    checkCreateAdminUser() {
      if (this.company?.maxAdminNumber && this.company.maxAdminNumber <= this.adminUsers.length + 1) {
        this.showAlert(
          `現在ご利用いただいているプランでは、これ以上Adminユーザー数を増やすことができないようです。\nアップデート等を希望される場合は、弊社の営業担当者もしくはcs@proces.co.jpまでお問い合わせください。`,
          "question"
        );
      } else {
        this.dialogAdd = true;
      }
    },
    async createAdminUser() {
      this.isLoading = true;
      try {
        if (this.company && this.newAdminUser) {
          const password = AuthClient.generatePassword();
          const adminUser = await AuthClient.createAdminUser(this.company.id, this.newAdminUser, password);
          await AuthClient.sendEmailToCreateAccount(this.newAdminUser.email, password);
          const sub = adminUser.User?.Attributes?.find((attribute) => attribute.Name === "sub")?.Value;
          if (sub) {
            await RadialApiClient.createAdminUser({
              id: sub,
              email: this.newAdminUser.email,
              name: this.newAdminUser.name,
              company: {
                connect: { id: this.company.id },
              },
              adminUserMaster: this.newAdminUser.adminUserMasterId
                ? {
                    connect: { id: this.newAdminUser.adminUserMasterId },
                  }
                : undefined,
              icon: "",
            });
            await this.reloadAdminUsers();
          }
        }
      } catch (error) {
        if ((error as any).code === "UsernameExistsException") {
          this.showAlert("このメールアドレスはすでに登録されております。", "warning");
        }
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.dialogAdd = false;
      this.showAlert("アカウントの仮作成が完了しました。メールボックスをご確認ください。", "success");
    },
    async select(event: Event) {
      this.isLoading = true;
      if (event.target) {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
          const file = target.files[0];
          if (this.newCompany) {
            const s3Key = `company_icon/${this.newCompany.id}/${file.name}`;
            await Storage.put(s3Key, file, {
              contentType: file.name.split(".").pop(),
            });
            this.newCompany.icon = s3Key;
          } else if (this.newAdminUser) {
            const s3Key = `adminUser_icon/${this.newAdminUser.id}/${file.name}`;
            await Storage.put(s3Key, file, {
              contentType: file.name.split(".").pop(),
            });
            this.newAdminUser.icon = s3Key;
          }
        }
      }
      this.isLoading = false;
    },
    async reloadAdminUsers() {
      this.rawAdminUsers = (await RadialApiClient.listAdminUsers()) ?? [];
    },
    async updateAdminUser() {
      this.isLoading = true;
      try {
        if (this.selectedAdminUser && this.newAdminUser) {
          await AuthClient.updateAdminUser(this.selectedAdminUser, this.newAdminUser);
          await RadialApiClient.updateAdminUser(this.newAdminUser.id, {
            email: this.newAdminUser.email,
            name: this.newAdminUser.name,
            adminUserMaster: this.newAdminUser.adminUserMasterId
              ? {
                  connect: { id: this.newAdminUser.adminUserMasterId },
                }
              : undefined,
            icon: this.newAdminUser.icon,
          });
        }
        await AuthClient.getUserInfo();
        await AuthClient.checkAuth(true);
        await this.reloadAdminUsers();
      } catch {
        this.showAlert("このメールアドレスはすでに登録されております。", "warning");
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.dialogEdit = false;
      this.showAlert("更新が完了しました！", "success");
    },
    async updateCompany() {
      this.isLoading = true;
      try {
        if (this.company && this.newCompany) {
          await AuthClient.updateCompany(this.company.email, this.newCompany);
          await RadialApiClient.updateCompany(this.company.id, {
            email: this.newCompany.email,
            name: this.newCompany.name,
            icon: this.newCompany.icon,
            address: this.newCompany.address,
          });
          await AuthClient.getUserInfo();
          await AuthClient.checkAuth(true);
          this.company = { ...this.newCompany };
        }
      } catch {
        this.showAlert("このメールアドレスはすでに登録されております。", "warning");
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.dialogEditCompany = false;
      this.showAlert("更新が完了しました！", "success");
    },
    async deleteAdminUser() {
      this.isLoading = true;
      if (this.selectedAdminUser) {
        await AuthClient.deleteAccount(this.selectedAdminUser.email);
        await RadialApiClient.deleteAdminUser(this.selectedAdminUser.id);
        await this.reloadAdminUsers();
        this.isLoading = false;
        this.alertDelete = false;
        this.showAlert("更新が完了しました！", "success");
      }
      this.isLoading = false;
      this.alertDelete = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      this.company = await RadialApiClient.getCompany(this.user.companyId);
    }
    await this.reloadAdminUsers();
    this.rawAdminUserMasters = (await RadialApiClient.listAdminUserMasters()) ?? [];
    this.isLoading = false;
  },
});
