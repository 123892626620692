
import Vue from "vue";
import { Auth } from "aws-amplify";
import router from "@/router";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import mixin from "@/mixin/mixin";

export default Vue.extend({
  name: "ResetPasswordDone",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      code: "",
      password: "",
      passwordConfirm: "",
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
    };
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    confirmResetPassword() {
      const email = this.$route.query.email as string | null;
      if (!email) {
        this.showAlert(
          `メールアドレスの確認が完了していないようです。
          メールアドレス認証用メールをご確認ください。`,
          "warning"
        );
      } else if (this.code === "") {
        this.showAlert("受信ボックスの認証メールに記載された確認コードをご入力くださいませ。", "warning");
      } else if (this.password === "") {
        this.showAlert("英数字6文字以上のパスワードを入力してください。", "warning");
      } else if (this.password !== this.passwordConfirm) {
        this.showAlert("確認パスワードが正しく入力されていないようです。再度お試しください。", "warning");
      } else {
        Auth.forgotPasswordSubmit(email, this.code, this.password)
          .then(() => {
            this.showAlert("パスワードの再設定に成功しました！", "success");
            router.push({ name: this.isAdmin ? "AdminLogin" : "Login" });
          })
          .catch(() => {
            this.showAlert(
              `エラーが発生しております。
              もしもう一度お試しいただき解決されなければ、
              cs@proces.co.jp
              にご連絡くださいませ。`,
              "error"
            );
          });
      }
    },
  },
  computed: {},
});
