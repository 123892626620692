
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { ItemNumberLogicCodeItem, User } from "@/store/model";
import loading from "@/components/vuetify/Loading.vue";
import { ItemNumberLogic } from "@/api/entities";
import { CreateItemNumberLogicDto, UpdateItemNumberLogicCustomDto, UpdateItemNumberLogicDto } from "@/api/dto";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  itemNumberLogic: ItemNumberLogic | null;
  logicItems: ItemNumberLogicCodeItem[];
  logicCodes: ItemNumberLogicCodeItem[];
}

export default Vue.extend({
  name: "ItemNumber",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    loading,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      itemNumberLogic: null,
      logicItems: Constant.itemNumberLogicCodeItems,
      logicCodes: [],
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {
    isUpdateButtonDisabled(): boolean {
      if (this.logicCodes.some((item) => !item.value)) {
        // コードが選択されてないものがある場合
        return true;
      }
      if (this.isIncludeDuplicatedItem(this.logicCodes)) {
        // 重複したコードを選択している場合
        return true;
      }
      if (this.itemNumberLogic) {
        if (JSON.stringify(this.formatToArray(this.itemNumberLogic.format)) !== JSON.stringify(this.logicCodes)) {
          // もともとのコード順から変更がない場合
          return false;
        }
      } else {
        // なにもitemNumberLogicが登録されてないとき
        if (this.logicCodes.length > 0) {
          if (this.logicCodes.some((item) => !item.value)) {
            // コードが選択されてないものがある場合
            return true;
          }
          if (this.isIncludeDuplicatedItem(this.logicCodes)) {
            // 重複したコードを選択している場合
            return true;
          }
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    cancelUpdateItemNumberLogic() {
      if (this.itemNumberLogic) {
        this.logicCodes = this.formatToArray(this.itemNumberLogic.format);
      }
    },
    async updateItemNumberLogic(updateButtonDidClick: boolean) {
      this.$emit("isLoading", true);
      if (this.itemNumberLogic) {
        // 更新
        const dto: UpdateItemNumberLogicCustomDto = {
          isEnabled: this.itemNumberLogic.isEnabled,
          format: updateButtonDidClick ? this.arrayToFormat(this.logicCodes) : this.itemNumberLogic.format, // 更新ボタンのときコード順を変更
        };
        const itemNumberLogic = await RadialApiClient.updateItemNumberLogic(dto);
        if (itemNumberLogic) {
          this.itemNumberLogic = itemNumberLogic;
          if (updateButtonDidClick) {
            this.logicCodes = this.formatToArray(itemNumberLogic.format);
            this.showAlert("無事に更新が完了しました！", "success");
          }
        } else {
          if (this.itemNumberLogic && !updateButtonDidClick) {
            // errorのとき、スイッチをもとに戻す
            this.itemNumberLogic.isEnabled = !this.itemNumberLogic.isEnabled;
          }
          this.showAlert(
            `エラーが発生しております。
              時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      } else {
        // 作成
        const dto: CreateItemNumberLogicDto = {
          format: this.arrayToFormat(this.logicCodes), // 更新ボタンのときコード順を変更
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
        };
        const itemNumberLogic = await RadialApiClient.createItemNumberLogic(dto);
        if (itemNumberLogic) {
          this.itemNumberLogic = itemNumberLogic;
          this.logicCodes = this.formatToArray(itemNumberLogic.format);
          this.showAlert("無事に更新が完了しました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
              時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.$emit("isLoading", false);
    },
    removeCode(index: number) {
      this.logicCodes.splice(index, 1);
    },
    addCode() {
      this.logicCodes.push({
        value: "",
        text: "",
      });
    },
    formatToArray(format: string): ItemNumberLogicCodeItem[] {
      const array: ItemNumberLogicCodeItem[] = [];
      const codeValues = format.match(/\$\{.*?\}/g); // ${}で囲まれた部分を1つの要素とした配列に変換
      codeValues?.forEach((value) => {
        const logicItem = this.logicItems.find((item) => item.value === value);
        if (logicItem) {
          array.push(logicItem);
        }
      });
      return array;
    },
    arrayToFormat(array: ItemNumberLogicCodeItem[]): string {
      let string: string = "";
      array.forEach((item) => {
        string = string + item.value;
      });
      return string;
    },
    isIncludeDuplicatedItem(array: ItemNumberLogicCodeItem[]): boolean {
      const set = new Set(array);
      return set.size !== array.length;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    await RadialApiClient.getItemNumberLogic().then((result) => {
      this.itemNumberLogic = result;
      if (this.itemNumberLogic?.format) {
        this.logicCodes = this.formatToArray(this.itemNumberLogic.format);
      }
    });
    this.$emit("isLoading", false);
  },
});
