
import { User } from "@/store/model";
import Vue from "vue";
import LocalDataService from "@/store/LocalDataService";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";
import { AdminUser, Color, GetDetailImagesEntity, Product, s3KeyWithId, Sku } from "@/api/entities";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { CreateProductImageDto } from "@/api/dto";
import store from "@/store";
import { Storage } from "aws-amplify";

export interface DataType {
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  isLoading: boolean;
  selectedProductImages: string[];
  productDetailImages: GetDetailImagesEntity;
  clickedImage: string | null;
  showingAddImage: boolean;
  imageFile: File | null;
  showDeleteImageDialog: boolean;
  isUploadImageMain: boolean;
  uploadImageColorId: string;
  skuColorIds: string[];
}

export default Vue.extend({
  name: "MdMapProductImage",
  mixins: [mixin],
  components: {
    S3Image,
    Loading,
    ConfirmationDialog,
  },
  props: {
    selectedProduct: {
      type: Object as () => Product,
      default: null,
    },
    selectedSkus: {
      type: Array as () => Sku[],
      required: true,
    },
    colors: {
      type: Array as () => Color[],
      required: true,
    },
  },
  data(): DataType {
    return {
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      isLoading: false,
      selectedProductImages: [],
      productDetailImages: { s3KeyMain: "", s3KeySkus: [] },
      clickedImage: null,
      showingAddImage: false,
      imageFile: null,
      showDeleteImageDialog: false,
      isUploadImageMain: false,
      uploadImageColorId: "",
      skuColorIds: [],
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
    isImageUploadButtonDisable(): boolean {
      if (this.isUploadImageMain) {
        return false;
      }
      return this.selectedSkus.length === 0;
    },
    imagePairs(): (string | null)[][] {
      // 品番画像を2行で表示するための縦のペア
      const pairs: (string | null)[][] = [];
      if (this.selectedProductImages.length > 10) {
        const halfIndex: number = Math.ceil(this.selectedProductImages.length / 2);
        const firstHalfImages = this.selectedProductImages.slice(0, halfIndex);
        const latterHalfImages = this.selectedProductImages.slice(halfIndex);
        firstHalfImages.forEach((image, index) => {
          pairs[index] = [image, null];
        });
        latterHalfImages.forEach((image, index) => {
          pairs[index][1] = image;
        });
      } else {
        const firstHalfImages = this.selectedProductImages.slice(0, 5);
        const latterHalfImages = this.selectedProductImages.slice(5);
        firstHalfImages.forEach((image, index) => {
          pairs[index] = [image, null];
        });
        latterHalfImages.forEach((image, index) => {
          pairs[index][1] = image;
        });
      }
      return pairs;
    },
  },
  methods: {
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    openImageUploadPage() {
      if (this.selectedProduct) {
        const routeData = this.$router.resolve({
          name: "AdminImageDataUpload",
        });
        window.open(routeData.href, "_blank");
      }
    },
    getImageUploadModalHeight(): string {
      let width = 0;
      if (this.selectedProduct) {
        width = this.skuColorIds.reduce((sumResult, colorId) => {
          const textWidth = this.getTextWidth(this.getColorName(colorId) ?? ""); // カラーの文字列の幅のピクセル数を計算
          return sumResult + textWidth + 35; // radioボタンの幅のピクセル数を計算
        }, 0);
      }
      return `height: ${Math.ceil(width / 371) * 24 + 44}px`;
    },
    getColorName(id: string): string {
      return this.colors.find((item) => item.id === id)?.name ?? "";
    },
    getTextWidth(text: string): number {
      const span = document.createElement("span");
      span.style.position = "absolute";
      span.style.top = "-1000px";
      span.style.left = "-1000px";
      span.style.whiteSpace = "nowrap";
      span.innerHTML = text;
      span.style.font = "Noto Sans Japanese";
      span.style.fontSize = "12px";
      span.style.lineHeight = "20px";
      span.style.fontWeight = "400";
      document.body.appendChild(span);
      const width = span.clientWidth;
      (span as any).parentElement.removeChild(span);
      return width;
    },
    selectImageFile(event: Event) {
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      // const reader = new FileReader();
      if (target.files && target.files.length > 0) {
        this.imageFile = target.files[0];
        this.showingAddImage = true;
      }
    },
    async reloadSelectedImage() {
      // 選んでいる品番の画像を取得する
      if (this.selectedProduct) {
        this.selectedProductImages = [];
        const productDetailImages = await RadialApiClient.getProductDetailImages(this.selectedProduct.id);
        if (productDetailImages) {
          this.productDetailImages = productDetailImages;
          const s3KeySkusWithId = productDetailImages.s3KeySkus.map((item) => item.s3KeyWithId).flat(1);
          if (s3KeySkusWithId.length > 0) {
            // SKU画像がある場合
            this.selectedProductImages = Array.from(
              new Set(
                [
                  productDetailImages.s3KeyMain,
                  ...productDetailImages.s3KeySkus
                    .map((item) => item.s3KeyWithId)
                    .flat(1)
                    .map((s3KeyWithId) => s3KeyWithId.s3Key),
                ].map((s3Key) => (s3Key === "" ? "" : s3Key))
              )
            );
          } else {
            // 品番メイン画像のみの場合
            this.selectedProductImages = [this.productDetailImages.s3KeyMain];
          }
        } else {
          this.selectedProductImages = Array.from(
            new Set([this.productDetailImages.s3KeyMain].map((s3Key) => (s3Key === "" ? "" : s3Key)))
          );
        }
        this.clickedImage = null;
        this.clickedImage = this.selectedProductImages[0];
      }
    },
    async uploadImage() {
      if (this.user && this.imageFile && this.selectedProduct) {
        this.isLoading = true;

        // upload image file to s3
        const fileName = this.imageFile.name.split(".").slice(0, -1).join(".") || this.imageFile.name;
        const extension = this.imageFile.name.substring(this.imageFile.name.lastIndexOf(".") + 1) ?? "";
        await Storage.put(`image/${this.user.companyId}/${fileName}`, this.imageFile, {
          contentType: `image/${extension}`,
        });

        // radial APIを叩く
        const dto: CreateProductImageDto = this.isUploadImageMain
          ? {
              index: this.isUploadImageMain ? 0 : 1,
              fileName,
              company: { connect: { id: this.user.companyId } },
              product: { connect: { id: this.selectedProduct.id } },
            }
          : {
              index: this.isUploadImageMain ? 0 : 1,
              fileName,
              color: this.isUploadImageMain ? null : { connect: { id: this.uploadImageColorId } },
              company: { connect: { id: this.user.companyId } },
              product: { connect: { id: this.selectedProduct.id } },
            };
        await RadialApiClient.createProductImage(dto)
          .then(async () => {
            // reload selected product image page
            await this.reloadSelectedImage();
          })
          .catch(() => {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
          });

        // アップロード成功モーダル
        this.showAlert("画像のアップロードが完了しました！", "success", -1);
        this.showingAddImage = false;
        this.isLoading = false;
      }
    },
    async deleteImage() {
      this.isLoading = true;
      const s3KeyWithIds: s3KeyWithId[] = this.productDetailImages.s3KeySkus
        .map((s3KeySku) => s3KeySku.s3KeyWithId)
        .flat(1);
      const deleteS3KeyWithId = s3KeyWithIds.find((s3KeyWithId) => s3KeyWithId.s3Key === this.clickedImage);
      if (!deleteS3KeyWithId) {
        // メイン画像を削除
        if (this.selectedProduct) {
          const productMainImages = await RadialApiClient.getProductMainImages({
            productIds: [this.selectedProduct.id],
          });
          if (productMainImages) {
            await Storage.remove(productMainImages.s3KeyProducts[0].s3Key);
            await RadialApiClient.deleteProductImage(productMainImages.s3KeyProducts[0].productImageId);
          }
        }
      } else {
        // sku画像を削除
        await Storage.remove(deleteS3KeyWithId.s3Key);
        await RadialApiClient.deleteProductImage(deleteS3KeyWithId.productImageId);
      }
      await this.reloadSelectedImage();
      this.isLoading = false;
      this.showDeleteImageDialog = false;
    },
  },
  async mounted() {
    // get product detail images
    const productDetailImages = await RadialApiClient.getProductDetailImages(this.selectedProduct.id);
    if (productDetailImages) {
      this.productDetailImages = productDetailImages;
      this.selectedProductImages = Array.from(
        new Set(
          [
            productDetailImages.s3KeyMain,
            ...productDetailImages.s3KeySkus
              .map((item) => item.s3KeyWithId)
              .flat(1)
              .map((s3KeyWithId) => s3KeyWithId.s3Key),
          ].map((s3Key) => (s3Key === "" ? "" : s3Key))
        )
      );
      this.clickedImage = this.selectedProductImages[0];
    }
    this.skuColorIds = Array.from(
      new Set(
        this.selectedSkus.map((sku) => {
          return sku.colorId;
        })
      )
    );
  },
});
