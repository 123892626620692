
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import GroupListChart from "@/views/group-list/GroupListChart.vue";
import GroupListRanking from "@/views/group-list/GroupListRanking.vue";
import { GroupListChartFilter } from "@/store/model";
import dayjs from "dayjs";
import RadialApiClient from "@/api/RadialApiClient";
import { GroupListGraphItemEntity } from "@/api/entities";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";
import { DigestRateBase } from "@/store/constant";
import store from "@/store";

const emptyChartFilter: GroupListChartFilter = {
  brand: [],
  season: [],
  category: [],
  attribute1: [],
  attribute2: [],
  attribute3: [],
  customTagMaster: [],
  store: [],
  color: [],
  size: [],
};

export interface DataType {
  rangeDates: string[];
  rangeDatesMenu: boolean;
  chartFilter: GroupListChartFilter;
  chartFilterData: GroupListChartFilter;
  groupBy: string;
  groupBys: { name: string; key: keyof GroupListChartFilter }[];
  filters: { name: string; key: keyof GroupListChartFilter }[];
  graphData: GroupListGraphItemEntity[];
  sortOptionKey: string | null;
  isAsc: boolean;
  hoverItemId: string | number | null;
  dataFormat: number;
  isLoading: boolean;
  dateRange: number;
  digestRateBase: DigestRateBase;
}

export default Vue.extend({
  name: "GroupList",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ElementRangeDatePicker,
    FilterChipButton,
    GroupListChart,
    GroupListRanking,
    Loading,
  },
  data(): DataType {
    return {
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      rangeDatesMenu: false,
      chartFilter: { ...emptyChartFilter },
      chartFilterData: { ...emptyChartFilter },
      groupBy: "brand",
      groupBys: [
        { name: "ブランド", key: "brand" },
        { name: "シーズン", key: "season" },
        { name: "アイテムカテゴリ", key: "category" },
        { name: "属性1", key: "attribute1" },
        { name: "属性2", key: "attribute2" },
        { name: "属性3", key: "attribute3" },
        // { name: "カスタムタグ", key: "customTag" },
        { name: "店舗", key: "store" },
        { name: "カラー", key: "color" },
        { name: "サイズ", key: "size" },
      ],
      filters: [
        { name: "ブランド", key: "brand" },
        { name: "シーズン", key: "season" },
        { name: "アイテムカテゴリ", key: "category" },
        { name: "属性", key: "attribute1" },
        // { name: "カスタムタグ", key: "customTagMaster" },
        { name: "店舗", key: "store" },
        { name: "カラー", key: "color" },
        { name: "サイズ", key: "size" },
      ],
      graphData: [],
      sortOptionKey: "saleQuantity",
      isAsc: false,
      hoverItemId: null,
      dataFormat: 0,
      isLoading: false,
      dateRange: 1,
      digestRateBase: "quantity",
    };
  },
  watch: {
    sortOptionKey() {
      this.updateGraph();
    },
    groupBy() {
      this.updateGraph();
    },
    digestRateBase() {
      this.updateGraph();
      store.commit({
        type: "setDigestRateBase",
        digestRateBase: this.digestRateBase,
      });
    },
  },
  computed: {
    minDate(): string {
      return dayjs().subtract(3, "year").format("YYYY-MM-DD");
    },
    attributeTabTitle(): string {
      if (this.attributesLength === 1) {
        if (this.chartFilter.attribute1.length > 0) {
          return this.chartFilter.attribute1[0].name;
        } else if (this.chartFilter.attribute2.length > 0) {
          return this.chartFilter.attribute2[0].name;
        } else if (this.chartFilter.attribute3.length > 0) {
          return this.chartFilter.attribute3[0].name;
        }
      }
      return "属性";
    },
    attributesLength(): number {
      return (
        this.chartFilter.attribute1.length + this.chartFilter.attribute2.length + this.chartFilter.attribute3.length
      );
    },
    availableAttributesLength(): number {
      return (
        this.chartFilterData.attribute1.length +
        this.chartFilterData.attribute2.length +
        this.chartFilterData.attribute3.length
      );
    },
    dateRangeError(): boolean {
      if (dayjs(this.rangeDates[1]).diff(dayjs(this.rangeDates[0]), "year") >= 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async updateGraph() {
      if (this.dateRangeError) {
        return;
      }
      this.isLoading = true;
      this.rangeDatesMenu = false;
      this.graphData =
        (await RadialApiClient.getGroupListGraph(this.groupBy, {
          start: dayjs(this.rangeDates[0]).toDate(),
          end: dayjs(this.rangeDates[1]).toDate(),
          brands: this.chartFilter.brand.map((val) => val.id),
          seasons: this.chartFilter.season.map((val) => val.id),
          categories: this.chartFilter.category.map((val) => val.id),
          attributes1: this.chartFilter.attribute1.map((val) => val.id),
          attributes2: this.chartFilter.attribute2.map((val) => val.id),
          attributes3: this.chartFilter.attribute3.map((val) => val.id),
          customTags: this.chartFilter.customTagMaster.map((val) => val.id),
          stores: this.chartFilter.store.map((val) => val.id),
          colors: this.chartFilter.color.map((val) => val.id),
          sizes: this.chartFilter.size.map((val) => val.id),
          orderBy: this.sortOptionKey ?? undefined,
          order: this.isAsc ? "asc" : "desc",
          digestRate: this.digestRateBase,
        })) ?? [];
      this.dateRange = dayjs(this.rangeDates[1]).diff(this.rangeDates[0], "day") + 1;
      this.isLoading = false;
    },
    clearFilter() {
      this.chartFilter = { ...emptyChartFilter };
      this.updateGraph();
    },
    clearAttributeFilter() {
      this.chartFilter.attribute1 = [];
      this.chartFilter.attribute2 = [];
      this.chartFilter.attribute3 = [];
    },
  },
  async mounted() {
    this.chartFilterData.brand = (await RadialApiClient.listBrands()) ?? [];
    this.chartFilterData.season = (await RadialApiClient.listSeasons()) ?? [];
    this.chartFilterData.season.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    this.chartFilterData.category = (await RadialApiClient.listCategories()) ?? [];
    const attributes = (await RadialApiClient.listAttributes()) ?? [];
    this.chartFilterData.attribute1 = attributes.filter((attribute) => attribute.number === 1);
    this.chartFilterData.attribute2 = attributes.filter((attribute) => attribute.number === 2);
    this.chartFilterData.attribute3 = attributes.filter((attribute) => attribute.number === 3);
    this.chartFilterData.customTagMaster = (await RadialApiClient.listCustomTagMasters()) ?? [];
    this.digestRateBase = store.state.digestRateBase;
    const stores = (await RadialApiClient.listStores()) ?? [];
    this.chartFilterData.store = stores.filter((store) => store.operating);
    this.chartFilterData.color = (await RadialApiClient.listColors()) ?? [];
    this.chartFilterData.size = (await RadialApiClient.listSizes()) ?? [];

    this.updateGraph();

    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/group-list",
        page_title: "Adminグループリスト画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/group-list",
        page_title: "Staffグループリスト画面",
      });
    }
  },
});
