
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import { SearchEntries, RangeDatesTemplate, AlertDetail, ProductListTableData, AdminUserDetail } from "@/store/model";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import LocalDataService from "@/store/LocalDataService";
import dayjs from "dayjs";
import ChartSlider from "@/components/charts/ChartSlider.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import {
  Product,
  ProductListInputEntity,
  ProductFilterInfoEntity,
  ProductListDataEntity,
  ProductSalesStockInfoEntity,
  Attribute,
  AlertMaster,
  FilterInfoDataEntity,
  ProductListEntity,
  CustomTagMaster,
  GetMainImagesEntity,
  AdminUser,
  CustomActionMaster,
  ProductListActionAddProductsDto,
  AlertWithMaster,
  CustomTagInfo,
  Action,
  ActionData,
  ProductStatus,
  // TODO: サーバー側対応完了後にコメントアウト外す
  // CustomTag
} from "@/api/entities";
import {
  ActionType,
  CreateActionDto,
  CreateCustomActionMasterDto,
  CreateCustomTagMasterDto,
  CustomTagsToProductsDto,
} from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import AuthClient from "@/api/AuthClient";
import { Constant } from "@/store/constant";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";

export interface DataType {
  currentIndex: number | null;
  productTableDatas: ProductListTableData[];
  productFilterInput: ProductFilterInfoEntity;
  productFilterStatusInput: { id: number; name: string; code: ProductStatus }[]; // ステータスのフィルタ一覧
  productFilterStoreInput: FilterInfoDataEntity[]; // 集計対象外を「集計対象外」としてまとめた店舗一覧
  statuses: string[];
  dialogDelete: boolean;
  isLoading: boolean;
  x: number;
  y: number;
  searchEntries: SearchEntries;
  savedSearchEntries: SearchEntries; // フィルター条件が変更されたかどうか判断する
  selectedItems: ProductListTableData[];
  actionOption: string;
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  isTableLoading: boolean;
  today: Date;
  alertDatas: AlertMaster[];
  options: {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
  };
  isDeleting: boolean;
  filterDialog: boolean;
  rangeDates: string[];
  salesFormat: number;
  stockFormat: number;
  tableFormat: number;
  rangeDatesMenu: boolean;
  dialogShow: boolean;
  allProductCount: number;
  cancelDates: string[];
  isCanceled: boolean;
  attributes: Attribute[];
  productSalesStockInfo: ProductSalesStockInfoEntity; // 売上在庫フィルターで表示させる売上金額、売上点数、在庫金額、在庫点数のリスト
  productSalePriceInfo: number[]; // フィルター追加で表示させる販売価格のリスト
  filterSalesStockDialog: boolean;
  salesStockDialogShow: boolean;
  showingAddCustomTagDialog: boolean;
  inputCustomTagName: string;
  customTagMasters: CustomTagMaster[];
  selectedCustomTagMaster: CustomTagMaster[];
  isFilteringInSalesStock: boolean;
  isFilteringInSalePrice: boolean;
  isMounting: boolean;
  productImages: GetMainImagesEntity;
  isTablet: boolean;
  isLandScape: boolean;
  showingCreateActionDialog: boolean;
  createActionDialogPageNumber: number; // 0: リストタイプの選択, 1: リスト情報, 2: カスタムアクション
  selectedActionType: ActionType;
  addActionListInfo: {
    title: string | null;
    offRatio: string | null;
    managerId: string | null;
    executedDate: Date | null;
  };
  adminUserDetails: AdminUserDetail[];
  customActionMasters: CustomActionMaster[];
  selectedCustomActionMaster: CustomActionMaster | null;
  inputCustomActionName: string;
  createdActionId: string | null;
  showingAddProductIntoActionDialog: boolean;
  addProductIntoActionDialogPageNumber: number; // 0: アクションタイプの選択, 1: アクション選択
  actions: Action[];
  inputActionName: string;
  selectedAction: Action | null;
  showingItemNumberSearchDialog: boolean;
  searchItemNumberLimitLength: number; // 品番一括検索で入力できる品番の数
}

declare global {
  interface Window {
    isLandScape: boolean;
  }
}

export default Vue.extend({
  name: "ProductList",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    S3Image,
    ChartSlider,
    FilterChipButton,
    ElementRangeDatePicker,
    ElementDatePicker,
    ConfirmationDialog,
    Loading,
  },
  data(): DataType {
    return {
      currentIndex: null,
      productTableDatas: [],
      productFilterInput: {
        brands: [],
        seasons: [],
        categories: [],
        attribute1: [],
        attribute2: [],
        attribute3: [],
        customTagDatas: [],
        stores: [],
      },
      productFilterStatusInput: Constant.productStatusInput,
      productFilterStoreInput: [],
      statuses: ["販売中", "販売終了", "未販売"],
      dialogDelete: false,
      isLoading: false,
      x: 0,
      y: 0,
      searchEntries: {
        itemNumber: "",
        brands: [],
        seasons: [],
        categories: [],
        attribute1: [],
        attribute2: [],
        attribute3: [],
        salesStatus: [],
        salesPrice: [-Infinity, Infinity],
        stockPrice: [-Infinity, Infinity],
        salesCount: [-Infinity, Infinity],
        stockCount: [-Infinity, Infinity],
        salePrice: [-Infinity, Infinity],
        alerts: [],
        customTags: [],
        stores: [],
        statuses: [],
      },
      savedSearchEntries: {
        itemNumber: "",
        brands: [],
        seasons: [],
        categories: [],
        attribute1: [],
        attribute2: [],
        attribute3: [],
        salesStatus: [],
        salesPrice: [-Infinity, Infinity],
        stockPrice: [-Infinity, Infinity],
        salesCount: [-Infinity, Infinity],
        stockCount: [-Infinity, Infinity],
        salePrice: [-Infinity, Infinity],
        alerts: [],
        customTags: [],
        stores: [],
        statuses: [],
      },
      selectedItems: [],
      actionOption: "アクション",
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      isTableLoading: false,
      isLandScape: false,
      today: new Date(),
      alertDatas: [],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ["sumSalesCount"],
        sortDesc: [true],
      },
      isDeleting: false,
      filterDialog: false,
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      salesFormat: 0,
      stockFormat: 0,
      tableFormat: 0,
      rangeDatesMenu: false,
      dialogShow: false,
      allProductCount: 0,
      cancelDates: [],
      isCanceled: false,
      attributes: [],
      productSalesStockInfo: {
        salesPrice: [],
        salesCount: [],
        stockPrice: [],
        stockCount: [],
      },
      productSalePriceInfo: [],
      filterSalesStockDialog: false,
      salesStockDialogShow: false,
      showingAddCustomTagDialog: false,
      inputCustomTagName: "",
      customTagMasters: [],
      selectedCustomTagMaster: [],
      isFilteringInSalesStock: false,
      isFilteringInSalePrice: false,
      isMounting: true,
      productImages: { s3KeyProducts: [] },
      isTablet: false,
      showingCreateActionDialog: false,
      createActionDialogPageNumber: 0,
      selectedActionType: "SALE",
      addActionListInfo: { title: null, offRatio: null, managerId: null, executedDate: null },
      adminUserDetails: [],
      customActionMasters: [],
      selectedCustomActionMaster: null,
      inputCustomActionName: "",
      createdActionId: null,
      showingAddProductIntoActionDialog: false,
      addProductIntoActionDialogPageNumber: 0,
      actions: [],
      inputActionName: "",
      selectedAction: null,
      showingItemNumberSearchDialog: false,
      searchItemNumberLimitLength: 1000,
    };
  },
  watch: {
    showingAlert: {
      handler() {
        if (!this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
    showingAddCustomTagDialog: {
      handler() {
        if (!this.showingAddCustomTagDialog) {
          this.actionOption = "アクション";
        }
      },
    },
    showingCreateActionDialog: {
      handler() {
        if (!this.showingCreateActionDialog && !this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
    showingAddProductIntoActionDialog: {
      handler() {
        if (!this.showingAddProductIntoActionDialog && !this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
    rangeDatesMenu: {
      async handler() {
        if (this.rangeDatesMenu) {
          this.cancelDates = this.rangeDates;
          this.isCanceled = true;
        } else if (!this.rangeDatesMenu && this.isCanceled) {
          this.rangeDates = this.cancelDates;
        }
      },
    },
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    headers(): { text: string; value: string; align: string; class: string; sortable?: boolean }[] {
      const result: {
        text: string;
        value: string;
        align: string;
        class: string;
        sortable?: boolean;
      }[] = [
        {
          text: "基本情報",
          value: "info",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70 product-list-basic-header pr-0",
          sortable: false,
        },
      ];
      Array.prototype.push.apply(
        result,
        this.tableFormat === 0
          ? [
              {
                text: "売上",
                value: "sumSalesCount",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70 product-list-header",
              },
              {
                text: "在庫",
                value: "sumStockCount",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70 product-list-header",
              },
            ]
          : [
              {
                text: "売上",
                value: "sumSalesPrice",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70",
              },
              {
                text: "在庫",
                value: "sumStockPrice",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70",
              },
            ]
      );
      Array.prototype.push.apply(result, [
        {
          text: "値引率",
          value: "discountRate",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 product-list-header",
        },
        {
          text: "消化率",
          value: "digestionRate",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 product-list-header",
        },
        {
          text: "消化週数",
          value: "storageLife",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 product-list-header",
        },
        {
          text: "カスタムタグ",
          value: "tags",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70",
          sortable: false,
        },
        {
          text: "アラート",
          value: "alertDatas",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70",
          sortable: false,
        },
        {
          text: "アクション",
          value: "tags",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70",
          sortable: false,
        },

        { text: "", value: "link" },
      ]);
      return result;
    },
    tabletHeaders(): { text: string; value: string; align: string; class: string; sortable?: boolean }[] {
      const result: {
        text: string;
        value: string;
        align: string;
        class: string;
        sortable?: boolean;
      }[] = [
        {
          text: `基本情報 品番数(${this.allProductCount})`,
          value: "info",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
          sortable: false,
        },
      ];
      Array.prototype.push.apply(
        result,
        this.tableFormat === 0
          ? [
              {
                text: "売上",
                value: "sumSalesCount",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
              },
              {
                text: "在庫",
                value: "sumStockCount",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
              },
            ]
          : [
              {
                text: "売上",
                value: "sumSalesPrice",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
              },
              {
                text: "在庫",
                value: "sumStockPrice",
                align: "center",
                class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
              },
            ]
      );
      Array.prototype.push.apply(result, [
        {
          text: "値引率",
          value: "digestionRate",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
        },
        {
          text: "消化率",
          value: "digestionRate",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
        },
        {
          text: "消化週数",
          value: "storageLife",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
        },
        {
          text: "",
          value: "comments",
          align: "center",
          class: "body-1-noto-sans radial-assistant-type-70 tablet-table",
        },
        { text: "", value: "positiveRate", align: "center", class: "tablet-table" },
        { text: "", value: "negativeRate", align: "center", class: "tablet-table" },
        { text: "", value: "alertDatas", align: "left", class: "tablet-table", sortable: false },
      ]);
      return result;
    },
    actionOptions(): string[] {
      return this.canUpdate ? ["カスタムタグ付与", "アクション作成", "アクションへ追加", "商品削除"] : [];
    },
    filterDateFrom(): string {
      if (dayjs(this.rangeDates[1]).diff(dayjs(this.rangeDates[0]), "days") > 0) {
        return this.rangeDates[0];
      } else {
        return this.rangeDates[1];
      }
    },
    filterDateTo(): string {
      if (dayjs(this.rangeDates[1]).diff(dayjs(this.rangeDates[0]), "days") > 0) {
        return this.rangeDates[1];
      } else {
        return this.rangeDates[0];
      }
    },
    minDate(): string {
      return dayjs().subtract(3, "year").format("YYYY-MM-DD");
    },
    pageRange(): number[] {
      const start = this.options.itemsPerPage * (this.options.page - 1) + 1;
      let end = this.options.itemsPerPage * this.options.page;
      if (end > this.allProductCount) {
        end = this.allProductCount;
      }
      return [start, end];
    },
    attributesLength(): number {
      return (
        this.searchEntries.attribute1.length +
        this.searchEntries.attribute2.length +
        this.searchEntries.attribute3.length
      );
    },
    nonSelectedCustomTagMasters(): CustomTagMaster[] {
      return this.customTagMasters.filter((item) => !this.selectedCustomTagMaster.some((tag) => tag.id === item.id));
    },
    filteredCustomTagMasters(): CustomTagMaster[] {
      let duplicatedCustomTags: string[] = [];
      if (this.selectedItems.length !== 0) {
        duplicatedCustomTags =
          this.selectedItems[0].customTagInfo?.map((info) => {
            return info.customTagName;
          }) ?? [];
        for (const index of Array(this.selectedItems.length - 1).keys()) {
          const customTags = this.selectedItems[index + 1].customTagInfo?.map((info) => {
            return info.customTagName;
          });
          duplicatedCustomTags = [...(duplicatedCustomTags ?? []), ...(customTags ?? [])].filter(
            (item) =>
              (duplicatedCustomTags ? duplicatedCustomTags.includes(item) : false) &&
              (customTags ? customTags.includes(item) : false)
          );
        }
      }
      duplicatedCustomTags = [...new Set(duplicatedCustomTags)];
      return this.nonSelectedCustomTagMasters
        .filter((item) => !duplicatedCustomTags.includes(item.name))
        .filter((item) => ~item.name.indexOf(this.inputCustomTagName));
    },
    filteredCustomActionMasters(): CustomActionMaster[] {
      return this.customActionMasters.filter((item) => ~item.name.indexOf(this.inputCustomActionName));
    },
    filteredActions(): Action[] {
      return this.actions
        .filter((item) => item.type === this.selectedActionType)
        .filter((item) => ~item.title.indexOf(this.inputActionName));
    },
    notSameCustomTag(): boolean {
      return !this.customTagMasters.some((item) => item.name === this.inputCustomTagName);
    },
    notSameCustomAction(): boolean {
      return !this.customActionMasters.some((item) => item.name === this.inputCustomActionName);
    },
    salesPriceDto(): { min: number | null; max: number | null } {
      // convert format from searchEntries to dto
      let min: number | null;
      let max: number | null;
      if (
        this.searchEntries.salesPrice[0] === -Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.salesPrice[0] === Math.min(...this.productSalesStockInfo.salesPrice)) ||
        this.allProductCount === 0
      ) {
        min = null;
      } else {
        min = this.searchEntries.salesPrice[0];
      }
      if (
        this.searchEntries.salesPrice[1] === Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.salesPrice[1] === Math.max(...this.productSalesStockInfo.salesPrice)) ||
        this.allProductCount === 0
      ) {
        max = null;
      } else {
        max = this.searchEntries.salesPrice[1];
      }
      return { min, max };
    },
    salesCountDto(): { min: number | null; max: number | null } {
      // convert format from searchEntries to dto
      let min: number | null;
      let max: number | null;
      if (
        this.searchEntries.salesCount[0] === -Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.salesCount[0] === Math.min(...this.productSalesStockInfo.salesCount)) ||
        this.allProductCount === 0
      ) {
        min = null;
      } else {
        min = this.searchEntries.salesCount[0];
      }
      if (
        this.searchEntries.salesCount[1] === Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.salesCount[1] === Math.max(...this.productSalesStockInfo.salesCount)) ||
        this.allProductCount === 0
      ) {
        max = null;
      } else {
        max = this.searchEntries.salesCount[1];
      }
      return { min, max };
    },
    stockPriceDto(): { min: number | null; max: number | null } {
      // convert format from searchEntries to dto
      let min: number | null;
      let max: number | null;
      if (
        this.searchEntries.stockPrice[0] === -Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.stockPrice[0] === Math.min(...this.productSalesStockInfo.stockPrice)) ||
        this.allProductCount === 0
      ) {
        min = null;
      } else {
        min = this.searchEntries.stockPrice[0];
      }
      if (
        this.searchEntries.stockPrice[1] === Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.stockPrice[1] === Math.max(...this.productSalesStockInfo.stockPrice)) ||
        this.allProductCount === 0
      ) {
        max = null;
      } else {
        max = this.searchEntries.stockPrice[1];
      }
      return { min, max };
    },
    stockCountDto(): { min: number | null; max: number | null } {
      // convert format from searchEntries to dto
      let min: number | null;
      let max: number | null;
      if (
        this.searchEntries.stockCount[0] === -Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.stockCount[0] === Math.min(...this.productSalesStockInfo.stockCount)) ||
        this.allProductCount === 0
      ) {
        min = null;
      } else {
        min = this.searchEntries.stockCount[0];
      }
      if (
        this.searchEntries.stockCount[1] === Infinity ||
        (!this.isFilteredInSalesStock() &&
          this.searchEntries.stockCount[1] === Math.max(...this.productSalesStockInfo.stockCount)) ||
        this.allProductCount === 0
      ) {
        max = null;
      } else {
        max = this.searchEntries.stockCount[1];
      }
      return { min, max };
    },
    salePriceDto(): { min: number | null; max: number | null } {
      // convert format from searchEntries to dto
      let min: number | null;
      let max: number | null;
      if (
        this.searchEntries.salePrice[0] === -Infinity ||
        (!this.isFilteredInSalePrice() && this.searchEntries.salePrice[0] === Math.min(...this.productSalePriceInfo)) ||
        this.allProductCount === 0
      ) {
        min = null;
      } else {
        min = this.searchEntries.salePrice[0];
      }
      if (
        this.searchEntries.salePrice[1] === Infinity ||
        (!this.isFilteredInSalePrice() && this.searchEntries.salePrice[1] === Math.max(...this.productSalePriceInfo)) ||
        this.allProductCount === 0
      ) {
        max = null;
      } else {
        max = this.searchEntries.salePrice[1];
      }
      return { min, max };
    },
    isSelectedActionStatusPending(): boolean {
      if (this.selectedAction) {
        return this.selectedAction.status === "PENDING";
      } else {
        return false;
      }
    },
    dateRangeError(): boolean {
      if (dayjs(this.filterDateTo).diff(dayjs(this.filterDateFrom), "year") >= 1) {
        return true;
      } else {
        return false;
      }
    },
    splitedItemNumbers(): string[] {
      return this.searchEntries.itemNumber.split(/\r?\n/);
    },
  },
  methods: {
    selectProduct(item: ProductListTableData) {
      if (this.selectedItems.includes(item)) {
        // item が含まれていたら削除する
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item);
      } else {
        this.selectedItems.push(item);
      }
    },
    async deleteAlert(index: number, alert: AlertWithMaster) {
      this.isLoading = true;
      const updatedAlert = await RadialApiClient.userDeleteAlert(alert.alertId);
      if (updatedAlert) {
        // searchをかけるのではなく、該当の行（プロダクト）から削除したアラートを更新する
        const selectedAlert = this.productTableDatas[index]?.alertDataList.find(
          (alertData) => alertData.alertId === alert.alertId
        );
        if (selectedAlert) {
          selectedAlert.userDeleted = true;
        }
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      this.isLoading = false;
    },
    async removeSelectedCustomTag(index: number, tag: CustomTagInfo) {
      this.isLoading = true;
      const deletedCustomTag = await RadialApiClient.deleteCustomTag(tag.customTagId.toString());
      if (deletedCustomTag) {
        // searchをかけるのではなく、該当の行（プロダクト）からカスタムタグを削除する
        this.productTableDatas[index].customTagInfo =
          this.productTableDatas[index].customTagInfo?.filter((info) => info.customTagId !== tag.customTagId) ?? [];
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      this.isLoading = false;
    },
    priceTextInput(num: number, event: any): number | null {
      const price = Number(event.replace(/[^0123456789]/g, ""));
      switch (num) {
        case 0:
          Vue.set(this.searchEntries.salesPrice, 0, price);
          return price;
        case 1:
          Vue.set(this.searchEntries.salesPrice, 1, price);
          return price;
        case 2:
          Vue.set(this.searchEntries.stockPrice, 0, price);
          return price;
        case 3:
          Vue.set(this.searchEntries.stockPrice, 1, price);
          return price;
        default:
          return price;
      }
    },
    countTextInput(num: number, event: any): number | null {
      const count = Number(event.replace(/[^0123456789]/g, ""));
      switch (num) {
        case 0:
          Vue.set(this.searchEntries.salesCount, 0, count);
          return count;
        case 1:
          Vue.set(this.searchEntries.salesCount, 1, count);
          return count;
        case 2:
          Vue.set(this.searchEntries.stockCount, 0, count);
          return count;
        case 3:
          Vue.set(this.searchEntries.stockCount, 1, count);
          return count;
        case 4:
          Vue.set(this.searchEntries.salePrice, 0, count);
          return count;
        case 5:
          Vue.set(this.searchEntries.salePrice, 1, count);
          return count;
        default:
          return count;
      }
    },
    optionSelected(event: any) {
      if (event === "商品削除") {
        this.showAlert(
          "",
          `本当に選択した商品データ${this.selectedItems.length}件を削除してよろしいですか？
          対応したSKUデータも削除されます。`,
          "error"
        );
      } else if (event === "カスタムタグ付与") {
        this.showingAddCustomTagDialog = true;
      } else if (event === "アクション作成") {
        this.showingCreateActionDialog = true;
      } else if (event === "アクションへ追加") {
        this.showingAddProductIntoActionDialog = true;
      }
    },
    async deleteItem() {
      this.isLoading = true;
      this.isDeleting = true;
      this.showingAlert = false;
      const promises: Promise<Product | null>[] = [];
      if (this.user?.companyId) {
        for (const item of this.selectedItems) {
          if (item.productId) {
            promises.push(RadialApiClient.deleteProduct(item.productId));
          }
        }
        await Promise.all(promises);
        this.selectedItems = [];
      }
      await this.search();
      this.isDeleting = false;
      this.isLoading = false;
    },
    getProductTableDatas(products: ProductListDataEntity[]): ProductListTableData[] {
      const productTableDatas: ProductListTableData[] = [];
      products.forEach((item: ProductListDataEntity) => {
        productTableDatas.push({
          productId: item.productId,
          name: item.name,
          itemNumber: item.itemNumber,
          price: item.price,
          salesPrice: item.salesPrice,
          salesStartedAt: item.salesStartedAt ? dayjs(item.salesStartedAt).format("YYYY/MM/DD") : "",
          salesEndedAt: item.salesEndedAt ? dayjs(item.salesEndedAt).format("YYYY/MM/DD") : "",
          alertDataList: this.getAlertDataList(item),
          customTagInfo: item.customTagInfo,
          sumSalesPrice: item.sumTransactionDiscountSubtotal,
          sumStockPrice: this.getSumStockPrice(item),
          sumSalesCount: item.sumTransactionQuantity,
          sumStockCount: item.stockCount,
          digestionRate: item.digestionRate.toLocaleString(),
          discountRate: item.discountRate,
          storageLife: item.storageLife,
          sumComment: item.sumComment,
          positiveRate: this.getCommentsRatio(item, true),
          negativeRate: this.getCommentsRatio(item, false),
          actionDataList: item.actionData,
        });
      });
      return productTableDatas;
    },
    async search() {
      this.isTableLoading = true;
      this.isCanceled = false;
      this.rangeDatesMenu = false;
      this.salesStockDialogShow = false;
      this.dialogShow = false;
      if (this.rangeDates.length === 1) {
        this.rangeDates = [this.rangeDates[0], this.rangeDates[0]];
      }
      const salesStatus: number[] = [];
      for (let i = 0; i < this.statuses.length; i++) {
        if ((this.searchEntries.salesStatus as any).includes(this.statuses[i])) {
          salesStatus.push(i);
        }
      }

      // フィルタ―条件に合わせてdtoを作成し検索APIを叩く
      const input: ProductListInputEntity = {
        start: dayjs(this.filterDateFrom).startOf("day").toDate(),
        end: dayjs(this.filterDateTo).endOf("day").toDate(),
        offset: this.pageRange[0] - 1,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy[0],
        isDesc: this.options.sortDesc[0],
        status: this.searchEntries.statuses.map((item) => item.code),
        itemNumber: this.splitedItemNumbers,
        brand: this.searchEntries.brands.map((brand) => (typeof brand.id === "number" ? brand.id : 0)),
        season: this.searchEntries.seasons.map((season) => (typeof season.id === "number" ? season.id : 0)),
        category: this.searchEntries.categories.map((category) => (typeof category.id === "number" ? category.id : 0)),
        attribute1: this.searchEntries.attribute1,
        attribute2: this.searchEntries.attribute2,
        attribute3: this.searchEntries.attribute3,
        salesStatus: salesStatus,
        salesPrice: this.salesPriceDto.min === null && this.salesPriceDto.max === null ? null : this.salesPriceDto,
        stockPrice: this.stockPriceDto.min === null && this.stockPriceDto.max === null ? null : this.stockPriceDto,
        salesCount: this.salesCountDto.min === null && this.salesCountDto.max === null ? null : this.salesCountDto,
        stockCount: this.stockCountDto.min === null && this.stockCountDto.max === null ? null : this.stockCountDto,
        productSalesPrice: this.salePriceDto.min === null && this.salePriceDto.max === null ? null : this.salePriceDto,
        alerts: this.searchEntries.alerts,
        customTagMasters: this.searchEntries.customTags,
        store: this.searchEntries.stores.map((store) => (typeof store.id === "string" ? store.id : "inoperative")),
      };

      const productList = await RadialApiClient.listProductList(input);
      if (productList) {
        const productListDataEntities: ProductListDataEntity[] = productList.productListDatas;
        this.productTableDatas = this.getProductTableDatas(productListDataEntities);
        this.allProductCount = productList.productMaxCount;
        if (this.isFilteredInSalesStock()) {
          this.productSalesStockInfo = productList.productSalesStockInfo
            ? productList.productSalesStockInfo
            : { salesPrice: [], salesCount: [], stockPrice: [], stockCount: [] };
          this.isFilteringInSalesStock = true;
        } else {
          this.productSalesStockInfo = productList.productSalesStockInfo
            ? productList.productSalesStockInfo
            : { salesPrice: [], salesCount: [], stockPrice: [], stockCount: [] };
        }
        if (this.isFilteredInSalePrice()) {
          this.productSalePriceInfo = productList.productFilterInfo ? productList.productFilterInfo.salesPrice : [];
          this.isFilteringInSalePrice = true;
        } else {
          this.productSalePriceInfo = productList.productFilterInfo ? productList.productFilterInfo.salesPrice : [];
        }
      }

      // get product image s3keys
      const productIds: string[] = this.productTableDatas.map((item) => item.productId);
      const productImages = await RadialApiClient.getProductMainImages({ productIds });
      if (productImages) {
        this.productImages = productImages;
      }

      // queryにsearchEntries情報を保存
      const searchEntriesQuery: SearchEntries = JSON.parse(JSON.stringify(this.searchEntries));
      searchEntriesQuery.salesPrice = [this.salesPriceDto.min, this.salesPriceDto.max];
      searchEntriesQuery.salesCount = [this.salesCountDto.min, this.salesCountDto.max];
      searchEntriesQuery.stockPrice = [this.stockPriceDto.min, this.stockPriceDto.max];
      searchEntriesQuery.stockCount = [this.stockCountDto.min, this.stockCountDto.max];
      searchEntriesQuery.salePrice = [this.salePriceDto.min, this.salePriceDto.max];
      const search = JSON.stringify(searchEntriesQuery);
      const rangeDates = JSON.stringify(this.rangeDates);
      if (
        (await AuthClient.checkAuth(this.isAdmin)) &&
        (search !== this.$route.query.search || rangeDates !== this.$route.query.rangeDates)
      ) {
        this.$router.push({
          name: this.isAdmin ? "AdminProductList" : "ProductList",
          query: { search, rangeDates },
        });
      }

      // LocalDataServiceにsearchEntries情報を保存
      await LocalDataService.setSearchEntries(searchEntriesQuery);

      // googleアナリティクスにデータを送信
      if (this.isAdmin) {
        this.$gtag?.event("Admin-productListSearch", {
          event_category: "Admin",
          event_label: "Admin商品リスト検索",
        });
      } else {
        this.$gtag?.event("Staff-productListSearch", {
          event_category: "Staff",
          event_label: "Staff商品リスト検索",
        });
      }
      this.isTableLoading = false;
    },
    showDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    async clearFilters() {
      this.options.page = 1;
      await LocalDataService.clearSearchEntries();
      const entries: SearchEntries | null = await LocalDataService.getSearchEntries();
      if (entries) {
        this.searchEntries = this.convertQueryToSearchEntriesFormat(entries);
      }
      await this.search();
      this.isFilteringInSalesStock = false;
      this.isFilteringInSalePrice = false;
      this.isLoading = false;
    },
    showAlert(title: string, message: string, type: string) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getCommentsRatio(product: ProductListDataEntity, isPositive: boolean) {
      if (product.sumComment) {
        if (isPositive && product.countPositiveComment) {
          return Math.ceil((100 * product.countPositiveComment) / product.sumComment);
        } else if (!isPositive && product.countNegativeComment) {
          return Math.ceil((100 * product.countNegativeComment) / product.sumComment);
        }
      }
      return 0;
    },
    getSumStockPrice(product: ProductListDataEntity): number {
      if (product.price && product.stockCount) {
        return (product.price ?? 0) * product.stockCount;
      }
      return 0;
    },
    getDigestionRate(product: ProductListDataEntity): string {
      if (product.sumTransactionQuantity && product.stockCount) {
        if (product.sumTransactionQuantity + product.stockCount === 0) {
          return "-";
        } else {
          const rate = Math.ceil(
            (100 * product.sumTransactionQuantity) / (product.sumTransactionQuantity + product.stockCount)
          );
          return rate.toString();
        }
      }
      return "-";
    },
    getAlertDataList(product: ProductListDataEntity): {
      alertId: string;
      alertMasterId: number;
      userDeleted: boolean;
      count: number;
    }[] {
      const alertDataList: {
        alertId: string;
        alertMasterId: number;
        userDeleted: boolean;
        count: number;
      }[] = [];
      if (product.alertDatas) {
        for (const alert of product.alertDatas) {
          if (
            alertDataList.some(
              (item) => item.alertMasterId === alert.alertMaster.id && item.userDeleted === alert.userDeleted
            )
          ) {
            alertDataList[
              alertDataList.findIndex(
                (item) => item.alertMasterId === alert.alertMaster.id && item.userDeleted === alert.userDeleted
              )
            ].count += 1;
          } else {
            alertDataList.push({
              alertId: alert.alertId,
              alertMasterId: alert.alertMaster.id,
              userDeleted: alert.userDeleted,
              count: 1,
            });
          }
        }
      }
      return alertDataList;
    },
    getAlertDetail(alertMasterId: number): AlertDetail {
      const alert = this.alertDatas
        .filter((item) => {
          return item.id === alertMasterId;
        })
        .shift();
      if (alert) {
        return {
          number: alert.id,
          name: alert.name,
          color: alert.prominenceColor,
          storeIntegration: alert.storeIntegration,
          description: alert.description,
          comment: alert.comment,
        };
      }
      return {
        number: -1,
        name: "",
        color: "",
        storeIntegration: false,
        description: "",
        comment: "",
      };
    },
    async paginationButtonDidClick(index: number) {
      if (index === 0) {
        this.options.page -= 1;
        await this.search();
      } else {
        this.options.page += 1;
        await this.search();
      }
    },
    templateDates(range: RangeDatesTemplate) {
      this.rangeDates = [range.from, range.to];
    },
    cancelDatePick() {
      this.rangeDatesMenu = false;
    },
    resetAttributesEntries() {
      this.searchEntries.attribute1 = [];
      this.searchEntries.attribute2 = [];
      this.searchEntries.attribute3 = [];
    },
    getAttributeTabTitle(): string {
      if (this.searchEntries.attribute1.length !== 0 && this.attributesLength === 1) {
        const attribute1 = this.attributes.find((attribute) => {
          return attribute.id === this.searchEntries.attribute1[0];
        });
        if (attribute1) {
          return attribute1.name;
        }
      } else if (this.searchEntries.attribute2.length !== 0 && this.attributesLength === 1) {
        const attribute2 = this.attributes.find((attribute) => {
          return attribute.id === this.searchEntries.attribute2[0];
        });
        if (attribute2) {
          return attribute2.name;
        }
      } else if (this.searchEntries.attribute3.length !== 0 && this.attributesLength === 1) {
        const attribute3 = this.attributes.find((attribute) => {
          return attribute.id === this.searchEntries.attribute3[0];
        });
        if (attribute3) {
          return attribute3.name;
        }
      } else {
        return "属性";
      }
      return "";
    },
    getIndex(item: ProductListTableData): number {
      const start = this.options.itemsPerPage * (this.options.page - 1) + 1;
      return start + this.productTableDatas.indexOf(item);
    },
    async searchButtonDidClick() {
      this.options.page = 1;
      await this.search();
    },
    isFilteredInAddFilter(): boolean {
      return this.searchEntries.salesStatus.length !== 0 || this.isFilteredInSalePrice();
    },
    isFilteredInSalePrice(): boolean {
      if (this.isFilteringInSalePrice) {
        return true;
      } else {
        return (
          (this.searchEntries.salePrice[0]
            ? this.searchEntries.salePrice[0] > Math.min(...this.productSalePriceInfo)
            : false) ||
          (this.searchEntries.salePrice[1]
            ? this.searchEntries.salePrice[1] < Math.max(...this.productSalePriceInfo)
            : false)
        );
      }
    },
    isFilteredInSalesStock(): boolean {
      if (this.isFilteringInSalesStock) {
        return true;
      } else {
        return (
          (this.searchEntries.salesPrice[0]
            ? this.searchEntries.salesPrice[0] > Math.min(...this.productSalesStockInfo.salesPrice)
            : false) ||
          (this.searchEntries.salesPrice[1]
            ? this.searchEntries.salesPrice[1] < Math.max(...this.productSalesStockInfo.salesPrice)
            : false) ||
          (this.searchEntries.salesCount[0]
            ? this.searchEntries.salesCount[0] > Math.min(...this.productSalesStockInfo.salesCount)
            : false) ||
          (this.searchEntries.salesCount[1]
            ? this.searchEntries.salesCount[1] < Math.max(...this.productSalesStockInfo.salesCount)
            : false) ||
          (this.searchEntries.stockPrice[0]
            ? this.searchEntries.stockPrice[0] > Math.min(...this.productSalesStockInfo.stockPrice)
            : false) ||
          (this.searchEntries.stockPrice[1]
            ? this.searchEntries.stockPrice[1] < Math.max(...this.productSalesStockInfo.stockPrice)
            : false) ||
          (this.searchEntries.stockCount[0]
            ? this.searchEntries.stockCount[0] > Math.min(...this.productSalesStockInfo.stockCount)
            : false) ||
          (this.searchEntries.stockCount[1]
            ? this.searchEntries.stockCount[1] < Math.max(...this.productSalesStockInfo.stockCount)
            : false)
        );
      }
    },
    async searchInDateRange() {
      if (this.dateRangeError) {
        return;
      }
      if (!this.isFilteredInSalesStock()) {
        // 売上在庫でフィルタリングしていない時は売上在庫フィルタの条件をリセット
        this.searchEntries.salesPrice = [-Infinity, Infinity];
        this.searchEntries.salesCount = [-Infinity, Infinity];
        this.searchEntries.stockPrice = [-Infinity, Infinity];
        this.searchEntries.stockCount = [-Infinity, Infinity];
      } else {
        this.isFilteringInSalesStock = true;
      }
      if (!this.isFilteredInSalePrice()) {
        this.searchEntries.salePrice = [-Infinity, Infinity];
      } else {
        this.isFilteringInSalePrice = true;
      }
      await this.search();
    },
    async searchInItemNumber() {
      if (!this.isFilteredInSalesStock()) {
        // 売上在庫でフィルタリングしていない時は売上在庫フィルタの条件をリセット
        this.searchEntries.salesPrice = [-Infinity, Infinity];
        this.searchEntries.salesCount = [-Infinity, Infinity];
        this.searchEntries.stockPrice = [-Infinity, Infinity];
        this.searchEntries.stockCount = [-Infinity, Infinity];
      } else {
        this.isFilteringInSalesStock = true;
      }
      if (!this.isFilteredInSalePrice()) {
        this.searchEntries.salePrice = [-Infinity, Infinity];
      } else {
        this.isFilteringInSalePrice = true;
      }
      await this.search();
    },
    async clearSalesStockFilter() {
      this.isLoading = true;
      // 売上在庫以外のフィルタ―条件に合わせてdtoを作成し検索APIを叩き、全商品の売上在庫データのリストを取得
      const salesStatus: number[] = [];
      for (let i = 0; i < this.statuses.length; i++) {
        if ((this.searchEntries.salesStatus as any).includes(this.statuses[i])) {
          salesStatus.push(i);
        }
      }
      const inputSalesStock: ProductListInputEntity = {
        start: dayjs(this.filterDateFrom).startOf("day").toDate(),
        end: dayjs(this.filterDateTo).endOf("day").toDate(),
        offset: this.pageRange[0] - 1,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy[0],
        isDesc: this.options.sortDesc[0],
        itemNumber: [],
        status: [],
        brand: this.searchEntries.brands.map((brand) => (typeof brand.id === "number" ? brand.id : 0)),
        season: this.searchEntries.seasons.map((season) => (typeof season.id === "number" ? season.id : 0)),
        category: this.searchEntries.categories.map((category) => (typeof category.id === "number" ? category.id : 0)),
        attribute1: this.searchEntries.attribute1,
        attribute2: this.searchEntries.attribute2,
        attribute3: this.searchEntries.attribute3,
        salesStatus: salesStatus,
        salesPrice: null,
        stockPrice: null,
        salesCount: null,
        stockCount: null,
        productSalesPrice: this.salePriceDto.min === null && this.salePriceDto.max === null ? null : this.salePriceDto,
        alerts: this.searchEntries.alerts,
        customTagMasters: this.searchEntries.customTags,
        store: this.searchEntries.stores.map((store) => (typeof store.id === "string" ? store.id : "")),
      };
      const allProductSalesStockList = await RadialApiClient.listProductList(inputSalesStock);
      if (allProductSalesStockList) {
        this.productSalesStockInfo = allProductSalesStockList.productSalesStockInfo;
      }
      this.searchEntries.salesPrice = [-Infinity, Infinity];
      this.searchEntries.stockPrice = [-Infinity, Infinity];
      this.searchEntries.salesCount = [-Infinity, Infinity];
      this.searchEntries.stockCount = [-Infinity, Infinity];
      this.isFilteringInSalesStock = false;
      this.isLoading = false;
    },
    async selectCustomTag(tag: CustomTagMaster) {
      this.isLoading = true;
      if (!this.selectedCustomTagMaster.map((item) => item.id).includes(tag.id)) {
        this.selectedCustomTagMaster.push(tag);
      }
      this.isLoading = false;
    },
    removeSelectedCustomTagMaster(tag: CustomTagMaster) {
      this.isLoading = true;
      const tagIndex = this.selectedCustomTagMaster.map((item) => item.id).indexOf(tag.id);
      if (tagIndex !== -1) {
        this.selectedCustomTagMaster.splice(tagIndex, 1);
      }
      this.isLoading = false;
    },
    async createCustomTagMaster() {
      this.isLoading = true;
      const masterDto: CreateCustomTagMasterDto = {
        name: this.inputCustomTagName,
        company: {
          connect: {
            id: this.user?.companyId,
          },
        },
      };
      await RadialApiClient.createCustomTagMaster(masterDto);
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      const inputCustomTagMaster = this.customTagMasters.find((item) => item.name === this.inputCustomTagName);
      if (inputCustomTagMaster) {
        this.selectedCustomTagMaster.push(inputCustomTagMaster);
      }
      this.inputCustomTagName = "";
      this.isLoading = false;
    },
    cancelAddCustomTags() {
      this.selectedCustomTagMaster = [];
      this.showingAddCustomTagDialog = false;
    },
    cancelCreateAction() {
      // 設定をリセット
      this.showingCreateActionDialog = false;
      this.selectedActionType = "SALE";
      this.inputCustomActionName = "";
      this.addActionListInfo.title = null;
      this.addActionListInfo.offRatio = null;
      this.addActionListInfo.managerId = null;
      this.addActionListInfo.executedDate = null;
      this.createActionDialogPageNumber = 0;
    },
    cancelAddProductIntoAction() {
      // 設定をリセット
      this.showingAddProductIntoActionDialog = false;
      this.selectedActionType = "SALE";
      this.inputActionName = "";
      this.selectedAction = null;
      this.addProductIntoActionDialogPageNumber = 0;
    },
    async addProductIntoAction() {
      if (this.selectedAction) {
        const productIds: string[] = this.selectedItems
          .map((item) => {
            if (!item.actionDataList.map((actionData) => actionData.id).includes(this.selectedAction?.id ?? ""))
              return item.productId;
          })
          .filter((item): item is NonNullable<typeof item> => item != null);
        const dto: ProductListActionAddProductsDto = {
          actionId: this.selectedAction.id,
          productIds: productIds,
        };
        await RadialApiClient.addActionToProducts(dto);
        this.createdActionId = dto.actionId;
        // テーブルの情報を再取得
        await this.search();
        this.showAlert(
          "",
          `${this.selectedAction.title}に商品を追加しました！
            詳細をアクションリストで閲覧しますか？`,
          "success"
        );
        // 設定をリセット
        this.showingAddProductIntoActionDialog = false;
        this.selectedActionType = "SALE";
        this.inputActionName = "";
        this.selectedAction = null;
        this.addProductIntoActionDialogPageNumber = 0;
        this.selectedItems = [];
        this.showingAddProductIntoActionDialog = false;
        this.isLoading = false;
      } else {
        this.showAlert("", "選択されているアクションがありません", "error");
      }
    },
    showNextPageToCreateAction(currentPage: number) {
      switch (currentPage) {
        case 0:
          if (this.selectedActionType === "SALE") {
            this.createActionDialogPageNumber = 1;
          } else if (this.selectedActionType === "CUSTOM") {
            this.createActionDialogPageNumber = 2;
          }
          break;
        case 2:
          this.createActionDialogPageNumber = 1;
          break;
        default:
          this.createActionDialogPageNumber = 0;
          break;
      }
    },
    async addCustomTags() {
      if (this.user) {
        const dto: CustomTagsToProductsDto = {
          customTagMaterIds: this.selectedCustomTagMaster.map((item) => item.id),
          productIds: this.selectedItems.map((item) => item.productId),
          creatorAdminUserId: this.user?.sub !== this.user?.companyId ? this.user?.sub : null,
        };
        await RadialApiClient.addCustomTags(dto);
        await this.search();
        this.selectedCustomTagMaster = [];
        this.selectedItems = [];
        this.showingAddCustomTagDialog = false;
        this.showAlert("", "選択した商品へのタグの付与が完了しました。", "success");
      } else {
        this.showAlert(
          "",
          `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
          "error"
        );
      }
    },
    async clearAddFilter() {
      this.isLoading = true;
      this.searchEntries.salesStatus = [];
      // 販売価格以外のフィルタ―条件に合わせてdtoを作成し検索APIを叩き、全商品の売上在庫データのリストを取得
      const salesStatus: number[] = [];
      for (let i = 0; i < this.statuses.length; i++) {
        if ((this.searchEntries.salesStatus as any).includes(this.statuses[i])) {
          salesStatus.push(i);
        }
      }
      const inputSalePrice: ProductListInputEntity = {
        start: dayjs(this.filterDateFrom).startOf("day").toDate(),
        end: dayjs(this.filterDateTo).endOf("day").toDate(),
        offset: this.pageRange[0] - 1,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy[0],
        isDesc: this.options.sortDesc[0],
        itemNumber: [],
        status: [],
        brand: this.searchEntries.brands.map((brand) => (typeof brand.id === "number" ? brand.id : 0)),
        season: this.searchEntries.seasons.map((season) => (typeof season.id === "number" ? season.id : 0)),
        category: this.searchEntries.categories.map((category) => (typeof category.id === "number" ? category.id : 0)),
        attribute1: this.searchEntries.attribute1,
        attribute2: this.searchEntries.attribute2,
        attribute3: this.searchEntries.attribute3,
        salesStatus: [],
        salesPrice: this.salesPriceDto.min === null && this.salesPriceDto.max === null ? null : this.salesPriceDto,
        stockPrice: this.stockPriceDto.min === null && this.stockPriceDto.max === null ? null : this.stockPriceDto,
        salesCount: this.salesCountDto.min === null && this.salesCountDto.max === null ? null : this.salesCountDto,
        stockCount: this.stockCountDto.min === null && this.stockCountDto.max === null ? null : this.stockCountDto,
        productSalesPrice: null,
        alerts: this.searchEntries.alerts,
        customTagMasters: this.searchEntries.customTags,
        store: this.searchEntries.stores.map((store) => (typeof store.id === "string" ? store.id : "")),
      };
      const allProductSalePriceList = await RadialApiClient.listProductList(inputSalePrice);
      this.productSalePriceInfo = allProductSalePriceList?.productFilterInfo?.salesPrice ?? [];
      this.searchEntries.salePrice = [-Infinity, Infinity];
      this.isFilteringInSalePrice = false;
      this.isLoading = false;
    },
    async tableOptionsUpdated() {
      if (!this.isMounting) {
        this.isLoading = true;
        this.isTableLoading = true;
        await this.search();
        this.isLoading = false;
        this.isTableLoading = false;
      }
    },
    isSalesStockFilterDisabled(): boolean {
      return this.allProductCount === 0;
    },
    convertQueryToSearchEntriesFormat(entries: SearchEntries): SearchEntries {
      // LocalDataServiceから得られたentriesのフォーマットをsearchEntriesのフォーマットに返還
      entries.salesPrice = [entries.salesPrice[0] ?? -Infinity, entries.salesPrice[1] ?? Infinity];
      entries.salesCount = [entries.salesCount[0] ?? -Infinity, entries.salesCount[1] ?? Infinity];
      entries.stockPrice = [entries.stockPrice[0] ?? -Infinity, entries.stockPrice[1] ?? Infinity];
      entries.stockCount = [entries.stockCount[0] ?? -Infinity, entries.stockCount[1] ?? Infinity];
      entries.salePrice = [entries.salePrice[0] ?? -Infinity, entries.salePrice[1] ?? Infinity];
      return entries;
    },
    getS3Key(product: ProductListTableData): string {
      return this.productImages.s3KeyProducts.find((item) => item.productId === product.productId)?.s3Key ?? "";
    },
    selectActionType(actionType: ActionType) {
      this.selectedActionType = actionType;
    },
    async createAction() {
      this.isLoading = true;
      // エラーハンドリング(セールアクション作成に販売価格がない商品を選択しているとき)
      if (this.selectedItems.some((item) => item.salesPrice === null) && this.selectedActionType === "SALE") {
        this.selectedActionType = "SALE";
        this.inputCustomActionName = "";
        this.selectedCustomActionMaster = null;
        this.addActionListInfo.title = null;
        this.addActionListInfo.offRatio = null;
        this.addActionListInfo.managerId = null;
        this.addActionListInfo.executedDate = null;
        this.createActionDialogPageNumber = 0;
        this.showingCreateActionDialog = false;
        this.showAlert(
          "",
          `選択されている商品の中に販売価格が設定されていない商品があるため、セールアクションを作成できませんでした。
          販売価格を設定の上、再度お試しください。`,
          "error"
        );
        this.showingCreateActionDialog = false;
        this.isLoading = false;
        return;
      }

      // Actionテーブルにレコードを追加
      const actionDto: CreateActionDto = {
        title: this.addActionListInfo.title ?? "",
        type: this.selectedActionType,
        customActionMasterId: this.selectedCustomActionMaster ? this.selectedCustomActionMaster.id : null,
        creatorAdminUserId: this.user?.sub,
        managerAdminUserId: this.addActionListInfo.managerId,
        status: "PENDING",
        company: { connect: { id: store.state.company?.id } },
        executedDate: this.addActionListInfo.executedDate,
      };
      const action = await RadialApiClient.createAction(actionDto);

      if (action) {
        // アクションと商品を紐づける
        await RadialApiClient.addActionToProducts({
          actionId: action.id,
          productIds: this.selectedItems.map((item) => item.productId),
          offRate: this.selectedActionType === "SALE" ? Number(this.addActionListInfo.offRatio) : undefined,
        });
        // テーブルの情報を再取得
        await this.search();

        // アクションリストを新しいタブで開くかのダイアログ
        this.createdActionId = action.id;
        this.showAlert(
          "",
          `${this.addActionListInfo.title}を作成しました！
        詳細をアクションリストで閲覧しますか？`,
          "success"
        );
        // 設定をリセット
        this.showingCreateActionDialog = false;
        this.selectedActionType = "SALE";
        this.inputCustomActionName = "";
        this.addActionListInfo.title = null;
        this.addActionListInfo.offRatio = null;
        this.addActionListInfo.managerId = null;
        this.addActionListInfo.executedDate = null;
        this.createActionDialogPageNumber = 0;
        this.selectedItems = [];
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }

      this.showingCreateActionDialog = false;
      this.isLoading = false;
    },
    resetActionInfo() {
      // 設定をリセット
      this.selectedActionType = "SALE";
      this.inputCustomActionName = "";
      this.selectedCustomActionMaster = null;
      this.addActionListInfo.title = null;
      this.addActionListInfo.offRatio = null;
      this.addActionListInfo.managerId = null;
      this.addActionListInfo.executedDate = null;
      this.createActionDialogPageNumber = 0;
      this.showingAlert = false;
    },
    addActionButtonDisable(): boolean {
      switch (this.selectedActionType) {
        case "SALE":
          return !(this.addActionListInfo.title && this.addActionListInfo.offRatio && this.addActionListInfo.managerId);
        case "CUSTOM":
          return !(this.selectedCustomActionMaster && this.addActionListInfo.title && this.addActionListInfo.managerId);
        default:
          return true;
      }
    },
    async createCustomActionMaster() {
      this.isLoading = true;
      const customActionMaster = await RadialApiClient.createCustomActionMaster({
        name: this.inputCustomActionName,
        company: { connect: { id: this.user?.companyId } },
      });
      if (customActionMaster) {
        this.customActionMasters.push(customActionMaster);
        this.selectedCustomActionMaster = customActionMaster;
        this.inputCustomActionName = "";
      }
      this.isLoading = false;
    },
    openActionList(actionId: string | null = null) {
      if (actionId) {
        const routeData = this.$router.resolve({
          name: "AdminActionList",
          params: { actionId },
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "AdminActionList",
          params: this.createdActionId ? { actionId: this.createdActionId } : undefined,
        });
        window.open(routeData.href, "_blank");
        this.resetActionInfo();
      }
    },
    zenkakuToHankaku(str: string): string {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
    changeTextField() {
      const offRatioText = this.addActionListInfo.offRatio;
      this.addActionListInfo.offRatio =
        !offRatioText?.replace(/,/g, "") ?? false
          ? "0" // 空文字列の時
          : Number(offRatioText) >= 100
          ? "100"
          : Number(offRatioText).toString(); // 数字が100未満の時
    },
    changeRotation() {
      if (screen.orientation.type.includes("landscape")) {
        this.isLandScape = true;
      } else {
        this.isLandScape = false;
      }
    },
    getPercentOff(salesPrice: number, price: number): string {
      const off = 100 - (salesPrice / price) * 100;
      return `(${Math.ceil(off)}% OFF)`;
    },
    getModalButtonName(): string {
      return this.actionOption === "商品削除" && this.selectedItems.length > 0 && !this.isDeleting
        ? "削除"
        : (this.actionOption === "アクション作成" || this.actionOption === "アクションへ追加") &&
          this.alertType !== "error"
        ? "閲覧"
        : "";
    },
    updateItemNumbers() {
      if (this.splitedItemNumbers.length > this.searchItemNumberLimitLength) {
        this.$nextTick(async () => {
          const cuttedItemNumbers = this.splitedItemNumbers.slice(0, this.searchItemNumberLimitLength);
          this.searchEntries.itemNumber = cuttedItemNumbers.join("\n");
        });
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.isTableLoading = true;
    this.isMounting = true;

    // タブレットかどうか判断
    this.isTablet = Constant.isUsingTablet();

    if (this.isTablet) {
      // タブレットが横向きかどうか判断
      this.isLandScape = screen.orientation.type.includes("landscape") ? true : false;
      // タブレットが回転した場合のコールバック登録
      window.addEventListener("orientationchange", this.changeRotation);
    }

    const searchString = this.$route.query.search as string;
    if (searchString) {
      this.searchEntries = this.convertQueryToSearchEntriesFormat(JSON.parse(searchString));
    }
    const rangeDatesString = this.$route.query.rangeDates as string;
    if (rangeDatesString) {
      this.rangeDates = JSON.parse(rangeDatesString);
    }
    if (this.user) {
      const productFilterInput = await RadialApiClient.getProductFilterInfo();
      if (productFilterInput) {
        this.productFilterInput = productFilterInput;
        this.productFilterInput.brands.sort((a, b) => {
          // 属性コードがない場合は名前でソート
          if (a.code && b.code) {
            return a.code > b.code ? 1 : -1;
          } else if (a.code) {
            return -1;
          } else if (b.code) {
            return 1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        });
        this.productFilterInput.categories.sort((a, b) => {
          // 属性コードがない場合は名前でソート
          if (a.code && b.code) {
            return a.code > b.code ? 1 : -1;
          } else if (a.code) {
            return -1;
          } else if (b.code) {
            return 1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        });
        const seasons = await RadialApiClient.listSeasons();
        if (seasons) {
          this.productFilterInput.seasons.sort((a, b) => {
            const seasonA = seasons.find((season) => season.name === a.name);
            const seasonB = seasons.find((season) => season.name === b.name);
            if (seasonA && seasonB) {
              if (seasonA.startYearMonth < seasonB.startYearMonth) {
                return 1;
              } else if (seasonA.startYearMonth === seasonB.startYearMonth) {
                if (seasonA.code && seasonB.code) {
                  return seasonA.code > seasonB.code ? 1 : -1;
                } else if (seasonA.code && seasonB.code === "") {
                  return -1;
                } else if (seasonA.code === "" && seasonB.code) {
                  return 1;
                } else {
                  return seasonA.name > seasonB.name ? -1 : 1;
                }
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          });
        }
        this.productFilterInput.attribute1 = this.productFilterInput.attribute1
          .filter((item) => item !== null)
          .sort((a, b) => {
            // 属性コードがない場合は名前でソート
            if (a.code && b.code) {
              return a.code > b.code ? 1 : -1;
            } else if (a.code) {
              return -1;
            } else if (b.code) {
              return 1;
            } else {
              return a.name > b.name ? 1 : -1;
            }
          });
        this.productFilterInput.attribute2 = this.productFilterInput.attribute2
          .filter((item) => item !== null)
          .sort((a, b) => {
            // 属性コードがない場合は名前でソート
            if (a.code && b.code) {
              return a.code > b.code ? 1 : -1;
            } else if (a.code) {
              return -1;
            } else if (b.code) {
              return 1;
            } else {
              return a.name > b.name ? 1 : -1;
            }
          });
        this.productFilterInput.attribute3 = this.productFilterInput.attribute3
          .filter((item) => item !== null)
          .sort((a, b) => {
            // 属性コードがない場合は名前でソート
            if (a.code && b.code) {
              return a.code > b.code ? 1 : -1;
            } else if (a.code) {
              return -1;
            } else if (b.code) {
              return 1;
            } else {
              return a.name > b.name ? 1 : -1;
            }
          });
      }
      this.attributes = (await RadialApiClient.listAttributes()) ?? [];
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      // 店舗一覧を作成
      const storeTable = (await RadialApiClient.listStores()) ?? [];
      storeTable.map((store) => {
        if (store.operating) {
          this.productFilterStoreInput.push({ id: store.id, name: store.name, code: null });
        } 
      });
      // アラート一覧の取得
      this.alertDatas = (await RadialApiClient.listAlertMasters()) ?? [];
    }

    // テーブルの情報を取得
    await this.search();

    // 作成者と担当者のリスト情報を取得
    if (this.user) {
      if (store.state.company) {
        this.adminUserDetails.push({
          id: store.state.company.id,
          icon: store.state.company.icon ?? "",
          name: store.state.company.name,
        });
      }
    }
    const adminUsers: AdminUser[] = (await RadialApiClient.listAdminUsers()) ?? [];
    adminUsers.map((adminUser) => {
      if (adminUser.adminUserMasterId !== 3) {
        this.adminUserDetails.push({ id: adminUser.id, icon: adminUser.icon ?? "", name: adminUser.name });
      }
    });

    // custom action masterのテーブルを取得
    this.customActionMasters = (await RadialApiClient.listCustomActionMasters()) ?? [];

    // actionのレコードを取得
    this.actions = (await RadialApiClient.listAllActions()) ?? [];

    // googleアナリティクスにデータを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/product-list",
        page_title: "Admin商品リスト画面",
      });
    } else {
      this.$gtag?.pageview({
        page_path: "/product-list",
        page_title: "Staff商品リスト画面",
      });
    }
    this.isLoading = false;
    this.isTableLoading = false;
    this.isMounting = false;
  },
});
