
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { Auth } from "aws-amplify";
import AuthClient from "@/api/AuthClient";
import router from "@/router/index";
import LocalDataService from "@/store/LocalDataService";
import RadialApiClient from "@/api/RadialApiClient";
import mixin from "@/mixin/mixin";

export default Vue.extend({
  name: "AccountSetting",
  mixins: [mixin],
  components: {
    NavigationDrawer,
  },
  data() {
    return {
      name: "",
      email: "",
      isItem: true,
    };
  },
  computed: {},
  methods: {
    async logoutDidClick() {
      await LocalDataService.clear();
      Auth.signOut().then(() => {
        router.push({ name: this.isAdmin ? "AdminLogin" : "Login" });
      });
    },
  },
  async mounted() {
    const user = await AuthClient.getUserInfo();
    if (await AuthClient.checkAuth(this.isAdmin)) {
      const info = await AuthClient.getUserInfo();
      if (info && user) {
        const company = await RadialApiClient.getCompany(info.companyId);
        if (company) {
          this.name = this.isAdmin ? company.name : user.name;
          this.email = this.isAdmin ? company.email : user.email;
        } else {
          this.name = "Error";
          this.email = "Error";
        }
      }
    } else {
      router.push({ name: this.isAdmin ? "AdminLogin" : "Login" });
    }
  },
});
