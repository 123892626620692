import { render, staticRenderFns } from "./GroupList.vue?vue&type=template&id=8d4db544&scoped=true&"
import script from "./GroupList.vue?vue&type=script&lang=ts&"
export * from "./GroupList.vue?vue&type=script&lang=ts&"
import style1 from "./GroupList.vue?vue&type=style&index=1&id=8d4db544&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d4db544",
  null
  
)

export default component.exports