
import Vue from "vue";
import dayjs from "dayjs";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import S3Image from "@/components/aws/S3Image.vue";
import { DashboardProductRankingEntity, GetMainImagesEntity } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import { DashboardProductRankingDto } from "@/api/dto";
import { User, DashboardStore } from "@/store/model";
import store from "@/store";
import mixin from "@/mixin/mixin";

export interface DataType {
  rangeDates: string[];
  rangeDatesMenu: boolean;
  today: Date;
  cancelDates: string[];
  isCanceled: boolean;
  storeTable: DashboardStore[];
  selectedStores: DashboardStore[];
  rankingItems: DashboardProductRankingEntity[];
  hover: boolean;
  hoverIndex: number | null;
  productImages: GetMainImagesEntity;
  isLoading: boolean;
}

export default Vue.extend({
  name: "dashboard-sale-ranking",
  mixins: [mixin],
  components: {
    ElementRangeDatePicker,
    FilterChipButton,
    S3Image,
  },
  data(): DataType {
    return {
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      rangeDatesMenu: false,
      today: new Date(),
      cancelDates: [],
      isCanceled: false,
      storeTable: [],
      selectedStores: [],
      rankingItems: [
        {
          productId: null,
          name: null,
          itemNumber: null,
          sumDiscountSubtotal: 0,
          sumPositiveCommentsByProductId: null,
          sumNegativeCommentsByProductId: null,
          sumNeutralCommentsByProductId: null,
        },
        {
          productId: null,
          name: null,
          itemNumber: null,
          sumDiscountSubtotal: 0,
          sumPositiveCommentsByProductId: null,
          sumNegativeCommentsByProductId: null,
          sumNeutralCommentsByProductId: null,
        },
        {
          productId: null,
          name: null,
          itemNumber: null,
          sumDiscountSubtotal: 0,
          sumPositiveCommentsByProductId: null,
          sumNegativeCommentsByProductId: null,
          sumNeutralCommentsByProductId: null,
        },
        {
          productId: null,
          name: null,
          itemNumber: null,
          sumDiscountSubtotal: 0,
          sumPositiveCommentsByProductId: null,
          sumNegativeCommentsByProductId: null,
          sumNeutralCommentsByProductId: null,
        },
        {
          productId: null,
          name: null,
          itemNumber: null,
          sumDiscountSubtotal: 0,
          sumPositiveCommentsByProductId: null,
          sumNegativeCommentsByProductId: null,
          sumNeutralCommentsByProductId: null,
        },
      ],
      hover: false,
      hoverIndex: null,
      productImages: { s3KeyProducts: [] },
      isLoading: true,
    };
  },
  props: {
    isTablet: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    minDate(): string {
      return dayjs(this.today).subtract(29, "day").format("YYYY-MM-DD");
    },
  },
  methods: {
    async changeDateRange() {
      this.isCanceled = false;
      this.rangeDatesMenu = false;
      await this.updateRanking();
    },
    async updateRanking() {
      let storeIds = this.selectedStores.filter((store) => store.operating).map((store) => store.id);
      if (this.selectedStores.map((store) => store.id).includes("inoperative")) {
        storeIds = storeIds.concat(this.storeTable.filter((store) => !store.operating).map((store) => store.id));
      }
      const params: DashboardProductRankingDto = {
        start: this.rangeDates[0],
        end: this.rangeDates[1],
        storeIds,
        limit: 5,
      };
      this.rankingItems = (await RadialApiClient.getDashboardRanking(params)) ?? [];

      // get product image s3keys
      const productIds: string[] = this.rankingItems.map((product) => product.productId ?? "");
      const productImages = await RadialApiClient.getProductMainImages({ productIds });
      if (productImages) {
        this.productImages = productImages;
      }
    },
    getCommentTotalCount(item: DashboardProductRankingEntity): number {
      return (
        (item.sumPositiveCommentsByProductId ?? 0) +
        (item.sumNegativeCommentsByProductId ?? 0) +
        (item.sumNeutralCommentsByProductId ?? 0)
      );
    },
    getEvaluationRatio(item: DashboardProductRankingEntity, isPositive: boolean): string {
      const total = this.getCommentTotalCount(item);
      const key: keyof DashboardProductRankingEntity = isPositive
        ? "sumPositiveCommentsByProductId"
        : "sumNegativeCommentsByProductId";
      if (total > 0) {
        return Math.floor((100 * (item[key] ?? 0)) / total).toLocaleString();
      }
      return "0";
    },
    async changeFilter() {
      await this.updateRanking();
    },
    async clearFilter() {
      this.selectedStores = [];
      await this.updateRanking();
    },
    hoverTable(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hover = true;
        this.hoverIndex = index;
      } else if (hoverFlag === 1) {
        this.hover = false;
      }
    },
    showDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    getS3Key(productId: string): string {
      return this.productImages.s3KeyProducts.find((item) => item.productId === productId)?.s3Key ?? "";
    },
  },
  watch: {
    rangeDatesMenu: {
      async handler() {
        if (this.rangeDatesMenu) {
          this.cancelDates = this.rangeDates;
          this.isCanceled = true;
        } else if (!this.rangeDatesMenu && this.isCanceled) {
          this.rangeDates = this.cancelDates;
        }
      },
    },
    isLoading() {
      this.$emit("changeLoadingStatus", this.isLoading);
    },
  },
  async mounted() {
    const storeTable = (await RadialApiClient.listStores()) ?? [];
    this.storeTable = storeTable.filter((store) => store.operating);
    if (this.user) {
      if (!this.isAdmin) {
        const myStore = this.storeTable.find((store) => store.cognitoSub === this.user?.sub);
        if (myStore) {
          this.selectedStores = this.selectedStores.concat(myStore);
        }
      }
    }
    await this.updateRanking();
    this.isLoading = false;
  },
});
