
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import dayjs from "dayjs";
import {
  Season,
  Brand,
  Category,
  SalesBuyingPlanResultInfoEntity,
  BrandCategory,
  SalesBuyingPlanTotalEntity,
  SalesBuyingPlanBrandCategoryEntity,
  SalesBuyingPlanMonthBudgetTotalRateEntity,
  SalesBuyingPlanMonthBudgetRateEntity,
  SalesBuyingPlanMonthBudgetBrandRateEntity,
} from "@/api/entities";
import anum from "@/components/animation/AnimatedNumber.vue";
import RadialApiClient from "@/api/RadialApiClient";
import { BudgetTotalDto, UpdateSalesBuyingPlanBrandCategoryDto } from "@/api/dto";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { User } from "@/store/model";
import { Constant } from "@/store/constant";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

dayjs.extend(isSameOrBefore);

export class BrandCategoryTableTab {
  brand: Brand;
  categories: CategoryTableTab[];
}

export class CategoryTableTab {
  brandCategoryId: string;
  index: number;
  category: Category | null;
}

export class StockedSeasonBrandCategoryDigestRateTarget {
  seasonBrandCategoryId: number;
  digestRateTarget: number;
}

export interface DataType {
  user: User | null;
  seasons: Season[];
  selectedSeason: Season | null;
  brands: Brand[];
  brandCategories: BrandCategory[];
  categories: Category[];
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  budgetTotals: SalesBuyingPlanTotalEntity[];
  budgetBrandCategory: SalesBuyingPlanBrandCategoryEntity | null;
  editMode: boolean;
  isLoading: boolean;
  tableMonths: string[];
  costRateTarget: number | null;
  brandCategoryTableTabs: BrandCategoryTableTab[];
  selectedBrand: BrandCategoryTableTab | null;
  selectedCategory: CategoryTableTab | null;
  defaultBrandCategoryPlan: UpdateSalesBuyingPlanBrandCategoryDto | null;
  defaultSeasonBrandCategoryDigestRateTargets: StockedSeasonBrandCategoryDigestRateTarget[];
  tableFormat: string;
  brandCategoryViews: { id: string; name: string }[];
  allRates: SalesBuyingPlanMonthBudgetTotalRateEntity | null;
  remainedNeededBuyingBudgets: number[];
}

export default Vue.extend({
  name: "SeasonPlan",
  mixins: [mixin],
  components: { NavigationDrawer, anum, ConfirmationDialog, Loading },
  data(): DataType {
    return {
      user: null,
      seasons: [],
      selectedSeason: null,
      brands: [],
      brandCategories: [],
      categories: [],
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      budgetTotals: [],
      budgetBrandCategory: null,
      editMode: false,
      isLoading: false,
      tableMonths: [],
      costRateTarget: null,
      brandCategoryTableTabs: [],
      selectedBrand: null,
      selectedCategory: null,
      defaultBrandCategoryPlan: null,
      defaultSeasonBrandCategoryDigestRateTargets: [],
      tableFormat: "total",
      brandCategoryViews: Constant.BrandCategorySeasonPlanView,
      allRates: null,
      remainedNeededBuyingBudgets: [],
    };
  },
  watch: {
    async selectedSeason() {
      this.editMode = false;
      await this.seasonPlanSearch();
    },
  },
  computed: {},
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getDigestRateTarget(): number {
      // 目標消化率の計算
      // 該当のシーズンの合計定価売上予算 / 合計必要仕入予算
      const totalNeededBuyingBudget = this.budgetTotals.reduce((sumResult, info) => {
        return sumResult + info.neededBuyingBudget;
      }, 0);
      const totalPriceSaleResultBudget = this.budgetTotals.reduce((sumResult, info) => {
        return sumResult + info.priceSalesResultBudget;
      }, 0);
      return totalNeededBuyingBudget === 0
        ? 0
        : Math.round(10 * ((totalPriceSaleResultBudget * 100) / totalNeededBuyingBudget)) / 10;
    },
    getProperDigestRateTarget(): number {
      // プロパー売上予算合計 / 定価売上予算合計 / 目標消化率
      const digestRateTarget = this.getDigestRateTarget();
      const properSalesBudgetTotal = this.budgetTotals.reduce((sumResult, info) => {
        return sumResult + info.properSaleBudget;
      }, 0);
      const priceSalesBudgetTotal = this.budgetTotals.reduce((sumResult, info) => {
        return sumResult + info.priceSalesResultBudget;
      }, 0);
      return priceSalesBudgetTotal === 0
        ? 0
        : Math.round(10 * ((properSalesBudgetTotal / priceSalesBudgetTotal) * digestRateTarget)) / 10;
    },
    // シーズン概要 - 目標原価率を編集した際の再計算処理
    editCostRate() {
      // シーズン全体 - 粗利予算の再計算(全ての月)
      if (this.costRateTarget) {
        for (const budgetTotal of this.budgetTotals) {
          budgetTotal.grossMarginBudget =
            budgetTotal.saleBudget * 1000 - budgetTotal.priceSalesResultBudget * (this.costRateTarget / 100);
        }
      }
    },
    // シーズン全体 - 各種テーブル数値を編集した際の再計算処理
    editTotals(index: number, key: string) {
      // editingにはbudgetTotals内のindex番目のポインタが入る
      const editing = this.budgetTotals[index];
      switch (key) {
        // 目標プロパー売上構成比編集時
        case "properRateTarget": {
          this.recalculateTotalsLoop(editing, index, 0, 3);
          break;
        }
        // 目標OFF率編集時
        case "offRateTarget": {
          this.recalculateTotalsLoop(editing, index, 1, 3);
          break;
        }
      }
    },
    // ブランド×カテゴリ - 目標消化率を編集した際の再計算処理
    editBrandCategoryDigestRate() {
      // ブランド×カテゴリ - 必要仕入れ予算（全ての月）、期首在庫と期末在庫（全ての月）
      if (this.budgetBrandCategory) {
        const totalPriceSaleResultBudget = this.budgetBrandCategory.resultInfo.reduce((sumResult, info) => {
          return sumResult + info.priceSalesResultBudget;
        }, 0);
        const totalNeededBuyingBudget = totalPriceSaleResultBudget / this.budgetBrandCategory.digestRateTarget;
        let lastEndingStock = 0;
        this.budgetBrandCategory.resultInfo.forEach((monthResult) => {
          // 必要仕入予算
          monthResult.neededBuyingBudget = totalNeededBuyingBudget * monthResult.buyingRate;
          // 期首在庫と期末在庫
          monthResult.openingStock = lastEndingStock;
          monthResult.endingStock =
            monthResult.openingStock - monthResult.priceSalesResultBudget + monthResult.neededBuyingBudget;
          lastEndingStock = monthResult.endingStock;
        });
      }
      // シーズン全体 - 必要仕入予算（全ての月）、期首在庫と期末在庫（全ての月）
      this.recalculateNeededBudgetAndStock(0, "all");
    },
    // ブランドカテゴリ - 各種テーブル数値を編集した際の再計算処理
    editBrandCategories(detailIndex: number, key: string) {
      // editingにはbudgetBrandCategory内のdetailIndex番目のポインタが入る
      const editing = this.budgetBrandCategory?.resultInfo[detailIndex];
      if (editing && this.budgetBrandCategory && this.selectedCategory?.brandCategoryId) {
        switch (key) {
          // 売上構成比編集時
          case "brandCategoryRate": {
            // ブランドカテゴリ - 売上予算（該当の月）
            editing.saleBudget = (this.budgetTotals[detailIndex].saleBudget * 1000 * editing.brandCategoryRate) / 100;
            // ブランドカテゴリ - 定価売上予算（該当の月）
            editing.priceSalesResultBudget =
              (this.budgetTotals[detailIndex].priceSalesResultBudget * editing.brandCategoryRate) / 100;
            // ブランドカテゴリ - 必要仕入予算（全ての月）、期首在庫と期末在庫（全ての月）
            const totalPriceSaleResultBudget = this.budgetBrandCategory.resultInfo.reduce((sumResult, info) => {
              return sumResult + info.priceSalesResultBudget;
            }, 0);
            const totalNeededBuyingBudget = totalPriceSaleResultBudget / this.budgetBrandCategory.digestRateTarget;
            let lastEndingStock = 0;
            this.budgetBrandCategory.resultInfo.forEach((monthResult) => {
              monthResult.neededBuyingBudget = totalNeededBuyingBudget * monthResult.buyingRate;
              monthResult.openingStock = lastEndingStock;
              monthResult.endingStock =
                monthResult.openingStock - monthResult.priceSalesResultBudget + monthResult.neededBuyingBudget;
              lastEndingStock = monthResult.endingStock;
            });
            // シーズン全体 - 必要仕入予算（全ての月）、期首在庫と期末在庫（全ての月）
            this.recalculateNeededBudgetAndStock(detailIndex, "all");
          }
          // 仕入構成比編集時
          case "buyingRate": {
            // ブランドカテゴリ - 必要仕入予算（該当の月）
            const totalPriceSaleResultBudget = this.budgetBrandCategory.resultInfo.reduce((sumResult, info) => {
              return sumResult + info.priceSalesResultBudget;
            }, 0);
            editing.neededBuyingBudget =
              (totalPriceSaleResultBudget / this.budgetBrandCategory.digestRateTarget) *
              this.budgetBrandCategory.resultInfo[detailIndex].buyingRate;
            // ブランドカテゴリ - 期首在庫と期末在庫
            let lastEndingStock = 0;
            this.budgetBrandCategory.resultInfo.forEach((monthResult) => {
              monthResult.openingStock = lastEndingStock;
              monthResult.endingStock =
                monthResult.openingStock - monthResult.priceSalesResultBudget + monthResult.neededBuyingBudget;
              lastEndingStock = monthResult.endingStock;
            });
            // シーズン全体 - 必要仕入予算（該当の月）、期首在庫と期末在庫（全ての月）
            this.recalculateNeededBudgetAndStock(detailIndex, "solo");
          }
        }
      }
    },
    // シーズン全体 - 必要仕入予算, 期首在庫と期末在庫の再計算処理
    recalculateNeededBudgetAndStock(index: number, key: string) {
      if (key === "solo") {
        // シーズン全体 - 必要仕入予算（該当の月）
        this.budgetTotals[index].neededBuyingBudget = this.budgetBrandCategory?.resultInfo[index].neededBuyingBudget
          ? this.remainedNeededBuyingBudgets[index] + this.budgetBrandCategory?.resultInfo[index].neededBuyingBudget
          : this.remainedNeededBuyingBudgets[index];
        // シーズン全体 - 期首在庫と期末在庫（全ての月）
        let lastEndingStock = 0;
        this.budgetTotals.forEach((budgetTotal, index) => {
          budgetTotal.openingStock = lastEndingStock;
          budgetTotal.endingStock =
            budgetTotal.openingStock - budgetTotal.priceSalesResultBudget + budgetTotal.neededBuyingBudget;
          lastEndingStock = budgetTotal.endingStock;
        });
      } else if (key === "all") {
        // シーズン全体 - 必要仕入予算（全ての月）、期首在庫と期末在庫（全ての月）
        let lastEndingStock = 0;
        this.budgetTotals.forEach((budgetTotal, index) => {
          budgetTotal.neededBuyingBudget = this.budgetBrandCategory?.resultInfo[index].neededBuyingBudget
            ? this.remainedNeededBuyingBudgets[index] + this.budgetBrandCategory?.resultInfo[index].neededBuyingBudget
            : this.remainedNeededBuyingBudgets[index];
          budgetTotal.openingStock = lastEndingStock;
          budgetTotal.endingStock =
            budgetTotal.openingStock - budgetTotal.priceSalesResultBudget + budgetTotal.neededBuyingBudget;
          lastEndingStock = budgetTotal.endingStock;
        });
      }
    },
    recalculateTotalsLoop(editing: SalesBuyingPlanTotalEntity, pointer: number, start: number, end: number) {
      for (let index = start; index <= end; index++) {
        this.recalculateTotals(editing, pointer, index);
      }
    },
    // シーズン全体 - 各種テーブル数値を編集した際の再計算処理をまとめている
    recalculateTotals(editing: SalesBuyingPlanTotalEntity, index: number, caseNumber: number) {
      switch (caseNumber) {
        // シーズン全体 - プロパー売上予算とセール売上予算の再計算（該当の月）
        case 0: {
          editing.properSaleBudget = (editing.saleBudget * 1000 * editing.properRateTarget) / 100;
          editing.bargainSaleBudget = editing.saleBudget * 1000 * (1 - editing.properRateTarget / 100);
          break;
        }
        // シーズン全体 - 定価売上予算の再計算（該当の月）
        case 1: {
          editing.priceSalesResultBudget =
            editing.properSaleBudget + editing.bargainSaleBudget / (1 - editing.offRateTarget / 100);
          break;
        }
        // ブランドカテゴリ
        case 2: {
          if (this.budgetBrandCategory) {
            // ブランドカテゴリ - 定価売上予算（該当の月）
            this.budgetBrandCategory.resultInfo[index].priceSalesResultBudget =
              (editing.priceSalesResultBudget * this.budgetBrandCategory.resultInfo[index].brandCategoryRate) / 100;
            // ブランドカテゴリ - 必要仕入予算（全ての月）、期初在庫と期末在庫（全ての月）
            const totalPriceSaleResultBudget = this.budgetBrandCategory.resultInfo.reduce((sumResult, info) => {
              return sumResult + info.priceSalesResultBudget;
            }, 0);
            const totalNeededBuyingBudget =
              this.budgetBrandCategory.digestRateTarget === 0
                ? 0
                : totalPriceSaleResultBudget / this.budgetBrandCategory.digestRateTarget;
            let lastEndingStock = 0;
            this.budgetBrandCategory.resultInfo.forEach((monthResult) => {
              monthResult.neededBuyingBudget = totalNeededBuyingBudget * monthResult.buyingRate;
              monthResult.openingStock = lastEndingStock;
              monthResult.endingStock =
                monthResult.openingStock - monthResult.priceSalesResultBudget + monthResult.neededBuyingBudget;
              lastEndingStock = monthResult.endingStock;
            });
            break;
          }
        }
        // シーズン全体 - 必要仕入予算（全ての月）、期首在庫, 期末在庫（全ての月）
        case 3: {
          // シーズン全体 - 必要仕入予算（全ての月）、期首在庫と期末在庫（全ての月）
          let lastEndingStock = 0;
          this.budgetTotals.forEach((budgetTotal, index) => {
            budgetTotal.neededBuyingBudget = this.budgetBrandCategory?.resultInfo[index].neededBuyingBudget
              ? this.remainedNeededBuyingBudgets[index] + this.budgetBrandCategory?.resultInfo[index].neededBuyingBudget
              : this.remainedNeededBuyingBudgets[index];
            budgetTotal.openingStock = lastEndingStock;
            budgetTotal.endingStock =
              budgetTotal.openingStock - budgetTotal.priceSalesResultBudget + budgetTotal.neededBuyingBudget;
            lastEndingStock = budgetTotal.endingStock;
          });
        }
      }
    },
    sumTotal(index: number): number | null {
      switch (index) {
        // 売上予算の合計
        case 0: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.saleBudget * 1000;
          }, 0);
        }
        // 売上実績の合計
        case 1: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.saleResult;
          }, 0);
        }
        // 粗利予算の合計
        case 2: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.grossMarginBudget;
          }, 0);
        }
        // 粗利実績の合計
        case 3: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.grossMarginResult;
          }, 0);
        }
        // プロパー売上予算の合計
        case 4: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.properSaleBudget;
          }, 0);
        }
        // プロパー売上実績の合計
        case 5: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.properSaleResult;
          }, 0);
        }
        // セール売上予算の合計
        case 6: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.bargainSaleBudget;
          }, 0);
        }
        // セール売上実績の合計
        case 7: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.bargainSaleResult;
          }, 0);
        }
        // 定価売上予算の合計
        case 8: {
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.priceSalesResultBudget;
          }, 0);
        }
        // 必要仕入予算の合計
        case 9: {
          // 各月の必要仕入予算の合計
          return this.budgetTotals.reduce((sumResult, info) => {
            return sumResult + info.neededBuyingBudget;
          }, 0);
          break;
        }
        default:
          return null;
      }
      return null;
    },
    sumBrandCategory(budgetCategoryResultInfo: SalesBuyingPlanResultInfoEntity[], index: number): number | null {
      if (budgetCategoryResultInfo) {
        switch (index) {
          // 売上構成比の平均
          case 0: {
            const budgetTotal = this.sumTotal(0);
            const budgetBrandCategory = budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.saleBudget;
            }, 0);
            if (budgetTotal) {
              return (budgetBrandCategory / budgetTotal) * 100;
            } else {
              return null;
            }
          }
          // 売上予算の合計
          case 1: {
            return budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.saleBudget;
            }, 0);
          }
          // 売上実績の合計
          case 2: {
            return budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.saleResultTotal;
            }, 0);
          }
          // 粗利予算の合計
          case 3: {
            return budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.grossMarginBudget;
            }, 0);
          }
          // 粗利実績の合計
          case 4: {
            return budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.grossMarginResult;
            }, 0);
          }
          // 定価売上予算の合計
          case 5: {
            return budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.priceSalesResultBudget;
            }, 0);
          }
          // 仕入構成比の合計
          case 6: {
            return budgetCategoryResultInfo.reduce((sumResult, info) => {
              return sumResult + info.buyingRate;
            }, 0);
          }
          // 必要仕入れ予算の合計
          case 7: {
            const totalPriceSaleResultBudget = this.budgetBrandCategory?.resultInfo.reduce((sumResult, info) => {
              return sumResult + info.priceSalesResultBudget;
            }, 0);
            return totalPriceSaleResultBudget && this.budgetBrandCategory?.digestRateTarget
              ? (100 * totalPriceSaleResultBudget) / this.budgetBrandCategory?.digestRateTarget
              : 0;
          }
          default: {
            return null;
          }
        }
      } else {
        return null;
      }
    },
    async seasonPlanSearch() {
      this.isLoading = true;
      if (this.selectedSeason) {
        // テーブルヘッダーの取得
        if (this.tableMonths.length === 0) {
          let startTableMonth = dayjs(this.selectedSeason.startYearMonth);
          const endTableMonth = dayjs(this.selectedSeason.endYearMonth);
          while (startTableMonth.isSameOrBefore(endTableMonth)) {
            this.tableMonths.push(startTableMonth.format("YY/M"));
            startTableMonth = startTableMonth.add(1, "M");
          }
        }
        // 合計の検索
        const budgetTotals = await RadialApiClient.getSalesBuyingPlanTotalEntity(this.selectedSeason.id).catch(
          (error) => {
            if (error.response.data.statusCode === 600) {
              this.showAlert(error.response.data.message, "error");
            }
          }
        );
        if (budgetTotals) {
          this.budgetTotals = budgetTotals;
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
        }
        // 合計の売上予算をk単位に変換
        this.budgetTotals.forEach((budgetTotal) => {
          budgetTotal.saleBudget = budgetTotal.saleBudget / 1000;
        });
        const dto: BudgetTotalDto[] = this.budgetTotals.map((budgetTotal) => {
          return {
            seasonPlanId: budgetTotal.seasonPlanId,
            yearMonth: budgetTotal.yearMonth,
            saleBudget: budgetTotal.saleBudget * 1000,
            priceSalesResultBudget: budgetTotal.priceSalesResultBudget,
          };
        });
        // ブランドカテゴリの検索
        const budgetBrandCategory = await RadialApiClient.getSalesBuyingPlanBrandCategoryEntity(
          this.selectedSeason.id,
          this.selectedCategory?.brandCategoryId ?? "",
          this.selectedBrand?.brand.id ?? 0,
          dto
        ).catch((error) => {
          if (error.response.data.statusCode === 600) {
            this.showAlert(error.response.data.message, "error");
          }
        });
        if (budgetBrandCategory) {
          this.budgetBrandCategory = budgetBrandCategory;
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
        }
      }
      // TODO: 削除
      // this.updateRemainedNeededBuyingBudgets();
      this.isLoading = false;
    },
    updateRemainedNeededBuyingBudgets() {
      this.remainedNeededBuyingBudgets = this.budgetTotals.map((budgetTotal, index) => {
        const brandCategoryNeededBuyingBudget = this.budgetBrandCategory?.resultInfo[index]?.neededBuyingBudget;
        return brandCategoryNeededBuyingBudget
          ? budgetTotal.neededBuyingBudget - brandCategoryNeededBuyingBudget
          : budgetTotal.neededBuyingBudget;
      });
    },
    async submitSeasonBudget() {
      this.isLoading = true;
      if (this.selectedSeason) {
        // シーズンの目標原価率の更新処理
        await RadialApiClient.updateSeason(this.selectedSeason.id, {
          name: this.selectedSeason.name,
          refSeason:
            this.selectedSeason.refSeasonId !== null
              ? {
                  connect: { id: Number(this.selectedSeason.refSeasonId) },
                }
              : undefined,
          costRateTarget: this.costRateTarget ?? 0,
        });
        // ブランドカテゴリの目標消化率の更新処理
        await RadialApiClient.updateSeasonBrandCategory(Number(this.budgetBrandCategory?.seasonBrandCategoryId), {
          digestRateTarget: this.budgetBrandCategory?.digestRateTarget,
        });
        // 予算合計の更新処理
        await RadialApiClient.updateSalesBuyingPlanTotalDto({
          seasonId: this.selectedSeason.id,
          budgetTotalInfos: this.budgetTotals.map((budgetTotal) => {
            return {
              seasonPlanId: budgetTotal.seasonPlanId,
              properRateTarget: budgetTotal.properRateTarget,
              offRateTarget: budgetTotal.offRateTarget,
            };
          }),
        })
          .then(() => {
            this.updateBrandCategoryPlan()
              .then(() => {
                this.showAlert(
                  "無事に更新が完了しました！\nダッシュボード画面には24時間以内に反映されます。\n以下のページでは数分以内に最新の予算データを確認することができます。\n\n・店舗-StoreActuals\n・店舗-日報\n・MD計画-店舗シーズン売上計画\n・店舗日別予算設定",
                  "success"
                );
              })
              .catch(() => {
                this.showAlert(
                  `エラーが発生しております。
                  入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
                  "error"
                );
              });
          })
          .catch(() => {
            this.showAlert(
              `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error"
            );
          });
      }
      this.editMode = false;
      this.isLoading = false;
    },
    async updateBrandCategoryPlan(): Promise<string> {
      // ブランドカテゴリ = 目標消化率、売上構成比及び仕入構成比の更新処理(合計タブにいるときの更新処理は除く)
      if (this.selectedSeason && this.budgetBrandCategory?.brandCategoryId !== "total" && this.editMode) {
        try {
          await Promise.all([
            (async () => {
              // ブランドカテゴリ - 目標消化率の更新
              await RadialApiClient.updateSeasonBrandCategory(Number(this.budgetBrandCategory?.seasonBrandCategoryId), {
                digestRateTarget: this.budgetBrandCategory?.digestRateTarget,
              });
            })(),
            (async () => {
              // ブランドカテゴリ - 売上構成比及び仕入構成比の更新
              await RadialApiClient.updateSalesBuyingPlanBudgetBrandCategoryDto({
                seasonId: this.selectedSeason?.id ?? 0,
                budgetBrandCategoryInfos: [
                  {
                    brandCategoryId: this.selectedCategory?.brandCategoryId ?? "",
                    digestRateTarget: this.budgetBrandCategory?.digestRateTarget ?? 0,
                    budgetCategoryInfos:
                      this.budgetBrandCategory?.resultInfo.map((brandCategoryYearMonthPlan) => {
                        return {
                          seasonBrandCategoryPlanId: brandCategoryYearMonthPlan.seasonBrandCategoryPlanId as number,
                          brandCategoryRate: brandCategoryYearMonthPlan.brandCategoryRate,
                          buyingRate: brandCategoryYearMonthPlan.buyingRate,
                          yearMonth: dayjs(brandCategoryYearMonthPlan.yearMonth, "YY/MM").format("YYYY-MM"),
                        };
                      }) ?? [],
                  },
                ],
              });
            })(),
          ]);
        } catch (error) {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
        }
      }
      return "updated";
    },
    async deselectSeasonBudget() {
      this.isLoading = true;
      this.editMode = false;
      if (this.selectedSeason && this.defaultBrandCategoryPlan) {
        // ブランドカテゴリ - 売上構成比及び仕入構成をデフォルトに更新
        await RadialApiClient.updateSalesBuyingPlanBudgetBrandCategoryDto(this.defaultBrandCategoryPlan);
        // ブランドカテゴリ - 目標消化率の数値をデフォルトに更新（TODO: 一括更新APIを今後作成する）
        this.defaultSeasonBrandCategoryDigestRateTargets.forEach(async (info) => {
          await RadialApiClient.updateSeasonBrandCategory(Number(info.seasonBrandCategoryId), {
            digestRateTarget: info.digestRateTarget,
          });
        });
        this.costRateTarget = this.selectedSeason.costRateTarget;
        await this.seasonPlanSearch();
      }
      this.isLoading = false;
    },
    changeEditMode() {
      this.editMode = true;
      // ブランドカテゴリデフォルト情報のリセット
      if (this.defaultBrandCategoryPlan?.budgetBrandCategoryInfos) {
        this.defaultBrandCategoryPlan.budgetBrandCategoryInfos = [];
        this.defaultSeasonBrandCategoryDigestRateTargets = [];
      }
      // 現在のブランドカテゴリ情報の保存（合計タブ情報は編集不可であるため保存しない）
      if (
        this.defaultBrandCategoryPlan &&
        this.budgetBrandCategory &&
        this.selectedCategory?.brandCategoryId !== "total"
      ) {
        this.defaultSeasonBrandCategoryDigestRateTargets.push({
          seasonBrandCategoryId: Number(this.budgetBrandCategory.seasonBrandCategoryId),
          digestRateTarget: this.budgetBrandCategory.digestRateTarget,
        });
        this.defaultBrandCategoryPlan?.budgetBrandCategoryInfos.push({
          brandCategoryId: this.budgetBrandCategory?.brandCategoryId ?? "",
          digestRateTarget: this.budgetBrandCategory.digestRateTarget ?? 0,
          budgetCategoryInfos: this.budgetBrandCategory?.resultInfo.map((brandCategoryYearMonthPlan) => {
            return {
              seasonBrandCategoryPlanId: brandCategoryYearMonthPlan.seasonBrandCategoryPlanId as number,
              brandCategoryRate: brandCategoryYearMonthPlan.brandCategoryRate,
              buyingRate: brandCategoryYearMonthPlan.buyingRate,
              yearMonth: dayjs(brandCategoryYearMonthPlan.yearMonth, "YY/MM").format("YYYY-MM"),
            };
          }),
        });
      }
      // シーズン全体の必要仕入予算とブランドカテゴリの必要仕入予算の差分を保存
      this.updateRemainedNeededBuyingBudgets();
    },
    async changePlanMode() {
      this.isLoading = true;
      this.allRates = null;
      if (this.editMode) {
        // 編集モード中にビューを切り替えた場合に売上構成比及び仕入構成比の一時保存（移動先のビューでのデータ反映）
        this.updateBrandCategoryPlan().then(async () => {
          if (this.selectedSeason) {
            const rates =
              this.tableFormat === "buying"
                ? await RadialApiClient.getSalesBuyingPlanBuyingRate(this.selectedSeason.id)
                : this.tableFormat === "sale"
                ? await RadialApiClient.getSalesBuyingPlanSaleRate(this.selectedSeason.id)
                : null;
            if (rates || this.tableFormat === "total") {
              this.allRates = rates;
            } else {
              this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
            }
          }
        });
      } else {
        if (this.selectedSeason) {
          const rates =
            this.tableFormat === "buying"
              ? await RadialApiClient.getSalesBuyingPlanBuyingRate(this.selectedSeason.id)
              : this.tableFormat === "sale"
              ? await RadialApiClient.getSalesBuyingPlanSaleRate(this.selectedSeason.id)
              : null;
          if (rates || this.tableFormat === "total") {
            this.allRates = rates;
          } else {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          }
        }
      }
      this.isLoading = false;
    },
    getBrandName(id: number): string {
      const brand = this.brands.find((brand) => brand.id === id);
      return brand?.name ?? "";
    },
    getCategoryName(id: number): string {
      const category = this.categories.find((category) => category.id === id);
      return category?.name ?? "";
    },
    getBrandTotalNumber(brandRate: SalesBuyingPlanMonthBudgetRateEntity[]): number {
      if (this.tableFormat === "buying") {
        return (
          Math.round(
            100 *
              brandRate.reduce((sumResult, info) => {
                return sumResult + info.rate;
              }, 0)
          ) / 100
        );
      } else if (this.tableFormat === "sale") {
        // 売上構成比における合計 = 該当のブランドの合計売上予算 / シーズン全体の合計売上予算
        const budgetTotal = this.budgetTotals.reduce((sumResult, info) => {
          return sumResult + info.saleBudget;
        }, 0);
        const budgetCategoryTotal = this.budgetTotals.reduce((sumResult, info, index) => {
          return sumResult + (info.saleBudget * brandRate[index]?.rate) / 100;
        }, 0);
        return budgetTotal === 0 ? 0 : Math.round((100 * (100 * budgetCategoryTotal)) / budgetTotal) / 100;
      } else {
        return 0;
      }
    },
    getCategoryTotalNumber(categoryRate: SalesBuyingPlanMonthBudgetRateEntity[]): number {
      if (this.tableFormat === "buying") {
        return (
          Math.round(
            100 *
              categoryRate.reduce((sumResult, info) => {
                return sumResult + info.rate;
              }, 0)
          ) / 100
        );
      } else if (this.tableFormat === "sale") {
        // 売上構成比における合計 = 該当のカテゴリーの合計売上予算 / シーズン全体の合計売上予算
        const budgetTotal = this.budgetTotals.reduce((sumResult, info) => {
          return sumResult + info.saleBudget;
        }, 0);
        const budgetCategoryTotal = this.budgetTotals.reduce((sumResult, info, index) => {
          return sumResult + (info.saleBudget * categoryRate[index]?.rate) / 100;
        }, 0);
        return budgetTotal === 0 ? 0 : Math.round((100 * (100 * budgetCategoryTotal)) / budgetTotal) / 100;
      } else {
        return 0;
      }
    },
    getBrandCategoryTotalNumber(seasonRate: SalesBuyingPlanMonthBudgetRateEntity[]): number {
      if (this.tableFormat === "buying") {
        return (
          Math.round(
            100 *
              seasonRate.reduce((sumResult, info) => {
                return sumResult + info.rate;
              }, 0)
          ) / 100
        );
      } else if (this.tableFormat === "sale") {
        // 売上構成比における合計 = 該当のブランド×カテゴリの合計売上予算 / シーズン全体の合計売上予算
        const budgetTotal = this.budgetTotals.reduce((sumResult, info) => {
          return sumResult + info.saleBudget;
        }, 0);
        const budgetCategoryTotal = this.budgetTotals.reduce((sumResult, info, index) => {
          return sumResult + (info.saleBudget * seasonRate[index]?.rate) / 100;
        }, 0);
        return budgetTotal === 0 ? 0 : Math.round((100 * (100 * budgetCategoryTotal)) / budgetTotal) / 100;
      } else {
        return 0;
      }
    },
    stockDefaultBrandCategoryPlan() {
      // 各ブランド×カテゴリ別のデフォルトの売上構成比及び仕入構成比をストックさせていく
      if (this.editMode && this.budgetBrandCategory && this.defaultBrandCategoryPlan) {
        // 予算修正中にタブを切り替えた場合に直前にブランドカテゴリ情報を配列に追加
        if (
          !this.defaultBrandCategoryPlan.budgetBrandCategoryInfos.some(
            (budgetBrandCategoryInto) =>
              budgetBrandCategoryInto.brandCategoryId === this.budgetBrandCategory?.brandCategoryId
          )
        ) {
          this.defaultSeasonBrandCategoryDigestRateTargets.push({
            seasonBrandCategoryId: Number(this.budgetBrandCategory.seasonBrandCategoryId),
            digestRateTarget: this.budgetBrandCategory.digestRateTarget,
          });
          this.defaultBrandCategoryPlan?.budgetBrandCategoryInfos.push({
            brandCategoryId: this.budgetBrandCategory?.brandCategoryId ?? "",
            digestRateTarget: this.budgetBrandCategory.digestRateTarget ?? 0,
            budgetCategoryInfos: this.budgetBrandCategory?.resultInfo.map((brandCategoryYearMonthPlan) => {
              return {
                seasonBrandCategoryPlanId: brandCategoryYearMonthPlan.seasonBrandCategoryPlanId as number,
                brandCategoryRate: brandCategoryYearMonthPlan.brandCategoryRate,
                buyingRate: brandCategoryYearMonthPlan.buyingRate,
                yearMonth: dayjs(brandCategoryYearMonthPlan.yearMonth, "YY/MM").format("YYYY-MM"),
              };
            }),
          });
        }
      }
    },
    async changeBrandOrCategoryTab(tab: BrandCategoryTableTab | CategoryTableTab) {
      this.isLoading = true;
      // ブランドカテゴリ - 売上構成比及び仕入構成比の一時更新
      // シーズン全体 - 更新された構成比をもとにした再検索処理（TODO: もしかしたら不要かも）
      if (this.selectedSeason) {
        // ブランドカテゴリ - 目標消化率の一時更新
        // ブランドカテゴリ - 売上構成比及び仕入構成の一時更新
        this.updateBrandCategoryPlan().then(async () => {
          // タブの更新
          if (tab instanceof BrandCategoryTableTab) {
            this.selectedBrand = tab;
            this.selectedCategory = tab.categories[0];
          } else {
            // totalタブに遷移時はCategoryTableTab型にしていないためこれ以上分岐が必要な場合はmountedの処理更新が必要
            this.selectedCategory = tab;
          }
          // ブランドカテゴリ - 更新された構成比をもとにした再検索処理
          const dto: BudgetTotalDto[] = this.budgetTotals.map((budgetTotal) => {
            return {
              seasonPlanId: budgetTotal.seasonPlanId,
              yearMonth: budgetTotal.yearMonth,
              saleBudget: budgetTotal.saleBudget * 1000,
              priceSalesResultBudget: budgetTotal.priceSalesResultBudget,
            };
          });
          const budgetBrandCategory = await RadialApiClient.getSalesBuyingPlanBrandCategoryEntity(
            this.selectedSeason?.id ?? 0,
            this.selectedCategory.brandCategoryId,
            this.selectedBrand?.brand.id ?? 0,
            dto
          ).catch((error) => {
            if (error.response.data.statusCode === 600) {
              this.showAlert(error.response.data.message, "error");
            }
          });
          if (budgetBrandCategory) {
            this.budgetBrandCategory = budgetBrandCategory;
            // TODO: 場所問題ないかテスト
            // 遷移先のタブにおける、シーズン全体の必要仕入予算とブランドカテゴリの必要仕入予算の差分を保存
            if (this.editMode) {
              this.updateRemainedNeededBuyingBudgets();
            }
          } else {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          }
          // ブランドカテゴリ - 移動先のタブにおける、売上構成比と仕入構成比のデフォルト値をセット
          if (this.selectedCategory?.brandCategoryId !== "total") {
            this.stockDefaultBrandCategoryPlan();
          }
        });
      }
      this.isLoading = false;
    },
    getSeasonRange(): string {
      if (this.selectedSeason) {
        return `${dayjs(this.selectedSeason.startYearMonth).format("YY/M")}〜${dayjs(
          this.selectedSeason.endYearMonth
        ).format("YY/M")}`;
      } else {
        return "";
      }
    },
    getRefSeasonName(): string {
      return this.seasons.find((season) => season.id === this.selectedSeason?.refSeasonId)?.name ?? "未選択";
    },
    async showSeasons() {
      if (this.editMode && this.defaultBrandCategoryPlan) {
        // 編集モードから遷移する場合
        // ブランドカテゴリ - 売上構成比及び仕入構成をデフォルトに更新
        await RadialApiClient.updateSalesBuyingPlanBudgetBrandCategoryDto(this.defaultBrandCategoryPlan);
        // ブランドカテゴリ - 目標消化率の数値をデフォルトに更新（TODO: 一括更新APIを今後作成する）
        this.defaultSeasonBrandCategoryDigestRateTargets.forEach(async (info) => {
          await RadialApiClient.updateSeasonBrandCategory(Number(info.seasonBrandCategoryId), {
            digestRateTarget: info.digestRateTarget,
          });
        });
      }
      const routeData = this.$router.resolve({
        name: "AdminSeasons",
      });
      window.open(routeData.href, "_self");
    },
    showRefSeasonPlan(seasonId: string) {
      const routeData = this.$router.resolve({
        name: "AdminSeasonPlan",
        params: { seasonId },
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      await Promise.all([
        (async () => {
          this.seasons = (await RadialApiClient.listSeasons()) ?? [];
        })(),
        (async () => {
          this.brands = (await RadialApiClient.listBrands()) ?? [];
        })(),
        (async () => {
          this.brandCategories = (await RadialApiClient.listBrandCategories()) ?? [];
        })(),
        (async () => {
          this.categories = (await RadialApiClient.listCategories()) ?? [];
        })(),
        (async () => {
          this.seasons = (await RadialApiClient.listSeasons()) ?? [];
        })(),
      ]);
      const seasonId = this.$route.params.seasonId;
      if (this.seasons.find((season) => season.id === Number(seasonId))) {
        this.selectedSeason = this.seasons.find((season) => season.id === Number(seasonId)) ?? null;
      }
      this.brandCategoryTableTabs = this.brands
        .filter((brand) => {
          return this.brandCategories.some((brandCategory) => brandCategory.brandId === brand.id);
        })
        .map((filteredBrand) => {
          const categoryTabs: CategoryTableTab[] = this.brandCategories
            .filter((brandCategory) => brandCategory.brandId === filteredBrand.id)
            .map((filteredBrandCategory) => {
              const newCategory = new CategoryTableTab();
              newCategory.brandCategoryId = filteredBrandCategory.id;
              newCategory.index = filteredBrandCategory.index;
              newCategory.category =
                this.categories.find((category) => category.id === filteredBrandCategory.categoryId) ?? null;
              return newCategory;
            })
            .sort((a, b) => {
              if (a.index > b.index) {
                return 1;
              } else {
                return -1;
              }
            });
          // categoryTabs.unshift({
          //   brandCategoryId: "total",
          //   index: 0,
          //   category: null,
          // });
          const newBrandCategory = new BrandCategoryTableTab();
          newBrandCategory.brand = filteredBrand;
          newBrandCategory.categories = categoryTabs;
          return newBrandCategory;
        });
      this.selectedBrand = this.brandCategoryTableTabs[0];
      this.selectedCategory = this.brandCategoryTableTabs[0].categories[0];
    }
    if (this.selectedSeason) {
      // 目標原価率のセット
      this.costRateTarget = this.selectedSeason.costRateTarget;
      // ブランドカテゴリデフォルト数値のリセット
      this.defaultBrandCategoryPlan = {
        seasonId: this.selectedSeason.id,
        budgetBrandCategoryInfos: [],
      };
    }
    this.isLoading = false;
  },
});
