
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { Storage } from "aws-amplify";
import LocalDataService from "@/store/LocalDataService";
import RadialApiClient from "@/api/RadialApiClient";
import { Company, ImageCheckResultEntity, AdminUser } from "@/api/entities";
import { ImageUploadDto } from "@/api/dto";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import mixin from "@/mixin/mixin";

export interface DataType {
  user: User | null;
  isLoading: boolean;
  showingAlert: boolean;
  files: File[];
  imageNames: string[];
  count: number;
  allCount: number;
  checkMessage: ImageCheckResultEntity;
  companyAdminUser: Company | AdminUser | null;
  alertMessage: string;
  alertType: string;
}

export default Vue.extend({
  name: "ImageDataUpload",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
  },
  data(): DataType {
    return {
      user: null,
      isLoading: false,
      showingAlert: false,
      files: [],
      imageNames: [],
      count: 0,
      allCount: 0,
      checkMessage: { errors: [], status: 2 }, // statusが2の時がinitializeされた状態
      companyAdminUser: null,
      alertMessage: "",
      alertType: "success",
    };
  },
  computed: {
    buttonTitle(): string {
      if (this.companyAdminUser) {
        switch (this.companyAdminUser.imageProgress) {
          case 100:
            return "ファイルを送信";
          case 0:
            return "ファイルを送信";
          case -1:
            return "アップロード中にエラーが起きています";
          default:
            return "ファイルを送信中...";
        }
      }
      return "";
    },
    newImageLength(): number {
      return this.files.length - this.updateImageLength;
    },
    updateImageLength(): number {
      return [...new Set(this.files)].filter((file) => this.imageNames.includes(file.name.split(".")[0])).length;
    },
    progress(): number {
      if (this.allCount > 0) {
        return Math.ceil((this.count * 100) / this.allCount);
      }
      return 100;
    },
    isRoot(): boolean {
      return this.user?.sub === this.user?.companyId;
    },
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async select(event: Event) {
      this.isLoading = true;
      if (event.target) {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
          // for (const file of target.files) {
          //   if (!this.files.some(item => item.name === file.name)) {
          //     this.files.push(file);
          //   }
          // }
          this.files = [];
          for (const file of target.files) {
            this.files.push(file);
          }
          const dto: ImageUploadDto = {
            fileNames: this.files.map((file) => file.name.normalize('NFKC').replace(/\.[^/.]+$/, "")),
          };
          const checkImagesResult = await RadialApiClient.checkImages(dto);
          if (checkImagesResult) {
            this.checkMessage = checkImagesResult;
          }
        }
      }
      this.isLoading = false;
    },
    removeFile(file: File) {
      this.files = this.files.filter((item) => item.name !== file.name);
    },
    async upload() {
      if (this.user) {
        this.isLoading = true;
        //new version
        for (const file of this.files) {
          // upload image file to s3
          const name = file.name.normalize('NFKC').replace(/\.[^/.]+$/, "");
          Storage.put(`image/${this.user.companyId}/${name}`, file, {
            contentType: file.name.split(".").pop(),
          });
        }
        const dto: ImageUploadDto = {
          fileNames: this.files.map((file) => {
            const name = file.name.normalize('NFKC').replace(/\.[^/.]+$/, "");
            return name;
          }),
        };
        await RadialApiClient.uploadImages(dto);

        // old version
        const promises: Promise<any>[] = [];
        this.count = 0;
        for (const file of this.files) {
          if (file && file.name) {
            let contentType = "image/jpeg";
            if (file.name.endsWith("png")) {
              contentType = "image/png";
            }
            const name = file.name.replace(/\.[^/.]+$/, "");
            promises.push(
              Storage.put(`${this.user.companyId}/${name}`, file, {
                contentType: contentType,
              }).then(() => {
                this.count += 1;
              })
            );
          }
        }

        // default
        this.allCount = promises.length;
        Promise.all(promises).then(() => {
          this.files = [];
          if (this.allCount >= 100) {
            // 多くの新規や更新件数がある場合は「大変おつかれさまでした。」でねぎらってあげる
            this.showAlert(
              `画像のアップロードが完了しました！
               大変おつかれさまでした。商品リストや詳細ページにてご確認いただけます。`,
              "success"
            );
          } else {
            // 新規や更新件数が少ない場合はシンプルにアップロードが成功しましたモーダルを表示する
            this.showAlert(
              `画像のアップロードが完了しました！
               商品リストや詳細ページにてご確認いただけます。`,
              "success"
            );
          }
          this.count = 0;
          this.allCount = 0;
          this.isLoading = false;
          this.checkMessage = { errors: [], status: 2 };
          if (this.user) {
            LocalDataService.updateImageKeys(this.user.companyId);
          }
        });
      }
    },
    getCsvErrorMessage(index: number): string {
      let message = this.checkMessage.errors[index].message;
      for (const fileName of this.checkMessage.errors[index].fileNames) {
        message = message.concat(`\n・${fileName}`);
      }
      return message;
    },
  },
  async mounted() {
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      if (this.isRoot) {
        this.companyAdminUser = await RadialApiClient.getCompany(this.user.sub);
      } else {
        this.companyAdminUser = await RadialApiClient.getAdminUser(this.user.sub);
      }
      const imageKeys = await LocalDataService.updateImageKeys(this.user.companyId);
      for (const image of imageKeys) {
        this.imageNames.push(image.split("/")[1]);
      }
    }
  },
});
