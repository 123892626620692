
import Vue from "vue";
import dayjs from "dayjs";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import {
  Factory,
  Warehouse,
  GetMainImagesEntity,
  OrderProductStockedStatus,
  OrderInstructionSearchResultEntity,
  OrderProductSearchResultEntity,
} from "@/api/entities";
import AuthClient from "@/api/AuthClient";
import { User, OrderProductStatusDetail, OrderInstructionDraft } from "@/store/model";
import RadialApiClient from "@/api/RadialApiClient";
import { Constant } from "@/store/constant";
import mixin from "@/mixin/mixin";

export interface DataType {
  isAdmin: boolean;
  isLoading: boolean;
  user: User | null;
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  isDeleting: boolean;
  orderInstructionSummaries: OrderInstructionSearchResultEntity[];
  selectedOrderInstructions: string[];
  // selectedOrderInstructionsStatus: OrderInstructionStatus;
  activeItem: OrderInstructionSearchResultEntity | null;
  // orderInstructionStatus: OrderInstructionStatusDetail[];
  warehouses: Warehouse[];
  factories: Factory[];
  orderedRangeDates: string[];
  orderedRangeDatesMenu: boolean;
  stockedRangeDates: string[];
  stockedRangeDatesMenu: boolean;
  actionOption: string | null;
  actionOptions: string[];
  orderProductSummaries: OrderProductSearchResultEntity[];
  selectedOrderProducts: number[];
  selectedOrderProductsStatus: OrderProductStockedStatus;
  dialogStatusUpdate: boolean;
  dialogInstructionCreate: boolean;
  orderInstructionToCreate: OrderInstructionDraft | null;
  orderProductStatus: OrderProductStatusDetail[];
  productImages: GetMainImagesEntity;
}

export default Vue.extend({
  name: "Orders",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    ElementRangeDatePicker,
    ElementDatePicker,
  },
  data(): DataType {
    return {
      isAdmin: false,
      isLoading: false,
      user: null,
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      isDeleting: false,
      orderInstructionSummaries: [],
      selectedOrderInstructions: [],
      // selectedOrderInstructionsStatus: "ORDERED",
      activeItem: null,
      // orderInstructionStatus: Constant.OrderInstructionStatus,
      warehouses: [],
      factories: [],
      orderedRangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      orderedRangeDatesMenu: false,
      stockedRangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      stockedRangeDatesMenu: false,
      actionOption: "アクション",
      actionOptions: ["ステータス更新"],
      orderProductSummaries: [],
      selectedOrderProducts: [],
      // TODO 選択された発注品番をもとに更新
      selectedOrderProductsStatus: "ORDERED",
      dialogStatusUpdate: false,
      dialogInstructionCreate: false,
      orderInstructionToCreate: null,
      orderProductStatus: Constant.OrderProductStatus,
      productImages: { s3KeyProducts: [] },
    };
  },
  watch: {
    dialogInstructionCreate() {
      if (this.dialogInstructionCreate) {
        this.orderInstructionToCreate = {
          orderNumber: "",
          factoryId: null,
          companyId: this.user?.companyId ?? "",
          orderedDate: new Date(),
          stockedDate: new Date(),
          status: "PREORDER",
          warehouseId: null,
        };
      } else {
        this.orderInstructionToCreate = null;
      }
    },
  },
  computed: {
    min() {
      // TODO: 発注ベースか納品ベースか仕様決め（プロパティ名も合わせて変更）
      return "2021-01-01";
    },
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getFactoryName(factoryId: string): string {
      return this.factories.find((factory) => factory.id === factoryId)?.name ?? "";
    },
    getWarehouseName(warehouseId: string): string {
      return this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name ?? "";
    },
    getOrderInstructionStatus(status: string): string {
      switch (status) {
        case "PREORDER":
          return "未定";
        case "ORDERED":
          return "発注済み";
        case "PARTLYSTOCKED":
          return "一部納品済み";
        case "STOCKED":
          return "納品済み";
        case "DROP":
          return "発注中止";
        default:
          return "";
      }
    },
    listActive(item: OrderInstructionSearchResultEntity) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    showDetailInNewTab(orderInstructionId: string) {
      const routeData = this.$router.resolve({
        name: "AdminOrderInstructionDetail",
        params: { orderInstructionId },
      });
      window.open(routeData.href, "_blank");
    },
    deselectOrderInstructions() {
      this.selectedOrderInstructions = [];
      // this.dialogStatusUpdate = false;
    },
    async selectOrderInstruction(summary: OrderInstructionSearchResultEntity) {
      const result = await RadialApiClient.downloadOrderInstruction(summary.orderInstructionId);
    },
    async deleteOrderInstructions() {
      this.isDeleting = true;
      // TODO: 発注指示書一括削除の追加
      if (this.selectedOrderInstructions) {
        this.showAlert("無事に更新が完了しました！", "success");
        this.selectedOrderInstructions = [];
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      this.getOrderInstructions();
      this.showingAlert = false;
      this.isDeleting = false;
    },
    formatInfoDate(date: Date | null): string {
      if (date) {
        return dayjs(date).format("YYYY/M/D");
      }
      return "-";
    },
    async getOrderInstructions() {
      const orderInstructionSummaries = await RadialApiClient.getOrderInstructions({
        orderedDate: {
          start: this.orderedRangeDates[0],
          end: this.orderedRangeDates[1],
        },
        stockedDate: {
          start: this.stockedRangeDates[0],
          end: this.stockedRangeDates[1],
        },
      });
      if (orderInstructionSummaries) {
        this.orderInstructionSummaries = orderInstructionSummaries;
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    },
    async changeDateRange() {
      this.isLoading = true;
      this.orderedRangeDatesMenu = false;
      this.stockedRangeDatesMenu = false;
      this.getOrderInstructions();
      this.getOrderProducts();
      this.isLoading = false;
    },
    actionSelected() {
      if (this.actionOption === "ステータス更新") {
        // this.dialogStatusUpdate = true;
      } else if (this.actionOption === "発注指示書の作成") {
        this.dialogInstructionCreate = true;
      }
    },
    showProductInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: "AdminProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    deselectOrderProducts() {
      this.selectedOrderProductsStatus = "ORDERED";
      this.selectedOrderProducts = [];
      this.dialogStatusUpdate = false;
    },
    async createOrderInstruction() {
      if (
        this.user &&
        this.orderInstructionToCreate &&
        this.orderInstructionToCreate.warehouseId &&
        this.orderInstructionToCreate.factoryId
      ) {
        await RadialApiClient.createOrderInstruction({
          orderNumber: this.orderInstructionToCreate.orderNumber,
          orderedDate: this.orderInstructionToCreate.orderedDate,
          stockedDate: this.orderInstructionToCreate.stockedDate,
          company: { connect: { id: this.user.companyId } },
          warehouse: { connect: { id: this.orderInstructionToCreate.warehouseId } },
          factory: { connect: { id: this.orderInstructionToCreate.factoryId } },
        });
        this.showAlert("発注指示書を作成しました！", "success");
        this.selectedOrderProductsStatus = "ORDERED";
        this.selectedOrderProducts = [];
      }
      this.dialogInstructionCreate = false;
    },
    async getOrderProducts() {
      const orderProductSummaries = await RadialApiClient.getOrderProducts({
        orderedDate: {
          start: this.orderedRangeDates[0],
          end: this.orderedRangeDates[1],
        },
        stockedDate: {
          start: this.stockedRangeDates[0],
          end: this.stockedRangeDates[1],
        },
      });
      if (orderProductSummaries) {
        this.orderProductSummaries = orderProductSummaries;
        const productIds: string[] = this.orderProductSummaries.map((item) => item.productId);
        const productImages = await RadialApiClient.getProductMainImages({ productIds });
        if (productImages) {
          this.productImages = productImages;
        }
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    },
    getS3Key(productId: string): string {
      return this.productImages.s3KeyProducts.find((item) => item.productId === productId)?.s3Key ?? "";
    },
  },
  async mounted() {
    this.isLoading = true;
    this.isAdmin = this.$route.path.startsWith("/admin");
    if (this.isAdmin) {
      this.user = await AuthClient.getUserInfo();
    }
    this.getOrderInstructions();
    this.getOrderProducts();
    this.warehouses = (await RadialApiClient.listWarehouses()) ?? [];
    this.factories = (await RadialApiClient.listFactories()) ?? [];
    this.isLoading = false;
  },
});
