
import Vue from "vue";
import { Storage } from "aws-amplify";

export interface DataType {
  url: string | null;
  error: string;
}

export default Vue.extend({
  name: "S3Image",
  props: {
    imagePath: {
      type: String,
      required: false,
    },
    width: {
      type: String || Number,
      required: true,
    },
    height: {
      type: String || Number,
      required: true,
    },
  },
  data(): DataType {
    return {
      url: null,
      error: "",
    };
  },
  watch: {
    imagePath() {
      this.getImage();
    },
  },
  mounted() {
    this.getImage();
  },
  methods: {
    async getImage() {
      if (!this.imagePath) {
        this.url = null;
        return;
      }
      if (
        this.imagePath.startsWith("http://") ||
        this.imagePath.startsWith("https://") ||
        this.imagePath.startsWith("data")
      ) {
        this.url = this.imagePath;
      } else {
        this.url = await Storage.get(this.imagePath);
      }
    },
  },
});
