
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import dayjs from "dayjs";
import {
  Brand,
  Category,
  MdMapProductEntity,
  MdMapStatsEntity,
  OrderProduct,
  OrderSku,
  Product,
  Season,
  Sku,
  CustomTag,
  Color,
  Size,
  SeasonPlan,
  Attribute,
  OrderProductStockedStatus,
  BrandCategory,
  GetMainImagesEntity,
  Factory,
  ProductStatus,
  MdMapAdditionalProductEntity,
  MdMapDeliveryProductEntity,
  MdMapStatsDelioveryAdditionalEntity,
  ItemNumberLogic,
  s3KeyWithId,
  AdminUser,
} from "@/api/entities";
import { MdPlanFilters, OrderProductStatusDetail, ProductStatusDetail, User } from "@/store/model";
import { Options } from "highcharts";
import { Chart } from "highcharts-vue";
import S3Image from "@/components/aws/S3Image.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import RadialApiClient from "@/api/RadialApiClient";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import {
  CreateProductDto,
  UpdateSeasonBrandCategoryPlanDto,
  UpdateSeasonPlanDto,
  UpsertOrderSkuDto,
  CreateOrderProductDto,
  LatestItemNumberDto,
} from "@/api/dto";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AuthClient from "@/api/AuthClient";
import MdMapSummary from "@/views/season/MdMapSummary.vue";
import MdMapProductDetail from "@/views/season/MdMapProductDetail.vue";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import mixin from "@/mixin/mixin";

dayjs.extend(customParseFormat);

export interface MdMapCategoryDeliveryEntity {
  category: Category;
  stats: MdMapStatsEntity;
  products: MdMapDeliveryProductEntity[];
}
export interface MdMapCategoryAdditionalEntity {
  category: Category;
  stats: MdMapStatsEntity;
  products: MdMapAdditionalProductEntity[];
}

export interface MdMapDeliveryEntity {
  // MdMapの初期投入ビュー全体でブランドxカテゴリの情報を表示するときのための型
  brand: Brand;
  categories: MdMapCategoryDeliveryEntity[];
}
export interface MdMapAdditionalEntity {
  // MdMapの追加発注ビュー全体でブランドxカテゴリの情報を表示するときのための型
  brand: Brand;
  categories: MdMapCategoryAdditionalEntity[];
}

export interface MdMapSku {
  // skuとorderSkuをまとめた型
  sku: Sku;
  orderSku: OrderSku | null;
}

export interface MdMapProduct {
  // productの情報をまとめた型
  product: Product;
  orderProducts: OrderProductInfo[];
  mdMapProduct: MdMapDeliveryProductEntity | MdMapAdditionalProductEntity;
  skus: Sku[];
}

export interface OrderProductInfo {
  orderProduct: OrderProduct;
  orderSkus: OrderSku[];
}

export interface CreatingProductFromScratch {
  // 品番新規登録のための型
  status: ProductStatus;
  season: Season;
  brand: Brand;
  category: Category;
  brandCategoryId: string;
  itemNumber?: string | null;
  name?: string | null;
  priceString?: string | null;
  attribute1Id?: string | null;
  attribute2Id?: string | null;
  attribute3Id?: string | null;
  directionMemo?: string | null;
  salesStartedAt?: Date | null;
  salesEndedAt?: Date | null;
}

export interface CreatingOrderProductFromExistingProduct {
  // 既存品番から追加発注情報を登録するための型
  product?: Product | null;
  season: Season;
  brand: Brand;
  category: Category;
  brandCategoryId: string;
  orderedDate: Date;
  stockedDate: Date;
  stockedStatus: OrderProductStockedStatus | null;
  orderSkus: UpsertOrderSkuDto[];
}

export interface DataType {
  mapMode: string;
  mapViews: { id: string; name: string }[];
  seasons: Season[];
  selectedSeason: Season | null;
  brands: Brand[];
  brandCategories: BrandCategory[];
  categories: Category[];
  colors: Color[];
  sizes: Size[];
  adminUsers: AdminUser[];
  today: Date;
  isLoading: boolean;
  selectedYearMonth: string;
  mdPlanFilters: MdPlanFilters;
  filters: string[];
  filterKeys: string[];
  mdMapDeliveryEntities: MdMapDeliveryEntity[];
  mdMapAdditionalEntities: MdMapAdditionalEntity[];
  selectedProduct: MdMapProduct | null;
  selectedSkus: Sku[];
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  addProductModal: boolean;
  addOrderProductModal: boolean;
  statsTotal: MdMapStatsDelioveryAdditionalEntity | null;
  searchedProductList: Product[];
  filteredProductList: Product[];
  inputProductName: string | null;
  creatingProductFromScratch: CreatingProductFromScratch | null;
  creatingOrderProductFromExistingProduct: CreatingOrderProductFromExistingProduct | null;
  productStatusDetails: ProductStatusDetail[];
  orderProductStatusDetails: OrderProductStatusDetail[];
  selectedSeasonMonthMemo: string | null;
  selectedSeasonPlan: SeasonPlan | null;
  directionMemoRules: ((v: string) => boolean | "Max 180 characters")[];
  editSeasonMonthMemo: boolean;
  attribute1s: Attribute[];
  attribute2s: Attribute[];
  attribute3s: Attribute[];
  seasonYearMonths: string[];
  tabYearMonth: string;
  productImages: GetMainImagesEntity;
  factories: Factory[];
  itemNumberLogic: ItemNumberLogic | null;
  // Productをselect時に非同期で実行し子コンポーネントへデータ送信
  selectedOrderProducts: OrderProduct[];
  selectedOrderSkus: OrderSku[];
}

export default Vue.extend({
  name: "MdMap",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    S3Image,
    anum,
    FilterChipButton,
    ElementDatePicker,
    ConfirmationDialog,
    MdMapSummary,
    MdMapProductDetail,
    highcharts: Chart,
    Loading,
  },
  data(): DataType {
    return {
      mapMode: "delivery",
      mapViews: Constant.MdMapView,
      seasons: [],
      selectedSeason: null,
      brands: [],
      brandCategories: [],
      categories: [],
      colors: [],
      sizes: [],
      adminUsers: [],
      today: new Date(),
      isLoading: false,
      selectedYearMonth: "合計",
      mdPlanFilters: {
        brand: [],
        category: [],
      },
      filters: ["ブランド", "アイテムカテゴリ"],
      filterKeys: ["brand", "category"],
      mdMapDeliveryEntities: [],
      mdMapAdditionalEntities: [],
      selectedProduct: null,
      selectedSkus: [],
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      addProductModal: false,
      addOrderProductModal: false,
      statsTotal: null,
      searchedProductList: [],
      filteredProductList: [],
      inputProductName: null,
      creatingProductFromScratch: null,
      creatingOrderProductFromExistingProduct: null,
      productStatusDetails: Constant.MDStatus,
      orderProductStatusDetails: Constant.OrderProductStatus,
      selectedSeasonMonthMemo: "",
      selectedSeasonPlan: null,
      directionMemoRules: [(v) => (v != null && v.length <= 180) || "Max 180 characters"],
      editSeasonMonthMemo: false,
      attribute1s: [],
      attribute2s: [],
      attribute3s: [],
      seasonYearMonths: [],
      tabYearMonth: "合計",
      productImages: { s3KeyProducts: [] },
      factories: [],
      itemNumberLogic: null,
      selectedOrderProducts: [],
      selectedOrderSkus: [],
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    statsTotalRate(): number {
      if (this.statsTotal) {
        if (this.statsTotal.neededBuyingBudget !== 0) {
          const rate =
            (100 * (this.statsTotal.additionalBuyingBudget + this.statsTotal.deliveryBuyingBudget)) /
            this.statsTotal.neededBuyingBudget;
          if (!isNaN(rate)) {
            // 0/0のときだけNaNになるので注意
            return Math.ceil(rate);
          }
        }
      }
      return 0;
    },
    minStockedDateRange(): string {
      // 納品日の選択可能な最も古い月は、シーズン初月とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").format("YYYY-MM-DD");
    },
    maxStockedDateRange(): string {
      // 納品日の選択可能な最も新しい月は、シーズン最終月とする
      return dayjs(this.selectedSeason?.endYearMonth).endOf("month").format("YYYY-MM-DD");
    },
    minOrderedDateRange(): string {
      // 発注日の選択可能な最も古い月は、シーズン初月の6ヶ月前とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").subtract(6, "month").format("YYYY-MM-DD");
    },
    maxOrderedDateRange(): string {
      // 発注日の選択可能な最も新しい月は、シーズン最終月とする
      return dayjs(this.selectedSeason?.endYearMonth).endOf("month").format("YYYY-MM-DD");
    },
    minSalesStartedAtRange(): string {
      // 販売開始日の選択可能な最も古い月は、シーズン初月とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").format("YYYY-MM-DD");
    },
    maxSalesStartedAtRange(): string {
      // 販売開始日の選択可能な最も新しい月は、シーズン最終月とする
      return dayjs(this.selectedSeason?.endYearMonth).endOf("month").format("YYYY-MM-DD");
    },
    minSalesEndedAtRange(): string {
      // 販売終了日の選択可能な最も古い月は、シーズン初月とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").format("YYYY-MM-DD");
    },
    isDisabledCreateProductFromScratchButton(): boolean {
      if (this.creatingProductFromScratch && this.creatingProductFromScratch.status === "ORDERED") {
        if (this.itemNumberLogic?.isEnabled) {
          if (!this.creatingProductFromScratch.priceString) {
            return true;
          }
        } else {
          if (!this.creatingProductFromScratch.itemNumber || !this.creatingProductFromScratch.priceString) {
            return true;
          }
        }
      }
      return false;
    },
    isDisabledCreateProductFromExistingProductButton(): boolean {
      if (
        this.creatingOrderProductFromExistingProduct &&
        this.creatingOrderProductFromExistingProduct.orderSkus.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
  },
  methods: {
    updateMdMapProductCustomTag(tags: CustomTag[]) {
      // 子コンポーネントからの実行
      if (this.mapMode === "delivery") {
        this.mdMapDeliveryEntities
          .filter((item) => item.brand.id === this.selectedProduct?.product.brandId)[0]
          .categories.filter((item) => item.category.id === this.selectedProduct?.product.categoryId)[0]
          .products.filter((product) => product.productId === this.selectedProduct?.product.id)[0].customTags = tags;
      } else {
        this.mdMapAdditionalEntities
          .filter((item) => item.brand.id === this.selectedProduct?.product.brandId)[0]
          .categories.filter((item) => item.category.id === this.selectedProduct?.product.categoryId)[0]
          .products.filter((product) => product.productId === this.selectedProduct?.product.id)[0].customTags = tags;
      }
    },
    async searchMdMap() {
      // 子コンポーネントからの実行
      this.mapMode === "delivery" ? await this.deliverySearch() : await this.additionalSearch();
    },
    async submitSeasonMonthMemo() {
      if (this.selectedSeasonMonthMemo && this.selectedSeasonMonthMemo.length > 180) {
        // 将来的にcounterのprominence仕様次第でエラーダイアログ出す可能性あり
        return "";
      } else if (this.selectedSeasonPlan) {
        const dto: UpdateSeasonPlanDto = {
          directionMemo: this.selectedSeasonMonthMemo,
        };
        const seasonPlan = await RadialApiClient.updateSeasonPlan(this.selectedSeasonPlan.id, dto);
        if (seasonPlan) {
          this.selectedSeasonMonthMemo = seasonPlan.directionMemo;
        }
      }
    },
    formatDate(date: Date) {
      return dayjs(date).format("YYYY年M月DD日");
    },
    formatDatePicker(date: Date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    colorText(item: Color): string {
      return item.code + ": " + item.name;
    },
    sizeText(item: Size): string {
      return item.code + ": " + item.name;
    },
    searchProductByName() {
      if (this.inputProductName) {
        const inputProductName = this.inputProductName;
        this.filteredProductList = this.searchedProductList.filter((item) => {
          if (item.name) {
            return ~item.name.indexOf(inputProductName);
          }
        });
      } else {
        this.filteredProductList = this.searchedProductList;
        if (this.creatingOrderProductFromExistingProduct) {
          this.creatingOrderProductFromExistingProduct.product = null;
        }
      }
    },
    async changeInputProductName() {
      if (this.creatingOrderProductFromExistingProduct) {
        if (this.inputProductName) {
          if (this.creatingOrderProductFromExistingProduct.product) {
            this.inputProductName =
              this.creatingOrderProductFromExistingProduct.product.name ??
              this.creatingOrderProductFromExistingProduct.product.itemNumber?.toString() ??
              null;
          } else {
            this.inputProductName = "";
          }
        } else {
          this.creatingOrderProductFromExistingProduct.product = null;
        }
      }
    },
    async addDeliveryProduct(categoryEntity: MdMapCategoryDeliveryEntity) {
      this.addProductModal = true;
      const statsEntity: MdMapStatsEntity = categoryEntity.stats;
      if (this.selectedSeason) {
        // モーダル内情報初期化
        this.creatingProductFromScratch = {
          season: this.selectedSeason,
          brand: statsEntity.brand,
          category: statsEntity.category,
          brandCategoryId: statsEntity.brandCategoryId,
          status: "PLANNING",
          salesStartedAt: dayjs(this.selectedYearMonth).toDate(),
          salesEndedAt: dayjs(this.selectedSeason.endYearMonth).endOf("month").toDate(),
        };
      }
    },
    async addAdditionalProduct(categoryEntity: MdMapCategoryAdditionalEntity) {
      this.addOrderProductModal = true;
      const statsEntity: MdMapStatsEntity = categoryEntity.stats;
      if (this.selectedSeason) {
        // モーダル内情報初期化
        this.creatingOrderProductFromExistingProduct = {
          season: this.selectedSeason,
          brand: statsEntity.brand,
          category: statsEntity.category,
          brandCategoryId: statsEntity.brandCategoryId,
          // 追加発注追加時の発注日の初期値はタブの選択月初日とする
          orderedDate: dayjs(this.selectedYearMonth).toDate(),
          // 追加発注追加時の納品日の初期値はタブの選択月末日とする
          stockedDate: dayjs(this.selectedYearMonth).endOf("month").toDate(),
          stockedStatus: "PREORDER",
          orderSkus: [],
        };
        this.inputProductName = "";
        // 既存品番検索のためのリスト取得
        const products =
          (await RadialApiClient.listProducts(this.selectedSeason.id, statsEntity.brand.id, statsEntity.category.id)) ??
          [];
        // 既に描画されている品番カードと、選択月以降が販売開始となっている品番を除く
        this.searchedProductList = products.filter((product) => {
          return (
            !categoryEntity.products.some((categoryProduct) => categoryProduct.productId === product.id) &&
            dayjs(product.salesStartedAt).isBefore(dayjs(this.selectedYearMonth))
          );
        });
      }
      this.filteredProductList = this.searchedProductList;
    },
    async createProductFromScratch() {
      this.isLoading = true;
      if (this.creatingProductFromScratch) {
        // stringで持っていた数値をnumberにする
        const price = this.creatingProductFromScratch.priceString
          ? Number(this.creatingProductFromScratch.priceString.replace(/[^0123456789]/g, ""))
          : 0;
        const dto: CreateProductDto = {
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
          itemNumber: this.creatingProductFromScratch.itemNumber,
          season: {
            connect: {
              id: this.creatingProductFromScratch.season.id,
            },
          },
          brand: {
            connect: {
              id: this.creatingProductFromScratch.brand.id,
            },
          },
          category: {
            connect: {
              id: this.creatingProductFromScratch.category.id,
            },
          },
          brandCategory: {
            connect: {
              id: this.creatingProductFromScratch.brandCategoryId,
            },
          },
          status: this.creatingProductFromScratch.status,
          name: this.creatingProductFromScratch.name,
          price,
          attribute1: this.creatingProductFromScratch.attribute1Id
            ? {
                connect: {
                  id: this.creatingProductFromScratch.attribute1Id,
                },
              }
            : undefined,
          attribute2: this.creatingProductFromScratch.attribute2Id
            ? {
                connect: {
                  id: this.creatingProductFromScratch.attribute2Id,
                },
              }
            : undefined,
          attribute3: this.creatingProductFromScratch.attribute3Id
            ? {
                connect: {
                  id: this.creatingProductFromScratch.attribute3Id,
                },
              }
            : undefined,
          directionMemo: this.creatingProductFromScratch.directionMemo,
          salesStartedAt: dayjs(this.creatingProductFromScratch.salesStartedAt).toDate(),
          salesEndedAt: dayjs(this.creatingProductFromScratch.salesEndedAt).toDate(),
        };
        if (this.itemNumberLogic && this.itemNumberLogic.isEnabled) {
          const latestNumberDto: LatestItemNumberDto = {
            seasonCode: this.creatingProductFromScratch.season.code ?? "",
            brandCode: this.creatingProductFromScratch.brand.code ?? "",
            categoryCode: this.creatingProductFromScratch.category.code ?? "",
            attribute1Code:
              this.attribute1s.find((item) => item.id === this.creatingProductFromScratch?.attribute1Id)?.code ?? "",
            attribute2Code:
              this.attribute2s.find((item) => item.id === this.creatingProductFromScratch?.attribute2Id)?.code ?? "",
            attribute3Code:
              this.attribute3s.find((item) => item.id === this.creatingProductFromScratch?.attribute3Id)?.code ?? "",
          };
          const result = await RadialApiClient.getLatestItemNumber(latestNumberDto);
          if (result) {
            dto.itemNumber = result.latest;
          } else {
            this.showAlert(
              `品番の自動採番においてエラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error",
              -1
            );
          }
        }
        const product = await RadialApiClient.createMdMapProduct(dto).catch((error) => {
          this.isLoading = false;
          this.addProductModal = false;
          this.showAlert(error.response.data.message, "error", -1);
        });
        if (product) {
          const products =
            (await RadialApiClient.listMdMapDeliveryProductEntities(
              this.creatingProductFromScratch?.season.id ?? 0,
              dayjs(this.selectedYearMonth).format("YYYY-MM"),
              this.creatingProductFromScratch?.brand.id.toString(),
              this.creatingProductFromScratch?.category.id.toString()
            )) ?? [];
          this.reloadBrandCategoryDeliveryProducts(
            products,
            this.creatingProductFromScratch?.brand.id ?? 0,
            this.creatingProductFromScratch?.category.id ?? 0
          );
          this.isLoading = false;
          this.addProductModal = false;
          this.showAlert(
            `新しく品番が作成されました！
                MDマップにて引き続き、skuや発注情報の追加をしていきましょう。`,
            "success",
            -1
          );
        } else {
          this.isLoading = false;
          this.addProductModal = false;
          this.showAlert(
            `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error",
            -1
          );
        }
      }

      // get product image s3keys
      const productIds = this.mdMapDeliveryEntities
        .map((brandItem) =>
          brandItem.categories.map((categoryItem) =>
            categoryItem.products.map((product) => {
              return product.productId;
            })
          )
        )
        .flat(3);
      const productImages = await RadialApiClient.getProductMainImages({ productIds });
      if (productImages) {
        this.productImages = productImages;
      }

      this.isLoading = false;
    },
    async createOrderProductFromExistingProduct() {
      this.isLoading = true;
      if (this.creatingOrderProductFromExistingProduct) {
        const dto: CreateOrderProductDto = {
          year: dayjs(this.selectedYearMonth).year(),
          month: dayjs(this.selectedYearMonth).month(),
          stockedDate: this.creatingOrderProductFromExistingProduct.stockedDate,
          orderedDate: this.creatingOrderProductFromExistingProduct.orderedDate,
          stockedStatus: this.creatingOrderProductFromExistingProduct.stockedStatus,
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
          season: {
            connect: {
              id: this.creatingOrderProductFromExistingProduct.season.id,
            },
          },
          brand: {
            connect: {
              id: this.creatingOrderProductFromExistingProduct.brand.id,
            },
          },
          category: {
            connect: {
              id: this.creatingOrderProductFromExistingProduct.category.id,
            },
          },
          brandCategory: {
            connect: {
              id: this.creatingOrderProductFromExistingProduct.brandCategoryId,
            },
          },
          product: {
            connect: {
              id: this.creatingOrderProductFromExistingProduct.product?.id ?? "",
            },
          },
        };
        // orderProduct(追加発注)の作成
        const orderProduct = await RadialApiClient.createOrderProduct(dto);
        if (orderProduct) {
          // orderSkuの一括作成
          const createdOrderSkus = await RadialApiClient.createMdMapOrderProductSkusFromScratch({
            orderProductId: orderProduct.id,
            orderSkus: this.creatingOrderProductFromExistingProduct?.orderSkus ?? [],
          });
          if (createdOrderSkus) {
            const products = await RadialApiClient.listMdMapAdditionalProductEntities(
              this.creatingOrderProductFromExistingProduct?.season.id ?? 0,
              dayjs(this.selectedYearMonth).format("YYYY-MM"),
              this.creatingOrderProductFromExistingProduct?.brand.id.toString(),
              this.creatingOrderProductFromExistingProduct?.category.id.toString()
            );
            if (products) {
              this.reloadBrandCategoryAdditionalProducts(
                products,
                this.creatingOrderProductFromExistingProduct?.brand.id ?? 0,
                this.creatingOrderProductFromExistingProduct?.category.id ?? 0
              );
              this.isLoading = false;
              this.addOrderProductModal = false;
              this.showAlert("新しく追加発注情報を作成しました！", "success", -1);
            } else {
              this.isLoading = false;
              this.addOrderProductModal = false;
              this.showAlert(
                `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
                "error",
                -1
              );
            }
          }
        }
      }
      this.isLoading = false;
    },
    async updateStatsProductsDetail(productId: string, seasonId: number, brandId: number, categoryId: number) {
      if (this.selectedSeason) {
        // statsTotalの更新
        await RadialApiClient.getMdMapStatsTotalEntity(this.selectedSeason.id, this.selectedYearMonth)
          .then((response) => {
            this.statsTotal = response;
          })
          .catch(() => {
            this.statsTotal = null;
          });
      }
      // statsの更新
      const stats =
        (await RadialApiClient.listMdMapStatsEntities(
          seasonId,
          dayjs(this.selectedYearMonth).format("YYYY-MM"),
          brandId.toString(),
          categoryId.toString()
        )) ?? [];
      if (stats && stats.length > 0) {
        const entity: MdMapStatsEntity = stats[0]; // 以下の処理はbrand x categoryの一致するstatsが一つしかない想定
        const deliveryEntities = this.mdMapDeliveryEntities
          .filter((item) => item?.brand?.id === entity?.brand?.id)[0]
          ?.categories.filter((item) => item?.category?.id === stats[0]?.category?.id)[0];
        if (deliveryEntities) {
          deliveryEntities.stats = entity;
        }
      }
      // productsの更新
      if (this.mapMode === "delivery") {
        const items =
          (await RadialApiClient.listMdMapDeliveryProductEntities(
            seasonId,
            dayjs(this.selectedYearMonth).format("YYYY-MM"),
            brandId.toString(),
            categoryId.toString()
          )) ?? [];
        this.reloadBrandCategoryDeliveryProducts(items, brandId, categoryId);
        // selectedProductの更新
        const newProductEntity = items.find((item) => item.product.id === productId);
        if (this.selectedProduct && newProductEntity) {
          await this.selectProduct(newProductEntity);
        } else {
          this.selectedProduct = null;
        }
      } else {
        const items =
          (await RadialApiClient.listMdMapAdditionalProductEntities(
            seasonId,
            dayjs(this.selectedYearMonth).format("YYYY-MM"),
            brandId.toString(),
            categoryId.toString()
          )) ?? [];
        this.reloadBrandCategoryAdditionalProducts(items, brandId, categoryId);
        // selectedProductの更新
        const newProductEntity = items.find((item) => item.product.id === productId);
        if (this.selectedProduct && newProductEntity) {
          // selectedProductの更新
          await this.selectProduct(newProductEntity);
        } else {
          this.selectedProduct = null;
        }
      }
    },
    async selectExistingProduct(product: Product) {
      if (this.creatingOrderProductFromExistingProduct) {
        this.creatingOrderProductFromExistingProduct.product = product;
        this.inputProductName =
          this.creatingOrderProductFromExistingProduct.product.name ??
          this.creatingOrderProductFromExistingProduct.product.itemNumber?.toString() ??
          null;
        const skus = await RadialApiClient.listSkusByProduct(this.creatingOrderProductFromExistingProduct.product.id);
        if (skus) {
          this.selectedSkus = skus;
          this.creatingOrderProductFromExistingProduct.orderSkus = skus.map((sku) => {
            return {
              skuId: sku.id,
              orderQuantity: 0,
              orderCost: 0,
            };
          });
        }
      }
    },
    showSeasons() {
      const routeData = this.$router.resolve({
        name: "AdminSeasons",
      });
      window.open(routeData.href, "_self");
    },
    showRefSeasonMdMap(seasonId: string) {
      const routeData = this.$router.resolve({
        name: "AdminMdMap",
        params: { seasonId },
      });
      window.open(routeData.href, "_blank");
    },
    async updateMdMapStats(num: string, id: number, index: number) {
      const input = Number(num.toString().replace(/[^0123456789]/g, "")); // 数字以外の文字を消す
      const dto: UpdateSeasonBrandCategoryPlanDto = {
        averagePriceBudget: index === 0 ? input : undefined,
        averageOrderQuantityBudget: index === 1 ? input : undefined,
      };
      const seasonBrandCategoryPlan = await RadialApiClient.updateSeasonBrandCategoryPlan(id.toString(), dto);
      if (seasonBrandCategoryPlan) {
        const stats = await RadialApiClient.listMdMapStatsEntities(
          seasonBrandCategoryPlan.seasonId,
          `${seasonBrandCategoryPlan.year}-${seasonBrandCategoryPlan.month}`,
          seasonBrandCategoryPlan.brandId.toString(),
          seasonBrandCategoryPlan.categoryId.toString()
        );
        if (stats && stats.length > 0) {
          const entity: MdMapStatsEntity = stats.filter((item) => item.seasonBrandCategoryPlanId === id)[0];
          this.mdMapDeliveryEntities
            .filter((item) => item.brand.id === entity.brand.id)[0]
            .categories.filter(
              (item) => item.category.id === stats.filter((item) => item.seasonBrandCategoryPlanId === id)[0].categoryId
            )[0].stats = entity;
        }
      }
    },
    // 選択された品番と同じブランドxカテゴリのものが上に来るようにスクロール対象のclassを入れる
    isScrollTarget(brand: number, category: number) {
      if (this.selectedProduct) {
        if (this.selectedProduct.product.brandId === brand && this.selectedProduct.product.categoryId === category) {
          return true;
        }
      }
      return false;
    },
    async changeYearMonthTab(yearMonth: string) {
      this.selectedProduct = null;
      if (yearMonth === "合計") {
        this.selectedYearMonth = "合計";
        this.tabYearMonth = yearMonth;
        this.mdMapDeliveryEntities = [];
        this.mdMapAdditionalEntities = [];
        // Vue Routerがundefinedなパラメータを受け付けないため、yearMonthがundefinedの場合、そのプロパティを含めずにparamsオブジェクトを作成する
        const params: { seasonId: string; yearMonth?: string; productId?: string } = {
          seasonId: this.selectedSeason?.id.toLocaleString() ?? "",
        };
        // `yearMonth`が`undefined`でない場合、`params`オブジェクトに追加
        if (typeof yearMonth !== "undefined") {
          params.yearMonth = yearMonth;
        }
        this.$router.push({
          name: "AdminMdMap",
          params,
        });
      } else {
        this.selectedYearMonth = dayjs(yearMonth, "YY/M").format("YYYY-MM");
        // シーズン月のディレクションメモを取得する
        const response = (await RadialApiClient.listSeasonPlan()) ?? [];
        this.selectedSeasonPlan =
          response.find((item) => {
            return (
              item.seasonId === this.selectedSeason?.id &&
              item.year === dayjs(this.selectedYearMonth).year() &&
              item.month === dayjs(this.selectedYearMonth).month() + 1
            );
          }) ?? null;
        this.selectedSeasonMonthMemo = this.selectedSeasonPlan?.directionMemo ?? null;
        this.tabYearMonth = yearMonth;
        this.mapMode === "delivery" ? await this.deliverySearch() : await this.additionalSearch();
      }
    },
    selectStockedDate(date: string, index: number) {
      switch (index) {
        case 0:
          if (this.creatingOrderProductFromExistingProduct) {
            this.creatingOrderProductFromExistingProduct.orderedDate = dayjs(date).toDate();
          }
          return;
        case 1:
          if (this.creatingOrderProductFromExistingProduct) {
            this.creatingOrderProductFromExistingProduct.stockedDate = dayjs(date).toDate();
          }
          return;
        default:
          return;
      }
    },
    inputNumber(numString: string, index: number) {
      const validNumString = Number(
        numString.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
      ).toLocaleString();
      switch (index) {
        case 0:
          if (this.creatingProductFromScratch) {
            this.creatingProductFromScratch.priceString = validNumString;
          }
          return;
        // TODO: いらない
        default:
          return;
      }
    },
    inputArrayNumber(numString: string, arrayNumber: number, index: number) {
      const validNumString = Number(
        numString.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
      ).toLocaleString();
      switch (index) {
        case 0:
          // if (this.newOrderProductSkus) {
          //   TODO: 本当にnewOrderProductSkusなのか
          //   this.newOrderProductSkus.orderSkus[arrayNumber].orderCostString = validNumString;
          // }
          return;
        case 1:
          // if (this.newOrderProductSkus) {
          //   this.newOrderProductSkus.orderSkus[arrayNumber].orderQuantityString = validNumString;
          // }
          return;
        default:
          return;
      }
    },
    salesDateFormat(date: Date): string {
      return dayjs(date).format("YYYY/M/D");
    },
    filterOptions(index: number): Brand[] | Category[] {
      switch (index) {
        case 0:
          return this.brands.filter((brand) => {
            return this.brandCategories.some((brandCategory) => brandCategory.brandId === brand.id);
          });
        case 1:
          return this.categories.filter((category) => {
            return this.brandCategories.some((brandCategory) => brandCategory.categoryId === category.id);
          });
        case 2:
        default:
          return [];
      }
    },
    async clearFilterKey(key: keyof MdPlanFilters) {
      this.mdPlanFilters[key] = [];
      this.mapMode === "delivery" ? await this.deliverySearch() : await this.additionalSearch();
    },
    async changeMapMode() {
      this.mapMode === "delivery" ? await this.deliverySearch() : await this.additionalSearch();
    },
    async additionalSearch() {
      this.isLoading = true;
      // カードの更新
      this.mdMapAdditionalEntities = []; // 初期化
      if (this.selectedSeason) {
        this.statsTotal = await RadialApiClient.getMdMapStatsTotalEntity(
          this.selectedSeason.id,
          this.selectedYearMonth
        );
        const statsEntities =
          (await RadialApiClient.listMdMapStatsEntities(this.selectedSeason.id, this.selectedYearMonth)) ?? [];
        const productEntities =
          (await RadialApiClient.listMdMapAdditionalProductEntities(this.selectedSeason.id, this.selectedYearMonth)) ??
          [];
        const filters = { ...this.mdPlanFilters };
        // フィルター条件がないときは、全部を検索条件とする
        if (this.mdPlanFilters.brand.length === 0) {
          filters.brand = this.brands;
        }
        if (this.mdPlanFilters.category.length === 0) {
          filters.category = this.categories;
        }
        const filteredStatsEntities = statsEntities.filter(
          (item) =>
            filters.brand.find((brand) => brand.id === item.brand.id) &&
            filters.category.find((category) => category.id === item.category.id)
        );
        const filteredProductEntities = productEntities.filter((product) => {
          return (
            filters.brand.find((brand) => brand.id === product.brandId) &&
            filters.category.find((category) => category.id === product.categoryId)
          );
        });
        // stats情報をMdMapEntity[]に入れていく
        for (const statsEntity of filteredStatsEntities) {
          const brandIndex = this.mdMapAdditionalEntities.findIndex((item) => item.brand.id === statsEntity.brand.id);
          if (brandIndex > -1) {
            this.mdMapAdditionalEntities[brandIndex].categories.push({
              category: statsEntity.category,
              stats: statsEntity,
              products: [],
            });
          } else {
            this.mdMapAdditionalEntities.push({
              brand: statsEntity.brand,
              categories: [
                {
                  category: statsEntity.category,
                  stats: statsEntity,
                  products: [],
                },
              ],
            });
          }
        }
        // products情報をMdMapEntityに入れていく
        for (const productEntity of filteredProductEntities) {
          const brandIndex = this.mdMapAdditionalEntities.findIndex((item) => item.brand.id === productEntity.brand.id);
          if (brandIndex > -1) {
            const categoryIndex = this.mdMapAdditionalEntities[brandIndex].categories.findIndex(
              (item) => item.category.id === productEntity.category.id
            );
            if (categoryIndex > -1) {
              this.mdMapAdditionalEntities[brandIndex].categories[categoryIndex].products.push(productEntity);
            }
          }
        }
        if (this.selectedProduct) {
          const selectedProductEntity = filteredProductEntities.find(
            (item) => item.product.id === this.selectedProduct?.product.id
          );
          if (selectedProductEntity) {
            await this.selectProduct(selectedProductEntity);
          } else {
            this.selectedProduct = null;
          }
        }
      }

      // get product image s3keys
      const productIds = this.mdMapAdditionalEntities
        .map((brandItem) =>
          brandItem.categories.map((categoryItem) => categoryItem.products.map((product) => product.productId))
        )
        .flat(3);
      const productImages = await RadialApiClient.getProductMainImages({ productIds });
      if (productImages) {
        this.productImages = productImages;
      }

      this.isLoading = false;
    },
    async deliverySearch() {
      this.isLoading = true;
      this.mdMapDeliveryEntities = []; // 初期化
      if (this.selectedSeason) {
        this.statsTotal = await RadialApiClient.getMdMapStatsTotalEntity(
          this.selectedSeason.id,
          this.selectedYearMonth
        );
        const statsEntities =
          (await RadialApiClient.listMdMapStatsEntities(this.selectedSeason.id, this.selectedYearMonth)) ?? [];
        const productEntities =
          (await RadialApiClient.listMdMapDeliveryProductEntities(this.selectedSeason.id, this.selectedYearMonth)) ??
          [];
        const filters = { ...this.mdPlanFilters };
        // フィルター条件がないときは、全部を検索条件とする
        if (this.mdPlanFilters.brand.length === 0) {
          filters.brand = this.brands;
        }
        if (this.mdPlanFilters.category.length === 0) {
          filters.category = this.categories;
        }
        const filteredStatsEntities = statsEntities.filter(
          (item) =>
            filters.brand.find((brand) => brand.id === item.brand.id) &&
            filters.category.find((category) => category.id === item.category.id)
        );
        const filteredProductEntities = productEntities.filter((product) => {
          return (
            filters.brand.find((brand) => brand.id === product.brandId) &&
            filters.category.find((category) => category.id === product.categoryId)
          );
        });
        // stats情報をMdMapEntity[]に入れていく
        for (const statsEntity of filteredStatsEntities) {
          const brandIndex = this.mdMapDeliveryEntities.findIndex((item) => item.brand.id === statsEntity.brand.id);
          if (brandIndex > -1) {
            this.mdMapDeliveryEntities[brandIndex].categories.push({
              category: statsEntity.category,
              stats: statsEntity,
              products: [],
            });
          } else {
            this.mdMapDeliveryEntities.push({
              brand: statsEntity.brand,
              categories: [
                {
                  category: statsEntity.category,
                  stats: statsEntity,
                  products: [],
                },
              ],
            });
          }
        }
        // products情報をMdMapEntityに入れていく
        for (const productEntity of filteredProductEntities) {
          const brandIndex = this.mdMapDeliveryEntities.findIndex((item) => item.brand.id === productEntity.brand.id);
          if (brandIndex > -1) {
            const categoryIndex = this.mdMapDeliveryEntities[brandIndex].categories.findIndex(
              (item) => item.category.id === productEntity.category.id
            );
            if (categoryIndex > -1) {
              this.mdMapDeliveryEntities[brandIndex].categories[categoryIndex].products.push(productEntity);
            }
          }
        }
        if (this.selectedProduct) {
          const selectedProductEntity = filteredProductEntities.find(
            (item) => item.product.id === this.selectedProduct?.product.id
          );
          if (selectedProductEntity) {
            await this.selectProduct(selectedProductEntity);
          } else {
            this.selectedProduct = null;
          }
        }
      }

      // get product image s3keys
      const productIds = this.mdMapDeliveryEntities
        .map((brandItem) =>
          brandItem.categories.map((categoryItem) => categoryItem.products.map((product) => product.productId))
        )
        .flat(3);
      const productImages = await RadialApiClient.getProductMainImages({ productIds });
      if (productImages) {
        this.productImages = productImages;
      }

      // パスパラメータに選択した年月の情報を保存
      const yearMonth = dayjs(this.selectedYearMonth).format("YY/M");
      if (this.isAdmin && yearMonth !== this.$route.params.yearMonth) {
        this.$router.push({
          name: "AdminMdMap",
          params: { yearMonth },
        });
      }

      this.isLoading = false;
    },
    async selectProduct(entity: MdMapDeliveryProductEntity | MdMapAdditionalProductEntity) {
      if (this.selectedSeason) {
        await Promise.all([
          (async () => {
            this.selectedOrderProducts =
              (await RadialApiClient.listOrderProducts(entity.product.id, this.selectedSeason?.id)) ?? [];
          })(),
          (async () => {
            this.selectedOrderSkus =
              (await RadialApiClient.listOrderSkus(entity.product.id, this.selectedSeason?.id)) ?? [];
          })(),
          (async () => {
            this.selectedSkus = (await RadialApiClient.listSkusByProduct(entity.product.id)) ?? [];
          })(),
        ]);
        if (this.selectedSkus) {
          this.selectedSkus.sort((a, b) => {
            if (a.colorId > b.colorId) return 1;
            if (a.colorId < b.colorId) return -1;
            if (a.sizeId > b.sizeId) return 1;
            if (a.sizeId < b.sizeId) return -1;
            return 0;
          });
          this.selectedProduct = {
            product: entity.product,
            orderProducts: this.selectedOrderProducts
              .map((orderProduct) => {
                return {
                  orderProduct: orderProduct,
                  orderSkus: this.selectedOrderSkus
                    .filter((orderSku) => {
                      return orderSku.orderProductId === orderProduct.id;
                    })
                    .sort((a, b) => {
                      if (a.orderQuantity < b.orderQuantity) {
                        return 1;
                      } else {
                        return -1;
                      }
                    }),
                };
              })
              .sort((a, b) => {
                if (a.orderProduct.stockedDate > b.orderProduct.stockedDate) {
                  return 1;
                } else {
                  return -1;
                }
              }),
            mdMapProduct: entity,
            skus: this.selectedSkus,
          };
        }
      }
      setTimeout(() => {
        const element = document.getElementById("scroll-target");
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 500);
    },
    sortMdMapProductCard(brandId: number, categoryId: number, mapMode: string) {
      // 該当のブランド×カテゴリのカードを並び替える
      if (mapMode === "delivery") {
        this.mdMapDeliveryEntities
          .filter((item) => item.brand.id === brandId)[0]
          .categories.filter((item) => item.category.id === categoryId)[0]
          .products.sort((a, b) => {
            if (a.product.createdAt > b.product.createdAt) {
              return 1;
            } else {
              return -1;
            }
          });
      } else if (mapMode === "additional") {
        this.mdMapAdditionalEntities
          .filter((item) => item.brand.id === brandId)[0]
          .categories.filter((item) => item.category.id === categoryId)[0]
          .products.sort((a, b) => {
            if (a.product.createdAt > b.product.createdAt) {
              return 1;
            } else {
              return -1;
            }
          });
      }
    },
    reloadBrandCategoryDeliveryProducts(entities: MdMapDeliveryProductEntity[], brandId: number, categoryId: number) {
      // Brand x Cateforyのproductを一度空にする
      this.mdMapDeliveryEntities
        .filter((item) => item.brand.id === brandId)[0]
        .categories.filter((item) => item.category.id === categoryId)[0].products = [];
      // Brand x Cateforyのproductを再取得する
      for (const entity of entities) {
        this.mdMapDeliveryEntities
          .filter((item) => item.brand.id === brandId)[0]
          .categories.filter((item) => item.category.id === categoryId)[0]
          .products.push(entity);
      }
      this.sortMdMapProductCard(brandId, categoryId, "delivery");
    },
    reloadBrandCategoryAdditionalProducts(
      entities: MdMapAdditionalProductEntity[],
      brandId: number,
      categoryId: number
    ) {
      // Brand x Cateforyのproductを一度空にする
      this.mdMapAdditionalEntities
        .filter((item) => item.brand.id === brandId)[0]
        .categories.filter((item) => item.category.id === categoryId)[0].products = [];
      // Brand x Cateforyのproductを再取得する
      for (const entity of entities) {
        this.mdMapAdditionalEntities
          .filter((item) => item.brand.id === brandId)[0]
          .categories.filter((item) => item.category.id === categoryId)[0]
          .products.push(entity);
      }
      this.sortMdMapProductCard(brandId, categoryId, "additional");
    },
    getSeasonRange(): string {
      if (this.selectedSeason) {
        return `${dayjs(this.selectedSeason.startYearMonth).format("YY/M")}〜${dayjs(
          this.selectedSeason.endYearMonth
        ).format("YY/M")}`;
      } else {
        return "";
      }
    },
    getRefSeasonName(): string {
      return this.seasons.find((season) => season.id === this.selectedSeason?.refSeasonId)?.name ?? "未選択";
    },
    getSkuSizeName(skuId: string): string {
      const sizeId = String(this.selectedSkus.find((sku) => sku.id === skuId)?.sizeId);
      return this.sizes.find((size) => size.id === sizeId)?.name ?? "";
    },
    getSkuColorName(skuId: string): string {
      const colorId = String(this.selectedSkus.find((sku) => sku.id === skuId)?.colorId);
      return this.colors.find((color) => color.id === colorId)?.name ?? "";
    },
    displayCustomTags(productEntity: MdMapProductEntity): { tags: CustomTag[]; isTooLong: boolean } {
      const customTags: CustomTag[] = productEntity.customTags;
      let isTooLong = false;
      const displayableCustomTags: CustomTag[] = [];
      const tagAreaWidth: number = (window.innerWidth - 880) / 4;
      let width = 0;
      const customTagSpaceWidth = 26;
      for (const [index, customTag] of customTags.entries()) {
        width += this.getCustomTagNameWidth(customTag.customTagMaster?.name ?? "");
        if (width + customTagSpaceWidth * (index + 1) < tagAreaWidth) {
          displayableCustomTags.push(customTag);
        } else {
          break;
        }
      }
      if (displayableCustomTags.length < customTags.length) {
        isTooLong = true;
      } else {
        isTooLong = false;
      }
      return {
        tags: displayableCustomTags,
        isTooLong,
      };
    },
    getCustomTagNameWidth(customTagName: string): number {
      const span = document.createElement("span");
      span.style.position = "absolute";
      span.style.top = "-1000px";
      span.style.left = "-1000px";
      span.style.whiteSpace = "nowrap";
      span.innerHTML = customTagName;
      span.style.font = "futura-pt";
      span.style.fontSize = "10px";
      span.style.fontWeight = "500";
      span.style.lineHeight = "14px";
      document.body.appendChild(span);
      const width = span.clientWidth;
      (span as any).parentElement.removeChild(span);
      return width;
    },
    getS3Key(product: Product): string {
      return this.productImages.s3KeyProducts.find((item) => item.productId === product.id)?.s3Key ?? "";
    },
    async closeSelectedProductScreen(event) {
      this.selectedProduct = null;
      // update product main images
      const productIds = this.mdMapDeliveryEntities
        .map((brandItem) =>
          brandItem.categories.map((categoryItem) => categoryItem.products.map((product) => product.productId))
        )
        .flat(3);
      this.productImages = (await RadialApiClient.getProductMainImages({ productIds })) ?? { s3KeyProducts: [] };
    },
    getOptions(): Options {
      const remainedOrders = this.statsTotal
        ? Math.ceil(
            this.statsTotal?.neededBuyingBudget -
              this.statsTotal?.deliveryBuyingBudget -
              this.statsTotal?.additionalBuyingBudget
          )
        : 0;
      return {
        title: undefined,
        chart: {
          renderTo: "piechart",
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          width: 96,
          height: 96,
          backgroundColor: "rgba(255, 255, 255, 0.0)",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            size: "100%",
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              `<div style="font-size: 8px">` +
              this.point.name +
              "</div>" +
              " " +
              `<div style="font-size: 10px">` +
              "¥" +
              this.y +
              "</div>"
            );
          },
        },
        series: [
          {
            //データ設定
            type: "pie",
            name: "",
            data: [
              {
                name: "新規",
                y: this.statsTotal?.deliveryBuyingBudget,
                color: "#5FB3B1",
              },
              {
                name: "追加",
                y: this.statsTotal?.additionalBuyingBudget,
                color: "#5EA3DA",
              },
              {
                name: "残り",
                y: remainedOrders > 0 ? remainedOrders : 0,
                color: "#F0F6F7",
              },
            ],
          },
        ],
      };
    },
    getIconS3Key(tag: CustomTag): string {
      if (tag.creatorAdminUserId) {
        return this.adminUsers.find((adminUser) => adminUser.id === tag.creatorAdminUserId)?.icon ?? "";
      } else {
        return store.state.company?.icon ?? "";
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.user) {
      await Promise.all([
        (async () => {
          this.seasons = (await RadialApiClient.listSeasons()) ?? [];
        })(),
        (async () => {
          this.brands = (await RadialApiClient.listBrands()) ?? [];
        })(),
        (async () => {
          this.brandCategories = (await RadialApiClient.listBrandCategories()) ?? [];
        })(),
        (async () => {
          this.categories = (await RadialApiClient.listCategories()) ?? [];
        })(),
        (async () => {
          this.colors = (await RadialApiClient.listColors()) ?? [];
        })(),
        (async () => {
          this.sizes = (await RadialApiClient.listSizes()) ?? [];
        })(),
        (async () => {
          this.factories = (await RadialApiClient.listFactories()) ?? [];
        })(),
        (async () => {
          this.adminUsers = (await RadialApiClient.listAdminUsers()) ?? [];
        })(),
        (async () => {
          const allAttributes = (await RadialApiClient.listAttributes()) ?? [];
          this.attribute1s = allAttributes.filter((item) => {
            return item.number === 1;
          });
          this.attribute2s = allAttributes.filter((item) => {
            return item.number === 2;
          });
          this.attribute3s = allAttributes.filter((item) => {
            return item.number === 3;
          });
        })(),
        (async () => {
          this.itemNumberLogic = await RadialApiClient.getItemNumberLogic();
        })(),
      ]).catch(() => {
        this.showAlert(
          `エラーが発生しております。
          時間を空けてからもう一度お試しください。`,
          "error",
          -1
        );
      });
      const seasonId = this.$route.params.seasonId;
      if (this.seasons.find((season) => season.id === Number(seasonId))) {
        this.selectedSeason = this.seasons.find((season) => season.id === Number(seasonId)) ?? null;
      }
      // タブ情報の取得
      let startTableMonth = dayjs(this.selectedSeason?.startYearMonth).isSameOrBefore(
        dayjs(this.selectedSeason?.endYearMonth)
      )
        ? dayjs(this.selectedSeason?.startYearMonth)
        : dayjs(this.selectedSeason?.endYearMonth);
      const endTableMonth = startTableMonth.isSame(dayjs(this.selectedSeason?.startYearMonth))
        ? dayjs(this.selectedSeason?.endYearMonth)
        : dayjs(this.selectedSeason?.startYearMonth);
      while (startTableMonth.isSameOrBefore(endTableMonth)) {
        this.seasonYearMonths.push(dayjs(startTableMonth).format("YY/M"));
        startTableMonth = startTableMonth.add(1, "M");
      }
      this.seasonYearMonths.unshift("合計");

      // 表示するyearMonthタブと商品を選択
      const yearMonth = this.$route.params.yearMonth;
      if (seasonId && yearMonth) {
        // 商品の年月のタブを表示
        await this.changeYearMonthTab(yearMonth);
        const productId = this.$route.params.productId;
        const selectedProductEntity = this.mdMapDeliveryEntities
          .map((brandEntity) => brandEntity.categories.map((categoryEntity) => categoryEntity.products))
          .flat(2)
          .find((product) => product.product.id === productId);
        if (selectedProductEntity) {
          await this.selectProduct(selectedProductEntity);
        }
      }
    }

    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/mdmap",
        page_title: "AdminMDマップ画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/mdmap",
        page_title: "StaffMDマップ画面",
      });
    }

    this.isLoading = false;
  },
});
