
import Vue from "vue";
import dayjs from "dayjs";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import RadialApiClient from "@/api/RadialApiClient";
import { BudgetWeekRate } from "@/api/entities";
import { UpdateBudgetWeekRateDto } from "@/api/dto";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";
import * as lodash from "lodash";

export class WeekdayBudgetStringRate {
  year: string;
  month: string;
  weekday: string;
  weekend: string;
}

export interface DataType {
  selectedYear: string;
  budgetWeekStringRateList: WeekdayBudgetStringRate[];
  savedBudgetWeekStringRateList: WeekdayBudgetStringRate[];
  isLoading: boolean;
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  user: User | null;
}

export default Vue.extend({
  name: "WeekdayBudget",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ElementDatePicker,
    ConfirmationDialog,
    Loading,
  },
  data(): DataType {
    return {
      selectedYear: dayjs().format("YYYY-MM-DD"),
      budgetWeekStringRateList: [],
      savedBudgetWeekStringRateList: [],
      isLoading: false,
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      user: null,
    };
  },
  computed: {},
  methods: {
    updateTextField() {
      this.budgetWeekStringRateList = this.budgetWeekStringRateList.map((budgetWeekStringRate) => {
        // 文字列に数字もしくはコンマ以外の文字が含まれれば"1.0"に置換
        const weekdayText = this.zenkakuToHankaku(budgetWeekStringRate.weekday);
        const weekday = !weekdayText
          ? "1" // 空文字列の時
          : isNaN(Number(weekdayText))
          ? "1" // 文字でない時
          : Number(weekdayText) >= 0
          ? Number(weekdayText).toString() // 数字が0以上の時
          : "1"; // 数字が0未満の時
        const weekendText = this.zenkakuToHankaku(budgetWeekStringRate.weekend);
        const weekend = !weekendText
          ? "1" // 空文字列の時
          : isNaN(Number(weekendText))
          ? "1" // 文字でない時
          : Number(weekendText) >= 0
          ? Number(weekendText).toString() // 数字が0以上の時
          : "1"; // 数字が0未満の時
        return {
          year: budgetWeekStringRate.year,
          month: budgetWeekStringRate.month,
          weekday,
          weekend,
        } as WeekdayBudgetStringRate;
      });
    },
    castNumberToString(budgetWeekRateList: BudgetWeekRate[]): WeekdayBudgetStringRate[] {
      return budgetWeekRateList.map((budgetWeekRate) => {
        return {
          year: budgetWeekRate.year.toString(),
          month: budgetWeekRate.month.toString(),
          weekday: budgetWeekRate.weekday.toString(),
          weekend: budgetWeekRate.weekend.toString(),
        };
      });
    },
    async updateYear() {
      this.isLoading = true;
      const budgetWeekRateList: BudgetWeekRate[] =
        (await RadialApiClient.getBudgetWeekRateList(Number(dayjs(this.selectedYear).format("YYYY")))) ?? [];
      // APIからはnumberですべての情報が帰ってくるため、stringにキャストする
      this.budgetWeekStringRateList = this.castNumberToString(budgetWeekRateList);
      this.savedBudgetWeekStringRateList = lodash.cloneDeep(this.budgetWeekStringRateList);

      this.isLoading = false;
    },
    async update() {
      const dto: UpdateBudgetWeekRateDto[] = this.budgetWeekStringRateList.map((budgetWeekStringRate) => {
        return {
          year: Number(budgetWeekStringRate.year),
          month: Number(budgetWeekStringRate.month),
          weekday: Number(budgetWeekStringRate.weekday),
          weekend: Number(budgetWeekStringRate.weekend),
        } as UpdateBudgetWeekRateDto;
      });
      this.isLoading = true;
      const updateResponseMessage = await RadialApiClient.updateBudgetWeekRateList(dto);
      this.budgetWeekStringRateList = this.castNumberToString(updateResponseMessage ?? []);
      this.savedBudgetWeekStringRateList = lodash.cloneDeep(this.budgetWeekStringRateList);
      this.showAlert(
        `${dayjs(this.selectedYear).format("YYYY")}年の各月の平日土日の傾斜設定を更新しました！
        今後月別の予算を作成もしくは更新されたタイミングで、更新いただいたロジックが適用され、日別予算が更新されます。
        既存の日別予算データは上記対応をしない限り更新されませんのでよろしくお願いいたします。`,
        "success"
      );
      this.isLoading = false;
    },
    isSameValue(): boolean {
      return (
        this.budgetWeekStringRateList.length === this.savedBudgetWeekStringRateList.length &&
        this.budgetWeekStringRateList.every(
          (budgetWeekStringRate, index) =>
            budgetWeekStringRate.weekday === this.savedBudgetWeekStringRateList[index].weekday
        ) &&
        this.budgetWeekStringRateList.every(
          (budgetWeekStringRate, index) =>
            budgetWeekStringRate.weekend === this.savedBudgetWeekStringRateList[index].weekend
        )
      );
    },
    zenkakuToHankaku(str: string): string {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
    cancelChanges() {
      this.budgetWeekStringRateList = lodash.cloneDeep(this.savedBudgetWeekStringRateList);
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    const budgetWeekRateList: BudgetWeekRate[] =
      (await RadialApiClient.getBudgetWeekRateList(Number(dayjs().format("YYYY")))) ?? [];
    // APIからはnumberですべての情報が帰ってくるため、stringにキャストする
    this.budgetWeekStringRateList = this.castNumberToString(budgetWeekRateList);
    this.savedBudgetWeekStringRateList = lodash.cloneDeep(this.budgetWeekStringRateList);
    this.isLoading = false;
  },
});
