
/*
  [ElementDatePicker]
    <必須>
    - v-model = 選択した日付(string型)

    <任意>
    - :format = 日付を表示するフォーマット(yyyy/MM/dd)
    - :type = 選択するデータのタイプ（date / month）
    - :pageType = 表示するページ (3yearsが入る場合に表示を切り替える)
    - :min = 選択可能な日付の最小値
    - :max = 選択可能な日付の最大値
    - :calendarIcon = カレンダーアイコンを表示するかどうか
    - :innerClass = v-date-pickerに付与するクラス
    - :prefix = 先頭に付ける文言
    - :noSeasonOption = 検索オプションからシーズンを消す
    - :alignLeft = ポップアップが画面からはみ出す場合にtrueにする
    - @input = 確定時に呼ばれるイベント
*/
import Vue from "vue";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import RadialApiClient from "@/api/RadialApiClient";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

// The setting to use element-ui
import DatePicker from "element-ui";
import { DatePickerOptions } from "element-ui/types/date-picker";
Vue.use(DatePicker);

import { RangeDatesTemplate } from "@/store/model";

import { Season } from "@/api/entities";

export interface DataType {
  showMenu: boolean;
  hoverIndex: number | null;
  hoverSeasonIndex: number | null;
  seasons: Season[];
}

export default Vue.extend({
  name: "element-range-date-picker",
  props: {
    value: {
      type: Array as () => string[],
      required: false,
      default: null,
    },
    format: {
      type: String,
      required: false,
      default: "yyyy-MM-dd",
    },
    type: {
      type: String,
      required: false,
      default: "date",
    },
    pageType: {
      type: String,
      required: false,
      default: "",
    },
    min: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: String,
      required: false,
      default: null,
    },
    calendarIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    innerClass: {
      type: String,
      required: false,
      default: "",
    },
    prefix: {
      type: String,
      required: false,
      default: null,
    },
    noSeasonOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      showMenu: false,
      hoverIndex: null,
      hoverSeasonIndex: null,
      seasons: [],
    };
  },
  methods: {
    focus() {
      this.showMenu = true;
    },
    unFocus() {
      this.showMenu = false;
      this.hoverIndex = null;
      this.hoverSeasonIndex = null;
      this.$emit("submit");
    },
    panelFocus() {
      window.setTimeout(this.focus, 10);
    },
    panelUnFocus() {
      window.setTimeout(this.unFocus, 10);
    },
    oneWeekDayjs(minusWeek = 0): { from: Dayjs; to: Dayjs } {
      // 曜日取得
      const day = dayjs().day();
      // 本日、日曜日の場合先週の月曜日から今日(日曜日)までを範囲とする
      // 月曜日始まりにするため下でaddしている(dayjsの週間始まりは日曜日)のとバランスをとるため
      if (day === 0) {
        minusWeek += 1;
      }
      const from = dayjs().subtract(minusWeek, "week").startOf("weeks").add(1, "day");
      const to =
        minusWeek > 0 ? dayjs().subtract(minusWeek, "week").endOf("weeks").add(1, "day") : dayjs().endOf("day");
      return { from: from, to: to };
    },
    hover(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hoverIndex = index;
      } else if (hoverFlag === 1) {
        this.hoverIndex = null;
      }
    },
    hoverSeason(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hoverSeasonIndex = index;
      } else if (hoverFlag === 1) {
        this.hoverSeasonIndex = null;
      }
    },
    selectRange(index: number, seasonIndexFlag: number) {
      // seasonIndexFlag === 1の時シーズンが選択されている
      if (seasonIndexFlag === 0) {
        if (this.pageType === "3years") {
          if (index !== 8) {
            // index === 8はシーズンが選択されており別の処理のため例外処理
            this.date = [this.rangeDatesTemplates[index].from, this.rangeDatesTemplates[index].to];
          }
        } else {
          if (index !== 6) {
            // index === 6はシーズンが選択されており別の処理のため例外処理
            this.date = [this.rangeDatesTemplates[index].from, this.rangeDatesTemplates[index].to];
          }
        }
      } else {
        // シーズンが選択されたときの処理
        this.date = [
          dayjs(this.seasons[index].startYearMonth).format("YYYY-MM-DD"),
          dayjs(this.seasons[index].endYearMonth).add(1, "month").date(0).format("YYYY-MM-DD"),
        ];
      }
    },
    getSeasonRange(season: Season): string {
      return `(${dayjs(season.startYearMonth).format("YY/MM")}~${dayjs(season.endYearMonth).format("YY/MM")})`;
    },
  },
  computed: {
    date: {
      get(): string[] | null {
        if (this.value.length !== 0) {
          return [dayjs(this.value[0]).format("YYYY-MM-DD"), dayjs(this.value[1]).format("YYYY-MM-DD")];
        } else {
          return null;
        }
      },
      set(newValue: string[] | Date[] | null) {
        if (newValue) {
          if (this.type === "date") {
            // 以下のmin, maxの制限のせいでそれを超えたシーズン期間での検索にバグが起こってしまっている
            // 制限範囲を超えた検索はUI上disabledになっているため、一旦コメントアウトしてバグ対応とする
            // this.$emit("input", [
            //   dayjs(this.min !== null && dayjs(newValue[0]).isBefore(this.min) ? this.min : newValue[0]).format(
            //     "YYYY-MM-DD"
            //   ),
            //   dayjs(this.max !== null && dayjs(newValue[1]).isAfter(this.max) ? this.max : newValue[1]).format(
            //     "YYYY-MM-DD"
            //   ),
            // ]);
            this.$emit("input", [dayjs(newValue[0]).format("YYYY-MM-DD"), dayjs(newValue[1]).format("YYYY-MM-DD")]);
          } else {
            // 以下のmin, maxの制限のせいでそれを超えたシーズン期間での検索にバグが起こってしまっている
            // 制限範囲を超えた検索はUI上disabledになっているため、一旦コメントアウトしてバグ対応とする
            // this.$emit("input", [
            //   dayjs(this.min !== null && dayjs(newValue[0]).isBefore(this.min) ? this.min : newValue[0]).format(
            //     "YYYY-MM-DD"
            //   ),
            //   dayjs(this.max !== null && dayjs(newValue[1]).isAfter(this.max) ? this.max : newValue[1])
            //     .add(1, this.type as ManipulateType)
            //     .date(0)
            //     .format("YYYY-MM-DD"),
            // ]);
            this.$emit("input", [
              dayjs(newValue[0]).format("YYYY-MM-DD"),
              dayjs(newValue[1])
                .add(1, this.type as ManipulateType)
                .date(0)
                .format("YYYY-MM-DD"),
            ]);
          }
        } else {
          this.$emit("input", null);
        }
      },
    },
    inBetweenDatesPickerOptions(): DatePickerOptions {
      return {
        disabledDate: (time) => {
          if (this.min && this.max) {
            return !dayjs(time.getTime()).isBetween(dayjs(this.min).subtract(1, "day"), dayjs(this.max).add(1, "day"));
          } else if (this.min) {
            return dayjs(time).isBefore(dayjs(this.min).subtract(1, "day").add(1, "day").format("YYYY-MM-DD"));
          } else if (this.max) {
            return dayjs(time).isAfter(dayjs(this.max));
          } else {
            return false;
          }
        },
      };
    },
    rangeDatesTemplates(): RangeDatesTemplate[] {
      if (this.type === "date") {
        let templates: {
          name: string;
          from: string;
          to: string;
        }[] = [];
        if (this.pageType === "3years") {
          templates = [
            {
              name: "今週（月曜〜本日）",
              from: this.oneWeekDayjs(0).from.format("YYYY-MM-DD").toString(),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "先週（月曜〜日曜）",
              from: this.oneWeekDayjs(1).from.format("YYYY-MM-DD").toString(),
              to: this.oneWeekDayjs(1).to.format("YYYY-MM-DD").toString(),
            },
            {
              name: "直近7日間",
              from: dayjs().subtract(6, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "直近30日間",
              from: dayjs().subtract(29, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "直近90日間",
              from: dayjs().subtract(89, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "直近1年間",
              from: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "昨年同時期",
              from: dayjs().subtract(1, "year").subtract(90, "day").format("YYYY-MM-DD"),
              to: dayjs().subtract(1, "year").add(90, "day").format("YYYY-MM-DD"),
            },
            {
              name: "一昨年同時期",
              from: dayjs().subtract(2, "year").subtract(90, "day").format("YYYY-MM-DD"),
              to: dayjs().subtract(2, "year").add(90, "day").format("YYYY-MM-DD"),
            },
          ];
        } else {
          templates = [
            {
              name: "今週（月曜〜本日）",
              from: this.oneWeekDayjs(0).from.format("YYYY-MM-DD").toString(),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "先週（月曜〜日曜）",
              from: this.oneWeekDayjs(1).from.format("YYYY-MM-DD").toString(),
              to: this.oneWeekDayjs(1).to.format("YYYY-MM-DD").toString(),
            },
            {
              name: "直近7日間",
              from: dayjs().subtract(6, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "直近30日間",
              from: dayjs().subtract(29, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "直近90日間",
              from: dayjs().subtract(89, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
            {
              name: "直近1年間",
              from: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
              to: dayjs().format("YYYY-MM-DD"),
            },
          ];
        }
        return this.min !== null
          ? templates.filter((template) => {
              return dayjs(template.from) >= dayjs(this.min);
            })
          : templates;
      } else {
        const templates = [
          {
            name: "直近3ヶ月間",
            from: dayjs().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          },
          {
            name: "直近6ヶ月間",
            from: dayjs().subtract(5, "month").startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          },
          {
            name: "直近1年間",
            from: dayjs().subtract(11, "month").startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          },
        ];
        return this.min !== null
          ? templates.filter((template) => {
              return dayjs(template.from) >= dayjs(this.min);
            })
          : templates;
      }
    },
  },
  async mounted() {
    this.seasons = (await RadialApiClient.listSeasons()) ?? [];
    this.seasons.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      } else {
        return 1;
      }
    });
  },
  watch: {
    date: {
      async handler() {},
    },
  },
});
