
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { Category } from "@/api/entities";
import { UpdateCategoryDto, CreateCategoryDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: string | null;
  selectedIndex: number;
  categories: Category[];
  selectedCategory: Category | null;
  dialogAddCategory: boolean;
  dialogEditCategory: boolean;
  dialogDeleteCategory: boolean;
  newCategoryInfo: {
    code: string | null;
    name: string;
  };
  page: number;
  pageSize: number;
  list: Category[];
  listCount: number;
}

export default Vue.extend({
  name: "Category",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      selectedIndex: 0,
      categories: [],
      selectedCategory: null,
      dialogAddCategory: false,
      dialogEditCategory: false,
      dialogDeleteCategory: false,
      newCategoryInfo: {
        code: null,
        name: "",
      },
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {
    editCategoryDisabled(): boolean {
      if (this.selectedCategory) {
        return (
          !this.newCategoryInfo.name || // カテゴリ名が空の場合
          (this.newCategoryInfo.name === this.selectedCategory.name &&
            this.newCategoryInfo.code === this.selectedCategory.code) || // 変更が何もない場合
          (this.newCategoryInfo.name !== this.selectedCategory.name &&
            this.list.some((category) => category.name === this.newCategoryInfo?.name)) || // カテゴリ名が他のカテゴリと一致していた場合
          (this.newCategoryInfo.code !== this.selectedCategory.code &&
            this.list.some((category) => category.code === this.newCategoryInfo?.code)) // カテゴリコードが他のカテゴリと一致していた場合
        );
      }
      return false;
    },
    addCategoryDisabled(): boolean {
      if (!this.newCategoryInfo.name || !this.newCategoryInfo.code) {
        // カテゴリ名が空もしくはカテゴリコードが空の場合
        return true;
      } else if (
        this.list.some((category) => {
          return category.name === this.newCategoryInfo.name || category.code === this.newCategoryInfo.code;
        })
      ) {
        // カテゴリ名またはカテゴリコードが他のカテゴリと一致していた場合
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    sortList() {
      this.list.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
    },
    selectCategory(category: Category, index: number) {
      this.selectedCategory = { ...category };
      if (index === 0) {
        this.dialogEditCategory = true;
        this.newCategoryInfo = {
          code: this.selectedCategory.code,
          name: this.selectedCategory.name,
        };
      } else {
        this.dialogDeleteCategory = true;
      }
    },
    deselectCategory() {
      this.selectedCategory = null;
      this.dialogEditCategory = false;
      this.newCategoryInfo = {
        code: null,
        name: "",
      };
      this.dialogDeleteCategory = false;
    },
    cancelAddCategory() {
      this.dialogAddCategory = false;
      this.newCategoryInfo = {
        code: null,
        name: "",
      };
    },
    addCategory() {
      this.dialogAddCategory = true;
      this.newCategoryInfo = {
        code: null,
        name: "",
      };
    },
    async createCategory() {
      this.$emit("isLoading", true);
      if (this.list.some((category) => category.code === this.newCategoryInfo.code)) {
        this.showAlert(
          `カテゴリーコードが重複しております。
          入力内容をご確認のうえ、もう一度お試しください。`,
          "error"
        );
      } else {
        if (this.user) {
          const dto: CreateCategoryDto = {
            company: { connect: { id: this.user.companyId } },
            name: this.newCategoryInfo.name,
            code: this.newCategoryInfo.code,
          };
          const category = await RadialApiClient.createCategory(dto);
          if (category) {
            this.list.push(category);
            this.listCount = this.list.length;
            this.sortList();
            this.updatePage(this.page);
            this.showAlert("新しくアイテムカテゴリが作成されました！", "success");
            this.newCategoryInfo = {
              code: null,
              name: "",
            };
            this.dialogAddCategory = false;
          } else {
            this.showAlert(
              `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
        }
      }
      this.$emit("isLoading", false);
    },
    async editCategory() {
      this.$emit("isLoading", true);
      if (this.selectedCategory) {
        if (
          this.selectedCategory.code !== this.newCategoryInfo.code &&
          this.list.some((category) => category.code === this.newCategoryInfo.code)
        ) {
          this.showAlert(
            `カテゴリーコードが重複しております。
            入力内容をご確認のうえ、もう一度お試しください。`,
            "error"
          );
        } else {
          const dto: UpdateCategoryDto = {
            name: this.newCategoryInfo.name,
            code: this.newCategoryInfo.code,
          };
          await RadialApiClient.updateCategory(this.selectedCategory.id, dto)
            .then(async () => {
              const list = await RadialApiClient.listCategories();
              if (list) {
                this.list = list;
                this.sortList();
                this.updatePage(this.page);
              }
              this.selectedCategory = null;
              this.newCategoryInfo = {
                code: null,
                name: "",
              };
              this.dialogEditCategory = false;
            })
            .catch(() => {
              this.showAlert(
                "エラーが発生しております。\n入力内容をご確認のうえ、時間を空けてからもう一度お試しください。",
                "error"
              );
            });
        }
      }
      this.$emit("isLoading", false);
    },
    async deleteCategory() {
      this.dialogDeleteCategory = false;
      this.$emit("isLoading", true);
      if (this.selectedCategory) {
        await RadialApiClient.deleteCategory(this.selectedCategory.id)
          .then(async () => {
            const list = await RadialApiClient.listCategories();
            if (list) {
              this.list = list;
              this.listCount = this.list.length;
              this.sortList();
              this.updatePage(this.page);
            }
            this.showAlert("無事に削除が完了しました！", "success");
          })
          .catch(() => {
            this.showAlert(
              "エラーが発生しております。\n入力内容をご確認のうえ、時間を空けてからもう一度お試しください。",
              "error"
            );
          });
      }
      this.selectedCategory = null;
      this.$emit("isLoading", false);
    },
    getAlertMessage(): string {
      if (this.selectedCategory) {
        return `本当に${this.selectedCategory.name}を削除してもよろしいですか？
                このアイテムカテゴリに紐づく以下の全てのデータも合わせて削除されます。

                ・シーズンMD計画情報
                ・品番情報
                ・sku情報
                ・発注情報
                ・コメント
                ・アラート
                ・カスタムタグ`;
      } else {
        return "";
      }
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.categories = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    const list = await RadialApiClient.listCategories();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.categories = this.list;
      } else {
        this.categories = this.list.slice(0, this.pageSize);
      }
    }
    this.$emit("isLoading", false);
  },
});
