
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { Attribute } from "@/api/entities";
import { CreateAttributeDto, UpdateAttributeDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: string | null;
  attributes: Attribute[];
  newAttributeInfo: {
    code: string | null;
    name: string;
  };
  dialogAddAttribute: boolean;
  dialogEditAttribute: boolean;
  dialogDeleteAttribute: boolean;
  selectedAttribute: Attribute | null;
  page: number;
  pageSize: number;
  list: Attribute[];
  listCount: number;
}

export default Vue.extend({
  name: "Attribute",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      attributes: [],
      newAttributeInfo: {
        code: null,
        name: "",
      },
      dialogAddAttribute: false,
      dialogEditAttribute: false,
      dialogDeleteAttribute: false,
      selectedAttribute: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
    tabIndex: {
      type: String,
      required: true,
    },
  },
  computed: {
    editAttributeDisabled(): boolean {
      if (this.selectedAttribute) {
        return (
          !this.selectedAttribute.name || // 属性名が空の場合
          (this.newAttributeInfo.name === this.selectedAttribute.name &&
            this.newAttributeInfo.code === this.selectedAttribute.code) || // 変更が何もない場合
          (this.newAttributeInfo.name !== this.selectedAttribute.name &&
            this.list.some((attribute) => attribute.name === this.newAttributeInfo?.name)) || // 属性名が他の属性と一致していた場合
          (this.newAttributeInfo.code !== this.selectedAttribute.code &&
            this.list.some((attribute) => attribute.code === this.newAttributeInfo?.code)) // 属性コードが他の属性と一致していた場合
        );
      }
      return false;
    },
    addAttributeDisabled(): boolean {
      if (!this.newAttributeInfo.name || !this.newAttributeInfo.code) {
        // 属性名が空もしくは属性コードが空の場合
        return true;
      } else if (
        this.list.some((attribute) => {
          return attribute.name === this.newAttributeInfo.name || attribute.code === this.newAttributeInfo.code;
        })
      ) {
        // カテゴリ名またはカテゴリコードが他のカテゴリと一致していた場合
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    sortList() {
      this.list.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
    },
    getAttributeInfo(): string {
      switch (this.tabIndex) {
        case "attribute1":
          return "属性1";
        case "attribute2":
          return "属性2";
        case "attribute3":
          return "属性3";
        default:
          return "";
      }
    },
    selectAttribute(attribute: Attribute, index: number) {
      this.selectedAttribute = { ...attribute };
      if (index === 0) {
        this.newAttributeInfo = {
          code: this.selectedAttribute.code,
          name: this.selectedAttribute.name,
        };
        this.dialogEditAttribute = true;
      } else {
        this.dialogDeleteAttribute = true;
      }
    },
    deselectAttribute() {
      this.selectedAttribute = null;
      this.dialogEditAttribute = false;
      this.dialogDeleteAttribute = false;
    },
    cancelAddAttribute() {
      this.dialogAddAttribute = false;
      this.newAttributeInfo = {
        code: null,
        name: "",
      };
    },
    addAttribute() {
      this.dialogAddAttribute = true;
      this.newAttributeInfo = {
        code: null,
        name: "",
      };
    },
    async createAttribute() {
      this.$emit("isLoading", true);
      if (this.list.some((attribute) => attribute.code === this.newAttributeInfo.code)) {
        this.showAlert(
          `カテゴリーコードが重複しております。
          入力内容をご確認のうえ、もう一度お試しください。`,
          "error"
        );
      } else {
        if (this.user) {
          const dto: CreateAttributeDto = {
            company: { connect: { id: this.user.companyId } },
            number: Number(this.tabIndex.replace("attribute", "")),
            name: this.newAttributeInfo.name,
            code: this.newAttributeInfo.code,
          };
          const attribute = await RadialApiClient.createAttribute(dto);
          if (attribute) {
            this.list.push(attribute);
            this.listCount = this.list.length;
            this.sortList();
            this.updatePage(this.page);
            this.showAlert("新しく属性が作成されました！", "success");
            this.newAttributeInfo = {
              code: null,
              name: "",
            };
            this.dialogAddAttribute = false;
          } else {
            this.showAlert(
              `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
        }
      }
      this.$emit("isLoading", false);
    },
    async editAttribute() {
      this.$emit("isLoading", true);
      if (this.selectedAttribute) {
        if (
          this.selectedAttribute.code !== this.newAttributeInfo.code &&
          this.list.some((attribute) => attribute.code === this.newAttributeInfo.code)
        ) {
          this.showAlert(
            `カテゴリーコードが重複しております。
            入力内容をご確認のうえ、もう一度お試しください。`,
            "error"
          );
        } else {
          const dto: UpdateAttributeDto = {
            number: this.selectedAttribute.number,
            name: this.newAttributeInfo.name,
            code: this.newAttributeInfo.code,
          };
          await RadialApiClient.updateAttribute(this.selectedAttribute.id, dto)
            .then(async () => {
              const list = ((await RadialApiClient.listAttributes()) ?? []).filter((attribute) => {
                switch (this.tabIndex) {
                  case "attribute1":
                    return attribute.number === 1;
                  case "attribute2":
                    return attribute.number === 2;
                  case "attribute3":
                    return attribute.number === 3;
                }
              });
              if (list) {
                this.list = list;
                this.sortList();
                this.updatePage(this.page);
              }
              this.selectedAttribute = null;
              this.newAttributeInfo = {
                code: null,
                name: "",
              };
              this.dialogEditAttribute = false;
            })
            .catch(() => {
              this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
            });
        }
      }
      this.$emit("isLoading", false);
    },
    async deleteAttribute() {
      this.dialogDeleteAttribute = false;
      this.$emit("isLoading", true);
      if (this.selectedAttribute) {
        await RadialApiClient.deleteAttribute(this.selectedAttribute.id)
          .then(async (result) => {
            const list = ((await RadialApiClient.listAttributes()) ?? []).filter((attribute) => {
              switch (this.tabIndex) {
                case "attribute1":
                  return attribute.number === 1;
                case "attribute2":
                  return attribute.number === 2;
                case "attribute3":
                  return attribute.number === 3;
              }
            });
            if (list) {
              this.list = list;
              this.sortList();
              this.updatePage(this.page);
            }
            this.showAlert("無事に削除が完了しました！", "success");
          })
          .catch(() => {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          });
      }
      this.selectedAttribute = null;
      this.$emit("isLoading", false);
    },
    getAlertMessage(): string {
      if (this.selectedAttribute) {
        return `本当に${this.selectedAttribute.name}を削除してもよろしいですか？`;
      } else {
        return "";
      }
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.attributes = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    const list = ((await RadialApiClient.listAttributes()) ?? []).filter((attribute) => {
      switch (this.tabIndex) {
        case "attribute1":
          return attribute.number === 1;
        case "attribute2":
          return attribute.number === 2;
        case "attribute3":
          return attribute.number === 3;
      }
    });
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.attributes = this.list;
      } else {
        this.attributes = this.list.slice(0, this.pageSize);
      }
    }
    this.$emit("isLoading", false);
  },
});
