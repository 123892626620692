
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { Color } from "@/api/entities";
import { CreateColorDto, UpdateColorDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: string | null;
  selectedIndex: number;
  colors: Color[];
  newColors: { colorName: string; colorCode: string }[];
  dialogAddColor: boolean;
  dialogEditColor: boolean;
  dialogDeleteColor: boolean;
  selectedColor: Color | null;
  page: number;
  pageSize: number;
  list: Color[];
  listCount: number;
}

export default Vue.extend({
  name: "Color",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      selectedIndex: 0,
      colors: [],
      newColors: [{ colorName: "", colorCode: "" }],
      dialogAddColor: false,
      dialogEditColor: false,
      dialogDeleteColor: false,
      selectedColor: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {
    editColorDisabled(): boolean {
      if (this.selectedColor) {
        if (this.selectedColor.name === "" || this.selectedColor.code === "" || this.isProhibited) {
          return true;
        }
        if (this.selectedColor.name === this.colors[this.selectedIndex].name) {
          return this.colors.some((color) => {
            return color.code === this.selectedColor?.code;
          });
        } else if (this.selectedColor.code === this.colors[this.selectedIndex].code) {
          return this.colors.some((color) => {
            color.name === this.selectedColor?.name;
          });
        } else {
          return this.colors.some((color) => {
            return color.code === this.selectedColor?.code || color.name === this.selectedColor?.name;
          });
        }
      }
      return true;
    },
    addColorDisabled(): boolean {
      // コード、名前が空の場合、禁止文字列が使用されている場合
      if (
        this.newColors.some((newColor) => newColor.colorCode === "" || newColor.colorName === "") ||
        this.isProhibited
      ) {
        return true;
      }
      // 一括作成時に同じフォーム内でコードもしくは名前に重複がある場合
      const { colorNameCounts, colorCodeCounts } = this.newColors.reduce(
        (acc, color) => {
          acc.colorNameCounts[color.colorName] = (acc.colorNameCounts[color.colorName] || 0) + 1;
          acc.colorCodeCounts[color.colorCode] = (acc.colorCodeCounts[color.colorCode] || 0) + 1;
          return acc;
        },
        { colorNameCounts: {}, colorCodeCounts: {} }
      );
      if (
        Object.values(colorNameCounts).some((count) => (count as number) > 1) ||
        Object.values(colorCodeCounts).some((count) => (count as number) > 1)
      ) {
        return true;
      }
      // 既存のカラーとコードまたは名前が一致する場合
      return this.list.some((color) => {
        return this.newColors.some(
          (newColor) => newColor.colorCode === color.code || newColor.colorName === color.name
        );
      });
    },
    isProhibited() {
      const prohibitedChars = /\\|\/|:|\*|\?|"|<|>|\||_/;
      if (this.newColors.some((color) => prohibitedChars.test(color.colorName))) {
        return true;
      } else if (this.selectedColor?.name) {
        return prohibitedChars.test(this.selectedColor.name);
      } else {
        return false;
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    selectColor(color: Color, index: number, selectedIndex: number) {
      this.selectedColor = { ...color };
      if (index === 0) {
        this.selectedIndex = selectedIndex;
        this.dialogEditColor = true;
      } else {
        this.dialogDeleteColor = true;
      }
    },
    deselectColor() {
      this.selectedColor = null;
      this.dialogEditColor = false;
      this.dialogDeleteColor = false;
    },
    sortList() {
      this.list.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return (a.name ?? 0) > (b.name ?? 0) ? 1 : -1;
        }
      });
    },
    setNewColor() {
      this.newColors.push({
        colorCode: "",
        colorName: "",
      });
    },
    removeNewColor(index: number) {
      this.newColors.splice(index, 1);
    },
    async addColor() {
      this.$emit("isLoading", true);

      if (this.user) {
        let isError = false;
        await Promise.all(
          this.newColors.map(async (newColor, index) => {
            const dto: CreateColorDto = {
              company: { connect: { id: this.user?.companyId } },
              name: this.newColors[index].colorName,
              code: this.newColors[index].colorCode,
            };
            const createdColor = await RadialApiClient.createColor(dto);
            if (createdColor) {
              this.list.push(createdColor);
            } else {
              isError = true;
            }
          })
        );
        if (isError) {
          this.showAlert(
            `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        } else {
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("新しくカラーが作成されました！", "success");
        }
      }
      this.newColors = [];
      this.$emit("isLoading", false);
      this.dialogAddColor = false;
    },
    cancelAddColor() {
      this.dialogAddColor = false;
      this.newColors = [];
    },
    async editColor() {
      this.$emit("isLoading", true);
      if (this.selectedColor) {
        const dto: UpdateColorDto = {
          name: this.selectedColor.name,
          code: this.selectedColor.code,
        };
        await RadialApiClient.updateColor(this.selectedColor.id, dto);
        const list = await RadialApiClient.listColors();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
        this.selectedColor = null;
        this.$emit("isLoading", false);
        this.dialogEditColor = false;
      }
    },
    async deleteColor() {
      this.dialogDeleteColor = false;
      this.$emit("isLoading", true);
      if (this.selectedColor) {
        await RadialApiClient.deleteColor(this.selectedColor.id);
        const list = await RadialApiClient.listColors();
        if (list) {
          this.list = list;
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.selectedColor = null;
      this.$emit("isLoading", false);
      this.showAlert("無事に削除が完了しました！", "success");
    },
    getAlertMessage(): string {
      if (this.selectedColor) {
        return `本当に${this.selectedColor.name}を削除してもよろしいですか？
                このサイズに紐づく以下の全てのデータも合わせて削除されます。

                ・sku情報
                ・発注情報
                ・コメント
                ・アラート`;
      } else {
        return "";
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.colors = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    const list = await RadialApiClient.listColors();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.colors = this.list;
      } else {
        this.colors = this.list.slice(0, this.pageSize);
      }
    }
    this.$emit("isLoading", false);
  },
});
