
import { ShopifyJanCodeRecognitionKey } from "@/api/entities";
import Vue from "vue";

export interface DataType {
  janCodeRecognitionKey: ShopifyJanCodeRecognitionKey;
}

export default Vue.extend({
  name: "IntegrationShopifyEc",
  data(): DataType {
    return { janCodeRecognitionKey: "SKU" };
  },
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    integrationBargainTypes: {
      type: Object,
      required: true,
    },
    bargainTypes: {
      type: Array as () => Object[],
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    shopifyPosEcStatus: {
      type: Number,
      required: true,
    },
    shopifyShopName: {
      type: String,
      required: true,
    },
  },
  computed: {
    dialogShopifyEc: {
      get(): boolean {
        return (this as any).value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    integrateShopifyEc() {
      this.$emit("integrate", {
        integrationBargainTypes: this.integrationBargainTypes,
        janCodeRecognitionKey: this.shopifyPosEcStatus !== 0 ? this.janCodeRecognitionKey : null
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
});
