
/*
    [Checkbox]
      <必須>
      - v-model = テキストフィールドに入力された文字列
      - :label = テキストフィールドのタイトル
  
      <任意>
      - @change = テキストフィールドが変更されたときに呼ばれるイベント
  */
import Vue from "vue";
export default Vue.extend({
  name: "CustomTextField",
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    data: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
  },
  computed: {
    model: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit("input", newValue);
      },
    },
  },
});
