import VueGtag from "vue-gtag";

import { Constant } from "@/store/constant";
import AuthClient from "../api/AuthClient";
import Vue from "vue";

export class Gtag {
  static async init() {
    const user = await AuthClient.getUserInfo();
    if (user) {
      const id = Constant.ga4TrackingIdDatas.find((data) => data.companyId === user.companyId)?.trackingId ?? ""; // GA4で追加したいアカウントが増えた場合は発行したTrackingIDとCompanyIDのペアをConstant.tsに追加する
      Vue.use(VueGtag, {
        // pageTrackerExcludedRoutes: [""], pageviewを自動送信したくないページを追加(routerのページ名を追加する), pageviewするページはpageviewメソッドを叩く
        config: { id },
        params: { send_page_view: false },
      });
    }
  }
}
