
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import {
  Company,
  Store,
  ShopifyStoreEntity,
  FutureshopJanCodeRecognitionKey,
  CustomIntegration,
  Service,
  SmaregiIntegration,
  FutureshopIntegration,
  ShopifyIntegration,
  BaseJanCodeRecognitionKey,
  BaseIntegration,
  SmaregiCategory,
  SmaregiUnitPrice,
  SmaregiOption,
  SmaregiOptionType,
  SmaregiFreeLabel,
  MakeshopIntegration,
  MakeshopJanCodeRecognitionKey,
  ShopifyOption,
  ShopifyOptionType,
  ShopifyCustomization,
  SmaregiProductNumber,
  NextEngineIntegration,
  NextEngineJanCodeRecognitionKey,
  RadialConnection,
  ShopifyJanCodeRecognitionKey,
} from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import {
  BargainType,
  IntegrationBargainName,
  IntegrationBargainTypes,
  User,
  RadialConnectionItem,
  SmaregiCsvItems,
  SmaregiRequiredCsvSetting,
  SmaregiOptionalCsvSetting,
  SmaregiOriginalCsvSetting,
  ShopifyCsvItems,
  ShopifyOptionalCsvSetting,
  ShopifyOriginalCsvSetting,
} from "@/store/model";
import { Constant } from "@/store/constant";
import {
  UpdateStoreDto,
  CreateFutureshopIntegrationDto,
  LogizardAuthorizeDto,
  CreateSmaregiIntegrationDto,
  LogizardJanCodeRecognitionKey,
  UpdateLogizardIntegrationDto,
  UpdateShopifyIntegrationDto,
  UpdateSmaregiIntegrationDto,
  UpdateFutureshopIntegrationDto,
  UpdateCustomIntegrationDto,
  ShopifyAuthorizeDto,
  CreateMakeshopIntegrationDto,
  CreateShopifyCustomizationDto,
  UpdateShopifyCustomizationDto,
  NextEngineAuthorizeDto,
  CreateSmaregiOptionDto,
  UpdateSmaregiOptionDto,
  CreateShopifyOptionDto,
  UpdateShopifyOptionDto,
} from "@/api/dto";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import IntegrationSmaregi from "@/views/integration/IntegrationSmaregi.vue";
import IntegrationFutureshop from "@/views/integration/IntegrationFutureshop.vue";
import IntegrationBase from "@/views/integration/IntegrationBase.vue";
import IntegrationMakeshop from "@/views/integration/IntegrationMakeshop.vue";
import IntegrationLogizard from "@/views/integration/IntegrationLogizard.vue";
import IntegrationShopifyPos from "@/views/integration/IntegrationShopifyPos.vue";
import IntegrationShopifyEc from "@/views/integration/IntegrationShopifyEc.vue";
import IntegrationNextEngine from "@/views/integration/IntegrationNextEngine.vue";

import mixin from "@/mixin/mixin";
import * as lodash from "lodash";
import dayjs, { Dayjs } from "dayjs";

export class ShopifyStoreDto {
  posEcStatus: number; //レジポスで連携するときは:1 ECで連携するのは:2
  stores: ShopifyStoreItemDto[];
}

export class ShopifyStoreItemDto {
  locationName: string;
  posId: string; //locationId
  posEcWarehouseStatus: number; //未選択:0 pos:1 EC:2 warehouse:3
}

export interface EditingFutureshopIntegration {
  // futureshop連携情報の作成及び更新のための型
  shopKey: string;
  clientId: string;
  clientSecret: string;
  janCode: FutureshopJanCodeRecognitionKey;
  stockSynchronized: boolean;
}

export interface EditingBaseIntegration {
  // BASE連携情報の作成及び更新のための型
  refreshToken: string;
  janCode: BaseJanCodeRecognitionKey;
  stockSynchronized: boolean;
}

export interface EditingMakeshopIntegration {
  // MakeShop連携情報の作成及び更新のための型
  shopId: string;
  productToken: string;
  orderToken: string;
  janCode: MakeshopJanCodeRecognitionKey;
  stockSynchronized: boolean;
}

export interface EditingNextEngineIntegration {
  // NextEngine連携情報の作成及び更新のための型
  url: string;
  janCode: NextEngineJanCodeRecognitionKey;
  stockSynchronized: boolean;
}

export interface SmaregiRequiredCsvSettingStatus {
  category: SmaregiCategory | null;
  unitPrice: SmaregiUnitPrice | null;
  productNumber: SmaregiProductNumber | null;
}

export interface ShopifyRequiredCsvSettingStatus {
  category: SmaregiCategory | null;
  unitPrice: SmaregiUnitPrice | null;
  productNumber: SmaregiProductNumber | null;
}

export interface SmaregiCsvSettingOption {
  smaregiOptionId: string;
  smaregiOption: SmaregiOptionType | null;
  radialConnection: RadialConnection | null;
  reserveItemsCode: string | null;
  free: string | null;
}

export interface ShopifyOptionWithRadialConnections {
  ids: string[];
  name: ShopifyOptionType;
  radialConnections: RadialConnection[];
  multiple: boolean;
}

export interface DataType {
  isLoading: boolean;
  shopifyStores: ShopifyStoreEntity[];
  dialogDeintegrateSmaregi: boolean;
  dialogDeintegrateLogizard: boolean;
  dialogDeintegrateFutureshop: boolean;
  dialogDeintegrateShopify: boolean;
  dialogDeintegrateBase: boolean;
  dialogDeintegrateMakeshop: boolean;
  dialogDeintegrateNextEngine: boolean;
  dialogUpdateFutureshopSkuLogic: boolean;
  dialogUpdateBaseSkuLogic: boolean;
  dialogUpdateLogizardSkuLogic: boolean;
  dialogUpdateMakeshopSkuLogic: boolean;
  dialogUpdateNextEngineSkuLogic: boolean;
  user: User | null;
  dialogSmaregi: boolean;
  dialogFutureshop: boolean;
  dialogBase: boolean;
  dialogLogizard: boolean;
  dialogShopifyPos: boolean;
  dialogShopifyEc: boolean;
  dialogMakeshop: boolean;
  dialogNextEngine: boolean;
  smaregiContractId: string | null;
  logizardGroupId: string;
  logizardConnectionId: string;
  logizardUserId: string | null;
  logizardPassword: string;
  futureshopIntegration: FutureshopIntegration | null;
  newFutureshopIntegration: EditingFutureshopIntegration | null;
  baseIntegration: BaseIntegration | null;
  newBaseIntegration: EditingBaseIntegration | null;
  makeshopIntegration: MakeshopIntegration | null;
  newMakeshopIntegration: EditingMakeshopIntegration | null;
  nextEngineIntegration: NextEngineIntegration | null;
  newNextEngineIntegration: EditingNextEngineIntegration | null;
  company: Company | null;
  tabIndex: string;
  isEditingSmaregi: boolean;
  isEditingFutureshop: boolean;
  isEditingBase: boolean;
  isEditingLogizard: boolean;
  isEditingShopify: boolean;
  isEditingCustom: boolean;
  isEditingMakeshop: boolean;
  isEditingNextEngine: boolean;
  editingShopifyPosEc: string | null;
  isFocusingSmaregi: boolean;
  isFocusingFutureshop: boolean;
  isFocusingBase: boolean;
  isFocusingLogizard: boolean;
  isFocusingShopifyPos: boolean;
  isFocusingShopifyEc: boolean;
  isFocusingCustom: boolean;
  isFocusingMakeshop: boolean;
  isFocusingNextEngine: boolean;
  smaregiTab: string;
  logizardTab: string;
  shopifyTab: string;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  logizardItemIds: { str: string; key: LogizardJanCodeRecognitionKey }[];
  logizardKeys: { str: string; key: LogizardJanCodeRecognitionKey[] }[];
  logizardItemId: LogizardJanCodeRecognitionKey | null;
  logizardKey: LogizardJanCodeRecognitionKey[];
  logizardKey1: LogizardJanCodeRecognitionKey | null;
  logizardKey2: LogizardJanCodeRecognitionKey | null;
  shopifyPassword: string;
  shopifyShortcutDialog: boolean;
  buttonDisabled: boolean;
  bargainTypes: BargainType[];
  integrationBargainTypes: IntegrationBargainTypes;
  integrationBargainNames: IntegrationBargainName[];
  customIntegrations: CustomIntegration[];
  customEditingIndex: number | null;
  services: Service[];
  smaregiIntegration: SmaregiIntegration | null;
  shopifyIntegration: ShopifyIntegration | null;
  shopifyStoreStatuses: ShopifyStoreEntity[];
  savedShopifyStoreStatuses: ShopifyStoreEntity[];
  smaregiRequiredCsvSetting: SmaregiRequiredCsvSettingStatus;
  savedSmaregiRequiredCsvSetting: SmaregiRequiredCsvSettingStatus;
  shopifyCustomizationItems: ShopifyCustomization[];
  savedShopifyCustomizationItems: ShopifyCustomization[];
  radialConnections: RadialConnectionItem[];
  shopifyShopName: string;
  shopifyAccessToken: string | null;
  smaregiOptions: SmaregiOption[];
  savedSmaregiOptions: SmaregiOption[];
  smaregiFreeLabels: SmaregiFreeLabel[];
  smaregiCsvItems: SmaregiCsvItems;
  shopifyOptions: ShopifyOption[];
  savedShopifyOptions: ShopifyOption[];
  optionalShopifyOptions: ShopifyOptionWithRadialConnections[];
  savedOptionalShopifyOptions: ShopifyOptionWithRadialConnections[];
  shopifyCsvItems: ShopifyCsvItems;
}

export default Vue.extend({
  name: "Integration",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    Loading,
    IntegrationSmaregi,
    IntegrationFutureshop,
    IntegrationBase,
    IntegrationMakeshop,
    IntegrationNextEngine,
    IntegrationLogizard,
    IntegrationShopifyPos,
    IntegrationShopifyEc,
  },
  data(): DataType {
    return {
      isLoading: false,
      shopifyStores: [],
      dialogDeintegrateSmaregi: false,
      dialogDeintegrateLogizard: false,
      dialogDeintegrateFutureshop: false,
      dialogDeintegrateShopify: false,
      dialogDeintegrateBase: false,
      dialogDeintegrateMakeshop: false,
      dialogDeintegrateNextEngine: false,
      dialogUpdateFutureshopSkuLogic: false,
      dialogUpdateBaseSkuLogic: false,
      dialogUpdateLogizardSkuLogic: false,
      dialogUpdateMakeshopSkuLogic: false,
      dialogUpdateNextEngineSkuLogic: false,
      user: null,
      dialogSmaregi: false,
      dialogFutureshop: false,
      dialogBase: false,
      dialogLogizard: false,
      dialogShopifyPos: false,
      dialogShopifyEc: false,
      dialogMakeshop: false,
      dialogNextEngine: false,
      smaregiContractId: null,
      logizardGroupId: "",
      logizardConnectionId: "",
      logizardUserId: null,
      logizardPassword: "",
      futureshopIntegration: null,
      newFutureshopIntegration: null,
      baseIntegration: null,
      newBaseIntegration: null,
      makeshopIntegration: null,
      newMakeshopIntegration: null,
      nextEngineIntegration: null,
      newNextEngineIntegration: null,
      company: null,
      tabIndex: "connected",
      isEditingSmaregi: false,
      isEditingFutureshop: false,
      isEditingBase: false,
      isEditingLogizard: false,
      isEditingShopify: false,
      isEditingCustom: false,
      isEditingMakeshop: false,
      isEditingNextEngine: false,
      editingShopifyPosEc: null,
      isFocusingSmaregi: true,
      isFocusingFutureshop: true,
      isFocusingBase: true,
      isFocusingLogizard: true,
      isFocusingShopifyPos: true,
      isFocusingShopifyEc: true,
      isFocusingCustom: true,
      isFocusingMakeshop: true,
      isFocusingNextEngine: true,
      smaregiTab: "smaregi-master",
      logizardTab: "logizard-sku",
      shopifyTab: "shopify-master",
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      logizardItemIds: [
        { str: "JANコード", key: "JANCODE" },
        { str: "品番", key: "ITEMNUMBER" },
      ],
      logizardKeys: [
        { str: "key1:カラーコード,key2:サイズコード", key: ["COLORCODE", "SIZECODE"] },
        { str: "key1:サイズコード,key2カラーコード", key: ["SIZECODE", "COLORCODE"] },
      ],
      logizardItemId: null,
      logizardKey: [],
      logizardKey1: "COLORCODE",
      logizardKey2: "SIZECODE",
      shopifyPassword: "",
      shopifyShortcutDialog: false,
      buttonDisabled: true,
      bargainTypes: Constant.bargainTypes,
      integrationBargainTypes: { productBargain: [], headBargain: [] },
      integrationBargainNames: Constant.integrationBargainNames,
      customIntegrations: [],
      customEditingIndex: null,
      services: [],
      smaregiIntegration: null,
      shopifyIntegration: null,
      shopifyStoreStatuses: [],
      savedShopifyStoreStatuses: [],
      smaregiRequiredCsvSetting: {
        category: null,
        unitPrice: null,
        productNumber: null,
      },
      savedSmaregiRequiredCsvSetting: {
        category: null,
        unitPrice: null,
        productNumber: null,
      },
      shopifyCustomizationItems: [],
      savedShopifyCustomizationItems: [],
      radialConnections: Constant.radialConnections,
      shopifyShopName: "",
      shopifyAccessToken: null,
      smaregiOptions: [],
      savedSmaregiOptions: [],
      smaregiFreeLabels: [],
      smaregiCsvItems: Constant.smaregiCsvItems,
      shopifyOptions: [],
      savedShopifyOptions: [],
      optionalShopifyOptions: [],
      savedOptionalShopifyOptions: [],
      shopifyCsvItems: Constant.shopifyCsvItems,
    };
  },
  watch: {
    logizardItemId: {
      async handler() {
        if (this.logizardItemId === "JANCODE") {
          this.logizardKey = [];
        }
      },
    },
  },
  computed: {
    isCustomIntegrated(): boolean {
      return this.company?.customIntegrations !== undefined;
    },
    isSmaregiIntegrated(): boolean {
      if (this.company) {
        if (this.company.smaregiIntegration) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isLogizardIntegrated(): boolean {
      if (this.company) {
        if (this.company.logizardIntegration) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isFutureshopIntegrated(): boolean {
      if (this.company) {
        if (this.company.futureshopIntegration) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isMakeshopIntegrated(): boolean {
      if (this.company?.makeshopIntegration ?? false) {
        return true;
      }
      return false;
    },
    isNextEngineIntegrated(): boolean {
      if (this.company?.nextEngineIntegration ?? false) {
        return true;
      }
      return false;
    },
    isBaseIntegrated(): boolean {
      if (this.company) {
        if (this.company.baseIntegration) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    shopifyIntegrationStatus(): number {
      if (!this.company?.shopifyIntegration) {
        if (!this.shopifyShopName && !this.shopifyAccessToken) {
          return 0; // shopifyAppStoreでradialアプリをインストールしていない状態
        } else {
          return 1; // shopifyAppStoreでradialアプリをインストールしている && 初回連携を行っていない状態
        }
      } else {
        if (this.shopifyStoreStatuses.map((Store) => Store.posEcWarehouseStatus).includes(0)) {
          return 2; // 店舗と倉庫の連携をしていない状態
        } else {
          return 3; // 連携が完了している状態
        }
      }
    },
    shopifyPosEcStatus(): number {
      if (this.company?.shopifyIntegration) {
        if (this.company.shopifyIntegration.posEcStatus === 0) {
          return 0; // POS
        } else if (this.company.shopifyIntegration.posEcStatus === 1) {
          return 1; // EC
        } else {
          return 2; // POS EC
        }
      } else {
        return -1;
      }
    },
    isShopifyPOSIntegrated(): boolean {
      if (this.company) {
        if (this.company.shopifyIntegration && this.company.shopifyIntegration.posEcStatus !== 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isShopifyECIntegrated(): boolean {
      if (this.company) {
        if (this.company.shopifyIntegration && this.company.shopifyIntegration.posEcStatus > 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    enableKey(): boolean {
      if (this.logizardItemId === "ITEMNUMBER") {
        return true;
      } else {
        return false;
      }
    },
    shopifyStoreStatusTable(): { id: number; name: string }[] {
      if (this.shopifyPosEcStatus === 0) {
        // posアプリとしてShopifyを連携
        return [
          { id: 1, name: "店舗" },
          { id: 2, name: "倉庫" },
        ];
      } else if (this.shopifyPosEcStatus === 1) {
        // ecアプリとしてShopifyを連携
        return [{ id: 2, name: "倉庫" }];
      } else {
        // this.shopifyPosEcStatus === 2 posEcアプリとしてShopifyを連携
        return [
          { id: 1, name: "店舗" },
          { id: 2, name: "倉庫" },
        ];
      }
    },
    isSameSmaregiCsvSettingStatus(): boolean {
      return (
        lodash.isEqual(this.smaregiRequiredCsvSetting, this.savedSmaregiRequiredCsvSetting) &&
        lodash.isEqual(this.smaregiOptions, this.savedSmaregiOptions)
      );
    },
    isSameShopifyCsvSettingStatus(): boolean {
      return (
        lodash.isEqual(this.shopifyOptions, this.savedShopifyOptions) &&
        lodash.isEqual(this.shopifyCustomizationItems, this.savedShopifyCustomizationItems) &&
        lodash.isEqual(this.optionalShopifyOptions, this.savedOptionalShopifyOptions)
      );
    },
    canShopifyUpdate(): boolean {
      // TODO: shopify申請用にcompanyId指定して表示している
      return (
        this.canUpdate ||
        this.user?.adminUserName === "cs" ||
        this.user?.companyId === "a0ca3458-528f-4658-b0f1-ad474a9c421d"
      );
    },
    optionalSmaregiOptions(): SmaregiOption[] {
      return this.smaregiOptions.filter(
        (smaregiOption) => smaregiOption.name !== "RESERVEITEMS" && smaregiOption.name !== "TAXDIVISION"
      );
    },
    originalSmaregiOptions(): SmaregiOption[] {
      return this.smaregiOptions.filter((smaregiOption) => smaregiOption.name === "TAXDIVISION");
    },
    freeSmaregiOptions(): SmaregiOption[] {
      return this.smaregiOptions.filter((smaregiOption) => smaregiOption.name === "RESERVEITEMS");
    },
    requiredShopifyOptions(): ShopifyOption[] {
      return this.shopifyOptions.filter((shopifyOption) =>
        this.shopifyCsvItems.requiredItems.map((item) => item.key).includes(shopifyOption.name)
      );
    },
    originalShopifyOptions(): ShopifyOption[] {
      return this.shopifyOptions.filter((shopifyOption) =>
        this.shopifyCsvItems.originalItems.map((item) => item.key).includes(shopifyOption.name)
      );
    },
  },
  methods: {
    editIntegration(bool: boolean) {
      this.isFocusingSmaregi = !bool;
      this.isFocusingFutureshop = !bool;
      this.isFocusingBase = !bool;
      this.isFocusingLogizard = !bool;
      this.isFocusingShopifyPos = !bool;
      this.isFocusingShopifyEc = !bool;
      this.isFocusingCustom = !bool;
      this.isFocusingMakeshop = !bool;
      this.isFocusingNextEngine = !bool;
      this.isEditingSmaregi = bool;
      this.isEditingFutureshop = bool;
      this.isEditingBase = bool;
      this.isEditingLogizard = bool;
      this.isEditingShopify = bool;
      this.isEditingCustom = bool;
      this.isEditingMakeshop = bool;
      this.isEditingNextEngine = bool;
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async updateLoizardCombination() {
      this.logizardKey1 = this.logizardKey[0] ?? null;
      this.logizardKey2 = this.logizardKey[1] ?? null;
      if (this.logizardItemId && this.company && this.company.logizardIntegration) {
        const dto: UpdateLogizardIntegrationDto = {
          itemId: this.logizardItemId,
          key1: this.logizardKey1,
          key2: this.logizardKey2,
        };
        await RadialApiClient.updateLogizardIntegration(this.company.logizardIntegration.id, dto);
      }
      this.buttonDisabled = true;
      this.dialogUpdateLogizardSkuLogic = false;
    },
    cancelUpdateLoizardCombination() {
      if (this.company) {
        this.logizardItemId = this.company.logizardIntegration?.itemId ?? null;
        this.logizardKey1 = this.company.logizardIntegration?.key1 ?? null;
        this.logizardKey2 = this.company.logizardIntegration?.key2 ?? null;
      }
      this.buttonDisabled = true;
    },
    async updateSmaregiWebhookSetting() {
      // スマレジWebhook処理のための店舗及び倉庫の設定導線
      if (this.smaregiIntegration) {
        const updatedSmaregiIntegration = await RadialApiClient.updateSmaregiIntegration(this.smaregiIntegration.id, {
          storeSynchronized: this.smaregiIntegration.storeSynchronized,
          warehouseSynchronized: this.smaregiIntegration.warehouseSynchronized,
        });
        if (updatedSmaregiIntegration) {
          this.showAlert("スマレジの設定が更新されました！", "success");
          this.smaregiIntegration = updatedSmaregiIntegration;
        } else {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
    },
    async updateFutureshopIntegration() {
      // futureshopIntegrationの更新
      if (this.futureshopIntegration && this.newFutureshopIntegration) {
        const updatedFutureshopIntegration = await RadialApiClient.updateFutureshopIntegration(
          this.futureshopIntegration.id,
          {
            stockSynchronized: this.newFutureshopIntegration.stockSynchronized,
          }
        );
        if (updatedFutureshopIntegration) {
          this.showAlert("futureshopの設定が更新されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
    },
    async updateMakeshopIntegration() {
      // makeshopIntegrationの更新
      if (this.makeshopIntegration && this.newMakeshopIntegration) {
        const updatedMakeshopIntegration = await RadialApiClient.updateMakeshopIntegration(
          this.makeshopIntegration.id,
          {
            stockSynchronized: this.newMakeshopIntegration.stockSynchronized,
          }
        );
        if (updatedMakeshopIntegration) {
          this.showAlert("MakeShopの設定が更新されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
    },
    async updateNextEngineIntegration() {
      // nextEngineIntegrationの更新
      if (this.nextEngineIntegration && this.newNextEngineIntegration) {
        const updatedNextEngineIntegration = await RadialApiClient.updateNextEngineIntegration(
          this.nextEngineIntegration.id,
          {
            stockSynchronized: this.newNextEngineIntegration.stockSynchronized,
          }
        );
        if (updatedNextEngineIntegration) {
          this.showAlert("MakeShopの設定が更新されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
    },
    async updateBaseIntegration() {
      // baseIntegrationの更新
      if (this.baseIntegration && this.newBaseIntegration) {
        const updatedBaseIntegration = await RadialApiClient.updateBaseIntegration(this.baseIntegration.id, {
          stockSynchronized: this.newBaseIntegration.stockSynchronized,
        });
        if (updatedBaseIntegration) {
          this.showAlert("BASEの設定が更新されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
    },
    async updateFutureshopSkuLogic() {
      if (this.futureshopIntegration && this.newFutureshopIntegration) {
        const futureshopIntegration = await RadialApiClient.updateFutureshopIntegrationLogic(
          this.futureshopIntegration.id,
          {
            shopKey: this.newFutureshopIntegration.shopKey,
            clientId: this.newFutureshopIntegration.clientId,
            clientSecret: this.newFutureshopIntegration.clientSecret,
            janCode: this.newFutureshopIntegration.janCode,
            headProductCouponDiscountTotal: this.integrationBargainTypes.headBargain[1].number,
            headVolumeDiscountTotal: this.integrationBargainTypes.headBargain[0].number,
            stockSynchronized: this.newFutureshopIntegration.stockSynchronized,
          }
        );
        if (futureshopIntegration) {
          this.showAlert(
            `futureshopとの連携ロジックを更新しました！
            売上データの取得を開始します。
            数時間後、データをご確認ください。`,
            "success"
          );
        } else {
          this.showAlert(
            `エラーが発生しました。
            ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
            "error"
          );
        }
      }
      this.buttonDisabled = true;
      this.dialogUpdateFutureshopSkuLogic = false;
    },
    async updateBaseSkuLogic() {
      if (this.baseIntegration && this.newBaseIntegration) {
        const baseIntegration = await RadialApiClient.updateBaseIntegrationLogic(this.baseIntegration.id, {
          refreshToken: this.newBaseIntegration.refreshToken,
          janCode: this.newBaseIntegration.janCode,
          stockSynchronized: this.newBaseIntegration.stockSynchronized,
        });
        if (baseIntegration) {
          this.showAlert(
            `BASEとの連携ロジックを更新しました！
            売上データの取得を開始します。
            数時間後、データをご確認ください。`,
            "success"
          );
        } else {
          this.showAlert(
            `エラーが発生しました。
            ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
            "error"
          );
        }
      }
      this.buttonDisabled = true;
      this.dialogUpdateBaseSkuLogic = false;
    },
    async updateMakeshopSkuLogic() {
      if (this.makeshopIntegration && this.newMakeshopIntegration) {
        const makeshopIntegration = await RadialApiClient.updateMakeshopIntegrationLogic(this.makeshopIntegration.id, {
          shopId: this.newMakeshopIntegration.shopId,
          productToken: this.newMakeshopIntegration.productToken,
          orderToken: this.newMakeshopIntegration.orderToken,
          janCode: this.newMakeshopIntegration.janCode,
          stockSynchronized: this.newMakeshopIntegration.stockSynchronized,
        });
        if (makeshopIntegration) {
          this.showAlert(
            `MakeShopとの連携ロジックを更新しました！
            売上データの取得を開始します。
            数時間後、データをご確認ください。`,
            "success"
          );
        } else {
          this.showAlert(
            `エラーが発生しました。
            ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
            "error"
          );
        }
      }
      this.buttonDisabled = true;
      this.dialogUpdateMakeshopSkuLogic = false;
    },
    async updateNextEngineSkuLogic() {
      if (this.nextEngineIntegration && this.newNextEngineIntegration) {
        const nextEngineIntegration = await RadialApiClient.updateNextEngineIntegrationLogic(
          this.nextEngineIntegration.id,
          {
            janCode: this.newNextEngineIntegration.janCode,
          }
        );
        if (nextEngineIntegration) {
          this.showAlert(
            `MakeShopとの連携ロジックを更新しました！
            売上データの取得を開始します。
            数時間後、データをご確認ください。`,
            "success"
          );
        } else {
          this.showAlert(
            `エラーが発生しました。
            ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
            "error"
          );
        }
      }
      this.buttonDisabled = true;
      this.dialogUpdateNextEngineSkuLogic = false;
    },
    cancelUpdateFutureshopSkuLogic() {
      this.newFutureshopIntegration = this.futureshopIntegration
        ? {
            shopKey: this.futureshopIntegration.shopKey,
            clientId: this.futureshopIntegration.clientId,
            clientSecret: this.futureshopIntegration.clientSecret,
            janCode: this.futureshopIntegration.janCode,
            stockSynchronized: this.futureshopIntegration.stockSynchronized,
          }
        : null;
      this.buttonDisabled = true;
    },
    cancelUpdateBaseSkuLogic() {
      this.newBaseIntegration = this.baseIntegration
        ? {
            refreshToken: this.baseIntegration.refreshToken,
            janCode: this.baseIntegration.janCode,
            stockSynchronized: this.baseIntegration.stockSynchronized,
          }
        : null;
      this.buttonDisabled = true;
    },
    cancelUpdateMakeshopSkuLogic() {
      this.newMakeshopIntegration = this.makeshopIntegration
        ? {
            shopId: this.makeshopIntegration.shopId,
            productToken: this.makeshopIntegration.productToken,
            orderToken: this.makeshopIntegration.orderToken,
            janCode: this.makeshopIntegration.janCode,
            stockSynchronized: this.makeshopIntegration.stockSynchronized,
          }
        : null;
      this.buttonDisabled = true;
    },
    cancelUpdateNextEngineSkuLogic() {
      this.newNextEngineIntegration = this.nextEngineIntegration
        ? {
            url: "",
            janCode: this.nextEngineIntegration.janCode,
            stockSynchronized: this.nextEngineIntegration.stockSynchronized,
          }
        : null;
      this.buttonDisabled = true;
    },
    async updateCustomIntegrationBargainTypes(customServiceNumber: number): Promise<IntegrationBargainTypes> {
      const customIntegration = this.customIntegrations[customServiceNumber];
      this.integrationBargainTypes = {
        productBargain: this.integrationBargainNames[0].productBargains.map((item, index) => {
          return {
            id: index,
            name: item.value,
            number: customIntegration?.[item.key as keyof CustomIntegration] as number,
          };
        }),
        headBargain: this.integrationBargainNames[0].headBargains.map((item, index) => {
          return {
            id: index,
            name: item.value,
            number: customIntegration?.[item.key as keyof CustomIntegration] as number,
          };
        }),
      };
      return this.integrationBargainTypes;
    },
    async updateIntegrationBargainTypes(serviceNumber: number): Promise<IntegrationBargainTypes | null> {
      if (this.user) {
        this.company = await RadialApiClient.getCompany(this.user.companyId);
      }
      switch (serviceNumber) {
        // スマレジの場合
        case 1: {
          this.integrationBargainTypes = {
            productBargain: this.integrationBargainNames[1].productBargains.map((item, index) => {
              return {
                id: index,
                name: item.value,
                number: (this.smaregiIntegration?.[item.key as keyof SmaregiIntegration] as number) ?? 0,
              };
            }),
            headBargain: this.integrationBargainNames[1].headBargains.map((item, index) => {
              return {
                id: index,
                name: item.value,
                number: (this.smaregiIntegration?.[item.key as keyof SmaregiIntegration] as number) ?? 0,
              };
            }),
          };
          return this.integrationBargainTypes;
        }
        // futureshopの場合
        case 2: {
          this.integrationBargainTypes = {
            productBargain: this.integrationBargainNames[2].productBargains.map((item, index) => {
              return {
                id: index,
                name: item.value,
                number: (this.futureshopIntegration?.[item.key as keyof FutureshopIntegration] as number) ?? 0,
              };
            }),
            headBargain: this.integrationBargainNames[2].headBargains.map((item, index) => {
              return {
                id: index,
                name: item.value,
                number: (this.futureshopIntegration?.[item.key as keyof FutureshopIntegration] as number) ?? 0,
              };
            }),
          };
          return this.integrationBargainTypes;
        }
        // shopifyの場合(3がPOS、4がEC)
        case 3:
        case 4: {
          if (this.shopifyIntegrationStatus === 2) {
            this.shopifyTab = "shopify-store";
          }
          this.integrationBargainTypes = {
            productBargain: this.integrationBargainNames[serviceNumber].productBargains.map((item, index) => {
              return {
                id: index,
                name: item.value,
                number: (this.company?.shopifyIntegration?.[item.key as keyof ShopifyIntegration] as number) ?? 0,
              };
            }),
            headBargain: this.integrationBargainNames[serviceNumber].headBargains.map((item, index) => {
              return {
                id: index,
                name: item.value,
                number: (this.company?.shopifyIntegration?.[item.key as keyof ShopifyIntegration] as number) ?? 0,
              };
            }),
          };
          return this.integrationBargainTypes;
        }
        // BASEの場合
        case 5: {
          // TODO: ver4.15以降にBASEの値引き対応を行う。
          // this.integrationBargainTypes = {
          //   productBargain: this.integrationBargainNames[5].productBargains.map((item, index) => {
          //     return {
          //       id: index,
          //       name: item.value,
          //       number: (this.baseIntegration?.[item.key as keyof BaseIntegration] as number) ?? 0
          //     };
          //   }),
          //   headBargain: this.integrationBargainNames[5].headBargains.map((item, index) => {
          //     return {
          //       id: index,
          //       name: item.value,
          //       number: (this.baseIntegration?.[item.key as keyof BaseIntegration] as number) ?? 0
          //     };
          //   })
          // };
          return this.integrationBargainTypes;
        }
        default:
          return null;
      }
    },
    async updateCsvSettings(serviceNumber: number) {
      switch (serviceNumber) {
        // スマレジの場合
        case 1:
          this.isLoading = true;
          const smaregiOptions = (await RadialApiClient.listSmaregiOption()) ?? [];
          this.smaregiOptions = smaregiOptions.sort((a, b) => {
            if (a.id > b.id) {
              return 1;
            } else {
              return -1;
            }
          });
          this.smaregiFreeLabels =
            (await RadialApiClient.getSmaregiFreeLabel(this.smaregiIntegration?.contractId ?? "")) ?? [];
          this.savedSmaregiOptions = lodash.cloneDeep(this.smaregiOptions);
          this.isLoading = false;
          return;
        // Shopifyの場合
        case 2:
          this.isLoading = true;
          // shopifyOptionに関する情報を更新する
          await this.updateShopifyOptionInfo();
          this.isLoading = false;
          return;
      }
    },
    async updateCustomBargainLogic(index: number) {
      const dto: UpdateCustomIntegrationDto = {
        productBargain: this.integrationBargainTypes.productBargain[0].number,
        headPointBargain: this.integrationBargainTypes.headBargain[0].number,
        headCouponBargain: this.integrationBargainTypes.headBargain[1].number,
        headBundleBargain: this.integrationBargainTypes.headBargain[2].number,
        headRoundingBargain: this.integrationBargainTypes.headBargain[3].number,
      };
      const updatedCustomIntegration = await RadialApiClient.updateCustomIntegration(
        this.customIntegrations[index].id,
        dto
      );
      if (updatedCustomIntegration) {
        this.showAlert(
          `カスタムアプリの値引き項目の設定変更連携が完了しました！
          以後、radialでcsv経由で登録する売上データに設定変更内容が反映される予定です。`,
          "success"
        );
        this.customIntegrations.splice(index, 1, updatedCustomIntegration);
        this.updateCustomIntegrationBargainTypes(index);
      }
      this.buttonDisabled = true;
    },
    async updateBargainLogic(index: number) {
      this.isLoading = true;
      switch (index) {
        // smaregiの値引き項目設定変更時
        case 1:
          if (this.company?.smaregiIntegration) {
            const dto: UpdateSmaregiIntegrationDto = {
              productUnitDiscountPrice: this.integrationBargainTypes?.productBargain[0].number,
              productApplyBargainDiscountPrice: this.integrationBargainTypes?.productBargain[1].number,
              headDiscountPriceProportional: this.integrationBargainTypes?.headBargain[0].number,
              headDiscountCouponProportional: this.integrationBargainTypes?.headBargain[1].number,
              headProductBundleProportional: this.integrationBargainTypes?.headBargain[2].number,
              headRoundingPriceProportional: this.integrationBargainTypes?.headBargain[3].number,
            };
            this.smaregiIntegration = await RadialApiClient.updateSmaregiIntegration(
              this.company.smaregiIntegration.id,
              dto
            );
            if (this.smaregiIntegration) {
              this.showAlert(
                `スマレジの値引き項目の設定変更連携が完了しました！
                以後、radialで取得する売上データに設定変更内容が反映される予定です。`,
                "success"
              );
            }
            this.updateIntegrationBargainTypes(1);
          }
          break;
        // futureshopの値引き項目設定変更時
        case 2:
          if (this.company?.futureshopIntegration) {
            const dto: UpdateFutureshopIntegrationDto = {
              headVolumeDiscountTotal: this.integrationBargainTypes?.headBargain[0].number,
              headProductCouponDiscountTotal: this.integrationBargainTypes?.headBargain[1].number,
            };
            this.futureshopIntegration = await RadialApiClient.updateFutureshopIntegration(
              this.company.futureshopIntegration.id,
              dto
            );
            if (this.futureshopIntegration) {
              this.showAlert(
                `futurshopの値引き項目の設定変更連携が完了しました！
                以後、radialで取得する売上データに設定変更内容が反映される予定です。`,
                "success"
              );
            }
            this.updateIntegrationBargainTypes(2);
          }
          break;
        // shopifyの値引き項目設定変更時(3がPOSの時、4がECの時)
        case 3:
        case 4:
          if (this.company?.shopifyIntegration) {
            const dto: UpdateShopifyIntegrationDto =
              index === 3 // shopifyPOSの場合
                ? {
                    posProductUnitDiscountPrice: this.integrationBargainTypes?.productBargain[0].number,
                    posHeadDiscountPrice: this.integrationBargainTypes?.headBargain[0].number,
                  }
                : // shopifyECの場合
                  {
                    ecProductUnitDiscountPrice: this.integrationBargainTypes?.productBargain[0].number,
                    ecHeadDiscountPrice: this.integrationBargainTypes?.headBargain[0].number,
                  };
            this.shopifyIntegration = await RadialApiClient.updateShopifyIntegration(
              this.company.shopifyIntegration.id,
              dto
            );
            if (this.shopifyIntegration) {
              this.showAlert(
                `shopify${index === 3 ? "POS" : "EC"}の値引き項目の設定変更連携が完了しました！
                以後、radialで取得する売上データに設定変更内容が反映される予定です。`,
                "success"
              );
            }
            this.updateIntegrationBargainTypes(index);
          }
          break;
        default:
          return "";
      }
      this.isLoading = false;
      this.buttonDisabled = true;
    },
    cancelCustomUpdateBargainLogic(index: number) {
      this.updateCustomIntegrationBargainTypes(index);
      this.buttonDisabled = true;
    },
    cancelUpdateBargainLogic(index: number) {
      this.updateIntegrationBargainTypes(index);
      this.buttonDisabled = true;
    },
    getServiceName(serviceId: number): string | null {
      return this.services.find((item) => item.id === serviceId)?.name ?? null;
    },
    getServiceType(serviceId: number): string {
      const service = this.services.find((item) => item.id === serviceId);
      if (service?.manual && service.type === "POS") {
        return "レジポスアプリ";
      } else if (service?.manual && service.type === "EC") {
        return "ECアプリ";
      } else {
        return "";
      }
    },
    async integrateSmaregi(event: { smaregiContractId: string; integrationBargainTypes: IntegrationBargainTypes }) {
      if (this.company) {
        if (!event.smaregiContractId) {
          this.dialogSmaregi = false;
          this.showAlert("スマレジ契約IDを入力してください。", "error");
        } else {
          const dto: CreateSmaregiIntegrationDto = {
            company: {
              connect: {
                id: this.company.id,
              },
            },
            contractId: event.smaregiContractId,
            productUnitDiscountPrice: event.integrationBargainTypes?.productBargain[0].number,
            productApplyBargainDiscountPrice: event.integrationBargainTypes?.productBargain[1].number,
            headDiscountPriceProportional: event.integrationBargainTypes?.headBargain[0].number,
            headDiscountCouponProportional: event.integrationBargainTypes?.headBargain[1].number,
            headProductBundleProportional: event.integrationBargainTypes?.headBargain[2].number,
            headRoundingPriceProportional: event.integrationBargainTypes?.headBargain[3].number,
          };
          const status = (await RadialApiClient.smaregiAuthorize(dto))?.status;
          if (status === 0 && this.user) {
            this.company = await RadialApiClient.getCompany(this.user.companyId);
            if (this.company?.smaregiIntegration) {
              this.smaregiIntegration = this.company.smaregiIntegration;
            }
            this.dialogSmaregi = false;
            this.editIntegration(true);
            this.isFocusingSmaregi = true;
            this.smaregiTab = "smaregi-master";
            this.showAlert(
              `スマレジとの連携が完了しました！
              売上及び在庫データの取得を開始します。
              数時間以内に完了しますのでしばらくお待ちください。
              続いて、連携管理画面より店舗と倉庫の紐付けを行いましょう。`,
              "success"
            );
          } else if (status === 1) {
            this.dialogSmaregi = false;
            this.showAlert(
              `スマレジ連携が正常に行われていないようです。
              入力情報を再度ご確認ください。
              解決しない場合はcs@proces.co.jpまでお問い合わせくださいませ。`,
              "error"
            );
          } else if (status === 2) {
            this.dialogSmaregi = false;
            this.showAlert(
              `この契約IDはすでに別のアカウントと連携されているようです。
              入力情報を再度ご確認ください。`,
              "warning"
            );
          }
        }
      }
    },
    async integrateLogizard(event: {
      logizardGroupId: string;
      logizardConnectionId: string;
      logizardUserId: string | null;
      logizardPassword: string;
      logizardItemId: LogizardJanCodeRecognitionKey | null;
      logizardKey: LogizardJanCodeRecognitionKey[];
    }) {
      if (this.company) {
        if (
          event.logizardUserId === null ||
          event.logizardPassword === "" ||
          event.logizardGroupId === "" ||
          event.logizardConnectionId === ""
        ) {
          this.showAlert("未入力の項目があります。入力してください。", "warning");
        } else {
          this.logizardKey1 = event.logizardKey[0];
          this.logizardKey2 = event.logizardKey[1];
          if (event.logizardItemId) {
            const dto: LogizardAuthorizeDto = {
              companyId: this.company.id,
              userId: event.logizardUserId,
              password: event.logizardPassword,
              groupId: event.logizardGroupId,
              connectionId: event.logizardConnectionId,
              itemId: event.logizardItemId,
              key1: event.logizardKey[0],
              key2: event.logizardKey[1],
            };
            this.isLoading = true;
            const status = (await RadialApiClient.logizardAuthorize(dto))?.status;
            this.isLoading = false;
            if (status === 0 && this.user) {
              this.company = await RadialApiClient.getCompany(this.user.companyId);
              if (this.company?.logizardIntegration) {
                this.company.logizardIntegration.connectionId = event.logizardUserId;
              }
              this.dialogLogizard = false;
              this.editIntegration(true);
              this.isFocusingLogizard = true;
              this.showAlert(
                `ロジザードとの連携が完了しました！
                在庫データの取得を開始します。
                数十分後、在庫データをご確認ください。`,
                "success"
              );
              this.logizardTab = "logizard-sku";
            } else if (status === 1) {
              this.showAlert(
                `グループ番号または接続番号が間違っているようです。
                再度入力情報をご確認ください。
                解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
                "warning"
              );
            } else if (status === 2) {
              this.showAlert(
                `ユーザーIDまたはパスワードが間違っています。
                再度入力情報をご確認ください。
                解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
                "warning"
              );
            } else if (status === 3) {
              this.showAlert(
                `このユーザーIDはすでに連携されています。
                ロジザードにお問い合わせください。
                解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
                "warning"
              );
            } else if (status === 4) {
              this.showAlert(
                `エラーが発生しております。
                もしもう一度お試しいただき解決されなければ、
                cs@proces.co.jp
                にご連絡くださいませ。`,
                "error"
              );
            }
          }
        }
      }
    },
    async integrateFutureshop(event: { newFutureshopIntegration: EditingFutureshopIntegration }) {
      if (this.company && event.newFutureshopIntegration) {
        if (event.newFutureshopIntegration.clientId === null) {
          this.showAlert("APIキーをご入力ください", "warning");
        } else if (event.newFutureshopIntegration.clientSecret === "") {
          this.showAlert("APIパスワードをご入力ください", "warning");
        } else if (event.newFutureshopIntegration.shopKey === "") {
          this.showAlert("店舗KEYをご入力ください", "warning");
        } else if (event.newFutureshopIntegration) {
          this.isLoading = true;
          const dto: CreateFutureshopIntegrationDto = {
            company: {
              connect: {
                id: this.company.id,
              },
            },
            clientId: event.newFutureshopIntegration.clientId,
            clientSecret: event.newFutureshopIntegration.clientSecret,
            shopKey: event.newFutureshopIntegration.shopKey,
            janCode: event.newFutureshopIntegration.janCode,
            headVolumeDiscountTotal: this.integrationBargainTypes?.headBargain[0].number,
            headProductCouponDiscountTotal: this.integrationBargainTypes?.headBargain[1].number,
          };
          const status = (await RadialApiClient.futureshopAuthorize(dto))?.status;
          this.isLoading = false;
          if (status === 0 && this.user) {
            this.showAlert(
              `futureshopとの連携が完了しました！
              売上データの取得を開始します。
              数十分後、在庫データをご確認ください。`,
              "success"
            );
            this.dialogFutureshop = false;
          } else if (status === 1) {
            this.showAlert(
              `店舗KEY、APIキーまたはAPIパスワードが間違っています。
              再度API認証情報をご確認ください。
              解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
              "warning"
            );
          } else if (status === 2) {
            this.showAlert(
              `このAPIキーはすでに連携されています。
              再度API認証情報を確認ください。
              解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
              "warning"
            );
          } else if (status === 4) {
            this.showAlert(
              `エラーが発生しました。
              ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
              "error"
            );
          }
        }
      }
    },
    async integrateMakeshop(event: { newMakeshopIntegration: EditingMakeshopIntegration }) {
      if (this.company && event.newMakeshopIntegration) {
        if (event.newMakeshopIntegration.productToken === null) {
          this.showAlert("商品データ連携認証コードをご入力ください", "warning");
        } else if (event.newMakeshopIntegration.orderToken === "") {
          this.showAlert("注文データ連携認証コードをご入力ください", "warning");
        } else if (event.newMakeshopIntegration.shopId === "") {
          this.showAlert("店舗IDをご入力ください", "warning");
        } else if (event.newMakeshopIntegration) {
          const dto: CreateMakeshopIntegrationDto = {
            company: {
              connect: {
                id: this.company.id,
              },
            },
            productToken: event.newMakeshopIntegration.productToken,
            orderToken: event.newMakeshopIntegration.orderToken,
            shopId: event.newMakeshopIntegration.shopId,
            janCode: event.newMakeshopIntegration.janCode,
          };
          this.isLoading = true;
          const status = (await RadialApiClient.makeshopAuthorize(dto))?.status;
          this.isLoading = false;
          if (status === 0 && this.user) {
            this.showAlert(
              `MakeShopとの連携が完了しました！
              売上データの取得を開始します。
              数十分後、在庫データをご確認ください。`,
              "success"
            );
            this.dialogMakeshop = false;
          } else if (status === 1) {
            this.showAlert(
              `店舗ID、商品データ連携認証コードまたは注文データ連携認証コードが間違っています。
              再度API認証情報をご確認ください。
              解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
              "warning"
            );
          } else if (status === 2) {
            this.showAlert(
              `この店舗IDはすでに連携されています。
              再度API認証情報を確認ください。
              解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
              "warning"
            );
          } else if (status === 4) {
            this.showAlert(
              `エラーが発生しました。
              ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
              "error"
            );
          }
        }
      }
    },
    async integrateNextEngine(event: { newNextEngineIntegration: EditingNextEngineIntegration }) {
      if (this.company && event.newNextEngineIntegration) {
        if (event.newNextEngineIntegration.url === "") {
          this.showAlert("URLをご入力ください", "warning");
        } else if (event.newNextEngineIntegration) {
          const url = new URL(event.newNextEngineIntegration.url);
          const params = new URLSearchParams(url.search);

          const dto: NextEngineAuthorizeDto = {
            uid: params.get("uid") ?? "",
            state: params.get("state") ?? "",
            janCode: event.newNextEngineIntegration.janCode,
            stockSynchronized: true,
          };
          this.isLoading = true;
          const status = (await RadialApiClient.nextEngineAuthorize(dto))?.status;
          this.isLoading = false;
          if (status === 0) {
            this.showAlert(
              `NextEngineとの連携が完了しました！
              売上データの取得を開始します。
              数十分後、在庫データをご確認ください。`,
              "success"
            );
            this.dialogNextEngine = false;
          } else {
            this.showAlert(
              `エラーが発生しました。
              ログアウト後、再度ログインいただき、エラーが解決されない場合はcs@proces.co.jpまでお問い合わせください。`,
              "error"
            );
          }
        }
      }
    },
    async integrateBase(event: { newBaseIntegration: EditingBaseIntegration }) {
      const apiUrl = RadialApiClient.apiUrl;
      const baseAuthLink = `https://api.thebase.in/1/oauth/authorize?response_type=code&client_id=5a26928b819d19299b519c632acfc4d1&redirect_uri=${apiUrl}/integration/base/callback-auth&scope=read_users%20read_orders%20read_items%20write_items%20write_orders&state=${this.company?.id},${event.newBaseIntegration?.janCode}`;
      window.open(baseAuthLink, "_blank");
      this.showAlert(
        `BASEの認証画面にて連携の設定を完了させましょう！
        連携完了から数分後にradialへのデータの同期が始まります。`,
        "success"
      );
      this.dialogBase = false;
    },
    async integrateShopifyPos(event: { integrationBargainTypes: IntegrationBargainTypes, janCodeRecognitionKey: ShopifyJanCodeRecognitionKey}) {
      this.isLoading = true;
      if (this.company) {
        if (!this.shopifyShopName) {
          this.dialogShopifyPos = false;
          this.showAlert("radialアプリをインストールしてください", "warning");
          return;
        } else {
          //Auth
          if (this.shopifyShopName && this.shopifyAccessToken) {
            const posEcStatus = 0;
            const dto: ShopifyAuthorizeDto = {
              shopName: this.shopifyShopName,
              accessToken: this.shopifyAccessToken,
              posEcStatus: posEcStatus,
              company: {
                connect: {
                  id: this.company.id,
                },
              },
              posProductUnitDiscountPrice: event.integrationBargainTypes?.productBargain[0].number,
              posHeadDiscountPrice: event.integrationBargainTypes?.headBargain[0].number,
              ecProductUnitDiscountPrice: this.shopifyIntegration?.ecProductUnitDiscountPrice ?? 0,
              ecHeadDiscountPrice: this.shopifyIntegration?.ecHeadDiscountPrice ?? 0,
              janCodeRecognition: event.janCodeRecognitionKey ?? this.shopifyIntegration?.janCodeRecognition
            };
            this.isLoading = true;
            const status = (await RadialApiClient.shopifyAuthorize(dto))?.status;
            this.isLoading = false;
            this.dialogShopifyPos = false;
            if (status === 0 && this.user) {
              this.company = await RadialApiClient.getCompany(this.user.companyId);
              if (this.company?.shopifyIntegration?.accessToken) {
                this.shopifyIntegration = this.company.shopifyIntegration;
                this.shopifyStoreStatuses = (await RadialApiClient.getShopifyStores()) ?? [];
                this.savedShopifyStoreStatuses = JSON.parse(JSON.stringify(this.shopifyStoreStatuses));
              }
              this.editIntegration(true);
              this.isFocusingShopifyPos = true;
              this.showAlert("ShopifyPOSとの連携が完了しました！", "success");
              // 連携内容編集画面に遷移
              this.trigger(7);
              this.updateIntegrationBargainTypes(3);
            } else {
              this.showAlert(
                `連携中に問題が発生しました。
              cs@proces.co.jpまでお問い合わせください。`,
                "warning"
              );
            }
          } else {
            this.showAlert(
              `radialアプリのインストールに失敗しています。
              cs@proces.co.jpまでお問い合わせください。`,
              "warning"
            );
          }
        }
      }
      this.isLoading = false;
    },
    async integrateShopifyEc(event: { integrationBargainTypes: IntegrationBargainTypes, janCodeRecognitionKey: ShopifyJanCodeRecognitionKey }) {
      this.isLoading = true;
      if (this.company) {
        if (!this.shopifyShopName) {
          this.dialogShopifyPos = false;
          this.showAlert("radialアプリをインストールしてください", "warning");
          return;
        } else {
          //Auth
          if (this.shopifyShopName && this.shopifyAccessToken) {
            const posEcStatus = 1;
            const dto: ShopifyAuthorizeDto = {
              shopName: this.shopifyShopName,
              accessToken: this.shopifyAccessToken,
              posEcStatus: posEcStatus,
              company: {
                connect: {
                  id: this.company.id,
                },
              },
              posProductUnitDiscountPrice: this.shopifyIntegration?.posProductUnitDiscountPrice ?? 0,
              posHeadDiscountPrice: this.shopifyIntegration?.posHeadDiscountPrice ?? 0,
              ecProductUnitDiscountPrice: event.integrationBargainTypes?.productBargain[0].number,
              ecHeadDiscountPrice: event.integrationBargainTypes?.headBargain[0].number,
              janCodeRecognition: event.janCodeRecognitionKey ?? this.shopifyIntegration?.janCodeRecognition
            };
            this.isLoading = true;
            const status = (await RadialApiClient.shopifyAuthorize(dto))?.status;
            this.isLoading = false;
            this.dialogShopifyEc = false;
            if (status === 0 && this.user) {
              this.company = await RadialApiClient.getCompany(this.user.companyId);
              if (this.company?.shopifyIntegration?.accessToken) {
                this.shopifyIntegration = this.company.shopifyIntegration;
                this.shopifyStoreStatuses = (await RadialApiClient.getShopifyStores()) ?? [];
                this.savedShopifyStoreStatuses = JSON.parse(JSON.stringify(this.shopifyStoreStatuses));
              }
              this.editIntegration(true);
              this.isFocusingShopifyPos = true;
              this.showAlert("ShopifyECとの連携が完了しました！", "success");
              // 連携内容編集画面に遷移
              this.trigger(8);
              this.updateIntegrationBargainTypes(4);
            } else {
              this.showAlert(
                `連携中に問題が発生しました。
              cs@proces.co.jpまでお問い合わせください。`,
                "warning"
              );
            }
          } else {
            this.showAlert(
              `radialアプリのインストールに失敗しています。
              cs@proces.co.jpまでお問い合わせください。`,
              "warning"
            );
          }
        }
      }
      this.isLoading = false;
    },
    async updateStore(store: Store) {
      if (!this.user) return;
      const dto: UpdateStoreDto = {
        posId: store.posId ?? undefined,
        name: store.name,
        email: store.email,
        postalCode: store.postalCode,
      };
      await RadialApiClient.updateStore(store.id, dto);
    },
    async trigger(serviceId: number) {
      switch (serviceId) {
        case 0:
          if (this.isSmaregiIntegrated) {
            this.editIntegration(true);
            this.isFocusingSmaregi = true;
          } else {
            this.dialogSmaregi = true;
            this.smaregiContractId = null;
          }
          break;
        case 2:
          if (this.isLogizardIntegrated) {
            this.editIntegration(true);
            if (this.logizardKey1 === "COLORCODE") {
              this.logizardKey = ["COLORCODE", "SIZECODE"];
            } else if (this.logizardKey1 === "SIZECODE") {
              this.logizardKey = ["SIZECODE", "COLORCODE"];
            }
            this.isFocusingLogizard = true;
          } else {
            this.dialogLogizard = true;
          }
          break;
        case 3:
          if (this.isFutureshopIntegrated) {
            this.newFutureshopIntegration = this.futureshopIntegration
              ? {
                  shopKey: this.futureshopIntegration.shopKey,
                  clientId: this.futureshopIntegration.clientId,
                  clientSecret: this.futureshopIntegration.clientSecret,
                  janCode: this.futureshopIntegration.janCode,
                  stockSynchronized: this.futureshopIntegration.stockSynchronized,
                }
              : null;
            this.editIntegration(true);
            this.isFocusingFutureshop = true;
          } else {
            this.newFutureshopIntegration = {
              shopKey: "",
              clientId: "",
              clientSecret: "",
              janCode: "JANCODE",
              stockSynchronized: true,
            };
            this.dialogFutureshop = true;
          }
          break;
        case 6:
          // カスタムアプリ
          this.editIntegration(true);
          this.isFocusingCustom = true;
          break;
        case 7:
          // shopifyPOS
          if (this.shopifyIntegrationStatus === 1 || this.shopifyPosEcStatus === 1) {
            this.dialogShopifyPos = true;
          } else {
            this.editIntegration(true);
            this.isFocusingShopifyPos = true;
          }
          break;
        case 8:
          // shopifyEC
          if (this.shopifyIntegrationStatus === 1 || this.shopifyPosEcStatus === 0) {
            this.dialogShopifyEc = true;
          } else {
            this.editIntegration(true);
            this.isFocusingShopifyEc = true;
          }
          break;
        case 9:
          // BASE
          if (this.isBaseIntegrated) {
            this.newBaseIntegration = this.baseIntegration
              ? {
                  refreshToken: this.baseIntegration.refreshToken,
                  janCode: this.baseIntegration.janCode,
                  stockSynchronized: this.baseIntegration.stockSynchronized,
                }
              : null;
            this.editIntegration(true);
            this.isFocusingBase = true;
          } else {
            this.newBaseIntegration = {
              refreshToken: "",
              janCode: "BARCODE",
              stockSynchronized: true,
            };
            this.dialogBase = true;
          }
          break;
        case 10:
          // MakeShop
          if (this.isMakeshopIntegrated) {
            this.newMakeshopIntegration = this.makeshopIntegration
              ? {
                  shopId: this.makeshopIntegration.shopId,
                  productToken: this.makeshopIntegration.productToken,
                  orderToken: this.makeshopIntegration.orderToken,
                  janCode: this.makeshopIntegration.janCode,
                  stockSynchronized: this.makeshopIntegration.stockSynchronized,
                }
              : null;
            this.editIntegration(true);
            this.isFocusingMakeshop = true;
          } else {
            this.newMakeshopIntegration = {
              shopId: "",
              productToken: "",
              orderToken: "",
              janCode: "JANCODE",
              stockSynchronized: true,
            };
            this.dialogMakeshop = true;
          }
          break;
        case 11:
          // NextEngine
          if (this.isNextEngineIntegrated) {
            this.newNextEngineIntegration = this.nextEngineIntegration
              ? {
                  url: "",
                  janCode: this.nextEngineIntegration.janCode,
                  stockSynchronized: this.nextEngineIntegration.stockSynchronized,
                }
              : null;
            this.editIntegration(true);
            this.isFocusingNextEngine = true;
          } else {
            this.newNextEngineIntegration = {
              url: "",
              janCode: "JANCODE",
              stockSynchronized: true,
            };
            this.dialogNextEngine = true;
          }
          break;
        default:
          return null;
      }
    },
    async deintegrate(serviceId: number) {
      this.isLoading = true;
      if (this.company) {
        switch (serviceId) {
          case 0: {
            if (this.company.smaregiIntegration) {
              await RadialApiClient.deintegrateSmaregi(this.company.smaregiIntegration.id);
            }
            this.dialogDeintegrateSmaregi = false;
            this.editIntegration(false);
            break;
          }
          case 2: {
            if (this.company.logizardIntegration) {
              await RadialApiClient.deintegrateLogizard(this.company.logizardIntegration.id);
            }
            this.dialogDeintegrateLogizard = false;
            this.editIntegration(false);
            break;
          }
          case 3: {
            if (this.company.futureshopIntegration) {
              await RadialApiClient.deintegrateFutureshop(this.company.futureshopIntegration.id);
            }
            this.dialogDeintegrateFutureshop = false;
            this.editIntegration(false);
            break;
          }
          case 4: {
            if (this.company.shopifyIntegration) {
              await RadialApiClient.deintegrateShopifyIntegration(this.company.shopifyIntegration.id);
            }
            this.dialogDeintegrateShopify = false;
            this.editIntegration(false);
            break;
          }
          case 5: {
            if (this.company.baseIntegration) {
              await RadialApiClient.deintegrateBase(this.company.baseIntegration.id);
            }
            this.dialogDeintegrateBase = false;
            this.editIntegration(false);
            break;
          }
          case 6: {
            if (this.company.makeshopIntegration) {
              await RadialApiClient.deintegrateMakeshop(this.company.makeshopIntegration.id);
            }
            this.dialogDeintegrateMakeshop = false;
            this.editIntegration(false);
            break;
          }
          case 7: {
            if (this.company.nextEngineIntegration) {
              await RadialApiClient.deintegrateNextEngine(this.company.nextEngineIntegration.id);
            }
            this.dialogDeintegrateNextEngine = false;
            this.editIntegration(false);
            break;
          }
        }
      }
      if (this.user) {
        this.company = await RadialApiClient.getCompany(this.user.companyId);
      }
      this.isLoading = false;
    },
    async updateShopifyStoreStatus() {
      this.isLoading = true;
      if (this.company && this.user) {
        const dto = {
          stores: this.shopifyStoreStatuses.map((storeStatus) => {
            return {
              locationName: storeStatus.locationName,
              posId: storeStatus.posId,
              posEcWarehouseStatus: storeStatus.posEcWarehouseStatus,
            };
          }),
        };
        const message = await RadialApiClient.updateShopifyStoreStatus(dto);
        this.shopifyStoreStatuses = (await RadialApiClient.getShopifyStores()) ?? [];
        this.savedShopifyStoreStatuses = lodash.cloneDeep(this.shopifyStoreStatuses);
        if (message?.status === 0) {
          this.showAlert("shopifyの店舗と倉庫の設定が更新されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
        this.isLoading = false;
      }
    },
    cancelShopifyStoreStatus() {
      this.shopifyStoreStatuses = lodash.cloneDeep(this.savedShopifyStoreStatuses);
    },
    isSameShopifyStoreStatus(): boolean {
      return (
        this.shopifyStoreStatuses.length === this.savedShopifyStoreStatuses.length &&
        this.shopifyStoreStatuses.every(
          (storeStatus, index) =>
            storeStatus.posEcWarehouseStatus === this.savedShopifyStoreStatuses[index].posEcWarehouseStatus
        )
      );
    },
    cancelSmaregiCsvSettingStatus() {
      this.smaregiRequiredCsvSetting = lodash.cloneDeep(this.savedSmaregiRequiredCsvSetting);
      this.smaregiOptions = lodash.cloneDeep(this.savedSmaregiOptions);
    },
    cancelShopifyCsvSettingStatus() {
      this.shopifyOptions = lodash.cloneDeep(this.savedShopifyOptions);
      this.shopifyCustomizationItems = lodash.cloneDeep(this.savedShopifyCustomizationItems);
    },
    async updateSmaregiCsvSettingStatus(modalFlag: boolean) {
      // modalFlagがtrueの時csv設定が完了したモーダルを表示する
      this.isLoading = true;
      if (this.company && this.user && this.smaregiIntegration) {
        // smaregiIntegrationテーブルの更新
        const dto: UpdateSmaregiIntegrationDto = {
          category: this.smaregiRequiredCsvSetting.category,
          unitPrice: this.smaregiRequiredCsvSetting.unitPrice,
          productNumber: this.smaregiRequiredCsvSetting.productNumber,
        };
        this.smaregiIntegration = await RadialApiClient.updateSmaregiIntegration(this.smaregiIntegration.id, dto);
        if (this.smaregiIntegration) {
          this.smaregiRequiredCsvSetting = {
            category: this.smaregiIntegration.category,
            unitPrice: this.smaregiIntegration.unitPrice,
            productNumber: this.smaregiIntegration.productNumber,
          };
        }
        this.savedSmaregiRequiredCsvSetting = lodash.cloneDeep(this.smaregiRequiredCsvSetting);
        // smaregiOptionテーブルの更新
        for (const smaregiOption of this.smaregiOptions) {
          const savedSmaregiOption = this.savedSmaregiOptions.find((option) => option.id === smaregiOption.id);
          if (savedSmaregiOption) {
            if (!lodash.isEqual(smaregiOption, savedSmaregiOption)) {
              const dto: UpdateSmaregiOptionDto = {
                name: smaregiOption.name,
                radialConnection: smaregiOption.radialConnection,
                reserveItemsCode: smaregiOption.reserveItemsCode,
                free: smaregiOption.free,
              };
              await RadialApiClient.updateSmaregiOption(smaregiOption.id, dto);
              const response = (await RadialApiClient.listSmaregiOption()) ?? [];
              this.smaregiOptions = response.sort((a, b) => {
                if (a.id > b.id) {
                  return 1;
                } else {
                  return -1;
                }
              });
              this.savedSmaregiOptions = lodash.cloneDeep(this.smaregiOptions);
            }
          }
        }
        if (modalFlag) {
          this.showAlert("smaregiのcsvダウンロード設定が更新されました！", "success");
        }
      }

      this.isLoading = false;
    },
    async updateShopifyCsvSettingStatus(modalFlag: boolean) {
      this.isLoading = true;
      if (
        this.shopifyCustomizationItems.every((item) => item.radialConnectionNew) &&
        this.requiredShopifyOptions.every((option) => option.radialConnection) &&
        this.optionalShopifyOptions.every((option) => option.radialConnections.length > 0) &&
        this.originalShopifyOptions.every((option) => option.free !== "")
      ) {
        const noSettedTypes = this.getNoSettedTypes();
        if (
          noSettedTypes.optional.length + noSettedTypes.original.length === 0 ||
          !this.shopifyIntegration?.productSynchronized
        ) {
          if (this.company && this.user && this.shopifyIntegration) {
            // shopifyOptionテーブルの更新
            // requiredItemsとoriginalItemsの更新
            for (const shopifyOption of this.shopifyOptions) {
              const savedShopifyOption = this.savedShopifyOptions.find((option) => option.id === shopifyOption.id);
              if (savedShopifyOption) {
                if (!lodash.isEqual(shopifyOption, savedShopifyOption)) {
                  const dto: UpdateShopifyOptionDto = {
                    name: shopifyOption.name,
                    radialConnection: shopifyOption.radialConnection,
                    free: shopifyOption.free,
                  };
                  await RadialApiClient.updateShopifyOption(shopifyOption.id, dto);
                }
              }
            }
            // shopifyOptionテーブルの更新
            // optionalItemsの更新
            for (const optionalShopifyOption of this.optionalShopifyOptions) {
              const savedOptionalShopifyOption = this.savedOptionalShopifyOptions.find(
                (option) => option.name === optionalShopifyOption.name
              );
              if (!lodash.isEqual(optionalShopifyOption, savedOptionalShopifyOption)) {
                for (const index of [
                  ...new Array(
                    Math.max(
                      1,
                      Math.min(optionalShopifyOption.ids.length, optionalShopifyOption.radialConnections.length)
                    )
                  ).keys(),
                ]) {
                  let dto: UpdateShopifyOptionDto = {
                    name: optionalShopifyOption.name,
                    radialConnection: optionalShopifyOption.radialConnections[index] ?? null,
                    index: optionalShopifyOption.multiple ? index : null,
                  };
                  await RadialApiClient.updateShopifyOption(optionalShopifyOption.ids[index], dto);
                }
                // optionalShopifyOption.ids.length !== optionalShopifyOption.radialConnections.lengthの時の処理
                const idLength = optionalShopifyOption.ids.length;
                const radialConnectionLength = optionalShopifyOption.radialConnections.length;
                if (radialConnectionLength !== 0) {
                  if (idLength > radialConnectionLength) {
                    //idsの方が多い場合はdeleteする
                    for (const index of [...new Array(idLength - radialConnectionLength).keys()]) {
                      await RadialApiClient.deleteShopifyOption(
                        optionalShopifyOption.ids[radialConnectionLength + index]
                      );
                    }
                  } else if (idLength < radialConnectionLength) {
                    // idsの方少ない場合はcreate
                    for (const index of [...new Array(radialConnectionLength - idLength).keys()]) {
                      const dto: CreateShopifyOptionDto = {
                        name: optionalShopifyOption.name,
                        radialConnection: optionalShopifyOption.radialConnections[idLength + index],
                        company: { connect: { id: this.company?.id } },
                        index: idLength + index,
                      };
                      await RadialApiClient.createShopifyOption(dto);
                    }
                  }
                }
              }
            }
            // shopifyOptionに関する情報を更新する
            await this.updateShopifyOptionInfo();
            // shopifyCustomizationの更新
            // 変更があるレコードのみデフォルトAPIを叩いて更新
            for (const [index, shopifyCustomizationItem] of this.shopifyCustomizationItems.entries()) {
              if (
                shopifyCustomizationItem.name !== this.savedShopifyCustomizationItems[index].name ||
                shopifyCustomizationItem.radialConnectionNew !==
                  this.savedShopifyCustomizationItems[index].radialConnectionNew
              ) {
                const dto: UpdateShopifyCustomizationDto = {
                  name: shopifyCustomizationItem.name,
                  radialConnectionNew: shopifyCustomizationItem.radialConnectionNew,
                };
                await RadialApiClient.updateShopifyCustomization(shopifyCustomizationItem.id, dto);
              }
            }
            const response = (await RadialApiClient.listShopifyCustomizations()) ?? [];
            this.shopifyCustomizationItems = response.sort((a, b) => a.id - b.id);
            this.savedShopifyCustomizationItems = lodash.cloneDeep(this.shopifyCustomizationItems);
          }
          if (modalFlag) {
            this.showAlert("shopifyのcsvダウンロード設定が更新されました！", "success");
          }
        } else {
          // csv設定で設定されていない項目がある場合はAPI連携設定を無効化し設定するべき項目をエラーハンドリング
          const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(this.shopifyIntegration.id, {
            productSynchronized: false,
          });
          if (updatedShopifyIntegration) {
            this.shopifyIntegration = updatedShopifyIntegration;
          }
          let message = "設定されていない項目があるためAPI自動連携設定を無効化しました。\n\n";
          if (noSettedTypes.optional.length !== 0) {
            message +=
              "「Shopifyアップロード任意項目（radialデータ紐付け選択項目）」で以下の項目を設定してください。\n";
            for (const type of noSettedTypes.optional) {
              message += `・${type}\n`;
            }
            message += "\n\n";
          }
          if (noSettedTypes.original.length !== 0) {
            message += "「Shopifyアップロード任意項目（独自データ紐付け選択項目）」で以下の項目を設定してください。\n";
            for (const type of noSettedTypes.original) {
              message += `・${type}\n`;
            }
            message += "\n\n";
          }
          this.showAlert(message, "error");
        }
      } else {
        this.showAlert("設定されていない項目があります。", "error");
      }

      this.isLoading = false;
    },
    cancelDialog() {
      this.dialogBase = false;
      this.dialogFutureshop = false;
      this.dialogLogizard = false;
      this.dialogMakeshop = false;
      this.dialogNextEngine = false;
      this.dialogSmaregi = false;
      this.dialogShopifyPos = false;
      this.dialogShopifyEc = false;
    },
    async deleteShopifyCustomizationItem(id: number) {
      this.isLoading = true;
      await RadialApiClient.deleteShopifyCustomization(id);
      this.shopifyCustomizationItems = this.shopifyCustomizationItems.filter((item) => item.id !== id);
      this.savedShopifyCustomizationItems = this.savedShopifyCustomizationItems.filter((item) => item.id !== id);
      this.isLoading = false;
    },
    async addShopifyCustomizationItem() {
      this.isLoading = true;
      if (this.company) {
        const dto: CreateShopifyCustomizationDto = {
          name: "",
          radialConnectionNew: null,
          company: { connect: { id: this.company.id } },
        };
        const shopifyCustomization = await RadialApiClient.createShopifyCustomization(dto);
        if (shopifyCustomization) {
          this.shopifyCustomizationItems.push(shopifyCustomization);
          this.savedShopifyCustomizationItems.push(lodash.cloneDeep(shopifyCustomization));
        }
      }
      this.isLoading = false;
    },
    getParams(params: string): { [key: string]: string } {
      const paramsArray = params.slice(1).split("&");
      const paramsObject: { [key: string]: string } = {};
      paramsArray.forEach((param) => {
        paramsObject[param.split("=")[0]] = param.split("=")[1];
      });
      return paramsObject;
    },
    convertSmaregiOptionToCsvSetting(
      smaregiOptions: SmaregiOption[],
      smaregiFreeLabels: SmaregiFreeLabel[]
    ): SmaregiCsvSettingOption[] {
      const smaregiCsvSettingOptions: SmaregiCsvSettingOption[] = smaregiOptions.map((smaregiOption) => {
        return {
          smaregiOptionId: smaregiOption.id,
          smaregiOption: smaregiOption.name,
          radialConnection: smaregiOption.radialConnection,
          reserveItemsCode: smaregiOption.reserveItemsCode,
          reserveItemsName:
            smaregiFreeLabels.find((freeLabel) => freeLabel.reserveItemsCode === smaregiOption.reserveItemsCode)
              ?.labelName ?? null,
          free: smaregiOption.free,
        };
      });
      return smaregiCsvSettingOptions;
    },
    updateFreeSmaregiOption(id: string, event: string) {
      const index = this.smaregiOptions.findIndex((smaregiOption) => smaregiOption.id === id);
      if (index !== undefined) {
        this.smaregiOptions[index].reserveItemsCode = event;
      }
    },
    updateOptionalSmaregiOption(id: string, event: SmaregiOptionType) {
      const index = this.smaregiOptions.findIndex((smaregiOption) => smaregiOption.id === id);
      if (index !== undefined) {
        this.smaregiOptions[index].name = event;
        const radialConnections = this.getRadialConnectionsInSmaregiOptional(event);
        if (radialConnections.length === 1) {
          this.smaregiOptions[index].radialConnection = radialConnections[0].key;
        }
      }
    },
    updateOptionalShopifyOption(oldType: ShopifyOptionType, newType: ShopifyOptionType) {
      const index = this.optionalShopifyOptions.findIndex((option) => option.name === oldType);
      if (index !== undefined) {
        const csvItem = this.shopifyCsvItems.optionalItems.find((item) => item.key === newType);
        if (csvItem) {
          this.optionalShopifyOptions[index].name = newType;
          // this.optionalShopifyOptions[index].radialConnections = csvItem.multiple ? [] : [csvItem.setting[0].key];
          this.optionalShopifyOptions[index].multiple = csvItem.multiple;
        }
      }
    },
    updateOriginalSmaregiOption(id: string, event: SmaregiOptionType) {
      const index = this.smaregiOptions.findIndex((smaregiOption) => smaregiOption.id === id);
      if (index !== undefined) {
        this.smaregiOptions[index].name = event;
      }
    },
    updateOriginalShopifyOption(id: string, event: ShopifyOptionType) {
      const index = this.shopifyOptions.findIndex((shopifyOption) => shopifyOption.id === id);
      if (index !== undefined) {
        this.shopifyOptions[index].name = event;
      }
    },
    updateSmaregiRadialConnection(id: string, event: RadialConnection) {
      const index = this.smaregiOptions.findIndex((smaregiOption) => smaregiOption.id === id);
      if (index !== undefined) {
        this.smaregiOptions[index].radialConnection = event;
      }
    },
    updateShopifyRadialConnection(name: string, event: RadialConnection) {
      const index = this.shopifyOptions.findIndex((shopifyOption) => shopifyOption.name === name);
      if (index !== undefined) {
        this.shopifyOptions[index].radialConnection = event;
      }
    },
    updateOptionalShopifyRadialConnection(name: string, event: RadialConnection) {
      const index = this.optionalShopifyOptions.findIndex((shopifyOption) => shopifyOption.name === name);
      if (index !== undefined) {
        this.optionalShopifyOptions[index].radialConnections = [event];
      }
    },
    updateOptionalShopifyRadialConnections(name: string, event: RadialConnection[]) {
      const index = this.optionalShopifyOptions.findIndex((shopifyOption) => shopifyOption.name === name);
      if (index !== undefined) {
        this.optionalShopifyOptions[index].radialConnections = event;
      }
    },
    updateSmaregiOptionFree(id: string, event: string) {
      const index = this.smaregiOptions.findIndex((smaregiOption) => smaregiOption.id === id);
      if (index !== undefined) {
        this.smaregiOptions[index].free = event;
      }
    },
    updateShopifyOptionFree(id: string, event: string) {
      const index = this.shopifyOptions.findIndex((shopifyOption) => shopifyOption.id === id);
      if (index !== undefined) {
        this.shopifyOptions[index].free = event;
      }
    },
    async addFreeSmaregiOption() {
      // APIを叩いてsmaregiOptionを追加
      // reserveItemsCodeは選択されていないものを表示
      this.isLoading = true;
      if (!this.isSameSmaregiCsvSettingStatus) {
        await this.updateSmaregiCsvSettingStatus(false);
      }
      const dto: CreateSmaregiOptionDto = {
        name: "RESERVEITEMS",
        radialConnection: null,
        reserveItemsCode: this.smaregiFreeLabels
          .map((label) => label.reserveItemsCode)
          .filter((code) => !this.freeSmaregiOptions.map((option) => option.reserveItemsCode).includes(code))[0],
        company: { connect: { id: this.company?.id } },
      };
      const response = await RadialApiClient.createSmaregiOption(dto);
      if (response) {
        this.smaregiOptions.push(response);
        this.savedSmaregiOptions = lodash.cloneDeep(this.smaregiOptions);
      } else {
        this.showAlert(
          `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
          "error"
        );
      }
      this.isLoading = false;
    },
    async addOptionalSmaregiOption() {
      // APIを叩いてsmaregiOptionを追加
      // smaregiOptionTypeは選択されていないものを表示
      this.isLoading = true;
      if (!this.isSameSmaregiCsvSettingStatus) {
        await this.updateSmaregiCsvSettingStatus(false);
      }
      const smaregiOptionType = this.smaregiCsvItems.optionalItems
        .map((item) => item.key)
        .filter((key) => !this.optionalSmaregiOptions.map((option) => option.name).includes(key))[0];
      const radialConnection =
        this.getRadialConnectionsInSmaregiOptional(smaregiOptionType).length === 1
          ? this.getRadialConnectionsInSmaregiOptional(smaregiOptionType)[0].key
          : null;
      const dto: CreateSmaregiOptionDto = {
        name: smaregiOptionType,
        radialConnection: radialConnection,
        company: { connect: { id: this.company?.id } },
      };
      const response = await RadialApiClient.createSmaregiOption(dto);
      if (response) {
        this.smaregiOptions.push(response);
        this.savedSmaregiOptions = lodash.cloneDeep(this.smaregiOptions);
      } else {
        this.showAlert(
          `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
          "error"
        );
      }
      this.isLoading = false;
    },
    async addOptionalShopifyOption() {
      // APIを叩いてshopifyOptionを追加
      // shopifyOptionTypeは選択されていないものを表示
      this.isLoading = true;
      if (!this.isSameShopifyCsvSettingStatus) {
        await this.updateShopifyCsvSettingStatus(false);
      }
      const csvItem = this.shopifyCsvItems.optionalItems.filter(
        (item) => !this.optionalShopifyOptions.map((option) => option.name).includes(item.key)
      )[0];
      const dto: CreateShopifyOptionDto = {
        name: csvItem.key,
        radialConnection: csvItem.multiple ? null : csvItem.setting[0].key,
        company: { connect: { id: this.company?.id } },
        index: csvItem.multiple ? 0 : null,
      };
      const response = await RadialApiClient.createShopifyOption(dto);
      if (response) {
        this.shopifyOptions.push(response);
        this.savedShopifyOptions = lodash.cloneDeep(this.shopifyOptions);
        this.optionalShopifyOptions.push({
          ids: [response.id],
          name: response.name,
          radialConnections: response.radialConnection ? [response.radialConnection] : [],
          multiple: csvItem.multiple,
        });
        this.savedOptionalShopifyOptions = lodash.cloneDeep(this.optionalShopifyOptions);
        const noSettedTypes = this.getNoSettedTypes();
        if (
          noSettedTypes.optional.length + noSettedTypes.original.length !== 0 &&
          this.shopifyIntegration?.productSynchronized
        ) {
          // csv設定で設定されていない項目がある場合はAPI連携設定を無効化し設定するべき項目をエラーハンドリング
          const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(this.shopifyIntegration.id, {
            productSynchronized: false,
          });
          if (updatedShopifyIntegration) {
            this.shopifyIntegration = updatedShopifyIntegration;
          }
          let message = "設定されていない項目があるためAPI自動連携設定を無効化しました。\n\n";
          if (noSettedTypes.optional.length !== 0) {
            message +=
              "「Shopifyアップロード任意項目（radialデータ紐付け選択項目）」で以下の項目を設定してください。\n";
            for (const type of noSettedTypes.optional) {
              message += `・${type}\n`;
            }
            message += "\n\n";
          }
          if (noSettedTypes.original.length !== 0) {
            message += "「Shopifyアップロード任意項目（独自データ紐付け選択項目）」で以下の項目を設定してください。\n";
            for (const type of noSettedTypes.original) {
              message += `・${type}\n`;
            }
            message += "\n\n";
          }
          this.showAlert(message, "error");
        }
      } else {
        this.showAlert(
          `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
          "error"
        );
      }

      this.isLoading = false;
    },
    async addOriginalSmaregiOption() {
      // APIを叩いてsmaregiOptionを追加
      // smaregiOptionTypeは選択されていないものを表示
      this.isLoading = true;
      if (!this.isSameSmaregiCsvSettingStatus) {
        await this.updateSmaregiCsvSettingStatus(false);
      }
      const smaregiOriginalType = this.smaregiCsvItems.originalItems
        .map((item) => item.key)
        .filter((key) => !this.originalSmaregiOptions.map((option) => option.name).includes(key))[0];
      const dto: CreateSmaregiOptionDto = {
        name: smaregiOriginalType,
        free: "1",
        company: { connect: { id: this.company?.id } },
      };
      const response = await RadialApiClient.createSmaregiOption(dto);
      if (response) {
        this.smaregiOptions.push(response);
        this.savedSmaregiOptions = lodash.cloneDeep(this.smaregiOptions);
      } else {
        this.showAlert(
          `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
          "error"
        );
      }
      this.isLoading = false;
    },
    async addOriginalShopifyOption() {
      // APIを叩いてshopifyOptionを追加
      // shopifyOptionTypeは選択されていないものを表示
      this.isLoading = true;
      if (!this.isSameShopifyCsvSettingStatus) {
        await this.updateShopifyCsvSettingStatus(false);
      }
      const shopifyOriginalType = this.shopifyCsvItems.originalItems
        .map((item) => item.key)
        .filter((key) => !this.originalShopifyOptions.map((option) => option.name).includes(key))[0];
      const dto: CreateShopifyOptionDto = {
        name: shopifyOriginalType,
        free: this.shopifyCsvItems.originalItems.find((item) => item.key === shopifyOriginalType)?.setting[0].key,
        company: { connect: { id: this.company?.id } },
      };
      const response = await RadialApiClient.createShopifyOption(dto);
      if (response) {
        this.shopifyOptions.push(response);
        this.savedShopifyOptions = lodash.cloneDeep(this.shopifyOptions);
        const noSettedTypes = this.getNoSettedTypes();
        if (
          noSettedTypes.optional.length + noSettedTypes.original.length !== 0 &&
          this.shopifyIntegration?.productSynchronized
        ) {
          // csv設定で設定されていない項目がある場合はAPI連携設定を無効化し設定するべき項目をエラーハンドリング
          const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(this.shopifyIntegration.id, {
            productSynchronized: false,
          });
          if (updatedShopifyIntegration) {
            this.shopifyIntegration = updatedShopifyIntegration;
          }
          let message = "設定されていない項目があるためAPI自動連携設定を無効化しました。\n\n";
          if (noSettedTypes.optional.length !== 0) {
            message +=
              "「Shopifyアップロード任意項目（radialデータ紐付け選択項目）」で以下の項目を設定してください。\n";
            for (const type of noSettedTypes.optional) {
              message += `・${type}\n`;
            }
            message += "\n\n";
          }
          if (noSettedTypes.original.length !== 0) {
            message += "「Shopifyアップロード任意項目（独自データ紐付け選択項目）」で以下の項目を設定してください。\n";
            for (const type of noSettedTypes.original) {
              message += `・${type}\n`;
            }
            message += "\n\n";
          }
          this.showAlert(message, "error");
        }
      } else {
        this.showAlert(
          `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
          "error"
        );
      }
      this.isLoading = false;
    },
    async deleteSmaregiOption(id: string) {
      // APIを叩いてsmaregiOptionを削除
      this.isLoading = true;
      await RadialApiClient.deleteSmaregiOption(id);
      const response = (await RadialApiClient.listSmaregiOption()) ?? [];
      this.smaregiOptions = response.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      this.savedSmaregiOptions = lodash.cloneDeep(this.smaregiOptions);
      this.isLoading = false;
    },
    async deleteOptionalShopifyOption(ids: string[]) {
      for (const id of ids) {
        await RadialApiClient.deleteShopifyOption(id);
      }
      // shopifyOptionに関する情報を更新する
      await this.updateShopifyOptionInfo();
      const noSettedTypes = this.getNoSettedTypes();
      if (
        noSettedTypes.optional.length + noSettedTypes.original.length !== 0 &&
        this.shopifyIntegration?.productSynchronized
      ) {
        // csv設定で設定されていない項目がある場合はAPI連携設定を無効化し設定するべき項目をエラーハンドリング
        const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(this.shopifyIntegration.id, {
          productSynchronized: false,
        });
        if (updatedShopifyIntegration) {
          this.shopifyIntegration = updatedShopifyIntegration;
        }
        let message = "設定されていない項目があるためAPI自動連携設定を無効化しました。\n\n";
        if (noSettedTypes.optional.length !== 0) {
          message += "「Shopifyアップロード任意項目（radialデータ紐付け選択項目）」で以下の項目を設定してください。\n";
          for (const type of noSettedTypes.optional) {
            message += `・${type}\n`;
          }
          message += "\n\n";
        }
        if (noSettedTypes.original.length !== 0) {
          message += "「Shopifyアップロード任意項目（独自データ紐付け選択項目）」で以下の項目を設定してください。\n";
          for (const type of noSettedTypes.original) {
            message += `・${type}\n`;
          }
          message += "\n\n";
        }
        this.showAlert(message, "error");
      }
    },
    async deleteShopifyOption(id: string) {
      // APIを叩いてshopifyOptionを削除
      this.isLoading = true;
      await RadialApiClient.deleteShopifyOption(id);
      const response = (await RadialApiClient.listShopifyOption()) ?? [];
      this.shopifyOptions = response.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      this.savedShopifyOptions = lodash.cloneDeep(this.shopifyOptions);
      this.optionalShopifyOptions = this.convertOptionalShopifyOption();
      this.savedOptionalShopifyOptions = lodash.cloneDeep(this.optionalShopifyOptions);
      const noSettedTypes = this.getNoSettedTypes();
      if (
        noSettedTypes.optional.length + noSettedTypes.original.length !== 0 &&
        this.shopifyIntegration?.productSynchronized
      ) {
        // csv設定で設定されていない項目がある場合はAPI連携設定を無効化し設定するべき項目をエラーハンドリング
        const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(this.shopifyIntegration.id, {
          productSynchronized: false,
        });
        if (updatedShopifyIntegration) {
          this.shopifyIntegration = updatedShopifyIntegration;
        }
        let message = "設定されていない項目があるためAPI自動連携設定を無効化しました。\n\n";
        if (noSettedTypes.optional.length !== 0) {
          message += "「Shopifyアップロード任意項目（radialデータ紐付け選択項目）」で以下の項目を設定してください。\n";
          for (const type of noSettedTypes.optional) {
            message += `・${type}\n`;
          }
          message += "\n\n";
        }
        if (noSettedTypes.original.length !== 0) {
          message += "「Shopifyアップロード任意項目（独自データ紐付け選択項目）」で以下の項目を設定してください。\n";
          for (const type of noSettedTypes.original) {
            message += `・${type}\n`;
          }
          message += "\n\n";
        }
        this.showAlert(message, "error");
      }
      this.isLoading = false;
    },
    getRadialConnectionsInSmaregiOptional(
      smaregiOptionType: SmaregiOptionType
    ): { key: RadialConnection; name: string }[] {
      return this.smaregiCsvItems.optionalItems?.find((item) => item.key === smaregiOptionType)?.setting ?? [];
    },
    getRadialConnectionsInSmaregiOriginal(smaregiOptionType: SmaregiOptionType): { key: string; name: string }[] {
      return this.smaregiCsvItems.originalItems?.find((item) => item.key === smaregiOptionType)?.setting ?? [];
    },
    getRadialConnectionsInShopifyOriginal(shopifyOptionType: ShopifyOptionType): { key: string; name: string }[] {
      return this.shopifyCsvItems.originalItems?.find((item) => item.key === shopifyOptionType)?.setting ?? [];
    },
    getFilteredSmaregiOptionalItems(smaregiOptionType: SmaregiOptionType): SmaregiOptionalCsvSetting[] {
      // 選択されていないアイテムとセレクターで現在選択されているアイテムを返す
      return this.smaregiCsvItems.optionalItems.filter(
        (item) =>
          !this.optionalSmaregiOptions.map((option) => option.name).includes(item.key) || item.key === smaregiOptionType
      );
    },
    getFilteredShopifyOptionalItems(shopifyOptionType: ShopifyOptionType): ShopifyOptionalCsvSetting[] {
      // 選択されていないアイテムとセレクターで現在選択されているアイテムを返す
      return this.shopifyCsvItems.optionalItems.filter(
        (item) =>
          !this.optionalShopifyOptions.map((option) => option.name).includes(item.key) || item.key === shopifyOptionType
      );
    },
    getFilteredShopifyOriginalItems(shopifyOriginalType: ShopifyOptionType): ShopifyOriginalCsvSetting[] {
      // 選択されていないアイテムとセレクターで現在選択されているアイテムを返す
      return this.shopifyCsvItems.originalItems.filter(
        (item) =>
          !this.originalShopifyOptions.map((option) => option.name).includes(item.key) ||
          item.key === shopifyOriginalType
      );
    },
    getFilteredSmaregiFreeLabels(reserveItemsCode: string): SmaregiFreeLabel[] {
      // 選択されていないアイテムとセレクターで現在選択されているアイテムを返す
      return this.smaregiFreeLabels.filter(
        (item) =>
          !this.freeSmaregiOptions.map((option) => option.reserveItemsCode).includes(item.reserveItemsCode) ||
          item.reserveItemsCode === reserveItemsCode
      );
    },
    getRadialConnectionsInShopifyOptional(
      shopifyOptionType: ShopifyOptionType
    ): { key: RadialConnection; name: string }[] {
      return this.shopifyCsvItems.optionalItems?.find((item) => item.key === shopifyOptionType)?.setting ?? [];
    },

    async updateSmaregiProductSynchronized() {
      if (this.smaregiIntegration) {
        if (
          this.smaregiOptions.every((smaregiOption) => smaregiOption.radialConnection || smaregiOption.free) &&
          this.smaregiIntegration.category &&
          this.smaregiIntegration.unitPrice &&
          this.smaregiIntegration.productNumber
        ) {
          const updatedSmaregiIntegration = await RadialApiClient.updateSmaregiIntegration(this.smaregiIntegration.id, {
            productSynchronized: this.smaregiIntegration.productSynchronized,
          });
          if (updatedSmaregiIntegration) {
            this.showAlert("スマレジの設定が更新されました！", "success");
            this.smaregiIntegration = updatedSmaregiIntegration;
          } else {
            this.showAlert(
              `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
        } else {
          this.showAlert("設定されていない項目があります。すべての項目を設定した後、連携を行ってください。", "error");
          const updatedSmaregiIntegration = await RadialApiClient.updateSmaregiIntegration(this.smaregiIntegration.id, {
            productSynchronized: false,
          });
          if (updatedSmaregiIntegration) {
            this.smaregiIntegration = updatedSmaregiIntegration;
          }
        }
      }
    },
    async updateShopifyProductSynchronized() {
      this.isLoading = true;
      if (this.shopifyIntegration) {
        if (
          this.shopifyOptions.every((shopifyOption) => shopifyOption.radialConnection || shopifyOption.free) &&
          this.optionalShopifyOptions.every((option) => option.radialConnections.length > 0)
        ) {
          const noSettedTypes = this.getNoSettedTypes();
          if (noSettedTypes.optional.length + noSettedTypes.original.length === 0) {
            // csv設定で設定されていない項目がある場合はAPI連携設定を無効化し設定するべき項目をエラーハンドリング
            const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(
              this.shopifyIntegration.id,
              {
                productSynchronized: this.shopifyIntegration.productSynchronized,
              }
            );
            if (updatedShopifyIntegration) {
              this.showAlert("Shopifyの設定が更新されました！", "success");
              this.shopifyIntegration = updatedShopifyIntegration;
            } else {
              this.showAlert(
                `エラーが発生しております。
            時間を空けてからもう一度お試しください。`,
                "error"
              );
            }
          } else {
            let message = "";
            if (noSettedTypes.optional.length !== 0) {
              message +=
                "「Shopifyアップロード任意項目（radialデータ紐付け選択項目）」で以下の項目を設定してください。\n";
              for (const type of noSettedTypes.optional) {
                message += `・${type}\n`;
              }
              message += "\n\n";
            }
            if (noSettedTypes.original.length !== 0) {
              message +=
                "「Shopifyアップロード任意項目（独自データ紐付け選択項目）」で以下の項目を設定してください。\n";
              for (const type of noSettedTypes.original) {
                message += `・${type}\n`;
              }
              message += "\n\n";
            }
            this.showAlert(message, "error");
            const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(
              this.shopifyIntegration.id,
              {
                productSynchronized: false,
              }
            );
            if (updatedShopifyIntegration) {
              this.shopifyIntegration = updatedShopifyIntegration;
            }
          }
        } else {
          this.showAlert("設定されていない項目があります。すべての項目を設定した後、連携を行ってください。", "error");
          const updatedShopifyIntegration = await RadialApiClient.updateShopifyIntegration(this.shopifyIntegration.id, {
            productSynchronized: false,
          });
          if (updatedShopifyIntegration) {
            this.shopifyIntegration = updatedShopifyIntegration;
          }
        }
      }
      this.isLoading = false;
    },
    convertOptionalShopifyOption(): ShopifyOptionWithRadialConnections[] {
      // ShopifyOptionからShopifyOptionWithRadialConnectionsにconvert
      const optionalShopifyOptions: ShopifyOptionWithRadialConnections[] = this.shopifyCsvItems.optionalItems
        .map((optionalItem) => {
          const shopifyOptions = this.shopifyOptions.filter((shopifyOption) => shopifyOption.name === optionalItem.key);
          if (shopifyOptions.length > 0) {
            return {
              ids: shopifyOptions.map((option) => option.id),
              name: shopifyOptions[0].name,
              radialConnections: shopifyOptions
                .map((shopifyOption) => shopifyOption.radialConnection)
                .filter((item): item is NonNullable<typeof item> => item !== null),
              multiple: optionalItem.multiple,
            };
          }
        })
        .filter((item): item is NonNullable<typeof item> => {
          return item !== null && item !== undefined;
        });
      return optionalShopifyOptions;
    },
    async updateShopifyOptionInfo() {
      const response = (await RadialApiClient.listShopifyOption()) ?? [];
      this.shopifyOptions = response.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      this.savedShopifyOptions = lodash.cloneDeep(this.shopifyOptions);
      this.optionalShopifyOptions = this.convertOptionalShopifyOption();
      this.savedOptionalShopifyOptions = lodash.cloneDeep(this.optionalShopifyOptions);
    },
    getNoSettedTypes(): { optional: string[]; original: string[] } {
      let noSettedTypes: { optional: string[]; original: string[] } = { optional: [], original: [] };
      const option1NameIndex = this.shopifyOptions.find((option) => option.name === "OPTION1NAME");
      const option1ValueIndex = this.shopifyOptions.find((option) => option.name === "OPTION1VALUE");
      if (option1NameIndex === undefined && option1ValueIndex !== undefined) {
        noSettedTypes.original.push("OPTION1 NAME");
      }
      if (option1NameIndex !== undefined && option1ValueIndex === undefined) {
        noSettedTypes.optional.push("OPTION1 VALUE");
      }
      const option2NameIndex = this.shopifyOptions.find((option) => option.name === "OPTION2NAME");
      const option2ValueIndex = this.shopifyOptions.find((option) => option.name === "OPTION2VALUE");
      if (option2NameIndex === undefined && option2ValueIndex !== undefined) {
        noSettedTypes.original.push("OPTION2 NAME");
      }
      if (option2NameIndex !== undefined && option2ValueIndex === undefined) {
        noSettedTypes.optional.push("OPTION2 VALUE");
      }
      const option3NameIndex = this.shopifyOptions.find((option) => option.name === "OPTION3NAME");
      const option3ValueIndex = this.shopifyOptions.find((option) => option.name === "OPTION3VALUE");
      if (option3NameIndex === undefined && option3ValueIndex !== undefined) {
        noSettedTypes.original.push("OPTION3 NAME");
      }
      if (option3NameIndex !== undefined && option3ValueIndex === undefined) {
        noSettedTypes.optional.push("OPTION3 VALUE");
      }
      return noSettedTypes;
    },
    getLastBatchedEndedAt(service: string) {
      switch (service) {
        case "smaregi": {
          return this.company?.smaregiIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.smaregiIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        case "logizard": {
          return this.company?.logizardIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.logizardIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        case "futureshop": {
          return this.company?.futureshopIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.futureshopIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        case "base": {
          return this.company?.baseIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.baseIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        case "makeshop": {
          return this.company?.makeshopIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.makeshopIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        case "nextEngine": {
          return this.company?.nextEngineIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.nextEngineIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        case "shopify": {
          return this.company?.shopifyIntegration?.lastBatchEndedAt
            ? `${dayjs(this.company.shopifyIntegration.lastBatchEndedAt).format("YYYY.MM.DD. HH")}:00`
            : "未取得";
        }
        default: {
          return "未取得";
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      const company = await RadialApiClient.getCompany(this.user.companyId);
      if (company) {
        this.company = company;
        if (company.logizardIntegration) {
          this.logizardItemId = company.logizardIntegration.itemId;
          this.logizardKey1 = company.logizardIntegration.key1;
          this.logizardKey2 = company.logizardIntegration.key2;
        }
        if (
          !this.isSmaregiIntegrated &&
          !this.isFutureshopIntegrated &&
          !this.isBaseIntegrated &&
          !this.isLogizardIntegrated &&
          !this.isShopifyPOSIntegrated &&
          !this.isCustomIntegrated
        ) {
          this.tabIndex = "regipos";
        }
        if (company.customIntegrations) {
          this.customIntegrations = company.customIntegrations;
          this.customIntegrations.sort((a, b) => {
            if (a.serviceId < b.serviceId) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        this.smaregiIntegration = company.smaregiIntegration ?? null;
        this.smaregiRequiredCsvSetting = {
          category: this.smaregiIntegration?.category ?? null,
          unitPrice: this.smaregiIntegration?.unitPrice ?? null,
          productNumber: this.smaregiIntegration?.productNumber ?? null,
        };
        this.savedSmaregiRequiredCsvSetting = lodash.cloneDeep(this.smaregiCsvSetting);
        this.futureshopIntegration = company.futureshopIntegration ?? null;
        this.makeshopIntegration = company.makeshopIntegration ?? null;
        this.baseIntegration = company.baseIntegration ?? null;
        this.nextEngineIntegration = company.nextEngineIntegration ?? null;

        // パスパラメータからshopifyのshop名とaccessTokenを取得
        const params = this.getParams(location.search);
        if (params.app) {
          this.shopifyShopName = params.name;
          this.shopifyAccessToken = params.id;
        }
        if (company.shopifyIntegration && company.shopifyIntegration.accessToken) {
          this.shopifyIntegration = company.shopifyIntegration;
          this.shopifyShopName = this.shopifyIntegration.shopName;
          this.shopifyAccessToken = this.shopifyIntegration.accessToken;
          this.shopifyStoreStatuses = (await RadialApiClient.getShopifyStores()) ?? [];
          this.savedShopifyStoreStatuses = lodash.cloneDeep(this.shopifyStoreStatuses);
          const response = (await RadialApiClient.listShopifyCustomizations()) ?? [];
          this.shopifyCustomizationItems = response.sort((a, b) => a.id - b.id);
          this.savedShopifyCustomizationItems = lodash.cloneDeep(this.shopifyCustomizationItems);
        }
        this.services = (await RadialApiClient.listServices()) ?? [];
      }
    }
    this.isLoading = false;
  },
});
