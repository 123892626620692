
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import dayjs from "dayjs";
import LocalDataService from "@/store/LocalDataService";
import {
  ReportStoreCommentEntity,
  ReportCommentEntity,
  Comment,
  Sku,
  Member,
  StoreActualsDailyReportItemEntity,
  StoreAnalysis,
  StoreActualsRankingSaleEntity,
  StoreActualsRankingOtherEntity,
  StoreActualsRankingSaleItemEntity,
  StoreActualsRankingOtherItemEntity,
  StoreActualsGraphItemEntity,
  StoreActualsGraphEntity,
  Store,
} from "@/api/entities";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import { UpdateCommentDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { Constant } from "@/store/constant";
import { StoreActualsTableHeader, User } from "@/store/model";
import { Chart } from "highcharts-vue";
import { Options, SeriesOptionsType, TooltipOptions } from "highcharts";
import * as Highcharts from "highcharts";
import router from "@/router";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import mixin from "@/mixin/mixin";

export interface StoreMember {
  id: string;
  name: string;
}

export interface ActualStore {
  id: string;
  name: string;
  cognitoSub: string;
  serviceId: number | null;
}

export class ReportSalesProduct {
  productId: string;
  summary: ProductSummary;
}

export interface ProductSummary {
  productName: string;
  itemNumber: string;
  sumQuantity: number;
  countComments: number;
}

export interface DataType {
  reportSummaryEntities: StoreActualsDailyReportItemEntity[];
  currentReport: StoreAnalysis | null;
  reportCommentEntity: ReportCommentEntity | null;
  currentComments: ReportStoreCommentEntity[];
  reportSalesProducts: ReportSalesProduct[];
  today: Date;
  selectedDate: string;
  reportTab: string;
  storeMembers: StoreMember[];
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  buttonDisabled: boolean;
  sentReportDialog: boolean;
  tempSavedAlert: boolean;
  images: string[];
  dateMenu: boolean;
  isLoading: boolean;
  showUpdateComment: boolean;
  showingDeleteCommentDialog: boolean;
  editingComment: Comment;
  dialogCommentThanks: boolean;
  memberName: string;
  commentTab: string;
  deleteCommentId: string;
  skus: Sku[];
  members: Member[];
  hover: boolean;
  hoverIndex: number | null;
  stores: ActualStore[];
  rangeDates: string[];
  rangeDatesMenu: boolean;
  selectedStore: ActualStore | null;
  store: Store | null;
  storeActualsSaleRanking: StoreActualsRankingSaleEntity | null;
  storeActualsOtherRanking: StoreActualsRankingOtherEntity | null;
  storeActualsGraph: StoreActualsGraphItemEntity[];
  showWeather: boolean;
  saleHeader: StoreActualsTableHeader[];
  otherHeader: StoreActualsTableHeader[];
  currnentGraphTab: string;
  saleOptions: Options;
  otherOptions: Options;
  dailyStoreBudget: number;
}

export default Vue.extend({
  name: "Report",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    S3Image,
    ElementDatePicker,
    ConfirmationDialog,
    ElementRangeDatePicker,
    highcharts: Chart,
    Loading,
  },
  data(): DataType {
    return {
      reportSummaryEntities: [],
      currentReport: null,
      reportCommentEntity: null,
      currentComments: [],
      reportSalesProducts: [],
      today: new Date(),
      selectedDate: dayjs().format("YYYY-MM-DD"),
      reportTab: "info",
      storeMembers: [],
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      buttonDisabled: true,
      sentReportDialog: false,
      tempSavedAlert: false,
      images: [],
      dateMenu: false,
      isLoading: false,
      showUpdateComment: false,
      showingDeleteCommentDialog: false,
      editingComment: {
        id: "",
        companyId: "",
        productId: "",
        skuId: "",
        memberId: "",
        storeId: "",
        age: "10代",
        gender: "女性",
        figure: "やせ",
        height: "~150",
        content: "",
        positivity: 0,
        servedAt: new Date(),
        target: 0b00000,
        category: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      },
      dialogCommentThanks: false,
      memberName: "",
      commentTab: "customer-comment",
      deleteCommentId: "",
      skus: [],
      members: [],
      hover: false,
      hoverIndex: null,
      stores: [],
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      rangeDatesMenu: false,
      selectedStore: null,
      store: null,
      storeActualsSaleRanking: null,
      storeActualsOtherRanking: null,
      storeActualsGraph: [],
      showWeather: false,
      saleHeader: Constant.storeActualsSaleTableHeader,
      otherHeader: Constant.storeActualsOtherTableHeader,
      currnentGraphTab: "unitPrice",
      saleOptions: {
        title: undefined,
        chart: {
          width: 598,
          height: 276,
          backgroundColor: "#fbfbfb",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
      },
      otherOptions: {
        title: undefined,
        chart: {
          width: 598,
          height: 276,
          backgroundColor: "#fbfbfb",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
      },
      dailyStoreBudget: 0,
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    minDate(): string {
      return dayjs(this.today).subtract(30, "day").format("YYYY-MM-DD");
    },
    customerAverageAmount(): string {
      if (this.currentReport) {
        if (this.currentReport.priceAmount > 0) {
          if (this.currentReport.purchasersCount > 0) {
            return Math.ceil(this.currentReport.priceAmount / this.currentReport.purchasersCount).toLocaleString();
          } else return "∞";
        } else return "0";
      } else return "-";
    },
    purchaseRate(): string {
      if (this.currentReport?.customersCount === 0) {
        return "∞";
      } else {
        if (this.currentReport) {
          return Math.ceil(
            (100 * this.currentReport.purchasersCount) / this.currentReport.customersCount
          ).toLocaleString();
        }
        return "0";
      }
    },
    storeNames(): string[] {
      return Array.from(new Set(this.currentComments.map((it) => it.storeName ?? ""))).filter((it) => it !== "");
    },
    isCustomerCommentFilled(): boolean {
      return (
        this.editingComment.content !== "" &&
        this.editingComment.content.length < 256 &&
        this.editingComment.target !== 0b00000
      );
    },
    isStoreCommentFilled(): boolean {
      return this.editingComment.content !== "" && this.editingComment.content.length < 256;
    },
  },
  methods: {
    getWeatherIcon(weatherCode: number): string {
      switch (weatherCode) {
        case 0:
        case 1:
          return "mdi-white-balance-sunny";
        case 2:
          return "mdi-weather-partly-cloudy";
        case 3:
          return "mdi-weather-cloudy";
        case 45:
        case 48:
          return "mdi-weather-fog";
        case 51:
        case 53:
        case 55:
        case 56:
        case 57:
        case 61:
        case 63:
          return "mdi-weather-rainy";
        case 65:
          return "mdi-weather-pouring";
        case 66:
        case 67:
          return "mdi-weather-snowy-rainy";
        case 71:
        case 73:
          return "mdi-weather-snowy";
        case 75:
          return "mdi-snowman";
        case 77:
          return "mdi-snowboard";
        case 80:
        case 81:
        case 82:
          return "mdi-weather-partly-rainy";
        case 85:
        case 86:
          return "mdi-weather-partly-snowy-rainy";
        case 95:
        case 96:
          return "mdi-weather-lightning";
        case 99:
          return "mdi-lightning-bolt-outline";
        default:
          return "";
      }
    },
    showCreateStore() {
      router.push({ name: "AdminCustomerTouchPoint" });
    },
    customSaleSort(items: StoreActualsRankingSaleItemEntity[], index: string[], isDesc: boolean[]) {
      items.sort((a, b) => {
        if (index[0] === "saleResult") {
          if (!isDesc[0]) {
            return a.saleResult - b.saleResult;
          } else {
            return b.saleResult - a.saleResult;
          }
        } else {
          return 0;
        }
      });
      return items;
    },
    customOtherSort(items: StoreActualsRankingOtherItemEntity[], index: string[], isDesc: boolean[]) {
      items.sort((a, b) => {
        if (index[0] === "unitPrice") {
          if (!isDesc[0]) {
            return a.unitPrice - b.unitPrice;
          } else {
            return b.unitPrice - a.unitPrice;
          }
        } else if (index[0] === "purchasersCount") {
          if (!isDesc[0]) {
            return a.purchasersCount - b.purchasersCount;
          } else {
            return b.purchasersCount - a.purchasersCount;
          }
        } else if (index[0] === "setRate") {
          if (!isDesc[0]) {
            return a.setRate - b.setRate;
          } else {
            return b.setRate - a.setRate;
          }
        } else {
          return 0;
        }
      });
      return items;
    },
    getStoreName(storeId: string): string {
      return this.stores.find((store) => store.id === storeId)?.name ?? "集計対象外";
    },
    getMemberName(memberId: string): string {
      return this.members.find((member) => member.id === memberId)?.name ?? "";
    },
    hoverCard(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hover = true;
        this.hoverIndex = index;
      } else if (hoverFlag === 1) {
        this.hover = false;
      }
    },
    async changeGraph() {
      this.updateChart();
    },
    async updateChart() {
      if (this.storeActualsGraph) {
        // X軸の日付データの取得
        const categories = this.storeActualsGraph.map((item) => {
          return dayjs(item.date).format("MM.DD");
        });
        // 売上実績データの取得
        const dataSaleResult = this.storeActualsGraph.map((item) => {
          return item.saleResult;
        });
        // 売上予算データの取得
        const dataSaleBudget = this.storeActualsGraph.map((item) => {
          return item.saleBudget;
        });
        // 天気データの取得
        const dataSaleTemperature = this.storeActualsGraph.map((item) => {
          return item.temperature;
        });
        // 客単価, 購入客数, セット率データの取得
        const dataOtherResult = this.storeActualsGraph.map((item) => {
          switch (this.currnentGraphTab) {
            case "unitPrice":
              return item.unitPrice;
            case "purchasersCount":
              return item.purchasersCount;
            case "setRate":
              return Math.round(item.setRate * 100) / 100;
            default:
              return item.unitPrice;
          }
        });
        // Y軸のトップラインのための最大値取得
        const saleMaxData = Math.max(...dataSaleResult, ...dataSaleBudget);
        const saleMax =
          Math.ceil(saleMaxData / Math.pow(10, String(saleMaxData).length - 1)) *
          Math.pow(10, String(saleMaxData).length - 1);
        const otherMaxData = Math.max(...dataOtherResult);
        const otherMax =
          this.currnentGraphTab === "setRate"
            ? Math.ceil((Math.round(otherMaxData * 100) / 100) * 10) / 10
            : Math.ceil(otherMaxData / Math.pow(10, String(otherMaxData).length - 1)) *
              Math.pow(10, String(otherMaxData).length - 1);
        const temperatureMaxData = Math.max(...dataSaleTemperature);
        const temperatureMax =
          (temperatureMaxData / Math.pow(10, String(temperatureMaxData).length - 1)) *
          Math.pow(10, String(temperatureMaxData).length - 1);
        // Y軸のGrid幅の設定
        const saleTickInterval = saleMax / 4;
        const otherTickInterval = otherMax / 4;
        const temperatureTickInterval = temperatureMax / 4;
        // グラフデータの設定
        const saleSeries: SeriesOptionsType[] = [
          {
            name: "売上実績", // グラフの名前指定
            type: "line", // グラフの形指定
            data: dataSaleResult, // グラフに使用するデータ指定
            color: "#5FB3B1",
            dashStyle: "Solid",
            lineWidth: 3,
            yAxis: 0,
            marker: {
              radius: 8,
              symbol: "circle",
            },
          },
          // 売上予算データ
          {
            name: "売上予算", // グラフの名前指定
            type: "line", // グラフの形指定
            data: dataSaleBudget, // グラフに使用するデータ指定
            color: "#A5D4D4",
            dashStyle: "LongDash",
            lineWidth: 2,
            yAxis: 0,
            marker: {
              radius: 4,
              symbol: "circle",
            },
          },
        ];
        if (this.showWeather) {
          saleSeries.push({
            name: "気温", // グラフの名前指定
            type: "line", // グラフの形指定
            data: dataSaleTemperature, // グラフに使用するデータ指定
            color: "#F291A2",
            dashStyle: "Solid",
            lineWidth: 2,
            yAxis: 1,
            marker: {
              radius: 1,
              symbol: "circle",
            },
          });
        }

        // ツールチップの設定
        const saleTooltip: TooltipOptions = {
          formatter: function () {
            let s = this.x.toString();
            if (this.points?.length === 2) {
              s +=
                "<br/>" +
                '<b style="color:#5FB3B1">' +
                this.points[0].series.name +
                "</b>" +
                ": " +
                "￥" +
                this.points[0].y.toLocaleString();
              s +=
                "<br/>" +
                '<b style="color:#A5D4D4">' +
                this.points[1].series.name +
                "</b>" +
                ": " +
                "￥" +
                this.points[1].y.toLocaleString();
            } else if (this.points?.length === 3) {
              s +=
                "<br/>" +
                '<b style="color:#5FB3B1">' +
                this.points[0].series.name +
                "</b>" +
                ": " +
                "￥" +
                this.points[0].y.toLocaleString();
              s +=
                "<br/>" +
                '<b style="color:#A5D4D4">' +
                this.points[1].series.name +
                "</b>" +
                ": " +
                "￥" +
                this.points[1].y.toLocaleString();
              s +=
                "<br/>" +
                '<b style="color:#F291A2">' +
                this.points[2].series.name +
                "</b>" +
                ": " +
                this.points[2].y +
                "°C";
            }
            return s;
          },
          shared: true,
        };

        // OtherデータのY軸の単位の取得
        const yStartOtherUnit = this.currnentGraphTab === "unitPrice" ? "¥" : "";
        const yEndOtherUnit = "";

        // OtherデータのY軸の小数点の設定
        const yOtherDecimal = this.currnentGraphTab === "setRate" ? 1 : 0;

        // saleOptions（売上予算及び実績グラフ）の更新
        this.saleOptions = {
          title: undefined,
          chart: {
            width: 598,
            height: 276,
            backgroundColor: "#fbfbfb",
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          xAxis: [
            {
              categories,
              tickInterval: 1,
              labels: {
                style: {
                  // X軸の各ポイントにおけるカラー（assistantType50で統一）
                  color: "#799498",
                },
              },
            },
          ],
          yAxis: [
            {
              // 売上予算及び実績のy軸設定
              title: {
                text: undefined,
              },
              endOnTick: false,
              min: 0,
              max: saleMax,
              tickInterval: saleTickInterval, // y軸のgrid幅を揃える
              labels: {
                formatter: function () {
                  return `￥${Highcharts.numberFormat(Number(this.value), 0, ",", ",")}`;
                },
                style: {
                  // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                  color: "#799498",
                },
              },
            },
            {
              // 天気のy軸設定
              title: {
                text: undefined,
              },
              labels: {
                formatter: function () {
                  return `${Highcharts.numberFormat(Number(this.value), 0, ".", ",")}°C`;
                },
              },
              endOnTick: false,
              min: 0,
              max: temperatureMax,
              tickInterval: temperatureTickInterval, // y軸のgrid幅を揃える
              visible: this.showWeather,
              opposite: this.showWeather, // trueの場合グラフの右側にy軸を配置する
            },
          ],
          tooltip: saleTooltip,
          plotOptions: {
            line: {
              dataLabels: {
                enabled: false,
                color: "#006400",
                verticalAlign: "bottom",
                useHTML: true,
              },
            },
            series: {
              states: {
                inactive: {
                  enabled: false,
                },
              },
            },
          },
          series: saleSeries,
        };
        // otherOptions（客単価, 購入客数, セット率）の更新
        this.otherOptions = {
          title: undefined,
          chart: {
            width: 598,
            height: 276,
            backgroundColor: "#fbfbfb",
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          xAxis: [
            {
              categories,
              tickInterval: 1,
              labels: {
                style: {
                  // X軸の各ポイントにおけるカラー（assistantType50で統一）
                  color: "#799498",
                },
              },
            },
          ],
          yAxis: [
            {
              // 各種データのy軸設定
              title: {
                text: undefined,
              },
              endOnTick: false,
              min: 0,
              max: otherMax,
              tickInterval: otherTickInterval, // y軸のgrid幅を揃える
              labels: {
                formatter: function () {
                  return `${yStartOtherUnit}${Highcharts.numberFormat(
                    Number(this.value),
                    yOtherDecimal,
                    ".",
                    ","
                  )}${yEndOtherUnit}`;
                },
                style: {
                  // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                  color: "#799498",
                },
              },
            },
          ],
          tooltip: {
            formatter: function () {
              let s = this.x.toString();
              if (this.points) {
                const dataName = this.points[0].series.name;
                const startUnit = dataName === "客単価" ? "¥" : "";
                const endUnit = "";
                s +=
                  "<br/>" +
                  `<b style="color:${this.points[0].color}">` +
                  this.points[0].series.name +
                  "</b>" +
                  ": " +
                  startUnit +
                  this.points[0].y +
                  endUnit;
              }
              return s;
            },
            shared: true,
          },
          plotOptions: {
            line: {
              dataLabels: {
                enabled: false,
                color: "#006400",
                verticalAlign: "bottom",
                useHTML: true,
              },
            },
            series: {
              states: {
                inactive: {
                  enabled: false,
                },
              },
            },
          },
          series: [
            {
              name:
                this.currnentGraphTab !== "purchasersCount"
                  ? this.currnentGraphTab !== "setRate"
                    ? "客単価"
                    : "セット率"
                  : "購入客数",
              type: "line", // グラフの形指定
              data: dataOtherResult, // グラフに使用するデータ指定
              color: "#5FB3B1",
              dashStyle: "Solid",
              lineWidth: 3,
              marker: {
                radius: 8,
                symbol: "circle",
              },
            },
          ],
        };
      }
    },
    async changeStoreActual() {
      this.isLoading = true;
      // グラフデータの取得
      const storeActualsGraph = await RadialApiClient.getStoreActualsGraph(
        this.rangeDates[0],
        this.rangeDates[1],
        this.selectedStore?.id ?? ""
      );
      if (storeActualsGraph) {
        this.storeActualsGraph = storeActualsGraph.items;
      } else {
        this.showAlert(
          `エラーが発生しております。
          もしもう一度お試しいただき解決されなければ、
          cs@proces.co.jp
          にご連絡くださいませ。`,
          "error"
        );
      }
      this.updateChart();
      // 売上予算および実績のテーブル数値取得
      const storeActualsSaleRanking = await RadialApiClient.listStoreActualsSaleRanking(
        this.rangeDates[0],
        this.rangeDates[1]
      );
      if (storeActualsSaleRanking) {
        this.storeActualsSaleRanking = {
          items: storeActualsSaleRanking.items.filter((storeSales) => {
            return this.stores.some((store) => store.id === storeSales.storeId);
          }),
          total: storeActualsSaleRanking.total,
        };
      } else {
        this.showAlert(
          `エラーが発生しております。
          もしもう一度お試しいただき解決されなければ、
          cs@proces.co.jp
          にご連絡くださいませ。`,
          "error"
        );
      }
      // 客単価, 購入客数, セット率のテーブル数値取得
      const storeActualsOtherRanking = await RadialApiClient.listStoreActualsOtherRanking(
        this.rangeDates[0],
        this.rangeDates[1]
      );
      if (storeActualsOtherRanking) {
        this.storeActualsOtherRanking = {
          items: storeActualsOtherRanking.items.filter((storeOthers) => {
            return this.stores.some((store) => store.id === storeOthers.storeId);
          }),
          total: storeActualsOtherRanking.total,
        };
      } else {
        this.showAlert(
          `エラーが発生しております。
          もしもう一度お試しいただき解決されなければ、
          cs@proces.co.jp
          にご連絡くださいませ。`,
          "error"
        );
      }
      this.isLoading = false;
    },
    async changeReportDate() {
      this.isLoading = true;
      this.dateMenu = false;
      this.reportSummaryEntities = (
        (await RadialApiClient.listStoreActualsDailyReport(this.selectedDate))?.items ?? []
      ).filter((dailyReport) => dailyReport.storeAnalysisId !== null);
      this.sortList(1);
      this.isLoading = false;
    },
    async selectReport(report: StoreActualsDailyReportItemEntity) {
      this.isLoading = true;
      this.currentReport = await RadialApiClient.getStoreAnalysis(report.storeAnalysisId);
      // コメントタブの合計情報の取得
      this.reportCommentEntity = await RadialApiClient.getReportComment(this.selectedDate, report.storeId);
      // コメントタブのコメント情報の取得
      this.currentComments = (await RadialApiClient.listReportComments(this.selectedDate, report.storeId)) ?? [];
      // 売上商品タブの情報取得
      this.reportSalesProducts = [];
      const skuSummaries = await RadialApiClient.listReportSalesProducts(this.selectedDate, report.storeId);
      if (skuSummaries) {
        // SKU単位のデータをProduct単位へ変換する
        skuSummaries.forEach((skuSummary) => {
          const productSummary = this.reportSalesProducts.find(
            (reportSalesProduct) => reportSalesProduct.productId === skuSummary.productId
          );
          if (productSummary) {
            productSummary.summary.sumQuantity += skuSummary.sumQuantity;
            // countCommentsはProduct単位で返ってきているため更新なし
          } else {
            this.reportSalesProducts.push({
              productId: skuSummary.productId,
              summary: {
                productName: skuSummary.productName,
                itemNumber: skuSummary.itemNumber,
                sumQuantity: skuSummary.sumQuantity,
                countComments: skuSummary.countComments,
              },
            });
          }
        });
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      const dailyStoreBudget = await RadialApiClient.getStoreDailyBudget(
        report.storeId,
        dayjs(this.selectedDate).format("YYYY"),
        dayjs(this.selectedDate).format("MM"),
        dayjs(this.selectedDate).format("DD")
      );
      if (dailyStoreBudget) {
        this.dailyStoreBudget = dailyStoreBudget.saleBudget;
      }
      this.isLoading = false;
    },
    positivityRates(index: number): string {
      if (this.reportCommentEntity) {
        const total =
          (this.reportCommentEntity.sumPositiveCommentsByProductId ?? 0) +
          (this.reportCommentEntity.sumNegativeCommentsByProductId ?? 0) +
          (this.reportCommentEntity.sumNeutralCommentsByProductId ?? 0);
        if (total > 0) {
          switch (index) {
            case 0:
              return Math.floor(
                (100 * (this.reportCommentEntity.sumPositiveCommentsByProductId ?? 0)) / total
              ).toLocaleString();
            case 1:
              return Math.floor(
                (100 * (this.reportCommentEntity.sumNegativeCommentsByProductId ?? 0)) / total
              ).toLocaleString();
            case 2:
              return Math.floor(
                (100 * (this.reportCommentEntity.sumNeutralCommentsByProductId ?? 0)) / total
              ).toLocaleString();
            default:
              return "-";
          }
        }
      }
      return "-";
    },
    inputCount() {
      if (this.currentReport) {
        this.currentReport.customersCount = Number(
          this.currentReport.customersCount.toString().replace(/[^0123456789]/g, "")
        );
        if (!this.currentReport.customersCount) {
          this.currentReport.customersCount = 0;
        }
      }
    },
    inputDailyReport() {
      if (this.currentReport && this.currentReport.memberId !== null && this.currentReport.description !== null) {
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    },
    closeSentReportDialog() {
      this.sentReportDialog = false;
      this.currentReport = null;
    },
    async updateReport(status: number) {
      if (this.currentReport) {
        this.currentReport.status = status;
        await RadialApiClient.updateStoreAnalysis(this.currentReport.id, {
          status: this.currentReport.status,
          customersCount: Number(this.currentReport.customersCount),
          memberId: this.currentReport.memberId,
          description: this.currentReport.description,
        })
          .then(async () => {
            if (status === 1) {
              this.sentReportDialog = true;
            } else {
              this.tempSavedAlert = true;
              setTimeout(() => {
                this.tempSavedAlert = false;
              }, 3000);
            }
            await this.changeReportDate();
            this.buttonDisabled = true;
          })
          .catch(() => {
            this.showAlert(
              `エラーが発生しております。
              もしもう一度お試しいただき解決されなければ、
              cs@proces.co.jp
              にご連絡くださいませ。`,
              "error"
            );
          });
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getTarget(comment: ReportStoreCommentEntity): string {
      if (comment.skuId && comment.skuId !== "null") {
        if (!comment.sizeName && !comment.colorName) {
          return comment.itemNumber;
        } else {
          if (!comment.colorName) {
            comment.colorName = "";
          }
          if (!comment.sizeName) {
            comment.sizeName = "";
          }
        }
        return `${comment.colorName}\n${comment.sizeName}`;
      }
      return comment.itemNumber;
    },
    getTargetName(target: number): string[] {
      const list: string[] = [];
      if (target & 0b10000) {
        list.push("色味");
      }
      if (target & 0b01000) {
        list.push("サイズ感");
      }
      if (target & 0b00100) {
        list.push("仕様");
      }
      if (target & 0b00010) {
        list.push("素材・質感");
      }
      if (target & 0b00001) {
        list.push("その他");
      }
      return list;
    },
    getPositivityName(positivity: number) {
      if (positivity == 0) {
        return "ポジティブ";
      } else if (positivity == 1) {
        return "ネガティブ";
      } else {
        return "中立";
      }
    },
    showDetail(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    getMainImage(itemNumber: string, colorName: string): string {
      const images = this.images.filter((image) =>
        image.startsWith(`${this.user?.companyId}/${itemNumber}_${colorName}`)
      );
      if (images.length > 0) {
        const mainImages = images.filter((image) => image.endsWith("_M"));
        if (mainImages.length > 0) {
          return mainImages[0];
        } else {
          return images[0];
        }
      }
      return "";
    },
    getImageFromProduct(itemNumber: string): string {
      const images = this.images.filter((image) => image.startsWith(`${this.user?.companyId}/${itemNumber}_`));
      if (images.length > 0) {
        const mainImages = images.filter((image) => image.endsWith("_M"));
        if (mainImages.length > 0) {
          return mainImages[0];
        } else {
          return images[0];
        }
      }
      return "";
    },
    showDeleteCommentDialog(id: string) {
      this.showingDeleteCommentDialog = true;
      this.deleteCommentId = id;
    },
    async deleteComment() {
      await RadialApiClient.deleteComment(this.deleteCommentId);
      if (this.currentReport) {
        this.currentComments =
          (await RadialApiClient.listReportComments(this.selectedDate, this.currentReport.storeId)) ?? [];
      }
      this.showingDeleteCommentDialog = false;
    },
    async getSkuName(sku: Sku): Promise<string> {
      const color = sku.colorId ? await RadialApiClient.getColor(sku.colorId) : null;
      const size = sku.sizeId ? await RadialApiClient.getSize(sku.sizeId) : null;
      return `${color ? color.name : null} ${size ? size.name : null}`;
    },
    async closeCommentThanksDialog() {
      this.editingComment = {
        id: "",
        companyId: "",
        productId: "",
        skuId: null,
        content: "",
        positivity: 0,
        target: 0b00000,
        servedAt: new Date(),
        age: "10代",
        gender: "女性",
        figure: "やせ",
        height: "~150",
        storeId: "",
        memberId: "",
        category: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      this.dialogCommentThanks = false;
    },
    editComment(comment: ReportStoreCommentEntity) {
      this.editingComment.productId = comment.productId;
      this.skus = comment.skus;
      this.editingComment.id = comment.commentId;
      this.editingComment.companyId = this.user?.companyId ?? "";
      this.editingComment.skuId = comment.skuId;
      this.editingComment.memberId = comment.memberId;
      this.editingComment.age = comment.age;
      this.editingComment.gender = comment.gender;
      this.editingComment.figure = comment.figure;
      this.editingComment.height = comment.height;
      this.editingComment.content = comment.content;
      this.editingComment.positivity = comment.positivity;
      this.editingComment.target = comment.target;
      this.editingComment.category = comment.category;
      this.editingComment.servedAt = dayjs(comment.servedAt).toDate();
      if (comment.memberName) {
        this.memberName = comment.memberName;
      }
      if (comment.category === 1) {
        this.commentTab = "store-comment";
      } else if (comment.category === 0) {
        this.commentTab = "customer-comment";
      }
      this.showUpdateComment = true;
    },
    async updateComment(index: number) {
      if (index === 1) {
        this.editingComment.category = 1;
        this.editingComment.positivity = 3;
        this.editingComment.target = 0b00000;
        this.editingComment.gender = "";
        this.editingComment.figure = "";
        this.editingComment.age = "";
        this.editingComment.height = "";
      }
      if (this.editingComment.memberId) {
        if (this.editingComment.skuId === "null") {
          const skuDto: UpdateCommentDto = {
            age: this.editingComment.age,
            figure: this.editingComment.figure,
            height: this.editingComment.height,
            content: this.editingComment.content,
            gender: this.editingComment.gender,
            positivity: this.editingComment.positivity,
            servedAt: this.editingComment.servedAt,
            target: this.editingComment.target,
            category: this.editingComment.category,
            member: { connect: { id: this.editingComment.memberId } },
          };
          await RadialApiClient.updateComment(this.editingComment.id, skuDto);
        } else if (this.editingComment.skuId) {
          const dto: UpdateCommentDto = {
            age: this.editingComment.age,
            figure: this.editingComment.figure,
            height: this.editingComment.height,
            content: this.editingComment.content,
            gender: this.editingComment.gender,
            positivity: this.editingComment.positivity,
            servedAt: this.editingComment.servedAt,
            target: this.editingComment.target,
            category: this.editingComment.category,
            member: { connect: { id: this.editingComment.memberId } },
          };
          await RadialApiClient.updateComment(this.editingComment.id, dto);
        }
      }
      if (this.currentReport) {
        this.currentComments =
          (await RadialApiClient.listReportComments(this.selectedDate, this.currentReport.storeId)) ?? [];
      }
      this.showUpdateComment = false;
      this.dialogCommentThanks = true;
    },
    showUpdateCommentCanceled() {
      this.editingComment = {
        id: "",
        companyId: "",
        productId: "",
        skuId: null,
        content: "",
        positivity: 0,
        target: 0b00000,
        servedAt: new Date(),
        age: "10代",
        gender: "女性",
        figure: "やせ",
        height: "~150",
        storeId: "",
        memberId: "",
        category: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      this.showUpdateComment = false;
    },
    sortList(index: number) {
      switch (index) {
        case 0:
          if (this.members) {
            this.members.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              } else {
                return 1;
              }
            });
          }
          return;
        case 1:
          if (this.reportSummaryEntities) {
            this.reportSummaryEntities.sort((item) => {
              if (item.storeId === this.store?.id) {
                return -1;
              } else if (item.memberId) {
                return -2;
              } else {
                return 0;
              }
            });
          }
          return;
        case 2:
          if (this.stores) {
            this.stores.sort((store) => {
              if (store.serviceId?.toString().length === 3) {
                return -1;
              } else {
                return 0;
              }
            });
          }
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.user) {
      this.images = (await LocalDataService.getImageKeys(this.user.companyId)) ?? [];
      const stores = (await RadialApiClient.listStores()) ?? [];
      this.store = this.isAdmin ? null : stores.find((store) => store.cognitoSub === this.user?.sub) ?? null;
      this.stores = stores
        .filter((store) => store.operating === true && store.deleted === false)
        .map((store) => {
          return {
            id: store.id,
            name: store.name,
            cognitoSub: store.cognitoSub ?? "",
            serviceId: store.serviceId,
          };
        });
      this.sortList(2);
      // 全ての店舗選択を配列に追加
      this.stores.unshift({
        id: "total",
        name: "ALL",
        cognitoSub: "",
        serviceId: null,
      });
      this.members = (await RadialApiClient.getMembers()) ?? [];
      this.sortList(0);
      if (!this.isAdmin) {
        this.storeMembers = this.members
          .filter((member) => member.storeId === this.store?.id)
          .map((storeMember) => {
            return {
              id: storeMember.id,
              name: storeMember.name,
            };
          });
      }
      this.selectedStore = this.isAdmin
        ? this.stores.find((store) => store.id === "total") ?? null
        : this.stores.find((store) => store.cognitoSub === this.user?.sub) ?? null;
      if (this.stores.length > 1) {
        // 店舗が作成されている場合のみ、エラー解消のためStoreActualsのAPIを叩く（ALL店舗は追加済み）
        await this.changeReportDate();
        await this.changeStoreActual();
      }
    }

    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/report",
        page_title: "Adminレポート画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/report",
        page_title: "Staffレポート画面",
      });
    }

    this.isLoading = false;
  },
});
