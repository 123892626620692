
import { User } from "@/store/model";
import Vue from "vue";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import Loading from "@/components/vuetify/Loading.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import mixin from "@/mixin/mixin";
import {
  AdminUser,
  Attribute,
  Brand,
  Category,
  CustomTag,
  CustomTagMaster,
  Factory,
  Product,
  ProductStore,
  Season,
} from "@/api/entities";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { CreateCustomTagDto, CreateCustomTagMasterDto } from "@/api/dto";
import store from "@/store";
import dayjs from "dayjs";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import * as lodash from "lodash";

export interface DataType {
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  isLoading: boolean;
  editingProduct: EditingProduct | null;
}

export interface EditingProduct {
  // 品番編集のための型
  product: Product;
  priceString: string; // 数字以外の文字を抜いて、3桁カンマ区切り処理のために文字列で情報を持っている
  salesPriceString: string | null; // 数字以外の文字を抜いて、3桁カンマ区切り処理のために文字列で情報を持っている
  leadTimeString: string | null;
  minimumOrderQuantityString: string | null;
  salesStartedAt: Date | null;
  salesEndedAt: Date | null;
}

export default Vue.extend({
  name: "MdMapBasic",
  mixins: [mixin],
  components: {
    S3Image,
    Loading,
    ConfirmationDialog,
    anum,
    ElementDatePicker,
  },
  props: {
    selectedProduct: {
      type: Object as () => Product,
      default: null,
    },
    selectedSeason: {
      type: Object as () => Season,
      default: null,
    },
    selectedBrand: {
      type: Object as () => Brand,
      default: null,
    },
    selectedCategory: {
      type: Object as () => Category,
      default: null,
    },
    weightedAverageCost: {
      type: Number,
      default: null,
    },
    attribute1s: {
      type: Array as () => Attribute[],
      required: true,
    },
    attribute2s: {
      type: Array as () => Attribute[],
      required: true,
    },
    attribute3s: {
      type: Array as () => Attribute[],
      required: true,
    },
    factories: {
      type: Array as () => Factory[],
      required: true,
    },
  },
  data(): DataType {
    return {
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      isLoading: false,
      editingProduct: null,
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    isProductEditing(): boolean {
      const preProduct = lodash.cloneDeep({
        product: this.selectedProduct,
        priceString: this.selectedProduct.price?.toLocaleString() ?? "0",
        salesPriceString: this.selectedProduct.salesPrice?.toLocaleString() ?? null,
        leadTimeString: this.selectedProduct.leadTime?.toLocaleString() ?? null,
        minimumOrderQuantityString: this.selectedProduct.minimumOrderQuantity?.toLocaleString() ?? null,
        salesStartedAt: this.selectedProduct.salesStartedAt ?? dayjs(this.selectedYearMonth).toDate(),
        salesEndedAt:
          this.selectedProduct.salesEndedAt ?? dayjs(this.selectedSeason.endYearMonth).endOf("month").toDate(),
      });
      const postProduct = lodash.cloneDeep(this.editingProduct);
      return !lodash.isEqual(preProduct, postProduct);
    },
    isDisabledSaveProductButton(): boolean {
      if (this.editingProduct && this.editingProduct.product.status === "ORDERED") {
        if (!this.editingProduct.product.itemNumber || !this.editingProduct.priceString) {
          return true;
        }
      }
      return false;
    },
    minSalesStartedAtRange(): string {
      // 販売開始日の選択可能な最も古い月は、シーズン初月とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").format("YYYY-MM-DD");
    },
    maxSalesStartedAtRange(): string {
      // 販売開始日の選択可能な最も新しい月は、シーズン最終月とする
      return dayjs(this.selectedSeason?.endYearMonth).endOf("month").format("YYYY-MM-DD");
    },
    minSalesEndedAtRange(): string {
      // 販売終了日の選択可能な最も古い月は、シーズン初月とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").format("YYYY-MM-DD");
    },
    genderList(): { id: number; text: string }[] {
      return Constant.genderList;
    },
    storeOptions(): ProductStore[] {
      return Constant.storeOptions;
    },
    isProhibited() {
      const prohibitedChars = /\\|\/|:|\*|\?|"|<|>|\||_/;
      if (this.editingProduct?.product.itemNumber) {
        return prohibitedChars.test(this.editingProduct.product.itemNumber);
      } else {
        return false;
      }
    },
  },
  watch: {
    isProductEditing(isTabInfoEditing: boolean) {
      this.$emit("is-tab-info-changed", isTabInfoEditing);
    },
    isDisabledSaveProductButton(isTabInfoDisabled: boolean) {
      this.$emit("is-product-editing-disabled", isTabInfoDisabled);
    },
    selectedProduct() {
      this.updateEditingProduct();
    },
  },
  methods: {
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    getConvertedNumber(numString: string): number {
      // 数字以外の文字を消す
      return Number(numString.replace(/[^0123456789]/g, ""));
    },
    inputNumber(numString: string, index: number) {
      const validNumString = this.getConvertedNumber(numString).toLocaleString();
      switch (index) {
        case 0:
          if (this.editingProduct) {
            if (this.editingProduct.salesPriceString) {
              const price = this.getConvertedNumber(validNumString);
              const salesPrice = this.getConvertedNumber(this.editingProduct.salesPriceString);
              // 数字以外の文字を消す
              if (price < salesPrice) {
                this.editingProduct.priceString = this.editingProduct.salesPriceString;
                return;
              }
            }
            this.editingProduct.priceString = validNumString;
          }
          return;
        case 1:
          if (this.editingProduct) {
            if (this.editingProduct.priceString) {
              const price = this.getConvertedNumber(this.editingProduct.priceString);
              const salesPrice = this.getConvertedNumber(validNumString);
              if (price < salesPrice) {
                this.editingProduct.salesPriceString = this.editingProduct.priceString;
                return;
              }
            }
            this.editingProduct.salesPriceString = validNumString;
          }
          return;
        case 2:
          if (this.editingProduct) {
            this.editingProduct.leadTimeString = validNumString;
          }
          return;
        case 3:
          if (this.editingProduct) {
            this.editingProduct.minimumOrderQuantityString = validNumString;
          }
          return;
        default:
          return;
      }
    },
    updateEditingProduct() {
      this.editingProduct = {
        product: { ...this.selectedProduct },
        priceString: this.selectedProduct.price?.toLocaleString() ?? "0",
        salesPriceString: this.selectedProduct.salesPrice?.toLocaleString() ?? null,
        salesStartedAt: this.selectedProduct.salesStartedAt ?? dayjs(this.selectedYearMonth).toDate(),
        salesEndedAt:
          this.selectedProduct.salesEndedAt ?? dayjs(this.selectedSeason.endYearMonth).endOf("month").toDate(),
        leadTimeString: this.selectedProduct.leadTime?.toLocaleString() ?? null,
        minimumOrderQuantityString: this.selectedProduct.minimumOrderQuantity?.toLocaleString() ?? null,
      };
    },
    async saveProduct() {
      this.isLoading = true;
      if (this.editingProduct) {
        // stringで持っていた数値をnumberにする
        const price = this.editingProduct.priceString ? this.getConvertedNumber(this.editingProduct.priceString) : 0;
        const salesPrice = this.editingProduct.salesPriceString
          ? this.getConvertedNumber(this.editingProduct.salesPriceString)
          : 0;
        const leadTime = this.editingProduct.leadTimeString
          ? this.getConvertedNumber(this.editingProduct.leadTimeString)
          : 0;
        const minimumOrderQuantity = this.editingProduct.minimumOrderQuantityString
          ? this.getConvertedNumber(this.editingProduct.minimumOrderQuantityString)
          : 0;
        await RadialApiClient.updateProduct(this.editingProduct.product.id, {
          name: this.editingProduct.product.name,
          itemNumber: this.editingProduct.product.itemNumber,
          gender: this.editingProduct.product.gender,
          factory: this.editingProduct.product.factoryId
            ? {
                connect: {
                  id: this.editingProduct.product.factoryId,
                },
              }
            : undefined,
          store: this.editingProduct.product.store,
          attribute1: this.editingProduct.product.attribute1Id
            ? {
                connect: {
                  id: this.editingProduct.product.attribute1Id,
                },
              }
            : undefined,
          attribute2: this.editingProduct.product.attribute2Id
            ? {
                connect: {
                  id: this.editingProduct.product.attribute2Id,
                },
              }
            : undefined,
          attribute3: this.editingProduct.product.attribute3Id
            ? {
                connect: {
                  id: this.editingProduct.product.attribute3Id,
                },
              }
            : undefined,
          price,
          salesPrice,
          leadTime,
          minimumOrderQuantity,
          salesStartedAt: this.editingProduct.salesStartedAt
            ? dayjs(this.editingProduct.salesStartedAt).toDate()
            : undefined,
          salesEndedAt: this.editingProduct.salesEndedAt ? dayjs(this.editingProduct.salesEndedAt).toDate() : undefined,
          description: this.editingProduct.product.description,
          composition: this.editingProduct.product.composition,
          directionMemo: this.editingProduct.product.directionMemo,
          status: this.editingProduct.product.status,
          // salesUpdatedAt
          freeComment: this.editingProduct.product.freeComment,
        })
          .then(() => {
            // スタッツ及びselectedProductの更新
            this.$emit(
              "update-stats",
              this.selectedProduct.id,
              this.selectedProduct.seasonId,
              this.selectedProduct.brandId,
              this.selectedProduct.categoryId
            );
            this.showAlert(
              `品番情報を更新しました！
              MDマップにて引き続き、skuや発注情報の追加をしていきましょう。`,
              "success",
              -1
            );
          })
          // TODO: errorをうまくcatchできていないため確認必要
          .catch((error) => {
            if (error.response.data.statusCode === 400) {
              if (error.response.data.message === "Unique constraint failed on the fields: companyId, itemNumber") {
                this.showAlert(
                  `同じ品番のアイテムが既に登録されているようです。
                  入力された品番を再度ご確認ください。`,
                  "error",
                  -1
                );
              }
            } else {
              this.showAlert(
                `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
                "error",
                -1
              );
            }
          });
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.updateEditingProduct();
    this.isLoading = false;
  },
});
