
import Vue from "vue";
import dayjs from "dayjs";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import RadialApiClient from "@/api/RadialApiClient";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import S3Image from "@/components/aws/S3Image.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import mixin from "@/mixin/mixin";
import { Store, Warehouse, Color, Size } from "@/api/entities";
import {
  ActionCreateDto,
  ActionRequestSearchFilterDto,
  CreateActionDto,
  StockActionType,
  UpdateStockActionDetailDto,
} from "@/api/dto";
import { ActionRequestSearchResultEntity, AdminUser } from "@/api/entities";
import store from "@/store";
import { AdminUserDetail } from "@/store/model";

export interface StockSearchEntries {
  sourceList: Store[] | Warehouse[];
  destinationList: Store[]; // | Warehouse[]; // 5.3.3移行実装予定の在庫返却で使用
}

export interface StockFilterInfo {
  stores: Store[];
  warehouses: Warehouse[];
}

export interface DataType {
  rangeDates: string[];
  rangeDatesMenu: boolean;
  cancelDates: string[];
  isCanceled: boolean;
  searchEntries: StockSearchEntries;
  stores: Store[];
  warehouses: Warehouse[];
  colors: Color[];
  sizes: Size[];
  selectedActionRequestIds: string[];
  actionOption: string;
  showingAlert: boolean;
  showingCreateAction: boolean;
  createActionListInfo: { title: string | null; managerId: string | null; executedDate: Date | null };
  alertMessage: string;
  alertType: string;
  actionReqestTableDataList: ActionRequestSearchResultEntity[];
  hoverIndex: number;
  clickedActionRequestIndex: number | null;
  adminUserDetails: AdminUserDetail[];
  adminUsers: AdminUser[];
}

export default Vue.extend({
  name: "Stock",
  mixins: [mixin],
  components: {
    FilterChipButton,
    ElementRangeDatePicker,
    ElementDatePicker,
    S3Image,
    ConfirmationDialog,
  },
  data(): DataType {
    return {
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      rangeDatesMenu: false,
      cancelDates: [],
      isCanceled: false,
      searchEntries: {
        sourceList: [],
        destinationList: [],
      },
      stores: [],
      warehouses: [],
      colors: [],
      sizes: [],
      selectedActionRequestIds: [],
      actionOption: "アクション",
      showingAlert: false,
      showingCreateAction: false,
      createActionListInfo: { title: null, managerId: null, executedDate: null },
      alertMessage: "",
      alertType: "success",
      actionReqestTableDataList: [],
      hoverIndex: -1,
      clickedActionRequestIndex: null,
      adminUserDetails: [],
      adminUsers: [],
    };
  },
  props: {
    actionType: {
      type: String,
      required: true,
    },
  },
  computed: {
    stockFilterInfo(): StockFilterInfo {
      return {
        stores: this.stores,
        warehouses: this.warehouses,
      };
    },
    actionOptions(): string[] {
      return ["アクション作成", "リクエストから削除"];
    },
  },
  methods: {
    async changeDateRange() {
      this.$emit("isLoading", true);
      await this.search();
      this.isCanceled = false;
      this.rangeDatesMenu = false;
      this.$emit("isLoading", false);
    },
    optionSelected(event: any) {
      if (event === "アクション作成") {
        this.showingCreateAction = true;
      } else {
        this.showAlert("本当に選択したリクエストを削除してよろしいですか？", "error");
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async search() {
      this.$emit("isLoading", true);
      let dto: ActionRequestSearchFilterDto;
      if (this.actionType === "stock-moving") {
        dto = {
          start: dayjs(this.rangeDates[0]).startOf("day").toDate(),
          end: dayjs(this.rangeDates[1]).endOf("day").toDate(),
          stockActionType: StockActionType.STOCKMOVING,
        };
        if (this.searchEntries.sourceList.length !== 0) {
          dto.sourceStoreIds = this.searchEntries.sourceList.map((source) => source.id);
        }
        if (this.searchEntries.destinationList.length !== 0) {
          dto.destinationStoreIds = this.searchEntries.destinationList.map((destination) => destination.id);
        }
      } else {
        dto = {
          start: dayjs(this.rangeDates[0]).startOf("day").toDate(),
          end: dayjs(this.rangeDates[1]).endOf("day").toDate(),
          stockActionType: StockActionType.STOCKFOLLOW,
        };
        if (this.searchEntries.sourceList.length !== 0) {
          dto.sourceWarehouseIds = this.searchEntries.sourceList.map((source) => source.id);
        }
        if (this.searchEntries.destinationList.length !== 0) {
          dto.destinationStoreIds = this.searchEntries.destinationList.map((destination) => destination.id);
        }
      }
      this.actionReqestTableDataList = (await RadialApiClient.listActionRequset(dto)) ?? [];
      this.$emit("isLoading", false);
    },
    selectProduct(item: ActionRequestSearchResultEntity) {
      if (this.selectedActionRequestIds.includes(item.stockActionDetailId)) {
        // item が含まれていたら削除する
        this.selectedActionRequestIds = this.selectedActionRequestIds.filter(
          (selectedId) => selectedId !== item.stockActionDetailId
        );
      } else {
        this.selectedActionRequestIds.push(item.stockActionDetailId);
      }
    },
    showDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    clickMovingStockField(index: number) {
      this.clickedActionRequestIndex = index;
    },
    async changeMovingStockField(index: number) {
      this.clickedActionRequestIndex = index;
      await this.changeQuantity();
    },
    async hover(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hoverIndex = index;
      } else if (hoverFlag === 1) {
        if (this.clickedActionRequestIndex !== null) {
          // hoverが切り替わるかつ在庫数のフィールドがクリックされていた時にAPIを叩いて在庫数を変更
          await this.changeQuantity();
          this.clickedActionRequestIndex = null;
        }
        this.hoverIndex = -1;
      }
    },
    async changeQuantity() {
      // tableDataのquantityを変更
      if (this.clickedActionRequestIndex !== null) {
        const newQuantity = Number(
          this.actionReqestTableDataList[this.clickedActionRequestIndex].quantity
            .toString()
            .replace(/[^0123456789]/g, "")
        );
        this.actionReqestTableDataList[this.clickedActionRequestIndex].quantity = newQuantity >= 0 ? newQuantity : 0;

        // API叩いてstockActionDetailテーブルの対象レコードのquantityを更新
        const dto: UpdateStockActionDetailDto = {
          quantity: this.actionReqestTableDataList[this.clickedActionRequestIndex].quantity,
        };
        await RadialApiClient.updateStockActionDetail(
          this.actionReqestTableDataList[this.clickedActionRequestIndex].stockActionDetailId,
          dto
        );
      }
    },
    getColorName(colorId: string): string {
      return this.colors.find((color) => color.id === colorId)?.name ?? "";
    },
    getSizeName(sizeId: string): string {
      return this.sizes.find((size) => size.id === sizeId)?.name ?? "";
    },
    getStoreName(storeId: string): string {
      return this.stores.find((store) => store.id === storeId)?.name ?? "";
    },
    getWarehouseName(warehouseId: string): string {
      return this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name ?? "";
    },
    async deleteRequest() {
      for (const actionRequestId of this.selectedActionRequestIds) {
        await RadialApiClient.deleteStockActionDetail(actionRequestId);
      }
      await this.search();
      this.showingAlert = false;
      this.selectedActionRequestIds = [];
    },
    cancelCreateAction() {
      // 設定をリセット
      this.showingCreateAction = false;
      this.actionOption = "アクション";
      this.createActionListInfo.title = null;
      this.createActionListInfo.managerId = null;
      this.createActionListInfo.executedDate = null;
    },
    async createAction() {
      if (this.createActionListInfo.title) {
        // Actionテーブルにレコードを追加
        const actionDto: CreateActionDto = {
          title: this.createActionListInfo.title ?? "",
          type: this.actionType === "stock-moving" ? "STOCKMOVING" : "STOCKFOLLOW",
          customActionMasterId: null,
          creatorAdminUserId: store.state.user?.sub,
          managerAdminUserId: this.createActionListInfo.managerId,
          status: "PENDING",
          company: { connect: { id: store.state.company?.id } },
          executedDate: this.createActionListInfo.executedDate,
        };
        const action = await RadialApiClient.createAction(actionDto);
        if (action) {
          const dto: ActionCreateDto = {
            stockActionDetailIds: this.selectedActionRequestIds,
            // TODO: このタイトル無意味のためバックエンド対応とともに削除
            title: this.createActionListInfo.title,
            actionId: action?.id ?? "",
          };
          await RadialApiClient.createActionFromRequests(dto)
            .then(async () => {
              await this.search();
              this.showAlert("アクションを作成しました。", "success");
            })
            .catch(() => {
              this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
            });
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
        }
      }
      // 設定をリセット
      this.showingCreateAction = false;
      this.actionOption = "アクション";
      this.createActionListInfo.title = null;
      this.createActionListInfo.managerId = null;
      this.createActionListInfo.executedDate = null;
      this.selectedActionRequestIds = [];
    },
    createActionButtonDisable(): boolean {
      return !(this.createActionListInfo.title && this.createActionListInfo.managerId);
    },
    getCreateAt(date: Date): string {
      return dayjs(date).format("YYYY/MM/DD");
    },
    getAdminUserName(id: String | null): string {
      if (id) {
        // adminUserの場合
        return this.adminUsers.find((adminUser) => adminUser.id === id)?.name ?? "";
      } else {
        // companyの場合
        return store.state.company?.name ?? "";
      }
    },
    getIcon(id: String | null): string {
      if (id) {
        // adminUserの場合
        return this.adminUsers.find((adminUser) => adminUser.id === id)?.icon ?? "";
      } else {
        // companyの場合
        return store.state.company?.icon ?? "";
      }
    },
  },
  watch: {
    rangeDatesMenu: {
      async handler() {
        if (this.rangeDatesMenu) {
          this.cancelDates = this.rangeDates;
          this.isCanceled = true;
        } else if (!this.rangeDatesMenu && this.isCanceled) {
          this.rangeDates = this.cancelDates;
        }
      },
    },
    showingCreateAction: {
      handler() {
        if (!this.showingCreateAction) {
          this.actionOption = "アクション";
        }
      },
    },
    showingAlert: {
      handler() {
        if (!this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    // 各テーブルの情報を取得
    await Promise.all([
      (async () => {
        this.stores = (await RadialApiClient.listStores()) ?? [];
      })(),
      (async () => {
        this.warehouses = (await RadialApiClient.listWarehouses()) ?? [];
      })(),
      (async () => {
        this.colors = (await RadialApiClient.listColors()) ?? [];
      })(),
      (async () => {
        this.sizes = (await RadialApiClient.listSizes()) ?? [];
      })(),
      (async () => {
        this.adminUsers = (await RadialApiClient.listAdminUsers()) ?? [];
      })(),
    ]).catch(() => {
      this.showAlert(
        `エラーが発生しております。
          時間を空けてからもう一度お試しください。`,
        "error"
      );
    });

    // 作成者と担当者のリスト情報を取得
    if (store.state.user) {
      if (store.state.company) {
        this.adminUserDetails.push({
          id: store.state.company.id,
          icon: store.state.company.icon ?? "",
          name: store.state.company.name,
        });
      }
    }
    const adminUsers: AdminUser[] = (await RadialApiClient.listAdminUsers()) ?? [];
    adminUsers.map((adminUser) => {
      if (adminUser.adminUserMasterId !== 3) {
        this.adminUserDetails.push({ id: adminUser.id, icon: adminUser.icon ?? "", name: adminUser.name });
      }
    });

    await this.search();
    this.$emit("isLoading", false);
  },
});
