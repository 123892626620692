
import { CommentFilter, User } from "@/store/model";
import Vue from "vue";
import LocalDataService from "@/store/LocalDataService";
import S3Image from "@/components/aws/S3Image.vue";
import {
  Member,
  Product,
  Sku,
  Comment,
  ProductDetailCommentEntity,
  Size,
  Color,
  Store,
  GetDetailImagesEntity,
} from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import dayjs from "dayjs";
import { UpdateCommentDto } from "@/api/dto";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  isLoading: boolean;
  tableFormat: number;
  filters: string[];
  filterKeys: string[];
  categories: string[];
  positivities: string[];
  targets: string[];
  ages: string[];
  genders: string[];
  commentFilter: CommentFilter;
  filteredComments: ProductDetailCommentEntity[];
  showUpdateComment: boolean;
  showingDeleteCommentDialog: boolean;
  commentTab: string;
  storeCommentServedAtMenu: boolean;
  today: string;
  editingComment: Comment | null;
  dialogCommentThanks: boolean;
  memberName: string;
  deleteCommentId: string;
  comments: ProductDetailCommentEntity[];
  sizes: Size[];
  colors: Color[];
  productDetailImages: GetDetailImagesEntity;
}

export default Vue.extend({
  name: "ProductDetailComment",
  mixins: [mixin],
  components: {
    S3Image,
    FilterChipButton,
    ElementDatePicker,
    ConfirmationDialog,
    Loading,
  },
  props: {
    propProduct: {
      type: Object as () => Product,
      default: null,
    },
    propImages: {
      type: Array as () => string[],
      required: true,
    },
    propComment: {
      type: Array as () => ProductDetailCommentEntity[],
      required: true,
      default: [],
    },
    user: {
      type: Object as () => User | null,
      required: false,
    },
    propStore: {
      type: Object as () => Store,
      default: null,
    },
    members: {
      type: Array as () => Member[],
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data(): DataType {
    return {
      isLoading: false,
      tableFormat: 0,
      filters: ["カテゴリ", "性質", "対象", "年齢", "性別", "店舗"],
      filterKeys: ["category", "positivity", "target", "age", "gender", "storeName"],
      filteredComments: [],
      categories: ["お客様コメント", "店舗間共有"],
      positivities: ["ポシティブ", "ネガティブ", "中立", "店舗間共有"],
      targets: ["色味", "サイズ感", "仕様", "素材・質感", "その他"],
      ages: ["10代", "20代", "30代", "40代", "50代", "60代~"],
      genders: ["女性", "男性"],
      commentFilter: {
        category: [],
        positivity: [],
        target: [],
        age: [],
        gender: [],
        storeName: [],
      },
      showUpdateComment: false,
      showingDeleteCommentDialog: false,
      commentTab: "customer-comment",
      storeCommentServedAtMenu: false,
      today: dayjs().format("YYYY-MM-DD"),
      editingComment: null,
      dialogCommentThanks: false,
      memberName: "",
      deleteCommentId: "",
      comments: [],
      sizes: [],
      colors: [],
      productDetailImages: { s3KeyMain: "", s3KeySkus: [] },
    };
  },
  computed: {
    storeNames(): string[] {
      return Array.from(new Set(this.comments.map((it) => it.storeName ?? ""))).filter((it) => it !== "");
    },
    positivityRates(): string[] {
      // const all = this.filteredComments.filter(item => item.category === 0).length;
      const all = this.filteredComments.length;
      if (all === 0) {
        return ["0", "0", "0"];
      } else {
        const rates: string[] = [];
        rates.push(
          Math.ceil((100 * this.filteredComments.filter((item) => item.positivity === 0).length) / all).toLocaleString()
        );
        rates.push(
          Math.ceil((100 * this.filteredComments.filter((item) => item.positivity === 1).length) / all).toLocaleString()
        );
        rates.push(
          Math.ceil((100 * this.filteredComments.filter((item) => item.positivity === 2).length) / all).toLocaleString()
        );
        return rates;
      }
    },
    isCustomerCommentFilled(): boolean {
      if (this.editingComment) {
        return (
          this.editingComment.content !== "" &&
          this.editingComment.content.length < 256 &&
          this.editingComment.target !== 0b00000
        );
      } else {
        return false;
      }
    },
    isStoreCommentFilled(): boolean {
      if (this.editingComment) {
        return this.editingComment.content !== "" && this.editingComment.content.length < 256;
      } else {
        return false;
      }
    },
  },
  methods: {
    filterOptions(index: number): string[] {
      switch (index) {
        case 0:
          return this.categories;
        case 1:
          return this.positivities;
        case 2:
          return this.targets;
        case 3:
          return this.ages;
        case 4:
          return this.genders;
        case 5:
          return this.storeNames;
        default:
          return [""];
      }
    },
    async search() {
      await LocalDataService.setCommentFilter(this.commentFilter);
      this.filteredComments = this.comments.filter((comment) => {
        return (
          (this.commentFilter.category.length === 0
            ? true
            : this.commentFilter.category.includes(this.categories[comment.category])) &&
          (this.commentFilter.positivity.length === 0
            ? true
            : this.commentFilter.positivity.includes(this.positivities[comment.positivity])) &&
          (this.commentFilter.target.length === 0
            ? true
            : [...this.commentFilter.target, ...this.getTargetName(comment.target)].filter(
                (item) => this.commentFilter.target.includes(item) && this.getTargetName(comment.target).includes(item)
              ).length > 0) &&
          (this.commentFilter.age.length === 0 ? true : this.commentFilter.age.includes(comment.age)) &&
          (this.commentFilter.gender.length === 0 ? true : this.commentFilter.gender.includes(comment.gender)) &&
          (this.commentFilter.storeName.length === 0
            ? true
            : this.commentFilter.storeName.includes(comment.storeName ?? ""))
        );
      });
      this.filteredComments.sort((a, b) => {
        if (a.servedAt > b.servedAt) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    async clearFilterKey(key: keyof CommentFilter) {
      this.commentFilter[key] = [];
      await this.search();
    },
    async clearFilters() {
      await LocalDataService.clearCommentFilter();
      const entries: CommentFilter | null = await LocalDataService.getCommentFilter();
      if (entries) {
        this.commentFilter = entries;
      }
      await this.search();
    },
    getTarget(comment: ProductDetailCommentEntity): string {
      if (comment.skuId && comment.skuId !== "") {
        if (this.propProduct && this.propProduct.skus) {
          const filteredSkus = this.propProduct.skus.filter((sku) => sku.id === comment.skuId);
          if (filteredSkus.length > 0) {
            const sku = filteredSkus[0];
            if (!sku.colorId && !sku.sizeId) {
              return this.propProduct.itemNumber ?? "";
            }
            const size = sku.sizeId
              ? this.sizes.find((size) => {
                  return size.id === sku.sizeId;
                })
              : null;
            const color = sku.colorId
              ? this.colors.find((color) => {
                  return color.id === sku.colorId;
                })
              : null;
            return `${color ? color.name : ""}\n${size ? size.name : ""}`;
          }
        }
      }
      return "品番";
    },
    getTargetName(target: number): string[] {
      const list: string[] = [];
      if (target & 0b10000) {
        list.push("色味");
      }
      if (target & 0b01000) {
        list.push("サイズ感");
      }
      if (target & 0b00100) {
        list.push("仕様");
      }
      if (target & 0b00010) {
        list.push("素材・質感");
      }
      if (target & 0b00001) {
        list.push("その他");
      }
      return list;
    },
    getDate(date: string): string {
      return dayjs(date).format("YYYY.MM.DD");
    },
    getPositivityName(positivity: number) {
      if (positivity == 0) {
        return "ポジティブ";
      } else if (positivity == 1) {
        return "ネガティブ";
      } else {
        return "中立";
      }
    },
    getImageFromProduct(product: Product): string {
      const images = this.propImages.filter((image) =>
        image.startsWith(`${this.user?.companyId}/${product.itemNumber}_`)
      );
      if (images.length > 0) {
        const mainImages = images.filter((image) => image.endsWith("_M"));
        if (mainImages.length > 0) {
          return mainImages[0];
        } else {
          return images[0];
        }
      }
      return "";
    },
    showDeleteCommentDialog(id: string) {
      this.showingDeleteCommentDialog = true;
      this.deleteCommentId = id;
    },
    async deleteComment() {
      await RadialApiClient.deleteComment(this.deleteCommentId);
      this.comments = (await RadialApiClient.listProductDetailComments(this.propProduct.id)) ?? [];
      this.showingDeleteCommentDialog = false;
      this.search();
    },
    getMainImage(skuId: string) {
      if (this.propProduct) {
        return this.productDetailImages.s3KeySkus.find((item) => item.skuId === skuId)?.s3KeyWithId[0]?.s3Key ?? "";
      }
      return "";
    },
    getSkuName(sku: Sku): string {
      if (!sku.sizeId || !sku.colorId) {
        return "";
      }
      const size = sku.sizeId
        ? this.sizes.find((size) => {
            return size.id === sku.sizeId;
          })
        : null;
      const color = sku.colorId
        ? this.colors.find((color) => {
            return color.id === sku.colorId;
          })
        : null;
      return `${color ? color.name : ""} ${size ? size.name : ""}`;
    },
    async closeCommentThanksDialog() {
      this.editingComment = {
        id: "",
        companyId: "",
        productId: "",
        skuId: null,
        content: "",
        positivity: 0,
        target: 0b00000,
        servedAt: new Date(),
        age: "10代",
        gender: "女性",
        figure: "やせ",
        height: "~150",
        storeId: "",
        memberId: "",
        category: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      this.dialogCommentThanks = false;
    },
    async editComment(comment: ProductDetailCommentEntity) {
      if (comment.category === 1) {
        this.commentTab = "store-comment";
      } else if (comment.category === 0) {
        this.commentTab = "customer-comment";
      }
      this.editingComment = {
        id: comment.commentId,
        companyId: this.companyId,
        productId: this.propProduct.id,
        skuId: comment.skuId ?? "product",
        memberId: comment.memberId,
        storeId: comment.storeId,
        age: comment.age,
        figure: comment.figure,
        height: comment.height,
        content: comment.content,
        gender: comment.gender,
        positivity: comment.positivity,
        servedAt: dayjs(comment.servedAt).toDate(),
        target: comment.target,
        category: comment.category,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      if (comment.memberName) {
        this.memberName = comment.memberName;
      }
      this.showUpdateComment = true;
    },
    async updateComment(index: number) {
      if (this.editingComment) {
        if (index === 1) {
          this.editingComment.category = 1;
          this.editingComment.positivity = 3;
          this.editingComment.target = 0b00000;
          this.editingComment.gender = "";
          this.editingComment.figure = "";
          this.editingComment.age = "";
          this.editingComment.height = "";
        }
        if (this.propProduct && this.editingComment.memberId) {
          const dto: UpdateCommentDto = {
            sku:
              this.editingComment.skuId === "product"
                ? null
                : {
                    connect: {
                      id: this.editingComment.skuId ?? "",
                    },
                  },
            age: this.editingComment.age,
            figure: this.editingComment.figure,
            height: this.editingComment.height,
            content: this.editingComment.content,
            gender: this.editingComment.gender,
            positivity: this.editingComment.positivity,
            servedAt: this.editingComment.servedAt,
            target: this.editingComment.target,
            category: this.editingComment.category,
            member: { connect: { id: this.editingComment.memberId } },
          };
          await RadialApiClient.updateComment(this.editingComment.id, dto);
        }
      }
      this.comments = (await RadialApiClient.listProductDetailComments(this.propProduct.id)) ?? [];
      this.showUpdateComment = false;
      this.dialogCommentThanks = true;
      this.search();
    },
    showUpdateCommentCanceled() {
      this.editingComment = {
        id: "",
        companyId: "",
        productId: "",
        skuId: null,
        content: "",
        positivity: 0,
        target: 0b00000,
        servedAt: new Date(),
        age: "10代",
        gender: "女性",
        figure: "やせ",
        height: "~150",
        storeId: "",
        memberId: "",
        category: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      this.showUpdateComment = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    const entries: CommentFilter | null = await LocalDataService.getCommentFilter();
    if (entries) {
      this.commentFilter = entries;
    }
    this.comments = this.propComment;
    this.sizes = (await RadialApiClient.listSizes()) ?? [];
    this.colors = (await RadialApiClient.listColors()) ?? [];
    await this.search();
    // get product detail images
    const productDetailImages = await RadialApiClient.getProductDetailImages(this.propProduct.id);
    if (productDetailImages) {
      this.productDetailImages = productDetailImages;
    }
    this.isLoading = false;
  },
});
