
import { User } from "@/store/model";
import Vue from "vue";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import Loading from "@/components/vuetify/Loading.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import mixin from "@/mixin/mixin";
import { AdminUser, CustomTag, CustomTagMaster, Product } from "@/api/entities";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { CreateCustomTagDto, CreateCustomTagMasterDto } from "@/api/dto";
import store from "@/store";

export interface DataType {
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  isLoading: boolean;
  selectedCustomTags: CustomTag[];
  customTagMasters: CustomTagMaster[];
  inputCustomTagName: string;
  deletingCustomTagMaster: CustomTagMaster | null;
  showDeleteCustomTagMasterDialog: boolean;
}

export default Vue.extend({
  name: "ProductCustomTag",
  mixins: [mixin],
  components: {
    S3Image,
    Loading,
    ConfirmationDialog,
    anum,
  },
  props: {
    selectedProduct: {
      type: Object as () => Product,
      default: null,
    },
    adminUsers: {
      type: Array as () => AdminUser[],
      required: true,
    },
  },
  data(): DataType {
    return {
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      isLoading: false,
      selectedCustomTags: [],
      customTagMasters: [],
      inputCustomTagName: "",
      deletingCustomTagMaster: null,
      showDeleteCustomTagMasterDialog: false,
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
    nonSelectedCustomTagMasters(): CustomTagMaster[] {
      return this.customTagMasters.filter(
        (item) => !this.selectedCustomTags.some((tag) => tag.customTagMasterId === item.id)
      );
    },
    filteredCustomTagMasters(): CustomTagMaster[] {
      return this.nonSelectedCustomTagMasters.filter((item) => ~item.name.indexOf(this.inputCustomTagName));
    },
    notSameCustomTag(): boolean {
      return !this.customTagMasters.some((item) => item.name === this.inputCustomTagName);
    },
    deleteCustomTagSentence(): string {
      if (this.deletingCustomTagMaster) {
        return `本当に削除してもよろしいですか？
            他の品番に紐づけられている${this.deletingCustomTagMaster.name}タグも合わせて削除されます。`;
      } else {
        return "";
      }
    },
  },
  methods: {
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    async selectCustomTag(tag: CustomTagMaster) {
      this.isLoading = true;
      if (this.selectedProduct) {
        const dto: CreateCustomTagDto = {
          creatorAdminUserId: this.user?.sub !== this.user?.companyId ? this.user?.sub : null,
          customTagMaster: {
            connect: {
              id: tag.id,
            },
          },
          product: {
            connect: {
              id: this.selectedProduct.id,
            },
          },
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
        };
        await RadialApiClient.createCustomTag(dto);
        const tags = (await RadialApiClient.listProductCustomTags(null, this.selectedProduct.id)) ?? [];
        this.selectedCustomTags = tags;
        this.inputCustomTagName = "";
        this.updateMdMapProductCustomTag(tags);
      }
      this.isLoading = false;
    },
    updateMdMapProductCustomTag(tags: CustomTag[]) {
      this.$emit("update-custom-tags", tags);
    },
    async removeSelectedCustomTag(tag: CustomTag) {
      this.isLoading = true;
      await RadialApiClient.deleteCustomTag(tag.id.toString());
      if (this.selectedProduct) {
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(null, this.selectedProduct.id)) ?? [];
        this.updateMdMapProductCustomTag(this.selectedCustomTags);
      }
      this.isLoading = false;
    },
    async createCustomTagMaster() {
      this.isLoading = true;
      const masterDto: CreateCustomTagMasterDto = {
        name: this.inputCustomTagName,
        company: {
          connect: {
            id: this.user?.companyId,
          },
        },
      };
      const newTag = await RadialApiClient.createCustomTagMaster(masterDto);
      if (this.selectedProduct && newTag) {
        const dto: CreateCustomTagDto = {
          creatorAdminUserId: this.user?.sub !== this.user?.companyId ? this.user?.sub : null,
          customTagMaster: {
            connect: {
              id: newTag.id,
            },
          },
          product: {
            connect: {
              id: this.selectedProduct.id,
            },
          },
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
        };
        await RadialApiClient.createCustomTag(dto);
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(null, this.selectedProduct.id)) ?? [];
        this.updateMdMapProductCustomTag(this.selectedCustomTags);
      }
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      this.inputCustomTagName = "";
      this.isLoading = false;
    },
    async deleteCustomTagMaster() {
      this.isLoading = true;
      if (this.deletingCustomTagMaster) {
        await RadialApiClient.deleteCustomTagMaster(this.deletingCustomTagMaster.id);
      }
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      if (this.selectedProduct) {
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(null, this.selectedProduct.id)) ?? [];
      }
      this.showDeleteCustomTagMasterDialog = false;
      // マップ上に表示されているselectedProduct以外のカスタムタグを削除するためにsearchを再実行
      this.$emit("search-mdmap");
      this.isLoading = false;
    },
    selectDeletingCustomTagMaster(item: CustomTagMaster) {
      this.deletingCustomTagMaster = item;
      this.showDeleteCustomTagMasterDialog = true;
    },
    getIconS3Key(tag: CustomTag): string {
      if (tag.creatorAdminUserId) {
        return this.adminUsers.find((adminUser) => adminUser.id === tag.creatorAdminUserId)?.icon ?? "";
      } else {
        return store.state.company?.icon ?? "";
      }
    },
  },
  async mounted() {
    this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(null, this.selectedProduct.id)) ?? [];
    this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
  },
});
