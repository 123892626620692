
/*
  [Checkbox]
    <必須>
    - :isLoading = Loadingのステータス(Boolean)

    <任意>
    - :noAnimation = trueの時ぐるぐるのアニメーションを表示しない
*/
import Vue from "vue";
export default Vue.extend({
  name: "loading",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
    noAnimation: {
      type: Boolean,
      default: false,
    },
  },
});
