import { User } from "./model";

export class AdminUserManager {
  private user: User;

  constructor(user: User) {
    this.user = user;
  }

  private creatableAdminUserNames(view: string | null | undefined): string[] {
    switch (view) {
      case "AdminIntegration":
        return ["cs"];
      default:
        return ["root", "admin"];
    }
  }

  canCreate(view: string | null | undefined) {
    return this.creatableAdminUserNames(view).includes(this.user.adminUserName);
  }

  private updatableAdminUserNames(view: string | null | undefined): string[] {
    switch (view) {
      case "AdminCsvItemSkuUpload":
        return ["root", "admin", "cs"];
      default:
        return ["root", "admin"];
    }
  }

  canUpdate(view: string | null | undefined) {
    return this.updatableAdminUserNames(view).includes(this.user.adminUserName);
  }
}
