import AWS from "aws-sdk";
import awsmobile from "@/aws-exports";
import Auth from "@aws-amplify/auth";

export default class S3Client {
  static notNull<T>(item: T | null | undefined): item is T {
    return item !== null && item !== undefined;
  }

  /*
      Storage.list()は現状，最大で1000個のkeyしか返してこない (https://github.com/aws-amplify/amplify-js/issues/6838)
      そのため，1001個以上のkeyを取得するためには，aws-sdkを用いて自分で通信処理を実装する必要がある．
    */

  // 1000個のキーを取得する関数（再帰的に呼ぶことで最終的に全keyを取得する）
  static async getThousandKeys(params: AWS.S3.Types.ListObjectsV2Request): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      new AWS.S3().listObjectsV2(params, async (error, data) => {
        if (error) {
          reject(error);
        } else {
          // data.Contents内の全Keyのうちnullでないものをkeysに入れる
          const keys: string[] =
            data.Contents?.map((content) => content.Key?.replace("public/", "")).filter(this.notNull) ?? [];
          if (data.IsTruncated) {
            // 続きがある場合
            params.ContinuationToken = data.NextContinuationToken;
            resolve(keys.concat(await this.getThousandKeys(params))); // ここで自分自身を呼ぶ
          } else {
            resolve(keys);
          }
        }
      });
    });
  }

  static async list(path: string): Promise<string[]> {
    AWS.config.update({
      region: awsmobile.aws_project_region,
      credentials: await Auth.currentUserCredentials(),
    });
    return this.getThousandKeys({
      Bucket: awsmobile.aws_user_files_s3_bucket,
      Prefix: `public/${path}`,
    });
  }
}
