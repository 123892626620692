
import { ProductStatusDetail, User } from "@/store/model";
import Vue from "vue";
import LocalDataService from "@/store/LocalDataService";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import Loading from "@/components/vuetify/Loading.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import mixin from "@/mixin/mixin";
import {
  AdminUser,
  Attribute,
  Brand,
  Category,
  Color,
  CustomTag,
  Factory,
  OrderProduct,
  OrderSku,
  Product,
  ProductStatus,
  Season,
  Size,
  Sku,
} from "@/api/entities";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import store from "@/store";
import MdMapProductImage from "@/views/season/MdMapProductImage.vue";
import ProductCustomTag from "@/views/season/ProductCustomTag.vue";
import MdMapSkus from "@/views/season/MdMapSkus.vue";
import MdMapOrders from "@/views/season/MdMapOrders.vue";
import MdMapBasic from "@/views/season/MdMapBasic.vue";

export interface DataType {
  selectedProductStatus: ProductStatusDetail | null;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  isLoading: boolean;
  isDisabled: boolean;
  isEditing: boolean;
  mapProductTab: MdMapProductTab;
  newProductName: string | null;
}

export interface MdMapProduct {
  // productの情報をまとめた型
  product: Product;
  orderProducts: OrderProductInfo[];
  mdMapProduct: MdMapDeliveryProductEntity | MdMapAdditionalProductEntity;
  skus: Sku[];
}

export interface OrderProductInfo {
  orderProduct: OrderProduct;
  orderSkus: OrderSku[];
}

export class MdMapDeliveryProductEntity {
  brand: Brand;
  category: Category;
  product: Product;
  customTags: CustomTag[];
  brandId: number;
  categoryId: number;
  brandCategoryId: string;
  productId: string;
  salesStartedAt: Date; // 販売開始日
  price: number; // 定価
  deliveryCount: number; // 初期投入数
  weightedAverageCost: number; // 該当品番の原価の加重平均 = AVG(orderQuantity * cost)
}

export class MdMapAdditionalProductEntity {
  brand: Brand;
  category: Category;
  product: Product;
  customTags: CustomTag[];
  brandId: number;
  categoryId: number;
  brandCategoryId: string;
  productId: string;
  salesStartedAt: Date; // 販売開始日
  price: number; // 定価
  additionalCount: number; // 追加発注数
  weightedAverageCost: number; // 該当品番の原価の加重平均 = AVG(orderQuantity * cost)
}

export interface MdMapSkuColor {
  // 商品詳細情報でのskuをカラーごとに表示するための型
  colorId: string;
  mdMapSkus: Sku[];
}

export type MdMapProductTab = "PRODUCT" | "SKUS" | "ORDERS";

export default Vue.extend({
  name: "MdMapProductDetail",
  mixins: [mixin],
  components: {
    S3Image,
    Loading,
    ConfirmationDialog,
    anum,
    MdMapProductImage,
    ProductCustomTag,
    MdMapSkus,
    MdMapOrders,
    MdMapBasic,
  },
  props: {
    selectedSeason: {
      type: Object as () => Season,
      default: null,
    },
    selectedProduct: {
      type: Object as () => MdMapProduct,
      default: null,
    },
    productStatusDetails: {
      type: Array as () => ProductStatusDetail[],
      required: true,
    },
    adminUsers: {
      type: Array as () => AdminUser[],
      required: true,
    },
    colors: {
      type: Array as () => Color[],
      required: true,
    },
    sizes: {
      type: Array as () => Size[],
      required: true,
    },
    attribute1s: {
      type: Array as () => Attribute[],
      required: true,
    },
    attribute2s: {
      type: Array as () => Attribute[],
      required: true,
    },
    attribute3s: {
      type: Array as () => Attribute[],
      required: true,
    },
    factories: {
      type: Array as () => Factory[],
      required: true,
    },
  },
  data(): DataType {
    return {
      selectedProductStatus: null,
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      isLoading: false,
      isDisabled: false,
      isEditing: false,
      mapProductTab: "PRODUCT",
      newProductName: this.selectedProduct.product.name,
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
  },
  methods: {
    updateMdMapProductCustomTag(tags: CustomTag[]) {
      this.$emit("update-custom-tags", tags);
    },
    searchMdMap() {
      this.$emit("search-mdmap");
    },
    onTabInfoChanged(isTabInfoEditing) {
      this.isEditing = isTabInfoEditing;
    },
    onTabInfoDisabled(isTabInfoDisabled) {
      this.isDisabled = isTabInfoDisabled;
    },
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    closeSelectedProductScreen() {
      this.$emit("close-selected-product", this.selectedProduct, this.productImages);
    },
    showDetailInNewTab() {
      if (this.selectedProduct) {
        const productId = this.selectedProduct.product.id;
        if (productId) {
          const routeData = this.$router.resolve({
            name: "AdminProductDetail",
            params: { productId },
          });
          window.open(routeData.href, "_blank");
        }
      }
    },
    async deleteProduct() {
      if (this.selectedProduct) {
        this.showAlert(
          `本当に削除してよろしいですか？
          この品番に紐づく全てのデータも合わせて削除されます。

          ・sku情報
          ・コメント
          ・タグ
          ・発注情報`,
          "error",
          0
        );
      }
    },
    async executeDelete() {
      this.isLoading = true;
      // MDマップカード内からPeoductの物理削除
      if (this.selectedProduct) {
        const deletedProduct = await RadialApiClient.deleteMdMapProduct(this.selectedProduct.product.id);
        if (deletedProduct) {
          // statsの更新
          const product = this.selectedProduct.product;
          this.$emit("update-stats", product.id, product.seasonId, product.brandId, product.categoryId);
        } else {
          this.showAlert(
            "エラーが発生しております。\n入力内容をご確認のうえ、時間を空けてからもう一度お試しください。",
            "error",
            -1
          );
        }
      }
      this.closeSelectedProductScreen();
      this.showingAlert = false;
      this.isLoading = false;
    },
    cancelEditing() {
      this.newProductName = this.selectedProduct.product.name;
      this.selectedProductStatus =
        this.productStatusDetails.find((item) => {
          return item.id === this.selectedProduct.product.status;
        }) ?? null;
      switch (this.mapProductTab) {
        case "PRODUCT":
          (this.$refs.product as any).updateEditingProduct();
          break;
        case "SKUS":
          (this.$refs.skus as any).cancelSkusEdit();
          break;
        case "ORDERS":
          (this.$refs.orders as any).cancelOrdersEdit();
        default:
      }
      this.isEditing = false;
    },
    async saveInfo() {
      switch (this.mapProductTab) {
        case "PRODUCT":
          await (this.$refs.product as any).saveProduct();
          // statsの更新
          this.updateProduct(this.selectedProduct.product.id, this.newProductName, this.selectedProductStatus);
          break;
        case "SKUS":
          await (this.$refs.skus as any).saveSkus();
          // statsの更新
          this.updateProduct(this.selectedProduct.product.id, this.newProductName, this.selectedProductStatus);
          this.$emit(
            "update-stats",
            this.selectedProduct.product.id,
            this.selectedProduct.product.seasonId,
            this.selectedProduct.product.brandId,
            this.selectedProduct.product.categoryId
          );
          break;
        case "ORDERS":
          await (this.$refs.orders as any).saveOrderProductSkus();
          // statsの更新
          this.updateProduct(this.selectedProduct.product.id, this.newProductName, this.selectedProductStatus);
          break;
      }
      this.isEditing = false;
    },
    async updateProduct(productId: string, newName: string | null, newStatus: ProductStatusDetail | null) {
      await RadialApiClient.updateProduct(productId, {
        name: newName ?? this.selectedProduct.product.name,
        status:
          newStatus?.id ??
          this.productStatusDetails.find((item) => {
            return item.id === this.selectedProduct.product.status;
          })?.id ??
          null,
      });
    },
    async setProductStatus(newStatus: ProductStatusDetail) {
      this.isEditing = true;
      this.selectedProductStatus = newStatus;
    },
  },
  async mounted() {
    this.selectedProductStatus =
      this.productStatusDetails.find((item) => {
        return item.id === this.selectedProduct.product.status;
      }) ?? null;
  },
});
