
import Vue from "vue";
import draggable from "vuedraggable";
import RadialApiClient from "@/api/RadialApiClient";
import { Brand, BrandWithCategoryInfoEntity, Category } from "@/api/entities";
import { UpdateBrandDto, CreateBrandDto, CreateBrandCategoryDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: string | null;
  selectedIndex: number;
  brands: Brand[];
  selectedBrand: Brand | null;
  selectedBrandCategory: (Category & { index: number; brandCategoryId: string }) | null;
  dialogAddBrand: boolean;
  dialogEditBrand: boolean;
  dialogDeleteBrand: boolean;
  dialogDeleteBrandCategory: boolean;
  newBrandInfo: {
    code: string | null;
    name: string;
  };
  brandCategories: BrandWithCategoryInfoEntity[];
  editingBrandId: number | null;
  newBrandCategory: Brand | null;
  categories: Category[];
  page: number;
  pageSize: number;
  list: BrandWithCategoryInfoEntity[];
  listCount: number;
  buttonDisabled: boolean;
}

export default Vue.extend({
  name: "Brand",
  mixins: [mixin],
  components: {
    draggable,
    ConfirmationDialog,
    Pagination,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      selectedIndex: 0,
      brands: [],
      selectedBrand: null,
      selectedBrandCategory: null,
      dialogAddBrand: false,
      dialogEditBrand: false,
      dialogDeleteBrand: false,
      dialogDeleteBrandCategory: false,
      newBrandInfo: {
        code: null,
        name: "",
      },
      brandCategories: [],
      editingBrandId: null,
      newBrandCategory: null,
      categories: [],
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
      buttonDisabled: true,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {
    editBrandDisabled(): boolean {
      if (this.selectedBrand) {
        return (
          !this.newBrandInfo.name || // ブランド名が空の場合
          (this.newBrandInfo.name === this.selectedBrand.name && this.newBrandInfo.code === this.selectedBrand.code) || // 変更が何もない場合
          (this.newBrandInfo.name === this.selectedBrand.name && !this.newBrandInfo.code && !this.selectedBrand.code) || // 変更が何もない場合で、もともとcodeがなかった場合
          (this.newBrandInfo.name !== this.selectedBrand.name &&
            this.brands.some((brand) => brand.name === this.newBrandInfo.name)) || // ブランド名が他のブランドと一致していた場合
          (this.newBrandInfo.code !== this.selectedBrand.code &&
            this.brands.some((brand) => brand.code === this.newBrandInfo.code)) // ブランドコードが他のブランドと一致していた場合
        );
      }
      return false;
    },
    addBrandDisabled(): boolean {
      if (!this.newBrandInfo.name || !this.newBrandInfo.code) {
        // ブランド名が空もしくはブランドコードが空の場合
        return true;
      } else if (
        this.brands.some((brand) => {
          return brand.name === this.newBrandInfo.name || brand.code === this.newBrandInfo.code;
        })
      ) {
        // ブランド名またはブランドコードが他のブランドと一致していた場合
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getSelectedBrandCategories(brandCategories: (Category & { index: number; brandCategoryId: string })[]): Category[] {
      return this.categories.filter(
        (category) => !brandCategories.find((brandCategory) => brandCategory.name === category.name)
      );
    },
    async updateBrandCategoryIndex(event: any, brandCategory: BrandWithCategoryInfoEntity) {
      this.$emit("isLoading", true);
      const newIndex = event.newIndex;
      const oldIndex = event.oldIndex;
      if (oldIndex === newIndex) {
        // 処理なし
      } else if (oldIndex < newIndex) {
        // 上から下に動かした場合の処理
        const minIndex = brandCategory.categories[newIndex]?.index;
        const maxIndex = brandCategory.categories[newIndex - 1]?.index;
        // 更新にあたって必要十分なブランドカテゴリの取得
        const updatedBrandCategories = brandCategory.categories.filter((category) => {
          return category.index >= minIndex && category.index <= maxIndex;
        });
        updatedBrandCategories.forEach((updatedCategory, index) => {
          const id = updatedCategory.brandCategoryId;
          if (updatedBrandCategories.length === index + 1) {
            // ドラッグしたブランドカテゴリの更新
            RadialApiClient.updateBrandCategory(id, {
              index: minIndex + index,
            });
          } else {
            // 残りのブランドカテゴリのindex更新（連番にリセット）
            RadialApiClient.updateBrandCategory(id, {
              index: minIndex + index,
            });
          }
        });
      } else if (oldIndex > newIndex) {
        // 下から上に動かした場合の処理
        const minIndex = brandCategory.categories[newIndex + 1]?.index;
        const maxIndex = brandCategory.categories[newIndex]?.index;
        // 更新にあたって必要十分なブランドカテゴリの取得
        const updatedBrandCategories = brandCategory.categories.filter((category) => {
          return category.index >= minIndex && category.index <= maxIndex;
        });
        updatedBrandCategories.forEach((updatedCategory, index) => {
          const id = updatedCategory.brandCategoryId;
          if (index === 0) {
            // ドラッグしたブランドカテゴリの更新
            RadialApiClient.updateBrandCategory(id, {
              index: minIndex,
            });
          } else {
            // 残りのブランドカテゴリのindex更新（連番にリセット）
            RadialApiClient.updateBrandCategory(id, {
              index: minIndex + index,
            });
          }
        });
      }
      setTimeout(() => {
        this.getBrandCategories();
      }, 100);
      this.$emit("isLoading", false);
    },
    getBrandCategoryLength(brandCategory: BrandWithCategoryInfoEntity): number {
      return brandCategory.categories.length;
    },
    async getBrandCategories() {
      const list = (await RadialApiClient.listBrandWithCategoryInfo()) ?? [];
      for (const brand of this.brands) {
        if (
          !list.some((brandCategory) => {
            return brandCategory.brand.id === brand.id;
          })
        ) {
          list.push({ brand: brand, categories: [] });
        }
      }
      this.list = list;
      this.listCount = this.list.length;
      this.sortList();
      this.updatePage(this.page);
    },
    sortList() {
      this.list.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.brand.code && b.brand.code) {
          return a.brand.code > b.brand.code ? 1 : -1;
        } else if (a.brand.code) {
          return -1;
        } else if (b.brand.code) {
          return 1;
        } else {
          return a.brand.name > b.brand.name ? 1 : -1;
        }
      });
    },
    selectBrand(brand: Brand, index: number) {
      this.selectedBrand = { ...brand };
      if (index === 0) {
        this.newBrandInfo = {
          code: this.selectedBrand.code,
          name: this.selectedBrand.name,
        };
        this.dialogEditBrand = true;
      } else {
        this.dialogDeleteBrand = true;
      }
    },
    deselectBrand() {
      this.selectedBrand = null;
      this.dialogEditBrand = false;
      this.newBrandInfo = {
        code: null,
        name: "",
      };
      this.dialogDeleteBrand = false;
    },
    cancelAddList(index: number) {
      switch (index) {
        case 0:
          this.dialogAddBrand = false;
          this.newBrandInfo = {
            code: null,
            name: "",
          };
          return;
        case 1:
          this.editingBrandId = null;
          this.newBrandCategory = null;
          this.buttonDisabled = true;
          return;
        default:
          return;
      }
    },
    addBrand() {
      this.dialogAddBrand = true;
      this.newBrandInfo = {
        code: null,
        name: "",
      };
    },
    async createBrand() {
      this.$emit("isLoading", true);
      if (this.brands.some((brand) => brand.code === this.newBrandInfo.code)) {
        this.showAlert(
          `ブランドコードが重複しております。
          入力内容をご確認のうえ、もう一度お試しください。`,
          "error"
        );
      } else {
        if (this.user) {
          const dto: CreateBrandDto = {
            company: { connect: { id: this.user.companyId } },
            code: this.newBrandInfo.code,
            name: this.newBrandInfo.name,
          };
          const brand = await RadialApiClient.createBrand(dto);
          if (brand) {
            this.brands.push(brand);
            await this.getBrandCategories();
            this.showAlert(
              `新しくブランドが作成されました！
                引き続き、作成したブランドにカテゴリを紐付けしていきましょう。`,
              "success"
            );
            this.newBrandInfo = {
              code: null,
              name: "",
            };
            this.dialogAddBrand = false;
          } else {
            this.showAlert(
              `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error"
            );
          }
        }
      }
      this.$emit("isLoading", false);
    },
    async addBrandCategory(brandCategory: BrandWithCategoryInfoEntity) {
      this.$emit("isLoading", true);
      if (this.user && this.newBrandCategory) {
        const dto: CreateBrandCategoryDto = {
          company: { connect: { id: this.user.companyId } },
          brand: { connect: { id: brandCategory.brand.id } },
          category: { connect: { id: this.newBrandCategory.id } },
          index: brandCategory.categories.length,
        };
        await RadialApiClient.createBrandCategory(dto)
          .then(async () => {
            await this.getBrandCategories();
          })
          .catch(() => {
            this.showAlert(
              `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error"
            );
          });
      }
      this.newBrandCategory = null;
      this.$emit("isLoading", false);
    },
    async editBrand() {
      this.$emit("isLoading", true);
      if (this.selectedBrand) {
        if (
          this.selectedBrand.code !== this.newBrandInfo.code &&
          this.brands.some((brand) => brand.code === this.newBrandInfo.code)
        ) {
          this.showAlert(
            `ブランドコードが重複しております。
            入力内容をご確認のうえ、もう一度お試しください。`,
            "error"
          );
        } else {
          const dto: UpdateBrandDto = {
            code: this.newBrandInfo.code,
            name: this.newBrandInfo.name,
          };
          await RadialApiClient.updateBrand(this.selectedBrand.id, dto)
            .then(async () => {
              this.brands = (await RadialApiClient.listBrands()) ?? [];
              await this.getBrandCategories();
              this.selectedBrand = null;
              this.dialogEditBrand = false;
              this.newBrandInfo = {
                code: null,
                name: "",
              };
            })
            .catch(() => {
              this.showAlert(
                "エラーが発生しております。\n入力内容をご確認のうえ、時間を空けてからもう一度お試しください。",
                "error"
              );
            });
        }
      }
      this.$emit("isLoading", false);
    },
    async deleteBrand() {
      this.dialogDeleteBrand = false;
      this.$emit("isLoading", true);
      if (this.selectedBrand) {
        await RadialApiClient.deleteBrand(this.selectedBrand.id)
          .then(async (result) => {
            this.brands = (await RadialApiClient.listBrands()) ?? [];
            await this.getBrandCategories();
            this.showAlert("無事に削除が完了しました！", "success");
          })
          .catch(() => {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          });
      }
      this.selectedBrand = null;
      this.$emit("isLoading", false);
    },
    selectBrandCategory(category: Category & { index: number; brandCategoryId: string }, brand: Brand) {
      this.selectedBrandCategory = category;
      this.selectedBrand = brand;
      this.dialogDeleteBrandCategory = true;
    },
    async deleteBrandCategory() {
      this.dialogDeleteBrandCategory = false;
      if (this.selectedBrandCategory) {
        await RadialApiClient.deleteBrandCategory(this.selectedBrandCategory.brandCategoryId);
        this.selectedBrandCategory = null;
        this.selectedBrand = null;
      }
      this.getBrandCategories();
    },
    deselectBrandCategory() {
      this.selectedBrandCategory = null;
      this.selectedBrand = null;
      this.dialogDeleteBrandCategory = false;
    },
    getAlertMessage(type: string): string {
      switch (type) {
        case "brand":
          if (this.selectedBrand) {
            return `本当に${this.selectedBrand.name}を削除してもよろしいですか？\nこのブランドに紐づく以下の全てのデータも合わせて削除されます。\n\n・シーズンMD計画情報\n・品番情報\n・sku情報\n・発注情報\n・コメント\n・アラート\n・カスタムタグ`;
          } else {
            return "";
          }
        case "brandCategory":
          if (this.selectedBrandCategory && this.selectedBrand) {
            return `本当に${this.selectedBrandCategory.name}を${this.selectedBrand.name}から削除してもよろしいですか？\nこのブランドカテゴリに紐づくデータが以下の画面上に表示されなくなります。\n\n・MDマップ\n・商品リスト`;
          } else {
            return "";
          }
        default:
          return "";
      }
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.brandCategories = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    this.brands = (await RadialApiClient.listBrands()) ?? [];
    this.categories = (await RadialApiClient.listCategories()) ?? [];
    const list = (await RadialApiClient.listBrandWithCategoryInfo()) ?? [];
    for (const brand of this.brands) {
      if (
        !list.some((brandCategory) => {
          return brandCategory.brand.id === brand.id;
        })
      ) {
        list.push({ brand: brand, categories: [] });
      }
    }
    this.list = list;
    this.sortList();
    this.listCount = this.list.length;
    if (this.listCount < this.pageSize) {
      this.brandCategories = this.list;
    } else {
      this.brandCategories = this.list.slice(0, this.pageSize);
    }
    this.$emit("isLoading", false);
  },
});
