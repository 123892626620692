
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { Size } from "@/api/entities";
import { CreateSizeDto, UpdateSizeDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: string | null;
  selectedIndex: number;
  sizes: Size[];
  newSizeName: string;
  newSizeCode: string;
  dialogAddSize: boolean;
  dialogEditSize: boolean;
  dialogDeleteSize: boolean;
  selectedSize: Size | null;
  page: number;
  pageSize: number;
  list: Size[];
  listCount: number;
}

export default Vue.extend({
  name: "Size",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      selectedIndex: 0,
      sizes: [],
      newSizeName: "",
      newSizeCode: "",
      dialogAddSize: false,
      dialogEditSize: false,
      dialogDeleteSize: false,
      selectedSize: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {
    editSizeDisabled(): boolean {
      if (this.selectedSize) {
        if (this.selectedSize.name === "") {
          return true;
        } else if (this.selectedSize.code === "") {
          return true;
        }
        if (this.selectedSize.name === this.sizes[this.selectedIndex].name) {
          for (const size of this.sizes) {
            if (this.selectedSize.code === size.code) {
              return true;
            }
          }
          return false;
        } else if (this.selectedSize.code === this.sizes[this.selectedIndex].code) {
          for (const size of this.sizes) {
            if (this.selectedSize.name === size.name) {
              return true;
            }
          }
          return false;
        } else {
          for (const size of this.sizes) {
            if (this.selectedSize.code === size.code || this.selectedSize.name === size.name) {
              return true;
            }
          }
        }
        return false;
      }
      return true;
    },
    addSizeDisabled(): boolean {
      if (this.newSizeCode === "" || this.newSizeName === "") {
        return true;
      }
      for (const size of this.sizes) {
        if (this.newSizeCode === size.code || this.newSizeName === size.name) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    sortList() {
      this.list.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return (a.name ?? 0) > (b.name ?? 0) ? 1 : -1;
        }
      });
    },
    selectSize(size: Size, index: number, selectedIndex: number) {
      this.selectedSize = { ...size };
      if (index === 0) {
        this.selectedIndex = selectedIndex;
        this.dialogEditSize = true;
      } else {
        this.dialogDeleteSize = true;
      }
    },
    deselectSize() {
      this.selectedSize = null;
      this.dialogEditSize = false;
      this.dialogDeleteSize = false;
    },
    cancelAddSize() {
      this.dialogAddSize = false;
      this.newSizeName = "";
      this.newSizeCode = "";
    },
    async addSize() {
      this.$emit("isLoading", true);
      if (this.user) {
        const dto: CreateSizeDto = {
          company: { connect: { id: this.user.companyId } },
          name: this.newSizeName,
          code: this.newSizeCode,
        };
        const size = await RadialApiClient.createSize(dto);
        if (size) {
          this.list.push(size);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("新しくサイズが作成されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
            入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.newSizeName = "";
      this.newSizeCode = "";
      this.$emit("isLoading", false);
      this.dialogAddSize = false;
    },
    async editSize() {
      this.$emit("isLoading", true);
      if (this.selectedSize) {
        const dto: UpdateSizeDto = {
          name: this.selectedSize.name,
          code: this.selectedSize.code,
        };
        await RadialApiClient.updateSize(this.selectedSize.id, dto);
        const list = await RadialApiClient.listSizes();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
        this.selectedSize = null;
        this.$emit("isLoading", false);
        this.dialogEditSize = false;
      }
    },
    async deleteSize() {
      this.dialogDeleteSize = false;
      this.$emit("isLoading", true);
      if (this.selectedSize) {
        await RadialApiClient.deleteSize(this.selectedSize.id);
        const list = await RadialApiClient.listSizes();
        if (list) {
          this.list = list;
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.selectedSize = null;
      this.$emit("isLoading", false);
      this.showAlert("無事に削除が完了しました！", "success");
    },
    getAlertMessage(): string {
      if (this.selectedSize) {
        return `本当に${this.selectedSize.name}を削除してもよろしいですか？
                このサイズに紐づく以下の全てのデータも合わせて削除されます。

                ・sku情報
                ・発注情報
                ・コメント
                ・アラート`;
      } else {
        return "";
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.sizes = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    const list = await RadialApiClient.listSizes();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.sizes = this.list;
      } else {
        this.sizes = this.list.slice(0, this.pageSize);
      }
    }
    this.$emit("isLoading", false);
  },
});
