
import { OrderProductStatusDetail, User } from "@/store/model";
import dayjs from "dayjs";
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import Loading from "@/components/vuetify/Loading.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import mixin from "@/mixin/mixin";
import {
  AdminUser,
  Color,
  OrderProduct,
  OrderProductStockedStatus,
  OrderSku,
  Product,
  Season,
  Size,
  Sku,
  SkuWithCostEntity,
} from "@/api/entities";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import { CreateOrderProductDto, UpdateOrderProductDto, UpsertOrderSkuDto } from "@/api/dto";
import store from "@/store";
import * as lodash from "lodash";

export interface DataType {
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  deleteOption: number;
  isLoading: boolean;
  editingOrderProducts: OrderProductInfo[];
  orderProductStatusDetails: OrderProductStatusDetail[];
  addOrderProductSkusModal: boolean;
  newOrderProductSkus: CreatingOrderProductSkusFromScratch | null;
  updateSkusCostModal: boolean;
  skusWithCost: SkuWithCostEntity[];
  deletingOrderInfo: number[];
  editingOrderInfo: OrderProductInfo[];
}

export interface OrderProductInfo {
  orderProduct: OrderProduct;
  orderSkus: OrderSku[];
}

export interface CreatingOrderProductSkusFromScratch {
  // OrderProductとOrderSkuの一括作成のための型
  product: Product;
  season: Season;
  orderedDate: Date;
  stockedDate: Date;
  stockedStatus: OrderProductStockedStatus;
  orderSkus: MdMapOrderSku[];
}

export interface MdMapOrderSku {
  sku: Sku;
  orderQuantityString?: string | null; // 数字以外の文字を抜いて、3桁カンマ区切り処理のために文字列で情報を持っている
  orderCostString?: string | null; // 数字以外の文字を抜いて、3桁カンマ区切り処理のために文字列で情報を持っている
}

export default Vue.extend({
  name: "MdMapOrders",
  mixins: [mixin],
  components: {
    Loading,
    ConfirmationDialog,
    ElementDatePicker,
    anum,
  },
  props: {
    selectedProduct: {
      type: Object as () => Product,
      default: null,
    },
    selectedSeason: {
      type: Object as () => Season,
      default: null,
    },
    orderProducts: {
      type: Array as () => OrderProductInfo[],
      required: true,
    },
    colors: {
      type: Array as () => Color[],
      required: true,
    },
    sizes: {
      type: Array as () => Size[],
      required: true,
    },
    skus: {
      type: Array as () => Sku[],
      required: true,
    },
  },
  data(): DataType {
    return {
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      deleteOption: 0,
      isLoading: false,
      editingOrderProducts: [],
      orderProductStatusDetails: Constant.OrderProductStatus,
      addOrderProductSkusModal: false,
      newOrderProductSkus: null,
      updateSkusCostModal: false,
      skusWithCost: [],
      editingOrderInfo: [],
      deletingOrderInfo: [],
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
    isDisabledCreateOrderProductSkusButton(): boolean {
      if (this.newOrderProductSkus) {
        return this.newOrderProductSkus.orderSkus.some((item) => {
          return item.orderQuantityString === "" || item.orderCostString === "";
        });
      }
      return true;
    },
    minOrderedDateRange(): string {
      // 発注日の選択可能な最も古い月は、シーズン初月の6ヶ月前とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").subtract(6, "month").format("YYYY-MM-DD");
    },
    maxOrderedDateRange(): string {
      // 発注日の選択可能な最も新しい月は、シーズン最終月とする
      return dayjs(this.selectedSeason?.endYearMonth).endOf("month").format("YYYY-MM-DD");
    },
    minStockedDateRange(): string {
      // 納品日の選択可能な最も古い月は、シーズン初月とする
      return dayjs(this.selectedSeason?.startYearMonth).startOf("month").format("YYYY-MM-DD");
    },
    maxStockedDateRange(): string {
      // 納品日の選択可能な最も新しい月は、シーズン最終月とする
      return dayjs(this.selectedSeason?.endYearMonth).endOf("month").format("YYYY-MM-DD");
    },
    isOrdersEditing(): boolean {
      const preSkus = JSON.stringify(this.orderProducts);
      const postSkus = JSON.stringify(this.editingOrderProducts);
      return preSkus !== postSkus;
    },
  },
  watch: {
    isOrdersEditing(isTabInfoEditing: boolean) {
      this.$emit("is-tab-info-changed", isTabInfoEditing);
    },
    orderProducts() {
      this.updateEditingOrders();
    },
  },
  methods: {
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    updateEditingOrders() {
      this.editingOrderProducts = lodash.cloneDeep(this.orderProducts);
    },
    getErrorMessage(errorMessages: { status: number; orderProduct: OrderProduct | null }[]): string {
      const overLappingErrorMessages = errorMessages.filter((message) => message.status === 400);
      const otherErrorMessages = errorMessages.filter((message) => message.status !== 400);
      let message = "";
      if (overLappingErrorMessages.length > 0) {
        message = message.concat(
          `以下を納品月とした同じ品番のアイテムが既に登録されているようです。品番及び納品日を再度ご確認ください。\n`
        );
        for (const errorMessage of overLappingErrorMessages) {
          message = message.concat(`\n・${dayjs(errorMessage.orderProduct?.stockedDate).format("YYYY年M月")}}`);
        }
        message = message.concat("\n\n");
      }
      message = message.concat("入力内容をご確認のうえ、もう一度お試しください。");
      return message;
    },
    getColorDetail(id: string): Color | null {
      return this.colors.find((item) => item.id === id) ?? null;
    },
    getSkuColorDetail(skuId: string): Color | null {
      const colorId = String(this.skus.find((item) => item.id === skuId)?.colorId);
      return this.getColorDetail(colorId);
    },
    getSizeDetail(id: string): Size | null {
      return this.sizes.find((item) => item.id === id) ?? null;
    },
    getSkuSizeDetail(skuId: string): Size | null {
      const sizeId = String(this.skus.find((item) => item.id === skuId)?.sizeId);
      return this.getSizeDetail(sizeId);
    },
    getOrderProductData(orderProduct: OrderProductInfo, index: number): number {
      // asyncawaitを利用しなければいけない場合に、返り値としてPromise<>と記述するのは仕方ないのか
      switch (index) {
        case 0:
          // orderProductあたりの1つでも発注があるsku数の算出
          return orderProduct.orderSkus.filter((orderSku) => {
            return orderSku.orderQuantity > 0;
          }).length;
        case 1: {
          // 該当のproductに対するsku数
          return this.skus.length;
        }
        case 2:
          // orderProductあたりの合計発注量の算出
          return orderProduct.orderSkus
            ? orderProduct.orderSkus.reduce((sum, item) => sum + Number(item.orderQuantity), 0)
            : 0;
        default:
          return 0;
      }
    },
    getNewOrderSkuCost(orderCost: string | null | undefined): number {
      if (orderCost && this.selectedProduct?.price) {
        const newCost = Number(orderCost.replace(/[^0123456789]/g, ""));
        return Math.ceil((Number(newCost) * 100) / this.selectedProduct.price);
      } else {
        return 0;
      }
    },
    selectStockedDate(date: string, index: number) {
      switch (index) {
        case 2:
          if (this.newOrderProductSkus) {
            this.newOrderProductSkus.orderedDate = dayjs(date).toDate();
          }
          return;
        case 3:
          if (this.newOrderProductSkus) {
            this.newOrderProductSkus.stockedDate = dayjs(date).toDate();
          }
          return;
        default:
          return;
      }
    },
    inputNumber(orderSku: OrderSku, num: number, index) {
      const validNum = Number(
        num.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
      );
      if (index === 0) {
        orderSku.orderCost = validNum;
      } else if (index === 1) {
        orderSku.orderQuantity = validNum;
      }
    },
    inputArrayNumber(numString: string | null | undefined, arrayNumber: number, index: number) {
      const validNumString = Number(
        numString?.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
      ).toLocaleString();
      switch (index) {
        case 0:
          if (this.newOrderProductSkus) {
            this.newOrderProductSkus.orderSkus[arrayNumber].orderCostString = validNumString;
          }
          return;
        case 1:
          if (this.newOrderProductSkus) {
            this.newOrderProductSkus.orderSkus[arrayNumber].orderQuantityString = validNumString;
          }
          return;
      }
    },
    stockEditingOrderProductInfo(orderProduct: OrderProduct) {
      if (!this.editingOrderInfo.some((info) => info.orderProduct.id === orderProduct.id)) {
        this.editingOrderInfo.push({
          orderProduct: orderProduct,
          orderSkus: [],
        });
      }
    },
    stockEditingOrderSkuInfo(orderProduct: OrderProduct, orderSku: OrderSku) {
      if (!this.editingOrderInfo.some((info) => info.orderProduct.id === orderProduct.id)) {
        // orderProductIdに含まれてなければ追加
        this.editingOrderInfo.push({
          orderProduct: orderProduct,
          orderSkus: [orderSku],
        });
      } else if (
        !this.editingOrderInfo.find((info) => info.orderProduct.id === orderProduct.id)?.orderSkus.includes(orderSku)
      ) {
        // orderSkuIdsに含まれてなければ追加
        this.editingOrderInfo.find((info) => info.orderProduct.id === orderProduct.id)?.orderSkus.push(orderSku);
      }
    },
    stockDeletingOrderProductInfo(orderProduct: OrderProduct) {
      // 発注タブ内にて削除予定のorderを非表示に
      this.editingOrderProducts = this.editingOrderProducts.filter((item) => {
        return item.orderProduct.id !== orderProduct.id;
      });
      // 削除予定のorderをストック
      if (!this.deletingOrderInfo.includes(orderProduct.id)) {
        this.deletingOrderInfo.push(orderProduct.id);
      }
    },
    cancelOrdersEdit() {
      this.updateEditingOrders();
      this.editingOrderInfo = [];
      this.deletingOrderInfo = [];
    },
    addOrder() {
      if (this.selectedProduct && this.selectedSeason) {
        this.newOrderProductSkus = {
          product: this.selectedProduct,
          season: this.selectedSeason,
          // 発注追加時の発注日の初期値はタブの選択月初日とする
          orderedDate: dayjs(this.selectedYearMonth).toDate(),
          // 発注追加時の納品日の初期値はタブの選択月末日とする
          stockedDate: dayjs(this.selectedYearMonth).endOf("month").toDate(),
          stockedStatus: "PREORDER",
          orderSkus: this.skus.map((sku) => {
            return {
              sku: sku,
              orderQuantityString: "0",
              orderCostString: sku.cost
                ? sku.cost.toLocaleString()
                : this.selectedProduct?.price && this.selectedSeason?.costRateTarget
                ? Math.floor((this.selectedProduct.price * this.selectedSeason.costRateTarget) / 100).toLocaleString()
                : "0",
            };
          }),
        };
        this.addOrderProductSkusModal = true;
      }
    },
    async createOrderProductSkusFromScratch() {
      this.isLoading = true;
      if (this.newOrderProductSkus) {
        // 新しいOrderProductの作成
        const dto: CreateOrderProductDto = {
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
          season: {
            connect: {
              id: this.newOrderProductSkus.season.id,
            },
          },
          brand: {
            connect: {
              // TODO: サーバー側でnullならないよう変更してもらう
              id: this.newOrderProductSkus.product.brandId ?? 0,
            },
          },
          category: {
            connect: {
              id: this.newOrderProductSkus.product.categoryId ?? 0,
            },
          },
          brandCategory: {
            connect: {
              id: this.newOrderProductSkus.product.brandCategoryId ?? "",
            },
          },
          product: {
            connect: {
              id: this.newOrderProductSkus.product.id,
            },
          },
          year: Number(dayjs(this.newOrderProductSkus.stockedDate).format("YYYY")),
          month: Number(dayjs(this.newOrderProductSkus.stockedDate).format("M")),
          orderedDate: dayjs(this.newOrderProductSkus.orderedDate).toDate(),
          stockedDate: dayjs(this.newOrderProductSkus.stockedDate).toDate(),
          stockedStatus: this.newOrderProductSkus.stockedStatus,
        };
        const responseOrderProduct = await RadialApiClient.createOrderProduct(dto).catch((error) => {
          this.isLoading = false;
          this.addOrderProductSkusModal = false;
          if (error.response.data.statusCode === 400) {
            this.showAlert(
              `${dayjs(this.newOrderProductSkus?.stockedDate).format(
                "YYYY年M月"
              )}を納品月とした同じ品番のアイテムが既に登録されているようです。品番及び納品日を再度ご確認ください。`,
              "error",
              -1
            );
          } else {
            this.showAlert(
              `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error",
              -1
            );
          }
        });
        const orderSkus: UpsertOrderSkuDto[] = this.newOrderProductSkus.orderSkus.map((item) => {
          return {
            skuId: item.sku.id,
            orderQuantity: Number(item.orderQuantityString?.replace(/[^0123456789]/g, "") ?? 0),
            orderCost: Number(item.orderCostString?.replace(/[^0123456789]/g, "") ?? 0),
          };
        });
        if (responseOrderProduct) {
          const createdOrderProductSkusCost = await RadialApiClient.createMdMapOrderProductSkusFromScratch({
            orderProductId: responseOrderProduct?.id,
            orderSkus: orderSkus,
          });
          if (createdOrderProductSkusCost) {
            // スタッツ及びselectedProductの更新
            this.$emit(
              "update-stats",
              this.selectedProduct.id,
              this.selectedProduct.seasonId,
              this.selectedProduct.brandId,
              this.selectedProduct.categoryId
            );
            this.isLoading = false;
            this.addOrderProductSkusModal = false;
            this.skusWithCost = createdOrderProductSkusCost.skus;
            this.updateSkusCostModal = true;
          } else {
            this.isLoading = false;
            this.addOrderProductSkusModal = false;
            this.showAlert(
              `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
              "error",
              -1
            );
          }
        }
      }
      this.isLoading = false;
    },
    async saveOrderProductSkus() {
      this.isLoading = true;
      // 削除ストックにあるものを編集ストックから排除
      this.editingOrderInfo = this.editingOrderInfo.filter((info) => {
        return !this.deletingOrderInfo.includes(info.orderProduct.id);
      });
      // 編集ストックにある発注情報の更新
      let errorMessages: { status: number; orderProduct: OrderProduct | null }[] = [];
      if (this.editingOrderInfo.length > 0) {
        for (const orderInfo of this.editingOrderInfo) {
          // OrderProductの更新
          const dto: UpdateOrderProductDto = {
            year: Number(dayjs(orderInfo.orderProduct.stockedDate).format("YYYY")),
            month: Number(dayjs(orderInfo.orderProduct.stockedDate).format("M")),
            orderedDate: dayjs(orderInfo.orderProduct.orderedDate).toDate() ?? null,
            stockedDate: dayjs(orderInfo.orderProduct.stockedDate).toDate() ?? null,
            stockedStatus: orderInfo.orderProduct.stockedStatus,
          };
          await RadialApiClient.updateOrderProduct(orderInfo.orderProduct.id, dto).catch((error) => {
            errorMessages.push({ status: error.response?.data.statusCode, orderProduct: orderInfo.orderProduct });
            this.isLoading = false;
            if (error.response.data.statusCode === 400) {
              this.showAlert(
                `${dayjs(orderInfo.orderProduct.stockedDate).format(
                  "YYYY年M月"
                )}を納品月とした同じ品番のアイテムが既に登録されているようです。品番及び納品日を再度ご確認ください。`,
                "error",
                -1
              );
            } else {
              this.showAlert(
                `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
                "error",
                -1
              );
            }
          });
          if (orderInfo.orderSkus.length > 0) {
            // OrderSkuの更新
            const orderSkus: UpsertOrderSkuDto[] = orderInfo.orderSkus.map((item) => {
              return {
                skuId: item.skuId,
                orderQuantity: item.orderQuantity,
                orderCost: item.orderCost,
              };
            });
            // TODO: 今後、、原価の更新をorderProduct横断でできるように
            await RadialApiClient.createMdMapOrderProductSkusFromScratch({
              orderProductId: orderInfo.orderProduct.id,
              orderSkus: orderSkus,
            }).catch((error) => {
              errorMessages.push({ status: error.response?.data.statusCode, orderProduct: orderInfo.orderProduct });
            });
          }
        }
        this.editingOrderInfo = [];
      }
      // 削除ストックにある発注情報の削除
      if (this.deletingOrderInfo.length > 0) {
        await Promise.all(
          this.deletingOrderInfo.map((orderId) => RadialApiClient.deleteMdMapOrderProduct(orderId))
        ).catch((error) => {
          errorMessages.push({ status: error.response?.data.statusCode, orderProduct: null });
        });
        this.deletingOrderInfo = [];
      }
      if (errorMessages.length === 0) {
        // スタッツ及びselectedProductの更新
        this.$emit(
          "update-stats",
          this.selectedProduct.id,
          this.selectedProduct.seasonId,
          this.selectedProduct.brandId,
          this.selectedProduct.categoryId
        );
        this.showAlert("発注情報を更新しました！", "success", -1);
      } else {
        this.showAlert(this.getErrorMessage(errorMessages), "error", -1);
      }
      this.isLoading = false;
    },
    async updateSkuCosts() {
      this.isLoading = true;
      const updatedSkusCost = await RadialApiClient.updateSkusCost({
        skuCosts: this.skusWithCost.map((item) => {
          return {
            skuId: item.skuId,
            cost: Math.floor(item.newCost),
          };
        }),
      });
      if (updatedSkusCost) {
        // スタッツ及びselectedProductの更新
        this.$emit(
          "update-stats",
          this.selectedProduct.id,
          this.selectedProduct.seasonId,
          this.selectedProduct.brandId,
          this.selectedProduct.categoryId
        );
        this.isLoading = false;
        this.updateSkusCostModal = false;
        this.showAlert("原価情報を更新しました！", "success", -1);
      } else {
        this.isLoading = false;
        this.updateSkusCostModal = false;
        this.showAlert(
          `エラーが発生しております。
          入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
          "error",
          -1
        );
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.updateEditingOrders();
    this.isLoading = false;
  },
});
