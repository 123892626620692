
import Vue from "vue";
import dayjs from "dayjs";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import { DashboardChartFilters, DashboardChartGroup, DashboardSaveInfo, DashboardStore } from "@/store/model";
import { Season, Brand, Category, DashboardSaleAnalysisItemEntity } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import LocalDataService from "@/store/LocalDataService";
import { Chart } from "highcharts-vue";
import { Options, SeriesOptionsType } from "highcharts";
import * as Highcharts from "highcharts";
import { DashboardSaleAnalysisDto } from "@/api/dto";
import AuthClient from "@/api/AuthClient";
import mixin from "@/mixin/mixin";

export interface TotalAnalysis {
  result: number;
  budget: number;
  colorName: string;
}

const ColorType = {
  green: "#5FB3B1",
  blue: "#4487C8",
  red: "#F291A2",
  greenThin: "#A5D5D4",
  blueThin: "#76B3E0",
  redThin: "#F6CCD4",
} as const;
export type ColorType = (typeof ColorType)[keyof typeof ColorType];

export interface DataType {
  rangeDates: string[];
  rangeDatesMenu: boolean;
  today: Date;
  cancelDates: string[];
  isCanceled: boolean;
  dashboardChartFilters: DashboardChartFilters;
  filters: string[];
  filterKeys: string[];
  seasonTable: Season[];
  brandTable: Brand[];
  categoryTable: Category[];
  storeTable: DashboardStore[];
  tab: string;
  filterGroups: DashboardChartGroup[];
  selectedFilterGroupId: number | null;
  groupNumber: number;
  options: Options;
  totalAnalysis: TotalAnalysis[];
  colorState: Set<ColorType>;
  myStore: DashboardStore | null;
  isLoading: boolean;
}

export default Vue.extend({
  name: "dashboard-chart",
  mixins: [mixin],
  components: {
    ElementRangeDatePicker,
    FilterChipButton,
    highcharts: Chart,
  },
  data(): DataType {
    return {
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      rangeDatesMenu: false,
      today: new Date(),
      cancelDates: [],
      isCanceled: false,
      dashboardChartFilters: { season: [], brand: [], category: [], store: [] },
      filters: ["シーズン", "ブランド", "アイテムカテゴリ", "店舗"],
      filterKeys: ["season", "brand", "category", "store"],
      seasonTable: [],
      brandTable: [],
      categoryTable: [],
      storeTable: [],
      tab: "sales",
      filterGroups: [],
      selectedFilterGroupId: null,
      groupNumber: 0,
      options: {
        title: undefined,
        chart: {
          width: 800,
          height: this.isTablet ? 293 : 350,
          backgroundColor: "#ffffff",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
      },
      totalAnalysis: [],
      colorState: new Set<ColorType>(),
      myStore: null,
      isLoading: true,
    };
  },
  props: {
    isTablet: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    minDate(): string {
      return dayjs(this.today).subtract(29, "day").format("YYYY-MM-DD");
    },
  },
  methods: {
    filterOptions(index: number): Season[] | Brand[] | Category[] | DashboardStore[] {
      switch (index) {
        case 0:
          return this.seasonTable;
        case 1:
          return this.brandTable;
        case 2:
          return this.categoryTable;
        case 3:
          return this.storeTable;
        default:
          return [];
      }
    },
    async changeDateRange() {
      this.isLoading = true;
      this.isCanceled = false;
      this.rangeDatesMenu = false;
      await this.updateOptions();
      this.isLoading = false;
    },
    getLeftOverColorName(): keyof typeof ColorType {
      if (!this.colorState.has(ColorType.green)) {
        this.colorState.add(ColorType.green);
        return "green";
      } else if (!this.colorState.has(ColorType.blue)) {
        this.colorState.add(ColorType.blue);
        return "blue";
      } else {
        this.colorState.add(ColorType.red);
        return "red";
      }
    },
    async addGroup() {
      this.groupNumber += 1;
      // 新しいfilterGroupの追加
      const newFilterGroup: DashboardChartGroup = {
        groupId: this.groupNumber,
        colorName: this.getLeftOverColorName(),
        filters: { season: [], brand: [], category: [], store: [] },
      };
      // staffの場合デフォルトで店舗フィルタに自分の店舗を追加
      if (this.myStore) {
        newFilterGroup.filters.store = newFilterGroup.filters.store.concat(this.myStore);
      }
      this.filterGroups = this.filterGroups.concat(newFilterGroup);
      // selectedFilterGroupIdの変更
      this.selectedFilterGroupId = this.filterGroups.slice(-1)[0].groupId;
      // filter-chip-buttonのv-model変更
      this.dashboardChartFilters = this.filterGroups.find(
        (filterGroup) => filterGroup.groupId === this.selectedFilterGroupId
      )?.filters ?? { season: [], brand: [], category: [], store: [] };
      // filterGroupを保存
      this.saveGroups();
      // optionsを更新
      await this.updateOptions();
    },
    async deleteFilterGroup(groupId: number) {
      // filterGroupsから該当filterGroupを削除
      const index = this.filterGroups.findIndex((filterGroup) => filterGroup.groupId === groupId);
      const key = this.filterGroups[index].colorName;
      this.colorState.delete(ColorType[key as keyof typeof ColorType]);
      this.filterGroups.splice(index, 1);
      // selectedFilterGroupIdが削除された場合はselectedFilterGroupIdの再選択とfilter-chip-buttonのv-modelの変更
      if (this.selectedFilterGroupId === groupId) {
        this.selectedFilterGroupId = this.filterGroups.slice(-1)[0].groupId;
        this.dashboardChartFilters = this.filterGroups.find(
          (filterGroup) => filterGroup.groupId === this.selectedFilterGroupId
        )?.filters ?? { season: [], brand: [], category: [], store: [] };
      }
      // filterGroupを保存
      this.saveGroups();
      // optionsを更新
      await this.updateOptions();
    },
    selectFilterGroup(groupId: number) {
      // 選択されたfilterGroupのgroupIdをselectedFilterGroupIdに代入
      this.selectedFilterGroupId = groupId;
      //filter-chip-buttonのv-model変更
      this.dashboardChartFilters = this.filterGroups.find(
        (filterGroup) => filterGroup.groupId === this.selectedFilterGroupId
      )?.filters ?? { season: [], brand: [], category: [], store: [] };
    },
    async changeFilter() {
      // filterGroupsの選択されているfilterGroupのfiltersを変
      this.filterGroups[
        this.filterGroups.findIndex((filterGroup) => filterGroup.groupId === this.selectedFilterGroupId)
      ].filters = this.dashboardChartFilters;
      // filterGroupを保存
      this.saveGroups();
      // optionsを更新
      await this.updateOptions();
    },
    async clearFilter(index: number) {
      // dashboardChartFiltersの選択されているfilter情報を空にする
      const filterKey: keyof DashboardChartFilters = this.filterKeys[index] as keyof DashboardChartFilters;
      this.dashboardChartFilters[filterKey] = [];
      // filterGroupsの該当filterGroupのfiltersを変更
      this.filterGroups[
        this.filterGroups.findIndex((filterGroup) => filterGroup.groupId === this.selectedFilterGroupId)
      ].filters = this.dashboardChartFilters;
      // filterGroupを保存
      this.saveGroups();
      // optionsを更新
      await this.updateOptions();
    },
    isSelected(groupId: number): boolean {
      return this.selectedFilterGroupId === groupId;
    },
    async saveGroups() {
      const saveInfo: DashboardSaveInfo = {
        groupNumber: this.groupNumber,
        filterGroups: this.filterGroups,
      };
      await LocalDataService.setDashboardGroups(saveInfo);
    },
    async updateOptions() {
      // filterGroupsからcategoriesとseriesを作成
      let categories: string[] = [];
      let series: SeriesOptionsType[] = [];
      let totalAnalysis: TotalAnalysis[] = [];
      let maxData = 0;
      for (const colorName of ["green", "blue", "red"]) {
        // グラフ削除時のアニメーション抑制のためにカラーの数for文を回す
        const filterGroup: DashboardChartGroup | undefined = this.filterGroups.find(
          (filterGroup) => filterGroup.colorName === colorName
        );
        if (filterGroup) {
          const colorCodeResult = ColorType[colorName as keyof typeof ColorType];
          const colorCodeBudget = ColorType[`${colorName}Thin` as keyof typeof ColorType];
          // APIからチャートデータを取得
          const filterGroup = this.filterGroups.find((filterGroup) => filterGroup.colorName === colorName);
          if (filterGroup) {
            let storeIds = filterGroup.filters.store.filter((store) => store.operating).map((store) => store.id);
            if (filterGroup.filters.store.map((store) => store.id).includes("inoperative")) {
              storeIds = storeIds.concat(this.storeTable.filter((store) => !store.operating).map((store) => store.id));
            }
            const params: DashboardSaleAnalysisDto = {
              start: this.rangeDates[0],
              end: this.rangeDates[1],
              seasonIds: filterGroup.filters.season.map((season) => season.id),
              brandIds: filterGroup.filters.brand.map((brand) => brand.id),
              categoryIds: filterGroup.filters.category.map((category) => category.id),
              storeIds,
            };
            const saleAnalysis = await RadialApiClient.getDashboardChart(params);
            if (saleAnalysis) {
              // x軸の値を作成
              categories = categories.concat(saleAnalysis.items.map((item) => dayjs(item.date).format("MM.DD")));
              // y軸の値を作成
              const dataResult = saleAnalysis.items.map((item) => {
                switch (this.tab) {
                  case "sale":
                    return item.saleResult;
                  case "margin":
                    return item.grossMarginResult;
                  case "proper":
                    return item.properSaleResult;
                  case "bargain":
                    return item.bargainSaleResult;
                  default:
                    return 0;
                }
              });
              const dataBudget = saleAnalysis.items.map((item) => {
                switch (this.tab) {
                  case "sale":
                    return item.saleBudget;
                  case "margin":
                    return item.grossMarginBudget;
                  case "proper":
                    return item.properSaleBudget;
                  case "bargain":
                    return item.bargainSaleBudget;
                  default:
                    return 0;
                }
              });
              maxData = Math.max(maxData, ...dataResult, ...dataBudget);
              series.push({
                name: `グラフ${filterGroup.groupId}`, // グラフの名前指定
                type: "line", // グラフの形指定
                data: dataResult, // グラフに使用するデータ指定
                color: colorCodeResult,
                dashStyle: "Solid",
                lineWidth: 3,
                marker: {
                  radius: 8,
                  symbol: "circle",
                },
              });
              series.push({
                name: `グラフ${filterGroup.groupId}予算`, // グラフの名前指定
                type: "line", // グラフの形指定
                data: dataBudget, // グラフに使用するデータ指定
                color: colorCodeBudget,
                dashStyle: "LongDash",
                lineWidth: 2,
                marker: {
                  radius: 5,
                  symbol: "circle",
                },
              });

              // 総計の情報を作成
              const resultKey: keyof DashboardSaleAnalysisItemEntity =
                this.tab === "sale"
                  ? "saleResult"
                  : this.tab === "margin"
                  ? "grossMarginResult"
                  : this.tab === "proper"
                  ? "properSaleResult"
                  : "bargainSaleResult";
              const budgetKey: keyof DashboardSaleAnalysisItemEntity =
                this.tab === "sale"
                  ? "saleBudget"
                  : this.tab === "margin"
                  ? "grossMarginBudget"
                  : this.tab === "proper"
                  ? "properSaleBudget"
                  : "bargainSaleBudget";
              totalAnalysis = totalAnalysis.concat({
                result: saleAnalysis.total[resultKey],
                budget: saleAnalysis.total[budgetKey],
                colorName: colorName,
              });
            }
          } else {
            series = series.concat({ name: "", type: "line", data: [] }, { name: "", type: "line", data: [] });
          }
        } else {
          series = series.concat({ name: "", type: "line", data: [] }, { name: "", type: "line", data: [] });
        }
      }
      // totalAnalysisとoptionsのcategories(x軸の日付部分)とseries(折れ線グラフ)を更新
      const max =
        Math.ceil(maxData / Math.pow(10, String(maxData).length - 1)) * Math.pow(10, String(maxData).length - 1);
      const tickInterval = max / 10;
      this.totalAnalysis = totalAnalysis;
      this.options = {
        title: undefined,
        chart: {
          width: 800,
          height: this.isTablet ? 293 : 350,
          backgroundColor: "#ffffff",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        xAxis: [
          {
            categories,
            tickInterval: 1,
            labels: {
              style: {
                // X軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            },
          },
        ],
        yAxis: [
          {
            // y軸設定
            title: {
              text: undefined,
            },
            endOnTick: false,
            min: 0,
            max,
            tickInterval, // y軸のgrid幅を揃える
            labels: {
              formatter: function () {
                return `￥${Highcharts.numberFormat(Number(this.value), 0, ",", ",")}`;
              },
              style: {
                // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                color: "#799498",
              },
            },
          },
        ],
        tooltip: {
          formatter: function () {
            let s = this.x.toString();
            if (this.points) {
              for (const point of this.points) {
                s +=
                  "<br/>" +
                  `<b style="color:${this.points[this.points.indexOf(point)].color}">` +
                  this.points[this.points.indexOf(point)].series.name +
                  "</b>" +
                  ": " +
                  "￥" +
                  this.points[this.points.indexOf(point)].y.toLocaleString();
              }
            }
            return s;
          },
          style: {
            // ツールチップ内の文字カラーはassistantType70
            color: "#3C6065",
            fontSize: "12px",
          },
          shared: true,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false,
              color: "#006400",
              verticalAlign: "bottom",
              useHTML: true,
            },
          },
          series: {
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        series,
      };
    },
    async changeTabs() {
      // optionsを更新
      await this.updateOptions();
    },
  },
  watch: {
    rangeDatesMenu: {
      async handler() {
        if (this.rangeDatesMenu) {
          this.cancelDates = this.rangeDates;
          this.isCanceled = true;
        } else if (!this.rangeDatesMenu && this.isCanceled) {
          this.rangeDates = this.cancelDates;
        }
      },
    },
    isLoading() {
      this.$emit("changeLoadingStatus", this.isLoading);
    },
  },
  async mounted() {
    this.seasonTable = (await RadialApiClient.listSeasons()) ?? [];
    this.seasonTable.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    this.brandTable = (await RadialApiClient.listBrands()) ?? [];
    this.categoryTable = (await RadialApiClient.listCategories()) ?? [];
    const storeTable = (await RadialApiClient.listStores()) ?? [];
    this.storeTable = storeTable
      .filter((store) => store.operating)
      .map((store) => {
        return {
          id: store.id,
          companyId: store.companyId,
          cognitoSub: store.cognitoSub,
          serviceId: store.serviceId,
          posId: store.posId,
          name: store.name,
          email: store.email,
          postalCode: store.postalCode,
          deleted: store.deleted,
          operating: store.operating,
          shareRate: store.shareRate,
          icon: store.icon,
        };
      });
    const savedInfo = await LocalDataService.getDashboardGroups();
    if (savedInfo) {
      this.groupNumber = savedInfo.groupNumber;
      this.filterGroups = savedInfo.filterGroups;
      this.selectFilterGroup(this.filterGroups[0].groupId);
      if (this.filterGroups.map((filterGroup) => filterGroup.colorName).includes("green")) {
        this.colorState.add(ColorType.green);
      }
      if (this.filterGroups.map((filterGroup) => filterGroup.colorName).includes("blue")) {
        this.colorState.add(ColorType.blue);
      }
      if (this.filterGroups.map((filterGroup) => filterGroup.colorName).includes("red")) {
        this.colorState.add(ColorType.red);
      }
    } else {
      this.addGroup();
    }
    await this.updateOptions();
    const isAdmin = this.$route.path.startsWith("/admin");
    const user = await AuthClient.getUserInfo();
    if (user) {
      if (!isAdmin) {
        const storeInfo = await RadialApiClient.getStore(user.sub);
        this.myStore = this.storeTable.find((store) => store.id === storeInfo?.id) ?? null;
        if (this.myStore) {
          this.dashboardChartFilters.store = this.dashboardChartFilters.store.concat({
            id: this.myStore.id,
            companyId: this.myStore.cognitoSub,
            cognitoSub: this.myStore.cognitoSub,
            serviceId: this.myStore.serviceId,
            posId: this.myStore.posId,
            name: this.myStore.name,
            email: this.myStore.email,
            postalCode: this.myStore.postalCode,
            deleted: this.myStore.deleted,
            operating: this.myStore.operating,
            shareRate: this.myStore.shareRate,
            icon: this.myStore.icon,
          } as DashboardStore);
        }
      }
    }
    this.isLoading = false;
  },
});
