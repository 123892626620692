import { User } from "@/store/model";
import { AdminUser, Store } from "@/api/entities";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import awsmobile from "@/aws-exports";
import RadialApiClient from "./RadialApiClient";
import { PromiseResult } from "aws-sdk/lib/request";
import store from "@/store";
import { CreateCompanyDto } from "./dto";
export default class AuthClient {
  static setupAWS() {
    AWS.config.region = awsmobile.aws_cognito_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
    });
  }

  static async getUserInfo(): Promise<User | null> {
    const userInfo = await Auth.currentUserInfo();
    if (userInfo && userInfo.attributes) {
      let sub = userInfo.attributes.sub;
      if (sub.split(":").length > 1) {
        sub = sub.split(":")[1];
      }
      const companyId = userInfo.attributes["custom:companyId"] ?? sub;
      return {
        id: userInfo.id,
        name: userInfo.attributes.name,
        email: userInfo.attributes.email,
        address: userInfo.attributes.address,
        sub,
        adminUserName: await this.getAdminUserName(sub, companyId),
        companyId,
      };
    }
    return null;
  }

  static async getAdminUserName(sub: string, companyId: string): Promise<string> {
    if (sub === companyId) {
      return "root";
    } else {
      const adminUser = await RadialApiClient.getAdminUser(sub);
      if (adminUser && adminUser.adminUserMasterId) {
        // TODO (schema): Fix roleMasterId -> adminUserMasterId
        const adminUserMaster = await RadialApiClient.getAdminUserMaster(adminUser.adminUserMasterId);
        return adminUserMaster?.name ?? "admin";
      } else {
        return "staff";
      }
    }
  }

  static async checkAuth(isAdmin: boolean): Promise<boolean> {
    try {
      const session = await Auth.currentSession();
      if (session) {
        if (RadialApiClient.env === "localhost") {
          // コメントアウトする必要なし！
          console.log("accessToken:", session.getAccessToken().getJwtToken());
        }
        const user = await this.getUserInfo();
        if (user) {
          store.commit({
            type: "setUser",
            user,
          });
          const company = await RadialApiClient.getCompany(user.companyId);
          if (company) {
            store.commit({
              type: "setCompany",
              company,
            });
          }
          if (isAdmin) {
            if (user.sub !== user.companyId) {
              const adminUser = await RadialApiClient.getAdminUser(user.sub);
              if (adminUser) {
                store.commit({
                  type: "setAdminUser",
                  adminUser,
                });
              }
            }
          } else {
            const radialStore = await RadialApiClient.getStore(user.sub);
            if (store) {
              store.commit({ type: "setStore", store: radialStore });
            }
          }
          return user.adminUserName !== "staff" ? isAdmin : !isAdmin;
        }
      }
    } catch {
      return false;
    }
    return false;
  }

  static generatePassword(): string {
    const passwordBase = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += passwordBase.charAt(Math.floor(Math.random() * passwordBase.length));
    }
    return password;
  }

  static async sendEmailToCreateAccount(
    email: string,
    password: string
  ): Promise<PromiseResult<AWS.SES.SendEmailResponse, AWS.AWSError>> {
    const ses = new AWS.SES({ region: "ap-northeast-1" });
    const params: AWS.SES.Types.SendEmailRequest = {
      Source: "admin@radial-db.com",
      Destination: {
        ToAddresses: [email],
      },
      Message: {
        Subject: { Data: "radialアカウント仮登録完了のお知らせ" },
        Body: {
          Text: {
            Data: `Radialアカウントの仮登録が完了しました！\n仮パスワードは ${password} です。\n\n以下のURLから初回登録を行いましょう。${
              RadialApiClient.rawUrl
            }/admin/update-password?email=${encodeURIComponent(email)}`,
          },
        },
      },
    };
    return ses.sendEmail(params).promise();
  }

  static async createAccount(
    name: string,
    email: string,
    address: string,
    password: string,
    send: boolean,
    companyId: string | null = null
  ): Promise<PromiseResult<AWS.CognitoIdentityServiceProvider.Types.AdminCreateUserResponse, AWS.AWSError>> {
    this.setupAWS();
    const client = new AWS.CognitoIdentityServiceProvider();
    const userAttributes = companyId
      ? [
          {
            Name: "name",
            Value: name,
          },
          {
            Name: "custom:companyId",
            Value: companyId,
          },
          {
            Name: "email",
            Value: email,
          },
          {
            Name: "address",
            Value: address,
          },
          {
            Name: "email_verified",
            Value: "true",
          },
        ]
      : [
          {
            Name: "name",
            Value: name,
          },
          {
            Name: "email",
            Value: email,
          },
          {
            Name: "address",
            Value: address,
          },
          {
            Name: "email_verified",
            Value: "true",
          },
        ];
    const params: AWS.CognitoIdentityServiceProvider.Types.AdminCreateUserRequest = {
      UserPoolId: awsmobile.aws_user_pools_id,
      Username: email,
      TemporaryPassword: password,
      UserAttributes: userAttributes,
      ForceAliasCreation: false,
      MessageAction: send ? undefined : "SUPPRESS",
    };
    return client.adminCreateUser(params).promise();
  }

  static async createStore(
    companyId: string,
    store: Store,
    password: string
  ): Promise<PromiseResult<AWS.CognitoIdentityServiceProvider.Types.AdminCreateUserResponse, AWS.AWSError>> {
    return await this.createAccount(store.name, store.email ?? "", "", password, true, companyId);
  }

  static async createAdminUser(
    companyId: string,
    adminUser: AdminUser,
    password: string
  ): Promise<PromiseResult<AWS.CognitoIdentityServiceProvider.Types.AdminCreateUserResponse, AWS.AWSError>> {
    return await this.createAccount(adminUser.name, adminUser.email ?? "", "", password, false, companyId);
  }

  static async updateCompany(email: string, newCompany: CreateCompanyDto) {
    this.setupAWS();
    const client = new AWS.CognitoIdentityServiceProvider();
    await client
      .adminUpdateUserAttributes({
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: email,
        UserAttributes: [
          {
            Name: "name",
            Value: newCompany.name,
          },
          {
            Name: "email",
            Value: newCompany.email,
          },
          {
            Name: "custom:companyId",
            Value: newCompany.id,
          },
        ],
      })
      .promise();
  }

  static async updateAdminUser(oldAdminUser: AdminUser, newAdminUser: AdminUser) {
    this.setupAWS();
    const client = new AWS.CognitoIdentityServiceProvider();
    await client
      .adminUpdateUserAttributes({
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: oldAdminUser.email,
        UserAttributes: [
          {
            Name: "name",
            Value: newAdminUser.name,
          },
          {
            Name: "email",
            Value: newAdminUser.email,
          },
          {
            Name: "custom:companyId",
            Value: newAdminUser.companyId,
          },
        ],
      })
      .promise();
  }

  static async updateStore(oldStore: Store, newStore: Store) {
    this.setupAWS();
    const client = new AWS.CognitoIdentityServiceProvider();
    if (oldStore.email && newStore.email) {
      await client
        .adminUpdateUserAttributes({
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: oldStore.email,
          UserAttributes: [
            {
              Name: "name",
              Value: newStore.name,
            },
            {
              Name: "email",
              Value: newStore.email,
            },
            {
              Name: "custom:companyId",
              Value: newStore.companyId,
            },
          ],
        })
        .promise();
    }
  }

  static async deleteAccount(username: string): Promise<{}> {
    this.setupAWS();
    const client = new AWS.CognitoIdentityServiceProvider();
    const params = {
      Username: username,
      UserPoolId: awsmobile.aws_user_pools_id,
    };
    return client.adminDeleteUser(params).promise();
  }
}
