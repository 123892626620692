
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { SkuCustomValueSetting, CustomValueType } from "@/api/entities";
import { CreateSkuCustomValueSettingDto, UpdateSkuCustomValueSettingDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { CustomValueTypeItem, User } from "@/store/model";
import CustomSelector from "@/components/vuetify/Selector.vue";
import CustomTextField from "@/components/vuetify/TextField.vue";
import { Constant } from "@/store/constant";
import * as lodash from "lodash";
import mixin from "@/mixin/mixin";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: SkuCustomValueSetting | null;
  selectedIndex: number;
  skuCustomValueSettings: SkuCustomValueSetting[];
  newSkuCustomValueSetting: {
    settingName: string;
    settingType: CustomValueType | null;
  };
  dialogAddSkuCustomValueSetting: boolean;
  dialogEditSkuCustomValueSetting: boolean;
  dialogDeleteSkuCustomValueSetting: boolean;
  selectedSkuCustomValueSetting: SkuCustomValueSetting | null;
  page: number;
  pageSize: number;
  list: SkuCustomValueSetting[];
  listCount: number;
  customTypeValues: CustomValueTypeItem[];
}

export default Vue.extend({
  name: "SkuCustom",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    Pagination,
    CustomSelector,
    CustomTextField,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      selectedIndex: 0,
      skuCustomValueSettings: [],
      newSkuCustomValueSetting: { settingName: "", settingType: null },
      dialogAddSkuCustomValueSetting: false,
      dialogEditSkuCustomValueSetting: false,
      dialogDeleteSkuCustomValueSetting: false,
      selectedSkuCustomValueSetting: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
      customTypeValues: Constant.customValueTypes,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: true,
    },
  },
  computed: {
    editSkuCustomValueSettingDisabled(): boolean {
      if (this.selectedSkuCustomValueSetting) {
        if (this.selectedSkuCustomValueSetting.name === "" || this.isProhibited) {
          // 項目名が空文字列 or 禁止文字が使用されている場合
          return true;
        }
        if (
          this.skuCustomValueSettings
            .filter((_, index) => index !== this.selectedIndex)
            .some((skuCustomValueSetting) => {
              return skuCustomValueSetting.name === this.selectedSkuCustomValueSetting?.name;
            })
        ) {
          // 同じ項目名のものがすでにある場合
          return true;
        }
        if (
          lodash.isEqual(this.selectedSkuCustomValueSetting, this.skuCustomValueSettings[this.selectedIndex])
        ) {
          // 値が変更されていない場合
          return true;
        }
        return false;
      }
      return true;
    },
    addSkuCustomValueSettingDisabled(): boolean {
      // コード、名前が空の場合、禁止文字列が使用されている場合
      if (
        this.newSkuCustomValueSetting.settingType === null ||
        this.newSkuCustomValueSetting.settingName === "" ||
        this.isProhibited
      ) {
        return true;
      }
      // 同じ項目名のものがすでにある場合
      if (
        this.skuCustomValueSettings.some((skuCustomValueSetting) => {
          return skuCustomValueSetting.name === this.newSkuCustomValueSetting.settingName;
        })
      ) {
        return true;
      }
      return false;
    },
    isProhibited() {
      const prohibitedChars = /\\|\/|:|\*|\?|"|<|>|\||_/;
      if (prohibitedChars.test(this.newSkuCustomValueSetting.settingName)) {
        return true;
      } else if (this.selectedSkuCustomValueSetting?.name) {
        return prohibitedChars.test(this.selectedSkuCustomValueSetting.name);
      } else {
        return false;
      }
    },
  },
  methods: {
    listActive(item: SkuCustomValueSetting) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    selectSkuCustomValueSetting(
      SkuCustomValueSetting: SkuCustomValueSetting,
      index: number,
      selectedIndex: number
    ) {
      this.selectedSkuCustomValueSetting = { ...SkuCustomValueSetting };
      if (index === 0) {
        this.selectedIndex = selectedIndex;
        this.dialogEditSkuCustomValueSetting = true;
      } else {
        this.dialogDeleteSkuCustomValueSetting = true;
      }
    },
    deselectSkuCustomValueSetting() {
      this.selectedSkuCustomValueSetting = null;
      this.dialogEditSkuCustomValueSetting = false;
      this.dialogDeleteSkuCustomValueSetting = false;
    },
    sortList() {
      this.list.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    async addSkuCustomValueSetting() {
      this.$emit("isLoading", true);

      if (this.user && this.newSkuCustomValueSetting.settingType) {
        let isError = false;
        const dto: CreateSkuCustomValueSettingDto = {
          company: { connect: { id: this.user?.companyId } },
          type: this.newSkuCustomValueSetting.settingType,
          name: this.newSkuCustomValueSetting.settingName,
        };
        const createdSkuCustomValueSetting = await RadialApiClient.createSkuCustomValueSetting(dto);
        if (createdSkuCustomValueSetting) {
          this.list.push(createdSkuCustomValueSetting);
        } else {
          isError = true;
        }
        if (isError) {
          this.showAlert(
            `エラーが発生しております。
                入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        } else {
          this.listCount = this.list.length;
          this.updatePage(this.page);
          this.showAlert("新しくカスタム項目が作成されました！", "success");
        }
      }
      this.newSkuCustomValueSetting = { settingName: "", settingType: null };
      this.$emit("isLoading", false);
      this.dialogAddSkuCustomValueSetting = false;
    },
    cancelAddSkuCustomValueSetting() {
      this.dialogAddSkuCustomValueSetting = false;
      this.newSkuCustomValueSetting = { settingName: "", settingType: null };
    },
    async editSkuCustomValueSetting() {
      this.$emit("isLoading", true);
      if (this.selectedSkuCustomValueSetting) {
        const dto: UpdateSkuCustomValueSettingDto = {
          name: this.selectedSkuCustomValueSetting.name,
          type: this.selectedSkuCustomValueSetting.type,
        };
        await RadialApiClient.updateSkuCustomValueSetting(this.selectedSkuCustomValueSetting.id, dto);
        const list = await RadialApiClient.listSkuCustomValueSettings();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
        this.selectedSkuCustomValueSetting = null;
        this.dialogEditSkuCustomValueSetting = false;
      }
      this.$emit("isLoading", false);
    },
    async deleteSkuCustomValueSetting() {
      this.dialogDeleteSkuCustomValueSetting = false;
      this.$emit("isLoading", true);
      if (this.selectedSkuCustomValueSetting) {
        await RadialApiClient.deleteSkuCustomValueSetting(this.selectedSkuCustomValueSetting.id);
        const list = await RadialApiClient.listSkuCustomValueSettings();
        if (list) {
          this.list = list;
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.selectedSkuCustomValueSetting = null;
      this.$emit("isLoading", false);
      this.showAlert("無事に削除が完了しました！", "success");
    },
    getAlertMessage(): string {
      if (this.selectedSkuCustomValueSetting) {
        return `本当に${this.selectedSkuCustomValueSetting.name}を削除してもよろしいですか？
                設定されている値が全て削除されます。`;
      } else {
        return "";
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.skuCustomValueSettings = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    const list = await RadialApiClient.listSkuCustomValueSettings();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.skuCustomValueSettings = this.list;
      } else {
        this.skuCustomValueSettings = this.list.slice(0, this.pageSize);
      }
    }
    this.$emit("isLoading", false);
  },
});
