import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "vuetify";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

import VueLazyload from "vue-lazyload";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import RadialCheckboxMarked from "./assets/RadialCheckboxMarked.vue";
import RadialCheckboxBlankOutline from "./assets/RadialCheckboxBlankOutline.vue";
import RadialMinusBox from "./assets/RadialMinusBox.vue";
import RadialCancelCircle from "./assets/RadialCancelCircle.vue";

import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

// Add additional themes.
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

import "@/styles/font-colors.sass";
import "@/styles/typography.sass";
import "@/styles/override.sass";
import "@/styles/colors.sass";
import "@/styles/mask-images.sass";

import dayjs from "dayjs";
import "dayjs/locale/ja";

import { Gtag } from "./store/gtag";
Gtag.init();

import VueGtag from "vue-gtag";

import LocalDataService from "@/store/LocalDataService";
import { Constant } from "@/store/constant";

// import element
import locale from "element-ui/lib/locale";
import lang from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;

locale.use(lang);

(async () => {
  const user = await LocalDataService.getUser();
  if (user) {
    const id = Constant.ga4TrackingIdDatas.find((data) => data.companyId === user.companyId)?.trackingId ?? ""; // GA4で追加したいアカウントが増えた場合は発行したTrackingIDとCompanyIDのペアをConstant.tsに追加する
    Vue.use(VueGtag, {
      // pageTrackerExcludedRoutes: [""], pageviewを自動送信したくないページを追加(routerのページ名を追加する), pageviewするページはpageviewメソッドを叩く
      config: { id },
      params: { send_page_view: false },
    });
  }
})();

Vue.use(Loading);
Vue.component("Loading", Loading);
dayjs.locale("ja");
Vue.config.productionTip = false;
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "https://dummyimage.com/200x250/ccc/999.png&text=Not+Found",
  loading: "https://dummyimage.com/200x250/dcdcdc/999.png&text=Now loading",
  attempt: 1,
});

// // イベント設定用関数
// function noScroll(event: Event) {
//   event.preventDefault();
// }

// // スクロール禁止
// Vue.prototype.$noScroll = function() {
//   // SP
//   document.addEventListener("touchmove", noScroll, { passive: false });
//   // PC
//   document.addEventListener("mousewheel", noScroll, { passive: false });
// };

// // スクロール禁止を解除
// Vue.prototype.$returnScroll = function() {
//   // SP
//   document.removeEventListener("touchmove", noScroll);
//   // PC
//   document.removeEventListener("mousewheel", noScroll);
// };
Vue.use(vuetify);
new Vue({
  router,
  store,
  vuetify: new vuetify({
    theme: {
      themes: {
        light: {
          primary: "#3C6065",
          success: "#44807F",
          error: "#EB4465",
          radialWarning: "#E8AA42",
          radialPrimaryGreen100: "#1B3333",
          radialPrimaryGreen90: "#294D4C",
          radialPrimaryGreen80: "#366564",
          radialPrimaryGreen70: "#44807F",
          radialPrimaryGreen60: "#519998",
          radialPrimaryGreen50: "#5FB3B1",
          radialPrimaryGreen40: "#A5D4D4",
          radialPrimaryGreen30: "#CCE4E4",
          radialPrimaryGreen20: "#E7F4F5",
          radialPrimaryGreen10: "#FAFEFF",
          radialSecondaryGreen100: "#2A4935",
          radialSecondaryGreen90: "#36654D",
          radialSecondaryGreen80: "#3D7559",
          radialSecondaryGreen70: "#458567",
          radialSecondaryGreen60: "#4B9272",
          radialSecondaryGreen50: "#57A283",
          radialSecondaryGreen40: "#74BFA1",
          radialSecondaryGreen30: "#A8E1CA",
          radialSecondaryGreen20: "#C6EBDC",
          radialSecondaryGreen10: "#EEFCF7",
          radialInfo100: "#264984",
          radialInfo90: "#3366A4",
          radialInfo80: "#3A76B6",
          radialInfo70: "#4487C8",
          radialInfo60: "#4C95D5",
          radialInfo50: "#5EA3DA",
          radialInfo40: "#76B3E0",
          radialInfo30: "#76B3E0",
          radialInfo20: "#BFDDF2",
          radialInfo10: "#E5F2F9",
          radialDanger100: "#DB1336",
          radialDanger90: "#E43150",
          radialDanger80: "#E9284A",
          radialDanger70: "#EB4465",
          radialDanger60: "#F4506D",
          radialDanger50: "#F4667F",
          radialDanger40: "#EF8195",
          radialDanger30: "#F291A2",
          radialDanger20: "#F6CCD4",
          radialDanger10: "#FCEBEF",
          radialPrimaryGray100: "#3C5E66",
          radialPrimaryGray90: "#4B7580",
          radialPrimaryGray80: "#5B8D99",
          radialPrimaryGray70: "#7DA8B2",
          radialPrimaryGray60: "#9FC4CC",
          radialPrimaryGray50: "#CAE0E5",
          radialPrimaryGray40: "#DFEFF2",
          radialPrimaryGray30: "#E6F0F2",
          radialPrimaryGray20: "#F0F6F7",
          radialPrimaryGray10: "#F5F9FA",
          radialSecondaryGray100: "#3C5566",
          radialSecondaryGray90: "#4B6B80",
          radialSecondaryGray80: "#5B8099",
          radialSecondaryGray70: "#7D9DB2",
          radialSecondaryGray60: "#9FBACC",
          radialSecondaryGray50: "#C9D9E4",
          radialSecondaryGray40: "#DFEAF2",
          radialSecondaryGray30: "#E6EDF2",
          radialSecondaryGray20: "#F0F4F7",
          radialSecondaryGray10: "#F5F8FA",
          radialAssistantType100: "#0F181A",
          radialAssistantType90: "#1F3133",
          radialAssistantType80: "#2E494D",
          radialAssistantType70: "#3C6065",
          radialAssistantType60: "#587A7F",
          radialAssistantType50: "#799498",
          radialAssistantType40: "#8EADB1",
          radialAssistantType30: "#B7C9CB",
          radialAssistantType20: "#D9E3E4",
          radialAssistantType10: "#ECF1F1",
          radialAssistantType0: "#F7FAFA",
          radialBackgroundWhite: "#FBFBFB",
          radialModalBackground: "#1F3133",
        },
      },
    },
    icons: {
      values: {
        radialCheckboxMarked: {
          component: RadialCheckboxMarked,
        },
        radialCheckboxBlankOutline: {
          component: RadialCheckboxBlankOutline,
        },
        radialMinusBox: {
          component: RadialMinusBox,
        },
        radialCancelCircle: {
          component: RadialCancelCircle,
        },
      },
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
