import store from "@/store";
import { AdminUserManager } from "@/store/AdminUserManager";
import Vue from "vue";

export default Vue.extend({
  computed: {
    adminUserManager(): AdminUserManager | null {
      const user = store.state.user;
      if (user) return new AdminUserManager(user);
      return null;
    },
    canCreate(): boolean {
      return this.adminUserManager?.canCreate(this.$route.name) ?? false;
    },
    canUpdate(): boolean {
      return this.adminUserManager?.canUpdate(this.$route.name) ?? false;
    },
    isAdmin(): boolean {
      return this.$route.path.startsWith("/admin");
    },
  },
});
