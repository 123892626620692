
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";
import Stock from "@/views/action/Stock.vue";

export interface SavedPrice {
  oldPrice: number | null;
  newPrice: number | null;
}

export interface DataType {
  isLoading: boolean;
  tabIndex: string;
}

export default Vue.extend({
  name: "ActionRequest",
  data(): DataType {
    return {
      isLoading: false,
      tabIndex: "stock-moving",
    };
  },
  mixins: [mixin],
  components: {
    NavigationDrawer,
    Loading,
    Stock,
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
  async mounted() {},
});
