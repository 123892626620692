
import Vue from "vue";
import router from "@/router/index";
import AuthClient from "@/api/AuthClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import mixin from "@/mixin/mixin";

export default Vue.extend({
  name: "SignUp",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      name: "",
      email: "",
      address: "",
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
    };
  },
  methods: {
    showAlert(title: string, message: string, type: string) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    loginDidClick() {
      router.push({ name: "AdminRoot" }, () => {});
    },
    signUpDone() {
      router.push({ name: "AdminLogin" });
    },
    async signUpDidClick() {
      if (!this.name || !this.email) {
        this.showAlert("", "空欄があります。すべての必須欄を埋めてください。", "warning");
        return;
      }
      const password = AuthClient.generatePassword();
      const result = await AuthClient.createAccount(this.name, this.email, this.address, password, false, null).catch(
        (error) => {
          if (error.code === "UsernameExistsException") {
            this.showAlert("", "このメールアドレスはすでに登録されております。", "warning");
          }
        }
      );
      if (result) {
        AuthClient.sendEmailToCreateAccount(this.email, password).then(() => {
          this.showAlert("", "アカウントの仮作成が完了しました。メールボックスをご確認ください。", "success");
        });
      }
    },
    closedDialog() {
      if (this.alertType === "success") {
        this.signUpDone();
      }
    },
  },
});
