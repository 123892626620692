
import Vue from "vue";
import { Auth } from "aws-amplify";
import router from "@/router";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import mixin from "@/mixin/mixin";

export default Vue.extend({
  name: "ResetPassword",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      email: "",
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
    };
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    loginDidClick() {
      router.push({ name: this.isAdmin ? "AdminRoot" : "Root" }, () => {});
    },
    resetPassword() {
      Auth.forgotPassword(this.email)
        .then(() => {
          this.showAlert(
            `パスワード再設定用のメールを送信しました。
            メール本文に記載の確認コードを入力してパスワードを
            リセットしてください。`,
            "success"
          );
        })
        .catch((error) => {
          if (error.code === "UserNotFoundException") {
            this.showAlert(
              `ご入力のメールアドレスは登録されていないようです！
              再度メールアドレスを入力くださいませ。`,
              "error"
            );
          } else if (error.code === "InvalidParameterException") {
            this.showAlert("英数字6文字以上のパスワードを入力してください。", "error");
          } else if (error.code === "NotAuthorizedException") {
            this.showAlert(
              `パスワードが設定されていないようです。
              メールアドレス認証用メールをご確認いただき、パスワードをご設定ください。`,
              "error"
            );
          } else {
            this.showAlert(
              `エラーが発生しております。
              もしもう一度お試しいただき解決されなければ、
              cs@proces.co.jp
              にご連絡くださいませ。`,
              "error"
            );
          }
        });
    },
    resetPassworDoneDidClick() {
      router.push(
        {
          name: this.isAdmin ? "AdminResetPasswordDone" : "ResetPasswordDone",
          query: { email: this.email },
        },
        () => {}
      );
    },
    closedDialog() {
      if (this.alertType === "success") {
        this.resetPassworDoneDidClick();
      }
    },
  },
  computed: {},
});
