
import Vue from "vue";
import mixin from "@/mixin/mixin";

export interface DataType {}

export default Vue.extend({
  name: "IntegrationBase",
  mixins: [mixin],
  components: {},
  data(): DataType {
    return {};
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    newBaseIntegration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogBase: {
      get(): boolean {
        return (this as any).value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    integrateBase() {
      this.$emit("integrate", {
        newBaseIntegration: this.newBaseIntegration,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
});
