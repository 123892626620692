
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import AuthClient from "@/api/AuthClient";
import { Route } from "vue-router";
import RadialApiClient from "@/api/RadialApiClient";
import { Company, Service, Store, CompanyPlan } from "@/api/entities";
import { UpdateStoreDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";
import Loading from "@/components/vuetify/Loading.vue";

export interface DataType {
  isLoading: boolean;
  company: Company | null;
  dialogAdd: boolean;
  dialogCreateStoreAcount: boolean;
  dialogEdit: boolean;
  dialogDelete: boolean;
  oldStore: Store | null;
  selectedStore: Store | null;
  newStoreName: string;
  newShareRate: number;
  newStoreEmail: string;
  // newStorePostalCode: string;
  user: User | null;
  stores: Store[];
  showingAlert: boolean;
  showingCreateAccountAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  prevRoute: Route | null;
  isOperating: boolean;
  activeItem: Store | null;
  services: Service[];
  customServices: Service[];
  chanelType: string;
  newStoreServiceId: number | null;
  newStorePosId: number | null;
  page: number;
  pageSize: number;
  list: Store[];
  listCount: number;
}

export default Vue.extend({
  name: "CustomerTouchPoint",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    Pagination,
    Loading,
  },
  data(): DataType {
    return {
      isLoading: false,
      company: null,
      dialogAdd: false,
      dialogCreateStoreAcount: false,
      dialogEdit: false,
      dialogDelete: false,
      oldStore: null,
      selectedStore: null,
      newStoreName: "",
      newShareRate: 100,
      newStoreEmail: "",
      user: null,
      stores: [],
      showingAlert: false,
      showingCreateAccountAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      prevRoute: null,
      isOperating: false,
      activeItem: null,
      services: [],
      customServices: [],
      chanelType: "",
      newStoreServiceId: null,
      newStorePosId: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).prevRoute = from;
    });
  },
  computed: {
    newStoreDisabled(): boolean {
      // 店舗かECかでdisableのハンドリングを変更させる
      if (this.newStoreServiceId === null) {
        return true;
      } else if (this.newStoreName === "") {
        return true;
      } else if (this.newShareRate <= 0 || this.newShareRate > 100) {
        return true;
      } else {
        return false;
      }
    },
    createStoreAccountDisabled(): boolean {
      if (this.selectedStore?.name === "") {
        return true;
      } else if (!this.selectedStore?.postalCode?.match(/^\d{3}-?\d{4}$/)) {
        return true;
      } else if (!this.selectedStore?.email?.match(/.+@.+\..+/)) {
        return true;
      }
      return false;
    },
    editStoreDisabled(): boolean {
      // 共通のエラーハンドリング
      if (this.selectedStore?.name === "") {
        return true;
      } else if (this.selectedStore && (this.selectedStore?.shareRate <= 0 || this.selectedStore?.shareRate > 100)) {
        return true;
      }
      // 店舗&&アカウント発行済みの場合のエラーハンドリング
      if (this.selectedStore?.cognitoSub && String(this.selectedStore.serviceId).length !== 3) {
        if (!this.selectedStore.email?.match(/.+@.+\..+/)) {
          return true;
        } else if (!this.selectedStore?.postalCode?.match(/^\d{3}-?\d{4}$/)) {
          return true;
        }
      }
      // ECにアカウント発行導線を用意した場合にエラーハンドリング追加
      return false;
    },
    plan(): CompanyPlan | null {
      // companyがnullの場合は何も表示しない
      return this.company?.plan ?? null;
    },
  },
  methods: {
    listActive(item: Store) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    sortList() {
      this.list.sort((a, b) => {
        if (a.serviceId && b.serviceId) {
          if (a.serviceId > b.serviceId) return 1;
          if (a.serviceId < b.serviceId) return -1;
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
        }
        return 0;
      });
    },
    selectStore(store: Store, index: number) {
      this.selectedStore = { ...store };
      this.oldStore = { ...store };
      if (index === 0) {
        this.dialogEdit = true;
      } else if (index === 1) {
        this.dialogDelete = true;
      } else if (index === 2) {
        this.dialogCreateStoreAcount = true;
      }
    },
    deselectStore(index: number) {
      this.selectedStore = null;
      this.oldStore = null;
      if (index === 0) {
        this.dialogEdit = false;
      } else if (index === 1) {
        this.dialogDelete = false;
      } else if (index === 2) {
        this.dialogCreateStoreAcount = false;
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingCreateAccountAlert = true;
    },
    checkAddStore() {
      if (this.company?.maxStoreNumber && this.company.maxStoreNumber <= this.stores.length) {
        this.showAlert(
          `現在ご利用いただいているプランでは、これ以上店舗数を増やすことができないようです。\nアップデート等を希望される場合は、弊社の営業担当者もしくはcs@proces.co.jpまでお問い合わせください。`,
          "question"
        );
      } else {
        this.dialogAdd = true;
        this.listCustomServices();
      }
    },
    async addStore() {
      this.isLoading = true;
      // カスタム店舗の新しいposIdを取得（削除導線も考慮しposIdの最大値を取得）
      if (this.stores.some((item) => item.serviceId === this.newStoreServiceId)) {
        this.newStorePosId =
          Math.max(
            ...this.list
              .filter((item) => item.serviceId === this.newStoreServiceId)
              .map((item) => {
                return Number(item.posId);
              })
          ) + 1;
      } else if (this.newStoreServiceId) {
        this.newStorePosId = 0;
        await RadialApiClient.createCustomIntegration({
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
          service: {
            connect: {
              id: this.newStoreServiceId,
            },
          },
          productBargain: 0,
          headCouponBargain: 0,
          headPointBargain: 0,
          headBundleBargain: 0,
          headRoundingBargain: 0,
        });
      }
      if (this.user) {
        const store = await RadialApiClient.createStore({
          service: {
            connect: {
              id: this.newStoreServiceId as number,
            },
          },
          company: {
            connect: {
              id: this.user.companyId,
            },
          },
          name: this.newStoreName,
          shareRate: Number(this.newShareRate),
          postalCode: "",
          operating: true,
          posId: String(this.newStorePosId),
        });
        if (store) {
          this.list.push(store);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("新しく店舗が作成されました！", "success");
        } else {
          this.showAlert(
            `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.isLoading = false;
      this.chanelType = "";
      this.newStoreServiceId = null;
      this.newStoreName = "";
      this.newShareRate = 100;
      this.dialogAdd = false;
    },
    async addStoreAccount() {
      this.isLoading = true;
      let postalCode = this.selectedStore?.postalCode?.replace("-", "");
      postalCode = postalCode?.slice(0, 3) + "-" + postalCode?.slice(3, 7);
      if (this.user && this.selectedStore) {
        const store: Store = { ...this.selectedStore };
        await AuthClient.createStore(this.user.companyId, store, AuthClient.generatePassword())
          .then(async (authStore) => {
            const updatedStore = await RadialApiClient.updateStore(store.id, {
              cognitoSub: authStore.User?.Username,
              name: this.selectedStore?.name,
              postalCode: postalCode,
              email: this.selectedStore?.email,
            });
            if (updatedStore) {
              this.showAlert("ご入力いただいたメールアドレス宛に認証メールを送信しました。", "success");
            }
          })
          .catch((error) => {
            if (error.code === "UsernameExistsException") {
              this.showAlert("このメールアドレスはすでに登録されております。", "error");
            } else {
              this.showAlert(
                `メールが送信できなかったようです。
              メールアドレスを再確認してください。`,
                "error"
              );
            }
          });
      }
      this.isLoading = false;
      this.selectedStore = null;
      this.dialogCreateStoreAcount = false;
    },
    cancelAddStore() {
      this.dialogAdd = false;
      this.chanelType = "";
      this.newStoreServiceId = null;
      this.newStoreName = "";
      this.newShareRate = 100;
    },
    async editStore() {
      if (!this.user) return;
      const postalCode = this.selectedStore?.postalCode?.replace("-", "") ?? null;
      if (this.selectedStore && postalCode) {
        this.selectedStore.postalCode = postalCode.slice(0, 3) + "-" + postalCode.slice(3, 7);
      }
      if (this.selectedStore && this.oldStore) {
        await AuthClient.updateStore(this.oldStore, this.selectedStore);
        const dto: UpdateStoreDto = {
          posId: this.selectedStore.posId ?? undefined,
          name: this.selectedStore.name,
          email: this.selectedStore.email,
          postalCode: this.selectedStore.postalCode,
          shareRate: Number(this.selectedStore.shareRate),
        };
        await RadialApiClient.updateStore(this.selectedStore.id, dto);
        const list = await RadialApiClient.listStores();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.selectedStore = null;
      this.dialogEdit = false;
    },
    async deleteStore() {
      this.isLoading = true;
      if (this.user && this.selectedStore) {
        if (this.selectedStore.cognitoSub) {
          // アカウント発行済みの店舗削除処理
          const members = await RadialApiClient.listMembers(this.selectedStore.cognitoSub);
          for (const member of members ?? []) {
            await RadialApiClient.deleteMember(member.id);
          }
        }
        await RadialApiClient.removeExcludedStoreId(this.selectedStore.id);
        const deletedStore = await RadialApiClient.deleteStore(this.selectedStore.id);
        if (deletedStore) {
          this.showAlert("無事に削除が完了しました！", "success");
          const list = await RadialApiClient.listStores();
          if (list) {
            this.list = list;
            this.listCount = this.list.length;
            this.sortList();
            this.updatePage(this.page);
          }
          // 同一serviceId(10, 50, 150)のストアがすべて削除されたときにcustomItegrationの設定も削除する
          if (!this.stores.some((store) => store.serviceId === this.selectedStore?.serviceId)) {
            const listCustomIntegration = await RadialApiClient.listCustomIntegration();
            const deleteCustomIntegration = listCustomIntegration?.find(
              (customIntegration) => customIntegration.serviceId === this.selectedStore?.serviceId
            );
            if (deleteCustomIntegration) {
              await RadialApiClient.deleteCustomIntegration(deleteCustomIntegration.id);
            }
          }
        } else {
          this.showAlert(
            `エラーが発生しております。
            入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.isLoading = false;
      this.selectedStore = null;
      this.dialogDelete = false;
    },
    showAlertOperating(store: Store) {
      this.selectedStore = { ...store };
      this.isOperating = store.operating;
      if (!this.isOperating) {
        this.alertTitle = "";
        this.alertMessage = `${store.name}を集計対象外に切り替えてよろしいですか？\n\n・各ページで、店舗に紐づく売上及び在庫のデータが表示されなくなります。\n・タグのロジックから店舗が除かれます。\n・店舗アカウントでのログインができなくなります。`;
        this.alertType = "error";
        this.showingAlert = true;
      } else {
        this.alertTitle = "";
        this.alertMessage = `${store.name}を集計対象に切り替えてよろしいですか？\n\n各ページで、店舗に紐づく売上及び在庫のデータが表示されるようになります。また、店舗アカウントでログインすることが可能になります。`;
        this.alertType = "info";
        this.showingAlert = true;
      }
    },
    async operatingCanceled() {
      const store = this.stores.find((store) => store.id === this.selectedStore?.id);
      if (store) {
        store.operating = !store.operating;
      }
      this.selectedStore = null;
      this.showingAlert = false;
    },
    async operatingChanged() {
      if (this.selectedStore) {
        const dto: UpdateStoreDto = {
          posId: this.selectedStore.posId ?? undefined,
          name: this.selectedStore.name,
          email: this.selectedStore.email,
          postalCode: this.selectedStore.postalCode,
          operating: this.selectedStore.operating,
          shareRate: Number(this.selectedStore.shareRate),
        };
        await RadialApiClient.updateStore(this.selectedStore.id, dto);
        if (!this.selectedStore.operating) {
          await RadialApiClient.addExcludedStoreId(this.selectedStore.id);
        } else if (this.selectedStore.operating) {
          await RadialApiClient.removeExcludedStoreId(this.selectedStore.id);
        }
      }
      this.showingAlert = false;
    },
    getAlertMessage(): string {
      if (this.selectedStore?.cognitoSub) {
        return `本当に${
          this.selectedStore?.name ?? ""
        }アカウントを削除してもよろしいですか？\nこの店舗に紐づく以下の全てのデータも合わせて削除されます。\n\n・メンバー情報\n・売上情報\n・アラート\n・店舗予算情報\n\nまた、削除後はログインができなくなります。`;
      } else {
        return `本当に${
          this.selectedStore?.name ?? ""
        }アカウントを削除してもよろしいですか？\nこの店舗に紐づく以下の全てのデータも合わせて削除されます。\n\n・メンバー情報\n・売上情報\n・アラート\n・店舗予算情報`;
      }
    },
    getServiceLogo(serviceId: number | null): string {
      switch (serviceId) {
        case 0:
          return require("@/assets/smaregi-logo.svg");
        case 1:
          return require("@/assets/shopify.svg");
        case 100:
          return require("@/assets/futureshop.svg");
        case 101:
          return require("@/assets/shopify.svg");
        case 103:
          return require("@/assets/base.svg");
        case 200:
          return require("@/assets/logizard.png");
        default:
          return require("@/assets/customServiceLogo.svg");
      }
    },
    getServiceName(serviceId: number | null): string | null {
      return this.services.find((item) => item.id === serviceId)?.name ?? null;
    },
    getServiceType(serviceId: number | null): string {
      const service = this.services.find((item) => item.id === serviceId);
      if (service?.manual && service.type === "POS") {
        return "カスタム店舗";
      } else if (service?.manual && service.type === "EC") {
        return "カスタムEC";
      } else if (!service?.manual && service?.type === "POS") {
        return "店舗";
      } else {
        return "EC";
      }
    },
    isServiceCustom(serviceId: number | null): boolean {
      return this.services.find((item) => item.id === serviceId)?.manual ?? false;
    },
    async listStores() {
      this.stores = (await RadialApiClient.listStores()) ?? [];
      if (this.stores) {
        this.stores.sort((a, b) => {
          if (a.serviceId && b.serviceId) {
            if (a.serviceId > b.serviceId) return 1;
            if (a.serviceId < b.serviceId) return -1;
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
          }
          return 0;
        });
      }
    },
    showIntegration() {
      const routeData = this.$router.resolve({
        name: "AdminIntegration",
      });
      window.open(routeData.href, "_blank");
    },
    listCustomServices() {
      this.newStoreServiceId = null;
      const customPosServices = this.services.filter((item) => {
        return item.manual === true && item.type === "POS";
      });
      const customEcServices = this.services.filter((item) => {
        return item.manual === true && item.type === "EC";
      });
      if (this.chanelType === "カスタム店舗") {
        this.customServices = customPosServices;
      } else {
        this.customServices = customEcServices;
      }
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.stores = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      this.company = await RadialApiClient.getCompany(this.user.companyId);
      const list = await RadialApiClient.listStores();
      if (list) {
        this.list = list;
        this.sortList();
        this.listCount = this.list.length;
        if (this.listCount < this.pageSize) {
          this.stores = this.list;
        } else {
          this.stores = this.list.slice(0, this.pageSize);
        }
      }
      this.services = (await RadialApiClient.listServices()) ?? [];
    }
  },
});
