
import { User } from "@/store/model";
import Vue from "vue";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";
import { AdminUser, Color, GetDetailImagesEntity, Product, Season, Size, Sku } from "@/api/entities";
import { Constant } from "@/store/constant";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import store from "@/store";
import * as lodash from "lodash";

export interface DataType {
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  isLoading: boolean;
  skuColors: MdMapSkuColor[];
  addSkuModal: boolean;
  creatingSkusEntries: CreatingSkusEntries;
  newSkus: CreatingSkuFromScratch[];
  productDetailImages: GetDetailImagesEntity;
  editingSkuColors: MdMapSkuColor[];
  editingSkuInfos: EditingSkuInfo[];
  deletingSkuIds: string[];
}

export interface EditingSkuInfo {
  // 必要な数だけAPIを叩くよう編集したSKUをストックするための型
  colorId: string;
  editingSkus: EditingSku[];
}

export interface MdMapSkuColor {
  // 商品詳細情報でのskuをカラーごとに表示するための型
  colorId: string;
  mdMapSkus: EditingSku[];
}

export interface CreatingSkusEntries {
  colors: Color[];
  sizes: Size[];
}

export interface CreatingSkuFromScratch {
  // sku新規登録のための型
  product: Product;
  janCode: string;
  colorId?: string | null;
  sizeId?: string | null;
  costString?: string | null; // 数字以外の文字を抜いて、3桁カンマ区切り処理のために文字列で情報を持っている
}

export interface EditingSku {
  // sku編集のための型
  id: string;
  colorId: string;
  sizeId: string;
  janCode: string;
  costString?: string | null; // 数字以外の文字を抜いて、3桁カンマ区切り処理のために文字列で情報を持っている
}

export default Vue.extend({
  name: "MdMapSkus",
  mixins: [mixin],
  components: {
    S3Image,
    Loading,
    ConfirmationDialog,
    FilterChipButton,
  },
  props: {
    selectedSeason: {
      type: Object as () => Season,
      default: null,
    },
    selectedProduct: {
      type: Object as () => Product,
      default: null,
    },
    colors: {
      type: Array as () => Color[],
      required: true,
    },
    sizes: {
      type: Array as () => Size[],
      required: true,
    },
    skus: {
      type: Array as () => Sku[],
      required: true,
    },
  },
  data(): DataType {
    return {
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      isLoading: false,
      skuColors: [],
      addSkuModal: false,
      creatingSkusEntries: {
        colors: [],
        sizes: [],
      },
      newSkus: [],
      productDetailImages: { s3KeyMain: "", s3KeySkus: [] },
      editingSkuColors: [],
      deletingSkuIds: [],
      editingSkuInfos: [],
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    storeOptions(): string[] {
      return Constant.storeOptions;
    },
    isSkusEditing(): boolean {
      const preSkus = JSON.stringify(this.skuColors);
      const postSkus = JSON.stringify(this.editingSkuColors);
      return preSkus !== postSkus;
    },
    isDisabledCreateSkuButton(): boolean {
      // janCodeとcostの数値判定も合わせて行う?
      if (this.newSkus.length > 0) {
        if (!this.newSkus.some((sku) => !sku.janCode || !sku.colorId || !sku.sizeId || !sku.costString)) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    isSkusEditing(isTabInfoEditing: boolean) {
      this.$emit("is-tab-info-changed", isTabInfoEditing);
    },
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    getErrorMessage(errorMessages: { status: number; color: string; size: string }[]): string {
      const overLappingErrorMessages = errorMessages.filter((message) => message.status === 600);
      const otherErrorMessages = errorMessages.filter((message) => message.status !== 600);
      let message = "";
      if (overLappingErrorMessages.length !== 0) {
        message = message.concat("以下の組み合わせでjanCodeが重複しているため、SKU情報を更新できませんでした。\n");
        for (const errorMessage of overLappingErrorMessages) {
          message = message.concat(`\n・${errorMessage.color} × ${errorMessage.size}`);
        }
        message = message.concat("\n\n");
      }
      if (otherErrorMessages.length !== 0) {
        message = message.concat("以下の組み合わせでエラーが発生したため、SKU情報を更新できませんでした。\n");
        for (const errorMessage of otherErrorMessages) {
          message = message.concat(`\n・${errorMessage.color} × ${errorMessage.size}`);
        }
        message = message.concat("\n\n");
      }
      message = message.concat("入力内容をご確認のうえ、もう一度お試しください。");
      return message;
    },
    inputNumber(sku: EditingSku | CreatingSkuFromScratch, numString: string | null | undefined) {
      const validNumString = Number(
        numString?.toString().replace(/[^0123456789]/g, "") // 数字以外の文字を消す
      ).toLocaleString();
      sku.costString = validNumString;
    },
    getCodeName(val: Color | Size): string {
      return `${val.code}: ${val.name}`;
    },
    getNewSkuCost(color: Color, size: Size): number {
      const cost = this.newSkus
        .find((sku) => sku.colorId === color.id && sku.sizeId === size.id)
        ?.costString?.replace(/[^0123456789]/g, "");
      if (this.selectedProduct?.price) {
        return Math.ceil((Number(cost) * 100) / this.selectedProduct.price);
      }
      return 0;
    },
    getColorDetail(id: string): Color | null {
      return this.colors.find((item) => item.id === id) ?? null;
    },
    getSizeDetail(id: string): Size | null {
      return this.sizes.find((item) => item.id === id) ?? null;
    },
    getMainImage(colorId: string): string {
      const skuIds = this.skus.filter((sku) => sku.colorId === colorId)?.map((sku) => sku.id);
      if (this.selectedProduct && skuIds) {
        for (const skuId of skuIds) {
          const s3Key =
            this.productDetailImages.s3KeySkus.find((item) => item.skuId === skuId)?.s3KeyWithId[0]?.s3Key ?? "";
          if (s3Key) {
            return s3Key;
          }
        }
      }
      return "";
    },
    stockEditingSkuInfo(sku: EditingSku) {
      const index = this.editingSkuInfos.findIndex((info) => info.colorId === sku.colorId);
      if (index > -1) {
        if (!this.editingSkuInfos[index].editingSkus.includes(sku)) {
          this.editingSkuInfos[index].editingSkus.push(sku);
        }
      } else {
        this.editingSkuInfos.push({
          colorId: sku.colorId,
          editingSkus: [sku],
        });
      }
    },
    stockDeleteSku(sku: EditingSku) {
      // SKUタブ内にて削除予定のSKUを非表示に
      const foundSkuColor = this.editingSkuColors.find((skuColor) => skuColor.colorId === sku.colorId);
      if (foundSkuColor && foundSkuColor.mdMapSkus) {
        foundSkuColor.mdMapSkus = foundSkuColor.mdMapSkus.filter((mapSku) => mapSku.id !== sku.id);
      }
      // 削除予定のSKUをストック
      this.deletingSkuIds.push(sku.id);
    },
    async saveSkus() {
      this.isLoading = true;
      // SKUの一括編集
      if (this.editingSkuInfos.length > 0) {
        let errorMessages: { status: number; color: string; size: string }[] = [];
        for (const colorInfo of this.editingSkuInfos) {
          for (const editingSku of colorInfo.editingSkus) {
            const cost = editingSku.costString ? Number(editingSku.costString.replace(/[^0123456789]/g, "")) : 0;
            await RadialApiClient.updateSku(editingSku.id, {
              janCode: editingSku.janCode,
              cost: cost,
            }).catch((error) => {
              const colorName = this.colors.find((color) => color.id === editingSku.colorId)?.name ?? "";
              const sizeName = this.sizes.find((size) => size.id === editingSku.sizeId)?.name ?? "";
              errorMessages.push({ status: error.response?.data.statusCode, color: colorName, size: sizeName ?? "" });
            });
          }
        }
        if (errorMessages.length === 0) {
          this.showAlert("SKU情報を更新しました！", "success");
          // 編集モード判定のため更新
          this.skuColors = lodash.cloneDeep(this.editingSkuColors);
          this.editingSkuInfos = [];
        } else {
          this.showAlert(this.getErrorMessage(errorMessages), "error");
        }
      }

      // SKUの一括削除
      if (this.deletingSkuIds.length > 0) {
        await Promise.all(this.deletingSkuIds.map((skuId) => RadialApiClient.deleteMdMapSku(skuId)));
        // 編集モード判定のため更新
        this.skuColors = lodash.cloneDeep(this.editingSkuColors);
        this.deletingSkuIds = [];
      }

      this.isLoading = false;
    },
    cancelSkusEdit() {
      this.editingSkuColors = lodash.cloneDeep(this.skuColors);
      this.editingSkuInfos = [];
      this.deletingSkuIds = [];
    },
    async addSku() {
      if (this.selectedProduct && this.selectedSeason) {
        // モーダル内情報初期化
        this.newSkus = [];
        this.creatingSkusEntries = {
          colors: [],
          sizes: [],
        };
        this.addSkuModal = true;
      }
    },
    clearCreatingSkusEntries(num: number) {
      this.newSkus = [];
      if (num === 0) {
        //  color
        this.creatingSkusEntries.colors = [];
      } else {
        // size
        this.creatingSkusEntries.sizes = [];
      }
    },
    async createSkusFromScratch() {
      this.isLoading = true;
      const promiseList: Promise<Sku | null | undefined>[] = [];
      this.newSkus.forEach((sku) => promiseList.push(this.createSkuFromScratch(sku)));
      await Promise.allSettled(promiseList).then((results) => {
        const successArray: Sku[] = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => (result as PromiseFulfilledResult<Sku>).value);
        const errorArray: any[] = results
          .filter((result) => result.status === "rejected")
          .map((result) => (result as PromiseRejectedResult).reason);
        if (errorArray.length === 0) {
          this.addSkuModal = false;
          this.isLoading = false;
          this.showAlert(`${results.length}種類のSKU情報を新たに作成しました！`, "success");
        } else {
          let errorMessage: string = "";
          if (successArray.length > 0) {
            errorMessage = errorMessage + "以下の組み合わせのSKU情報を新たに作成しました！\n\n";
            successArray.forEach((success, index) => {
              const color = this.colors.find((color) => color.id === success.colorId);
              const size = this.sizes.find((size) => size.id === success.sizeId);
              if (color && size) {
                errorMessage = errorMessage + `・${color.name} × ${size.name}\n`;
              }
            });
            errorMessage = errorMessage + "\n";
          }
          const janCodeErrors: string[] = [];
          const colorSizeErrors: string[] = [];
          const otherErrors: string[] = [];
          errorArray.forEach((error) => {
            const color = this.colors.find(
              (color) => color.id === JSON.parse(error.response.config.data).color.connect.id
            );
            const size = this.sizes.find((size) => size.id === JSON.parse(error.response.config.data).size.connect.id);
            if (color && size) {
              if (error.response.data.statusCode === 600) {
                janCodeErrors.push(`${color.name} × ${size.name}`);
              } else if (error.response.data.statusCode === 601) {
                colorSizeErrors.push(`${color.name} × ${size.name}`);
              } else {
                otherErrors.push(`${color.name} × ${size.name}`);
              }
            }
          });
          if (janCodeErrors.length > 0) {
            errorMessage =
              errorMessage + "以下の組み合わせでjanCodeが重複しているため、SKU情報を更新できませんでした。\n\n";
            janCodeErrors.forEach((item) => {
              errorMessage = errorMessage + `・${item}\n`;
            });
            errorMessage = errorMessage + "\n";
          }
          if (colorSizeErrors.length > 0) {
            errorMessage =
              errorMessage +
              "以下の組み合わせでカラーコード、サイズコードの組み合わせが重複しているため、SKU情報を更新できませんでした。\n\n";
            colorSizeErrors.forEach((item) => {
              errorMessage = errorMessage + `・${item}\n`;
            });
            errorMessage = errorMessage + "\n";
          }
          if (otherErrors.length > 0) {
            errorMessage = errorMessage + "以下の組み合わせでエラーが発生したため、SKU情報を更新できませんでした。\n\n";
            otherErrors.forEach((item) => {
              errorMessage = errorMessage + `・${item}\n`;
            });
            errorMessage = errorMessage + "\n";
          }
          errorMessage = errorMessage + "入力内容をご確認のうえ、もう一度お試しください。";
          this.addSkuModal = false;
          this.isLoading = false;
          this.showAlert(errorMessage, "error");
        }
      });
      // 作成したSKUを加えて並び替え
      this.sortSkuColors();
      // SKUタブ内のSKU更新
      this.editingSkuColors = lodash.cloneDeep(this.skuColors);
      // スタッツ及びselectedProductの更新
      this.$emit(
        "update-stats",
        this.selectedProduct.id,
        this.selectedProduct.seasonId,
        this.selectedProduct.brandId,
        this.selectedProduct.categoryId
      );
    },
    async createSkuFromScratch(sku: CreatingSkuFromScratch) {
      if (sku.colorId && sku.sizeId && this.selectedProduct) {
        const cost = sku.costString ? Number(sku.costString.replace(/[^0123456789]/g, "")) : 0;
        const result = await RadialApiClient.createSku({
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
          product: {
            connect: {
              id: this.selectedProduct.id,
            },
          },
          janCode: String(sku.janCode),
          color: {
            connect: {
              id: sku.colorId,
            },
          },
          size: {
            connect: {
              id: sku.sizeId,
            },
          },
          cost,
        });
        if (result) {
          // 編集モード判定のため更新
          const index = this.skuColors.findIndex((item) => item.colorId === sku.colorId);
          if (index > -1) {
            this.skuColors[index].mdMapSkus.push({
              id: result.id,
              colorId: result.colorId,
              sizeId: result.sizeId,
              janCode: result.janCode ?? "",
              costString: result.cost?.toLocaleString(),
            });
          } else {
            this.skuColors.push({
              colorId: sku.colorId,
              mdMapSkus: [
                {
                  id: result.id,
                  colorId: result.colorId,
                  sizeId: result.sizeId,
                  janCode: result.janCode ?? "",
                  costString: result.cost?.toLocaleString(),
                },
              ],
            });
          }
        }
        // 作成したSKUをまとめてアラート表示
        return result;
      }
    },
    async changeCreatingSkusEntries(num: number, id: string) {
      if (num === 0) {
        // color
        if (this.creatingSkusEntries.colors.some((color) => color.id === id)) {
          // colorが追加された場合
          const length = this.newSkus.length + this.creatingSkusEntries.sizes.length;
          const janCodes: string[] = (await RadialApiClient.getNewJanCodes(length)) ?? []; // 作成しようとしているSKUの個数分のjanCode
          const newJanCodes = janCodes.filter((code) => !this.newSkus.some((sku) => sku.janCode === code)); // すでに割り当てられているjanCodeは省く
          this.creatingSkusEntries.sizes.forEach((size, index) => {
            if (this.selectedProduct && this.selectedSeason) {
              if (!this.skus.some((sku) => sku.colorId === id && sku.sizeId === size.id)) {
                this.newSkus.push({
                  product: this.selectedProduct,
                  colorId: id,
                  sizeId: size.id,
                  janCode: newJanCodes[index],
                  // 原価の初期値にシーズンの目標原価率と定価を掛け合わせたものをセット
                  costString: this.selectedProduct.price
                    ? Math.floor((this.selectedProduct.price * this.selectedSeason.costRateTarget) / 100).toString()
                    : "0",
                });
              }
            }
          });
        } else {
          // colorが解除された場合
          this.newSkus = this.newSkus.filter((sku) => sku.colorId !== id);
        }
      } else {
        // size
        if (this.creatingSkusEntries.sizes.some((size) => size.id === id)) {
          // sizeが追加された場合
          const length = this.newSkus.length + this.creatingSkusEntries.colors.length;
          const janCodes: string[] = (await RadialApiClient.getNewJanCodes(length)) ?? []; // 作成しようとしているSKUの個数分のjanCode
          const newJanCodes = janCodes.filter((code) => !this.newSkus.some((sku) => sku.janCode === code)); // すでに割り当てられているjanCodeは省く
          this.creatingSkusEntries.colors.forEach((color, index) => {
            if (this.selectedProduct && this.selectedSeason) {
              if (!this.skus.some((sku) => sku.colorId === color.id && sku.sizeId === id)) {
                this.newSkus.push({
                  product: this.selectedProduct,
                  colorId: color.id,
                  sizeId: id,
                  janCode: newJanCodes[index],
                  // 原価の初期値にシーズンの目標原価率と定価を掛け合わせたものをセット
                  costString: this.selectedProduct.price
                    ? Math.floor((this.selectedProduct.price * this.selectedSeason.costRateTarget) / 100).toString()
                    : String(0),
                });
              }
            }
          });
        } else {
          // sizeが解除された場合
          this.newSkus = this.newSkus.filter((sku) => sku.sizeId !== id);
        }
      }
    },
    removeNewSku(colorId: string, sizeId: string) {
      this.newSkus = this.newSkus.filter((sku) => sku.colorId !== colorId || sku.sizeId !== sizeId);
      if (this.newSkus.filter((sku) => sku.colorId === colorId).length === 0) {
        // 消したことでそのカラーのskuが一つもなくなったときにチェックボックスを外す
        this.creatingSkusEntries.colors = this.creatingSkusEntries.colors.filter((color) => color.id !== colorId);
      }
      if (this.newSkus.filter((sku) => sku.sizeId === sizeId).length === 0) {
        // 消したことでそのサイズのskuが一つもなくなったときにチェックボックスを外す
        this.creatingSkusEntries.sizes = this.creatingSkusEntries.sizes.filter((size) => size.id !== sizeId);
      }
    },
    sortSkuColors() {
      // colorCode順にソート
      this.skuColors.sort((a, b) => {
        const aColorCode = this.getColorDetail(a.mdMapSkus[0].colorId)?.code ?? "";
        const bColorCode = this.getColorDetail(b.mdMapSkus[0].colorId)?.code ?? "";
        if (aColorCode > bColorCode) {
          return 1;
        } else {
          return -1;
        }
      });
      // sizeCode順にソート
      this.skuColors.forEach((item) => {
        item.mdMapSkus.sort((a, b) => {
          const aSizeCode = this.getSizeDetail(a.sizeId)?.code ?? "";
          const bSizeCode = this.getSizeDetail(b.sizeId)?.code ?? "";
          if (aSizeCode > bSizeCode) {
            return 1;
          } else {
            return -1;
          }
        });
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    const skuColorEntities: MdMapSkuColor[] = [];
    for (const sku of this.skus) {
      const index = skuColorEntities.findIndex((item) => item.colorId === sku.colorId);
      if (index > -1) {
        skuColorEntities[index].mdMapSkus.push({
          id: sku.id,
          colorId: sku.colorId,
          sizeId: sku.sizeId,
          janCode: sku.janCode ?? "",
          costString: sku.cost?.toLocaleString(),
        });
      } else {
        skuColorEntities.push({
          colorId: sku.colorId,
          mdMapSkus: [
            {
              id: sku.id,
              colorId: sku.colorId,
              sizeId: sku.sizeId,
              janCode: sku.janCode ?? "",
              costString: sku.cost?.toLocaleString(),
            },
          ],
        });
      }
    }
    this.skuColors = skuColorEntities;
    this.sortSkuColors();
    this.editingSkuColors = lodash.cloneDeep(this.skuColors);
    const productDetailImages = await RadialApiClient.getProductDetailImages(this.selectedProduct.id);
    if (productDetailImages) {
      this.productDetailImages = productDetailImages;
    }
    this.isLoading = false;
  },
});
