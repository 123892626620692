
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import RadialApiClient from "@/api/RadialApiClient";
import S3Image from "@/components/aws/S3Image.vue";
import dayjs from "dayjs";
import ProductDetailTrend from "@/views/product/ProductDetailTrend.vue";
import ProductDetailComment from "@/views/product/ProductDetailComment.vue";
import ProductDetailBasicInfo from "@/views/product/ProductDetailBasicInfo.vue";
import ProductDetailSku from "@/views/product/ProductDetailSku.vue";
import ProductDetailAlertSetting from "@/views/product/ProductDetailAlertSetting.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import { Constant } from "@/store/constant";
import {
  Member,
  Store,
  Comment,
  Warehouse,
  CustomTagMaster,
  CustomTag,
  ProductDetailBasicInfoEntity,
  SkuWithColorSize,
  ProductDetailSalesDigestRateChartEntity,
  Color,
  Size,
  AdminUser,
  ProductDetailCommentEntity,
} from "@/api/entities";
import { User } from "@/store/model";
import {
  UpdateProductDto,
  CreateCommentDto,
  UpdateAlertDto,
  CreateCustomTagMasterDto,
  CreateCustomTagDto,
} from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import mixin from "@/mixin/mixin";

export type ProductDetailTab = "TREND" | "COMMENT" | "PRODUCT" | "SKU" | "ALERT";

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  statuses: string[];
  product: ProductDetailBasicInfoEntity | null;
  images: string[];
  dialogComment: boolean;
  newComment: Comment | null;
  members: Member[];
  dialogCommentThanks: boolean;
  clickedImage: string | null;
  customerCommentServedAtMenu: boolean;
  storeCommentServedAtMenu: boolean;
  tab: string;
  commentTab: string;
  companyStores: Store[];
  isLoading: boolean;
  isLandScape: boolean;
  warehouses: Warehouse[];
  today: string;
  stores: Store[];
  store: Store | null;
  storeId: string;
  colors: Color[];
  sizes: Size[];
  dialogName: boolean;
  dialogDates: boolean;
  dialogPrice: boolean;
  productSalesStartedAtMenu: boolean;
  productsalesEndedAtMenu: boolean;
  productPriceUpdatedAtMenu: boolean;
  isVisible: number;
  selectedCustomTagMasters: CustomTagMaster[];
  selectedCustomTags: CustomTag[];
  inputCustomTagName: string;
  customTagMasters: CustomTagMaster[];
  deletingCustomTagMaster: CustomTagMaster | null;
  showDeleteCustomTagMasterDialog: boolean;
  productDetailSalesDigestRateChartEntity: ProductDetailSalesDigestRateChartEntity;
  productDetailSalesDigestRateChartPeriod: number;
  isTablet: boolean;
  adminUsers: AdminUser[];
  productDetailTab: ProductDetailTab;
  comments: ProductDetailCommentEntity[];
}

export default Vue.extend({
  name: "ProductDetail",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    S3Image,
    ProductDetailTrend,
    ProductDetailComment,
    ProductDetailBasicInfo,
    ProductDetailSku,
    ProductDetailAlertSetting,
    ElementDatePicker,
    ConfirmationDialog,
    Loading,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      statuses: ["販売中", "販売終了", "未販売"],
      product: null,
      images: [],
      dialogComment: false,
      newComment: null,
      members: [],
      dialogCommentThanks: false,
      clickedImage: null,
      customerCommentServedAtMenu: false,
      storeCommentServedAtMenu: false,
      tab: "product-detail-trend",
      commentTab: "customer-comment",
      companyStores: [],
      isLoading: true,
      isLandScape: false,
      warehouses: [],
      today: dayjs().format("YYYY-MM-DD"),
      stores: [],
      store: null,
      storeId: "",
      colors: [],
      sizes: [],
      dialogName: false,
      dialogDates: false,
      dialogPrice: false,
      productSalesStartedAtMenu: false,
      productsalesEndedAtMenu: false,
      productPriceUpdatedAtMenu: false,
      isVisible: 0,
      selectedCustomTagMasters: [],
      selectedCustomTags: [],
      inputCustomTagName: "",
      customTagMasters: [],
      deletingCustomTagMaster: null,
      showDeleteCustomTagMasterDialog: false,
      productDetailSalesDigestRateChartEntity: { productId: "", productData: null, skuData: [] },
      productDetailSalesDigestRateChartPeriod: 3,
      isTablet: false,
      adminUsers: [],
      productDetailTab: "TREND",
      comments: [],
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    nonSelectedCustomTagMasters(): CustomTagMaster[] {
      return this.customTagMasters.filter(
        (item) => !this.selectedCustomTags.some((tag) => tag.customTagMasterId === item.id)
      );
    },
    filteredCustomTagMasters(): CustomTagMaster[] {
      return this.nonSelectedCustomTagMasters.filter((item) => ~item.name.indexOf(this.inputCustomTagName));
    },
    notSameCustomTag(): boolean {
      return !this.customTagMasters.some((item) => item.name === this.inputCustomTagName);
    },
    percentOff(): string {
      if (this.product) {
        if (this.product.salesPrice && this.product.price && this.product.salesPrice < this.product.price) {
          const off = 100 - (this.product.salesPrice / this.product.price) * 100;
          return `(${Math.ceil(off)}% OFF)`;
        }
      }
      return "";
    },
    percent(): string {
      if (this.product) {
        if (this.product.salesPrice && this.product.price && this.product.salesPrice < this.product.price) {
          const off = 100 - (this.product.salesPrice / this.product.price) * 100;
          return `${Math.ceil(off)}`;
        }
      }
      return "0";
    },
    isCustomerCommentFilled(): boolean {
      if (this.newComment) {
        return (
          this.newComment.content !== "" &&
          this.newComment.content.length < 256 &&
          this.newComment.target !== 0b00000 &&
          this.newComment.memberId !== null
        );
      } else {
        return false;
      }
    },
    isStoreCommentFilled(): boolean {
      if (this.newComment) {
        return this.newComment.content !== "" && this.newComment.content.length < 256;
      } else {
        return false;
      }
    },
    imagePairs(): (string | null)[][] {
      const pairs: (string | null)[][] = [];
      if (this.images.length > 10) {
        const halfIndex: number = Math.ceil(this.images.length / 2);
        const firstHalfImages = this.images.slice(0, halfIndex);
        const latterHalfImages = this.images.slice(halfIndex);
        firstHalfImages.forEach((image, index) => {
          pairs[index] = [image, null];
        });
        latterHalfImages.forEach((image, index) => {
          pairs[index][1] = image;
        });
      } else {
        const firstHalfImages = this.images.slice(0, 5);
        const latterHalfImages = this.images.slice(5);
        firstHalfImages.forEach((image, index) => {
          pairs[index] = [image, null];
        });
        latterHalfImages.forEach((image, index) => {
          pairs[index][1] = image;
        });
      }
      return pairs;
    },
    deleteCustomTagMasterAlertMessage(): string {
      if (this.deletingCustomTagMaster) {
        return `本当に削除してもよろしいですか？
              他の品番に紐づけられている${this.deletingCustomTagMaster.name}タグも合わせて削除されます。`;
      } else {
        return "";
      }
    },
  },
  methods: {
    // 子コンポーネントからのデータ更新
    updatePeriod(selectedPeriod: number) {
      this.productDetailSalesDigestRateChartPeriod = selectedPeriod;
    },
    updateGraph(chartInfo: ProductDetailSalesDigestRateChartEntity) {
      this.productDetailSalesDigestRateChartEntity = chartInfo;
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async selectCustomTag(tag: CustomTagMaster) {
      this.isLoading = true;
      if (this.product) {
        const dto: CreateCustomTagDto = {
          creatorAdminUserId: this.user?.sub !== this.user?.companyId ? this.user?.sub : null,
          customTagMaster: {
            connect: {
              id: tag.id,
            },
          },
          product: {
            connect: {
              id: this.product.id,
            },
          },
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
        };
        await RadialApiClient.createCustomTag(dto);
        const tags = await RadialApiClient.listProductCustomTags(this.product.itemNumber);
        if (tags) {
          this.selectedCustomTags = tags;
          this.inputCustomTagName = "";
        }
      }
      this.isLoading = false;
    },
    async removeSelectedCustomTag(tag: CustomTag) {
      this.isLoading = true;
      await RadialApiClient.deleteCustomTag(tag.id.toString());
      if (this.product) {
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(this.product.itemNumber)) ?? [];
      }
      this.isLoading = false;
    },
    async createCustomTagMaster() {
      this.isLoading = true;
      const masterDto: CreateCustomTagMasterDto = {
        name: this.inputCustomTagName,
        company: {
          connect: {
            id: this.user?.companyId,
          },
        },
      };
      const newTag = await RadialApiClient.createCustomTagMaster(masterDto);
      if (this.product && newTag) {
        const dto: CreateCustomTagDto = {
          creatorAdminUserId: this.user?.sub !== this.user?.companyId ? this.user?.sub : null,
          customTagMaster: {
            connect: {
              id: newTag.id,
            },
          },
          product: {
            connect: {
              id: this.product.id,
            },
          },
          company: {
            connect: {
              id: this.user?.companyId,
            },
          },
        };
        await RadialApiClient.createCustomTag(dto);
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(this.product.itemNumber)) ?? [];
      }
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      this.inputCustomTagName = "";
      this.isLoading = false;
    },
    selectDeletingCustomTagMaster(item: CustomTagMaster) {
      this.deletingCustomTagMaster = item;
      this.showDeleteCustomTagMasterDialog = true;
    },
    async deleteCustomTagMaster() {
      this.isLoading = true;
      if (this.deletingCustomTagMaster) {
        await RadialApiClient.deleteCustomTagMaster(this.deletingCustomTagMaster.id);
      }
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      if (this.product) {
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(this.product.itemNumber)) ?? [];
      }
      this.showDeleteCustomTagMasterDialog = false;
      this.isLoading = false;
    },
    async updateProduct(event: { productId: string; dto: UpdateProductDto }) {
      if (event.productId && event.dto) {
        const productId = this.$route.params.productId;
        await RadialApiClient.updateProductDetailBasicInfo(event.productId, event.dto)
          .then(async () => {
            this.product = await RadialApiClient.getProductDetailBasicInfo(productId);
          })
          .catch((error) => {
            this.showAlert(error.response.data.message, "error");
          });
      }
    },
    async getProduct(event: { productId: string }) {
      if (event.productId) {
        this.product = await RadialApiClient.getProductDetailBasicInfo(event.productId);
      }
    },
    formatDate(date: Date | null | undefined): string {
      if (date) {
        return dayjs(date).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },
    showDialogName() {
      this.dialogName = true;
    },
    showDialogDates() {
      this.dialogDates = true;
    },
    showDialogPrice() {
      this.dialogPrice = true;
    },
    async hideEditDialog() {
      this.dialogName = false;
      this.dialogDates = false;
      this.dialogPrice = false;
      if (this.product) {
        this.product = await RadialApiClient.getProductDetailBasicInfo(this.product.id);
      }
    },
    async updateProductBasicInfo() {
      this.isLoading = true;
      // 商品詳細ページの左側の基本情報更新用メソッド
      this.dialogName = false;
      this.dialogDates = false;
      this.dialogPrice = false;
      if (this.product) {
        const updatedProduct = await RadialApiClient.updateProduct(this.product.id, {
          name: this.product.name,
          salesStartedAt: this.product.salesStartedAt ? dayjs(this.product.salesStartedAt).toDate() : null,
          salesEndedAt: this.product.salesEndedAt ? dayjs(this.product.salesEndedAt).toDate() : null,
          price: this.product.price,
          salesPrice: this.product.salesPrice,
          salesPriceUpdatedAt: dayjs(this.product.salesPriceUpdatedAt).toDate(),
        });
        if (updatedProduct) {
          this.showAlert("無事に更新が完了しました！", "success");
        } else {
          this.showAlert("エラーが発生しているようです。時間おいて再度お試しください。", "error");
        }
      }
      this.isLoading = false;
    },
    visible(index: number) {
      this.isVisible = index;
    },
    showCreateComment() {
      if (this.members.length > 0) {
        this.newComment = {
          id: "",
          companyId: "",
          productId: "",
          skuId: "",
          memberId: this.members[0].id,
          storeId: this.store?.id ?? null,
          age: "10代",
          gender: "女性",
          figure: "やせ",
          height: "~150",
          content: "",
          positivity: 0,
          servedAt: new Date(),
          target: 0b00000,
          category: 0,
          createdAt: new Date(),
          updatedAt: new Date(),
        };
        this.dialogComment = true;
      } else {
        this.showAlert(
          "コメント投稿を行うために、まずはメンバーを作成していただく必要があります。\n設定ページより追加しましょう！",
          "error"
        );
      }
    },
    async createComment(index: number) {
      if (this.product && this.newComment?.memberId) {
        const commentServedAt = this.newComment.servedAt;
        if (this.newComment.skuId === "null" && this.store) {
          const skuDto: CreateCommentDto = {
            company: {
              connect: {
                id: this.user?.companyId,
              },
            },
            product: {
              connect: {
                id: this.product.id,
              },
            },
            member: {
              connect: {
                id: this.newComment.memberId,
              },
            },
            store: {
              connect: {
                id: this.store.id,
              },
            },
            age: this.newComment.age,
            figure: this.newComment.figure,
            height: this.newComment.height,
            content: this.newComment.content,
            gender: this.newComment.gender,
            positivity: this.newComment.positivity,
            servedAt: commentServedAt,
            target: this.newComment.target,
            category: index,
          };
          await RadialApiClient.createComment(skuDto);
        } else if (this.newComment.skuId && this.store) {
          const dto: CreateCommentDto = {
            company: {
              connect: {
                id: this.user?.companyId,
              },
            },
            product: {
              connect: {
                id: this.product.id,
              },
            },
            sku: {
              connect: {
                id: this.newComment.skuId,
              },
            },
            member: {
              connect: {
                id: this.newComment.memberId,
              },
            },
            store: {
              connect: {
                id: this.store.id,
              },
            },
            age: this.newComment.age,
            figure: this.newComment.figure,
            height: this.newComment.height,
            content: this.newComment.content,
            gender: this.newComment.gender,
            positivity: this.newComment.positivity,
            servedAt: commentServedAt,
            target: this.newComment.target,
            category: index,
          };
          await RadialApiClient.createComment(dto);
        }
      }
      this.dialogComment = false;
      this.dialogCommentThanks = true;
    },
    createCommentCanceled() {
      this.dialogComment = false;
    },
    getSkuName(sku: SkuWithColorSize): string {
      if (!sku.size.name) {
        sku.size.name = "";
      }
      if (!sku.color.name) {
        sku.color.name = "";
      }
      return `${sku.color.name} ${sku.size.name}`;
    },
    async clickedAddCustomTagButton() {
      this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
    },
    changeRotation() {
      if (screen.orientation.type.includes("landscape")) {
        this.isLandScape = true;
      } else {
        this.isLandScape = false;
      }
    },
    showMdMap() {
      if (this.product && this.product.seasonId && this.product.id) {
        const routeData = this.$router.resolve({
          name: "AdminMdMap",
          params: {
            seasonId: this.product.seasonId.toString(),
            yearMonth: dayjs(this.product.salesStartedAt).format("YY/M"), // MDMapページの月選択とフォーマットをあわせるため「YY/M」
            productId: this.product.id,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    getIconS3Key(tag: CustomTag): string {
      if (tag.creatorAdminUserId) {
        return this.adminUsers.find((adminUser) => adminUser.id === tag.creatorAdminUserId)?.icon ?? "";
      } else {
        return store.state.company?.icon ?? "";
      }
    },
  },
  async mounted() {
    // タブレットを使っているのか判断
    this.isTablet = Constant.isUsingTablet();
    if (this.isTablet) {
      // タブレットが横向きかどうか判断
      this.isLandScape = screen.orientation.type.includes("landscape") ? true : false;
      // タブレットが回転した場合のコールバック登録
      window.addEventListener("orientationchange", this.changeRotation);
    }

    //描画に必要な情報を取得
    const productId = this.$route.params.productId;
    await Promise.all([
      (async () => {
        if (this.user && !this.isAdmin) {
          this.members = (await RadialApiClient.listMembers(this.user.sub)) ?? [];
        }
      })(),
      (async () => {
        this.product = await RadialApiClient.getProductDetailBasicInfo(productId);
        // get product images
        const productDetailImages = await RadialApiClient.getProductDetailImages(productId);
        if (productDetailImages) {
          this.images = Array.from(
            new Set(
              [
                productDetailImages.s3KeyMain,
                ...productDetailImages.s3KeySkus
                  .map((item) => item.s3KeyWithId)
                  .flat(1)
                  .map((s3KeyWithId) => s3KeyWithId.s3Key),
              ].map((s3Key) => (s3Key === "" ? "NotFound" : s3Key))
            )
          );
          this.clickedImage = this.images[0];
        }
        this.selectedCustomTags = (await RadialApiClient.listProductCustomTags(this.product?.itemNumber)) ?? [];
      })(),
      (async () => {
        const productDetailSalesDigestRateChartEntity = await RadialApiClient.getProductDetailTrendChartsValue(
          productId,
          1,
          10,
          this.productDetailSalesDigestRateChartPeriod
        );
        if (productDetailSalesDigestRateChartEntity) {
          this.productDetailSalesDigestRateChartEntity = productDetailSalesDigestRateChartEntity;
        }
      })(),
      (async () => {
        this.customTagMasters = (await RadialApiClient.listCustomTagMasters()) ?? [];
      })(),
      (async () => {
        this.stores = (await RadialApiClient.listStores()) ?? [];
        this.store = this.stores.find((store) => store.email === this.user?.email) ?? null;
      })(),
      (async () => {
        this.warehouses = (await RadialApiClient.listWarehouses()) ?? [];
      })(),
      (async () => {
        this.colors = (await RadialApiClient.listColors()) ?? [];
      })(),
      (async () => {
        this.sizes = (await RadialApiClient.listSizes()) ?? [];
      })(),
      (async () => {
        this.adminUsers = (await RadialApiClient.listAdminUsers()) ?? [];
      })(),
    ]);

    this.comments = (await RadialApiClient.listProductDetailComments(productId)) ?? [];
    this.comments = this.comments.sort((a, b) => {
      if (a.servedAt < b.servedAt) {
        return 1;
      } else {
        return -1;
      }
    });

    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/product-detail",
        page_title: "Admin商品詳細画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/product-detail",
        page_title: "Staff商品詳細画面",
      });
    }
    this.isLoading = false;
  },
});
