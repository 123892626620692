
/*
  [FilterChipButton]
    <必須>
    - v-model = チェックされたリスト
    - :options = チェックされていないものも含めたすべてのリスト
    - :name = フィルター名
    
    <任意>
    - :getButtonName = フィルターボタンの名前をv-modelの0番目から決める関数
    - :getName = チェックボックスの名前を各optionから決める関数
    - :getData = v-modelに追加するデータを各optionから決める関数
    - :color = フィルターボタンの色を指定する(green, blue, redから選択可能)
    - @change = いずれかのチェックボックスの状態が変化したときに呼ばれるイベント
    - @clear = 条件をクリア時に呼ばれるイベント
*/
import Vue from "vue";
import Checkbox from "./Checkbox.vue";

export default Vue.extend({
  name: "filter-chip-button",
  components: { Checkbox },
  props: {
    value: {
      type: Array as () => any[],
      required: true,
    },
    options: {
      type: Array as () => any[],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    getButtonName: {
      type: Function,
      required: false,
      default: (val: any) => val,
    },
    getName: {
      type: Function,
      required: false,
      default: (val: any) => val,
    },
    getData: {
      type: Function,
      required: false,
      default: (val: any) => val,
    },
    color: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    model: {
      get(): any[] {
        return this.value;
      },
      set(newValue: any[]) {
        this.$emit("input", newValue);
      },
    },
  },
});
