// import AppSyncClient from "@/api/AppSyncClient";
import AuthClient from "@/api/AuthClient";
import S3Client from "@/api/S3Client";
import { User, SearchEntries, CommentFilter, Counts, DashboardSaveInfo } from "@/store/model";
import localforage from "localforage"; // LocalStorage/IndexedDB/WebSQLの切り替え
import pako from "pako"; // zip圧縮
import { Constant } from "./constant";

const RADIAL_USER = "RADIAL_USER";
const RADIAL_SEARCH_ENTRIES = "RADIAL_SEARCH_ENTRIES";
const RADIAL_IMAGE_KEYS = "RADIAL_IMAGE_KEYS";
const RADIAL_VERSION = "RADIAL_VERSION";
const RADIAL_COMMENT_FILTER = "RADIAL_COMMENT_FILTER";
const RADIAL_COUNTS = "RADIAL_COUNTS";
const RADIAL_SALE_STOCK_COUNTS = "RADIAL_SALE_STOCK_COUNTS";
const RADIAL_DASHBOARD = "RADIAL_DASHBOARD";
/*
  更新頻度を設定する辞書（単位：h）
  0の場合は毎回更新する
  -1の場合は更新しない
*/
const updateDuration: { [name: string]: number } = {
  RADIAL_USER: -1,
  RADIAL_SEARCH_ENTRIES: -1,
  RADIAL_IMAGE_KEYS: -1,
  RADIAL_VERSION: -1,
  RADIAL_COMMENT_FILTER: -1,
  RADIAL_FILE_NAME: -1,
};

/*
  LocalDataServiceでは，localforageというライブラリを用いて
  IndexedDB/WebSQLを利用し最大限の読み書き速度でローカルにデータを保存し
  zlibによるzip圧縮で最高効率でストレージを利用する．

  [Chromeにおける各DBの容量]
  LocalStorage: 10MB
  IndexedDB: 端末の空き容量の10%
  WebSQL: 端末の空き容量の10%
*/
class LocalDataService {
  // localforageに保存されたzip圧縮データを展開してUTF-8で取得する
  public static async getItem(key: string, onUpdate: (() => any) | null): Promise<any> {
    if (updateDuration[key] >= 0 && onUpdate) {
      const updatedAt = (await localforage.getItem(`${key}_UPDATED_AT`)) as Date;
      const now = new Date() as Date;
      if (!updatedAt) {
        return await onUpdate();
      } else if (now.getTime() - updatedAt.getTime() > 3600 * 1000 * updateDuration[key]) {
        return await onUpdate();
      }
    }
    try {
      const data = (await localforage.getItem(key)) as Uint8Array;
      const inflated = pako.inflate(data);
      return JSON.parse(new TextDecoder("utf-8").decode(inflated));
    } catch {
      return onUpdate ? await onUpdate() : null;
    }
  }

  // データをUTF-8のJSONにエンコード後zipで圧縮してlocalforageに保存する
  public static async setItem(key: string, value: any | null) {
    if (value) {
      const data = new TextEncoder().encode(JSON.stringify(value));
      const deflated = pako.deflate(data, { level: 6 });
      await localforage.setItem(key, deflated);
      await localforage.setItem(`${key}_UPDATED_AT`, new Date());
    } else {
      await localforage.removeItem(key);
      await localforage.removeItem(`${key}_UPDATED_AT`);
    }
  }

  public static async getUser(): Promise<User | null> {
    return await this.getItem(RADIAL_USER, async () => {
      return await this.updateUser();
    });
  }

  public static async setUser(user: User | null) {
    await this.setItem(RADIAL_USER, user);
  }

  public static async updateUser(): Promise<User | null> {
    const user = await AuthClient.getUserInfo();
    this.setUser(user);
    return user;
  }

  public static async getSearchEntries(): Promise<SearchEntries | null> {
    return await this.getItem(RADIAL_SEARCH_ENTRIES, null);
  }

  public static async setSearchEntries(entries: SearchEntries | null) {
    await this.setItem(RADIAL_SEARCH_ENTRIES, entries);
  }

  public static async getVersion(): Promise<string | null> {
    return await this.getItem(RADIAL_VERSION, null);
  }

  public static async setVersion(version: string | null) {
    await this.setItem(RADIAL_VERSION, version);
  }

  public static async getCommentFilter(): Promise<CommentFilter | null> {
    return await this.getItem(RADIAL_COMMENT_FILTER, null);
  }

  public static async setCommentFilter(entries: CommentFilter | null) {
    await this.setItem(RADIAL_COMMENT_FILTER, entries);
  }

  public static async clearSearchEntries() {
    const clearedEntries: SearchEntries = {
      itemNumber: "",
      brands: [],
      seasons: [],
      categories: [],
      attribute1: [],
      attribute2: [],
      attribute3: [],
      salesStatus: [],
      salesPrice: [-Infinity, Infinity],
      stockPrice: [-Infinity, Infinity],
      salesCount: [-Infinity, Infinity],
      stockCount: [-Infinity, Infinity],
      salePrice: [-Infinity, Infinity],
      alerts: [],
      customTags: [],
      stores: [],
      statuses: []
    };
    await this.setSearchEntries(clearedEntries);
  }

  public static async getImageKeys(companyId: string): Promise<string[]> {
    return await this.getItem(RADIAL_IMAGE_KEYS, async () => {
      return await this.updateImageKeys(companyId);
    });
  }

  public static async setImageKeys(imageKeys: string[]) {
    await this.setItem(RADIAL_IMAGE_KEYS, imageKeys);
  }

  public static async updateImageKeys(companyId: string): Promise<string[]> {
    const imageKeys = await S3Client.list(companyId);
    this.setImageKeys(imageKeys);
    return imageKeys;
  }

  public static async clear() {
    await localforage.clear();
    await this.setVersion(Constant.version);
  }
  public static async clearCommentFilter() {
    const clearedFilter: CommentFilter = {
      category: [],
      positivity: [],
      target: [],
      age: [],
      gender: [],
      storeName: [],
    };
    await this.setCommentFilter(clearedFilter);
  }

  public static async getCounts(): Promise<Counts | null> {
    return await this.getItem(RADIAL_COUNTS, null);
  }

  public static async setCounts(name: Counts) {
    await this.setItem(RADIAL_COUNTS, name);
  }

  public static async getSaleStockCounts(): Promise<Counts | null> {
    return await this.getItem(RADIAL_SALE_STOCK_COUNTS, null);
  }

  public static async setSaleStockCounts(name: Counts) {
    await this.setItem(RADIAL_SALE_STOCK_COUNTS, name);
  }

  public static async getDashboardGroups(): Promise<DashboardSaveInfo | null> {
    return await this.getItem(RADIAL_DASHBOARD, null);
  }

  public static async setDashboardGroups(name: DashboardSaveInfo) {
    await this.setItem(RADIAL_DASHBOARD, name);
  }
}

export default LocalDataService;
