
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { Service, Warehouse } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import { UpdateWarehouseDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface DataType {
  user: User | null;
  activeItem: Warehouse | null;
  warehouses: Warehouse[];
  services: Service[];
  selectedWarehouse: Warehouse | null;
  isOperating: boolean;
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  option: number;
  dialogEdit: boolean;
  dialogAdd: boolean;
  dialogDelete: boolean;
  isLoading: boolean;
  newWarehouseName: string;
  newWarehouseServiceId: number | null;
  newWarehousePosId: number | null;
  page: number;
  pageSize: number;
  list: Warehouse[];
  listCount: number;
}

export default Vue.extend({
  name: "Warehouses",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
    Pagination,
    Loading,
  },
  data(): DataType {
    return {
      user: null,
      activeItem: null,
      warehouses: [],
      services: [],
      selectedWarehouse: null,
      isOperating: false,
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      option: 0,
      dialogEdit: false,
      dialogAdd: false,
      dialogDelete: false,
      isLoading: false,
      newWarehouseName: "",
      newWarehouseServiceId: null,
      newWarehousePosId: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
    };
  },
  computed: {
    editWarehouseDisabled(): boolean {
      return this.selectedWarehouse !== null && this.selectedWarehouse.name === "";
    },
    addWarehouseDisabled(): boolean {
      return this.newWarehouseName === "" || this.newWarehouseServiceId === null;
    },
  },
  methods: {
    listActive(item: Warehouse) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    sortList() {
      this.list.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    selectWarehouse(warehouse: Warehouse, index: number) {
      this.selectedWarehouse = { ...warehouse };
      if (index === 0) {
        this.dialogEdit = true;
      } else {
        this.dialogDelete = true;
      }
    },
    deselectWarehouse(index: number) {
      this.selectedWarehouse = null;
      if (index === 0) {
        this.dialogEdit = false;
      } else {
        this.dialogDelete = false;
      }
    },
    // カスタムタイプの倉庫のみ削除可能とする。
    async deleteWarehouse() {
      this.isLoading = true;
      if (this.user && this.selectedWarehouse) {
        await RadialApiClient.deleteWarehouse(this.selectedWarehouse.id);
        const list = await RadialApiClient.listWarehouses();
        if (list) {
          this.list = list;
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.isLoading = false;
      this.selectedWarehouse = null;
      this.dialogDelete = false;
    },
    async editWarehouse() {
      this.isLoading = true;
      if (this.user && this.selectedWarehouse) {
        await RadialApiClient.updateWarehouse(this.selectedWarehouse.id, {
          name: this.selectedWarehouse.name,
        } as UpdateWarehouseDto);
        const list = await RadialApiClient.listWarehouses();
        if (list) {
          this.list = list;
          this.sortList();
          this.updatePage(this.page);
        }
      }
      this.isLoading = false;
      this.selectedWarehouse = null;
      this.dialogEdit = false;
    },
    async addWarehouse() {
      this.isLoading = true;
      // カスタム倉庫の新しいposIdを取得（削除導線も考慮しposIdの最大値を取得）
      if (this.warehouses.some((item) => item.serviceId === this.newWarehouseServiceId)) {
        this.newWarehousePosId =
          this.warehouses
            .filter((item) => item.serviceId === this.newWarehouseServiceId)
            .map((item) => {
              return Number(item.posId);
            })
            .reduce((a, b) => {
              return Math.max(a, b);
            }) + 1;
      } else {
        this.newWarehousePosId = 0;
      }
      if (this.user) {
        const warehouse = await RadialApiClient.createWarehouse({
          // id: `${this.newWarehouseServiceId}#${this.user.companyId}#${this.newWarehousePosId}`,
          company: {
            connect: {
              id: this.user.companyId,
            },
          },
          service: {
            connect: {
              id: this.newWarehouseServiceId as number,
            },
          },
          name: this.newWarehouseName,
          posId: String(this.newWarehousePosId),
          isIntegrated: true,
        });
        if (warehouse) {
          this.list.push(warehouse);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert("新しく倉庫が作成されました！", "success", -1);
        } else {
          this.showAlert(
            `エラーが発生しております。
          入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error",
            -1
          );
        }
      }
      this.isLoading = false;
      this.newWarehouseName = "";
      this.newWarehouseServiceId = null;
      this.dialogAdd = false;
    },
    cancelAddWarehouse() {
      this.dialogAdd = false;
      this.newWarehouseName = "";
      this.newWarehouseServiceId = null;
    },
    showAlertOperating(warehouse: Warehouse) {
      this.selectedWarehouse = { ...warehouse };
      this.isOperating = warehouse.isIntegrated;
      if (!this.isOperating) {
        this.alertTitle = "";
        this.alertMessage = `${warehouse.name}を集計対象外に切り替えてよろしいですか？\n・各ページで、倉庫に紐づく在庫のデータが表示されなくなります。`;
        this.alertType = "error";
        this.option = 1;
        this.showingAlert = true;
      } else {
        this.alertTitle = "";
        this.alertMessage = `${warehouse.name}を集計対象に切り替えてよろしいですか？\n・各ページで、倉庫に紐づく在庫のデータが表示されるようになります。`;
        this.alertType = "info";
        this.option = 1;
        this.showingAlert = true;
      }
    },
    async operatingCanceled() {
      // 稼働ステータスを元に戻す
      const warehouse = this.warehouses.find((warehouse) => warehouse.id === this.selectedWarehouse?.id);
      if (warehouse) {
        warehouse.isIntegrated = !warehouse.isIntegrated;
      }
      this.selectedWarehouse = null;
      this.showingAlert = false;
    },
    async operatingChanged() {
      if (this.selectedWarehouse) {
        const dto: UpdateWarehouseDto = {
          name: this.selectedWarehouse.name,
          posId: this.selectedWarehouse.posId,
          isIntegrated: this.selectedWarehouse.isIntegrated,
        };
        await RadialApiClient.updateWarehouse(this.selectedWarehouse.id, dto);
      }
      this.showingAlert = false;
    },
    getServiceName(serviceId: number): string | null {
      return this.services.find((item) => item.id === serviceId)?.name ?? null;
    },
    getServiceLogo(serviceId: number): string {
      switch (serviceId) {
        case 0:
          return require("@/assets/smaregi-logo.svg");
        case 1:
          return require("@/assets/shopify.svg");
        case 100:
          return require("@/assets/futureshop.svg");
        case 101:
          return require("@/assets/shopify.svg");
        case 200:
          return require("@/assets/logizard.png");
        default:
          return require("@/assets/customServiceLogo.svg");
      }
    },
    isCustom(serviceId: number): boolean {
      return this.services.find((item) => item.id === serviceId)?.manual ? true : false;
    },
    getAlertMessage(): string {
      return `本当に${this.selectedWarehouse?.name ?? ""}倉庫を削除してもよろしいですか？`;
    },
    listCustomServices(): Service[] {
      return this.services.filter((item) => {
        return item.manual === true && item.type === "WMS";
      });
    },
    showIntegration() {
      const routeData = this.$router.resolve({
        name: "AdminIntegration",
      });
      window.open(routeData.href, "_blank");
    },
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.option = option;
      this.showingAlert = true;
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.warehouses = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      const list = await RadialApiClient.listWarehouses();
      if (list) {
        this.list = list;
        this.sortList();
        this.listCount = this.list.length;
        if (this.listCount < this.pageSize) {
          this.warehouses = this.list;
        } else {
          this.warehouses = this.list.slice(0, this.pageSize);
        }
      }
      this.services = (await RadialApiClient.listServices()) ?? [];
    }
    this.isLoading = false;
  },
});
