
import Vue from "vue";
import dayjs from "dayjs";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import { DailyStoreBudgetEntity, Store, BudgetWeekRate } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { DailyStoreBudgetDto } from "@/api/dto";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import mixin from "@/mixin/mixin";
export interface DailyBudgets {
  monthBudget: number;
  budgets: { day: number | null; budget: string | null }[];
}

export interface DataType {
  isLoading: boolean;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  selectedMonth: string;
  selectedMonthMenu: boolean;
  storeTable: Store[];
  selectedStoreId: string | null;
  dayOfWeek: string[];
  dailyBudgets: DailyBudgets;
  savedBudgets: (number | null)[];
  hoverIndex: number | null;
  elevationIndex: number | null;
  totalDailyBudget: number;
  totalBudget: number;
  userStoreId: string;
  startIndex: number;
  endIndex: number;
  actionOption: string | null;
  actionOptions: string[];
}

export default Vue.extend({
  name: "DailyStoreBudget",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ElementDatePicker,
    ConfirmationDialog,
    anum,
    Loading,
  },
  data(): DataType {
    return {
      isLoading: false,
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      selectedMonth: dayjs().format("YYYY-MM"),
      selectedMonthMenu: false,
      storeTable: [],
      selectedStoreId: null,
      dayOfWeek: ["日", "月", "火", "水", "木", "金", "土"],
      dailyBudgets: { monthBudget: 0, budgets: [] },
      savedBudgets: [],
      hoverIndex: null,
      elevationIndex: null,
      totalDailyBudget: 0,
      totalBudget: 0,
      userStoreId: "",
      startIndex: 0,
      endIndex: 0,
      actionOption: "均等分配",
      actionOptions: ["平均分配", "平均分配(平日土日比率を加味する)"],
    };
  },
  watch: {
    showingAlert: {
      handler() {
        if (!this.showingAlert) {
          this.actionOption = "AA";
        }
      },
    },
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    budgetCanUpdate(): boolean {
      return this.canUpdate || (this.user?.adminUserName === "staff" && this.selectedStoreId === this.userStoreId);
    },
  },
  methods: {
    async changedMonth() {
      // APIから予算の一覧を取得しdailyBudgetsとsavedBudgetsを更新
      this.isLoading = true;
      const budgets = await RadialApiClient.getDailyStoreBudgets(
        dayjs(this.selectedMonth).format("YYYY-MM"),
        this.selectedStoreId ?? ""
      ).catch((error) => {
        if (error.response.data.statusCode === 600) {
          this.showAlert(error.response.data.message, "error");
        }
      });
      if (budgets) {
        this.dailyBudgets = this.convertEntity(budgets);
        this.savedBudgets = this.dailyBudgets.budgets.map((budget) => {
          return budget.budget !== null ? Number(budget.budget.replace(/[,]/g, "")) : null;
        });
        this.totalDailyBudget = this.dailyBudgets.budgets
          .map((budget) => {
            return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
          })
          .reduce((sum, element) => sum + element, 0);
        this.totalBudget = budgets.monthBudget;
        this.isLoading = false;
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    },
    async changedStore() {
      // APIから予算の一覧を取得しdailyBudgetsとsavedBudgetsを更新
      this.isLoading = true;
      const budgets = await RadialApiClient.getDailyStoreBudgets(
        dayjs(this.selectedMonth).format("YYYY-MM"),
        this.selectedStoreId ?? ""
      ).catch((error) => {
        if (error.response.data.statusCode === 600) {
          this.showAlert(error.response.data.message, "error");
        }
      });
      if (budgets) {
        this.dailyBudgets = this.convertEntity(budgets);
        this.savedBudgets = this.dailyBudgets.budgets.map((budget) => {
          return budget.budget !== null ? Number(budget.budget.replace(/[,]/g, "")) : null;
        });
        this.totalDailyBudget = this.dailyBudgets.budgets
          .map((budget) => {
            return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
          })
          .reduce((sum, element) => sum + element, 0);
        this.totalBudget = budgets.monthBudget;
        this.isLoading = false;
      } else {
        this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    },
    isSameValue(): boolean {
      return this.savedBudgets.every(
        (savedBudget, index) =>
          savedBudget ===
          (this.dailyBudgets.budgets[index].budget !== null
            ? Number(this.dailyBudgets.budgets[index].budget?.replace(/[,]/g, "") ?? "0")
            : null)
      );
    },
    async updateBudgets() {
      this.isLoading = true;
      const dto: DailyStoreBudgetDto = {
        yearMonth: dayjs(this.selectedMonth).format("YYYY-MM"),
        storeId: this.selectedStoreId ?? "",
        budgets: this.dailyBudgets.budgets
          .filter((budget) => budget.day)
          .map((budget) => Number(budget.budget?.replace(/[,]/g, "") ?? "0")),
      };
      const budgets = await RadialApiClient.updateDailyStoreBudgets(dto);
      if (budgets) {
        this.dailyBudgets = this.convertEntity(budgets);
        this.savedBudgets = this.dailyBudgets.budgets.map((budget) => {
          return budget.budget !== null ? Number(budget.budget.replace(/[,]/g, "")) : null;
        });
        this.totalDailyBudget = this.dailyBudgets.budgets
          .map((budget) => {
            return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
          })
          .reduce((sum, element) => sum + element, 0);
        this.totalBudget = budgets.monthBudget;
      }
      this.isLoading = false;
    },
    convertEntity(entity: DailyStoreBudgetEntity): DailyBudgets {
      // 現在の日付
      const now = dayjs(this.selectedMonth);
      // 月初の日付
      const start = now.startOf("month");
      // 月末の日付
      const end = now.endOf("month");
      // 月末の日にち
      const endDate = end.get("date");
      // 月初の曜日
      const startWeekday = start.get("day");

      // 月初と月末のindexを取得する
      this.startIndex = startWeekday;
      this.endIndex = this.startIndex + endDate - 1;

      // entityの型をconvertする
      let budgets: { day: number | null; budget: string | null }[] = [];
      // カレンダーの月初前の空白部分のデータを連結
      [...Array(startWeekday)].map(() => (budgets = budgets.concat({ day: null, budget: null })));
      // 月初から月末までの予算のデータを連結
      for (const index of Array(endDate).keys()) {
        budgets = budgets.concat({
          day: index + 1,
          budget: entity.budgets[index].toLocaleString(),
        });
      }
      // カレンダーの月末以降の空白部分のデータを連結 budget.length === 28の場合は月末以降の空白なし
      if (budgets.length > 28 && budgets.length <= 35) {
        // カレンダーが5行になるように空白部分のデータを連結
        [...Array(35 - budgets.length)].map(() => (budgets = budgets.concat({ day: null, budget: null })));
      } else if (budgets.length > 35) {
        // カレンダーが6行になるように空白部分のデータを連結
        [...Array(42 - budgets.length)].map(() => (budgets = budgets.concat({ day: null, budget: null })));
      }
      return { monthBudget: entity.monthBudget, budgets: budgets };
    },
    clickTextField(index: number) {
      this.dailyBudgets.budgets[index].budget =
        this.dailyBudgets.budgets[index].budget?.replace(/[^0123456789]/g, "") ?? "0";
    },
    changeTextField(index: number) {
      const budgetText = this.zenkakuToHankaku(this.dailyBudgets.budgets[index].budget ?? "0");
      const budget = !budgetText.replace(/,/g, "")
        ? "0" // 空文字列の時
        : isNaN(Number(budgetText.replace(/,/g, "")))
        ? this.savedBudgets[index]?.toLocaleString() ?? "0" // 文字でない時
        : Number(budgetText.replace(/,/g, "")) >= 1000000000
        ? "1,000,000,000"
        : Number(budgetText.replace(/,/g, "")) >= 0
        ? Number(budgetText.replace(/,/g, "")).toLocaleString() // 数字が0以上の時
        : this.savedBudgets[index]?.toLocaleString() ?? "0"; // 数字が0未満の時
      this.dailyBudgets.budgets[index].budget = budget;
      this.totalDailyBudget = this.dailyBudgets.budgets
        .map((budget) => {
          return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
        })
        .reduce((sum, element) => sum + element, 0);
    },
    zenkakuToHankaku(str: string): string {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
    getSpaceStyle(index: number): string {
      const width = this.getTextWidth(this.dailyBudgets.budgets[index].budget ?? "", index === this.elevationIndex);
      return `min-width: calc(100% - ${width}px - 40px) !important;
              max-width: calc(100% - ${width}px - 40px) !important`;
    },
    getTextWidth(text: string, isElevation: boolean): number {
      const span = document.createElement("span");
      span.style.position = "absolute";
      span.style.top = "-1000px";
      span.style.left = "-1000px";
      span.style.whiteSpace = "nowrap";
      span.innerHTML = text;
      span.style.font = "futura-pt";
      if (isElevation) {
        span.style.fontSize = "24px";
        span.style.lineHeight = "39px";
      } else {
        span.style.fontSize = "18px";
        span.style.lineHeight = "30px";
      }
      span.style.fontWeight = "500";
      document.body.appendChild(span);
      const width = span.clientWidth;
      (span as any).parentElement.removeChild(span);
      return width;
    },
    hoverCard(index: number, hoverFlag: number) {
      if (!(this.canCreate || this.selectedStoreId === this.userStoreId)) return;
      if (hoverFlag === 0) {
        this.hoverIndex = index;
      } else if (hoverFlag === 1) {
        this.hoverIndex = null;
      }
    },
    clickCard(index: number) {
      if (!(this.canCreate || this.selectedStoreId === this.userStoreId)) return;
      this.elevationIndex = index;
      Vue.nextTick(() => {
        document.getElementById(`input${index}`)?.focus(); // elevationしたカード内のテキストフィールドをフォーカス
      });
    },
    clickOutsideCalendar() {
      this.elevationIndex = null;
    },
    getDailyCardStyle(index: number, spaceFlag: boolean): string {
      if (spaceFlag) {
        if (index === this.elevationIndex) {
          // clickされたカード
          return `width: calc(14% + 4px); height: calc(110px + 4px); top: ${
            62 + Math.floor(index / 7) * 110 - 2
          }px; left: calc(${(index % 7) * 14}% - 2px); z-index: 1`;
        } else {
          // clickされていないカード
          return `width: 14%; height: 110px; top: ${62 + Math.floor(index / 7) * 110}px; left: ${(index % 7) * 14}%`;
        }
      } else {
        // カレンダーの空白部分
        return `width: 14%; height: 110px; top: ${62 + Math.floor(index / 7) * 110}px; left: ${(index % 7) * 14}%`;
      }
    },
    cancelChanges() {
      for (const index of Array(this.savedBudgets.length).keys()) {
        this.dailyBudgets.budgets[index].budget = this.savedBudgets[index]?.toLocaleString() ?? null;
      }
      this.totalDailyBudget = this.dailyBudgets.budgets
        .map((budget) => {
          return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
        })
        .reduce((sum, element) => sum + element, 0);
    },
    getNonUserHeaderStyle(day: string): string {
      if (day !== "日") {
        return "border-top-width: 0px; border-right-width: 0px; border-left-width: 1px; border-bottom-width: 1px;";
      } else {
        return "border-top-width: 0px; border-right-width: 0px; border-left-width: 0px; border-bottom-width: 1px;";
      }
    },
    getNonUserStyle(index: number) {
      const nonUnderIndex = this.dailyBudgets.budgets.length / 7 - 1;
      if (Math.floor(index / 7) !== nonUnderIndex) {
        if (index % 7 !== 0) {
          return "border-top-width: 0px; border-right-width: 0px; border-left-width: 1px; border-bottom-width: 1px;";
        } else {
          return "border-top-width: 0px; border-right-width: 0px; border-left-width: 0px; border-bottom-width: 1px;";
        }
      } else {
        if (index % 7 !== 0) {
          return "border-top-width: 0px; border-right-width: 0px; border-left-width: 1px; border-bottom-width: 0px;";
        } else {
          return "border-top-width: 0px; border-right-width: 0px; border-left-width: 0px; border-bottom-width: 0px;";
        }
      }
    },
    moveNextCalendar(index: number) {
      if (index < this.endIndex && this.elevationIndex !== null) {
        this.clickCard(index + 1);
      }
    },
    movePrevCalendar(index: number) {
      if (index > this.startIndex && this.elevationIndex !== null) {
        this.clickCard(index - 1);
      }
    },
    actionSelected() {
      if (this.actionOption === "平均分配") {
        this.showAlert("月予算を日ごとに均等に配分(平均分配)してもよろしいでしょうか？", "info");
      } else {
        // 平均分配(平日土日比率を加味する)
        this.showAlert(
          `月予算を平日土日比率を加味して日ごとに配分(平均分配(平日土日比率を加味する))してもよろしいでしょうか？`,
          "info"
        );
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async clickExecuteButton() {
      this.isLoading = true;
      if (this.actionOption === "平均分配") {
        this.executeDistribution();
      } else {
        // 平均分配(平日土日比率を加味する)
        await this.executeWeekdayDistribution();
      }
      // 日別入力予算合計を更新
      this.totalDailyBudget = this.dailyBudgets.budgets
        .map((budget) => {
          return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
        })
        .reduce((sum, element) => sum + element, 0);
      this.isLoading = false;
      this.showingAlert = false;
    },
    executeDistribution() {
      // 月予算を均等に分配するため均等に分配できる予算と余剰の予算にわける
      const averageBudget = Math.floor(this.totalBudget / (this.endIndex - this.startIndex + 1));
      let remainder = this.totalBudget - averageBudget * (this.endIndex - this.startIndex + 1);
      // 日ごとの予算を更新
      for (const index of Array(this.dailyBudgets.budgets.length).keys()) {
        if (this.dailyBudgets.budgets[index].budget !== null) {
          if (remainder > 0) {
            // 余剰の予算がある場合は均等に分配できる予算に+1した値を代入
            this.dailyBudgets.budgets[index].budget = (averageBudget + 1).toLocaleString();
            remainder -= 1;
          } else {
            // 余剰の予算がない場合は均等に分配できる予算を代入
            this.dailyBudgets.budgets[index].budget = averageBudget.toLocaleString();
          }
        }
      }
    },
    async executeWeekdayDistribution() {
      const budgetWeekRateList: BudgetWeekRate[] =
        (await RadialApiClient.getBudgetWeekRateList(Number(dayjs(this.selectedMonth).format("YYYY")))) ?? [];
      const budgetWeekRate = budgetWeekRateList.find(
        (rate) => rate.month === Number(dayjs(this.selectedMonth).format("MM"))
      );
      const weekendRatio = budgetWeekRate ? budgetWeekRate.weekend / budgetWeekRate.weekday : 1; // weekdayの比率を1としたときのweekendの比率
      const weekdays = this.dailyBudgets.budgets.filter(
        (budget, index) => budget.day !== null && index % 7 !== 0 && index % 7 !== 6
      ).length; // 選択月の中の平日の日数
      const weekends = this.dailyBudgets.budgets.filter(
        (budget, index) => budget.day !== null && (index % 7 === 0 || index % 7 === 6)
      ).length; // 選択月の中の休日の日数
      let weekdayBudget = Math.floor(this.totalDailyBudget / (weekdays + weekends * weekendRatio)); // 平日の予算を算出
      let weekendBudget = Math.floor(weekdayBudget * weekendRatio); // 土日の予算を算出
      let remainder = this.totalDailyBudget - weekdayBudget * weekdays - weekendBudget * weekends; // 余剰の予算を算出
      const additionBudgetPerDay = Math.floor(remainder / (this.endIndex - this.startIndex + 1)); // 余剰の予算を均等に分配するときに加算する1日の予算
      weekdayBudget += additionBudgetPerDay; // 均等に分配する余剰の予算を平日の予算に加算
      weekendBudget += additionBudgetPerDay; // 均等に分配する余剰の予算を土日の予算に加算
      remainder -= additionBudgetPerDay * (this.endIndex - this.startIndex + 1); // 余剰予算を更新
      for (const index of Array(this.dailyBudgets.budgets.length).keys()) {
        if (this.dailyBudgets.budgets[index].budget !== null) {
          if (remainder > 0) {
            // 余剰の予算がある場合は均等に分配できる予算に+1した値を代入
            if (index % 7 !== 0 && index % 7 !== 6) {
              // 平日の場合
              this.dailyBudgets.budgets[index].budget = (weekdayBudget + 1).toLocaleString();
            } else {
              // 土日の場合
              this.dailyBudgets.budgets[index].budget = (weekendBudget + 1).toLocaleString();
            }
            remainder -= 1;
          } else {
            // 余剰の予算がない場合は均等に分配できる予算を代入
            if (index % 7 !== 0 && index % 7 !== 6) {
              // 平日の場合
              this.dailyBudgets.budgets[index].budget = weekdayBudget.toLocaleString();
            } else {
              // 土日の場合
              this.dailyBudgets.budgets[index].budget = weekendBudget.toLocaleString();
            }
          }
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.storeTable = (await RadialApiClient.listStores()) ?? [];
    this.storeTable = this.storeTable.filter((store) => store.operating);
    if (this.isAdmin) {
      this.selectedStoreId = this.storeTable[0].id;
    } else {
      const user = await AuthClient.getUserInfo();
      this.userStoreId = this.storeTable.filter((store) => store.cognitoSub === user?.sub)[0].id;
      this.selectedStoreId = this.userStoreId;
    }
    // APIから予算の一覧を取得しdailyBudgetsとsavedBudgetsを更新
    const budgets = await RadialApiClient.getDailyStoreBudgets(
      dayjs(this.selectedMonth).format("YYYY-MM"),
      this.selectedStoreId ?? ""
    ).catch((error) => {
      if (error.response.data.statusCode === 600) {
        this.showAlert(error.response.data.message, "error");
      }
    });
    if (budgets) {
      this.dailyBudgets = this.convertEntity(budgets);
      this.savedBudgets = this.dailyBudgets.budgets.map((budget) => {
        return budget.budget !== null ? Number(budget.budget.replace(/[,]/g, "")) : null;
      });
      this.totalDailyBudget = this.dailyBudgets.budgets
        .map((budget) => {
          return Number(budget.budget?.replace(/[,]/g, "") ?? "0");
        })
        .reduce((sum, element) => sum + element, 0);
      this.totalBudget = budgets.monthBudget;
      this.isLoading = false;
    } else {
      this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
    }
  },
});
