
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import dayjs from "dayjs";
import { MdMapSummaryEntity, Season, SeasonsSummaryEntity } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import router from "@/router";
import { Options } from "highcharts";
import { Chart } from "highcharts-vue";
import { Constant } from "@/store/constant";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

// TODO: lodashのimportエラーの解決
// import { groupBy } from "lodash";

export interface SeasonYearSummaries {
  seasonColumn: string;
  seasonSummaries: SeasonsSummaryEntity[];
}

export interface DataType {
  isLoading: boolean;
  seasons: Season[];
  allSeasonSummaries: SeasonYearSummaries[];
  user: User | null;
}

export default Vue.extend({
  name: "Seasons",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    highcharts: Chart,
    Loading,
  },
  data(): DataType {
    return {
      isLoading: false,
      seasons: [],
      allSeasonSummaries: [],
      user: null,
    };
  },
  computed: {},
  methods: {
    getSeasonRange(seasonId: number): string {
      const season = this.seasons.find((season) => season.id === seasonId);
      if (season) {
        return `${dayjs(season.startYearMonth).format("YY/M")}〜${dayjs(season.endYearMonth).format("YY/M")}`;
      } else {
        return "";
      }
    },
    getSeasonStartYearMonth(seasonId: number): string {
      return dayjs(this.seasons.find((season) => season.id === seasonId)?.startYearMonth).format("YY-MM");
    },
    getSeasonName(seasonId: number): string {
      const season = this.seasons.find((season) => season.id === seasonId);
      if (season) {
        return season.name;
      } else {
        return "";
      }
    },
    showSeason() {
      router.push({ name: "MasterList" });
    },
    showMdMap(seasonId: string) {
      const routeData = this.$router.resolve({
        name: "AdminMdMap",
        params: { seasonId },
      });
      window.open(routeData.href, "_blank");
    },
    showSeasonPlan(seasonId: string) {
      const routeData = this.$router.resolve({
        name: "AdminSeasonPlan",
        params: { seasonId },
      });
      window.open(routeData.href, "_blank");
    },
    getOptions(summary: MdMapSummaryEntity): Options {
      return {
        title: undefined,
        chart: {
          renderTo: "piechart",
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          width: 88,
          height: 88,
          backgroundColor: "rgba(255, 255, 255, 0.0)",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            size: "100%",
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              `<div style="font-size: 8px">` +
              this.point.name +
              "</div>" +
              " " +
              `<div style="font-size: 10px">` +
              this.y +
              "%" +
              "</div>"
            );
          },
        },
        series: [
          {
            //データ設定
            type: "pie",
            name: "",
            data: summary.statusRate.map((info) => {
              return {
                name: Constant.ProductStatus.find((productStatus) => productStatus.id === info.id)?.name,
                y: info.countRate,
                color: Constant.pieChartProductColors.find((productColor) => productColor.id === info.id)?.color,
              };
            }),
          },
        ],
      };
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.isAdmin) {
      this.user = await AuthClient.getUserInfo();
      if (this.user) {
        this.seasons = (await RadialApiClient.listSeasons()) ?? [];
        this.allSeasonSummaries = Constant.seasonColumns.map((seasonColumn) => {
          return {
            seasonColumn: seasonColumn,
            seasonSummaries: [],
          };
        });
        const summaries = await RadialApiClient.listSeasonSummaries();
        if (summaries) {
          summaries.forEach((summary) => {
            const selectedSeason = this.seasons.find((season) => season.id === summary.seasonId);
            const startYearMonth = dayjs(selectedSeason?.startYearMonth).format("YY-MM");
            const endYearMonth = dayjs(selectedSeason?.endYearMonth).format("YY-MM");
            const currentYearMonth = dayjs().format("YY-MM");
            if (endYearMonth < currentYearMonth) {
              this.allSeasonSummaries[0].seasonSummaries.push(summary);
            } else if (startYearMonth <= currentYearMonth && currentYearMonth <= endYearMonth) {
              this.allSeasonSummaries[1].seasonSummaries.push(summary);
            } else {
              this.allSeasonSummaries[2].seasonSummaries.push(summary);
            }
          });
        }
        this.allSeasonSummaries.forEach((season) => {
          season.seasonSummaries.sort((a, b) => {
            if (this.getSeasonStartYearMonth(a.seasonId) > this.getSeasonStartYearMonth(b.seasonId)) {
              return -1;
            } else {
              return 1;
            }
          });
          season.seasonSummaries.forEach((seasonSummary) => {
            seasonSummary.result.statusRate;
            // ステータス順に並び替え
            seasonSummary.result.statusRate = Constant.ProductStatus.map((status) => {
              const item = seasonSummary.result.statusRate.find((info) => info.id === status.id);
              if (item) {
                return {
                  id: item?.id,
                  countRate: item?.countRate,
                  amountRate: item?.amountRate,
                };
              } else {
                return {
                  id: "",
                  countRate: 0,
                  amountRate: 0,
                };
              }
            });
          });
        });
      }
    }

    // GAのページビューを送信
    if (this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/season",
        page_title: "Adminシーズン計画画面",
      });
    } else if (!this.isAdmin) {
      this.$gtag?.pageview({
        page_path: "/season",
        page_title: "Staffシーズン画面",
      });
    }
    this.isLoading = false;
  },
});
