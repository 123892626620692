
import Vue from "vue";
import dayjs from "dayjs";
import RadialApiClient from "@/api/RadialApiClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import anum from "@/components/animation/AnimatedNumber.vue";
import {
  Attribute,
  Brand,
  BrandCategory,
  Category,
  ItemRatioDetailEntity,
  ItemRatioStatusDetailEntity,
  MdMapSeasonTotalGroupListEntity,
  MdMapSeasonTotalItemRatioEntity,
  MdMapStatsTotalEntity,
  Season,
} from "@/api/entities";
import { MdMapSummaryItemFilters, MdMapSummaryPlanFilters, ProductStatusInfo, User } from "@/store/model";
import { Chart } from "highcharts-vue";
import { Options, SeriesOptionsType, TooltipOptions } from "highcharts";
import * as Highcharts from "highcharts";
import { Constant } from "@/store/constant";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export type pieChartKey =
  | "countByStatus"
  | "countByBrand"
  | "countByCategory"
  | "countByAttribute1"
  | "countByAttribute2"
  | "countByAttribute3";

export interface DataType {
  isLoading: boolean;
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  deleteOption: number;
  alertType: string;
  summaryPlanFilters: MdMapSummaryPlanFilters;
  summaryTotalItemFilters: MdMapSummaryItemFilters;
  summaryMonthlyItemFilters: MdMapSummaryItemFilters;
  planFilters: string[];
  planFilterKeys: string[];
  itemFilters: string[];
  itemFilterKeys: string[];
  statsTotal: MdMapStatsTotalEntity | null;
  buyingPlanOptions: Options;
  totalToggle: string;
  monthlyToggle: string;
  itemTotalNumbers: {
    count: number;
    amount: number;
  };
  pieChartTypes: {
    id: pieChartKey;
    name: string;
  }[];
  pieChartData: MdMapSeasonTotalItemRatioEntity | null;
  groupBy: string;
  groupBys: { name: string; key: string }[];
  areaGraphData: MdMapSeasonTotalGroupListEntity[];
}

export default Vue.extend({
  name: "MdMapSummary",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
    FilterChipButton,
    anum,
    highcharts: Chart,
    Loading,
  },
  props: {
    selectedSeason: {
      type: Object as () => Season | null,
      required: true,
    },
    brands: {
      type: Array as () => Brand[],
      required: true,
    },
    categories: {
      type: Array as () => Category[],
      required: true,
    },
    brandCategories: {
      type: Array as () => BrandCategory[],
      required: true,
    },
    attribute1s: {
      type: Array as () => Attribute[],
      required: true,
    },
    attribute2s: {
      type: Array as () => Attribute[],
      required: true,
    },
    attribute3s: {
      type: Array as () => Attribute[],
      required: true,
    },
    user: {
      type: Object as () => User | null,
      required: false,
    },
    seasonYearMonths: {
      type: Array as () => string[],
      required: true,
    },
  },
  data(): DataType {
    return {
      isLoading: false,
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      deleteOption: 0,
      alertType: "success",
      summaryPlanFilters: {
        brand: [],
        category: [],
      },
      summaryTotalItemFilters: {
        brand: [],
        category: [],
        status: [],
      },
      summaryMonthlyItemFilters: {
        brand: [],
        category: [],
        status: [],
      },
      planFilters: ["ブランド", "アイテムカテゴリ"],
      planFilterKeys: ["brand", "category"],
      itemFilters: ["ブランド", "アイテムカテゴリ", "ステータス"],
      itemFilterKeys: ["brand", "category", "status"],
      statsTotal: null,
      buyingPlanOptions: {
        title: undefined,
        chart: {
          width: 1240,
          height: 240,
          backgroundColor: "#fbfbfb",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
      },
      totalToggle: "count",
      monthlyToggle: "count",
      itemTotalNumbers: {
        count: 0,
        amount: 0,
      },
      pieChartTypes: [
        {
          id: "countByStatus",
          name: "ステータス",
        },
        {
          id: "countByBrand",
          name: "ブランド",
        },
        {
          id: "countByCategory",
          name: "カテゴリ",
        },
        {
          id: "countByAttribute1",
          name: "属性1",
        },
        {
          id: "countByAttribute2",
          name: "属性2",
        },
        {
          id: "countByAttribute3",
          name: "属性3",
        },
      ],
      pieChartData: null,
      groupBy: "brand",
      groupBys: [
        { name: "ブランド", key: "brand" },
        { name: "アイテムカテゴリ", key: "category" },
        { name: "属性1", key: "attribute1" },
        { name: "属性2", key: "attribute2" },
        { name: "属性3", key: "attribute3" },
      ],
      areaGraphData: [],
    };
  },
  computed: {
    statsTotalRate(): number {
      if (this.statsTotal) {
        if (this.statsTotal.neededBuyingBudget !== 0) {
          const rate = (100 * this.statsTotal.buyingBudget) / this.statsTotal.neededBuyingBudget;
          if (!isNaN(rate)) {
            // 0/0のときだけNaNになるので注意
            return Math.ceil(rate);
          }
        }
      }
      return 0;
    },
  },
  methods: {
    showAlert(message: string, type: string, option: number) {
      this.alertMessage = message;
      this.alertType = type;
      this.deleteOption = option; // -1でボタンを表示しない
      this.showingAlert = true;
    },
    filterOptions(index: number): Brand[] | Category[] | ProductStatusInfo[] {
      switch (index) {
        case 0:
          return this.brands.filter((brand) => {
            return this.brandCategories.some((brandCategory) => brandCategory.brandId === brand.id);
          });
        case 1:
          return this.categories.filter((category) => {
            return this.brandCategories.some((brandCategory) => brandCategory.categoryId === category.id);
          });
        case 2:
          return Constant.ProductStatus;
        default:
          return [];
      }
    },
    async clearPlanFilterKey(key: keyof MdMapSummaryPlanFilters) {
      this.summaryPlanFilters[key] = [];
      await this.planSearch();
    },
    async clearTotalItemFilterKey(key: keyof MdMapSummaryItemFilters) {
      this.summaryTotalItemFilters[key] = [];
      await this.totalItemSearch();
    },
    async clearMonthlyItemFilterKey(key: keyof MdMapSummaryItemFilters) {
      this.summaryMonthlyItemFilters[key] = [];
      await this.monthlyItemSearch();
    },
    async planSearch() {
      // 仕入サマリーの検索
      this.isLoading = true;
      const brandIds = this.summaryPlanFilters.brand.map((brand) => {
        return brand.id;
      });
      const categoryIds = this.summaryPlanFilters.category.map((category) => {
        return category.id;
      });
      if (this.selectedSeason) {
        const mdMapSummaryPlans = await RadialApiClient.listMdMapSeasonTotalBuyingInfoEntity(
          this.selectedSeason.id,
          brandIds.join(`,`),
          categoryIds.join(`,`)
        );
        if (mdMapSummaryPlans) {
          // 計画済み仕入れ予算の更新
          this.statsTotal = {
            buyingBudget: mdMapSummaryPlans.reduce((sumResult, summaryPlan) => {
              return sumResult + summaryPlan.info.buyingBudget;
            }, 0),
            neededBuyingBudget: mdMapSummaryPlans.reduce((sumResult, summaryPlan) => {
              return sumResult + summaryPlan.info.neededBuyingBudget;
            }, 0),
          };
          // 仕入サマリーグラフのための各種数値取得
          // X軸の年月データ取得
          const categories = mdMapSummaryPlans.map((summaryPlan) => {
            // return summaryPlan.yearMonth;
            return dayjs(summaryPlan.yearMonth, "YY/MM").format("YY/M");
          });
          // 計画済み予算データの取得
          const dataBuyingBudget = mdMapSummaryPlans.map((summaryPlan) => {
            return summaryPlan.info.buyingBudget;
          });
          // 必要仕入れ予算データの取得
          const dataNeededBuyingBudget = mdMapSummaryPlans.map((summaryPlan) => {
            return summaryPlan.info.neededBuyingBudget;
          });
          // Y軸のトップラインのための最大値取得
          const buyingPlanMaxData = Math.max(...dataBuyingBudget, ...dataNeededBuyingBudget);
          const buyingPlanMax =
            Math.ceil(buyingPlanMaxData / Math.pow(10, String(buyingPlanMaxData).length - 1)) *
            Math.pow(10, String(buyingPlanMaxData).length - 1);
          // Y軸のGrid幅の設定
          const buyingPlanTickInterval = buyingPlanMax / 4;
          // ツールチップの設定
          const buyingPlanTooltip: TooltipOptions = {
            style: {
              // ツールチップ内の文字カラーはassistantType70
              color: "#3C6065",
              fontSize: "12px",
            },
            formatter: function () {
              let s = this.x.toString();
              if (this.points && this.points.length > 1) {
                s +=
                  "<br/>" +
                  '<b style="color:#A5D4D4">' +
                  this.points[1].series.name +
                  "</b>" +
                  ": " +
                  "￥" +
                  this.points[1].y.toLocaleString();
                s +=
                  "<br/>" +
                  '<b style="color:#5FB3B1">' +
                  this.points[0].series.name +
                  "</b>" +
                  ": " +
                  "￥" +
                  this.points[0].y.toLocaleString();
              }
              return s;
            },
            shared: true,
          };
          // グラフデータの設定
          const buyingPlanSeries: SeriesOptionsType[] = [
            {
              name: "計画済み予算", // グラフの名前指定
              type: "line", // グラフの形指定
              data: dataBuyingBudget, // グラフに使用するデータ指定
              color: "#5FB3B1",
              dashStyle: "Solid",
              lineWidth: 3,
              yAxis: 0,
              marker: {
                radius: 8,
                symbol: "circle",
              },
            },
            // 売上予算データ
            {
              name: "必要仕入れ予算", // グラフの名前指定
              type: "line", // グラフの形指定
              data: dataNeededBuyingBudget, // グラフに使用するデータ指定
              color: "#A5D4D4",
              dashStyle: "LongDash",
              lineWidth: 2,
              yAxis: 0,
              marker: {
                radius: 4,
                symbol: "circle",
              },
            },
          ];
          // 仕入れサマリーのグラフ更新
          this.buyingPlanOptions = {
            title: undefined,
            chart: {
              height: 240,
              backgroundColor: "#fbfbfb",
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            xAxis: [
              {
                categories,
                tickInterval: 1,
                labels: {
                  style: {
                    // X軸の各ポイントにおけるカラー（assistantType50で統一）
                    color: "#799498",
                  },
                },
              },
            ],
            yAxis: [
              {
                // 売上予算及び実績のy軸設定
                title: {
                  text: undefined,
                },
                endOnTick: false,
                min: 0,
                max: buyingPlanMax,
                tickInterval: buyingPlanTickInterval, // y軸のgrid幅を揃える
                labels: {
                  formatter: function () {
                    return `￥${Highcharts.numberFormat(Number(this.value), 0, ",", ",")}`;
                  },
                  style: {
                    // Y軸の各ポイントにおけるカラー（assistantType50で統一）
                    color: "#799498",
                  },
                },
              },
            ],
            tooltip: buyingPlanTooltip,
            plotOptions: {
              line: {
                dataLabels: {
                  enabled: false,
                  verticalAlign: "bottom",
                  useHTML: true,
                },
              },
              series: {
                states: {
                  inactive: {
                    enabled: false,
                  },
                },
              },
            },
            series: buyingPlanSeries,
          };
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
        }
      }
      this.isLoading = false;
    },
    async totalItemSearch() {
      // アイテム構成比の全体検索
      this.isLoading = true;
      const brandIds = this.summaryTotalItemFilters.brand.map((brand) => {
        return brand.id;
      });
      const categoryIds = this.summaryTotalItemFilters.category.map((category) => {
        return category.id;
      });
      const productStatuses = this.summaryTotalItemFilters.status;
      if (this.selectedSeason) {
        const summaryTotalItem = await RadialApiClient.getMdMapSeasonTotalItemRatioEntity({
          seasonId: this.selectedSeason.id,
          brandIds:
            brandIds.length > 0
              ? this.summaryTotalItemFilters.brand.map((brand) => {
                  return brand.id;
                })
              : undefined,
          categoryIds:
            categoryIds.length > 0
              ? this.summaryTotalItemFilters.category.map((category) => {
                  return category.id;
                })
              : undefined,
          productStatuses:
            productStatuses.length > 0
              ? this.summaryTotalItemFilters.status.map((info) => {
                  return info.id;
                })
              : undefined,
        });
        if (summaryTotalItem) {
          // ステータス順に並び替え
          summaryTotalItem.countByStatus = Constant.ProductStatus.map((status) => {
            const item = summaryTotalItem.countByStatus.find((info) => info.id === status.id);
            if (item) {
              return {
                id: item?.id,
                countRate: item?.countRate,
                amountRate: item?.amountRate,
              };
            } else {
              return {
                id: "",
                countRate: 0,
                amountRate: 0,
              };
            }
          });
          this.pieChartData = summaryTotalItem;
          // 品番数表示におけるソート
          const key: pieChartKey[] = [
            "countByBrand",
            "countByCategory",
            "countByAttribute1",
            "countByAttribute2",
            "countByAttribute3",
          ];
          key.forEach((item) => {
            this.pieChartData?.[item].sort((a: any, b: any) => {
              if (a.countRate > b.countRate) {
                return -1;
              } else {
                return 1;
              }
            });
          });
          this.itemTotalNumbers = {
            count: summaryTotalItem.countTotal,
            amount: summaryTotalItem.amountTotal,
          };
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
        }
      }
      this.isLoading = false;
    },
    async monthlyItemSearch() {
      // アイテム構成比の月別の検索
      this.isLoading = true;
      const brandIds = this.summaryMonthlyItemFilters.brand.map((brand) => {
        return brand.id;
      });
      const categoryIds = this.summaryMonthlyItemFilters.category.map((category) => {
        return category.id;
      });
      const productStatuses = this.summaryMonthlyItemFilters.status;
      if (this.selectedSeason) {
        const summaryMonthlyItem = await RadialApiClient.listMdMapSeasonTotalGroupListEntity(this.groupBy, {
          seasonId: this.selectedSeason.id,
          brandIds:
            brandIds.length > 0
              ? this.summaryMonthlyItemFilters.brand.map((brand) => {
                  return brand.id;
                })
              : undefined,
          categoryIds:
            categoryIds.length > 0
              ? this.summaryMonthlyItemFilters.category.map((category) => {
                  return category.id;
                })
              : undefined,
          productStatuses:
            productStatuses.length > 0
              ? this.summaryMonthlyItemFilters.status.map((info) => {
                  return info.id;
                })
              : undefined,
        });
        if (summaryMonthlyItem) {
          this.areaGraphData = summaryMonthlyItem;
        } else {
          this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error", -1);
        }
      }
      this.isLoading = false;
    },
    getTotalOptions(key: pieChartKey): Options {
      return {
        title: undefined,
        chart: {
          renderTo: "piechart",
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          width: 120,
          height: 120,
          backgroundColor: "rgba(255, 255, 255, 0.0)",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            size: "100%",
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: {
          style: {
            color: "#3C6065",
          },
          formatter: function () {
            return (
              `<div style="font-size: 12px;">` +
              this.point.name +
              "</div>" +
              " " +
              `<div style="font-size: 12px;">` +
              this.y +
              "%" +
              "</div>"
            );
          },
        },
        series: [
          {
            //データ設定
            type: "pie",
            name: "",
            data:
              key === "countByStatus"
                ? this.pieChartData?.[key].map((status: ItemRatioStatusDetailEntity) => {
                    return {
                      name: Constant.ProductStatus.find((productStatus) => productStatus.id === status.id)?.name,
                      y: this.totalToggle === "count" ? status.countRate : status.amountRate,
                      color: Constant.pieChartProductColors.find((productColor) => productColor.id === status.id)
                        ?.color,
                    };
                  })
                : this.pieChartData?.[key].map((group: ItemRatioDetailEntity, index) => {
                    return {
                      name: group.name ? group.name : "未登録",
                      y: this.totalToggle === "count" ? group.countRate : group.amountRate,
                      color: index > 7 ? "#F5F9FA" : Constant.pieChartMdMapSummaryColors[index].color,
                    };
                  }),
          },
        ],
      };
    },
    getMonthlyOptions(): Options {
      const categories = this.areaGraphData.length > 0 ? this.areaGraphData[0].dates.map((date) => date.yearMonth) : [];
      const toggle = this.monthlyToggle;
      const colors = ["#CCE4E4", "#A5D5D4", "#5FB3B1", "#519998", "#44807F", "#294D4C"];
      // TODO: hover時の対応
      return {
        chart: {
          type: "area",
          width: 1240,
          height: 270,
          backgroundColor: "#fbfbfb",
          animation: false,
        },
        title: {
          text: "",
        },
        // TODO: 修正必要
        colors: colors,
        xAxis: {
          categories,
          tickInterval: 1,
          labels: {
            style: {
              color: "#799498",
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            formatter: (data) => {
              return this.monthlyToggle === "count" ? `${data.value}` : `¥${Number(data.value).toLocaleString()}`;
            },
            style: {
              color: "#799498",
            },
          },
        },
        tooltip: {
          style: {
            color: "#3C6065",
            fontSize: "12px",
          },
          formatter() {
            let s = this.x.toString();
            if (this.points) {
              if (toggle === "count") {
                this.points.forEach((point) => {
                  s +=
                    "<br/>" +
                    '<b style="color:#A5D4D4">' +
                    point.series.name +
                    "</b>" +
                    ": " +
                    point.y.toLocaleString();
                });
              } else {
                this.points.forEach((point) => {
                  s +=
                    "<br/>" +
                    '<b style="color:#A5D4D4">' +
                    point.series.name +
                    "</b>" +
                    ": " +
                    "￥" +
                    point.y.toLocaleString();
                });
              }
            }
            return s;
          },
          shared: true,
        },
        plotOptions: {
          area: {
            stacking: "normal",
            lineColor: "#799498",
            lineWidth: 1,
            marker: {
              enabled: false,
            },
          },
          series: {
            states: {
              hover: { enabled: false },
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        series: this.areaGraphData.map((item) => {
          return {
            showInLegend: false,
            name: item.name,
            type: "area",
            data: item.dates.map((date) => (this.monthlyToggle === "amount" ? date.amount : date.count)),
          };
        }),
        credits: {
          enabled: false,
        },
      };
    },
  },
  async mounted() {
    this.planSearch();
    this.totalItemSearch();
    this.monthlyItemSearch();
  },
});
