import { render, staticRenderFns } from "./DashboardSaleRanking.vue?vue&type=template&id=3df4ffb2&scoped=true&"
import script from "./DashboardSaleRanking.vue?vue&type=script&lang=ts&"
export * from "./DashboardSaleRanking.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardSaleRanking.vue?vue&type=style&index=0&id=3df4ffb2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df4ffb2",
  null
  
)

export default component.exports