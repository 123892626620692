
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import CustomTag from "@/views/custom-list/CustomTag.vue";
import CustomAction from "@/views/custom-list/CustomAction.vue";
import { User } from "@/store/model";
import store from "@/store";
import mixin from "@/mixin/mixin";

export interface DataType {
  isLoading: boolean;
  tabIndex: string;
}

export default Vue.extend({
  name: "CustomSetting",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    CustomTag,
    CustomAction,
  },
  data(): DataType {
    return {
      isLoading: false,
      tabIndex: "tags",
    };
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
  },
});
