import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/account/Login.vue";
import SignUp from "@/views/account/SignUp.vue";
import ResetPassword from "@/views/account/ResetPassword.vue";
import ResetPasswordDone from "@/views/account/ResetPasswordDone.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import ProductList from "@/views/product/ProductList.vue";
import SkuList from "@/views/product/SkuList.vue";
import ActualResultDownload from "@/views/product/ActualResultDownload.vue";
import ProductDetail from "@/views/product/ProductDetail.vue";
import CsvItemSkuUpload from "@/views/settings/CsvItemSkuUpload.vue";
import CsvSaleStockUpload from "@/views/settings/CsvSaleStockUpload.vue";
import ImageDataUpload from "@/views/settings/ImageDataUpload.vue";
import CustomerTouchPoint from "@/views/settings/CustomerTouchPoint.vue";
import Warehouses from "@/views/settings/Warehouses.vue";
import Factories from "@/views/settings/Factories.vue";
import Integration from "@/views/integration/Integration.vue";
import AccountSetting from "@/views/settings/AccountSetting.vue";
import AdminSetting from "@/views/settings/AdminSetting.vue";
import Members from "@/views/settings/Members.vue";
import UpdatePassword from "@/views/account/UpdatePassword.vue";
import AlertSetting from "@/views/settings/AlertSetting.vue";
import CustomSetting from "@/views/custom-list/CustomSetting.vue";
import Report from "@/views/daily-report/Report.vue";
import Delivery from "@/views/delivery-map/Delivery.vue";
import Seasons from "@/views/season/Seasons.vue";
import Orders from "@/views/season/Orders.vue";
import OrderInstructionDetail from "@/views/season/OrderInstructionDetail.vue";
import MdMap from "@/views/season/MdMap.vue";
import SeasonPlan from "@/views/season/SeasonPlan.vue";
import StoreSeasonPlan from "@/views/season/StoreSeasonPlan.vue";
import MasterList from "@/views/masterlist/MasterList.vue";
import WeekdayBudget from "@/views/settings/WeekdayBudget.vue";
import DailyStoreBudget from "@/views/settings/DailyStoreBudget.vue";
import GroupList from "@/views/group-list/GroupList.vue";
import ActionList from "@/views/action/ActionList.vue";
import ActionRequest from "@/views/action/ActionRequest.vue";
import RadialApiClient from "@/api/RadialApiClient";

Vue.use(VueRouter);

let routes: RouteConfig[] = [
  // Admin
  {
    path: "/admin",
    name: "AdminRoot",
    component: Login,
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: Login,
  },
  {
    path: "/admin/reset-password",
    name: "AdminResetPassword",
    component: ResetPassword,
  },
  {
    path: "/admin/reset-password-done",
    name: "AdminResetPasswordDone",
    component: ResetPasswordDone,
  },
  {
    path: "/admin/update-password",
    name: "AdminUpdatePassword",
    component: UpdatePassword,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: Dashboard,
  },
  {
    path: "/admin/product-list",
    name: "AdminProductList",
    component: ProductList,
  },
  {
    path: "/admin/sku-list",
    name: "AdminSkuList",
    component: SkuList,
  },
  {
    path: "/admin/result-download",
    name: "AdminActualResultDownload",
    component: ActualResultDownload,
  },
  {
    path: "/admin/product-detail/:productId",
    name: "AdminProductDetail",
    component: ProductDetail,
  },
  {
    path: "/admin/group-list",
    name: "AdminGroupList",
    component: GroupList,
  },
  {
    path: "/admin/report",
    name: "AdminReport",
    component: Report,
  },
  {
    path: "/admin/seasons",
    name: "AdminSeasons",
    component: Seasons,
  },
  {
    path: "/admin/orders",
    name: "AdminOrders",
    component: Orders,
  },
  {
    path: "/admin/order-instruction/:orderInstructionId",
    name: "AdminOrderInstructionDetail",
    component: OrderInstructionDetail,
  },
  {
    path: "/admin/mdmap/:seasonId/:yearMonth?/:productId?",
    name: "AdminMdMap",
    component: MdMap,
  },
  {
    path: "/admin/season-plan/:seasonId",
    name: "AdminSeasonPlan",
    component: SeasonPlan,
  },
  {
    path: "/admin/store-season-plan",
    name: "AdminStorePlan",
    component: StoreSeasonPlan,
  },
  {
    path: "/admin/delivery",
    name: "AdminDelivery",
    component: Delivery,
  },
  // アクション
  {
    path: "/admin/action-list/:actionId?",
    name: "AdminActionList",
    component: ActionList,
  },
  {
    path: "/admin/action-request",
    name: "AdminActionRequest",
    component: ActionRequest,
  },
  // Admin設定メニュー
  {
    path: "/admin/setting/item-sku",
    name: "AdminCsvItemSkuUpload",
    component: CsvItemSkuUpload,
  },
  {
    path: "/admin/setting/photos",
    name: "AdminImageDataUpload",
    component: ImageDataUpload,
  },
  {
    path: "/admin/setting/account",
    name: "AdminAccountSetting",
    component: AccountSetting,
  },
  {
    path: "/admin/setting/admin",
    name: "AdminSetting",
    component: AdminSetting,
  },
  {
    path: "/admin/setting/customer-touch-point",
    name: "AdminCustomerTouchPoint",
    component: CustomerTouchPoint,
  },
  {
    path: "/admin/setting/warehouses",
    name: "AdminWarehouses",
    component: Warehouses,
  },
  {
    path: "/admin/setting/factories",
    name: "AdminFactories",
    component: Factories,
  },
  {
    path: "/admin/setting/integration",
    name: "AdminIntegration",
    component: Integration,
  },
  {
    path: "/admin/setting/alert",
    name: "AlertSetting",
    component: AlertSetting,
  },
  {
    path: "/admin/setting/custom",
    name: "CustomSetting",
    component: CustomSetting,
  },
  {
    path: "/admin/setting/master-list",
    name: "MasterList",
    component: MasterList,
  },
  {
    path: "/admin/setting/sale-stock",
    name: "AdminCsvSaleStockUpload",
    component: CsvSaleStockUpload,
  },
  {
    path: "/admin/setting/weekday-budget",
    name: "AdminWeekdayBudget",
    component: WeekdayBudget,
  },
  {
    path: "/admin/setting/daily-store-budget",
    name: "AdminDailyStoreBudget",
    component: DailyStoreBudget,
  },
  // スタッフ
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/product-list",
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/sku-list",
    name: "SkuList",
    component: SkuList,
  },
  {
    path: "/result-download",
    name: "ActualResultDownload",
    component: ActualResultDownload,
  },
  {
    path: "/product-detail/:productId",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/group-list",
    name: "GroupList",
    component: GroupList,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
  },
  {
    path: "/members",
    name: "Members",
    component: Members,
  },
  {
    path: "/setting/account",
    name: "AccountSetting",
    component: AccountSetting,
  },
  {
    path: "/setting/daily-store-budget",
    name: "DailyStoreBudget",
    component: DailyStoreBudget,
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: Delivery,
  },
  {
    path: "/",
    name: "Root",
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/reset-password-done",
    name: "ResetPasswordDone",
    component: ResetPasswordDone,
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    component: UpdatePassword,
  },
];

if (RadialApiClient.env === "localhost") {
  routes.push(
    {
      path: "/admin/signup",
      name: "AdminSignUp",
      component: SignUp,
    },
    {
      path: "/signup",
      name: "SignUp",
      component: SignUp,
    }
  );
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
