
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import { AlertSettingEditing, User } from "@/store/model";
import { AlertSetting, Company, Store, Alert, AlertMaster } from "@/api/entities";
import RadialApiClient from "@/api/RadialApiClient";
import { UpdateAlertSettingDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import AuthClient from "@/api/AuthClient";
import mixin from "@/mixin/mixin";

export interface DataType {
  user: User | null;
  company: Company | null;
  tabIndex: string;
  isEditingAlert: boolean;
  selectedAlert: AlertSettingEditing | null;
  alertTab: string;
  stores: Store[];
  showingAlert: boolean;
  showingExcludedStoreIdsAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  alerts: Alert[];
  alertSettingDatas: AlertSetting[];
  alertSettings: AlertSettingEditing[];
  alertMasters: AlertMaster[];
  isUpdatingAlert: boolean;
  allStoreIds: string[];
  allNonOperatingStoreIds: string[];
  alertNumbers: string[];
  alertStoreIds: string[];
}

export default Vue.extend({
  name: "TagSetting",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ConfirmationDialog,
  },
  data(): DataType {
    return {
      user: null,
      company: null,
      tabIndex: "enabled",
      isEditingAlert: false,
      selectedAlert: null,
      alertTab: "numbers",
      stores: [],
      showingAlert: false,
      showingExcludedStoreIdsAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      alerts: [],
      alertSettingDatas: [],
      alertSettings: [],
      alertMasters: [],
      isUpdatingAlert: false,
      allStoreIds: [],
      allNonOperatingStoreIds: [],
      alertNumbers: [],
      alertStoreIds: [],
    };
  },
  watch: {
    alertTab() {
      if (this.selectedAlert) {
        this.selectedAlert.numbers = this.alertSettingDatas
          .filter((item) => item.alertMasterId === this.selectedAlert?.alertMasterId)[0]
          .numbers.split(",");
      }
    },
  },
  computed: {
    enabledAlerts(): AlertSettingEditing[] {
      return this.alertSettings.filter((item) => item.isEnabled);
    },
    disabledAlerts(): AlertSettingEditing[] {
      return this.alertSettings.filter((item) => !item.isEnabled);
    },
    updateButtonDisabled(): boolean {
      if (this.selectedAlert) {
        if (
          this.alertNumbers.join(",") ===
          this.alertSettingDatas.filter((item) => item.alertMasterId === this.selectedAlert?.alertMasterId)[0].numbers
        ) {
          return true;
        }
        if (this.alertNumbers.some((item) => item.toString().replace(/[^0123456789]/g, "") === "")) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
  methods: {
    selectAlert(alert: AlertSettingEditing) {
      this.selectedAlert = alert;
      this.alertNumbers = [...this.selectedAlert.numbers];
      this.alertStoreIds = [...this.selectedAlert.storeIds];
      this.isEditingAlert = true;
    },
    deselectAlert() {
      this.selectedAlert = null;
      this.isEditingAlert = false;
    },
    async enableAlert(alert: AlertSettingEditing) {
      this.setAlert(1);
      this.selectAlert(alert);
      await this.updateAlertSetting(alert, true);
    },
    async setAlert(num: number) {
      switch (num) {
        case 0:
          this.showAlert(
            "",
            `アラートを停止しますか？\n停止した場合は、商品リスト及び詳細ページから閲覧することができなくなります。再度追加したい場合は、アラート管理ページよりいつでも設定が可能です。`,
            "error"
          );
          return;
        case 1:
          this.showAlert(
            "",
            `アラートを追加しました！\n翌日までに情報が反映されますのでしばらくお待ち下さい。`,
            "success"
          );
          this.tabIndex = "enabled";
          return;
        default:
          return;
      }
    },
    showAlert(title: string, message: string, type: string) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    showAlertUpdatingStoreIds(storeName: string) {
      if (this.selectedAlert) {
        const alertName = this.getAlertDetail(this.selectedAlert.alertMasterId).name;
        if (this.selectedAlert.storeIds.length > this.alertStoreIds.length) {
          this.alertTitle = "";
          this.alertMessage = `${storeName}のデータを${alertName}アラートの集計対象から外してもよろしいですか？\n切替後、24時間以内に設定内容が反映される予定です。`;
          this.alertType = "error";
          this.showingExcludedStoreIdsAlert = true;
        } else {
          this.alertTitle = "";
          this.alertMessage = `${alertName}アラートの集計に${storeName}のデータを含めてよろしいですか？\n切替後、24時間以内に設定内容が反映される予定です。`;
          this.alertType = "info";
          this.showingExcludedStoreIdsAlert = true;
        }
      }
    },
    getAlertDetail(alertNumber: number): AlertMaster {
      return (
        this.alertMasters.find((item) => item.id === alertNumber) ?? {
          id: -1,
          name: "",
          storeIntegration: false,
          description: "",
          comment: "",
          prominenceColor: "",
          alerts: [],
          alertSettings: [],
        }
      );
    },
    getAlertCount(alertNumber: number): number {
      return this.alerts.filter((item) => {
        return item.alertMasterId === alertNumber;
      }).length;
    },
    async updateAlertSetting(alert: AlertSettingEditing, isEnabled: boolean) {
      this.showingExcludedStoreIdsAlert = false;
      this.showingAlert = false;
      if (this.selectedAlert) {
        this.selectedAlert.storeIds = this.alertStoreIds;
        const excludedStoreIdsArray = this.allStoreIds.filter((item) => this.alertStoreIds.indexOf(item) < 0);
        const dto: UpdateAlertSettingDto = {
          excludedStoreIds: excludedStoreIdsArray.concat(this.allNonOperatingStoreIds).join(","),
          numbers: this.alertNumbers.join(","),
          isEnabled: isEnabled,
        };
        await RadialApiClient.updateAlertSetting(alert.id, dto);
      }
      this.showAlert(
        "",
        `アラート設定内容を更新しました！\n24時間以内にデータが反映されます。しばらくお待ちください。`,
        "success"
      );
      if (this.company) {
        this.alertSettingDatas = (await RadialApiClient.listAlertSettings()) ?? [];
        this.alertSettings = this.alertSettingDatas.map((item) => {
          const excludedStoreIdsArray = item.excludedStoreIds.split(",");
          const storeIds = this.allStoreIds.filter((item) => excludedStoreIdsArray.indexOf(item) < 0);
          return {
            companyId: item.companyId,
            alertMasterId: item.alertMasterId,
            isEnabled: item.isEnabled,
            id: item.id,
            storeIds,
            numbers: item.numbers.split(","),
          };
        });
        if (!isEnabled) {
          this.isEditingAlert = false;
        }
      }
      this.sortList(0);
    },
    cancelUpdateAlertSetting() {
      if (this.selectedAlert) {
        this.alertNumbers = [...this.selectedAlert.numbers];
        this.alertStoreIds = [...this.selectedAlert.storeIds];
      }
      this.showingExcludedStoreIdsAlert = false;
    },
    sortList(index: number) {
      switch (index) {
        case 0:
          this.alertSettings.sort((a, b) => {
            if (a.alertMasterId > b.alertMasterId) {
              return 1;
            } else {
              return -1;
            }
          });
          break;
        case 1:
          this.stores.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else {
              return -1;
            }
          });
          break;
        case 2:
          this.alertMasters.sort((a, b) => {
            if (a.id > b.id) {
              return -1;
            } else {
              return 1;
            }
          });
          break;
        default:
          return "";
      }
    },
    radioChange(newValue, alertNumberIndex: number) {
      this.alertNumbers = [
        ...this.alertNumbers.slice(0, alertNumberIndex),
        `${newValue}-0`,
        ...this.alertNumbers.slice(alertNumberIndex + 1),
      ];
    },
    textToNumberWithIndex(newValue, alertNumberIndex: number) {
      const newAlertNumber = `${this.alertNumbers[alertNumberIndex].split("-")[0]}-${
        isNaN(Number(newValue)) ? 0 : Number(newValue)
      }`;
      this.alertNumbers = [
        ...this.alertNumbers.slice(0, alertNumberIndex),
        newAlertNumber,
        ...this.alertNumbers.slice(alertNumberIndex + 1),
      ];
    },
  },
  async mounted() {
    this.user = await AuthClient.getUserInfo();
    if (this.user) {
      const company = await RadialApiClient.getCompany(this.user.companyId);
      if (company) {
        this.company = company;
        if (company.stores) {
          this.stores = company.stores.filter((store) => store.operating === true);
          const nonOperatingStores = company.stores.filter((store) => store.operating === false);
          this.sortList(1);
          this.allStoreIds = this.stores
            .map((item) => {
              return item.id;
            })
            .filter((item) => item) as string[];
          this.allNonOperatingStoreIds = nonOperatingStores.map((store) => {
            return store.id;
          });
        }
        this.alertSettingDatas = (await RadialApiClient.listAlertSettings()) ?? [];
        this.alertSettings = this.alertSettingDatas.map((item) => {
          const excludedstoreIds = item.excludedStoreIds.split(",");
          const storeIds = this.allStoreIds.filter((item) => excludedstoreIds.indexOf(item) < 0);
          return {
            companyId: item.companyId,
            alertMasterId: item.alertMasterId,
            isEnabled: item.isEnabled,
            id: item.id,
            storeIds,
            numbers: item.numbers.split(","),
          };
        });
        this.sortList(0);
        this.alerts = (await RadialApiClient.listAlerts()) ?? [];
        this.alertMasters = (await RadialApiClient.listAlertMasters()) ?? [];
        this.sortList(2);
      }
    }
  },
});
