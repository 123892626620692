
/*
  [Pagination]
    <必須>
    - v-model = ページ番号
    - :length = 1つのページで表示する最大アイテム数
*/
import Vue from "vue";
export default Vue.extend({
  name: "pagination",
  props: {
    value: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    listCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pages(): number {
      if (this.pageSize === 0 || this.listCount === 0) {
        return 0;
      }
      return Math.ceil(this.listCount / this.pageSize);
    },
    page: {
      get(): number {
        return this.value;
      },
      set(newValue: number) {
        this.$emit("input", newValue);
      },
    },
  },
});
