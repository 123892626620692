
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import Season from "@/views/masterlist/Season.vue";
import Brand from "@/views/masterlist/Brand.vue";
import Category from "@/views/masterlist/Category.vue";
import Attribute from "@/views/masterlist/Attribute.vue";
import Color from "@/views/masterlist/Color.vue";
import Size from "@/views/masterlist/Size.vue";
import ProductCustom from "@/views/masterlist/ProductCustom.vue";
import SkuCustom from "@/views/masterlist/SkuCustom.vue";
import JanCode from "@/views/masterlist/JanCode.vue";
import ItemNumber from "@/views/masterlist/ItemNumber.vue";
import { User } from "@/store/model";
import AuthClient from "@/api/AuthClient";
import Loading from "@/components/vuetify/Loading.vue";
import mixin from "@/mixin/mixin";

export interface Datatype {
  user: User | null;
  isLoading: boolean;
  tabIndex: string;
}

export default Vue.extend({
  name: "MasterList",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    Season,
    Brand,
    Category,
    Attribute,
    Color,
    Size,
    ProductCustom,
    SkuCustom,
    JanCode,
    ItemNumber,
    Loading,
  },
  data(): Datatype {
    return {
      user: null,
      isLoading: false,
      tabIndex: "seasons",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  async mounted() {
    this.isLoading = true;
    this.user = await AuthClient.getUserInfo();
    this.isLoading = false;
  },
});
