
import Vue from "vue";
import { Chart } from "highcharts-vue";
import { Options } from "highcharts";
import { GroupListGraphItemEntity } from "@/api/entities";
import dayjs from "dayjs";
import mixin from "@/mixin/mixin";

export interface DataType {
  dataFormatIndex: number;
  isLoaded: boolean;
}

export default Vue.extend({
  name: "group-list-chart",
  mixins: [mixin],
  components: {
    Chart,
  },
  props: {
    graphData: {
      type: Array as () => GroupListGraphItemEntity[],
      required: true,
    },
    hoverItemId: {
      type: [String, Number],
      required: false,
    },
    dataFormat: {
      type: Number,
      required: true,
    },
    dateRange: {
      type: Number,
      required: true,
    },
  },
  data(): DataType {
    return {
      dataFormatIndex: 0,
      isLoaded: false,
    };
  },
  watch: {
    dataFormatIndex() {
      this.$emit("update:dataFormat", this.dataFormatIndex);
    },
    graphData() {
      if (this.graphData.length > 0) {
        this.isLoaded = true;
      }
    },
  },
  computed: {
    hoverIndex(): number {
      return this.graphData.findIndex((item) => item.id === this.hoverItemId);
    },
    colors(): string[] {
      const colors = ["#CCE4E4", "#A5D5D4", "#5FB3B1", "#519998", "#44807F", "#294D4C"];
      if (this.hoverIndex >= 0 && this.hoverIndex < colors.length) {
        colors[this.hoverIndex] = "#76B3E0";
      }
      return colors;
    },
    options(): Options {
      const categories = this.graphData.length > 0 ? this.graphData[0].dates.map((date) => date.date) : [];
      return {
        chart: {
          type: "area",
          height: 540,
          backgroundColor: "#fbfbfb",
          animation: false,
        },
        title: {
          text: "",
        },
        colors: this.colors,
        xAxis: {
          categories,
          tickmarkPlacement: "on",
          labels: {
            formatter: (data) => {
              return dayjs(data.value).format("MM.DD");
            },
            style: {
              // X軸の各ポイントにおけるカラー（assistantType50で統一）
              color: "#799498",
            },
            step: this.dateRange < 90 ? 1 : this.dateRange < 180 ? 7 : 14,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            formatter: (data) => {
              return this.dataFormat === 0 ? `${data.value}` : `¥${Number(data.value).toLocaleString()}`;
            },
            style: {
              // Y軸の各ポイントにおけるカラー（assistantType50で統一）
              color: "#799498",
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          area: {
            stacking: "normal",
            lineColor: "#666666",
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            events: {
              mouseOver: (event) => {
                const itemId = this.graphData[(event.target as any).index].id;
                this.$emit("update:hoverItemId", itemId);
              },
              mouseOut: (event) => {
                this.$emit("update:hoverItemId", null);
              },
            },
          },
          series: {
            states: {
              hover: { enabled: false },
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        series: this.graphData.map((item) => {
          return {
            showInLegend: false,
            name: item.name,
            type: "area",
            data: item.dates.map((date) => (this.dataFormat === 0 ? date.saleResultQuantity : date.saleResultAmount)),
          };
        }),
        credits: {
          enabled: false,
        },
      };
    },
  },
  methods: {},
  async mounted() {},
});
