
import Vue from "vue";
import TWEEN from "tween.ts";

export interface DataType {
  tweeningValue: number;
}

export default Vue.extend({
  name: "anum",
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    prependString: {
      type: String,
      required: false,
      default: "",
    },
    appendString: {
      type: String,
      required: false,
      default: "",
    },
    toFixed: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data(): DataType {
    return {
      tweeningValue: 0,
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.tween(oldValue, newValue);
    },
  },
  computed: {
    contentString(): string {
      if (this.toFixed) {
        return this.tweeningValue.toFixed(this.toFixed) || "0";
      } else {
        return this.tweeningValue.toLocaleString() || "0";
      }
    },
  },
  methods: {
    tween(start: number, end: number) {
      function animate(time: number) {
        if (TWEEN.update(time)) {
          requestAnimationFrame(animate);
        }
      }
      new TWEEN.Tween({ tweeningValue: start })
        .to({ tweeningValue: end }, 500)
        .onUpdate((vm) => {
          if (this.toFixed) {
            this.tweeningValue = Number(vm.tweeningValue);
          } else {
            this.tweeningValue = Math.ceil(Number(vm.tweeningValue));
          }
        })
        .start();
      requestAnimationFrame(animate);
    },
  },
  async mounted() {
    this.tween(0, this.value);
  },
});
