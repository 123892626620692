import { render, staticRenderFns } from "./IntegrationSmaregi.vue?vue&type=template&id=193962d9&scoped=true&"
import script from "./IntegrationSmaregi.vue?vue&type=script&lang=ts&"
export * from "./IntegrationSmaregi.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "193962d9",
  null
  
)

export default component.exports