
import Vue from "vue";
import RadialApiClient from "@/api/RadialApiClient";
import { ResultDownloadEntity } from "@/api/entities";
import {
  ResultDownloadProductMasterListType,
  ResultDownloadSkuMasterListType,
  ResultDownloadSaleStockResultListType,
  ResultDownloadProductDto,
  ResultDownloadSkuDto,
} from "@/api/dto";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import Loading from "@/components/vuetify/Loading.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import Checkbox from "@/components/vuetify/Checkbox.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import { Storage } from "aws-amplify";
import dayjs from "dayjs";
import { Constant } from "@/store/constant";
import mixin from "@/mixin/mixin";

export interface Datatype {
  isLoading: boolean;
  showingAlert: boolean;
  alertMessage: string;
  alertType: string;
  rangeDates: string[];
  productType: "PRODUCT" | "SKU" | null;
  channel: "SUM" | "STORE" | null;
  productMasterListTypes: { name: string; value: ResultDownloadProductMasterListType }[][];
  productMasterList: ResultDownloadProductMasterListType[];
  skuMasterListTypes: { name: string; value: ResultDownloadSkuMasterListType }[][];
  skuMasterList: ResultDownloadSkuMasterListType[];
  saleStockResultListTypes: { name: string; value: ResultDownloadSaleStockResultListType }[][];
  saleStockResultList: ResultDownloadSaleStockResultListType[];
}

export default Vue.extend({
  name: "ActualResultDownload",
  mixins: [mixin],
  components: { NavigationDrawer, Loading, ElementRangeDatePicker, Checkbox, ConfirmationDialog },
  data(): Datatype {
    return {
      isLoading: false,
      showingAlert: false,
      alertMessage: "",
      alertType: "success",
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      productType: null,
      channel: null,
      productMasterListTypes: Constant.ProductMasterListTypes,
      productMasterList: ["ITEM_NUMBER"],
      skuMasterListTypes: Constant.SkuMasterListTypes,
      skuMasterList: ["JAN_CODE"],
      saleStockResultListTypes: Constant.SaleStockResultListTypes,
      saleStockResultList: [],
    };
  },
  computed: {
    dateRangeError(): boolean {
      if (dayjs(this.rangeDates[1]).diff(dayjs(this.rangeDates[0]), "year") >= 1) {
        return true;
      } else {
        return false;
      }
    },
    minDate(): string {
      return dayjs().subtract(3, "year").format("YYYY-MM-DD");
    },
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async downloadCsv() {
      this.isLoading = true;
      if (this.productType === "PRODUCT" && this.channel) {
        const dto: ResultDownloadProductDto = {
          start: dayjs(this.rangeDates[0]).toDate(),
          end: dayjs(this.rangeDates[1]).toDate(),
          masterList: this.productMasterListTypes
            .flat()
            .filter((item) => this.productMasterList.includes(item.value))
            .map((item) => item.value), // productMasterListTypesの順番で配列を作成するための処理
          channel: this.channel,
          result: this.saleStockResultListTypes
            .flat()
            .filter((item) => this.saleStockResultList.includes(item.value))
            .map((item) => item.value), // saleStockResultListTypesの順番で配列を作成するための処理
        };
        const response = await RadialApiClient.downloadProductActualResultCsv(dto);
        const s3Key = response?.S3Key.replace(/^public\//, "");
        if (s3Key) {
          const url = String(await Storage.get(s3Key));
          location.href = url;
        } else {
          this.showAlert(
            `ダウンロード時にエラーが発生しました。
             解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
            "error"
          );
        }
      } else if (this.productType === "SKU" && this.channel) {
        const dto: ResultDownloadSkuDto = {
          start: dayjs(this.rangeDates[0]).toDate(),
          end: dayjs(this.rangeDates[1]).toDate(),
          masterList: this.skuMasterListTypes
            .flat()
            .filter((item) => this.skuMasterList.includes(item.value))
            .map((item) => item.value), // skuMasterListTypesの順番で配列を作成するための処理
          channel: this.channel,
          result: this.saleStockResultListTypes
            .flat()
            .filter((item) => this.saleStockResultList.includes(item.value))
            .map((item) => item.value), // saleStockResultListTypesの順番で配列を作成するための処理
        };
        const response = await RadialApiClient.downloadSkuActualResultCsv(dto);
        const s3Key = response?.S3Key.replace(/^public\//, "");
        if (s3Key) {
          const url = String(await Storage.get(s3Key));
          location.href = url;
        } else {
          this.showAlert(
            `ダウンロード時にエラーが発生しました。
             解決しない場合はcs@proces.co.jpまでお問い合わせください。`,
            "error"
          );
        }
      }
      this.isLoading = false;
    },
  },
});
