
import Vue from "vue";
import router from "@/router/index";
import { Auth } from "aws-amplify";
import AuthClient from "@/api/AuthClient";
import LocalDataService from "@/store/LocalDataService";
import RadialApiClient from "@/api/RadialApiClient";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import mixin from "@/mixin/mixin";

export default Vue.extend({
  name: "Login",
  mixins: [mixin],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      email: "",
      password: "",
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
    };
  },
  computed: {
    isLocalhost(): boolean {
      return RadialApiClient.env === "localhost";
    },
  },
  async mounted() {
    if (await AuthClient.checkAuth(this.isAdmin)) {
      router.push({ name: this.isAdmin ? "AdminDashboard" : "Dashboard" });
    }
  },
  methods: {
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async loginDidClick() {
      const email = this.email;
      const password = this.password;
      if (!email || !password) {
        this.showAlert(
          "メールアドレスまたはパスワードが正しく入力されていないようです。再度お試しください。",
          "warning"
        );
        return;
      }
      const user = await Auth.signIn(email, password).catch((error) => {
        if (error.code === "NotAuthorizedException" || error.code === "UserNotFoundException") {
          this.showAlert(
            "メールアドレスまたはパスワードが正しく入力されていないようです。再度お試しください。",
            "warning"
          );
        } else if (error.code === "UserNotConfirmedException") {
          this.showAlert(
            `メールの受信ボックスをご確認ください。
            確認用のメールを送信しております。`,
            "error"
          );
        } else if (error.code === "NetworkError") {
          this.showAlert(
            `サーバーに接続できません。ネットワーク接続をお確かめの上、再度お試しください。もし解決されなければ、
              cs@proces.co.jp
              にご連絡くださいませ。`,
            "error"
          );
        } else {
          this.showAlert(
            `エラーが発生しております。
          もしもう一度お試しいただき解決されなければ、
          cs@proces.co.jp
          にご連絡くださいませ。`,
            "error"
          );
        }
      });
      if (user) {
        if (this.isAdmin) {
          if (await AuthClient.checkAuth(this.isAdmin)) {
            router.push({ name: "AdminDashboard" });
          } else {
            this.showAlert(
              "メールアドレスまたはパスワードが正しく入力されていないようです。再度お試しください。",
              "warning"
            );
          }
        } else {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            router.push({
              name: "UpdatePassword",
              query: { email: this.email },
            });
          } else if (await AuthClient.checkAuth(this.isAdmin)) {
            const newUser = await AuthClient.getUserInfo();
            if (newUser) {
              const store = await RadialApiClient.getStore(newUser.sub);
              if (store) {
                if (store.deleted) {
                  this.showAlert(
                    `${store.name}はradial adminアカウントより削除されましたため、
                    ログインすることができません。`,
                    "error"
                  );
                  await LocalDataService.clear();
                  await Auth.signOut();
                } else if (!store.operating) {
                  this.showAlert(
                    `現在このアカウントはログインすることができません。
                    radial adminアカウントより${store.name}の稼働ステータスを変更したうえで再度お試しください。`,
                    "error"
                  );
                  await LocalDataService.clear();
                  await Auth.signOut();
                } else {
                  router.push({ name: "Dashboard" });
                }
              }
            }
          } else {
            this.showAlert(
              "メールアドレスまたはパスワードが正しく入力されていないようです。再度お試しください。",
              "warning"
            );
          }
        }
      }
    },
    resetPasswordDidClick() {
      router.push({
        name: this.isAdmin ? "AdminResetPassword" : "ResetPassword",
      });
    },
    signUpDidClick() {
      router.push({ name: this.isAdmin ? "AdminSignUp" : "SignUp" });
    },
  },
});
