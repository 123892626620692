
import Vue from "vue";
import dayjs from "dayjs";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import RadialApiClient from "@/api/RadialApiClient";
import { Season, SeasonDeleteInfoEntity } from "@/api/entities";
import { UpdateSeasonDto } from "@/api/dto";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Pagination from "@/components/vuetify/Pagination.vue";
import { User } from "@/store/model";
import mixin from "@/mixin/mixin";
import anum from "@/components/animation/AnimatedNumber.vue";

export interface AddingSeason {
  // シーズン新規作成ための型
  name: string;
  startYearMonth: string;
  endYearMonth: string;
  refSeasonId: number | null;
  costRateTarget: number;
  code: string | null;
}

export interface DataType {
  alertMessage: string;
  alertType: string;
  showingAlert: boolean;
  activeItem: string | null;
  selectedIndex: number;
  seasons: Season[];
  selectedSeason: Season | null;
  dialogAddSeason: boolean;
  dialogEditSeason: boolean;
  dialogDeleteCheck: boolean;
  dialogDeleteSeason: boolean;
  newSeason: AddingSeason | null;
  page: number;
  pageSize: number;
  list: Season[];
  listCount: number;
  deleteConfirmationNumbers: SeasonDeleteInfoEntity | null;
}

export default Vue.extend({
  name: "Season",
  mixins: [mixin],
  components: {
    ElementDatePicker,
    ConfirmationDialog,
    Pagination,
    anum,
  },
  data(): DataType {
    return {
      alertMessage: "",
      alertType: "success",
      showingAlert: false,
      activeItem: null,
      selectedIndex: 0,
      seasons: [],
      selectedSeason: null,
      dialogAddSeason: false,
      dialogEditSeason: false,
      dialogDeleteCheck: false,
      dialogDeleteSeason: false,
      newSeason: null,
      page: 1,
      pageSize: 12,
      list: [],
      listCount: 0,
      deleteConfirmationNumbers: null,
    };
  },
  props: {
    user: {
      type: Object as () => User | null,
      required: false,
    },
  },
  computed: {
    editSeasonDisabled(): boolean {
      if (this.selectedSeason) {
        if (!this.selectedSeason.name) {
          return true;
        } else if (isNaN(parseInt(this.selectedSeason.costRateTarget.toString()))) {
          return true;
        } else if (this.selectedSeason.costRateTarget < 0) {
          return true;
        } else if (
          this.selectedSeason.name !== this.seasons[this.selectedIndex].name &&
          this.list.some((season) => {
            return season.name === this.selectedSeason?.name;
          })
        ) {
          return true;
        } else if (
          this.selectedSeason.name === this.seasons[this.selectedIndex].name &&
          this.selectedSeason.refSeasonId === this.seasons[this.selectedIndex].refSeasonId &&
          this.selectedSeason.costRateTarget === this.seasons[this.selectedIndex].costRateTarget
        ) {
          if (this.selectedSeason.code === this.seasons[this.selectedIndex].code) {
            return true;
          } else if (!this.selectedSeason.code && !this.seasons[this.selectedIndex].code) {
            return true;
          }
        }
      }
      return false;
    },
    addSeasonDisabled(): boolean {
      if (this.newSeason) {
        if (!this.newSeason.name) {
          return true;
        } else if (isNaN(parseInt(this.newSeason.costRateTarget.toString()))) {
          return true;
        } else if (dayjs(this.newSeason.startYearMonth).isAfter(dayjs(this.newSeason.endYearMonth))) {
          return true;
        } else if (dayjs(this.newSeason.endYearMonth).diff(dayjs(this.newSeason.startYearMonth), "months") > 11) {
          return true;
        } else if (this.newSeason.costRateTarget < 0) {
          return true;
        } else {
          return this.list.some((season) => {
            return season.name === this.newSeason?.name;
          });
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    listActive(item: any) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    showAlert(message: string, type: string) {
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    sortList() {
      this.list.sort((a, b) => {
        if (a.startYearMonth < b.startYearMonth) {
          return 1;
        } else if (a.startYearMonth === b.startYearMonth) {
          if (a.code && b.code) {
            return a.code > b.code ? 1 : -1;
          } else if (a.code && b.code === "") {
            return -1;
          } else if (a.code === "" && b.code) {
            return 1;
          } else {
            return a.name > b.name ? -1 : 1;
          }
        } else {
          return -1;
        }
      });
    },
    getSeasonRange(season: Season, index: number): string {
      if (index === 0) {
        return dayjs(season.startYearMonth).format("YY/M");
      } else {
        return dayjs(season.endYearMonth).format("YY/M");
      }
    },
    getSeasonName(index: number): string {
      const season = this.seasons.find((s) => {
        return s.id === index;
      });
      return season?.name as string;
    },
    async selectSeason(season: Season, index: number, selectedIndex: number) {
      this.selectedSeason = { ...season };
      if (index === 0) {
        this.selectedIndex = selectedIndex;
        this.dialogEditSeason = true;
      } else {
        this.deleteConfirmationNumbers = await RadialApiClient.getSeasonDeleteInfo(this.selectedSeason.id);
        this.dialogDeleteCheck = true;
      }
    },
    deselectSeason() {
      this.selectedSeason = null;
      this.dialogEditSeason = false;
      this.dialogDeleteCheck = false;
      this.dialogDeleteSeason = false;
      this.deleteConfirmationNumbers = null;
    },
    cancelAddSeason() {
      this.dialogAddSeason = false;
      this.newSeason = null;
    },
    showAddSeasonDialog() {
      this.dialogAddSeason = true;
      this.newSeason = {
        name: "",
        startYearMonth: dayjs().format("YYYY-MM"),
        endYearMonth: dayjs().format("YYYY-MM"),
        refSeasonId: null,
        costRateTarget: 40,
        code: null,
      };
    },
    async addSeason() {
      this.$emit("isLoading", true);
      if (this.user && this.newSeason) {
        const season = await RadialApiClient.createSeason({
          company: { connect: { id: this.user.companyId } },
          name: this.newSeason.name,
          startYearMonth: dayjs(this.newSeason.startYearMonth).startOf("day").toDate(),
          endYearMonth: dayjs(this.newSeason.endYearMonth).endOf("month").endOf("day").toDate(),
          refSeason:
            this.newSeason.refSeasonId !== null
              ? {
                  connect: { id: this.newSeason.refSeasonId },
                }
              : undefined,
          costRateTarget: this.newSeason.costRateTarget,
          code: this.newSeason.code,
        });
        if (season) {
          this.list.push(season);
          this.listCount = this.list.length;
          this.sortList();
          this.updatePage(this.page);
          this.showAlert(
            "新しくシーズンが作成されました！\n各予算ページに数分後に反映されますのでしばらくお待ちください。",
            "success"
          );
        } else {
          this.showAlert(
            `エラーが発生しております。
              入力内容をご確認のうえ、時間を空けてからもう一度お試しください。`,
            "error"
          );
        }
      }
      this.newSeason = null;
      this.$emit("isLoading", false);
      this.dialogAddSeason = false;
    },
    async editSeason() {
      if (this.selectedSeason) {
        const dto: UpdateSeasonDto = {
          name: this.selectedSeason.name,
          refSeason:
            this.selectedSeason.refSeasonId !== null
              ? {
                  connect: { id: this.selectedSeason.refSeasonId },
                }
              : undefined,
          costRateTarget: this.selectedSeason.costRateTarget,
          code: this.selectedSeason.code,
        };
        await RadialApiClient.updateSeason(this.selectedSeason.id, dto)
          .then(async () => {
            const list = await RadialApiClient.listSeasons();
            if (list) {
              this.list = list;
              this.sortList();
              this.updatePage(this.page);
            }
            this.updatePage(this.page);
            this.selectedSeason = null;
            this.dialogEditSeason = false;
          })
          .catch(() => {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          });
      }
    },
    async deleteSeason() {
      this.dialogDeleteCheck = false;
      this.dialogDeleteSeason = false;
      this.$emit("isLoading", true);
      if (this.selectedSeason) {
        await RadialApiClient.deleteSeason(this.selectedSeason.id)
          .then(async () => {
            const list = await RadialApiClient.listSeasons();
            if (list) {
              this.list = list;
              this.listCount = this.list.length;
              this.sortList();
              this.updatePage(this.page);
            }
            this.showAlert("無事に削除が完了しました！", "success");
          })
          .catch(() => {
            this.showAlert("エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
          });
      }
      this.selectedSeason = null;
      this.$emit("isLoading", false);
    },
    getRefSeasons(id: number): Season[] | null {
      if (this.seasons) {
        return this.seasons.filter((season) => {
          return season.id !== id;
        });
      } else {
        return null;
      }
    },
    getAlertMessage(): string {
      if (this.selectedSeason) {
        return `本当に${this.selectedSeason.name}を削除してもよろしいですか？`;
      } else {
        return "";
      }
    },
    updatePage(event: number) {
      const start = (event - 1) * this.pageSize;
      const end = event * this.pageSize;
      this.seasons = this.list.slice(start, end);
      this.page = event;
    },
  },
  async mounted() {
    this.$emit("isLoading", true);
    const list = await RadialApiClient.listSeasons();
    if (list) {
      this.list = list;
      this.sortList();
      this.listCount = this.list.length;
      if (this.listCount < this.pageSize) {
        this.seasons = this.list;
      } else {
        this.seasons = this.list.slice(0, this.pageSize);
      }
    }
    this.$emit("isLoading", false);
  },
});
